/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
    CircularProgress,
} from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { clientTheme } from 'theme-exports'
import { useAuthedUser } from '../../context/AuthedUser/AuthedUserContext'
import { User } from 'hooks/cb-api'
import UsersApi from 'api/UsersApi'
import ConfigApi from 'api/ConfigApi'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import { SearchableSelect } from 'components'

type TUpdateUserInputs = {
    email: string
    firstName: string
    lastName: string
    username: string
    role: number
    merchantId: number
    isReadOnly: boolean
}

type TUpdateUserKeys =
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'username'
    | 'role'
    | 'merchantId'
    | 'isReadOnly'

type TOptionsList = {
    id: number
    name: string
    alias?: string
    description?: string
} & Record<string, unknown>

interface IUpdateUserProps {
    className?: string
    existingUser?: User
}

interface IUpdateUserFormProps {
    className?: string
    isEdit: boolean
    isSameUser: boolean
    existingUser: User | undefined
    merchantOptions: TOptionsList[]
    merchantOptionsLoading: boolean
    merchantsSearchValue: string
    setMerchantsSearchValue: (value: string) => void
    roleOptions: TOptionsList[]
    roleOptionsLoading: boolean
    values: TUpdateUserInputs
    register: any
    handleOnChangeValue: (key: TUpdateUserKeys, value: any) => void
    handleSubmit: () => void
    isSubmitting: boolean
    errors: { [key: string]: any }
}

export const UpdateUserForm: React.FC<IUpdateUserFormProps> = ({
    className = '',
    isEdit,
    isSameUser,
    existingUser,
    merchantOptions,
    merchantOptionsLoading,
    merchantsSearchValue,
    setMerchantsSearchValue,
    roleOptions,
    roleOptionsLoading,
    values,
    register,
    handleOnChangeValue,
    handleSubmit,
    isSubmitting,
    errors,
}) => {
    const { showNewDataGrid } = useAuthedUser()
    const { style: formVariantStyle = 'outlined' } = useFeatureToggle(
        'FORM_FIELDS'
    )
    const { client } = useFeatureToggle('CLIENT')
    const { READ_ONLY_OPTION } = useFeatureToggle('USERS')
    const [merchantName, setMerchantName] = useState<string>('')
    const [roleName, setRoleName] = useState<string>('')

    const merchantOptionsLength = merchantOptions ? merchantOptions.length : 0

    useEffect(() => {
        setMerchantName(
            existingUser?.merchant
                ? existingUser.merchant.business_name
                : 'Choose a company'
        )
        setRoleName(
            existingUser?.role ? existingUser.role.name : 'Choose a role'
        )
    }, [existingUser])

    return (
        <div className={`emp-updateUser-root ${className}`}>
            <form onSubmit={handleSubmit}>
                <FormControl
                    variant="outlined"
                    className={'emp-updateUser-spaced'}
                    fullWidth
                >
                    <FormLabel htmlFor="company-field">Company Name</FormLabel>

                    <div className={'emp-updateUser-inputs'}>
                        <SearchableSelect
                            value={merchantName}
                            onValueChange={(e: any) => {
                                handleOnChangeValue('merchantId', e.id)
                                setMerchantName(e.name)
                                // Clear out role, on merchant change
                                setRoleName('Choose a role')
                                handleOnChangeValue('role', undefined)
                            }}
                            options={
                                merchantsSearchValue.length > 1
                                    ? merchantOptions ?? []
                                    : []
                            }
                            accessor={'name'}
                            searchValue={merchantsSearchValue}
                            onSearchChange={setMerchantsSearchValue}
                            testId="userCompany"
                            displayType={formVariantStyle}
                            debounceDelay={200}
                            loadingValues={merchantOptionsLoading}
                        >
                            {merchantsSearchValue.length < 2 &&
                                !merchantOptionsLoading && (
                                    <div
                                        className={
                                            'emp-updateUser-searchableText'
                                        }
                                    >
                                        Please enter 2 or more characters
                                    </div>
                                )}
                            {merchantsSearchValue.length > 2 &&
                                merchantOptionsLength === 0 &&
                                !merchantOptionsLoading && (
                                    <div
                                        className={
                                            'emp-updateUser-searchableText'
                                        }
                                    >
                                        No results found
                                    </div>
                                )}
                        </SearchableSelect>
                    </div>
                </FormControl>
                <FormControl
                    variant="outlined"
                    className={'emp-updateUser-spaced'}
                    fullWidth
                    sx={{
                        '& .MuiFormLabel-root': {
                            ...clientTheme.formFields.formLabel,
                        },
                    }}
                >
                    <FormLabel htmlFor="role-field">Role</FormLabel>

                    <div className={'emp-updateUser-inputs'}>
                        <SearchableSelect
                            value={roleName}
                            onValueChange={(e: any) => {
                                handleOnChangeValue('role', e.id)
                                setRoleName(e.name)
                            }}
                            options={roleOptions ?? []}
                            accessor={'name'}
                            disabled={
                                isSameUser ||
                                (values.merchantId < 1 ? true : false)
                            }
                            hideSearch
                            testId="userRole"
                            displayType={formVariantStyle}
                            isPaginate={false}
                            loadingValues={roleOptionsLoading}
                        />
                    </div>
                    {Boolean(errors?.role?.message) && (
                        <FormHelperText error>
                            {errors?.role?.message}
                        </FormHelperText>
                    )}
                </FormControl>
                <FormControl className={'emp-updateUser-spaced'} fullWidth>
                    <FormLabel>First Name</FormLabel>
                    <TextField
                        variant={formVariantStyle}
                        className={'emp-updateUser-textField'}
                        required
                        type="text"
                        {...register('firstName')}
                        name="firstName"
                        sx={[
                            formVariantStyle === 'standard' && {
                                '& .MuiInputBase-input': {
                                    ...clientTheme.formFields.formText.standard,
                                    borderBottom: '1px solid #84878E',
                                },
                            },
                        ]}
                    />
                    {Boolean(errors?.firstName?.message) && (
                        <FormHelperText error>
                            {errors?.firstName?.message}
                        </FormHelperText>
                    )}
                </FormControl>
                <FormControl className={'emp-updateUser-spaced'} fullWidth>
                    <FormLabel>Last Name</FormLabel>
                    <TextField
                        variant={formVariantStyle}
                        className={'emp-updateUser-textField'}
                        required
                        type="text"
                        {...register('lastName')}
                        name="lastName"
                        sx={[
                            formVariantStyle === 'standard' && {
                                '& .MuiInputBase-input': {
                                    ...clientTheme.formFields.formText.standard,
                                    borderBottom: '1px solid #84878E',
                                },
                            },
                        ]}
                    />
                    {Boolean(errors?.lastName?.message) && (
                        <FormHelperText error>
                            {errors?.lastName?.message}
                        </FormHelperText>
                    )}
                </FormControl>
                <FormControl className={'emp-updateUser-spaced'} fullWidth>
                    <FormLabel>Email</FormLabel>
                    <TextField
                        variant={formVariantStyle}
                        className={'emp-updateUser-textField'}
                        required
                        type="email"
                        {...register('email')}
                        name="email"
                        sx={[
                            formVariantStyle === 'standard' && {
                                '& .MuiInputBase-input': {
                                    ...clientTheme.formFields.formText.standard,
                                    borderBottom: '1px solid #84878E',
                                },
                            },
                        ]}
                    />
                    {Boolean(errors?.email?.message) && (
                        <FormHelperText error>
                            {errors?.email?.message}
                        </FormHelperText>
                    )}
                </FormControl>
                <FormControl className={'emp-updateUser-spaced'} fullWidth>
                    <FormLabel>Username</FormLabel>
                    <TextField
                        variant={formVariantStyle}
                        className={'emp-updateUser-textField'}
                        required
                        type="text"
                        {...register('username')}
                        name="username"
                        disabled={isEdit}
                        sx={[
                            formVariantStyle === 'standard' && {
                                '& .MuiInputBase-input': {
                                    ...clientTheme.formFields.formText.standard,
                                    borderBottom: '1px solid #84878E',
                                },
                            },
                        ]}
                    />
                    {Boolean(errors?.username?.message) && (
                        <FormHelperText error>
                            {errors?.username?.message}
                        </FormHelperText>
                    )}
                </FormControl>

                {READ_ONLY_OPTION?.enabled && (
                    <FormControl className={'emp-updateUser-spaced'} fullWidth>
                        <Typography>Read Only</Typography>
                        <Typography style={{ fontSize: '11px' }}>
                            If this option is checked, then the user can only
                            view the records.
                        </Typography>
                        <RadioGroup
                            value={values.isReadOnly ? 'yes' : 'no'}
                            onChange={(e) => {
                                handleOnChangeValue(
                                    'isReadOnly',
                                    Boolean(e.target.value === 'yes')
                                )
                            }}
                            row
                        >
                            <FormControlLabel
                                value={'yes'}
                                control={
                                    <Radio size={'small'} color="secondary" />
                                }
                                label="Yes"
                            />
                            <FormControlLabel
                                value={'no'}
                                control={
                                    <Radio size={'small'} color="secondary" />
                                }
                                label="No"
                            />
                        </RadioGroup>
                    </FormControl>
                )}

                <div className={'emp-updateUser-btnsContainer'}>
                    <Button
                        type="submit"
                        color="secondary"
                        variant="contained"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <>
                                Saving...{' '}
                                <CircularProgress
                                    className={
                                        'emp-updateUser-circularProgress'
                                    }
                                    color="secondary"
                                />
                            </>
                        ) : (
                            'Save'
                        )}
                    </Button>
                    <Link to={showNewDataGrid ? '/users-new' : '/users'}>
                        <Button
                            variant={client === 'jpmc' ? 'text' : 'contained'}
                            color="primary"
                            style={{ width: '100%' }}
                            sx={[
                                client === 'jpmc' &&
                                    clientTheme.buttons.textOrOutlinedButton
                                        .style,
                            ]}
                        >
                            Close
                        </Button>
                    </Link>
                </div>
            </form>
        </div>
    )
}

export const UpdateUser: React.FC<IUpdateUserProps> = ({
    className,
    existingUser,
}) => {
    const history = useHistory()
    const { user: authedUser, showNewDataGrid } = useAuthedUser()

    const [roles, setRoles] = useState([])
    const [roleOptionsLoading, setRoleOptionsLoading] = useState<boolean>(false)
    const [merchants, setMerchants] = useState<TOptionsList[]>([])
    const [
        merchantOptionsLoading,
        setMerchantOptionsLoading,
    ] = useState<boolean>(false)
    const [merchantsSearchValue, setMerchantsSearchValue] = useState<string>('')
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})
    const [
        merchantDomainWhitelist,
        setMerchantDomainWhitelist,
    ] = useState<string>('')
    const [excludeServiceLevelIds, setExcludeServiceLevelIds] = useState<
        number[] | null
    >(null)

    useEffect(() => {
        ConfigApi.getStackConfigSlim()
            .then((data) => {
                setExcludeServiceLevelIds(
                    data?.attr?.disable_merchant_sl_create_credentials ?? []
                )
            })
            .catch(() => setExcludeServiceLevelIds(null))
    }, [])

    useEffect(() => {
        const returnData: TOptionsList[] = []

        if (
            authedUser &&
            authedUser.merchant &&
            merchantsSearchValue.length > 1
        ) {
            if (!excludeServiceLevelIds) {
                setAlertSnackbarProps({
                    message: `An error occurred while loading companies. Please try again later.`,
                    title: 'Error',
                    intent: 'error',
                })
                return setAlertSnackbarOpen(true)
            }

            setMerchantOptionsLoading(true)
            UsersApi.getMerchants(
                authedUser.merchant.id,
                merchantsSearchValue,
                excludeServiceLevelIds
            )
                .then((data) => {
                    data.data.forEach(
                        (data: { id: number; business_name: string }) => {
                            returnData.push({
                                id: data.id,
                                name: data.business_name,
                            })
                        }
                    )
                    setMerchantOptionsLoading(false)
                    setMerchants(returnData)
                })
                .catch(() => {
                    setAlertSnackbarProps({
                        message: `An error occurred while loading companies. Please try again later.`,
                        title: 'Error',
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                })
        }
        return () => {
            setMerchants([])
        }
    }, [authedUser, merchantsSearchValue, excludeServiceLevelIds])

    const validationSchema = Yup.object().shape({
        role: Yup.number()
            .required('User must be assigned to a role.')
            .positive('User must be assigned to a role.'),
        firstName: Yup.string().required('First name is needed.'),
        lastName: Yup.string().required('Last name is needed.'),
        email: Yup.string()
            .email()
            .required('Email is needed.')
            .matches(
                new RegExp(merchantDomainWhitelist),
                'Please enter an allowed email domain.'
            ),
        // username: Yup.string().min(3).max(256).required(),
    })

    const defaultValues = useMemo<TUpdateUserInputs>(() => {
        return existingUser?.id
            ? {
                  email: existingUser?.email || '',
                  firstName: existingUser?.fname || '',
                  lastName: existingUser?.lname || '',
                  username: existingUser?.username || '',
                  role: existingUser?.role?.id || 0,
                  merchantId: existingUser?.merchant?.id || 0,
                  isReadOnly: existingUser?.is_read_only || false,
              }
            : {
                  email: '',
                  firstName: '',
                  lastName: '',
                  username: '',
                  role: 0,
                  merchantId: 0,
                  isReadOnly: false,
              }
    }, [existingUser])

    const {
        reset,
        register,
        watch,
        handleSubmit,
        setValue,
        formState: { errors, isSubmitting },
        // TODO: Known React-Hook-Form typescript issue - Should be resolved with RHF's v8. Once resolved, need to switch typescript's "any" to "TUpdateUserInputs".
    } = useForm<any>({
        defaultValues: defaultValues,
        values: defaultValues,
        mode: 'all',
        resolver: yupResolver(validationSchema),
    })

    const onSubmit: SubmitHandler<TUpdateUserInputs> = (values) => {
        const updateUserPromise = existingUser?.id
            ? UsersApi.editUser(
                  existingUser.id as number,
                  values.firstName,
                  values.lastName,
                  values.email,
                  values.role,
                  values.merchantId,
                  values.isReadOnly
              )
            : UsersApi.addUser(
                  values.firstName,
                  values.lastName,
                  values.email,
                  values.username,
                  values.role,
                  values.merchantId,
                  values.isReadOnly
              )

        return updateUserPromise
            .then(() => {
                history.push(showNewDataGrid ? '/users-new' : '/users', {
                    success: 'true',
                    isEdit: existingUser,
                })
                reset()
            })
            .catch((err) => {
                setAlertSnackbarProps({
                    title: 'Error',
                    message:
                        err.response.data.message ??
                        'An error occurred. Please try again later.',
                    intent: 'error',
                })
                setAlertSnackbarOpen(true)
            })
    }

    const handleOnChangeValue = (key: TUpdateUserKeys, value: any) => {
        setValue(key, value, {
            shouldValidate: true,
            shouldDirty: true,
        })
    }

    const updatedMerchantId = watch('merchantId')

    useEffect(() => {
        if (updatedMerchantId > 0) {
            setRoleOptionsLoading(true)
            UsersApi.getRolesForMerchant(updatedMerchantId)
                .then(({ data }) => {
                    setRoles(data)
                })
                .finally(() => setRoleOptionsLoading(false))
                .catch(() => {
                    setAlertSnackbarProps({
                        message: `An error occurred while loading roles. Please try again later.`,
                        title: 'Error',
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                })

            UsersApi.getMerchantDomainWhitelist(updatedMerchantId)
                .then((data) => {
                    const domainWhitelistFormatted = data
                        .map((domain: string) => `@${domain}`)
                        .join('|')
                    setMerchantDomainWhitelist(domainWhitelistFormatted)
                })
                .catch(() => {
                    setAlertSnackbarProps({
                        message: `An error occurred. Please try again later.`,
                        title: 'Error',
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                })
        }
    }, [updatedMerchantId])

    return (
        <>
            <UpdateUserForm
                className={className}
                isEdit={existingUser?.id ? true : false}
                isSameUser={
                    (existingUser && authedUser?.id === existingUser.id) ||
                    false
                }
                existingUser={existingUser}
                merchantOptions={merchants}
                merchantOptionsLoading={merchantOptionsLoading}
                merchantsSearchValue={merchantsSearchValue}
                setMerchantsSearchValue={setMerchantsSearchValue}
                roleOptions={roles}
                roleOptionsLoading={roleOptionsLoading}
                values={watch()}
                register={register}
                handleOnChangeValue={handleOnChangeValue}
                handleSubmit={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                errors={errors}
            />
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => {
                    setAlertSnackbarOpen(false)
                }}
                showCloseIcon
            />
        </>
    )
}

import React, { useState } from 'react'
import axios from 'axios'
import { Button, Dialog, DialogContent, TextField } from '@mui/material'
import { clientTheme } from 'theme-exports'
import { SelectedReportRow } from 'views/ReconciliationReport/ReconciliationReport.vm'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

export interface FlagReportModalProps {
    open: boolean
    openUnflagReport: boolean
    toggleOpen: () => void
    rehydrateView: () => void
    selectedReports: SelectedReportRow[]
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

/**
 * @name FlagReportModal
 * @description - Add flags to selected reports
 * @param open - bool that opens or closes the modal view
 * @param openUnflagReport - bool that opens either unflag or flag modal
 * @param toggleOpen -  toggles the modal open or close
 * @param rehydrateView - reloads datagrid
 * @param selectedReports - select report rows needed information
 *  */

const FlagReportModal = ({
    open,
    openUnflagReport,
    toggleOpen,
    rehydrateView,
    selectedReports,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: FlagReportModalProps) => {
    const [comment, setComment] = useState('')

    const flagReport = async (comment: string) => {
        const flagRows = selectedReports.map((item) => ({
            processor_id: item.processorId,
            date: item.date,
            comment,
        }))

        try {
            await axios.post(`/cm/cases/reports/reconciliation/flag/bulk`, {
                flags: flagRows,
            })
            setAlertSnackbarMainProps({
                message: 'Your action was a success!',
                title: 'Success.',
                intent: 'success',
            })
            rehydrateView()
        } catch (error) {
            setAlertSnackbarMainProps({
                title: 'Error.',
                message: 'There was an error flagging these reports.',
                intent: 'error',
            })
        } finally {
            setAlertSnackbarMainOpen(true)
            handleOnClose()
        }
    }

    const unFlagReport = async () => {
        const unFlagRowIds = selectedReports.map((item) => item.flagId)

        try {
            await axios.post(`/cm/cases/reports/reconciliation/flag/delete`, {
                ids: unFlagRowIds,
            })
            setAlertSnackbarMainProps({
                message: 'Your action was a success!',
                title: 'Success.',
                intent: 'success',
            })
            rehydrateView()
        } catch (error) {
            setAlertSnackbarMainProps({
                title: 'Error.',
                message: 'There was an error unflagging these reports.',
                intent: 'error',
            })
        } finally {
            setAlertSnackbarMainOpen(true)
            handleOnClose()
        }
    }

    const handleFlagReport = (flag: boolean) => {
        flag ? flagReport(comment) : unFlagReport()
    }

    const handleOnClose = () => {
        setComment('')
        toggleOpen()
    }

    return (
        <Dialog open={open} onClose={handleOnClose} maxWidth="sm" fullWidth>
            <DialogContent className={'emp-flagReportModal-content'}>
                {openUnflagReport ? (
                    <>
                        <h2 className={'emp-flagReportModal-title '}>
                            Unflag Report
                        </h2>
                        <div className={'emp-flagReportModal-actions'}>
                            <Button
                                style={{ width: '100%' }}
                                variant="contained"
                                onClick={handleOnClose}
                                sx={clientTheme.buttons.defaultButton.style}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ width: '100%' }}
                                onClick={() => handleFlagReport(false)}
                            >
                                Continue
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <h2 className={'emp-flagReportModal-title'}>
                            Flag Report
                        </h2>
                        <h4 className={'emp-flagReportModal-subtitle'}>
                            Comment
                        </h4>
                        <TextField
                            variant="outlined"
                            onChange={(e) => setComment(e.target.value)}
                            value={comment}
                            placeholder="Comment"
                            multiline
                            rows={4}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius:
                                        clientTheme.selectionBox.borderRadius,
                                },
                            }}
                        />
                        <div className={'emp-flagReportModal-actions'}>
                            <Button
                                style={{ width: '100%' }}
                                variant="contained"
                                onClick={handleOnClose}
                                sx={clientTheme.buttons.defaultButton.style}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ width: '100%' }}
                                onClick={() => handleFlagReport(true)}
                            >
                                Flag
                            </Button>
                        </div>
                    </>
                )}
            </DialogContent>
        </Dialog>
    )
}

export default FlagReportModal

import React, { useEffect, useState } from 'react'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage'


interface ActiveMerchant {
    id: string
    name: string
}

interface ActiveMerchantInstance {
    id: string
    name: string
    isRootMerchant: boolean
    userDefaultMerchant: string
    setActiveMerchant: (merchant: ActiveMerchant) => void
    setMerchantSwitchToggle: (value: boolean) => void
}

export const ActiveMerchantContext = React.createContext<ActiveMerchantInstance>(
    {
        id: '',
        name: '',
        userDefaultMerchant: '',
        setActiveMerchant: () => {},
        isRootMerchant: false,
        setMerchantSwitchToggle: (value) => {},
    }
)

export const useActiveMerchant = () => {
    return React.useContext(ActiveMerchantContext)
}
/** Use useActiveMerchant to provide the active merchant throught that app. */
export const ActiveMerchantProvider: React.FC = ({ children }) => {
    const uiState = useUiStateContext()
    const [selectedMerchant, setSelectedMerchant] = useLocalStorage<string>(
        'selectedMerchant',
        ''
    )
    const [selectedMerchantId, setSelectedMerchantId] = useLocalStorage<string>(
        'selectedMerchantId',
        ''
    )
    const [merchantSwitchToggle, setMerchantSwitchToggle] = useState<boolean>(
        false
    )

    const handleSetActiveMerchant = (merchant: ActiveMerchant) => {
        uiState.setActiveMerchant(+merchant.id)
        merchant.name
            ? setSelectedMerchant(merchant.name)
            : setSelectedMerchant('All Merchant Accounts')

        merchant.id
            ? setSelectedMerchantId(merchant.id)
            : setSelectedMerchantId(`${uiState.whoami?.merchant?.id.toString() ?? '1'}`)

        if (!merchant.id) {
            localStorage.removeItem('selectedMerchant')
            localStorage.removeItem('selectedMerchantId')
        }
    }

    // Needed to reset merchant switcher - when switching to/from "loginAs User"
    useEffect(() => {
        selectedMerchantId &&
            merchantSwitchToggle &&
            handleSetActiveMerchant({ name: '', id: uiState.whoami?.merchant?.id.toString() ?? '1' })
        setMerchantSwitchToggle(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uiState.whoami?.merchant?.id])

    return (
        <ActiveMerchantContext.Provider
            value={{
                id: selectedMerchantId
                    ? selectedMerchantId
                    : `${
                          uiState.activeMerchant?.id ??
                          uiState.whoami?.merchant?.id ??
                          ''
                      }`,
                name: selectedMerchant
                    ? selectedMerchant
                    : uiState.activeMerchant?.business_name ??
                      'All Merchant Accounts',
                userDefaultMerchant:
                    uiState.whoami?.merchant?.id.toString() ?? '1',
                setActiveMerchant: handleSetActiveMerchant,
                isRootMerchant: uiState.activeMerchant?.isRootMerchant ?? false,
                setMerchantSwitchToggle,
            }}
        >
            {children}
        </ActiveMerchantContext.Provider>
    )
}

export default ActiveMerchantContext

import React, { useMemo } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Popover, Button, Typography, TextField } from '@mui/material'
import { clientTheme } from 'theme-exports'
import { SearchableSelect } from 'components'
import idDirectory from './idAttributes'

export type IFilterOption = {
    id: number
    name?: string
    value?: number
} & Record<string, unknown>

export interface IFilterFormField {
    label: string
    accessor: string
    inputType: 'text' | 'number' | 'date' | 'select'
    selectList?: IFilterOption[]
}

type TAGDataGridActionsFilterInputs = {
    [key: string]: any
}

interface IAGDataGridActionsFilterProps {
    anchorEl: null | HTMLElement
    handleCloseDataGridFilter: () => void
    filterFormFields: IFilterFormField[]
    filterValues: { [key: string]: string | number | undefined }
    setFilterValues: (filter: {
        [key: string]: string | number | undefined
    }) => void
    testId: string
}

export const AGDataGridActionsFilter = ({
    anchorEl,
    handleCloseDataGridFilter,
    filterFormFields,
    filterValues,
    setFilterValues,
    testId,
}: IAGDataGridActionsFilterProps): React.ReactElement => {
    const defaultValues = useMemo<TAGDataGridActionsFilterInputs>(() => {
        const formattedDefaultValues: { [key: string]: any } = {}
        filterFormFields.forEach((field: IFilterFormField) => {
            if (filterValues[field.accessor]) {
                formattedDefaultValues[field.accessor] =
                    filterValues[field.accessor]
            } else {
                formattedDefaultValues[field.accessor] = field.selectList
                    ?.length
                    ? field.selectList[0]?.value
                    : ''
            }
        })
        return formattedDefaultValues
    }, [filterValues, filterFormFields])

    const {
        reset,
        watch,
        register,
        setValue,
        handleSubmit,
    } = useForm<TAGDataGridActionsFilterInputs>({
        defaultValues: defaultValues,
        values: defaultValues,
        mode: 'onChange',
    })

    const onSubmit: SubmitHandler<TAGDataGridActionsFilterInputs> = (
        values
    ) => {
        const updatedValuesObj: {
            [key: string]: any
        } = {}

        // Need to remove 'empty string' values from request call
        for (const key in values) {
            if (values[key]) {
                updatedValuesObj[key] = values[key]
            }
        }
        setFilterValues(updatedValuesObj)
        handleCloseDataGridFilter()
    }

    const handleSelectedValue = (fieldName: any, value: any) => {
        setValue(fieldName, value, { shouldValidate: true, shouldDirty: true })
    }

    const handleClearFilter = () => {
        filterFormFields.forEach((field: IFilterFormField) => {
            setValue(
                field.accessor,
                field.selectList?.length ? field.selectList[0]?.value : '',
                { shouldValidate: true, shouldDirty: true }
            )
        })
    }

    return (
        <Popover
            id={`${idDirectory.popoverFilter}-${testId}`}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => {
                handleCloseDataGridFilter()
                reset(defaultValues)
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div
                id={`${idDirectory.divFilters}-${testId}`}
                className={'emp-agDataGridActionsFilter-container'}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div
                        className={
                            'emp-agDataGridActionsFilter-fieldsContainer'
                        }
                        style={{
                            gridTemplateColumns:
                                filterFormFields.length >= 4
                                    ? 'repeat(4, 1fr)'
                                    : 'repeat(2, 1fr)',
                        }}
                    >
                        {filterFormFields.map(
                            (field: IFilterFormField, idx: number) => {
                                let selectedValue = ''
                                if (field.inputType === 'select') {
                                    selectedValue =
                                        field?.selectList?.find(
                                            (option) =>
                                                option.value ===
                                                watch(field.accessor)
                                        )?.name ?? ''
                                }
                                return (
                                    <div key={`key-${field.accessor}-${idx}`}>
                                        <Typography
                                            className={
                                                'emp-agDataGridActionsFilter-label'
                                            }
                                            style={{
                                                fontSize:
                                                    clientTheme.typography.body1
                                                        .fontSize,
                                            }}
                                        >
                                            {field.label}:
                                        </Typography>
                                        {['text', 'number', 'date'].includes(
                                            field.inputType
                                        ) && (
                                            <TextField
                                                {...register(field.accessor)}
                                                name={field.accessor}
                                                fullWidth
                                                variant={'outlined'}
                                                size="small"
                                                className={
                                                    'emp-agDataGridActionsFilter-textInputField'
                                                }
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        color:
                                                            clientTheme
                                                                .typography
                                                                .fontColor
                                                                .primaryText,
                                                    },
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius:
                                                            clientTheme
                                                                .selectionBox
                                                                .borderRadius,
                                                    },
                                                }}
                                                type={field.inputType}
                                                id={`${idDirectory.inputFilter}-${testId}`}
                                            />
                                        )}
                                        {field.inputType === 'select' && (
                                            <div>
                                                <SearchableSelect
                                                    value={selectedValue}
                                                    onValueChange={(e) => {
                                                        handleSelectedValue(
                                                            field.accessor,
                                                            e?.value
                                                        )
                                                    }}
                                                    options={
                                                        field?.selectList ?? []
                                                    }
                                                    accessor={'name'}
                                                    hideSearch
                                                    testId={`${testId}-${field.accessor}`}
                                                    isPaginate={false}
                                                    selectFontSize={
                                                        clientTheme.typography
                                                            .body1.fontSize
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                )
                            }
                        )}
                    </div>
                    <div className={'emp-agDataGridActionsFilter-btnGrp'}>
                        <Button
                            variant="contained"
                            onClick={handleClearFilter}
                            id={`${idDirectory.btnFilterClear}-${testId}`}
                            sx={clientTheme.buttons.defaultButton.style}
                        >
                            Clear
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            id={`${idDirectory.btnFilterSearch}-${testId}`}
                        >
                            Filter
                        </Button>
                    </div>
                </form>
            </div>
        </Popover>
    )
}

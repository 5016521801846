import React, { useState } from 'react'
import { Typography } from '@mui/material'
import { AccentArea, UpdateMerchant, AlertSnackbar } from 'components'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import View from '../View'
// import { useParams } from 'react-router'
// import UsersApi from '../../api/UsersApi'
import { Deflector } from '../MerchantHierarchy/MerchantHierarchy.vm'
import useAddEditMerchant from './AddEditMerchant.vm'

interface IAddEditMerchantProps {
    topLevelMerchantID: number
    merchantId: number
    isEdit: boolean
    onClose: (isEdit: boolean, type?: 'mids' | 'merchant') => void
    forceReload: () => void
    merchantDetails: any
    merchantParentName: string
    availableDeflectors: Deflector[]
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    setVerifiRDRIsOpen: (val: boolean) => void
}

/**
 *
 * @desc -
 * @returns - AddEditMerchant page
 */
export const AddEditMerchant = ({
    topLevelMerchantID,
    merchantId,
    isEdit = false,
    onClose = () => {},
    forceReload,
    merchantDetails,
    merchantParentName,
    availableDeflectors,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    setVerifiRDRIsOpen,
}: IAddEditMerchantProps): React.ReactElement => {
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const { inputUtils, defaultDeflectorValues } = useAddEditMerchant({
        isEdit,
        merchantId,
        forceReload,
        merchantDetails,
        merchantParentName,
        setAlertSnackbarOpen,
        setAlertSnackbarProps,
        // For AlertSnackbar on main view.
        setAlertSnackbarMainProps,
        setAlertSnackbarMainOpen,
    })

    const {
        register,
        watch,
        errors,
        isDirty,
        isValid,
        isSubmitting,
        handleOnChangeValue,
        submitForm,
        reset,
        countryCodes,
    } = inputUtils

    const handleOnClose = () => {
        onClose(isEdit)
        reset()
    }

    return (
        <View
            title="Add/Edit Merchant"
            breadcrumb={''}
            className={'emp-addEditMerchant-root'}
        >
            <AccentArea
                title={
                    <Typography className={'emp-addEditMerchant-heading'}>
                        {isEdit ? 'Edit Merchant' : 'Add Merchant'}
                    </Typography>
                }
            >
                <UpdateMerchant
                    isEdit={isEdit}
                    onClose={handleOnClose}
                    merchantId={merchantId}
                    countryCodes={countryCodes}
                    values={watch()}
                    errors={errors}
                    register={register}
                    isDirty={isDirty}
                    isValid={isValid}
                    handleOnChangeValue={handleOnChangeValue}
                    isSubmitting={isSubmitting}
                    handleSubmit={submitForm}
                    topLevelMerchantID={topLevelMerchantID}
                    availableDeflectors={availableDeflectors}
                    defaultDeflectorValues={defaultDeflectorValues}
                    setVerifiRDRIsOpen={setVerifiRDRIsOpen}
                />
            </AccentArea>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </View>
    )
}

export default AddEditMerchant

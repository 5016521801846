import { useEffect } from 'react'
import { useActiveMerchant } from 'components'
import {
    useSWRActions,
    SWRActions,
    useCaseActions,
    useSwrData,
    useParams,
    CaseData,
} from 'hooks/cb-api'

interface QCReviewVM {
    cases: CaseData[]
    swrActions: SWRActions
    params: { [key: string]: any }
}

const useQCReview = (): QCReviewVM => {
    const { id: merchantId } = useActiveMerchant()

    const { params, setParams, setQueryParams, reset } = useParams({
        sort_order: 'desc',
    })

    const endpointConfig = {
        paramInUrl: false,
        url: 'cm/cases',
        id: {
            key: 'client_id',
            value: merchantId,
        },
    }

    const qcReviewData = useSwrData(endpointConfig, params)
    const paramSetter = { setParams, setQueryParams, params, reset }

    const swrActions = useSWRActions(qcReviewData, paramSetter)
    const caseActions = useCaseActions(qcReviewData, paramSetter)

    const { caseNetworkActions } = caseActions

    const extendedSwrActions = { ...swrActions, ...caseActions }

    const { setCaseStatusFiltlerGroup } = caseNetworkActions

    useEffect(() => {
        //TODO Change to qc_review filter
        setCaseStatusFiltlerGroup('qc_review')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        cases: qcReviewData.data || [],
        swrActions: extendedSwrActions,
        params,
    }
}

export default useQCReview

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Typography, Button } from '@mui/material'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import View from 'views/View'
import useIsMounted from 'hooks/utils/useIsMounted'
import { AccentArea, LoadingIndicator } from 'components'
import AlertDialog, { alertDialogContentProps } from 'components/AlertDialog'
import idDirectory from './idAttributes'

const Downloads: React.FC = () => {
    const { search } = useLocation()
    const history = useHistory()
    const { isMounted } = useIsMounted()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [alertDialogOpen, setAlertDialogOpen] = React.useState<boolean>(false)
    const [
        alertDialogProps,
        setAlertDialogProps,
    ] = React.useState<alertDialogContentProps>({})

    const closeAlertDialog = () => {
        setAlertDialogOpen(false)
        history.push('/')
    }

    const getDownloadFile = async () => {
        setIsLoading(true)
        try {
            const queryParameters = new URLSearchParams(search)
            const locHash = queryParameters.get('loc')

            if (!locHash) {
                setAlertDialogProps({
                    title: 'Error',
                    message: `Invalid url provided.`,
                    intent: 'error',
                })
                setAlertDialogOpen(true)
                return
            }

            const response = await axios.get('/docs/downloadable', {
                params: {
                    loc: locHash,
                },
            })
            const { data } = response

            let a = document.createElement('a')
            a.href = data.url
            a.setAttribute('download', data?.filename ?? '')
            document.body.appendChild(a)
            a.click()
            a.remove()

            window.history.replaceState({}, '', '/download')
        } catch (error) {
            const { response } = error as any

            setAlertDialogProps({
                title: 'Error',
                message:
                    response.data.error ??
                    `An error occurred. Please try again later.`,
                intent: 'error',
            })
            setAlertDialogOpen(true)
        } finally {
            if (isMounted.current) setIsLoading(false)
        }
    }

    useEffect(() => {
        getDownloadFile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <View title="Downloads" breadcrumb="Downloads" testId="downloads">
            <AccentArea
                testId="downloads"
                hasHeaderDivider={false}
                className={'emp-downloads-accentArea'}
            >
                <div
                    className={'emp-downloads-mainContainer'}
                    id={idDirectory.divMain}
                >
                    {isLoading ? (
                        <div>
                            <Typography
                                variant="h5"
                                style={{ fontWeight: 600 }}
                            >
                                Please wait, while we download your document.
                            </Typography>
                            <div
                                id={idDirectory.divLoading}
                                className={'emp-downloads-loadingContainer'}
                            >
                                <LoadingIndicator />
                            </div>
                        </div>
                    ) : (
                        <Button
                            variant="contained"
                            color="secondary"
                            id={idDirectory.btnReturn}
                            onClick={() => history.push('/')}
                        >
                            Return to Home
                        </Button>
                    )}
                </div>
            </AccentArea>
            <AlertDialog
                content={alertDialogProps}
                open={alertDialogOpen}
                onClose={closeAlertDialog}
            />
        </View>
    )
}

export default Downloads

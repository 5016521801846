import EntFeature from 'models/EntFeature'
import { useUiStateContext } from 'context/UiState/UiStateContext'

const useSidebarNav = () => {
    const uiState = useUiStateContext()
    return {
        allowedResource: (role: EntFeature | EntFeature[]) =>
            uiState.whoami?.hasRole(role) ?? false,
    }
}

export default useSidebarNav

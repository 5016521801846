import axios from 'axios'
import { useActiveMerchant } from 'components/ActiveMerchantContext'
import { DownloadParams } from 'lib/Cases'
import { useState } from 'react'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'

export type Download = (
    params: DownloadParams,
    isSinglePage?: boolean
) => Promise<unknown>

interface DownloadInstance {
    download: Download
    isLoadingDownloadExportAll: boolean
    isLoadingDownloadCsv: boolean
    isEmailExport: boolean
    closeEmailExport: () => void
    downloadXlsx: Download
    isLoadingDownloadXlsx: boolean
    isLoadingDownloadXlsxAll: boolean
    downloadError: boolean
    downloadErrorString: string
    closeDownloadError: () => void
}

export type UseDownload = (
    title: string,
    headers: { accessor: string; label: string | undefined }[]
) => DownloadInstance

export const useCases: UseDownload = (title, headers) => {
    const { id: clientId } = useActiveMerchant()
    const [isEmailExport, setIsEmailExport] = useState(false)
    const [downloadError, setDownloadError] = useState<boolean>(false)
    const [downloadErrorString, setDownloadErrorString] = useState<string>('')
    const [isLoadingDownloadXlsx, setIsLoadingDownloadXlsx] = useState<boolean>(
        false
    )
    const [
        isLoadingDownloadXlsxAll,
        setIsLoadingDownloadXlsxAll,
    ] = useState<boolean>(false)
    const [
        isLoadingDownloadExportAll,
        setIsLoadingDownloadExportAll,
    ] = useState<boolean>(false)
    const [isLoadingDownloadCsv, setIsLoadingDownloadCsv] = useState<boolean>(
        false
    )

    /*  TODO - The 6 items below are an ugly hack to quickly accomodate:
        https://chargebacks911.atlassian.net/browse/EXT-5855.
        This should be removed from the FE ASAP, as soon as the BE supports this.
    */
    const { client } = useFeatureToggle('CLIENT')
    const { user } = useAuthedUser()
    const roleAlias = user?.role?.alias ?? ''
    const shouldHideSomeJPMCColumns = client === 'jpmc' && ['merchant_admin', 'merchant_user'].includes(roleAlias)
    const jpmorganMerchantHeadersToHide = [
        'processor'
    ]
    const tempHeaders = !shouldHideSomeJPMCColumns
        ? headers
        : headers.filter(
              (i) => !jpmorganMerchantHeadersToHide.includes(i.accessor)
          )

    const download: Download = async (
        P: DownloadParams,
        isExportAll?: boolean
    ) => {
        isExportAll
            ? setIsLoadingDownloadExportAll(true)
            : setIsLoadingDownloadCsv(true)

        const accessorHeaders = tempHeaders.map((header) => header.accessor)
        const labelHeaders = tempHeaders
            .filter((header) => header.label)
            .map((header) => `${header.accessor}:${header.label}`)

        if (P) {
            Object.keys(P).forEach((key) => {
                if (P[key] === '') delete P[key]
            })
        }

        await axios
            .get(`cm/cases.csv`, {
                responseType: 'blob',
                params: {
                    ...P,
                    client_id: clientId,
                    headers: accessorHeaders,
                    header_labels: labelHeaders,
                },
                headers: {
                    'Content-Type':
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
            })
            .then((r) => {
                if (
                    [
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'text/csv',
                    ].includes(r.data.type) ||
                    !isExportAll
                ) {
                    setIsEmailExport(false)
                    const url = window.URL.createObjectURL(new Blob([r.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${title}.csv`)
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                } else {
                    setIsEmailExport(true)
                }
            })
            .catch(async (err) => {
                let responseObj = await err.response.data.text()
                setDownloadError(true)
                if (responseObj)
                    return setDownloadErrorString(
                        JSON.parse(responseObj).message
                    )
                setDownloadErrorString('An unknown error has occurred.')
            })
            .finally(() => {
                isExportAll
                    ? setIsLoadingDownloadExportAll(false)
                    : setIsLoadingDownloadCsv(false)
            })
    }

    const downloadXlsx: Download = async (
        P: DownloadParams,
        isSinglePage?: boolean
    ) => {
        isSinglePage
            ? setIsLoadingDownloadXlsx(true)
            : setIsLoadingDownloadXlsxAll(true)

        const accessorHeaders = tempHeaders.map((header) => header.accessor)
        const labelHeaders = tempHeaders
            .filter((header) => header.label)
            .map((header) => `${header.accessor}:${header.label}`)

        // Let's filter our params here, to prevent sending keys with no value to export endpoints.
        if (P) {
            Object.keys(P).forEach((key) => {
                if (P[key] === '') delete P[key]
            })
        }

        await axios
            .get(`/cm/cases.xlsx`, {
                responseType: 'blob',
                params: {
                    ...P,
                    client_id: clientId,
                    headers: accessorHeaders,
                    header_labels: labelHeaders,
                },
                headers: {
                    'Content-Type':
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
            })
            .then((r) => {
                if (
                    [
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'text/csv',
                    ].includes(r.data.type) ||
                    isSinglePage
                ) {
                    setIsEmailExport(false)

                    const url = window.URL.createObjectURL(new Blob([r.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${title}.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                } else {
                    setIsEmailExport(true)
                }
            })
            .catch(async (err) => {
                // Why Axios? WHY make this a promise, in the case of exceptions??
                let responseObj = await err.response.data.text()
                setDownloadError(true)
                if (responseObj)
                    return setDownloadErrorString(
                        JSON.parse(responseObj).message
                    )
                setDownloadErrorString('An unknown error has occurred.')
            })
            .finally(() => {
                isSinglePage
                    ? setIsLoadingDownloadXlsx(false)
                    : setIsLoadingDownloadXlsxAll(false)
            })
        return
    }
    return {
        download,
        isLoadingDownloadExportAll,
        isLoadingDownloadCsv,
        isEmailExport,
        closeEmailExport: () => setIsEmailExport(false),
        downloadXlsx,
        isLoadingDownloadXlsx,
        isLoadingDownloadXlsxAll,
        downloadError,
        downloadErrorString,
        closeDownloadError: () => setDownloadError(false),
    }
}

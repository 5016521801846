import React from 'react'
import {
    ButtonGroup,
    IconButton,
    Menu,
    MenuItem,
    useMediaQuery,
} from '@mui/material'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import theme from 'theme'

export interface CollapseButtonGroupProps {
    /** MUI style breakpoints */
    breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    /** up to and including the index of children to show.  */
    ignoreFirst?: number
    /** rest props for button group */
    [key: string]: any
}

/**
 * Button group that collapses into a menu popout on smaller screens. When
 * provided with a breakpoint, children will render as a popout menu if the
 * screen is smaller. A button group will bre rendered otherwise.
 *
 * @param props - {@link ExpandableButtonGroupProps}
 */
export const CollapseButtonGroup: React.FC<CollapseButtonGroupProps> = ({
    children,
    breakpoint = 'md',
    ignoreFirst = 0,
    ...rest
}) => {
    /** breakpoint to determine whether to render a button group or menu */
    const excededBreakpoint = useMediaQuery(theme.breakpoints.up(breakpoint))
    /** Ancher element for menu popout */
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (): void => {
        setAnchorEl(null)
    }

    if (excededBreakpoint) {
        return <ButtonGroup {...rest}>{children}</ButtonGroup>
    }
    return (
        // eslint-disable-next-line react/jsx-fragments
        <React.Fragment>
            <IconButton
                className={'emp-collapseButtonGroup-menu'}
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
            >
                <Icon icon={faEllipsisV} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                keepMounted
            >
                {React.Children.map(
                    children,
                    (child, idx) =>
                        idx + 1 > ignoreFirst &&
                        child && (
                            <MenuItem onClick={handleClose}>{child}</MenuItem>
                        )
                )}
            </Menu>
        </React.Fragment>
    )
}

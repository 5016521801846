/**
 * @description Directory for ID Attributes on Upload Case Updater.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    dialogModal: `uploadCaseUpdater-dialog-modal`,
    divRoot: `uploadCaseUpdater-div-root`,
    divUploadFile: `uploadCaseUpdater-div-uploadFile`,
    divFileInfo: `uploadCaseUpdater-div-fileInfo`,
    divFileList: `uploadCaseUpdater-div-fileList`,
    btnUpload: `uploadCaseUpdater-btn-upload`,
    divUpload: `uploadCaseUpdater-div-upload`,
    divTitle: `uploadCaseUpdater-div-title`,
    btnCSVTemplate: `uploadCaseUpdater-btn-csvTemplate`,
}

export default idDirectory

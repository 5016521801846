import { colors } from '@mui/material'
// import customThemeConfig from './client_theme/theme_config.json'
import { clientTheme } from 'theme-exports'
// Method for extension of the basic @material-ui theme
declare module '@mui/material/styles/createPalette' {
    interface Palette {
        sidebar: {
            primary: string
            secondary: string
            active: string
            hover: {
                primary: string
                secondary: string
            }
        }
    }
    interface PaletteOptions {
        sidebar: {
            primary: string
            secondary: string
            active: string
            hover: {
                primary: string
                secondary: string
            }
        }
    }
    // interface ThemeConfig {
    //     topBar: {
    //         background: string
    //         contrastText: string
    //     }
    //     footer: {
    //         background: string
    //         contrastText: string
    //     }
    // }
}

const white = '#FFFFFF'
const black = '#000000'

export default {
    white,
    black,
    primary: {
        contrastText: white,
        dark: clientTheme.primaryDark,
        main: clientTheme.primary,
        light: clientTheme.primaryLight,
    },
    secondary: {
        contrastText: white, //'#FFFFFF'
        dark: clientTheme.secondaryDark,
        main: clientTheme.secondary,
        light: clientTheme.secondaryLight,
    },
    success: {
        contrastText: white, //'#FFFFFF'
        dark: colors.green[900], //'#1b5e20'
        main: colors.green[600], //'#43a047'
        light: colors.green[400], //'#66bb6a'
    },
    info: {
        contrastText: white, //'#FFFFFF'
        dark: colors.blue[900], //'#0d47a1'
        main: colors.blue[600], //'#1e88e5'
        light: colors.blue[400], //'#42a5f5'
    },
    warning: {
        contrastText: white, //'#FFFFFF'
        dark: colors.orange[900], //'#e65100'
        main: colors.orange[600], //'#fb8c00'
        light: colors.orange[400], //'#ffa726'
    },
    error: {
        contrastText: white, //'#FFFFFF'
        dark: colors.red[900], //'#b71c1c'
        main: colors.red[600], //'#e53935'
        light: colors.red[400], //'#ef5350'
    },
    text: {
        primary: clientTheme.typography.fontColor.primaryText
            ? clientTheme.typography.fontColor.primaryText
            : colors.blueGrey[900], //'#263238'
        secondary: clientTheme.typography.fontColor.secondaryText
            ? clientTheme.typography.fontColor.secondaryText
            : colors.blueGrey[600], //'#546e7a'
        link: colors.blue[600], //'#1e88e5'
    },
    background: {
        default: clientTheme.mainLayout.backgroundColor,
        paper: white,
        bg: '#fff',
    },
    icon: colors.blueGrey[600], //'#546e7a'
    divider: colors.grey[200], //'#eeeeee'
    textAltGray: colors.grey[500], //'#9e9e9e'
    topBar: colors.grey[500], //'#9e9e9e'
    // customThemeConfig,
    sidebar: {
        primary: clientTheme.sidebar.backgroundColor,
        secondary: '#1f2024',
        active: 'red',
        hover: {
            primary: '#3f4143',
            secondary: '#D3ECF9',
        },
    },
}

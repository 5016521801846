/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
    Drawer,
    Dialog,
    Grid,
    TextField,
    Button,
    IconButton,
    InputAdornment,
    Tooltip,
    Box,
    Select,
    MenuItem
} from '@mui/material'
import Icon from 'components/Icon'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import View from 'views/View'
import idDirectory from './idAttributes'
import {
    MerchantEdit,
    MerchantMIDSDetails,
    AccentArea,
    VerifiRDRConfig,
} from 'components'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import AddEditMerchant from 'views/AddEditMerchant/AddEditMerchant'
import { MerchantUsersGrid } from './grids/MerchantUsersGrid'
import useMerchantHierarchy from './MerchantHierarchy.vm'
import { LoadingNode, MerchantNode } from './tree/MerchantNode'
import { Row } from 'react-table'
import AddEditMid from 'views/AddEditMid'
import { withRequiredRole } from 'components/useRequireRole'
import EntFeature from 'models/EntFeature'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { clientTheme } from 'theme-exports'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import { Merchant } from 'lib/Merchants'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { useHistory } from 'react-router'
import axios from 'axios'

interface OperatorParams {
    rows: Row<{ id: number }>[]
    selectedRows: string[]
}
type ContextMenuItem = {
    value: string
    operator: (params: OperatorParams) => void
}

export interface MerchantUserInfo {
    role: string
    midsAssigned: string | number
    merchantsAssigned: string | number
    firstName: string
    lastName: string
    username: string
    userStatus: boolean
    userPayload: {
        userId: string | number
        mids: string[] | number[]
    }
}

export interface RemoveAccessAction {
    title: string
    icon: any
    message?: string
    mid: string[] | number[]
    merchantId: number
    userId: string | number
}

/**
 * The MerchantHierarchy view of merchant-parent-child relationship maniuplation and setting which users can interact with which mids
 */
const MerchantHierarchy: React.FC = ({ ...props }: any) => {
    const { user } = useAuthedUser()
    const uiState = useUiStateContext()
    const history = useHistory()

    const isReadOnly = user?.is_read_only
    const isHiddenViewDetails = !uiState.whoami?.hasRole(
        EntFeature.MS_VIEW_DETAILS
    )
    const isReadOnlyEditMerchant = !uiState.whoami?.hasRole(
        EntFeature.EDIT_MERCHANT
    )
    const isReadOnlyEditMid = !uiState.whoami?.hasRole(EntFeature.EDIT_MID)

    const { style: formVariantStyle = 'outlined' } = useFeatureToggle(
        'SEARCH_BAR'
    )
    const { enabled: showAGSwitchEnabled } = useFeatureToggle(
        'SHOW_AG_SWITCHER'
    )
    const { DEFLECTORS, SELECT_SEARCH_TYPE } = useFeatureToggle(
        'MERCHANT_HIERARCHY'
    )
    const [verifiRDRisOpen, setVerifiRDRIsOpen] = useState<boolean>(false)

    const [alertSnackbarMainOpen, setAlertSnackbarMainOpen] = useState<boolean>(
        false
    )
    const [
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
    ] = useState<alertSnackbarContentProps>({})

    const {
        merchants,
        rootMerchant,
        isMerchantsLoading,
        // merchantSearchValue,
        midDetails,
        users,
        activeNodeId,
        setMerchantSearchValue,
        setActiveNodeId,
        setOwnParentMerchant,
        setChildParentMerchant,
        selectMid,
        selectMerchant,
        assignAllMerchantsToUser,
        assignAllMidsToUser,
        removeAllMerchantsFromUser,
        removeAllMidsFromUser,
        useMidsForMerchant,
        setForceReload,
        setStart,
        pagination,
        activeMerchantId,
        swrActions,
        wasMidTileClicked,
        midsMerchantId,
        setWasMidTileClickReset,
        wasMidTileClickReset,
        availableDeflectors,
        selectSearchType,
        setSelectSearchType,
    } = useMerchantHierarchy(props)

    const {
        isLoading,
        setSearch,
        rowsPerPage,
        setRowsPerPage,
        setPage,
        total,
        page,
        params,
        invalidate: rehydrateView,
    } = swrActions

    /**
     *
     * @param params - params from the context menu operator function
     * @param asyncfn - per userId function to run
     *
     */
    const selectedRowsBulkUserOp = (
        params: OperatorParams,
        asyncfn: (userId: number) => Promise<void>
    ) =>
        Promise.all(
            params.rows
                .filter((_, idx) =>
                    params.selectedRows.includes(idx.toString())
                )
                .map((i) => asyncfn(i.original.id))
        )

    const contextMenuItems: ContextMenuItem[] = [
        {
            value: `Assign to User's (All Merchants)`,
            operator: (params) =>
                selectedRowsBulkUserOp(params, assignAllMerchantsToUser),
        },
        {
            value: `Remove Permissions (All Merchants)`,
            operator: (params: any) =>
                selectedRowsBulkUserOp(params, removeAllMerchantsFromUser),
        },
        {
            value: `Assign to Merchant (All MIDS)`,
            operator: (params) =>
                selectedRowsBulkUserOp(params, assignAllMidsToUser),
        },
        {
            value: `Remove Permissions (All MIDS)`,
            operator: (params) =>
                selectedRowsBulkUserOp(params, removeAllMidsFromUser),
        },
    ]

    // What is the actual numeric ID?
    const activeNodeIdActual = +/[0-9]+$/.exec(activeNodeId)![0] || 1
    const isRootMerchantSelected =
        (rootMerchant && activeNodeId === `merchant-${rootMerchant.id}`) ?? true

    const [activeNodeType, setActiveNodeType] = useState<string>('merchant')
    const [
        editMerchantDrawerOpen,
        setEditMerchantDrawerOpen,
    ] = useState<boolean>(false)
    const [merchantDrawerIsEdit, setMerchantDrawerIsEdit] = useState<boolean>(
        false
    )
    const [drawerType, setDrawerType] = useState<string>('merchant')
    const [merchantName, setMerchantName] = useState<string>('')
    const [merchantDetails, setMerchantDetails] = useState<Merchant | null>(
        rootMerchant
    )
    const [merchantParentName, setMerchantParentName] = useState<string>('')
    const [searchInputValue, setSearchInputValue] = useState<string>('')

    const handleAddEditMerchantMids = (
        isEdit: boolean,
        type?: 'mids' | 'merchant'
    ) => {
        if (type) {
            isEdit && setActiveNodeType(type)
            setDrawerType(type)
            setMerchantDrawerIsEdit(isEdit)
        }
        setEditMerchantDrawerOpen(!editMerchantDrawerOpen)
    }

    useEffect(() => {
        setActiveNodeType(activeNodeId.startsWith('mid') ? 'mids' : 'merchant')
        setDrawerType(activeNodeId.startsWith('mid') ? 'mids' : 'merchant')
    }, [activeNodeId])

    const handleMidsTileClicked = React.useCallback(
        (obj: any) => {
            for (let child of obj) {
                const hasChildren =
                    child.children &&
                    Array.isArray(child.children) &&
                    child.children.length > 0
                child.expanded = hasChildren && midsMerchantId !== child.id
                if (midsMerchantId === child.id) {
                    child.expanded = true
                    child.makeActive = true
                }
                if (hasChildren && midsMerchantId !== child.id) {
                    handleMidsTileClicked(child.children)
                }
            }
        },
        [midsMerchantId]
    )

    useEffect(() => {
        if (wasMidTileClicked && merchants.length) {
            handleMidsTileClicked(merchants)
        }
    }, [wasMidTileClicked, merchants, handleMidsTileClicked])

    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))

    // This is part of a janky reload for merchant tree, until we have a store.
    const forceReload = () => {
        setEditMerchantDrawerOpen(false)
        selectMerchant(1)
        setActiveNodeId('merchant-1')
        //@ts-ignore
        setForceReload((prev) => !prev)
    }

    const [deflectorsLoading, setDeflectorsLoading] = useState<boolean>(
        DEFLECTORS.enabled
    )
    useEffect(() => {
        // This should absolutely not be necessary, but we're forced to do this due to how the service(s) involved were done.
        // I would very much like to get this information directly into the responses for merchant list, where it belongs.
        if (
            !activeNodeId.startsWith('mid') &&
            merchantDetails &&
            !merchantDetails?.deflectors &&
            activeNodeIdActual !== 1
        ) {
            setDeflectorsLoading(true)
            axios
                .get(`/cm/merchants/${activeNodeIdActual}`)
                .then(({ data }) => {
                    const detailsWithDeflectors = {
                        ...data,
                        deflectors: data?.deflectors || [],
                    }
                    return setMerchantDetails(detailsWithDeflectors)
                })
                .finally(() => {
                    setDeflectorsLoading(false)
                })
        }
        if (
            DEFLECTORS.enabled &&
            activeNodeIdActual === 1 &&
            rootMerchant?.id &&
            !merchantDetails?.deflectorsLoaded
        ) {
            setDeflectorsLoading(true)
            axios
                .get(`/cm/merchants/1`)
                .then(({ data }) => {
                    const detailsWithDeflectors = {
                        ...rootMerchant,
                        deflectors: data?.deflectors || [],
                        deflectorsLoaded: true,
                    }
                    setMerchantDetails(detailsWithDeflectors)
                })
                .finally(() => {
                    setDeflectorsLoading(false)
                })
        }
        if (
            !DEFLECTORS.enabled &&
            activeNodeIdActual === 1 &&
            rootMerchant?.id &&
            !merchantDetails?.deflectorsLoaded
        ) {
            const detailsWithDeflectors = {
                ...rootMerchant,
                deflectors: [],
                deflectorsLoaded: true,
            }
            setMerchantDetails(detailsWithDeflectors)
        }
    }, [
        merchantDetails,
        rootMerchant,
        DEFLECTORS.enabled,
        activeNodeId,
        activeNodeIdActual,
    ])

    return (
        <>
            <View
                title="Merchant Hierarchy"
                breadcrumb="Merchant Hierarchy"
                testId="merchantSetting"
                HeaderInterior={
                    <>
                        {showAGSwitchEnabled && (
                            <Tooltip
                                title={'Try our new merchant settings view'}
                            >
                                <Button
                                    onClick={() => {
                                        history.push({
                                            pathname: `/merchant-settings`,
                                        })
                                    }}
                                    className={
                                        'emp-switchArrowBtn'
                                    }
                                    sx={{
                                        ...clientTheme.buttons
                                            .textOrOutlinedButton.style,
                                    }}
                                >
                                    <div>
                                        <Icon
                                            className={`fa fa-right-left ${'emp-switchArrowIcon'}`}
                                        />
                                        <Icon className={'fa fa-table'} />
                                    </div>
                                </Button>
                            </Tooltip>
                        )}
                    </>
                }
            >
                <Grid container spacing={2} id={idDirectory.gridContainer}>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        id={`${idDirectory.gridContainer}-1`}
                    >
                        <AccentArea
                            title="Merchant Hierarchy"
                            testId="merchantSetting"
                            hideHeader
                        >
                            {wasMidTileClicked &&
                            merchants.length &&
                            !wasMidTileClickReset ? (
                                <Button
                                    className={
                                        'emp-reloadBtn'
                                    }
                                    color="secondary"
                                    size={'small'}
                                    fullWidth={true}
                                    variant={'contained'}
                                    name={'edit-mids-button'}
                                    onClick={() => {
                                        setWasMidTileClickReset(true)
                                        forceReload()
                                    }}
                                >
                                    Reload To View All Merchants
                                </Button>
                            ) : (
                                <div className={'emp-searchContainer'}>
                                    {SELECT_SEARCH_TYPE.enabled && (
                                        <Select
                                            id={idDirectory.selectSearchType}
                                            className={
                                                'emp-selectSearchContainer'
                                            }
                                            onChange={(e) => {
                                                setSelectSearchType(
                                                    e.target.value
                                                )
                                            }}
                                            value={selectSearchType}
                                            style={{
                                                borderRadius:
                                                    clientTheme.selectionBox
                                                        .borderRadius,
                                                height:
                                                    clientTheme.typography.body1
                                                        .fontSize === '14px'
                                                        ? '37px'
                                                        : '34px',
                                            }}
                                        >
                                            <MenuItem
                                                value={'merchant'}
                                                key={'key-merchant'}
                                                className={
                                                    'emp-selectSearchItem'
                                                }
                                            >
                                                Merchant
                                            </MenuItem>
                                            <MenuItem
                                                value={'mid'}
                                                key={'key-mid'}
                                                className={
                                                    'emp-selectSearchItem'
                                                }
                                            >
                                                MID
                                            </MenuItem>
                                        </Select>
                                    )}
                                    <TextField
                                        id={idDirectory.textFieldSearch}
                                        variant={formVariantStyle}
                                        size="small"
                                        placeholder="Search [press enter key to submit]"
                                        value={searchInputValue}
                                        onChange={(e) => {
                                            setSearchInputValue(e.target.value)
                                            if (!e.target.value.length) {
                                                setMerchantSearchValue(
                                                    e.target.value
                                                )
                                                setStart(0)
                                            }
                                        }}
                                        onKeyPress={(e) => {
                                            if (
                                                e.key.toLowerCase() ===
                                                    'enter' &&
                                                searchInputValue.length
                                            ) {
                                                setMerchantSearchValue(
                                                    searchInputValue
                                                )
                                                setStart(0)
                                            }
                                        }}
                                        fullWidth
                                        className={
                                            'emp-searchTextField'
                                        }
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                ...clientTheme.formFields
                                                    .formText.standard,
                                            },
                                        }}
                                        disabled={isMerchantsLoading}
                                        InputProps={
                                            formVariantStyle === 'standard'
                                                ? {
                                                      startAdornment: (
                                                          <InputAdornment position="start">
                                                              <FontAwesomeIcon
                                                                  icon={
                                                                      faSearch
                                                                  }
                                                                  className={
                                                                      'emp-searchIcon'
                                                                  }
                                                              />
                                                          </InputAdornment>
                                                      ),
                                                  }
                                                : {}
                                        }
                                    />
                                    <div
                                        style={{
                                            padding: `${
                                                formVariantStyle === 'standard'
                                                    ? '2px 0 2px 8px'
                                                    : '6px 0 6px 8px'
                                            }`,
                                        }}
                                    >
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSearchInputValue('')
                                                setMerchantSearchValue('')
                                                setStart(0)
                                            }}
                                            disabled={isMerchantsLoading}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </IconButton>
                                    </div>
                                </div>
                            )}
                            <Box
                                component="ul"
                                className={
                                    'emp-merchantTree'
                                }
                                id={idDirectory.ulMerchantTree}
                                sx={{
                                    '&>li.active': {
                                        color: 'white',
                                        backgroundColor:
                                            clientTheme.mainLayout.hover
                                                .backgroundColor,
                                    },
                                }}
                            >
                                {rootMerchant === null ? (
                                    <LoadingNode />
                                ) : (
                                    <MerchantNode
                                        key={rootMerchant.id}
                                        merchant={rootMerchant}
                                        merchants={[]}
                                        selectMid={selectMid}
                                        setChildParentMerchant={
                                            setChildParentMerchant
                                        }
                                        setOwnParentMerchant={
                                            setOwnParentMerchant
                                        }
                                        useMidsForMerchant={useMidsForMerchant}
                                        activeNodeId={activeNodeId}
                                        setActiveNodeId={setActiveNodeId}
                                        selectMerchant={selectMerchant}
                                        toggleMerchantDrawer={
                                            handleAddEditMerchantMids
                                        }
                                        setMerchantName={setMerchantName}
                                        open
                                        setStart={setStart}
                                        pagination={pagination}
                                        isPaginate={true}
                                        activeMerchantId={activeMerchantId}
                                        setMerchantDetails={setMerchantDetails}
                                        rowIndex={'root'}
                                    >
                                        {isMerchantsLoading ? (
                                            <LoadingNode />
                                        ) : (
                                            merchants.map((i, idx) => (
                                                <MerchantNode
                                                    key={i.id}
                                                    merchant={i}
                                                    merchants={merchants}
                                                    depth={2}
                                                    open={i.expanded}
                                                    selectMid={selectMid}
                                                    setChildParentMerchant={
                                                        setChildParentMerchant
                                                    }
                                                    setOwnParentMerchant={
                                                        setOwnParentMerchant
                                                    }
                                                    useMidsForMerchant={
                                                        useMidsForMerchant
                                                    }
                                                    activeNodeId={activeNodeId}
                                                    setActiveNodeId={
                                                        setActiveNodeId
                                                    }
                                                    selectMerchant={
                                                        selectMerchant
                                                    }
                                                    toggleMerchantDrawer={
                                                        handleAddEditMerchantMids
                                                    }
                                                    setMerchantName={
                                                        setMerchantName
                                                    }
                                                    activeMerchantId={
                                                        activeMerchantId
                                                    }
                                                    setMerchantDetails={
                                                        setMerchantDetails
                                                    }
                                                    rowIndex={idx}
                                                />
                                            ))
                                        )}
                                    </MerchantNode>
                                )}
                            </Box>
                        </AccentArea>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        id={`${idDirectory.gridContainer}-2`}
                    >
                        <Grid item style={{ position: 'sticky', top: '90px' }}>
                            {activeNodeType === 'merchant' ? (
                                <>
                                    {!isHiddenViewDetails ? (
                                        <Grid item>
                                            <AccentArea
                                                testId="merchantDetails"
                                                title={'Merchant Details'}
                                                headerAction={
                                                    !isReadOnlyEditMerchant && (
                                                        <Button
                                                            className={`${
                                                                !Boolean(
                                                                    isRootMerchantSelected ||
                                                                        isReadOnly
                                                                ) &&
                                                                'emp-editMerchantBtn'
                                                            }`}
                                                            size={'small'}
                                                            variant={
                                                                'contained'
                                                            }
                                                            name={
                                                                'edit-merchant-button'
                                                            }
                                                            onClick={() =>
                                                                handleAddEditMerchantMids(
                                                                    true,
                                                                    'merchant'
                                                                )
                                                            }
                                                            disabled={
                                                                isRootMerchantSelected ||
                                                                isReadOnly
                                                            }
                                                        >
                                                            Edit
                                                        </Button>
                                                    )
                                                }
                                            >
                                                <MerchantEdit
                                                    isRootMerchantSelected={
                                                        isRootMerchantSelected
                                                    }
                                                    merchantId={
                                                        activeNodeIdActual
                                                    }
                                                    merchant={merchantDetails}
                                                    merchantParentName={
                                                        merchantParentName
                                                    }
                                                    setMerchantParentName={
                                                        setMerchantParentName
                                                    }
                                                    availableDeflectors={
                                                        availableDeflectors
                                                    }
                                                    deflectorsLoading={
                                                        deflectorsLoading
                                                    }
                                                />
                                            </AccentArea>
                                        </Grid>
                                    ) : (
                                        <AccentArea
                                            testId="midSetupInstruction"
                                            title={'MID Setup'}
                                        >
                                            Please select a <strong>MID</strong>{' '}
                                            from the <strong>Merchant</strong>{' '}
                                            column to see additional
                                            information.
                                        </AccentArea>
                                    )}
                                </>
                            ) : (
                                <Grid item>
                                    <AccentArea
                                        testId="midDetails"
                                        title="MID Details"
                                        headerAction={
                                            !isReadOnlyEditMid && (
                                                <Button
                                                    className={`${
                                                        !isReadOnly &&
                                                        'emp-editMerchantBtn'
                                                    }`}
                                                    size={'small'}
                                                    variant={'contained'}
                                                    name={'edit-mids-button'}
                                                    onClick={() =>
                                                        handleAddEditMerchantMids(
                                                            true,
                                                            'mids'
                                                        )
                                                    }
                                                    disabled={isReadOnly}
                                                >
                                                    Edit
                                                </Button>
                                            )
                                        }
                                    >
                                        <MerchantMIDSDetails
                                            midId={activeNodeIdActual}
                                            midDetails={midDetails}
                                            availableDeflectors={
                                                availableDeflectors
                                            }
                                        />
                                    </AccentArea>
                                </Grid>
                            )}
                            <Grid item style={{ marginTop: '20px' }}>
                                <AccentArea
                                    testId="userList"
                                    title={
                                        rootMerchant?.business_name
                                            ? merchantName
                                                ? `[${merchantName}] User List`
                                                : `[${rootMerchant?.business_name}] User List`
                                            : 'User list'
                                    }
                                >
                                    <MerchantUsersGrid
                                        loading={isLoading}
                                        users={users}
                                        midId={midDetails?.id ?? 0}
                                        contextMenuItems={contextMenuItems}
                                        setForceReload={setForceReload}
                                        setSearch={setSearch}
                                        testId="merchantSetting"
                                        page={page}
                                        pageSize={rowsPerPage}
                                        onPageSizeChange={setRowsPerPage}
                                        onPageChange={setPage}
                                        total={total}
                                        params={params}
                                        rehydrateView={rehydrateView}
                                        merchantDetails={merchantDetails}
                                    />
                                </AccentArea>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </View>
            {isLargeScreen ? (
                <Dialog
                    onClose={() => handleAddEditMerchantMids(false)}
                    maxWidth={'md'}
                    fullWidth={true}
                    open={editMerchantDrawerOpen}
                >
                    {drawerType === 'merchant' ? (
                        <AddEditMerchant
                            topLevelMerchantID={rootMerchant?.id || 1}
                            merchantId={activeNodeIdActual}
                            isEdit={merchantDrawerIsEdit}
                            onClose={handleAddEditMerchantMids}
                            forceReload={forceReload}
                            merchantDetails={merchantDetails}
                            merchantParentName={merchantParentName}
                            availableDeflectors={availableDeflectors}
                            setAlertSnackbarMainProps={
                                setAlertSnackbarMainProps
                            }
                            setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                            setVerifiRDRIsOpen={setVerifiRDRIsOpen}
                        />
                    ) : (
                        <AddEditMid
                            merchantId={activeNodeIdActual}
                            isEdit={merchantDrawerIsEdit}
                            midDetails={midDetails}
                            toggleMidDrawer={handleAddEditMerchantMids}
                            forceReload={forceReload}
                            merchantName={merchantName}
                            availableDeflectors={availableDeflectors}
                            setAlertSnackbarMainProps={
                                setAlertSnackbarMainProps
                            }
                            setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                            setVerifiRDRIsOpen={setVerifiRDRIsOpen}
                        />
                    )}
                </Dialog>
            ) : (
                <Drawer
                    anchor={'right'}
                    open={editMerchantDrawerOpen}
                    onClose={() => handleAddEditMerchantMids(false)}
                >
                    {drawerType === 'merchant' ? (
                        <AddEditMerchant
                            topLevelMerchantID={rootMerchant?.id || 1}
                            merchantId={activeNodeIdActual}
                            isEdit={merchantDrawerIsEdit}
                            onClose={handleAddEditMerchantMids}
                            forceReload={forceReload}
                            merchantDetails={merchantDetails}
                            merchantParentName={merchantParentName}
                            availableDeflectors={availableDeflectors}
                            setAlertSnackbarMainProps={
                                setAlertSnackbarMainProps
                            }
                            setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                            setVerifiRDRIsOpen={setVerifiRDRIsOpen}
                        />
                    ) : (
                        <AddEditMid
                            merchantId={activeNodeIdActual}
                            isEdit={merchantDrawerIsEdit}
                            midDetails={midDetails}
                            toggleMidDrawer={handleAddEditMerchantMids}
                            forceReload={forceReload}
                            merchantName={merchantName}
                            availableDeflectors={availableDeflectors}
                            setAlertSnackbarMainProps={
                                setAlertSnackbarMainProps
                            }
                            setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                            setVerifiRDRIsOpen={setVerifiRDRIsOpen}
                        />
                    )}
                </Drawer>
            )}
            <AlertSnackbar
                content={alertSnackbarMainProps}
                open={alertSnackbarMainOpen}
                onClose={() => setAlertSnackbarMainOpen(false)}
                showCloseIcon
            />
            {DEFLECTORS.enabled && (
                <VerifiRDRConfig
                    id={activeNodeIdActual}
                    isMid={drawerType === 'mids'}
                    isOpen={verifiRDRisOpen}
                    setIsOpen={setVerifiRDRIsOpen}
                />
            )}
        </>
    )
}

export default withRequiredRole(
    EntFeature.MERCHANT_HIERARCHY,
    MerchantHierarchy
)

import React, { useState, useEffect } from 'react'
import { useTheme, Button } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangePicker } from 'react-date-range'
import {
    endOfDay,
    format,
    addDays,
    startOfDay,
    isSameDay,
    differenceInCalendarDays,
    isSameMonth,
} from 'date-fns'
import { clientTheme } from 'theme-exports'
import { dateUtilities } from 'utils/dateUtilities'

interface DateRange {
    to: string
    from: string
}

interface DateRangeInput {
    startDate: Date
    endDate: Date
    key: 'selection'
}

interface PresetDateRangePickerProps {
    className?: string
    id?: string
    activeCustomRange: DateRange | null
    returnSelectedRanges: (range: DateRange) => void
    closeInParent?: () => void
    allowFutureDateSelection: boolean
}

/**
 * @params activeCustomRange - this is either an object: { to: string, from: string } or null. All dates are captured and passed around as strings
 * @params returnSelectedRanges - this is a listening function passed in from the parent that will return the preset or custom date range selected when the 'Apply' button is clicked
 * @params closeInParent - this is an optional function that the parent can pass in that gets applied to the onClick action of the 'Cancel' button. Can be used in the parent to close a modal, cancel an action, etc. etc
 */

const PresetDateRangePicker = React.memo(
    //wrapped in memo to avoid re-renders when inputs are changing
    ({
        className,
        id,
        activeCustomRange,
        returnSelectedRanges,
        closeInParent,
        allowFutureDateSelection,
    }: PresetDateRangePickerProps) => {
        const theme = useTheme()
        const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
        const defaultDates = dateUtilities.getToday()
        const customRangeDefault = {
            startDate: defaultDates.to,
            endDate: defaultDates.from,
            key: 'selection',
        }
        const [customDateRange, setCustomDateRange] = useState<any[]>([
            customRangeDefault,
        ])

        // if a custom range is passed in from the rendering parent
        useEffect(() => {
            if (activeCustomRange) {
                // Removes timezone offset from passed in custom range dates so that the custom range date inputs can reflect the correct day
                const { normalizeDateOffset } = dateUtilities
                const normalizedDateTo = normalizeDateOffset(
                    endOfDay(new Date(activeCustomRange.to))
                )
                const normalizedDateFrom = normalizeDateOffset(
                    endOfDay(new Date(activeCustomRange.from))
                )
                setCustomDateRange([
                    {
                        startDate: normalizedDateFrom,
                        endDate: normalizedDateTo,
                        key: 'selection',
                    },
                ])
            }
        }, [activeCustomRange])

        const handleCustomDateRange = () => {
            const today = new Date()
            const toDate = customDateRange[0].endDate
            const fromDate = customDateRange[0].startDate

            let customRange: {
                from: string
                to: string
            }

            if (!allowFutureDateSelection) {
                if (toDate.setHours(0, 0, 0, 0) >= today.setHours(0, 0, 0, 0)) {
                    customRange = {
                        from: format(fromDate, 'yyyy-MM-dd'),
                        to: format(new Date(), 'yyyy-MM-dd'),
                    }
                } else {
                    customRange = {
                        from: format(fromDate, 'yyyy-MM-dd'),
                        to: format(toDate, 'yyyy-MM-dd'),
                    }
                }
            } else {
                customRange = {
                    from: format(fromDate, 'yyyy-MM-dd'),
                    to: format(toDate, 'yyyy-MM-dd'),
                }
            }
            returnSelectedRanges(customRange)
        }

        //Taken from 'react-date-range' to allow user to selection "X" days up to today
        const PresetInputRange: any[] = [
            {
                label: 'days up to today',
                range(value: string) {
                    return {
                        startDate: addDays(
                            startOfDay(new Date()),
                            (Math.max(Number(value), 1) - 1) * -1
                        ),
                        endDate: endOfDay(new Date()),
                    }
                },
                getCurrentValue(range: DateRangeInput) {
                    if (!isSameDay(range.endDate, endOfDay(new Date())))
                        return '-'
                    if (!range.startDate) return '∞'
                    return (
                        differenceInCalendarDays(
                            endOfDay(new Date()),
                            range.startDate
                        ) + 1
                    )
                },
            },
        ]

        return (
            <div className={className} id={id}>
                <DateRangePicker
                    onChange={(item) => setCustomDateRange([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={isLargeScreen ? 2 : 1}
                    ranges={customDateRange}
                    direction="horizontal"
                    rangeColors={[clientTheme.secondary]}
                    maxDate={allowFutureDateSelection ? undefined : new Date()}
                    className={'emp-presetDateRangePicker-container'}
                    inputRanges={PresetInputRange}
                    calendarFocus={
                        isSameMonth(new Date(), customDateRange[0].startDate)
                            ? 'backwards'
                            : 'forwards'
                    }
                />
                <div className={'emp-presetDateRangePicker-actionBtnGrp'}>
                    <Button
                        className={'emp-presetDateRangePicker-actionBtn'}
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            closeInParent && closeInParent()
                            handleCustomDateRange()
                        }}
                        size="small"
                    >
                        Apply
                    </Button>
                    <Button
                        className={'emp-presetDateRangePicker-actionBtn'}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            closeInParent && closeInParent()
                        }}
                        size="small"
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        )
    }
)

export default PresetDateRangePicker

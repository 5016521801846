import React from 'react'
import { Switch } from 'react-router'

interface WrapProps {
    wrap: React.FC | React.FC[]
}

export const Wrap: React.FC<WrapProps> = ({ children, wrap }) => {
    const [C, ...restWraps] = Array.isArray(wrap) ? wrap : [wrap]
    if (Boolean(C)) {
        return (
            <C>
                <Wrap wrap={restWraps}>{children}</Wrap>
            </C>
        )
    }
    return <>{children}</>
}

interface SetProps {
    /** component or array of component to wrap child routes with. */
    wrap: React.FC | React.FC[]
}

const Set: React.FC<SetProps> = ({ children, wrap }) => {
    return (
        <Switch>
            <Wrap wrap={wrap}>{children}</Wrap>
        </Switch>
    )
}

export default Set

/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import idDirectory from './idAttributes'
import CB from 'lib'
import { Merchant } from 'lib/Merchants'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { useActiveMerchant } from 'components'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import SearchableSelect from 'components/SearchableSelect'
import { IconButton } from '@mui/material'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

/**
 * @desc - MerchantSwitcher provides us with a wrapped MUI Autocomplete component that takes in an options list and handleChange function. It then provides us with a selectable and searchable auto-complete input made from those options.
 * @param defaultValue - optional default value for the input
 * @param merchantOptions - list of options to render the autocomlete list with
 * @param handleValueSelect - onChange function to extract the input value selected
 * @param className?
 * @param id?
 * @param label - autocomplete input label
 * @param loading
 * @param error
 *
 */

export interface MerchantSwitcherProps {
    testId?: string
}

const MerchantSwitcher = ({ testId }: MerchantSwitcherProps) => {
    const [merchants, setMerchants] = useState<Merchant[] | null>(null)
    const { user } = useAuthedUser()
    const {
        name,
        setActiveMerchant,
        isRootMerchant,
        userDefaultMerchant,
        id: selectedId,
    } = useActiveMerchant()
    const { MERCHANT_LIST } = useFeatureToggle('MERCHANT_SWITCHER')
    const [searchValue, setSearchValue] = useState('')
    const [merchantSearchLoading, setMerchantSearchLoading] = useState<boolean>(false)

    const value = React.useMemo(() => {
        if (isRootMerchant) return `${name} - ALL MERCHANTS`
        return name
    }, [name, isRootMerchant])

    useEffect(() => {
        const merchantId = user?.merchant?.id.toString()
        if (merchantId && searchValue.length > 1) {
            setMerchantSearchLoading(true)
            CB.merchants
                .list(merchantId, { show_all: 1, search: searchValue ?? '' })
                .then((res) => {
                    res.data.map((merchant) => {
                        if (!merchant.parent_id) {
                            merchant.business_name = `${merchant.business_name} - ALL MERCHANTS`
                        } else if (MERCHANT_LIST.enabled_merchant_alias) {
                            merchant.business_name = `${merchant.business_name} - ${merchant.alias}`
                        }
                        return merchant
                    })
                    setMerchants(res.data)
                })
                .finally(() => setMerchantSearchLoading(false))
        }
        setMerchants(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, searchValue])

    const handleChange = (value: Partial<Merchant> | null) => {
        setSearchValue('')
        if (value && value.id && value.business_name) {
            setActiveMerchant({
                id: value.id.toString(),
                name: value.business_name.replace(' - ALL MERCHANTS', ''),
            })
        } else {
            setActiveMerchant({
                id: user?.merchant?.id.toString() ?? '1',
                name: user?.merchant?.business_name ?? '',
            })
        }
    }

    return (
        <div
            id={`${idDirectory.divRoot}-${testId}`}
            className={'emp-merchantSwitcher-root'}
        >
            <div style={{ flex: '1 1 auto' }}>
                <SearchableSelect
                    value={value}
                    onValueChange={handleChange}
                    searchValue={searchValue}
                    onSearchChange={setSearchValue}
                    options={
                        searchValue.length > 1 ? (merchants as any) ?? [] : []
                    }
                    accessor="business_name"
                    testId={`${testId}-businessName`}
                    debounceDelay={200}
                    loadingValues={merchantSearchLoading}
                >
                    {searchValue.length < 2 && (
                        <span style={{ margin: '10px', fontSize: '14px' }}>
                            Please enter 2 or more characters
                        </span>
                    )}
                </SearchableSelect>
            </div>
            {userDefaultMerchant !== selectedId && userDefaultMerchant && (
                <div style={{ padding: '2px 0 2px 8px' }}>
                    <IconButton
                        size="small"
                        onClick={() => setActiveMerchant({ name: '', id: user?.merchant?.id.toString() ?? '1' })}
                    >
                        <Icon icon={faTimes} />
                    </IconButton>
                </div>
            )}
        </div>
    )
}

export default MerchantSwitcher

import React, { ReactElement, useState } from 'react'
import axios from 'axios'
import {
    Button,
    FormControl,
    FormHelperText,
    FormLabel,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    MenuItem,
    Select,
    Switch,
    Divider,
    CircularProgress,
} from '@mui/material'
import { clientTheme } from 'theme-exports'
import CSVMidUpload from './CSVMidUpload'
import { Lamp } from 'components'
import idDirectory from './idAttributes'
import { TAddEditMidInputs, TProcessor } from 'views/AddEditMid/AddEditMid.vm'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { UserStatus, AlertsDeflectorStatus } from '../MerchantEdit/MerchantEdit'
import { Deflector } from 'views/MerchantHierarchy/MerchantHierarchy.vm'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import EntFeature from 'models/EntFeature'

interface AddEditMidProps {
    isEdit: boolean
    merchantId: number
    selectedMidMerchantId?: number | string
    handleOnClose: () => void
    processors: TProcessor[]
    values: TAddEditMidInputs
    errors: { [key: string]: any }
    register: any
    isDirty: boolean
    isValid: boolean
    handleOnChangeValue: (fieldName: any, value: any) => void
    isSubmitting: boolean
    handleSubmit: () => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    availableDeflectors: Deflector[]
    defaultDeflectorValues: any[]
    setVerifiRDRIsOpen: (value: boolean) => void
}

const ServiceStatus = ({
    status,
    testId = '',
}: {
    status: boolean
    testId?: string
}) => {
    return (
        <div
            className={'emp-updateMid-lampWrapper'}
            {...(testId && { id: testId })}
        >
            <Lamp
                className={'emp-updateMid-lamp'}
                color={status ? 'green' : 'grey'}
            />
            {status ? 'Active' : 'Inactive'}
        </div>
    )
}

// form for updating or adding a MID
const AddEditForm = ({
    isEdit,
    merchantId,
    selectedMidMerchantId,
    handleOnClose,
    processors,
    values,
    errors,
    register,
    isDirty,
    isValid,
    handleOnChangeValue,
    isSubmitting,
    handleSubmit,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    availableDeflectors,
    defaultDeflectorValues,
    setVerifiRDRIsOpen,
}: AddEditMidProps): ReactElement => {
    const formType = isEdit ? 'edit' : 'add'
    const {
        MID_UPDATE,
        INHERIT_MERCHANT_SETTING = false,
        DEFLECTORS = false,
        SHOW_HELIX_MID = false,
        SHOW_MIDS_WPG_FIELD = false,
        MERCHANT_SERVICE_LEVELS = {},
    } = useFeatureToggle('MERCHANT_HIERARCHY')
    const {
        enabled: helixMIDEnabled,
        input_edit_label: helixMIDlabel,
    } = SHOW_HELIX_MID
    const { enabled: WPGFieldEnabled } = SHOW_MIDS_WPG_FIELD
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(
        false
    )
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})
    const uiState = useUiStateContext()
    const hasEditRDR = uiState.whoami?.hasRole(EntFeature.EDIT_RDR) || false

    const setActiveDeflectors = (id: number, value: boolean) => {
        if (!value) {
            handleOnChangeValue(
                'deflectors',
                values?.deflectors?.filter((obj: Deflector) => obj.id !== id) ??
                    []
            )
        } else {
            let addedDeflector = availableDeflectors.find(
                (obj: Deflector) => obj.id === id
            ) as Deflector

            addedDeflector.status = {
                id: 2,
                name: 'Pending Activation',
            }

            handleOnChangeValue('deflectors', [
                ...((values.deflectors &&
                    values.deflectors?.filter(
                        (obj: Deflector) => obj.id !== id
                    )) ||
                    []),
                addedDeflector,
            ])
        }
    }

    const setInheritMerchantSettings = (isInherit: boolean) => {
        if (isInherit) {
            axios
                .get(`/cm/merchants/${selectedMidMerchantId ?? merchantId}`)
                .then((response) =>
                    handleOnChangeValue(
                        'deflectors',
                        response.data.deflectors || []
                    )
                )
            handleOnChangeValue('inherit_merchant_deflectors', true)
        } else {
            if (isEdit) {
                axios
                    .get(`/cm/mids/${merchantId}`)
                    .then((response) =>
                        handleOnChangeValue(
                            'deflectors',
                            response.data.deflectors || []
                        )
                    )
            } else {
                handleOnChangeValue('deflectors', [])
            }
            handleOnChangeValue('inherit_merchant_deflectors', false)
        }
    }

    return (
        <>
            <form className={'emp-updateMid-root'} onSubmit={handleSubmit}>
                <div className={'emp-updateMid-status'}>
                    <FormControl>
                        <FormLabel
                            className={'emp-updateMid-inputLabel'}
                            id={`${formType}-${idDirectory.labelHeader}-overall-status`}
                        >
                            Overall Status
                        </FormLabel>

                        <UserStatus
                            status={values?.status ?? false}
                            testId={`${formType}-${idDirectory.statusField}-overall-status`}
                        />
                    </FormControl>

                    <FormControl>
                        <Switch
                            className={'emp-updateMid-switchBtn'}
                            checked={values?.status ?? false}
                            onChange={(e) =>
                                handleOnChangeValue('status', e.target.checked)
                            }
                            name={'status'}
                            size={'small'}
                            id={`${formType}-${idDirectory.switchField}-overall-status`}
                            color="secondary"
                        />
                    </FormControl>
                </div>
                <Divider className={'emp-updateMid-divider'} />
                <div className={'emp-updateMid-formContainer'}>
                    <div style={{ marginRight: 8, width: '48%' }}>
                        <div className={'emp-updateMid-formBlockFull'}>
                            <FormControl
                                className={`${'emp-updateMid-formControl'} ${'emp-updateMid-inputHalf'}`}
                            >
                                <FormLabel
                                    className={'emp-updateMid-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-mid`}
                                >
                                    MID
                                </FormLabel>
                                <TextField
                                    classes={{}}
                                    variant="outlined"
                                    className={`${'emp-updateMid-background'} ${'emp-updateMid-textFieldInput'} `}
                                    required
                                    type="text"
                                    {...register('mid')}
                                    name="mid"
                                    disabled={isEdit}
                                    id={`${formType}-${idDirectory.inputField}-mid`}
                                />
                                {Boolean(errors?.mid?.message) && (
                                    <FormHelperText error>
                                        {errors?.mid?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl
                                className={`${'emp-updateMid-formControl'} ${'emp-updateMid-inputHalf'}`}
                                fullWidth
                            >
                                <FormLabel
                                    className={'emp-updateMid-inputLabel'}
                                    id={`${formType}-${
                                        idDirectory.labelHeader
                                    }-${MID_UPDATE?.input_edit_label ?? ''}`}
                                >
                                    {MID_UPDATE?.input_edit_label ?? ''}
                                </FormLabel>
                                <TextField
                                    classes={{}}
                                    variant="outlined"
                                    className={`${'emp-updateMid-background'} ${'emp-updateMid-textFieldInput'}`}
                                    required
                                    type="text"
                                    {...register('alias')}
                                    name="alias"
                                    id={`${formType}-${
                                        idDirectory.inputField
                                    }-${MID_UPDATE?.input_edit_label ?? ''}`}
                                />
                                {Boolean(errors?.alias?.message) && (
                                    <FormHelperText error>
                                        {errors?.alias?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl
                                className={`${'emp-updateMid-formControl'} ${'emp-updateMid-inputHalf'}`}
                                fullWidth
                            >
                                <FormLabel
                                    className={'emp-updateMid-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-mid-descriptor`}
                                >
                                    MID Descriptor
                                </FormLabel>
                                <TextField
                                    classes={{}}
                                    variant="outlined"
                                    className={`${'emp-updateMid-background'} ${'emp-updateMid-textFieldInput'}`}
                                    required
                                    type="text"
                                    {...register('descriptor')}
                                    name="descriptor"
                                    disabled={isEdit}
                                    id={`${formType}-${idDirectory.inputField}-mid-descriptor`}
                                />
                                {Boolean(errors?.descriptor?.message) && (
                                    <FormHelperText error>
                                        {errors?.descriptor?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl
                                className={`${'emp-updateMid-formControl'} ${'emp-updateMid-inputHalf'}`}
                                fullWidth
                            >
                                <FormLabel
                                    className={'emp-updateMid-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-mcc`}
                                >
                                    MCC
                                </FormLabel>
                                <TextField
                                    classes={{}}
                                    variant="outlined"
                                    className={`${'emp-updateMid-background'} ${'emp-updateMid-textFieldInput'}`}
                                    type="text"
                                    {...register('mcc')}
                                    name="mcc"
                                    id={`${formType}-${idDirectory.inputField}-mcc`}
                                />
                                {Boolean(errors?.mcc?.message) && (
                                    <FormHelperText error>
                                        {errors?.mcc?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            {WPGFieldEnabled && (
                                <FormControl
                                    className={`${'emp-updateMid-formControl'} ${'emp-updateMid-inputHalf'}`}
                                    fullWidth
                                >
                                    <FormLabel
                                        className={'emp-updateMid-inputLabel'}
                                        id={`${formType}-${idDirectory.labelHeader}-wpg-merchant-code`}
                                    >
                                        WPG Merchant Code
                                    </FormLabel>
                                    <TextField
                                        classes={{}}
                                        variant="outlined"
                                        className={`${'emp-updateMid-background'} ${'emp-updateMid-textFieldInput'} `}
                                        type="text"
                                        {...register('wpg_merchant_code')}
                                        name="wpg_merchant_code"
                                        id={`${formType}-${idDirectory.inputField}-wpg-merchant-code`}
                                    />
                                    {Boolean(
                                        errors?.wpg_merchant_code?.message
                                    ) && (
                                        <FormHelperText error>
                                            {errors?.wpg_merchant_code?.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                            <FormControl
                                className={`${'emp-updateMid-formControl'} ${'emp-updateMid-inputHalf'}`}
                                fullWidth
                            >
                                <FormLabel
                                    className={'emp-updateMid-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-caid`}
                                >
                                    CAID
                                </FormLabel>
                                <TextField
                                    classes={{}}
                                    variant="outlined"
                                    className={`${'emp-updateMid-background'} ${'emp-updateMid-textFieldInput'} `}
                                    type="text"
                                    {...register('caid')}
                                    name="caid"
                                    id={`${formType}-${idDirectory.inputField}-caid`}
                                />
                                {Boolean(errors?.caid?.message) && (
                                    <FormHelperText error>
                                        {errors?.caid?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </div>
                        <div className={'emp-updateMid-formContainer'}>
                            <FormControl
                                className={`${'emp-updateMid-formControl'} ${'emp-updateMid-inputHalf'}`}
                            >
                                <FormLabel
                                    className={'emp-updateMid-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-platform`}
                                >
                                    Platform
                                </FormLabel>
                                <Select
                                    name="platform"
                                    value={values?.platform ?? ''}
                                    variant="outlined"
                                    className={`${'emp-updateMid-background'} ${'emp-updateMid-textFieldInput'}`}
                                    onChange={(e) =>
                                        handleOnChangeValue(
                                            'platform',
                                            e.target.value
                                        )
                                    }
                                    required
                                    id={`${formType}-${idDirectory.selectField}-platform`}
                                >
                                    {processors.map((processor, i) => (
                                        <MenuItem
                                            key={`${processor.name}-${i}`}
                                            value={processor.id}
                                        >
                                            <span>{processor.name}</span>
                                        </MenuItem>
                                    ))}
                                </Select>
                                {Boolean(errors?.platform?.message) && (
                                    <FormHelperText error>
                                        {errors?.platform?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            {helixMIDEnabled && (
                                <FormControl
                                    className={`${'emp-updateMid-formControl'} ${'emp-updateMid-inputHalf'}`}
                                    fullWidth
                                >
                                    <FormLabel
                                        className={'emp-updateMid-inputLabel'}
                                        id={`${formType}-${idDirectory.labelHeader}-helix-mid`}
                                    >
                                        {helixMIDlabel}
                                    </FormLabel>
                                    <TextField
                                        classes={{}}
                                        variant="outlined"
                                        className={`${'emp-updateMid-background'} ${'emp-updateMid-textFieldInput'} `}
                                        required
                                        type="text"
                                        value={values?.mid ?? ''}
                                        onChange={(e) =>
                                            handleOnChangeValue(
                                                'mid',
                                                e.target.value
                                            )
                                        }
                                        name="mid"
                                        id={`${formType}-${idDirectory.inputField}-helix-mid`}
                                    />
                                    {Boolean(errors?.mid?.message) && (
                                        <FormHelperText error>
                                            {errors?.mid?.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        </div>
                    </div>

                    <div className={'emp-updateMid-formBlock'}>
                        {!isEdit && (
                            <div
                                className={'emp-updateMid-csvUploadContainer'}
                                id={idDirectory.divBulkUpload}
                            >
                                <span>MID Bulk Upload:</span>
                                <CSVMidUpload
                                    merchantId={merchantId}
                                    onModalClose={handleOnClose}
                                    setAlertSnackbarMainProps={
                                        setAlertSnackbarMainProps
                                    }
                                    setAlertSnackbarMainOpen={
                                        setAlertSnackbarMainOpen
                                    }
                                />
                            </div>
                        )}
                        <FormControl
                            className={'emp-updateMid-formControl'}
                            fullWidth
                        >
                            <FormLabel
                                className={'emp-updateMid-inputLabel'}
                                id={`${formType}-${idDirectory.labelHeader}-merchant-service-level`}
                            >
                                MID Service Level
                            </FormLabel>
                            <RadioGroup
                                aria-label="service level"
                                name="service_level_id"
                                value={values?.service_level_id ?? ''}
                                onChange={(e) => {
                                    handleOnChangeValue(
                                        'service_level_id',
                                        e.target.value
                                    )
                                }}
                                className={'emp-updateMid-radioContainer'}
                            >
                                {MERCHANT_SERVICE_LEVELS.basic_service_enabled && (
                                    <div
                                        className={'emp-updateMid-serviceContainer'}
                                    >
                                        <FormControlLabel
                                            value={'1'}
                                            control={<Radio color="secondary" />}
                                            label="Basic Service"
                                            id={`${formType}-${idDirectory.radioField}-basic-service`}
                                        />
                                        <ServiceStatus
                                            status={
                                                values?.service_level_id === '1'
                                            }
                                            testId={`${formType}-${idDirectory.statusField}-basic-service`}
                                        />
                                    </div>
                                )}
                                {MERCHANT_SERVICE_LEVELS.pro_service_enabled && (
                                    <div
                                        className={'emp-updateMid-serviceContainer'}
                                    >
                                        <FormControlLabel
                                            value={'5'}
                                            control={<Radio color="secondary" />}
                                            label="Pro Service"
                                            id={`${formType}-${idDirectory.radioField}-pro-service`}
                                        />
                                        <ServiceStatus
                                            status={
                                                values?.service_level_id === '5'
                                            }
                                            testId={`${formType}-${idDirectory.statusField}-pro-service`}
                                        />
                                    </div>
                                )}
                                {MERCHANT_SERVICE_LEVELS.full_service_enabled && (
                                    <div
                                        className={'emp-updateMid-serviceContainer'}
                                    >
                                        <FormControlLabel
                                            value={'3'}
                                            control={<Radio color="secondary" />}
                                            label="Full Service"
                                            id={`${formType}-${idDirectory.radioField}-full-service`}
                                        />
                                        <ServiceStatus
                                            status={
                                                values?.service_level_id === '3'
                                            }
                                            testId={`${formType}-${idDirectory.statusField}-full-service`}
                                        />
                                    </div>
                                )}
                            </RadioGroup>
                            <Divider />
                            {INHERIT_MERCHANT_SETTING.enabled && (
                                <div
                                    className={'emp-updateMid-inheritMerchant'}
                                >
                                    <FormControl
                                        id={`${formType}-${idDirectory.labelHeader}-inherit-merchant`}
                                    >
                                        <p>Inherit from Merchant</p>
                                    </FormControl>
                                    <FormControl>
                                        <Switch
                                            className={
                                                'emp-updateMid-switchBtn'
                                            }
                                            checked={Boolean(
                                                values?.inherit_merchant_deflectors
                                            )}
                                            onChange={() => {
                                                setInheritMerchantSettings(
                                                    !values?.inherit_merchant_deflectors
                                                )
                                            }}
                                            size={'small'}
                                            id={`${formType}-${idDirectory.switchField}-inherit-merchant`}
                                            color="secondary"
                                        />
                                    </FormControl>
                                </div>
                            )}
                            <Divider />
                            {DEFLECTORS.enabled &&
                                availableDeflectors?.map(
                                    (availableDeflector, idx) => {
                                        // Statuses 1: Inactive - 2: Pending Activation - 3: Active
                                        let foundDeflector = undefined as any
                                        try {
                                            foundDeflector =
                                                values.deflectors &&
                                                values.deflectors.find(
                                                    (obj: any) => {
                                                        return (
                                                            obj.id ===
                                                            availableDeflector.id
                                                        )
                                                    }
                                                )
                                        } catch (e) {}

                                        const isChecked = foundDeflector
                                            ? foundDeflector.status.id === 1
                                                ? false
                                                : true
                                            : false

                                        const defaultDeflectorStatusId = defaultDeflectorValues.find(
                                            (obj: any) => {
                                                return (
                                                    obj.id ===
                                                    availableDeflector.id
                                                )
                                            }
                                        )?.status?.id
                                        
                                        return (
                                            <div
                                                className={
                                                    'emp-updateMid-deflectors'
                                                }
                                                key={`key-deflector-${idx}`}
                                            >
                                                <FormControl>
                                                    <FormLabel
                                                        className={
                                                            'emp-updateMid-inputLabel'
                                                        }
                                                        id={`${formType}-${idDirectory.labelHeader}-${availableDeflector.name}`}
                                                    >
                                                        {
                                                            availableDeflector.name
                                                        }
                                                    </FormLabel>
                                                    <AlertsDeflectorStatus
                                                        status={
                                                            foundDeflector
                                                                ? foundDeflector
                                                                      .status.id
                                                                : 1
                                                        }
                                                        label={
                                                            foundDeflector
                                                                ? foundDeflector
                                                                      ?.status
                                                                      ?.id === 2
                                                                    ? 'Pending Activation'
                                                                    : foundDeflector
                                                                          ?.status
                                                                          ?.id ===
                                                                      3
                                                                    ? 'Active'
                                                                    : 'Inactive'
                                                                : 'Inactive'
                                                        }
                                                        testId={`${idDirectory.statusField}-${availableDeflector.name}`}
                                                    />
                                                </FormControl>
                                                <div>
                                                    {availableDeflector.alias ===
                                                        'verifi_rdr' &&
                                                        hasEditRDR &&
                                                        isChecked && (
                                                            <Button
                                                                className={
                                                                    'emp-updateMid-editRDRBtn'
                                                                }
                                                                color={
                                                                    'secondary'
                                                                }
                                                                onClick={() => {
                                                                    setVerifiRDRIsOpen(
                                                                        true
                                                                    )
                                                                }}
                                                            >
                                                                Edit RDR
                                                            </Button>
                                                        )}
                                                    <FormControl>
                                                        <Switch
                                                            className={
                                                                'emp-updateMid-switchBtn'
                                                            }
                                                            checked={isChecked}
                                                            onChange={(e) => {
                                                                if (
                                                                    isEdit &&
                                                                    isChecked &&
                                                                    [
                                                                        2,
                                                                        3,
                                                                    ].includes(
                                                                        defaultDeflectorStatusId
                                                                    )
                                                                ) {
                                                                    setAlertSnackbarProps(
                                                                        {
                                                                            title:
                                                                                'Error',
                                                                            message: `Please open an issue at Jira Service Management, for further assistance.`,
                                                                            intent:
                                                                                'error',
                                                                        }
                                                                    )
                                                                    return setAlertSnackbarOpen(
                                                                        true
                                                                    )
                                                                }
                                                                setActiveDeflectors(
                                                                    availableDeflector.id,
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }}
                                                            name={
                                                                availableDeflector.alias
                                                            }
                                                            size={'small'}
                                                            disabled={Boolean(
                                                                values?.inherit_merchant_deflectors
                                                            )}
                                                            id={`${formType}-${idDirectory.switchField}-${availableDeflector.name}`}
                                                            color="secondary"
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                        </FormControl>
                    </div>
                </div>

                <div className={'emp-updateMid-actionBtnContainer'}>
                    <Button
                        variant="contained"
                        onClick={handleOnClose}
                        id={`${formType}-${idDirectory.btnCancel}`}
                        sx={clientTheme.buttons.defaultButton.style}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting || !isValid || !isDirty}
                        id={`${formType}-${idDirectory.btnSubmit}`}
                    >
                        {isSubmitting ? (
                            <div className={'emp-updateMid-loadingContainer'}>
                                <CircularProgress
                                    size={22}
                                    color="secondary"
                                    style={{
                                        position: 'absolute',
                                        left: '25%',
                                    }}
                                />
                                <div>Submit</div>
                            </div>
                        ) : (
                            'Submit'
                        )}
                    </Button>
                </div>
            </form>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => {
                    setAlertSnackbarOpen(false)
                }}
                showCloseIcon
            />
        </>
    )
}

const UpdateMid = ({
    isEdit,
    merchantId,
    selectedMidMerchantId,
    handleOnClose,
    processors,
    values,
    errors,
    register,
    isDirty,
    isValid,
    handleOnChangeValue,
    isSubmitting,
    handleSubmit,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    availableDeflectors,
    defaultDeflectorValues,
    setVerifiRDRIsOpen,
}: AddEditMidProps): React.ReactElement => {
    return (
        <AddEditForm
            isEdit={isEdit}
            merchantId={merchantId}
            selectedMidMerchantId={selectedMidMerchantId}
            handleOnClose={handleOnClose}
            processors={processors}
            values={values}
            errors={errors}
            register={register}
            isDirty={isDirty}
            isValid={isValid}
            handleOnChangeValue={handleOnChangeValue}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            setAlertSnackbarMainProps={setAlertSnackbarMainProps}
            setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
            availableDeflectors={availableDeflectors}
            defaultDeflectorValues={defaultDeflectorValues}
            setVerifiRDRIsOpen={setVerifiRDRIsOpen}
        />
    )
}

export default UpdateMid

import { useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import MerchantApi from 'api/MerchantApi'
import UtilityApi from 'api/UtilityApi'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { Deflector } from 'views/MerchantHierarchy/MerchantHierarchy.vm'

export type { Merchant } from 'lib/Merchants'
export type { Mid } from 'lib/Mids'

export type TProcessor = {
    alias: string
    id: number
    name: string
}

interface IAddEditMidVM {
    inputUtils: {
        register: any
        watch: any
        errors: { [key: string]: any }
        isDirty: boolean
        isValid: boolean
        isSubmitting: boolean
        handleOnChangeValue: (fieldName: string, value: any) => void
        submitForm: () => void
        reset: () => void
        processors: TProcessor[]
    }
    selectedMidMerchantId: string | number
    defaultDeflectorValues: Deflector[]
}

interface IAddEditMidtProps {
    isEdit: boolean
    selectedNodeId: number
    forceReload: () => void
    midDetails: any
    setAlertSnackbarProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarOpen: (status: boolean) => void
    // For AlertSnackbar on main view.
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export type TAddEditMidInputs = {
    mid: string | undefined
    alias: string | undefined
    descriptor: string | undefined
    service_level_id: string | undefined
    caid: string | undefined
    mcc: string | undefined
    platform: number | undefined
    wpg_merchant_code: string | undefined
    status: boolean
    deflectors: Deflector[]
    inherit_merchant_deflectors: boolean
}

const validationSchema = Yup.object().shape({
    mid: Yup.string().required('Field required!').nullable(),
    alias: Yup.string().required('Field required!').nullable(),
    descriptor: Yup.string().required('Field required!').nullable(),
    service_level_id: Yup.string().required('Field required!').nullable(),
    // caid: Yup.string().required('Field required!').nullable().nullable(),
    // mcc: Yup.string().required('Field required!').nullable().nullable(),
    platform: Yup.number().required('Field required!').positive('Field required!').nullable(),
})

/**
 *
 * @param selectedNodeId - this id comes from the Hierarchy tree and is either from a selected merchant or mid, if we are adding a MID, it will come as a merchant id, it we are editing a MID, it will come as a MID id
 */
const useAddEditMid = ({
    isEdit,
    selectedNodeId,
    forceReload,
    midDetails,
    setAlertSnackbarProps,
    setAlertSnackbarOpen,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: IAddEditMidtProps): IAddEditMidVM => {
    const { INHERIT_MERCHANT_SETTING = false } = useFeatureToggle(
        'MERCHANT_HIERARCHY'
    )
    const [processors, setProcessors] = useState<any>([])
    const [inheritMerchantDeflectors, setInheritMerchantDeflectors] = useState(
        []
    )

    useEffect(() => {
        UtilityApi.getProcessors()
            .then(({ data }: any) => setProcessors(data))
            .catch((e) => {
                // console.log(`[error]: ${e}`)
                setProcessors([])
            })
    }, [])

    useEffect(() => {
        if (!isEdit && INHERIT_MERCHANT_SETTING.enabled) {
            axios
                .get(
                    `/cm/merchants/${midDetails?.merchant_id ?? selectedNodeId}`
                )
                .then((response) =>
                    setInheritMerchantDeflectors(
                        response?.data?.deflectors || []
                    )
                )
        }
        // eslint-disable-next-line
    }, [])

    const defaultValues = useMemo<TAddEditMidInputs>(() => {
        return isEdit
            ? {
                  mid: midDetails?.mid ?? '',
                  alias: midDetails?.alias ?? '',
                  descriptor: midDetails?.descriptor.descriptor ?? '',
                  service_level_id:
                      midDetails?.service_level.id.toString() ?? '1',
                  caid: midDetails?.caid ?? '',
                  mcc: midDetails?.mcc ?? '',
                  platform: midDetails?.processor.id ?? 0,
                  wpg_merchant_code: midDetails?.wpg_merchant_code ?? '',
                  status: midDetails?.status.id === 2 ? true : false,
                  deflectors: midDetails?.deflectors ?? [],
                  inherit_merchant_deflectors:
                      midDetails?.inherit_merchant_deflectors ?? false,
              }
            : {
                  mid: '',
                  alias: '',
                  descriptor: '',
                  service_level_id: '1', // default value when adding mid
                  caid: '',
                  mcc: '',
                  platform: 0,
                  wpg_merchant_code: '',
                  status: true,
                  deflectors: INHERIT_MERCHANT_SETTING.enabled
                      ? inheritMerchantDeflectors
                      : [],
                  inherit_merchant_deflectors: INHERIT_MERCHANT_SETTING.enabled,
              }
    }, [
        isEdit,
        midDetails,
        inheritMerchantDeflectors,
        INHERIT_MERCHANT_SETTING.enabled,
    ])

    const {
        register,
        trigger,
        watch,
        setValue,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting, isValid, isDirty },
        // TODO: Known React-Hook-Form typescript issue - Should be resolved with RHF's v8. Once resolved, need to switch typescript's "any" to "TAddEditMidInputs".
    } = useForm<any>({
        defaultValues: defaultValues,
        values: defaultValues,
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
    })

    const onSubmit: SubmitHandler<TAddEditMidInputs> = (inputValues) => {
        // Again, we're doing something we should not be doing and translating
        // the shape of data, but we're doing this for now... Also, if inherit
        // deflectors from parent (merchant) is checked, we'll send an empty array.
        const deflectorsToSend = !inputValues.inherit_merchant_deflectors
            ? inputValues.deflectors.map((obj: any) => {
                  return {
                      id: obj.id,
                      status: obj.status.id,
                  }
              })
            : []

        const body = {
            mid: inputValues?.mid,
            alias: inputValues?.alias,
            merchant_id: isEdit ? midDetails?.merchant_id : selectedNodeId,
            status_id: inputValues.status ? 2 : 3,
            descriptor: inputValues?.descriptor,
            processor_id: inputValues?.platform,
            service_level_id: inputValues?.service_level_id,
            mid_type_id: 1,
            caid: inputValues?.caid,
            mcc: inputValues?.mcc,
            wpg_merchant_code: inputValues?.wpg_merchant_code,
            deflectors: deflectorsToSend,
            inherit_merchant_deflectors:
                inputValues.inherit_merchant_deflectors,
        }

        const addMid = () => MerchantApi.addMid(body)
        const updateMid = () => MerchantApi.updateMid(selectedNodeId, body)

        return !isEdit
            ? addMid()
                  .then((res: any) => {
                      // This is to catch 4xx code responses. Any response but a 200 or 201 is an error in regards to the UI - user is alerted via model of issues with mid creation.
                      if (res.status === 200 || res.status === 201) {
                          setAlertSnackbarMainProps({
                              title: 'Success',
                              intent: 'success',
                              message: 'MID successfully added!',
                          })
                          setAlertSnackbarMainOpen(true)
                          reset()
                          forceReload()
                      } else {
                          setAlertSnackbarProps({
                              title: 'Error',
                              intent: 'error',
                              message:
                                  res.message ??
                                  `An error occurred. Please try again later.`,
                          })
                          setAlertSnackbarOpen(true)
                      }
                  })
                  .catch((_e) => {
                      setAlertSnackbarProps({
                          title: 'Error',
                          intent: 'error',
                          message:
                              _e.message ??
                              `An error occurred. Please try again later.`,
                      })
                      setAlertSnackbarOpen(true)
                  })
            : updateMid()
                  .then((res: any) => {
                      setAlertSnackbarMainProps({
                          title: 'Success',
                          intent: 'success',
                          message: 'MID successfully updated!',
                      })
                      setAlertSnackbarMainOpen(true)
                      forceReload()
                  })
                  .catch((_e) => {
                      setAlertSnackbarProps({
                          title: 'Error',
                          intent: 'error',
                          message: `An error occurred. Please try again later.`,
                      })
                      setAlertSnackbarOpen(true)
                  })
    }

    const handleOnChangeValue = (fieldName: any, value: any) => {
        setValue(fieldName, value, { shouldValidate: true, shouldDirty: true })
    }

    // Needed to trigger validation on first render of form.
    useEffect(() => {
        const validateForm = async () => {
            await reset()
            trigger()
        }
        validateForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inheritMerchantDeflectors])

    return {
        inputUtils: {
            register,
            watch,
            errors,
            isDirty,
            isValid,
            isSubmitting,
            handleOnChangeValue,
            submitForm: handleSubmit(onSubmit),
            reset,
            processors,
        },
        selectedMidMerchantId: midDetails?.merchant_id,
        defaultDeflectorValues: defaultValues.deflectors,
    }
}

export default useAddEditMid

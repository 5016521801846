import React, { useMemo, useState } from 'react'
import { Button, TextField } from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { PopoverSelect, SelectOption } from 'components/PopoverSelect'
import { clientTheme } from 'theme-exports'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import idDirectory from './idAttributes'

const stylesObj = {
    textInputField: {
        '& > div input': {
            padding: '6px 7px',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: clientTheme.selectionBox.borderRadius,
        },
    },
}

type TRangeSelectInputs = {
    min: number | null
    max: number | null
}

interface IPopoverRangeSelectProps {
    testId: string
    onChange?: (range: { min: number; max: number }) => void
    min?: number
    max?: number
    placeholder?: string
    required?: boolean
    disabled?: boolean
    setAlertSnackbarOpen?: (status: boolean) => void
    setAlertSnackbarProps?: (value: alertSnackbarContentProps) => void
}

export const PopoverRangeSelect: React.FC<IPopoverRangeSelectProps> = ({
    testId,
    min = null,
    max = null,
    onChange = () => {},
    placeholder = '',
    required = false,
    disabled = false,
    setAlertSnackbarOpen = () => {},
    setAlertSnackbarProps = () => {},
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const defaultValues = useMemo<TRangeSelectInputs>(() => {
        return {
            min,
            max,
        }
    }, [min, max])

    const {
        handleSubmit,
        reset,
        setValue,
        watch,
    } = useForm<TRangeSelectInputs>({
        defaultValues: defaultValues,
        values: defaultValues,
        mode: 'onChange',
    })

    const onSubmit: SubmitHandler<TRangeSelectInputs> = (values) => {
        const regex = /^\d*(\.\d{0,2})?$/ // To validate no negative number, only numbers, one decimal, and only 2 numbers after decimal.

        if (
            (!values.max && values.max !== 0) ||
            (!values.min && values.min !== 0) ||
            !regex.test(String(values.max)) ||
            !regex.test(String(values.min))
        ) {
            setAlertSnackbarProps({
                intent: 'error',
                message: 'Invalid amount entered.',
                title: 'Error',
            })

            return setAlertSnackbarOpen(true)
        }

        if (Number(values.max) < Number(values.min)) {
            setAlertSnackbarProps({
                intent: 'error',
                message:
                    'The maximum value cannot be lower than the minimum value.',
                title: 'Error',
            })

            return setAlertSnackbarOpen(true)
        }

        onChange({
            min: +values.min,
            max: +values.max,
        })
        setAnchorEl(null)
    }

    const displayValue = useMemo(
        () => `${min?.toFixed(2) ?? ''} - ${max?.toFixed(2) ?? ''}`,
        [min, max]
    )

    const handleOnChangeValue = (key: 'min' | 'max', value: any) => {
        setValue(key, value, {
            shouldValidate: true,
            shouldDirty: true,
        })
    }

    return (
        <PopoverSelect
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            onClose={reset}
            value={/[0-9]/.test(displayValue) ? displayValue : ''}
            placeholder={
                /[0-9]/.test(displayValue) ? displayValue : placeholder
            }
            required={required}
            disabled={disabled}
            testId={testId}
        >
            <SelectOption noHover testId={testId}>
                <form
                    id={`${idDirectory.popoverRangeSelect.formRoot}-${testId}`}
                    onSubmit={handleSubmit(onSubmit)}
                    className="emp-popoverRangeSelect-content"
                >
                    <div
                        id={`${idDirectory.popoverRangeSelect.divCriteria}-${testId}`}
                        className={'emp-popoverRangeSelect-criteriaContainer'}
                    >
                        <TextField
                            variant="outlined"
                            placeholder="Min"
                            sx={stylesObj.textInputField}
                            type={'number'}
                            onChange={(e) =>
                                handleOnChangeValue('min', e.target.value)
                            }
                            value={watch('min')}
                            name="min"
                            onClick={(e) => e.stopPropagation()}
                            id={`${idDirectory.popoverRangeSelect.inputMin}-${testId}`}
                        />

                        <TextField
                            variant="outlined"
                            placeholder="Max"
                            sx={stylesObj.textInputField}
                            type={'number'}
                            onChange={(e) =>
                                handleOnChangeValue('max', e.target.value)
                            }
                            value={watch('max')}
                            name="max"
                            onClick={(e) => e.stopPropagation()}
                            id={`${idDirectory.popoverRangeSelect.inputMax}-${testId}`}
                        />
                    </div>
                    <div className="emp-popoverRangeSelect-btnContainer">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            className="emp-popoverRangeSelect-btn"
                            id={`${idDirectory.popoverRangeSelect.btnApply}-${testId}`}
                        >
                            Apply
                        </Button>
                    </div>
                </form>
            </SelectOption>
        </PopoverSelect>
    )
}

import { useSWRContext } from 'context/SWRContext'

interface UseFlagCase {
    handleBulkFlagToggle: (
        caseIds: number[],
        isFlagged: boolean,
        comment?: string
    ) => Promise<any>
}

const useFlagCaseModal = (): UseFlagCase => {
    const { swrActions } = useSWRContext()
    const { caseNetworkActions } = swrActions

    const { bulkToggleFlag } = caseNetworkActions

    return {
        handleBulkFlagToggle: bulkToggleFlag,
    }
}

export default useFlagCaseModal

import React from 'react'
import {
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    Typography,
    Tooltip,
} from '@mui/material'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

interface IModalAreaProps {
    className?: string
    id?: string
    children: React.ReactNode
    title: string
    open: boolean
    closeModal: () => void
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | undefined
    variant?: 'default' | 'success' | 'warning' | 'error'
    headerStyle?: 'defaultHeader' | 'greyHeader'
}

/**
 * Convenience wrapper to provide a consistent dialog in a modal. For something more custom, just copy the modal pattern from ds-core library
 */
const ModalArea = ({
    className,
    id,
    children,
    title,
    open,
    closeModal,
    maxWidth = undefined,
    variant = 'default',
    headerStyle = 'defaultHeader',
}: IModalAreaProps): React.ReactElement => {
    const dialogHeaderStyle = {
        defaultHeader: 'emp-modalArea-defaultHeader',
        greyHeader: 'emp-modalArea-greyHeader',
    }

    const dialogVariant = {
        default: 'emp-modalArea-default',
        success: 'emp-modalArea-success',
        warning: 'emp-modalArea-warning',
        error: 'emp-modalArea-error',
        info: 'emp-modalArea-info',
    }

    return (
        <Dialog
            className={` ${className} emp-modalArea-root`}
            open={open}
            id={id}
            maxWidth={maxWidth}
        >
            <DialogTitle
                className={`${dialogHeaderStyle[headerStyle]} ${dialogVariant[variant]}`}
            >
                <Typography
                    className={`${dialogVariant[variant]}`}
                    style={{ fontSize: '20px', fontWeight: 500 }}
                >
                    {title}
                </Typography>
                <Tooltip title="Close Window">
                    <IconButton onClick={closeModal} size="small">
                        <Icon icon={faTimes} />
                    </IconButton>
                </Tooltip>
            </DialogTitle>
            <DialogContent classes={{ root: 'emp-modalArea-dialogContent' }}>
                {children}
            </DialogContent>
        </Dialog>
    )
}

export default ModalArea

/**
 * @description Directory for ID Attributes on Downloads.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `downloads-div-root`,
    divMain: `downloads-div-main`,
    divLoading: `downloads-div-loading`,
    btnReturn: `downloads-btn-return`,
}

export default idDirectory

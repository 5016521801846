import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {
    Typography,
    Divider,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    Box,
} from '@mui/material'
import { format } from 'date-fns'
import {
    faLaptop,
    faPhone,
    faEnvelope,
    faMobileAlt,
    faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'
import { faCreditCard } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useIsMounted from 'hooks/utils/useIsMounted'
import { clientTheme } from 'theme-exports'
import {
    SearchableSelect,
    CaseBuilderFormBuilder,
} from 'components'
import idDirectory from './idAttributes'
import { CaseDetails, FormBuilderField } from '../CaseBuilder/CaseBuilder.vm'

type TInputCaseBuilderDetailsStep1Inputs = {
    product_id: number | null
    category_id: number | null
    sale_type: number | null
    is_shippable: boolean
    is_service: boolean
    is_subscription: boolean
    new_product_name: string
    new_product_description: string
    type_id: number
}

type TOption = {
    id: number
    name?: string
}

interface ITransactionInfoList {
    name: string
    accessor: string
    question: string
}

export interface IInputCaseBuilderDetailsStep1Props {
    caseInfo: any
    setEnableSaveAndContinue: (val: boolean) => void
    initialCaseDetails: CaseDetails | null
    setFormInputValues: (values: { [key: string]: any }) => void
    fieldErrorList?: any
    setFormProductTypeId: (value: number | undefined) => void
    formProductTypeId: number | undefined
    setSelectedProductDetails: (value: any) => void
}

export const salesMethodsList = [
    {
        id: 1,
        name: 'Ecommerce',
        icon: faLaptop,
    },
    {
        id: 2,
        name: 'Phone',
        icon: faPhone,
    },
    {
        id: 3,
        name: 'Mail/Direct Mail',
        icon: faEnvelope,
    },
    {
        id: 5,
        name: 'Card Present',
        icon: faCreditCard,
    },
    {
        id: 6,
        name: 'App/Mobile',
        icon: faMobileAlt,
    },
]

const InputCaseBuilderDetailsStep1 = ({
    caseInfo,
    setEnableSaveAndContinue,
    initialCaseDetails,
    setFormInputValues,
    fieldErrorList = [],
    setFormProductTypeId,
    formProductTypeId,
    setSelectedProductDetails,
}: IInputCaseBuilderDetailsStep1Props) => {
    const { isMounted } = useIsMounted()

    const [productTypesList, setProductTypesList] = useState<TOption[]>([])
    const [
        loadingProductTypesList,
        setLoadingProductTypesList,
    ] = useState<boolean>(false)
    const [
        loadingInitialProductType,
        setLoadingInitialProductType,
    ] = useState<boolean>(false)
    const [productSearchValue, setProductSearchValue] = useState('')
    const [categoryTypesList, setCategoryTypesList] = useState<TOption[]>([])
    const [
        loadingCategoryTypesList,
        setLoadingCategoryTypesList,
    ] = useState<boolean>(false)
    const [categorySearchValue, setCategorySearchValue] = useState('')
    const [selectValues, setSelectValues] = useState({
        product_id: 'Select a product from the list',
        category_id: '',
    })
    const [isFormBuilderValid, setIsFormBuilderValid] = useState<boolean>(true)
    const [formBuilderValues, setFormBuilderValues] = useState<
        FormBuilderField[]
    >([])
    const [
        formBuilderInitialized,
        setFormBuilderInitialized,
    ] = useState<boolean>(false)
    const [
        triggerFormFieldsUpdate,
        setTriggerFormFieldsUpdate,
    ] = useState<boolean>(false) // Need to trigger useEffect to update 'setFormInputValues'.

    const transactionInfoList: ITransactionInfoList[] = [
        {
            name: 'shippable',
            accessor: 'is_shippable',
            question: 'Is this a Shippable transaction?',
        },
        {
            name: 'service',
            accessor: 'is_service',
            question: 'Is this a Service Related transaction?',
        },
        {
            name: 'subscription',
            accessor: 'is_subscription',
            question: 'Is this a Subscription Related transaction?',
        },
    ]

    const validationSchema = Yup.object().shape({
        sale_type: Yup.number().required(),
        new_product_name: Yup.string().when(['product_id'], {
            is: (product_id) => product_id === null,
            then: Yup.string().required(),
        }),
        category_id: Yup.number()
            .nullable()
            .when(['product_id'], {
                is: (product_id) => product_id === null,
                then: Yup.number().required(),
        }),
    })

    const defaultValues = useMemo<TInputCaseBuilderDetailsStep1Inputs>(() => {
        return {
            product_id: initialCaseDetails?.product_id ?? null,
            category_id: null,
            sale_type: initialCaseDetails?.sale_type ?? null,
            is_shippable: initialCaseDetails?.is_shippable ?? false,
            is_service: initialCaseDetails?.is_service ?? false,
            is_subscription: initialCaseDetails?.is_subscription ?? false,
            new_product_name: '',
            new_product_description: '',
            type_id: initialCaseDetails?.is_shippable
                ? 1
                : initialCaseDetails?.is_service
                ? 4
                : 2,
        }
    }, [initialCaseDetails])

    const {
        watch,
        setValue,
        formState: { isValid },
        // TODO: Known React-Hook-Form typescript issue - Should be resolved with RHF's v8. Once resolved, need to switch typescript's "any" to "TInputCaseBuilderDetailsStep1Inputs".
    } = useForm<any>({
        defaultValues: defaultValues,
        values: defaultValues,
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
    })

    const handleSetValues = (valuesObj: { [key: string]: any }) => {
        for (const [key, value] of Object.entries(valuesObj)) {
            setValue(key, value, { shouldDirty: true, shouldValidate: true })
        }
        setTriggerFormFieldsUpdate((prev) => !prev)
    }

    const checkFormBuilderHasInvalidFields = (fields: any) => {
        return !fields.find(
            (obj: FormBuilderField) => obj.required && !obj.value
        )
            ? true
            : false
    }

    const handleFormBuilderValueChange = (field: number, value: any) => {
        const newFormBuilderValues = formBuilderValues.map(
            (obj: FormBuilderField) =>
                obj.id === field
                    ? {
                          ...obj,
                          value,
                      }
                    : obj
        )
        const hasInvalidFields = checkFormBuilderHasInvalidFields(
            newFormBuilderValues
        )

        setFormBuilderValues(newFormBuilderValues)
        setIsFormBuilderValid(hasInvalidFields)
    }

    useEffect(() => {
        if (initialCaseDetails) {
            const isEmptySelectProductId = Boolean(
                selectValues.product_id === 'Select a product from the list' &&
                    watch('product_id')
            )
            setLoadingProductTypesList(true)
            !productSearchValue &&
                isEmptySelectProductId &&
                setLoadingInitialProductType(true)
            axios
                .get('/builder/products', {
                    params: {
                        limit: 999,
                        mid_id: caseInfo.mid_id,
                        search: productSearchValue,
                    },
                })
                .then(({ data: { data } }) => {
                    if (isMounted.current)
                        setProductTypesList([
                            { id: 0, name: 'Select a product from the list' },
                            ...data.map((product: any) => {
                                return {
                                    ...product,
                                    name: `${product.name}: ${product.type.name}`,
                                    originalName: product.name,
                                }
                            }),
                        ])
                    if (
                        initialCaseDetails?.product_id &&
                        isEmptySelectProductId &&
                        isMounted.current
                    ) {
                        const productName = data.find(
                            (item: any) =>
                                item?.id === initialCaseDetails?.product_id
                        )
                        setSelectedProductDetails({
                            ...productName,
                            name: `${productName.name}: ${productName.type.name}`,
                            originalName: productName.name,
                        })
                        setSelectValues({
                            ...selectValues,
                            product_id: `${productName.name}: ${productName.type.name}`,
                        })
                    }
                })
                .catch((err) => {})
                .finally(() => {
                    if (isMounted.current) {
                        setLoadingProductTypesList(false)
                        setLoadingInitialProductType(false)
                    }
                })
        }
        // eslint-disable-next-line
    }, [productSearchValue])

    useEffect(() => {
        if (initialCaseDetails) {
            setLoadingCategoryTypesList(true)
            axios
                .get('/builder/products/categories', {
                    params: {
                        limit: 999,
                        search: categorySearchValue,
                    },
                })
                .then(({ data: { data } }) => {
                    if (isMounted.current) setCategoryTypesList(data)
                    // const booksEntry = data.find((obj: any) => obj.name.toLowerCase() === 'books')
                    if (data) {
                        handleSelectedValue('category_id', {id: data[0].id.toString(),name: data[0].name})
                    }
                })
                .catch((err) => {})
                .finally(() => {
                    if (isMounted.current) setLoadingCategoryTypesList(false)
                })
        }
        // eslint-disable-next-line
    }, [categorySearchValue])

    const handleTransactionInfo = (value: any, property: string) => {
        // Needed since 'is_shippable' and 'is_service' cannot both be true, but both can be false.
        if (
            (property === 'is_shippable' && value === 'yes') ||
            (watch('is_shippable') && property === 'is_subscription')
        ) {
            return handleSetValues({
                is_shippable: true,
                is_service: false,
                ...(property === 'is_subscription' && {
                    is_subscription: Boolean(value === 'yes'),
                }),
                type_id: 1,
            })
        }
        if (
            (property === 'is_service' && value === 'yes') ||
            (watch('is_service') && property === 'is_subscription')
        ) {
            return handleSetValues({
                is_service: true,
                is_shippable: false,
                ...(property === 'is_subscription' && {
                    is_subscription: Boolean(value === 'yes'),
                }),
                type_id: 4,
            })
        }
        handleSetValues({
            [property]: Boolean(value === 'yes'),
            type_id: 2,
        })
    }

    const handleSelectedValue = (key: string, e: TOption | null) => {
        if (!e?.id) setProductSearchValue('')
        const optionWithAllProps = e as any
        setSelectedProductDetails(optionWithAllProps)
        handleSetValues({
            [key]: e?.id ? e?.id : null,
        })
        setSelectValues({
            ...selectValues,
            [key]: e?.name,
        })
    }

    useEffect(() => {
        const groupFields: FormBuilderField[] = []
        if (initialCaseDetails?.groups && !formBuilderInitialized) {
            initialCaseDetails.groups.map((group: any) => {
                return group.fields.map((field: any) => {
                    if (isFormBuilderValid && field.is_required && !field.value)
                        setIsFormBuilderValid(false)
                    return groupFields.push({
                        id: field.id,
                        value: field.value,
                        required: field.is_required,
                    })
                })
            })
            setFormBuilderInitialized(true)
            setFormBuilderValues(groupFields)
            setIsFormBuilderValid(checkFormBuilderHasInvalidFields(groupFields))
        }
    }, [initialCaseDetails, isFormBuilderValid, formBuilderInitialized])

    useEffect(() => {
        const isEnableSaveAndContinue =
            isValid
            && !loadingProductTypesList
            && !loadingCategoryTypesList
            && isFormBuilderValid
        setEnableSaveAndContinue(isEnableSaveAndContinue)
        setFormInputValues({
            ...watch(),
            fields: [
                ...formBuilderValues.map((obj: FormBuilderField) => {
                    const { id, value } = obj

                    return { id, value }
                }),
            ],
        })
        if (watch('type_id') !== formProductTypeId)
            setFormProductTypeId(watch('type_id'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isValid,
        loadingProductTypesList,
        loadingCategoryTypesList,
        isFormBuilderValid,
        formBuilderValues,
        formProductTypeId,
        triggerFormFieldsUpdate,
    ])

    const dueDate = new Date(caseInfo?.date_due) ?? undefined
    let formattedDueDate = undefined
    // This ridiculousness is to get around a test error that does not actually exist.
    try {
        if (dueDate && caseInfo?.date_due) formattedDueDate = format(dueDate.setDate(dueDate.getDate() + 1), 'MMMM dd, yyyy')
    } catch(e) {}

    return (
        <div id={idDirectory.divRoot}>
            <form>
                {/* SECTION ONE */}
                <div
                    className={
                        'emp-inputCaseBuilderDetailsStep1-sectionOneContainer'
                    }
                    id={idDirectory.divSectionOne}
                >
                    <div id={idDirectory.divReasonCode}>
                        <Typography
                            variant="body1"
                            className={
                                'emp-inputCaseBuilderDetailsStep1-headerTypography'
                            }
                            id={`${idDirectory.typographyHeader}-reason-code`}
                        >
                            Reason Code and Information:
                        </Typography>
                        <Typography variant="h3">
                            {`${caseInfo?.reason_code ?? ''} - ${
                                caseInfo?.reason_code_description ?? ''
                            }`}
                        </Typography>
                        <Typography variant="body1">
                            There is no additional information on this dispute.
                        </Typography>
                        <Divider
                            className={
                                'emp-inputCaseBuilderDetailsStep1-divider'
                            }
                        />
                    </div>
                    <div
                        className={
                            'emp-inputCaseBuilderDetailsStep1-qualityNotesContainer'
                        }
                        id={idDirectory.divQualityNotes}
                    >
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            className={
                                'emp-inputCaseBuilderDetailsStep1-exclamationCircleIcon'
                            }
                        />
                        <Typography variant="body1">
                            Failure to complete this information by{' '}
                            <span
                                className={
                                    'emp-inputCaseBuilderDetailsStep1-dueDateTypography'
                                }
                            >
                                {caseInfo?.date_due && formattedDueDate}
                            </span>{' '}
                            will result in a permanent loss of funds.
                        </Typography>
                    </div>
                </div>
                {/* SECTION TWO */}
                <div
                    className={
                        'emp-inputCaseBuilderDetailsStep1-sectionTwoContainer'
                    }
                    id={idDirectory.divSectionTwo}
                >
                    <div id={idDirectory.divSalesMethods}>
                        <Typography
                            variant="body1"
                            className={
                                'emp-inputCaseBuilderDetailsStep1-headerTypography'
                            }
                            id={`${idDirectory.typographyHeader}-sales-method`}
                        >
                            Sales Method
                        </Typography>
                        <Divider
                            className={
                                'emp-inputCaseBuilderDetailsStep1-divider'
                            }
                        />
                        <Typography
                            variant="body1"
                            className={
                                'emp-inputCaseBuilderDetailsStep1-alertTypography'
                            }
                        >
                            Please select the sales method.
                        </Typography>
                        <div
                            className={
                                'emp-inputCaseBuilderDetailsStep1-salesMethodBtnsContainer'
                            }
                        >
                            {salesMethodsList.map((method) => (
                                <Box
                                    key={`sales-method-${method.id}`}
                                    className={`emp-inputCaseBuilderDetailsStep1-salesMethodBtn`}
                                    id={`${idDirectory.btnSalesMethod}-${method.name}`}
                                    onClick={() =>
                                        handleSetValues({
                                            sale_type: method.id,
                                        })
                                    }
                                    sx={[
                                        method.id === watch('sale_type')
                                            ? {
                                                  border: `1px solid ${clientTheme.secondary}`,
                                                  backgroundColor:
                                                      clientTheme.secondary,
                                                  color: '#fff',
                                              }
                                            : {
                                                  border: '1px solid #d1d1d1',
                                                  backgroundColor: '#EFEFEF',
                                                  '&:hover': {
                                                      backgroundColor:
                                                          '#d1d1d1',
                                                  },
                                              },
                                    ]}
                                >
                                    <FontAwesomeIcon
                                        icon={method.icon}
                                        className={
                                            'emp-inputCaseBuilderDetailsStep1-salesMethodIcon'
                                        }
                                    />
                                    {method.name}
                                </Box>
                            ))}
                        </div>
                    </div>
                </div>
                {/* SECTION THREE */}
                <div
                    className={
                        'emp-inputCaseBuilderDetailsStep1-sectionThreeContainer'
                    }
                    id={idDirectory.divSectionThree}
                >
                    <div>
                        <div id={idDirectory.divCustomerInfo}>
                            <CaseBuilderFormBuilder
                                fieldGroups={initialCaseDetails?.groups || []}
                                renderOneSection={true}
                                handleFormBuilderValueChange={
                                    handleFormBuilderValueChange
                                }
                                fieldErrorList={fieldErrorList}
                            />
                        </div>
                        <div
                            className={
                                'emp-inputCaseBuilderDetailsStep1-transactionInfoContainer'
                            }
                            id={idDirectory.divTransactionInfo}
                        >
                            <Typography
                                variant="body1"
                                className={
                                    'emp-inputCaseBuilderDetailsStep1-headerTypography'
                                }
                                id={`${idDirectory.typographyHeader}-transaction-info`}
                            >
                                Transaction Information
                            </Typography>
                            <Divider
                                className={
                                    'emp-inputCaseBuilderDetailsStep1-divider'
                                }
                            />
                            <div>
                                {transactionInfoList.map(
                                    (question: ITransactionInfoList) => {
                                        return (
                                            <div
                                                className={
                                                    'emp-inputCaseBuilderDetailsStep1-transactionQuestionContainer'
                                                }
                                                id={`${idDirectory.divtransactionQuestion}-${question.name}`}
                                                key={`key-transactionInfo-${question.accessor}`}
                                            >
                                                <Typography variant="body1">
                                                    {question.question}
                                                </Typography>
                                                <RadioGroup
                                                    value={
                                                        watch(question.accessor)
                                                            ? 'yes'
                                                            : 'no'
                                                    }
                                                    onChange={(e) => {
                                                        handleTransactionInfo(
                                                            e.target.value,
                                                            question.accessor
                                                        )
                                                    }}
                                                    row
                                                >
                                                    <FormControlLabel
                                                        value={'yes'}
                                                        control={
                                                            <Radio
                                                                size={'small'}
                                                                color="secondary"
                                                            />
                                                        }
                                                        label="Yes"
                                                    />
                                                    <FormControlLabel
                                                        value={'no'}
                                                        control={
                                                            <Radio
                                                                size={'small'}
                                                                color="secondary"
                                                            />
                                                        }
                                                        label="No"
                                                    />
                                                </RadioGroup>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                    <div id={idDirectory.divProductInfo}>
                        <Typography
                            variant="body1"
                            className={
                                'emp-inputCaseBuilderDetailsStep1-headerTypography'
                            }
                            id={`${idDirectory.typographyHeader}-product-info`}
                        >
                            Product Information
                        </Typography>
                        <Divider
                            className={
                                'emp-inputCaseBuilderDetailsStep1-divider'
                            }
                        />
                        <Typography
                            variant="body1"
                            style={{ fontSize: '12px' }}
                        >
                            Please Select one of the following:
                        </Typography>
                        <div
                            className={`emp-inputCaseBuilderDetailsStep1-productContainer`}
                        >
                            <div
                                className={
                                    'emp-inputCaseBuilderDetailsStep1-selectFieldContainer'
                                }
                            >
                                <SearchableSelect
                                    value={selectValues.product_id}
                                    onValueChange={(e) => {
                                        handleSelectedValue('product_id', e)
                                    }}
                                    options={
                                        !loadingProductTypesList
                                            ? productTypesList ?? []
                                            : []
                                    }
                                    searchValue={productSearchValue}
                                    onSearchChange={setProductSearchValue}
                                    accessor={'name'}
                                    testId={'productName'}
                                    debounceDelay={200}
                                    textFieldLabel={'Product Name'}
                                    loadingValues={loadingProductTypesList}
                                    loadingInitialValue={
                                        loadingInitialProductType
                                    }
                                >
                                    {productTypesList.length < 2 &&
                                        !loadingProductTypesList && (
                                            <div
                                                className={
                                                    'emp-inputCaseBuilderDetailsStep1-searchableSelectText'
                                                }
                                            >
                                                No products found.
                                            </div>
                                        )}
                                </SearchableSelect>
                            </div>
                            <Typography
                                variant="body1"
                                style={{
                                    textAlign: 'center',
                                    color: '#B91200',
                                    margin: '10px 0px',
                                }}
                            >
                                or manually enter a new product
                            </Typography>
                            <Divider
                                className={
                                    'emp-inputCaseBuilderDetailsStep1-divider'
                                }
                            />
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                size="small"
                                label={'New Product Name or Group'}
                                style={{ marginTop: 10 }}
                                InputLabelProps={{ shrink: true }}
                                className={
                                    'emp-inputCaseBuilderDetailsStep1-fieldContainer'
                                }
                                disabled={Boolean(watch('product_id'))}
                                value={watch('new_product_name')}
                                onChange={(e) =>
                                    handleSetValues({
                                        new_product_name: e.target.value,
                                    })
                                }
                                name={'new_product_name'}
                            />
                            <div
                                className={
                                    'emp-inputCaseBuilderDetailsStep1-selectFieldContainer'
                                }
                            >
                                <SearchableSelect
                                    value={selectValues.category_id}
                                    onValueChange={(e) => {
                                        handleSelectedValue('category_id', e)
                                    }}
                                    options={
                                        !loadingCategoryTypesList
                                            ? categoryTypesList ?? []
                                            : []
                                    }
                                    searchValue={categorySearchValue}
                                    onSearchChange={setCategorySearchValue}
                                    accessor={'name'}
                                    testId={'productCategories'}
                                    debounceDelay={200}
                                    textFieldLabel={'Product Categories'}
                                    loadingValues={loadingCategoryTypesList}
                                    disabled={Boolean(watch('product_id'))}
                                >
                                    {!categoryTypesList.length &&
                                        !loadingCategoryTypesList && (
                                            <div
                                                className={
                                                    'emp-inputCaseBuilderDetailsStep1-searchableSelectText'
                                                }
                                            >
                                                No categories found.
                                            </div>
                                        )}
                                </SearchableSelect>
                            </div>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                size="small"
                                multiline
                                rows={4}
                                label={'New Product Description'}
                                style={{ marginTop: 14 }}
                                InputLabelProps={{ shrink: true }}
                                className={
                                    'emp-inputCaseBuilderDetailsStep1-fieldContainer'
                                }
                                disabled={Boolean(watch('product_id'))}
                                value={watch('new_product_description')}
                                onChange={(e) =>
                                    handleSetValues({
                                        new_product_description: e.target.value,
                                    })
                                }
                                name={'new_product_description'}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default InputCaseBuilderDetailsStep1

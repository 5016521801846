import React from 'react'
import { CircularProgress } from '@mui/material'
import 'views/views.css'

/**
 * @name LoadingIndicator
 * @desc Displays loading message with MUI Circular Progress spinner
 * @param text Display text override. Defaults to ...Loading
 */
export const LoadingIndicator = ({
    text = 'Loading...',
    iconOnly = false,
    className,
}: {
    text?: string
    iconOnly?: boolean
    className?: string
}): React.ReactElement => {
    return (
        <span>
            <CircularProgress
                className={`emp-loadingIndicator-circularProgress ${className}`}
                color="secondary"
            />
            {!iconOnly && (
                <span className={'emp-loadingIndicator-text'}>{text}</span>
            )}
        </span>
    )
}

export default LoadingIndicator

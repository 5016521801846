import BaseService, { PageOf } from 'lib/BaseService'

interface ServerChargebacksByStatus {
    count: number
    case_status: string
}

type ChargebacksByStatus = (
    merchantId: string,
    params?: any
) => Promise<ServerChargebacksByStatus[]>

type ChargebacksByCardType = (
    merchantId: string,
    params?: any
) => Promise<PageOf>

export default class ReportService extends BaseService {
    chargebacksByStatus: ChargebacksByStatus = (merchantId, params) => {
        return this.axios
            .get(
                this.injectParams('/cm/cases/reports/cases-status-totals', {
                    client_id: merchantId,
                    ...params,
                })
            )
            .then((r) => r.data)
    }

    chargebacksByCardType: ChargebacksByCardType = (merchantId, params?) => {
        return this.axios
            .get(
                this.injectParams('/cm/cases/reports/card-type', {
                    merchant_id: merchantId,
                    limit: 5,
                    ...params,
                })
            )
            .then((r) => r.data.filter((data: any) => data.count > 0))
    }
}

import EntFeature from "./EntFeature"

// Brought in from ds-core. Needed?
export interface IPermission {
    id: number | string
    name: string
    identity: string
    on?: boolean
    category: string
    url?: string | null
    // TODO - 4.1.5 update (Should be 'tags?: string[] | []')
    tags?: string[] | any[]
}

export interface FeatureDetail {
    id: number | string;
    alias: EntFeature;
}

export interface Resource {
    id: number;
    alias: EntFeature;
    group: string | null;
    name: string;
    url: string | null;
    icon: string | null;
    is_enabled: boolean;
    is_menu_resource: boolean;
}

export interface ActiveMerchant {
    id: number;
    business_name: string;
    show_alerts_banner?: boolean | null;
    status: {
        id: number | string;
        name: string;
    };
    features?: any[] | null;
}

export interface UserRole {
    id: number;
    name: string;
    alias?: string;
}

export interface UserGroup {
    id: number;
    name: string;
}

export interface NamePart {
    fname: string;
    lname: string;
}

export interface IUserInfo {
    id: number | undefined;
    fname: string | undefined;
    lname: string | undefined;
    username: string | undefined;
    email: string | undefined;
    role: UserRole;
    groups: UserGroup[];
    merchant: ActiveMerchant | undefined;
    is_federated: boolean;
    is_read_only: boolean;
    resources: Resource[];
    features: FeatureDetail[];
    allow_resources: number[];
    theme_variant: number | undefined
}

export interface ICompany {
    id: number
    name: string
}

/**
 *  The main class for users (generally authed Users)
 *  be careful not to add more to user to prevent it from becoming a god object
 */
class User {
    public id: number | undefined

    public name: string | undefined

    public fname: string | undefined

    public lname: string | undefined

    public username: string | undefined

    public email: string | undefined

    public groups: UserGroup[] | undefined

    public role: UserRole | undefined

    public merchant: ActiveMerchant | undefined

    public is_federated: boolean | undefined

    public is_read_only: boolean | undefined

    public resources: Resource[]

    public features: FeatureDetail[]

    public avatar: string | undefined

    public company: ICompany | undefined

    public allow_resources: number[] | undefined

    public theme_variant: number | undefined

    private _loginRepresentation: string | undefined

    private _personRepresentation: string | undefined

    constructor() {
        this.id = undefined
        this.fname = undefined
        this.lname = undefined
        this.email = undefined
        this.username = undefined
        this.groups = []
        this.role = undefined
        this.merchant = undefined
        this.is_federated = undefined
        this.is_read_only = undefined

        // Avatar and Company were brought over from ds-core? Needed?
        this.avatar = undefined
        this.company = undefined

        this.resources = []
        this.features = []
        this.allow_resources = []
        this.theme_variant = undefined
    }

    public fullName(): string {
        // Space between only if both exist
        return `${this.fname ?? ''}${this.lname ? ` ${this.lname ?? ''}` : ''}`
    }

    // Get the first initial, or both if there is a final name
    public initials(): string {
        const first = this.fname?.substring(0, 1).toUpperCase() ?? ''
        const last = this.lname?.substring(0, 1).toUpperCase() ?? ''
        return `${first}${last}`
    }

    /**
     * Obtain two character representation (technically not initials, just first two)
     */
    public usernameInitials(): string {
        return this.email?.substring(0, 2)?.toUpperCase() ?? ''
    }

    public getResources(): Resource[] {
        return this.resources
    }

    /**
     * Cascade to get what a user uses to login, UNLESS it's a uuid
     */
    public loginRepresentation(): string | undefined {
        return this.email
    }

    /**
     * Cascade to get a person's username, UNLESS it's a uuid, then fallback to name
     */
    public personRepresentation(): string | undefined {
        // eslint-disable-next-line no-underscore-dangle
        this._personRepresentation = this._personRepresentation || ( // Regex to match uuid
            this.username && !/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(this.username) ?
                this.username : 
                this.fullName()
        )
        // eslint-disable-next-line no-underscore-dangle
        return this._personRepresentation
    }

    public getActiveMerchant(): ActiveMerchant | undefined {
        return this.merchant
    }

    public getActiveMerchantName(): string | undefined {
        return this.merchant?.business_name
    }

    /**
     *  Convert from data to object, e.g. json
     */
    public fromData(data: IUserInfo): User {
        this.id = data.id
        this.fname = data.fname
        this.lname = data.lname
        this.username = data.username
        this.email = data.email
        this.role = data.role
        this.is_federated = data.is_federated
        this.is_read_only = data.is_read_only 
        this.merchant = data.merchant
        this.resources = data.resources
        this.features = data.features
        this.allow_resources = data.allow_resources
        this.theme_variant = data.theme_variant
        return this
    }

    /**
     * Take an incoming full name and split it up to first and last if possible
     **/
    static transformFullname(fullName: string): NamePart {
        return {
            fname: fullName?.split(' ')?.slice(0, -1).join(' ') || '',
            lname: fullName?.split(' ')?.slice(-1)?.join(' ') || ''
        }
    }

    /**
     * Whether the user can even have access to a certain feature
     * @param {EntFeature} feature The feature identity
     * WARNING! This is NOT A SECURITY FEATURE users can edit their own data
     */
    public hasFeature(feature: EntFeature | EntFeature[]): boolean {
        if (typeof feature === 'string') {
            return this.features.some((presentFeature) => presentFeature?.alias === feature)
        }
        return this.features.some((presentFeature) => feature.includes(presentFeature?.alias))
    }

    /**
     * Whether the user has apparent permission to a feature currently
     * WARNING! This is NOT A SECURITY FEATURE users can edit their own data
     * @param {EntFeature} feature 
     */
    public hasPermission(feature: EntFeature | EntFeature[]): boolean {
        if (typeof feature === 'string') {
            return this.hasFeature(feature) || this.resources.some(resource => resource.alias === feature)
        }
        return this.hasFeature(feature) || this.resources.some(resource => feature.includes(resource.alias))
    }
}

export default User

import { useState } from 'react'
import { SortInfo, SWRActions, ParamSetter } from './index'

export const useSWRActions = (
    viewInstance: Record<string, any>,
    paramSetter: ParamSetter
): SWRActions => {
    const { params, setQueryParams, setParams, reset } = paramSetter
    // selected rows are the index(s) of the selected row(s) in the datagrid
    const [selectedRows, setSelectedRow] = useState<string[]>([])

    //** ROW SELECTION LOGIC */

    const handleRowSelection = (index: string) => {
        // if the row is already selected, remove it from the selectedRows array
        if (selectedRows.includes(index)) {
            setSelectedRow(selectedRows.filter((row) => row !== index))
        } else {
            setSelectedRow([...selectedRows, index])
        }
    }

    const handleSelectAllRows = (rowIndexList: string[]) => {
        setSelectedRow(rowIndexList)
    }

    const handleUnselectAllRows = () => setSelectedRow([])

    const selectSingleRowClearRest = (index: string) => {
        setSelectedRow([...selectedRows, index])
    }

    // ** END ROW SELECTION LOGIC */

    //if isValidating === true and the data is empty [], swr is still loading endpoint
    //if isValidating === false and the data is empty [], then the data is empty and loading is complete

    const swrLoadingState = () => {
        const { data, isValidating } = viewInstance
        if (isValidating && data.length === 0) return true
        if (!isValidating && data.length === 0) return false
        else return false
    }

    return {
        total: viewInstance.pagination?.total ?? 0,
        rowsPerPage: viewInstance.pagination?.limit ?? params.limit,
        setRowsPerPage: (size: number) => {
            setParams({ limit: size, start: 0 })
        },
        search: params.search ?? '',
        setSearch: (query: string) => setParams({ search: query }),
        // if viewInstance is currently validating (ongoing request) and data is undefined, SWR is waiting for a response.
        isLoading: swrLoadingState(),
        error: viewInstance.error,
        clearError: () => {},
        page:
            viewInstance.pagination?.start / viewInstance.pagination?.limit + 1,
        setPage: (page: number) =>
            setParams({
                start: viewInstance.pagination?.limit * (page - 1),
            }),
        setSortBy: (sort: boolean, accessor: string) =>
            setParams({ sort_by: accessor, sort_order: sort ? 'asc' : 'desc' }),
        setSortInfo: (sortInfo: SortInfo) => {
            setParams({
                sort_by: sortInfo.sortBy,
                sort_order: sortInfo.sortDesc,
            })
        },
        setSortDesc: (value) => setParams({ sort_by: value }),
        sortInfo: {
            sortBy: params.sort_by ?? '',
            sortDesc: params.sort_order ?? 'asc',
        },
        globalSearch: params.search ?? '',
        setDescriptorFilter: (descriptor: string) =>
            setParams({ descriptor: descriptor }),
        setGlobalSearch: (query: string) => setParams({ search: query }),
        setDateRange: (dateRange: string) =>
            setParams({ date_range: dateRange }),
        exportAll: () => {
            // figure this out
        },
        setQueryParams: (queryParams: any) => {
            let queryParamArray: any[] = []
            Object.entries(queryParams).forEach(([key, value]) => {
                queryParamArray.push([key, String(value)])
            })
            setQueryParams(queryParamArray)
        },
        invalidate: viewInstance.mutate,
        selectRow: handleRowSelection,
        selectAllRows: handleSelectAllRows,
        unselectAllRows: handleUnselectAllRows,
        selectedRows,
        selectSingleRowClearRest,
        resetEndpoint: reset,
    }
}

import React from 'react'
import VisaCEUndisputedTransactionDetails from './VisaCEUndisputedTransactionDetails'

interface VisaCEUndisputedTransactionFormProps {
    caseInfo: any
    countries: any
    currencies: any
    deleteUndisputedCases: any
}

const VisaCEUndisputedTransactionForm = ({
    caseInfo,
    currencies = [],
    deleteUndisputedCases,
}: VisaCEUndisputedTransactionFormProps) => {

    return(
        <div className={`emp-visaCEUndisputedTransactionForm-formRoot`}>
            <VisaCEUndisputedTransactionDetails
                currencies={currencies}
                undisputedCases={caseInfo}
                deleteUndisputedCases={deleteUndisputedCases}
            />
        </div>
    )
}

export default VisaCEUndisputedTransactionForm
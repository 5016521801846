import React from 'react'
import { RowModel } from '@material-ui/data-grid'
import {
    CaseMenu,
    CreditCardProcessorType,
    DataGridActions,
    DataGridSlim,
    Searchbar,
    VerdictType,
    AlertSnackbar,
} from 'components'
import { useColumnDefs } from 'hooks/useColumnDefs/useColumnDefs'
import { useCases } from '../useCases'
import {
    ActionsCell,
    ViewDocumentIconAction,
    EditChargebackCell,
    IssuerDocsCell,
} from './Cells'
import { useSWRContext } from 'context/SWRContext'
import { CaseData } from 'hooks/cb-api'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface ChargebacksGridProps {
    data: RowModel[]
    contextMenuItems: any
    canViewPartnerCompanyId: boolean
    canViewIssuerDocs: boolean
    canViewOutcomeVerdicts: boolean
    canViewPlatform: boolean
    disableSelectAllCheckbox?: boolean
    clearSelectedRows: boolean
    params?: any
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    isLoadingAdvFilters: boolean
}

export const amountValueFormatter = (value: string) => {
    return Number(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
}

/**
 * To separate out just the grid of the view,
 * so the column definitions don't have to be in the view
 * We could also move this to it's own view component in the future
 * @param data RowData[] Info about chargebacks
 * @param loading boolean Whether to show the loading state
 */

export const ChargebacksGrid = ({
    data,
    contextMenuItems,
    canViewPartnerCompanyId,
    canViewIssuerDocs,
    canViewOutcomeVerdicts,
    canViewPlatform,
    clearSelectedRows,
    params,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    isLoadingAdvFilters,
}: ChargebacksGridProps) => {
    const { user } = useAuthedUser()
    const { swrActions } = useSWRContext()
    const { CHARGEBACK_EXPORT_HEADERS, DATA_GRID } = useFeatureToggle(
        'CHARGEBACKS'
    )
    const showContextMenu = DATA_GRID.CONTEXT_MENU?.enabled

    const {
        isLoading,
        setPage,
        setRowsPerPage,
        total,
        page,
        setGlobalSearch,
        rowsPerPage,
        setSortBy,
        sortInfo,
        // exportAll,
        invalidate: rehydrateView,
        selectRow,
        selectAllRows,
        unselectAllRows,
        selectedRows: rowSelections,
    } = swrActions

    const {
        colDefs: columns,
        hiddenColumns,
        setHiddenColumns,
        //@ts-ignore
    } = useColumnDefs<CaseData>('chargebacks', user?.role?.alias, {
        cells: {
            actions: (cell: any) => (
                <ActionsCell
                    cell={cell}
                    rehydrateView={rehydrateView}
                    testId={'chargebacks'}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                />
            ),
            document: (cell: any) => (
                <ViewDocumentIconAction
                    caseId={cell.row.original.id}
                    disabled={!cell.row.original.properties.is_doc_available}
                    rowIndex={cell.row.id}
                    testId={'chargebacks'}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                />
            ),
            cc_type: (cell: any) => (
                <CreditCardProcessorType processor={cell.value} size="large" />
            ),
            verdict: (cell: any) => (
                <VerdictType verdict={cell.value} size="small" />
            ),
            dispute_amt: (cell: any) => (
                <span>
                    {amountValueFormatter(cell.row.original.dispute_amt)}
                </span>
            ),
            trans_amt: (cell: any) => (
                <span>{amountValueFormatter(cell.row.original.trans_amt)}</span>
            ),
            edit: (cell: any) => (
                <EditChargebackCell
                    rowData={cell.row.original}
                    rehydrateView={rehydrateView}
                    rowIndex={cell.row.id}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                />
            ),
            issuer_doc: (cell: any) => (
                <IssuerDocsCell
                    issuerDocs={cell.row.original.issuer_docs ?? []}
                    caseId={cell.row.original.id}
                    rowIndex={cell.row.id}
                    testId={'chargebacks'}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                />
            ),
            case_menu: (cell: any) => (
                <CaseMenu
                    row={cell.row.original}
                    rowIndex={cell.row.id}
                    onCaseHistoryOpen={(rowIndex: number) =>
                        selectRow(String(rowIndex))
                    }
                    onCaseHistoryClose={unselectAllRows}
                    caseHistoryDisplayType={'popover'}
                    testId={'chargebacks'}
                />
            ),
            is_credit: (cell: any) => (
                <span>{cell.row.original.is_credit ? 'Yes' : 'No'}</span>
            ),
            is_guaranteed: (cell: any) => (
                <span>{cell.row.original.is_guaranteed ? 'Yes' : 'No'}</span>
            ),
        },

        setSortBy,
        sortInfo,
    })

    const headers = CHARGEBACK_EXPORT_HEADERS.headers.filter(
        (i: { accessor: string; label: string | undefined }) =>
            !hiddenColumns.includes(i.accessor)
    )

    const {
        download,
        isLoadingDownloadExportAll,
        isLoadingDownloadCsv,
        isEmailExport,
        closeEmailExport,
        downloadXlsx,
        isLoadingDownloadXlsx,
        isLoadingDownloadXlsxAll,
        downloadError,
        downloadErrorString,
        closeDownloadError,
    } = useCases('Chargebacks', headers)

    const filteredColumns = columns.filter((i) => {
        if (i.accessor === 'partner_company_id') {
            return canViewPartnerCompanyId
        }
        if (i.accessor === 'issuer_doc') {
            return canViewIssuerDocs
        }
        if (i.accessor === 'verdict') {
            return canViewOutcomeVerdicts
        }
        if (i.accessor === 'processor') {
            return canViewPlatform
        }
        return true
    })

    return (
        <div id={'chargebacksGrid'}>
            <div className={'emp-searchbarContainer'}>
                <Searchbar
                    className={'emp-cssOutlinedInput'}
                    debounceDelay={200}
                    returnValue={setGlobalSearch}
                    testId={'chargebacks'}
                />
                <DataGridActions
                    printId="cb-cases-dg"
                    tableData={data}
                    dataActionPDF={true}
                    dataActionExportAllExcel={true}
                    // dataActionSwitchGrids={true}
                    dataForExportAll={[]}
                    columns={filteredColumns.filter((col) => col.Header)}
                    hiddenColumns={hiddenColumns}
                    setHiddenColumns={setHiddenColumns}
                    onExportAll={() => {
                        // exports total entries
                        download(
                            {
                                export_total: total,
                                ...params,
                            },
                            true
                        )
                    }}
                    onExportAllLoading={isLoadingDownloadExportAll}
                    onExportCsv={() => {
                        // exports total entries
                        download({
                            export_type: 'page',
                            export_total: total,
                            ...params,
                        })
                    }}
                    onExportCsvLoading={isLoadingDownloadCsv}
                    onExportXlsx={() =>
                        downloadXlsx(
                            {
                                export_type: 'page',
                                export_total: total,
                                ...params,
                            },
                            true
                        )
                    }
                    onExportXlsxLoading={isLoadingDownloadXlsx}
                    onExportAllXlsx={() =>
                        downloadXlsx({
                            export_total: total,
                            ...params,
                        })
                    }
                    onExportAllXlsxLoading={isLoadingDownloadXlsxAll}
                    fileName={'Chargebacks'}
                    testId={'chargebacks'}
                />
            </div>
            <DataGridSlim
                columns={filteredColumns}
                data={data}
                defaultRows={50}
                rowsPerPageOptions={[10, 25, 50, 100]}
                expandable={true}
                slim={false}
                loading={isLoading || isLoadingAdvFilters}
                onPageChange={setPage}
                onPageLimitChange={setRowsPerPage}
                totalEntries={total}
                pageSize={rowsPerPage}
                printId={'cb-cases-dg'}
                contextMenu={showContextMenu}
                hideRowCheckboxes={!showContextMenu}
                disableSelectAllCheckbox={!showContextMenu}
                contextMenuItems={contextMenuItems}
                hiddenColumns={hiddenColumns}
                hiddenColumnsEnabled={true}
                page={page}
                fixedLoadingIndicator={true}
                clearSelectedRows={clearSelectedRows}
                selectAllRowsInStore={selectAllRows}
                unselectAllRowsInStore={unselectAllRows}
                testId={'chargebacks'}
                handleRowSelection={selectRow}
                rowSelections={rowSelections}
                refreshDataGrid={() => rehydrateView()}
            />
            <AlertSnackbar
                content={{
                    message:
                        'This may take up to 15 minutes. We will send you an email with a link to download your export when it’s ready. Thank you!',
                    title: 'Export started.',
                    intent: 'success',
                }}
                onClose={closeEmailExport}
                autoHideDuration={30_000}
                open={isEmailExport}
                showCloseIcon
            />
            <AlertSnackbar
                content={{
                    message: downloadErrorString,
                    title: 'Error.',
                    intent: 'error',
                }}
                onClose={closeDownloadError}
                open={downloadError}
                showCloseIcon
            />
        </div>
    )
}

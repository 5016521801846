import React, { useEffect, useState } from 'react'
import { Typography, Card, CardContent, Divider } from '@mui/material'
import { getColDefs, ColumnConfig } from 'hooks/useColumnDefs/useColumnDefs'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { CaseData } from 'hooks/cb-api'

interface CaseInfoProps {
    caseInfo: CaseData
}

export const CaseInfo: React.FC<CaseInfoProps> = ({ caseInfo }) => {
    const { user } = useAuthedUser()
    const [casedetails, setCaseDetails] = useState<ColumnConfig[]>()

    useEffect(() => {
        //@ts-ignore
        getColDefs('caseManagement', user?.role?.alias).then((res) => {
            setCaseDetails(
                res.columns.filter(
                    (col) =>
                        col.cell !== 'actions' &&
                        col.cell !== 'document' &&
                        col.cell !== 'assign' &&
                        col.cell !== 'flag' &&
                        col.accessor !== 'date_completed' &&
                        col.accessor !== 'verdict' &&
                        col.cell !== 'case_menu'
                )
            )
        })
    }, [user])

    return (
        <Card id={'caseQCReviewCaseInfo'}>
            <CardContent>
                <Typography
                    variant="h6"
                    color="textSecondary"
                    className={'emp-cardTitle'}
                >
                    Case Details
                </Typography>
                <Divider className={'emp-divider'} />
                {casedetails?.map((column: ColumnConfig, idx: number) => {
                    //@ts-ignore
                    let fieldValue = caseInfo[column.accessor] ?? 'No info'

                    if (
                        column.accessor === 'assigned_user' &&
                        caseInfo[column.accessor]
                    ) {
                        fieldValue = `${
                            caseInfo[column.accessor]?.fname ?? ''
                        } ${caseInfo[column.accessor]?.lname ?? ''}`
                    }
                    return (
                        <div
                            className={'emp-caseDetailsSection'}
                            key={`key-${idx}`}
                        >
                            <Typography className={'emp-caseDetailsTitle'}>
                                {`${column.label}: `}
                            </Typography>
                            <Typography className={'emp-caseDetails'}>
                                {fieldValue}
                            </Typography>
                        </div>
                    )
                })}
                {caseInfo?.flag?.comment && (
                    <div className={'emp-caseDetailsSection'}>
                        <Typography className={'emp-caseDetailsTitle'}>
                            {`Comment: `}
                        </Typography>
                        <Typography className={'emp-caseDetails'}>
                            {caseInfo?.flag?.comment ?? 'No info'}
                        </Typography>
                    </div>
                )}
            </CardContent>
        </Card>
    )
}

/**
 * @description Directory for ID Attributes on StandardModal.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `standardModal-div-root`,
    divTitle: `standardModal-div-title`,
    divContent: `standardModal-div-content`,
    divActions: `standardModal-div-actions`,
    btnPrimary: `standardModal-btn-primary`,
    btnSecondary: `standardModal-btn-secondary`,
}

export default idDirectory

import React, { useEffect, useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import BarChart from 'components/BarChart'
import { IReportFilter } from 'views/Reports'
import { clientTheme } from 'theme-exports'

/**
 * @name ByPlatformPerCardType
 * @desc Render % of Chargebacks by Platform (Top 5) chart
 * @filterValues IReportsFilter
 */
const ByPlatformPerCardType = ({
    filterValues,
}: {
    filterValues: IReportFilter
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ [key: string]: number | string }[]>([])
    const [barDataKeys, setBarDataKeys] = useState<[string, number][]>([])

    useEffect(() => {
        setLoading(true)
        ReportsApi.getByCardTypePerPlatform(filterValues).then((data) => {
            setLoading(false)
            data.length ? setData([data[0]]) : setData([])
            setBarDataKeys(data[1])
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    const colorPalette = clientTheme.charts.pieCharts.byPlatformPerCardTypePieChart

    return (
        <BarChart
            chartTitle="% of Chargebacks by Card Type Per Platform (Top 5)"
            data={data}
            loading={loading}
            valueDataKey="value"
            stackedBarValueKeys={barDataKeys}
            showLegend
            colorPalette={colorPalette}
            //Remove if no showLegend
            height={280}
        />
    )
}

export default ByPlatformPerCardType

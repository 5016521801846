import React, { Component } from 'react'
import { Card, Typography } from '@mui/material'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

/**
 * This file contains a lot of inlined code, inlined svgs because we want to minimize
 * any chance of error within it.
 */

/**
 * Simple default low-ui error alert
 */
export const ErrorTitle = (
    <div
        role="alert"
        style={{
            color: '#e53935',
            background: 'white',
            font: '"Roboto", "Helvetica", "Arial", sans-serif;',
            fontSize: '1.5rem',
            boxShadow:
                '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
            padding: '1rem 0.3rem',
            textAlign: 'center',
            borderRadius: '4px',
            wordBreak: 'break-word',
        }}
    >
        Something went wrong!{' '}
        <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="exclamation-circle"
            className="svg-inline--fa fa-exclamation-circle fa-w-16 "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <path
                fill="currentColor"
                d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
            ></path>
        </svg>
    </div>
)

/**
 * A complete card ui for error
 */
// @ts-ignore
export const ErrorCard = (
    <Card style={{ justifyContent: 'center', margin: '1rem auto' }}>
        <div style={{ textAlign: 'center' }}>
            <h3>
                Something went wrong!{' '}
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="exclamation-circle"
                    className="svg-inline--fa fa-exclamation-circle fa-w-16 "
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                >
                    <path
                        fill="currentColor"
                        d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                    ></path>
                </svg>
            </h3>
        </div>
    </Card>
)

interface ISpecificErrorCard {
    area: string
}

/**
 * A customizable type for the error card
 */
const SpecificErrorCard = ({ area }: ISpecificErrorCard) => {
    const boxStyling = {
        justifyContent: 'center',
        minHeight: '4rem',
        margin: 'auto',
        textAlign: 'center',
        padding: '0.3rem',
        wordBreak: 'break-all',
    } as React.CSSProperties
    return (
        <Card style={boxStyling}>
            <Typography
                variant="h3"
                color="error"
                gutterBottom
                style={{ margin: '0.5rem' }}
            >
                Error <Icon icon={faExclamationCircle} />
            </Typography>
            <div style={{ textAlign: 'center' }}>
                <Typography variant="h5">
                    Something went wrong in {area}!
                </Typography>
            </div>
        </Card>
    )
}

export { SpecificErrorCard }

interface IErrorBoundaryProps {
    template?: React.ReactNode
    area?: string
}

interface IErrorBoundaryState {
    hasError: boolean
}

/**
 * Wrap an area to capture js errors if any occur
 * @prop {ErrorCard || Nodes} template What to show instead of the children on error
 * @prop {node | nodes} children The required area where if errors occur, the boundary template will be shown instead
 */
class ErrorBoundary extends Component<
    IErrorBoundaryProps,
    IErrorBoundaryState
> {
    constructor(props: Readonly<IErrorBoundaryProps>) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(_error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error: any, info: any) {
        // You can also log the error to an error reporting service
        console.log('ErrorBoundary captured error:', error, info)
        // logErrorToMyService(error, info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return this.props.template ? (
                this.props.template
            ) : this.props.area ? (
                <SpecificErrorCard area={this.props.area} />
            ) : (
                ErrorTitle
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary

import React from 'react'
import { BulkClose } from 'components/BulkClose/BulkClose'
import { StandardModal } from 'components'

interface BulkCloseDialogProps {
    open: boolean
    onClose: () => void
}

export const BulkCloseDialog: React.FC<BulkCloseDialogProps> = ({
    open,
    onClose,
}) => {
    return (
        <StandardModal
            openModal={open}
            onModalClose={onClose}
            modalHeaderTitle={'Auto Accept'}
            testId={'autoAccept'}
            enablePrimaryActionBtn={false}
            enableSecondaryActionBtn={false}
            showClientThemeTopBorder
        >
            <div id={'chargebacksBulkCloseDialog'}>
                <BulkClose onClose={onClose} />
            </div>
        </StandardModal>
    )
}

/**
 * @description Directory for ID Attributes on Sidbar.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
 const idDirectory = {
    drawer: `sidebar-drawer-drawer`,
    divRoot: `sidebar-div-root`,
}

export default idDirectory
import React, { useEffect } from 'react'
import View from 'views/View'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useMidHealth } from './MidHealth.vm'
import idDirectory from './idAttributes'
import { AccentArea, MerchantSwitcher } from 'components'
import MidHealthGrid from './grids/MidHealthGrid'
import { withRequiredRole } from 'components/useRequireRole'
import EntFeature from 'models/EntFeature'

const MidHealth = () => {
    const { midHealthData, swrActions } = useMidHealth()
    const [rating, setRating] = React.useState('');

    const {
        isLoading,
        search,
        setSearch,
        rowsPerPage,
        setRowsPerPage,
        setPage,
        total,
        page,
        setSortBy,
        setSortDesc,
        sortInfo,
        invalidate: rehydrateView,
    } = swrActions

    useEffect(() => {
        if (search !== rating) setRating('')
    }, [search, rating, setRating])

    return (
        <View
            title="MID Health"
            breadcrumb="MID Health"
            HeaderInterior={<MerchantSwitcher testId="midHealth" />}
            testId="midHealth"
        >
            <AccentArea
                containsFullWidthGrid
                headerAction={
                    <div>
                    <FormControl fullWidth size="small" variant="outlined" style={{minWidth: '170px'}}>
                        <InputLabel id="rating-select-label">Rating</InputLabel>
                        <Select
                            fullWidth
                            labelId="rating-select-label"
                            id="rating-select"
                            value={rating}
                            label="Rating"
                            onChange={(e) => {
                                setRating(e.target.value as string)
                                setSearch(e.target.value as string)
                            }}
                        >
                            <MenuItem value={''}>ALL</MenuItem>
                            <MenuItem value={'Good'}>Good</MenuItem>
                            <MenuItem value={'Low'}>Low Risk</MenuItem>
                            <MenuItem value={'Medium'}>Medium Risk</MenuItem>
                            <MenuItem value={'High'}>High Risk</MenuItem>
                        </Select>
                    </FormControl>
                    </div>
                }
                testId="midHealth"
            >
                <div id={idDirectory.divContainer}>
                    <MidHealthGrid
                        data={midHealthData}
                        sortInfo={sortInfo}
                        total={total}
                        onPageSizeChange={setRowsPerPage}
                        loading={isLoading}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={setPage}
                        onSortDirectionChange={setSortDesc}
                        onSortChange={setSortBy}
                        setSearch={setSearch}
                        globalSearch={search}
                        onSearchChange={setSearch}
                        testId="midHealth"
                        rehydrateView={rehydrateView}
                    />
                </div>
            </AccentArea>
        </View>
    )
}

export default withRequiredRole(EntFeature.MID_HEALTH, MidHealth)

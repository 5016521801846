/**
 * @description Directory for ID Attributes on Chargebacks.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    gridContainer: `chargebacks-grid-container`,
    gridItem: `chargebacks-grid-item`,
    btnAdvSearch: `chargebacks-btn-advSearch`,
    btnCaseFilter: `chargebacks-btn-caseFilter`,
    btnAutoAccept: `chargebacks-btn-autoAccept`
}

export default idDirectory

/**
 * @description Directory for ID Attributes on MerchantMIDSDetails.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    labelHeader: `midDetails-label-header`,
    textField: `midDetails-text-field`,
    statusField: `midDetails-status-field`,
    switchField: `midDetails-switch-field`,
    radioField: `midDetails-radio-field`,
}

export default idDirectory

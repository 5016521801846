import React from 'react'
import { Helmet } from 'react-helmet'
import { Breadcrumbs, Link as MLink, Skeleton } from '@mui/material'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import idDirectory from './idAttributes'

interface IViewProps {
    breadcrumb: string // If we get >2 nested breadcrumbs, change this to pass them as a reactnode directly
    title: string // The page document title
    loading?: boolean
    category?: {
        content: React.ReactNode
        to?: string
    }
    children: React.ReactNode
    className?: string
    HeaderInterior?: React.ReactNode
    testId?: string
}

const blankSpaces = (
    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
)

/**
 * A wrapper to give a consistent form to all views
 */
const View = ({
    title,
    breadcrumb,
    loading = false,
    category,
    children,
    className,
    HeaderInterior,
    testId,
}: IViewProps): React.ReactElement => {
    const { enabled, site_name } = useFeatureToggle('SITE_NAME')

    const DEFAULT_SITE_NAME = 'Chargebacks911'

    return (
        <>
            <Helmet>
                {enabled ? (
                    <title>
                        {loading ? '' : title} - {site_name}
                    </title>
                ) : (
                    <title>
                        {loading ? '' : title} - {DEFAULT_SITE_NAME}
                    </title>
                )}
            </Helmet>
            <div id={`${idDirectory.view.divRoot}-${testId}`}>
                <div
                    id={`${idDirectory.view.divGrid}-${testId}`}
                    className={`${'emp-view-grid'} ${className}`}
                >
                    <Breadcrumbs aria-label="breadcrumb">
                        {category?.content && (
                            <MLink
                                id={`${idDirectory.view.linkContent}-${testId}`}
                                color="inherit"
                                href={category?.to}
                            >
                                {loading ? (
                                    <Skeleton>{blankSpaces}</Skeleton>
                                ) : (
                                    category?.content
                                )}
                            </MLink>
                        )}
                        <div
                            id={`${idDirectory.view.linkBreadcrumb}-${testId}`}
                            aria-current="page"
                            color="inherit"
                            className={`view-title breadcrumb ${'emp-view-heading'}`}
                        >
                            {loading ? (
                                <Skeleton>{blankSpaces}</Skeleton>
                            ) : (
                                breadcrumb
                            )}
                        </div>
                    </Breadcrumbs>
                    <span
                        id={`${idDirectory.view.spanHeaderInterior}-${testId}`}
                    >
                        {HeaderInterior}
                    </span>
                </div>
                <div id={`${idDirectory.view.divChildren}-${testId}`}>
                    {children}
                </div>
            </div>
        </>
    )
}

export default View

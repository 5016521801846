/**
 * @description Directory for ID Attributes on UpdateMerchant.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    checkboxAddContact: `merchant-checkbox-addContact`,
    labelHeader: `merchant-label-header`,
    inputField: `merchant-input-field`,
    statusField: `merchant-status-field`,
    switchField: `merchant-switch-field`,
    radioField: `merchant-radio-field`,
    selectField: `merchant-select-field`,
    btnCancel: `merchant-btn-cancel`,
    btnSubmit: `merchant-btn-submit`,
}

export default idDirectory

/**
 * helper to format byte size to a human readable form.
 *
 * @param bytes - size of file in number of bytes.
 *
 * @returns {string} - human readable size string i.e 1.3KB, 1MB.
 */
export const bytesToSize = (bytes: number): string => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`
}

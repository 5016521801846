/**
 * @description Directory for ID Attributes on StandardPopover.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `standardPopover-div-root`,
    divTitle: `standardPopover-div-title`,
    divContent: `standardPopover-div-content`,
    divActions: `standardPopover-div-actions`,
    btnPrimary: `standardPopover-btn-primary`,
    btnSecondary: `standardPopover-btn-secondary`,
}

export default idDirectory

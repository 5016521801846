import React from 'react'
import { CellProps } from 'react-table';
import { DataGridSlim } from 'components'
import { ExtendedColumn } from 'components/DataGrid/ExtendedColumn'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'

interface MidTop5DataGridProps {
    data: Record<string, any>[]
    loading: boolean
    testId: string
}

export const MidTop5DataGrid: React.FC<MidTop5DataGridProps> = ({
    data,
    loading,
    testId,
}) => {
    const { client } = useFeatureToggle('CLIENT')

    const columnDefinitions: ExtendedColumn[] = [
        {
            Header: 'MID',
            accessor: client === 'bluesnap' ? 'midAlias2' : 'mid',
            type: 'string',
        },
        {
            Header: 'Merchant Descriptor',
            accessor: 'midDescriptor',
            type: 'string',
        },
        {
            Header: 'MID Alias',
            accessor: 'midAlias',
            type: 'string',
        },
        {
            Header: 'New',
            accessor: 'new',
            type: 'number',
            Cell: ({ cell }: CellProps<Record<string, any>>) => (
                <span>
                    {Number(cell.row.original.new).toLocaleString('en-US')}
                </span>
            ),
        },
        {
            Header: 'Non-fraud',
            accessor: 'nonFraud',
            type: 'number',
            Cell: ({ cell }: CellProps<Record<string, any>>) => (
                <span>
                    {Number(cell.row.original.nonFraud).toLocaleString('en-US')}
                </span>
            ),
        },
        {
            Header: '% Non-fraud',
            accessor: 'percentNonFraud',
            type: 'number',
        },
        {
            Header: 'Fraud',
            accessor: 'fraud',
            type: 'number',
            Cell: ({ cell }: CellProps<Record<string, any>>) => (
                <span>
                    {Number(cell.row.original.fraud).toLocaleString('en-US')}
                </span>
            ),
        },
        {
            Header: '% Fraud',
            accessor: 'percentFraud',
            type: 'number',
        },
        {
            Header: 'Defended',
            accessor: 'defended',
            type: 'number',
            Cell: ({ cell }: CellProps<Record<string, any>>) => (
                <span>
                    {Number(cell.row.original.defended).toLocaleString('en-US')}
                </span>
            ),
        },
    ]
    const columns = React.useMemo(() => columnDefinitions, [columnDefinitions])
    const rows = React.useMemo(() => data, [data])

    return (
        <div id={'dashboardMidTop5DataGrid'}>
            <DataGridSlim
                totalEntries={5}
                defaultRows={25}
                noSelection
                onPageLimitChange={() => {}}
                onPageChange={() => {}}
                expandable={false}
                data={rows}
                columns={columns}
                loading={loading}
                paginate={false}
                stickyHeaders={false}
                testId={testId}
            />
        </div>
    )
}

import React from 'react'
import { format, parseISO } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label,
} from 'recharts'
import { LoadingIndicator } from 'components'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'

export interface MultiBarChartProps {
    data: any[]
    isLoading: boolean
    barList: { label: string; accessor: string }[]
    dataKeyXAxis: string
    testId: string
    colorPalette?: string[]
    chartHeight?: number
    noDataChartHeight?: number
}

const MultiBarChart: React.FC<MultiBarChartProps> = ({
    data = [],
    isLoading,
    barList,
    dataKeyXAxis,
    testId,
    colorPalette = clientTheme.charts.pieCharts.defaultPieChart,
    chartHeight = 325,
    noDataChartHeight = 335,
}) => {
    const CustomTooltipValue = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className={'emp-multiBarChart-tooltipContainer'}>
                    <table style={{ borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr style={{ backgroundColor: '#b3b3b3' }}>
                                <td
                                    colSpan={2}
                                    className={
                                        'emp-multiBarChart-tooltipTableHeader'
                                    }
                                >
                                    {label}
                                </td>
                            </tr>
                            {payload.map((item: any, i: number) => {
                                return (
                                    <tr key={`key-tooltip-${i}`}>
                                        <td
                                            className={
                                                'emp-multiBarChart-tooltipTableRow'
                                            }
                                            style={{
                                                borderRight:
                                                    '1px dotted #b3b3b3',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                size="xs"
                                                icon={faSquare}
                                                style={{
                                                    marginRight: '5px',
                                                    color: payload[i].fill,
                                                }}
                                            />
                                            {payload[i].name}
                                        </td>
                                        <td
                                            className={
                                                'emp-multiBarChart-tooltipTableRow'
                                            }
                                            style={{
                                                textAlign: 'right',
                                            }}
                                        >
                                            {payload[i].value}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            )
        }
        return null
    }

    return (
        <div id={`${idDirectory.divRoot}-${testId}`}>
            {!isLoading ? (
                data.length ? (
                    <ResponsiveContainer
                        id={`${idDirectory.divChart}-${testId}`}
                        height={chartHeight}
                        width="100%"
                    >
                        <BarChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey={dataKeyXAxis}
                                tick={{
                                    fontSize: '12px',
                                }}
                                tickFormatter={(date) =>
                                    format(parseISO(date), 'MM/dd')
                                }
                            />
                            <YAxis />
                            <Tooltip
                                content={<CustomTooltipValue />}
                                cursor={{ fill: '#f5f5f5' }}
                            />
                            <Legend
                                verticalAlign="bottom"
                                align="center"
                                wrapperStyle={{
                                    fontSize: '12px',
                                    left: 30,
                                }}
                            />
                            {barList.map((bar, idx) => {
                                if (bar.accessor === dataKeyXAxis) return null
                                return (
                                    <Bar
                                        key={`key-${bar.accessor}-${idx}`}
                                        dataKey={bar.accessor}
                                        name={bar.label}
                                        fill={
                                            colorPalette[
                                                idx % colorPalette.length
                                            ]
                                        }
                                    />
                                )
                            })}
                        </BarChart>
                    </ResponsiveContainer>
                ) : (
                    <ResponsiveContainer
                        id={`${idDirectory.divNoDataChart}-${testId}`}
                        height={noDataChartHeight}
                        width="100%"
                    >
                        <BarChart data={[]}>
                            <XAxis ticks={['No Data']}>
                                <Label
                                    value="No Data"
                                    offset={-160}
                                    position="insideTop"
                                />
                            </XAxis>
                            <YAxis />
                        </BarChart>
                    </ResponsiveContainer>
                )
            ) : (
                <div
                    className={'emp-multiBarChart-loadingContainer'}
                    id={`${idDirectory.divLoading}-${testId}`}
                >
                    <LoadingIndicator />
                </div>
            )}
        </div>
    )
}

export default MultiBarChart

import { endpointMap } from './endpoints'
import CB from 'lib'
import axios from 'axios'

interface DataModel {
    name: string
}

interface Criteria {
    // eslint-disable-next-line no-undef
    headers?: HeadersInit
    body?: string
    method?: string
    //filter?: () => unknown[],
    params?: Record<string, string>
}

type endpointKey = keyof typeof endpointMap

/**
 * @deprecated Please use follow the approach as UsersApi
 * simple api endpoint connection wrapper to give a unified approach to query/save/delete
 */
class Connect {
    static async query(
        model: DataModel,
        idOrCriteria: Criteria | string | number
    ): Promise<unknown> {
        // Get auth token
        const defaultHeaders = {
            'Content-Type': 'application/json',
            Authorization: await CB.auth.idToken(),
        }
        // Turn the criteria into actionable information, with defaults
        const {
            method = 'GET',
            headers = defaultHeaders,
            body = undefined,
            params = undefined,
        } =
            typeof idOrCriteria !== 'string' && typeof idOrCriteria !== 'number'
                ? (idOrCriteria as Criteria)
                : {}
        const finalIdOrNot =
            typeof idOrCriteria === 'string' || typeof idOrCriteria === 'number'
                ? `/${encodeURIComponent(idOrCriteria)}`
                : ''
        const endpoint = endpointMap[model.name as endpointKey]
            ? endpointMap[model.name as endpointKey]
            : model.name

        const url = `${endpoint}${finalIdOrNot}`

        //@ts-ignore
        return axios({
            method,
            url,
            headers,
            params,
            data: body,
        })
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                throw new Error(
                    `${error.status}: ${error.error} - ${model.name} unable to obtain data`
                )
            })
    }

    // Todo save
    // Todo delete
}

export default Connect

import React, { useState, useEffect } from 'react'
import {
    useTheme,
    Paper,
    TextField,
    InputAdornment,
    ClickAwayListener,
    Popper,
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { format } from 'date-fns'
import MaskedInput from 'react-text-mask'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faTimes } from '@fortawesome/free-solid-svg-icons'
import { dateUtilities } from 'utils/dateUtilities'
import { PresetDateRangePicker } from 'components'
import idDirectory from './idAttributes'
import { clientTheme } from 'theme-exports'

// date_range default is for the last 7 days
const defaultDateRangeOnLoad: {
    to: Date
    from: Date
} = dateUtilities.getPriorDays(7)
const { to, from } = defaultDateRangeOnLoad

// default date range input values
const defaultInputDateRanges: IDateRange = {
    to: format(to, 'yyyy-MM-dd'),
    from: format(from, 'yyyy-MM-dd'),
}

export interface IDateRange {
    to: string
    from: string
}

const TextMaskCustomFormat = React.forwardRef((props: any, ref) => {
    const { inputRef, ...other } = props
    return (
        <MaskedInput
            {...other}
            mask={[
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                ' ',
                't',
                'o',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
            ]}
            showMask
            keepCharPositions={true}
        />
    )
})

/**
 * @name PresetDateRange
 * @desc Renders a date ranger picker (as seen on dashboard) with To/From fields and calendar icon to open PresetDateRangePicker
 * @param getDateRange function
 * @param labelOverride string (defaults to "Range")
 * @param dateRange IDateRange (defaults to past 7 days if not provided)
 */
export const PresetDateRange = ({
    getDateRange,
    dateRange = defaultInputDateRanges,
    fieldKey,
    clearDates = false,
    className,
    allowFutureDateSelection,
    testId,
    clearDateRange = () => {},
    clearDateIcon = false,
}: {
    getDateRange: (date: any) => void
    dateRange?: IDateRange
    fieldKey?: string
    clearDates?: boolean
    className?: string
    allowFutureDateSelection: boolean
    testId?: string
    clearDateRange?: () => void
    clearDateIcon?: boolean
}): React.ReactElement => {
    const theme = useTheme()
    const [hover, setHover] = useState(false)
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))
    const [dateRanges, setDateRanges] = React.useState<IDateRange>(dateRange)
    const [openDateRange, setOpenDateRange] = useState(false)
    const [customDateRange, setCustomDateRange] = useState<IDateRange | null>(
        null
    )
    const anchorRef = React.useRef(null)
    const [valueInput, setValueInput] = useState(
        `${dateRange.from} to ${dateRange.to}`
    )
    const [error, setError] = useState(false)

    // Sets date range from PresetDateRangePicker into state for use when the apply button has been clicked in the preset date range menu
    const returnPresetDateRange = (range: IDateRange): void => {
        setError(false)
        setDateRanges({
            to: range.to,
            from: range.from,
        })
        getDateRange(`${range.from}|${range.to}`)
        setValueInput(`${range.from} to ${range.to}`)
    }

    // fades in hidden div that contains PresetDateRangePicker
    const toggleOpenDateRange = (): void => setOpenDateRange((prev) => !prev)

    const handleDateInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ): void => {
        setValueInput(event.target.value)

        // Checks for valid date and for correct format- "YYYY-MM-DD to YYYY-MM-DD"
        const isRegexFormat = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])\sto\s\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])/.test(
            event.target.value
        )
        if (isRegexFormat) {
            const dateRange = event.target.value.split(' to ')
            const updatedRanges = {
                to: dateRange[1],
                from: dateRange[0],
            }
            setError(false)
            setDateRanges(updatedRanges)
            getDateRange(`${updatedRanges.from}|${updatedRanges.to}`)
        } else {
            setError(true)
        }
    }

    useEffect(() => {
        if (dateRanges.to && dateRanges.from) {
            setCustomDateRange({
                from: dateRanges.from,
                to: dateRanges.to,
            })
        } else return
    }, [dateRanges])

    useEffect(() => {
        if (clearDates) {
            setDateRanges({ from: '', to: '' })
        }
    }, [clearDates])

    return (
        <ClickAwayListener onClickAway={() => setOpenDateRange(false)}>
            <div
                className={`emp-presetDateRange-container ${className}`}
                id={`${idDirectory.divContainer}-${testId}`}
            >
                <TextField
                    ref={anchorRef}
                    color="secondary"
                    variant="outlined"
                    value={valueInput}
                    id={`date_${fieldKey}`}
                    InputProps={{
                        inputComponent: TextMaskCustomFormat,
                        endAdornment: (
                            <>
                                <InputAdornment
                                    position="start"
                                    onClick={toggleOpenDateRange}
                                >
                                    <FontAwesomeIcon
                                        icon={faCalendar}
                                        className={`emp-presetDateRange-calendarIcon`}
                                        style={{
                                            color:
                                                openDateRange || hover
                                                    ? clientTheme.secondaryDark
                                                    : clientTheme.secondary,
                                        }}
                                        onMouseEnter={() => setHover(true)}
                                        onMouseLeave={() => setHover(false)}
                                    />
                                </InputAdornment>
                                {clearDateIcon && (
                                    <InputAdornment
                                        position="start"
                                        onClick={clearDateRange}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className={
                                                'emp-presetDateRange-clearIcon'
                                            }
                                        />
                                    </InputAdornment>
                                )}
                            </>
                        ),
                    }}
                    onChange={handleDateInputChange}
                    label={error ? 'YYYY-MM-DD to YYYY-MM-DD' : ''}
                    sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-input': {
                            padding: clientTheme.dateRangeContainer.padding,
                            lineHeight: 1.44,
                            height: 'auto',
                        },
                        '& .MuiInputBase-root': {
                            borderRadius:
                                clientTheme.dateRangeContainer.borderRadius,
                            padding: '0px',
                        },
                        '& .MuiInputAdornment-root': {
                            marginRight: '12px',
                            marginBottom: '2px',
                        },
                    }}
                />
                <Popper
                    open={openDateRange}
                    anchorEl={anchorRef.current}
                    className={'emp-presetDateRange-popper'}
                    placement={isLargeScreen ? 'bottom-end' : 'bottom'}
                    disablePortal={true}
                    modifiers={[
                        {
                            name: 'flip',
                            enabled: true,
                        },
                        {
                            name: 'preventOverflow',
                            enabled: true,
                        },
                    ]}
                >
                    <Paper className={`emp-presetDateRange-paper`}>
                        <PresetDateRangePicker
                            returnSelectedRanges={returnPresetDateRange}
                            activeCustomRange={customDateRange}
                            closeInParent={toggleOpenDateRange}
                            allowFutureDateSelection={allowFutureDateSelection}
                        />
                    </Paper>
                </Popper>
            </div>
        </ClickAwayListener>
    )
}

export default PresetDateRange

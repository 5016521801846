import React from 'react'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { useLocation } from 'react-router-dom'
import CB from 'lib'

interface CaseStatusPatchContexttProps {
    children: React.ReactNode
}

const CaseStatusPatchContext = ({
    children,
}: CaseStatusPatchContexttProps): React.ReactElement => {
    const location = useLocation()
    const {
        selectedPatchIDs,
        setSelectedPatchIDs,
        selectedPatchType,
        setSelectedPatchType,
        hasClearedCaseStatus,
        setHasClearedCaseStatus
    } = useAuthedUser()

    React.useEffect(() => {
        try {
            const { pathname, state } = location
            const hasState = state as any
            const caseId = hasState?.caseId ?? undefined
            const reviewType = hasState?.reviewType ?? ''

            if (
                (
                    pathname.startsWith('/case-qc-review/view-case/')
                    || pathname.startsWith('/review/view-case/')
                    || pathname.startsWith('/chargebacks')
                    || pathname.startsWith('/case-management')
                )
                && caseId
            ) {
                setHasClearedCaseStatus && setHasClearedCaseStatus(false)
                if (!selectedPatchIDs) {
                    setSelectedPatchType && setSelectedPatchType(reviewType)
                    return setSelectedPatchIDs && setSelectedPatchIDs(caseId)
                }
                if (selectedPatchIDs === caseId) return
            } else {
                if (selectedPatchIDs && !hasClearedCaseStatus) {
                    setHasClearedCaseStatus && setHasClearedCaseStatus(true)
                    const actionId = selectedPatchType === 'upload' ? 1 : 2
                    CB.cases.callCasePatch(selectedPatchIDs, actionId, selectedPatchType)
                        .finally(() => {
                            setSelectedPatchIDs && setSelectedPatchIDs('')
                            setSelectedPatchType && setSelectedPatchType('')
                        })
                }
            }
        } catch(e) {}
    }, [location, selectedPatchIDs, setSelectedPatchIDs, selectedPatchType, setSelectedPatchType, hasClearedCaseStatus, setHasClearedCaseStatus])

    return (
        <>
            {children}
        </>
    )
}

export default CaseStatusPatchContext

/**
 * @description Directory for ID Attributes on AccentArea.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    card: `accentArea-card-card`,
    divHeader: `accentArea-card-header`,
    headerHelper: `accentArea-header-helper`,
    divAction: `accentArea-header-action`,
    divChildren: `accentArea-div-children`,
}

export default idDirectory

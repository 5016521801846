import React from 'react'
import { ConfirmActionModal } from 'components'
import { ModalMode, ModalVariant } from 'hooks/cb-api'
import useRevertDnrModal from './useRevertDnrModal'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface DnrProps {
    open: boolean
    toggleOpen: (variant: ModalVariant, mode?: ModalMode) => void
    isMultiSelectMode: boolean
    selectedCaseIds: number[]
    rehydrateView: () => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    unselectAllRows?: () => void
}

const RevertDnrModal = ({
    toggleOpen,
    isMultiSelectMode,
    selectedCaseIds,
    rehydrateView,
    open,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    unselectAllRows = () => {},
}: DnrProps): React.ReactElement => {
    const { handleBulkRevertDnr, loading } = useRevertDnrModal()

    const handleRevertDnr = () => {
        if (isMultiSelectMode) {
            handleBulkRevertDnr(selectedCaseIds)
                .then(() => {
                    setAlertSnackbarMainProps({
                        message: 'Your action was a success!',
                        title: 'Success.',
                        intent: 'success',
                    })
                    unselectAllRows()
                })
                .catch(() => {
                    setAlertSnackbarMainProps({
                        message:
                            "There was an error when running the bulk 'Revert Do Not Represent' action.",
                        title: 'Error.',
                        intent: 'error',
                    })
                })
                .finally(() => {
                    toggleOpen('revertDnr')
                    setAlertSnackbarMainOpen(true)
                })
        }
    }

    return (
        <div className="emp-revertDnrModal-root">
            <ConfirmActionModal
                open={open}
                toggleOpen={() => toggleOpen('revertDnr')}
                onConfirm={handleRevertDnr}
                header={`Revert Do Not Represent`}
                message={'You are about to revert case(s)'}
                confirmButtonText={'Continue'}
                inactivateConfirmButton={loading}
                isLoadingConfirmButton={loading}
                testId={'revertDoNotRepresent'}
            />
        </div>
    )
}

export default RevertDnrModal

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { FormControl, TextField } from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { clientTheme } from 'theme-exports'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import { CloseAndInvalidate } from '../grids/AlertsGrid'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { StandardModal, SearchableSelect } from 'components'

type TMarkAlertInputs = {
    [key: string]: number | string
}

interface IMarkAlertProps {
    alertId: number | undefined
    open: boolean
    onClose: CloseAndInvalidate
    alertsOutcomesData: any
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

const MarkAlert: React.FC<IMarkAlertProps> = ({
    alertId,
    open,
    onClose,
    alertsOutcomesData,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}) => {
    const { user } = useAuthedUser()

    const [outcomesMap, setOutcomesMap] = useState([])
    const [selectedValues, setSelectedValues] = useState<{
        [key: string]: any
    }>({
        outcome: 'Please Select',
    })
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const markValidationSchema = Yup.object().shape({
        outcome_id: Yup.number()
            .min(1)
            .required('An outcome type must be selected.'),
        refund_id: Yup.string().when('outcome_id', {
            is: (outcome_id) => [1, 2, 3].includes(+outcome_id),
            then: Yup.string().required('This field is required'),
            otherwise: Yup.string(),
        }),
        order_id: Yup.string().when('outcome_id', {
            is: (outcome_id) => [1, 2, 3].includes(+outcome_id),
            then: Yup.string().required('This field is required'),
            otherwise: Yup.string(),
        }),
        customer_name: Yup.string().when('outcome_id', {
            is: (outcome_id) => [1, 2, 3].includes(+outcome_id),
            then: Yup.string().required('This field is required'),
            otherwise: Yup.string(),
        }),
        case_id: Yup.string().when('outcome_id', {
            is: (outcome_id) => +outcome_id === 6,
            then: Yup.string().required('This field is required'),
            otherwise: Yup.string(),
        }),
    })

    const {
        reset,
        register,
        watch,
        handleSubmit,
        setValue,
        formState: { isSubmitting, isValid, isDirty },
        // TODO: Known React-Hook-Form typescript issue - Should be resolved with RHF's v8. Once resolved, need to switch typescript's "any" to "TMarkAlertInputs".
    } = useForm<any>({
        defaultValues: {
            outcome_id: 0,
            // file: null
        },
        mode: 'onChange',
        resolver: yupResolver(markValidationSchema),
    })

    const onSubmit: SubmitHandler<TMarkAlertInputs> = (values) => {
        const valuesToSend = {
            alert_id: alertId,
            user_id: user?.id,
            outcome_id: values.outcome_id,
            ...([1, 2, 3].includes(+values.outcome_id) && {
                refund_id: values.refund_id,
                order_id: values.order_id,
                customer_name: values.customer_name,
            }),
            ...([6].includes(+values.outcome_id) && {
                case_id: values.case_id,
            }),
        }

        return axios
            .post(`/cm/svc/alerts/outcome`, valuesToSend)
            .then(() => {
                setAlertSnackbarMainProps({
                    title: 'Success',
                    message: `Alert record ${alertId} has been marked.`,
                    intent: 'success',
                })
                setAlertSnackbarMainOpen(true)
                onClose('mark', true)
                reset()
            })
            .catch(() => {
                setAlertSnackbarProps({
                    title: 'Error',
                    message: `There was a problem marked alert record ${alertId}.`,
                    intent: 'error',
                })
                setAlertSnackbarOpen(true)
            })
    }

    const handleSelectedValue = (fieldName: any, value: any) => {
        setValue(fieldName, value, { shouldValidate: true, shouldDirty: true })
    }

    const handleOnClose = () => {
        onClose('mark')
        reset()
        setSelectedValues({
            outcome: 'Please Select',
        })
    }

    useEffect(() => {
        if (open && alertsOutcomesData.data) {
            setOutcomesMap(alertsOutcomesData.data)
        }
    }, [alertsOutcomesData, open])

    return (
        <StandardModal
            openModal={open}
            onModalClose={handleOnClose}
            modalHeaderTitle={'Mark Outcome'}
            maxModalWidth={'sm'}
            testId={'preChargebacksMarkOutcome'}
            primaryBtnText={'Complete'}
            handlePrimaryActionBtn={handleSubmit(onSubmit)}
            inactivatePrimaryActionBtn={isSubmitting || !isDirty || !isValid}
            isLoadingPrimaryActionBtn={isSubmitting}
            handleSecondaryActionBtn={handleOnClose}
            // className={'emp-preChargebacksAG-markAlert-modalContainer'}
        >
            <div id={'preChargebacksAGAlertsMarkAlert'}>
                <div className={'emp-inputContainer'}>
                    <label>
                        Outcome:<span style={{ color: 'red' }}>*</span>
                    </label>
                    <div>
                        <FormControl variant="outlined" fullWidth>
                            <SearchableSelect
                                value={selectedValues.outcome}
                                onValueChange={async (e) => {
                                    setSelectedValues({
                                        ...selectedValues,
                                        outcome: e?.name,
                                    })
                                    await reset()
                                    handleSelectedValue('outcome_id', e?.id)
                                }}
                                options={outcomesMap ?? []}
                                accessor={'name'}
                                testId={'outcome'}
                                hideSearch
                                isPaginate={false}
                            />
                        </FormControl>
                    </div>
                </div>
                {[1, 2, 3].includes(+watch('outcome_id')) && (
                    <>
                        <div className={'emp-inputContainer'}>
                            <label>
                                Refund ID:
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <div>
                                <TextField
                                    {...register('refund_id')}
                                    name="refund_id"
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius:
                                                clientTheme.selectionBox
                                                    .borderRadius,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <div className={'emp-inputContainer'}>
                            <label>
                                Order ID:<span style={{ color: 'red' }}>*</span>
                            </label>
                            <div>
                                <TextField
                                    {...register('order_id')}
                                    name="order_id"
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius:
                                                clientTheme.selectionBox
                                                    .borderRadius,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <div className={'emp-inputContainer'}>
                            <label>
                                Customer Name:
                                <span style={{ color: 'red' }}>*</span>
                            </label>
                            <div>
                                <TextField
                                    {...register('customer_name')}
                                    name="customer_name"
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius:
                                                clientTheme.selectionBox
                                                    .borderRadius,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </>
                )}
                {+watch('outcome_id') === 6 && (
                    <div className={'emp-inputContainer'}>
                        <label>
                            Case ID:<span style={{ color: 'red' }}>*</span>
                        </label>
                        <div>
                            <TextField
                                {...register('case_id')}
                                name="case_id"
                                variant="outlined"
                                fullWidth
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius:
                                            clientTheme.selectionBox
                                                .borderRadius,
                                    },
                                }}
                            />
                        </div>
                    </div>
                )}
                {/* This is currently display: none and removed from validation, until document endpoint becomes available. */}
                {+watch('outcome_id') ? (
                    // <div className={classes.inputContainer} style={{display: 'none'}}>
                    //     <label>Upload:</label>
                    //     <div>
                    //         <label htmlFor="upload-file-input">
                    //             <Input
                    //                 id="upload-file-input"
                    //                 name="file"
                    //                 type="file"
                    //                 style={{display: 'none'}}
                    //                 onChange={(ev: any) => {
                    //                     formik.setFieldValue('file', ev.target.files[0])
                    //                 }}
                    //             />
                    //             <Button color="secondary" variant="contained" component="span">
                    //                 {formik.values.file ?
                    //                     <><Icon icon={faPlus} /> &nbsp; Change File</>
                    //                     :
                    //                     <><Icon icon={faPlus} /> &nbsp; Choose File</>
                    //                 }
                    //             </Button>
                    //             {formik.values.file &&
                    //                 <div className={classes.selectedFile}>
                    //                     {/* @ts-ignore */}
                    //                     Currently selected file: [{formik.values.file.name}]
                    //                 </div>
                    //             }
                    //         </label>
                    //     </div>
                    // </div>
                    <></>
                ) : null}
            </div>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </StandardModal>
    )
}

export default MarkAlert

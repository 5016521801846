/**
 * @description Directory for ID Attributes on Picker.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `picker-div-root`,
    inputLabel: `picker-input-label`,
    select: `picker-select-select`,
    option: `picker-option-options`,
}

export default idDirectory

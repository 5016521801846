import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { OutcomeCellValue } from 'hooks/cb-api/types'
import AlertsEditIcon from 'assets/icons/alerts-icon-edit.svg'
import AlertsViewIcon from 'assets/icons/alerts-icon-view.svg'
import AlertsCompleteIcon from 'assets/icons/alerts-icon-complete.svg'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'

interface ActionCellProps {
    value: OutcomeCellValue
    alertId: string
    toggleModal: any
    setCurrentAlertId: any
    completed: boolean
}

const ActionsCell = ({
    value,
    alertId,
    toggleModal,
    setCurrentAlertId,
    completed,
}: ActionCellProps) => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    return (
        <div id={'preChargebacksAGAlertsActionsCell'}>
            {completed ? (
                <div>
                    <span className={'emp-completedContainer'}>
                        Already Completed
                    </span>
                </div>
            ) : (
                <div className={'emp-iconsContainer'}>
                    <Tooltip
                        arrow
                        placement="top"
                        title={value.length ? 'Edit Outcome' : 'Mark Outcome'}
                    >
                        <div className={`${isReadOnly && 'emp-disabledIcon'}`}>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    toggleModal('mark')
                                    setCurrentAlertId(alertId)
                                }}
                                disabled={isReadOnly}
                            >
                                <img
                                    className={`emp-actionIcon`}
                                    src={AlertsEditIcon}
                                    alt={
                                        value.length
                                            ? 'Edit Outcome'
                                            : 'Mark Outcome'
                                    }
                                />
                            </IconButton>
                        </div>
                    </Tooltip>
                    {value.length && (
                        <Tooltip
                            arrow
                            placement="top"
                            title="Confirm Completion"
                        >
                            <div
                                className={`${
                                    isReadOnly && 'emp-disabledIcon'
                                }`}
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        toggleModal('complete')
                                        setCurrentAlertId(alertId)
                                    }}
                                    disabled={isReadOnly}
                                >
                                    <img
                                        className={`emp-actionIcon`}
                                        src={AlertsCompleteIcon}
                                        alt="Edit"
                                    />
                                </IconButton>
                            </div>
                        </Tooltip>
                    )}
                    <Tooltip arrow placement="top" title="View Alert History">
                        <div>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    toggleModal('history')
                                    setCurrentAlertId(alertId)
                                }}
                            >
                                <img
                                    className={`emp-actionIcon`}
                                    src={AlertsViewIcon}
                                    alt="Edit"
                                />
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
            )}
        </div>
    )
}

export default ActionsCell

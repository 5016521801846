import React from 'react'
import Footer from './Footer'
import { Link } from '@mui/material'
import idDirectory from './idAttributes'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'

interface IStandardFooterProps {
    className?: string
}

/**
 * Use only to display copyright information and a way to get version info
 */
const StandardFooter = ({ className }: IStandardFooterProps) => {
    const {
        enabled: mainFooterEnabled,
        privacy_policy_link,
        footerTitle,
        positionRight,
    } = useFeatureToggle('MAIN_FOOTER')

    return (
        <>
            {mainFooterEnabled && (
                <Footer
                    id={idDirectory.footer}
                    className={'emp-standardFooter-root'}
                    style={{ textAlign: positionRight && 'right' }}
                >
                    {privacy_policy_link ? (
                        <Link
                            id={idDirectory.link}
                            href={privacy_policy_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={'emp-standardFooter-link'}
                        >
                            {footerTitle}
                        </Link>
                    ) : (
                        footerTitle
                    )}
                </Footer>
            )}
        </>
    )
}

export default StandardFooter

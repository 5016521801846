interface caseBuilderGroupMapType {
    [key: number]: string;
}

export const caseBuilderGroupMap: caseBuilderGroupMapType = {
    1: 'faAddressCard',
    2: 'faFileInvoice',
    3: 'faTruck',
    // 4: '',
    5: 'faCreditCard',
    6: 'faBoxOpen',
    7: 'faBuilding',
    8: 'faBriefcase',
    9: 'faTicket',
    10: 'faLaptop',
    // 11: '',
    // 12: '',
    13: 'faPlane',
    14: 'faList',
}

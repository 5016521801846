import React, { useState } from 'react'
import { RowModel } from '@material-ui/data-grid'
import {
    CreditCardProcessorType,
    DataGridActions,
    DataGridSlim,
    Searchbar,
    VerdictType,
} from 'components'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import { useColumnDefs } from 'hooks/useColumnDefs/useColumnDefs'
import { useCases } from '../../Chargebacks/useCases'
import { AssignCell, FlagCell } from '../../CaseManagement/grids/Cells'
import { ReviewCell } from './Cells'
import { useSWRContext } from 'context/SWRContext'
import { CaseData } from 'hooks/cb-api'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { amountValueFormatter } from '../../Chargebacks/grids/ChargebacksGrid'

interface QCReviewGridProps {
    data: RowModel[]
    testId: string
    params?: any
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export const QCReviewGrid: React.FC<QCReviewGridProps> = ({
    data,
    testId,
    params,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}) => {
    const { user } = useAuthedUser()
    const { swrActions } = useSWRContext()
    const [
        qcReviewErrorAlertProps,
        setQCReviewErrorAlertProps,
    ] = useState<alertSnackbarContentProps>({})
    const [qcReviewError, setQCReviewError] = useState<boolean>(false)

    const closeQCReviewError = () => {
        setQCReviewError(false)
    }

    const {
        isLoading,
        setPage,
        setRowsPerPage,
        total,
        page,
        setGlobalSearch,
        rowsPerPage,
        setSortBy,
        sortInfo,
        invalidate: rehydrateView,
    } = swrActions

    const {
        colDefs: columns,
        hiddenColumns,
        setHiddenColumns,
        //@ts-ignore
    } = useColumnDefs<CaseData>('qcReview', user?.role?.alias, {
        setSortBy,
        sortInfo,
        cells: {
            flag: (cell: any) => (
                <FlagCell
                    row={cell.row.original}
                    rowIndex={cell.row.id}
                    testId={testId}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                />
            ),
            cc_type: (cell: any) => (
                <CreditCardProcessorType processor={cell.value} size="large" />
            ),
            verdict: (cell: any) => (
                <VerdictType verdict={cell.value} size="small" />
            ),
            assign: (cell: any) => (
                <AssignCell
                    row={cell.row.original}
                    rowIndex={cell.row.id}
                    testId={testId}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                />
            ),
            actions: (cell: any) => (
                <ReviewCell
                    caseId={cell.row.original.id}
                    setAlertProps={setQCReviewErrorAlertProps}
                    setAlertOpen={setQCReviewError}
                    rowIndex={cell.row.id}
                />
            ),
            dispute_amt: (cell: any) => (
                <span>
                    {amountValueFormatter(cell.row.original.dispute_amt)}
                </span>
            ),
            trans_amt: (cell: any) => (
                <span>{amountValueFormatter(cell.row.original.trans_amt)}</span>
            ),
        },
    })

    const headers = columns
        .map((i) => {
            if (i.Header === 'Flag') {
                return {
                    accessor:
                        typeof i.accessor === 'function'
                            ? i.id ?? ''
                            : i.accessor ?? '',
                    label: 'Flag Comment',
                }
            }
            return typeof i.accessor === 'function'
                ? {
                      accessor: i.id ?? '',
                      label:
                          typeof i.Header === 'string'
                              ? i.Header
                              : i.Header?.props?.value ?? undefined,
                  }
                : {
                      accessor: i.accessor ?? '',
                      label:
                          typeof i.Header === 'string'
                              ? i.Header
                              : i.Header?.props?.value ?? undefined,
                  }
        })
        .filter((i) => !hiddenColumns.includes(i.accessor))

    const {
        download,
        isLoadingDownloadExportAll,
        isLoadingDownloadCsv,
        isEmailExport,
        closeEmailExport,
        downloadXlsx,
        isLoadingDownloadXlsx,
        isLoadingDownloadXlsxAll,
        downloadError,
        downloadErrorString,
        closeDownloadError,
    } = useCases('QC-Review', headers)

    const columnsToSend = columns
        .slice()
        .filter((col: any) => col.Header !== 'Document')
        .filter((col: any) => col.Header !== 'Assigned')

    return (
        <div id={'qcReviewGrid'}>
            <div className={`${'emp-searchbarContainer'} ${'emp-searchbar'}`}>
                <Searchbar
                    className={'emp-cssOutlinedInput'}
                    debounceDelay={200}
                    returnValue={setGlobalSearch}
                    testId={testId}
                />
                <DataGridActions
                    printId="cb-cases-dg"
                    tableData={data}
                    dataForExportAll={data}
                    columns={columns}
                    hiddenColumns={hiddenColumns}
                    setHiddenColumns={setHiddenColumns}
                    onExportAll={() => {
                        // exports total entries
                        download(
                            {
                                export_total: total,
                                ...params,
                            },
                            true
                        )
                    }}
                    onExportAllLoading={isLoadingDownloadExportAll}
                    onExportCsv={() => {
                        // exports total entries
                        download({
                            export_type: 'page',
                            export_total: total,
                            ...params,
                        })
                    }}
                    onExportCsvLoading={isLoadingDownloadCsv}
                    onExportXlsx={() =>
                        downloadXlsx(
                            {
                                export_type: 'page',
                                export_total: total,
                                ...params,
                            },
                            true
                        )
                    }
                    onExportXlsxLoading={isLoadingDownloadXlsx}
                    onExportAllXlsx={() =>
                        downloadXlsx({
                            export_total: total,
                            ...params,
                        })
                    }
                    onExportAllXlsxLoading={isLoadingDownloadXlsxAll}
                    dataActionExportAll={false}
                    dataActionCSV={false}
                    // dataActionSwitchGrids={true}
                    fileName={'QC-Review'}
                    testId={testId}
                />
            </div>

            <DataGridSlim
                page={page}
                columns={columnsToSend}
                data={data}
                rowsPerPageOptions={[10, 25, 50, 100]}
                pageSize={rowsPerPage}
                expandable={true}
                slim={false}
                loading={isLoading}
                onPageChange={setPage}
                onPageLimitChange={setRowsPerPage}
                totalEntries={total}
                hiddenColumns={hiddenColumns}
                hiddenColumnsEnabled
                fixedLoadingIndicator
                disableSelectAllCheckbox
                hideRowCheckboxes
                stickyHeaders={true}
                testId={testId}
                refreshDataGrid={() => rehydrateView()}
            />
            <AlertSnackbar
                content={{
                    message:
                        'This may take up to 15 minutes. We will send you an email with a link to download your export when it’s ready. Thank you!',
                    title: 'Export started.',
                    intent: 'success',
                }}
                onClose={closeEmailExport}
                autoHideDuration={30_000}
                open={isEmailExport}
                showCloseIcon
            />
            <AlertSnackbar
                content={{
                    message: downloadErrorString,
                    title: 'Error.',
                    intent: 'error',
                }}
                onClose={closeDownloadError}
                open={downloadError}
                showCloseIcon
            />
            <AlertSnackbar
                content={qcReviewErrorAlertProps}
                onClose={closeQCReviewError}
                open={qcReviewError}
                showCloseIcon
            />
        </div>
    )
}

import { useEffect, useState } from 'react'
import { Hub } from 'aws-amplify'
import axios from 'axios'
// import useSWR from 'swr'
// import { fetcher, fetcher_post} from './fetcher'
// import useURL from './utils/useURL'
import { UiStateStore, WhoAmI } from './types'
import WhoAmIModel from 'models/WhoAmI'

export const useUiState = (): UiStateStore => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<any>('')
  const [whoami, setWhoami] = useState<WhoAmI | null>(null)
  const [activeMerchant, setActiveMerchant] = useState<any>()
  useEffect(() => {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn') {
        invalidate('whoami')
      }
    })
    invalidate('whoami')
  },[])

  const invalidate = async (type: 'whoami') => {
    if (type === 'whoami') {
      try {
          setLoading(true)
          await axios.post('users/whoami').then(({data}:any ) => {
            const whoami = new WhoAmIModel().fromData(data)
            setWhoami(whoami)
          })
      } catch (err) {
          setError('Unauthenticated')
      } finally {
        setLoading(false)
      }
    }
  }

  const setActiveMerchant_ = async (merchantId: number | null) => {
    try {
      setLoading(true)
      await axios.get(
        `cm/merchants/${merchantId ?? whoami?.merchant?.id}`
      ).then(({ data }: any) => {
        setActiveMerchant(data)
      })
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }
  
  return {
    whoami,
    activeMerchant,
    setActiveMerchant: setActiveMerchant_,
    loading,
    error,
    invalidate
  }
}


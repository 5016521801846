import { useState, useEffect, useRef, useMemo } from 'react'
import axios from 'axios'
import { AgGridReact } from 'ag-grid-react'
import { format } from 'date-fns'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { dateUtilities } from 'utils/dateUtilities'
import { DownloadParams } from 'lib/Cases'
import { useActiveMerchant } from 'components'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import { IDateRange } from 'components/PresetDateRange'
import { useAGDataGridActions } from 'hooks/useAGDataGridActions/useAGDataGridActions'
import { Alert, useSwrData, AlertsServiceLevel } from 'hooks/cb-api'

export type Download = (params: DownloadParams) => Promise<unknown>

export type ModalVariant = 'complete' | 'mark' | 'history' | 'selectMid'

export interface MidList {
    name: string
    value: string
    id: string
    descriptor: string
}

export interface FilterValues {
    descriptor: string | undefined
    date_range: string | undefined
}

interface AlertsVM {
    alertsGridRef: any
    gridData: { [key: string]: any }[]
    dataSource: any
    isLoading: boolean
    pageSize: number
    page: number
    paginationTotal: number
    setLocalPage: (page: number) => void
    handlePageLimitChange: (pageSize: number) => void
    handleSearchField: (value: string) => void
    currentPayload: { [key: string]: any } | undefined
    refreshAlertsGrid: () => void
    filterValues: FilterValues
    setFilterValues: (filter: FilterValues) => void
    defaultDateRange: IDateRange
    serviceLevelData: AlertsServiceLevel
    isSelfService: boolean
    downloadXlsx: Download
    isLoadingDownloadXlsxAll: boolean
    downloadCSV: Download
    alertSnackbarMainOpen: boolean
    setAlertSnackbarMainOpen: (status: boolean) => void
    alertSnackbarMainProps: alertSnackbarContentProps
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    closeAlertSnackbar: () => void
    setCurrentAlertId: (id: number | undefined) => void
    currentAlertId: number | undefined
    toggleModal: (variant: ModalVariant) => void
    alertHistoryOpen: boolean
    markOutcomeOpen: boolean
    completeOpen: boolean
    selectMIDOpen: boolean
    alertsOutcomesData: any
    midList: MidList[]
    setSelectedMid: (mid: MidList) => void
    selectedAlertInfo: Alert
}

const defaultPostDateeRangeOnLoad: {
    to: Date
    from: Date
} = dateUtilities.getPriorDays(7)
const { to, from } = defaultPostDateeRangeOnLoad
export let defaultDateRange: IDateRange = {
    to: format(to, 'yyyy-MM-dd'),
    from: format(from, 'yyyy-MM-dd'),
}
export const useAlerts = (): AlertsVM => {
    const alertsGridRef = useRef<AgGridReact>(null)
    const { id: merchantId } = useActiveMerchant()
    const { user } = useAuthedUser()

    const [currentAlertId, setCurrentAlertId] = useState<number | undefined>(
        undefined
    )
    const [alertHistoryOpen, setAlertHistoryOpen] = useState<boolean>(false)
    const [markOutcomeOpen, setMarkOutcomeOpen] = useState<boolean>(false)
    const [completeOpen, setCompleteOpen] = useState<boolean>(false)
    const [selectMIDOpen, setSelectMIDOpen] = useState<boolean>(false)
    const [alertSnackbarMainOpen, setAlertSnackbarMainOpen] = useState<boolean>(
        false
    )
    const [
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
    ] = useState<alertSnackbarContentProps>({})
    const [midList, setMidList] = useState<MidList[] | []>([])
    const [selectedAlertInfo, setSelectedAlertInfo] = useState<any>({})
    const [
        isLoadingDownloadXlsxAll,
        setIsLoadingDownloadXlsxAll,
    ] = useState<boolean>(false)
    const [filterValues, setFilterValues] = useState<FilterValues>({
        descriptor: undefined,
        date_range: `${defaultDateRange.from}|${defaultDateRange.to}`,
    })

    const { descriptor, date_range } = filterValues

    const filterValuesMemo = useMemo(() => {
        return { merchant_id: merchantId, ...filterValues }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantId, descriptor, date_range])

    const {
        data: gridData,
        dataSource,
        isLoading,
        pageSize,
        page,
        paginationTotal,
        setLocalPage,
        handlePageLimitChange,
        handleSearchField,
        currentPayload,
        refreshAGGrid: refreshAlertsGrid,
    } = useAGDataGridActions(
        'cm/svc/alerts',
        alertsGridRef,
        +merchantId,
        filterValuesMemo,
        {}
    )

    const endpointConfigServiceLevel = {
        paramInUrl: false,
        url: '/cm/svc/alerts/service-level',
        id: {
            key: 'merchant_id',
            value: merchantId,
        },
    }

    const alertsServiceLevelData = useSwrData(endpointConfigServiceLevel)

    const endpointAlertsOutcomesData = {
        paramInUrl: false,
        url: '/cm/svc/alerts/outcome/dropdown',
        id: {
            key: 'merchant_id',
            value: merchantId,
        },
    }

    const alertsOutcomesData = useSwrData(endpointAlertsOutcomesData)

    const toggleModal = (variant: ModalVariant) => {
        switch (variant) {
            case 'complete':
                setCompleteOpen((prev) => !prev)
                break
            case 'mark':
                setMarkOutcomeOpen((prev) => !prev)
                break
            case 'history':
                setAlertHistoryOpen((prev) => !prev)
                break
            case 'selectMid':
                setSelectMIDOpen((prev) => !prev)
                break
            default:
                console.log('Unknown modal type')
                break
        }
    }

    useEffect(() => {
        if (!currentAlertId || !gridData.data.length) return
        const currentAlertData = gridData.data.find((data: any) => {
            return data.alert_id === currentAlertId
        })
        setSelectedAlertInfo(currentAlertData || {})
    }, [currentAlertId, gridData])

    // Get MID list for select MIDs
    useEffect(() => {
        if (!merchantId) return
        axios
            .get(`/cm/svc/alerts/descriptors/mapping`, {
                params: {
                    merchant_id: merchantId,
                },
            })
            .then((data) => {
                const midOptions = data.data.data.map((item: any) => {
                    return {
                        name: item.mid,
                        value: item.mid,
                        id: item.mid_id,
                        descriptor: item.descriptor,
                    }
                })
                setMidList(midOptions)
            })
            .catch((e) =>
                setMidList([{ name: '', value: '', id: '', descriptor: '' }])
            )
    }, [merchantId])

    const setSelectedMid = (item: MidList) => {
        // Obtaining descriptor data from the currently selected alert row for now.
        const selectedAlertData = gridData.data.find(
            (alert: any) => alert.alert_id === currentAlertId
        )
        if (!selectedAlertData) return Promise.reject()

        return axios.post(
            `/cm/svc/alerts/descriptors/mapping?merchant_id=${merchantId}`,
            {
                client_id: +merchantId,
                mid_id: +item.id,
                descriptor: selectedAlertData.descriptor,
                user_id: Number(user?.id),
            }
        )
    }

    //todo: update for Export All (csv)
    const downloadCSV: Download = async (P: DownloadParams) => {
        try {
            const response = await axios.get(`/cm/svc/alerts.csv`, {
                responseType: 'blob',
                params: {
                    ...P,
                    merchant_id: merchantId,
                    sort_order: 'asc',
                },
                headers: {
                    'Content-Type': 'text/csv',
                },
            })
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'alerts.csv')
            document.body.appendChild(link)
            link.click()
            link.remove()
            return
        } catch (error) {
            setAlertSnackbarMainProps({
                title: 'Error',
                message: 'An unknown error has occurred.',
                intent: 'error',
            })
            setAlertSnackbarMainOpen(true)
        }
    }

    const downloadXlsx: Download = async (
        P: DownloadParams,
        isSinglePage?: boolean
    ) => {
        !isSinglePage && setIsLoadingDownloadXlsxAll(true)

        try {
            const response = await axios.get(`/cm/svc/alerts.xlsx`, {
                responseType: 'blob',
                params: {
                    ...P,
                    sort_order: 'asc',
                    merchant_id: merchantId,
                },
                headers: {
                    'Content-Type':
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
            })
            if (
                [
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'text/csv',
                ].includes(response.data.type) ||
                isSinglePage
            ) {
                setAlertSnackbarMainOpen(false)
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'alerts.xlsx')
                document.body.appendChild(link)
                link.click()
                link.remove()
            } else {
                setAlertSnackbarMainProps({
                    message:
                        'This may take up to 15 minutes. We will send you an email with a link to download your export when it’s ready. Thank you!',
                    title: 'Export started.',
                    intent: 'success',
                })
                setAlertSnackbarMainOpen(true)
            }
            return
        } catch (error) {
            setAlertSnackbarMainProps({
                title: 'Error',
                message: 'An unknown error has occurred.',
                intent: 'error',
            })
            setAlertSnackbarMainOpen(true)
        } finally {
            !isSinglePage && setIsLoadingDownloadXlsxAll(false)
        }
    }

    const closeAlertSnackbar = () => setAlertSnackbarMainOpen(false)

    return {
        alertsGridRef,
        gridData: gridData.data,
        dataSource,
        isLoading,
        pageSize,
        page,
        paginationTotal,
        setLocalPage,
        handlePageLimitChange,
        handleSearchField,
        currentPayload,
        refreshAlertsGrid,
        filterValues,
        setFilterValues,
        defaultDateRange,
        serviceLevelData: alertsServiceLevelData.data || {},
        isSelfService:
            Number(alertsServiceLevelData.data?.service_level_id) === 2 ??
            false,
        downloadXlsx,
        isLoadingDownloadXlsxAll,
        downloadCSV,
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
        closeAlertSnackbar,
        setCurrentAlertId,
        currentAlertId,
        toggleModal,
        alertHistoryOpen,
        markOutcomeOpen,
        completeOpen,
        selectMIDOpen,
        alertsOutcomesData,
        midList: midList ?? [],
        setSelectedMid,
        selectedAlertInfo,
    }
}

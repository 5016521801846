import React from 'react'
import { Grid, Link } from '@mui/material'
import View from 'views/View'
import { TopicArea } from './Topic'
import { useHelp } from './Help.vm'
import idDirectory from './idAttributes'
import { withRequiredRole } from 'components/useRequireRole'
import EntFeature from 'models/EntFeature'
import { AccentArea } from 'components'
import { iconsTheme } from 'theme-exports'
import Icon from 'components/Icon'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'

/**
 * The Help view with it's videos
 */

export const Help = () => {
    const help = useHelp()
    const { USER_DOC, ADD_ONS } = useFeatureToggle('HELP')
    const { title_icon: titleIconEnabled } = useFeatureToggle('ACCENT_AREA')

    return (
        <View title="Help" breadcrumb="Help" testId="help">
            {USER_DOC.enabled && (
                <Grid
                    container
                    className={'emp-documents'}
                    id={`${idDirectory.gridContainer}-documents`}
                >
                    <Grid item xs={12} id={`${idDirectory.gridItem}-documents`}>
                        <AccentArea
                            testId="help"
                            title={
                                <div className={'emp-icon'}>
                                    {titleIconEnabled && (
                                        <Icon
                                            className={`${iconsTheme.question} emp-marginRight`}
                                        />
                                    )}

                                    <span>Documents</span>
                                </div>
                            }
                        >
                            <Link
                                href={
                                    USER_DOC.user_guide_link_is_local_file
                                        ? `${process.env.PUBLIC_URL}/help/${USER_DOC.user_guide_link}`
                                        : USER_DOC.user_guide_link
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className={'emp-link'}
                            >
                                Dispute Portal User Guide
                            </Link>
                        </AccentArea>
                    </Grid>
                </Grid>
            )}
            <Grid
                container
                spacing={4}
                id={`${idDirectory.gridContainer}-topics`}
            >
                {help.topics.map((topic) =>
                    ADD_ONS.topicTitles.includes(topic.title) ? (
                        <Grid
                            item
                            key={topic.id}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={12}
                            xs={12}
                            id={`${idDirectory.gridItem}-${topic.testId}`}
                        >
                            <TopicArea topic={topic} loading={false} />
                        </Grid>
                    ) : null
                )}
            </Grid>
        </View>
    )
}

export default withRequiredRole(EntFeature.HELP, Help)

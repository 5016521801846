import React from 'react'
import { Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

const FilterAlert = () => {
    return (
        <div className={'emp-filterAlert-root'}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <Typography className={'emp-filterAlert-text'}>
                Filter Currently Applied in Advanced Search
            </Typography>
        </div>
    )
}

export default FilterAlert

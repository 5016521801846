import React, {
    ReactElement,
    useRef,
    useMemo,
    useState,
    useCallback,
} from 'react'
import { AgGridReact } from 'ag-grid-react'
import { CellDoubleClickedEvent } from 'ag-grid-community'
import { AGDataGrid, StandardPopover } from 'components'
import { useAGDataGridActions } from 'hooks/useAGDataGridActions/useAGDataGridActions'
import { ExtendedColumn } from 'components/DataGrid/ExtendedColumn'

interface CaseHistoryPopoverProps {
    anchorEl: null | HTMLElement
    onClose: () => void
    caseInfo: any
    testId?: string
}

const CaseHistoryPopover = ({
    anchorEl,
    onClose,
    caseInfo,
    testId = 'caseHistoryPopover',
}: CaseHistoryPopoverProps): ReactElement => {
    const { id } = caseInfo
    const gridRef = useRef<AgGridReact>(null)
    const [shouldTruncateUser, setShouldTruncateUser] = useState<boolean>(true)
    const [shouldTruncateAction, setShouldTruncateAction] = useState<boolean>(
        true
    )
    const [
        refreshDataGridColumnsToggle,
        setRefreshDataGridColumnsToggle,
    ] = useState<boolean>(false)

    const filterValuesMemo = useMemo(() => {
        return { limit: undefined }
    }, [])

    const {
        dataSource,
        isLoading,
        pageSize = 5,
        page,
        paginationTotal,
        setLocalPage,
        handlePageLimitChange,
    } = useAGDataGridActions(
        `/cm/cases/${id}/history`,
        gridRef,
        +id,
        filterValuesMemo,
        {}
    )

    const columns: ExtendedColumn[] = [
        {
            Header: 'Timestamp',
            accessor: 'timestamp',
            type: 'string',
            minWidth: 145,
            tooltipField: 'timestamp',
        },
        {
            Header: 'Action',
            accessor: 'action',
            type: 'string',
            autoHeight: true,
            minWidth: 150,
            ...(!shouldTruncateAction && {
                wrapText: true,
                minWidth: 250,
            }),
            tooltipField: 'action',
        },
        {
            Header: 'Username',
            accessor: 'user',
            type: 'string',
            autoHeight: true,
            minWidth: 150,
            ...(!shouldTruncateUser && {
                wrapText: true,
                minWidth: 250,
            }),
            tooltipField: 'user',
        },
    ]

    const defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
        menuTabs: [],
        sortable: false,
    }

    const handleDoubleClickedCell = useCallback((e: CellDoubleClickedEvent) => {
        if (e.colDef.field === 'user') {
            setShouldTruncateUser((prev) => !prev)
            setRefreshDataGridColumnsToggle((prev) => !prev)
        }
        if (e.colDef.field === 'action') {
            setShouldTruncateAction((prev) => !prev)
            setRefreshDataGridColumnsToggle((prev) => !prev)
        }
    }, [])

    const handleOnClose = () => {
        onClose()
        setShouldTruncateUser(true)
        setShouldTruncateAction(true)
    }

    return (
        <StandardPopover
            anchorEl={anchorEl}
            onPopoverClose={handleOnClose}
            popoverHeaderTitle={`Case History`}
            testId={testId}
            popoverWidth={500}
            disableScrollLock={true}
            secondaryBtnText={'Close History'}
            handleSecondaryActionBtn={handleOnClose}
            showPrimaryActionBtn={false}
        >
            <div className={'emp-caseHistoryPopover-gridContainer'}>
                <AGDataGrid
                    gridRef={gridRef}
                    gridName={'caseHistoryPopover'}
                    fileName={'case-history'}
                    columns={columns}
                    defaultColDef={defaultColDef}
                    dataSource={dataSource}
                    loading={isLoading}
                    testId={'caseHistoryPopover'}
                    rowSelectionType={'single'}
                    disableRowClickSelection
                    enableSideBarPanel={false}
                    enableDataGridTopBorder={false}
                    alwaysShowHorizontalScroll={false}
                    enableAutoVerticalScroll={true}
                    rowsPerPageOptions={[5, 10]}
                    totalEntries={paginationTotal}
                    onPageLimitChange={handlePageLimitChange}
                    pageSize={pageSize}
                    page={page}
                    onPageChange={setLocalPage}
                    paginate={false}
                    handleDoubleClickedCell={handleDoubleClickedCell}
                    className={'emp-caseHistoryPopover-dataGrid'}
                    refreshDataGridColumnsToggle={refreshDataGridColumnsToggle}
                    gridThemeType={'materialSlim'}
                />
            </div>
        </StandardPopover>
    )
}

export default CaseHistoryPopover

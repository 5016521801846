import { LoadingIndicator } from 'components'
import React from 'react'
import {
    Area,
    AreaChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'
import { clientTheme } from 'theme-exports'
import { format, parseISO, differenceInDays } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

function renderText(
    child: any,
    x: number,
    y: number,
    rotate: string | 0,
    stroke: string,
    key?: number
) {
    if (child && child.content) {
        return (
            <text
                key={key}
                x={x}
                y={y}
                transform={`rotate(${rotate})`}
                textAnchor="middle"
                stroke={stroke}
                {...child.props}
            >
                {child.content}
            </text>
        )
    }

    return (
        <text
            key={key}
            x={x}
            y={y}
            transform={`rotate(${rotate})`}
            textAnchor="middle"
            stroke={stroke}
        >
            {child}
        </text>
    )
}

interface AxisLabelProps {
    axisType: 'yAxis' | 'xAxis'
    x: number
    y: number
    width: number
    height: number
    stroke: string
    children: any
}

export default function AxisLabel({
    axisType,
    x,
    y,
    width,
    height,
    stroke,
    children,
}: AxisLabelProps) {
    const isVert = axisType === 'yAxis'
    const cx = isVert ? x : x + width / 2
    const cy = isVert ? height / 2 + y : y + height + 20
    const rot = isVert ? `270 ${cx} ${cy}` : 0
    const lineHeight = 20

    if (children.length > 1 && children.map) {
        return (
            <g>
                {children.map((child: any, index: number) =>
                    renderText(
                        child,
                        cx,
                        cy + index * lineHeight,
                        rot,
                        stroke,
                        index
                    )
                )}
            </g>
        )
    }

    return renderText(children, cx, cy, rot, stroke)
}

interface ChargebacksByPostDateProps {
    data: Record<string, any>[]
    loading?: boolean
    error?: Error
    height?: number
    dateRange?: string
}

export const ChargebacksByPostDate: React.FC<ChargebacksByPostDateProps> = ({
    data,
    loading = false,
    height = 300,
    dateRange,
}) => {

    let dateFormat = 'yy-MM-dd'
    if (dateRange?.length) {
        try {
            const splitDateRange = dateRange.split('|')
            const from = new Date(splitDateRange[0])
            const to = new Date(splitDateRange[1])
            const daysDifference = differenceInDays(to, from)

            if (daysDifference > 90) dateFormat = 'MMM. Y'
        } catch(e) {}
    }

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className={'emp-tooltipContainer'}>
                    <table style={{ borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr style={{ backgroundColor: '#b3b3b3' }}>
                                <td
                                    colSpan={2}
                                    className={'emp-tooltipTableHeader'}
                                >
                                    {format(parseISO(label), dateFormat)}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    className={'emp-tooltipTableRow'}
                                    style={{
                                        borderRight: '1px dotted #b3b3b3',
                                        textAlign: 'center',
                                    }}
                                >
                                    <FontAwesomeIcon
                                        size="xs"
                                        icon={faSquare}
                                        style={{
                                            marginRight: '5px',
                                            color: payload[0].stroke,
                                        }}
                                    />
                                    Chargebacks
                                </td>
                                <td
                                    className={'emp-tooltipTableRow'}
                                    style={{
                                        textAlign: 'right',
                                    }}
                                >
                                    {payload[0].value}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }

        return null
    }

    return (
        <div id={'dashboardChargebacksByPostDate'}>
            {loading ? (
                <div
                    style={{
                        display: 'grid',
                        placeItems: 'center',
                        height: height,
                    }}
                >
                    <LoadingIndicator />
                </div>
            ) : data.length === 0 ? (
                <div
                    style={{
                        display: 'grid',
                        placeItems: 'center',
                        height: height,
                    }}
                >
                    <p style={{ fontWeight: 500, fontSize: 16 }}>
                        No Records Found!
                    </p>
                </div>
            ) : (
                <div className={'emp-root'}>
                    <ResponsiveContainer width="100%" height={height}>
                        <AreaChart
                            data={data}
                            margin={{ top: 10, right: 30, left: 10, bottom: 0 }}
                        >
                            <XAxis
                                dataKey="date"
                                tickFormatter={(date: string): string =>
                                    format(parseISO(date), dateFormat)
                                }
                                tick={{
                                    fontSize:
                                        clientTheme.typography.subtitle2
                                            .fontSize,
                                }}
                            />
                            <YAxis
                                interval={0}
                                label={{
                                    value: 'Chargebacks',
                                    angle: -90,
                                    position: 'insideLeft',
                                    dy: 10,
                                    dx: -10,
                                    fontSize: '10px',
                                }}
                                tick={{
                                    fontSize:
                                        clientTheme.typography.subtitle2
                                            .fontSize,
                                }}
                            />
                            <Tooltip content={<CustomTooltip />} />
                            <Area
                                type="monotone"
                                dataKey="count"
                                stroke={
                                    clientTheme.charts.areaChart
                                        .chargebacksByPostDate.stroke ??
                                    '#b71c1c'
                                }
                                fill={
                                    clientTheme.charts.areaChart
                                        .chargebacksByPostDate.fill ?? '#ef9a9a'
                                }
                                dot={{
                                    stroke:
                                        clientTheme.charts.areaChart
                                            .chargebacksByPostDate.stroke ??
                                        '#b71c1c',
                                    fill:
                                        clientTheme.charts.areaChart
                                            .chargebacksByPostDate.stroke ??
                                        '#b71c1c',
                                }}
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            )}
        </div>
    )
}

import React from 'react'
import { AgGridReact } from 'ag-grid-react'
// import { ValueFormatterParams } from 'ag-grid-community'
// import { AGDataGrid } from 'components'
// import { ExtendedColumn } from 'components/DataGrid/ExtendedColumn'
// import { amountValueFormatter } from '../../../views/ChargebacksAGGrid/grids/ChargebacksGrid'
// import { useAGDataGridActions } from 'hooks/useAGDataGridActions/useAGDataGridActions'

interface ConfirmCaseBuilderSummaryAGGridProps {
    testId: string
    gridRef: any
}

export const ConfirmCaseBuilderSummaryAGGrid: React.FC<ConfirmCaseBuilderSummaryAGGridProps> = ({
    testId,
    gridRef,
}) => {
    //TODO: uncomment once endpoint for datagrid is available and enable test on ConfirmCaseBuilderSummaryStep4.test.tsx line 45.
    // const columnDefinitions: ExtendedColumn[] = [
    //         {
    //             Header: 'Customer Name',
    //             accessor: 'customer_name',
    //             type: 'string',
    //             minWidth: 150,
    //         },
    //         {
    //             Header: 'Case Number',
    //             accessor: 'case_number',
    //             type: 'string',
    //             minWidth: 110,
    //         },
    //         {
    //             Header: 'Card Number',
    //             accessor: 'card_number',
    //             type: 'string',
    //             minWidth: 110,
    //         },
    //         {
    //             Header: 'Amount',
    //             accessor: 'amount',
    //             type: 'number',
    //             minWidth: 110,
    //             Cell: memo((params: ValueFormatterParams) => (
    //                 <span>{amountValueFormatter(params.data.amount)}</span>
    //             )),
    //         },
    //         {
    //             Header: 'Purchase Date',
    //             accessor: 'purchase_date',
    //             type: 'string',
    //             minWidth: 110,
    //         },
    //     ]

    //     const columns = useMemo(() => columnDefinitions, [columnDefinitions])

    //     const filterValuesMemo = useMemo(() => {
    //         return {}
    //     }, [])

    //     const {
    //         dataSource,
    //         isLoading,
    //         pageSize,
    //         page,
    //         paginationTotal,
    //         setLocalPage,
    //         handlePageLimitChange,
    //     } = useAGDataGridActions('builder/', gridRef, undefined, filterValuesMemo)

    // const defaultColDef = {
    //     flex: 1,
    //     minWidth: 80,
    //     resizable: true,
    // }

    return (
        //TODO: uncomment once endpoint for datagrid is available and enable test on ConfirmCaseBuilderSummaryStep4.test.tsx line 45.
        // <div className={'emp-confirmCaseBuilderSummaryAGGrid-gridContainer}>
        //     <AGDataGrid
        //         gridRef={gridRef}
        //         gridName={'confirmCaseBuilderSummaryGrid'}
        //         columns={columns}
        //         dataSource={dataSource}
        //         loading={isLoading}
        //         testId={testId}
        //         totalEntries={paginationTotal}
        //         defaultColDef={defaultColDef}
        //         rowSelectionType={'single'}
        //         disableRowClickSelection
        //         rowsPerPageOptions={[5, 10, 25, 50, 100]}
        //         onPageLimitChange={handlePageLimitChange}
        //         pageSize={pageSize}
        //         page={page}
        //         onPageChange={setLocalPage}
        //         paginate={false}
        //         enableSideBarPanel={false}
        //     />
        // </div>

        //TODO: Remove once endpoint for datagrid is available.
        <div
            className={`ag-theme-alpine ${'emp-confirmCaseBuilderSummaryAGGrid-gridContainer'}`}
        >
            <AgGridReact
                rowData={[]}
                columnDefs={[
                    { field: 'Customer Name' },
                    { field: 'Case Number' },
                    { field: 'Card Number' },
                    { field: 'Amount' },
                    { field: 'Purchase Date' },
                ]}
                overlayNoRowsTemplate={`<span class="ag-overlay-loading-center">No Records Found!</span>`}
                defaultColDef={{
                    flex: 1,
                    minWidth: 80,
                    resizable: true,
                    menuTabs: [],
                }}
                rowHeight={30}
                headerHeight={30}
            />
        </div>
    )
}

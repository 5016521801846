import React, { useEffect, useState, useMemo } from 'react'
import { Tooltip } from '@mui/material'
import { UploadIcon, Icon, CaseBuilder, ConfirmActionModal } from 'components'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import { useSWRContext } from 'context/SWRContext'
import { CaseStatusId } from 'hooks/cb-api'
import { clientTheme } from 'theme-exports'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface DnrActionIconProps {
    row: any
    iconSize?: 'small' | 'medium' | 'large' | undefined
    rehydrateView: () => void
    returnNetworkStatus?: (status: boolean) => void
    caseModel?: any
    setShowSnackbar?: (status: boolean) => void
    testId: string
    selectedTabFilter?: string
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

interface DnrProps {
    openModal: boolean
    toggleModal: () => void
    id: number
    rehydrateView: () => void
    callAction: (case_id: number, status_id: CaseStatusId) => any
    loading: boolean
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

const RevertDnr = ({
    openModal,
    toggleModal,
    id,
    rehydrateView,
    callAction,
    loading,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: DnrProps): React.ReactElement => {
    
    const revertCaseToNew = () =>
        callAction(id, CaseStatusId.New)
            .then(() => {
                setAlertSnackbarMainProps({
                    message: 'Your action was a success!',
                    title: 'Success.',
                    intent: 'success',
                })
            })
            .catch(() => {
                setAlertSnackbarMainProps({
                    message: 'There was an error, try again.',
                    title: 'Error.',
                    intent: 'error',
                })
            })
            .finally(() => {
                toggleModal()
                setAlertSnackbarMainOpen(true)
            })

    return (
        <div>
            <ConfirmActionModal
                open={openModal}
                toggleOpen={toggleModal}
                onConfirm={revertCaseToNew}
                header={`Revert Do Not Represent`}
                message={'You are about to revert case(s)'}
                confirmButtonText={'Continue'}
                inactivateConfirmButton={loading}
                isLoadingConfirmButton={loading}
                testId={'revertDoNotRepresent'}
            />
        </div>
    )
}

const DoNotRepresent = ({
    openModal,
    toggleModal,
    id,
    rehydrateView,
    callAction,
    loading,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: DnrProps): React.ReactElement => {

    const moveToDnrStatus = () =>
        callAction(id, CaseStatusId.DoNotRepresent)
            .then(() => {
                setAlertSnackbarMainProps({
                    message: 'Your action was a success!',
                    title: 'Success.',
                    intent: 'success',
                })
            })
            .catch(() => {
                setAlertSnackbarMainProps({
                    message: 'There was an error, try again.',
                    title: 'Error.',
                    intent: 'error',
                })
            })
            .finally(() => {
                toggleModal()
                setAlertSnackbarMainOpen(true)
            })

    return (
        <div>
            <ConfirmActionModal
                open={openModal}
                maxModalWidth={'sm'}
                toggleOpen={toggleModal}
                onConfirm={moveToDnrStatus}
                header={`Move Case to Do Not Represent`}
                message={
                    'You are about to move this case to do not represent and mark as a loss, would you like to proceed?'
                }
                confirmButtonText={'Continue'}
                inactivateConfirmButton={loading}
                isLoadingConfirmButton={loading}
                testId={'doNotRepresent'}
            />
        </div>
    )
}

/**
 * Use DnrActionIcon to ___________________
 */
const DnrActionIcon = ({
    row,
    rehydrateView,
    testId,
    selectedTabFilter = '',
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: DnrActionIconProps): React.ReactElement => {
    const uiState = useUiStateContext()
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const [openMoveToDnr, setOpenMoveToDnr] = useState(false)
    const [openRevertDnr, setOpenRevertDnr] = useState(false)
    const [statusState, setStatusState] = useState({
        tooltip: '',
        action: () => {},
        icon: '',
    })
    const [openCaseBuilder, setOpenCaseBuilder] = useState<boolean>(false)

    const showCaseBuilderIcon = (uiState.whoami?.roles ?? []).some(
        (role) => role === 'case-builder'
    )

    const enableCaseBuilderIcon =
        Boolean(row.original?.assigned_user?.id === uiState.whoami?.id) ||
        selectedTabFilter === 'new_cases'

    const memoRow = useMemo(() => row, [row])

    const { swrActions } = useSWRContext()

    const { casesLoading, caseNetworkActions } = swrActions

    const {
        shouldDisableRevertDNR,
        shouldDisableDNR,
        shouldDisableUpload,
    } = swrActions.caseViewActions

    const { setCaseStatus } = caseNetworkActions

    const toggleMoveToDnr = (): void => setOpenMoveToDnr((prev) => !prev)

    const toggleRevertDnr = (): void => setOpenRevertDnr((prev) => !prev)

    useEffect(() => {
        if (
            !shouldDisableRevertDNR(
                row.original.properties.is_revert_dnr_enabled
            )
        ) {
            return setStatusState({
                tooltip: 'Revert Do Not Represent',
                action: toggleRevertDnr,
                icon: 'icon-revert-Dnr',
            })
        }
        if (shouldDisableDNR(row.original.properties.is_dnr_enabled)) {
            return setStatusState({
                tooltip: `Case ${row.original.status_group}`,
                action: () => {},
                icon: 'icon-revert-Dnr-disabled',
            })
        }
        return setStatusState({
            tooltip: 'Do Not Represent',
            action: toggleMoveToDnr,
            icon: 'icon-Dnr',
        })
        // each time a network call is made, isLoading toggles and will allow for this useEffect to re-run the statement after cache flush
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoRow])

    return (
        <div className={`emp-dnrActionIcon-actionCell`}>
            {openMoveToDnr && (
                <DoNotRepresent
                    openModal={openMoveToDnr}
                    toggleModal={toggleMoveToDnr}
                    id={memoRow.original.id}
                    rehydrateView={rehydrateView}
                    callAction={setCaseStatus}
                    loading={casesLoading}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                />
            )}

            {openRevertDnr && (
                <RevertDnr
                    openModal={openRevertDnr}
                    toggleModal={toggleRevertDnr}
                    id={memoRow.original.id}
                    rehydrateView={rehydrateView}
                    callAction={setCaseStatus}
                    loading={casesLoading}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                />
            )}

            <Tooltip arrow placement="top" title={statusState.tooltip}>
                <div
                    className={` ${
                        shouldDisableRevertDNR(
                            row.original.properties.is_revert_dnr_enabled
                        ) &&
                        shouldDisableDNR(row.original.properties.is_dnr_enabled)
                            ? 'disabled-icon'
                            : 'emp-dnrActionIcon-root'
                    }`}
                >
                    <Icon
                        onClick={isReadOnly ? () => {} : statusState.action}
                        className={`${statusState.icon} ${
                            isReadOnly && 'emp-dnrActionIcon-disabledIcon'
                        }`}
                        id={`${testId}-dnr-icon-${memoRow.id}`}
                        iconColor={clientTheme.icons.dnrAndRevertDnrIcon}
                    />
                </div>
            </Tooltip>
            {showCaseBuilderIcon &&
                ['new_cases', 'assigned', 'in_progress'].includes(
                    selectedTabFilter
                ) && (
                    <Tooltip
                        arrow
                        placement="top"
                        title={
                            selectedTabFilter === 'in_progress'
                                ? 'Case In Progress'
                                : 'Case Builder'
                        }
                    >
                        {!enableCaseBuilderIcon ? (
                            <div>
                                <Icon
                                    onClick={() => {}}
                                    className={'icon-case-builder-disabled'}
                                    id={`${testId}-case-builder-disabled-icon-${memoRow.id}`}
                                />
                            </div>
                        ) : (
                            <div className={`emp-dnrActionIcon-root`}>
                                <Icon
                                    onClick={
                                        isReadOnly
                                            ? () => {}
                                            : () => {
                                                  setOpenCaseBuilder(true)
                                              }
                                    }
                                    className={`icon-case-builder ${
                                        isReadOnly &&
                                        'emp-dnrActionIcon-disabledIcon'
                                    }`}
                                    id={`${testId}-case-builder-icon-${memoRow.id}`}
                                />
                            </div>
                        )}
                    </Tooltip>
                )}
            <UploadIcon
                rowIndex={memoRow.id}
                caseIds={memoRow.original.id}
                size="small"
                deactivated={shouldDisableUpload(
                    memoRow.original.properties.is_upload_representment_enabled
                )}
                row={memoRow.original}
                rehydrateView={rehydrateView}
                testId={testId}
                setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
            />
            <CaseBuilder
                openCaseBuilder={openCaseBuilder}
                onClose={() => setOpenCaseBuilder(false)}
                caseInfo={memoRow.original}
                setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                refreshGrid={rehydrateView}
            />
        </div>
    )
}

// Split apart this component if the file starts to exceeds 300 lines or pieces are reused in 3 places

export default DnrActionIcon

/**
 * @description Directory for ID Attributes on Accent Area Modal.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `accentAreaModal-div-root`,
    divTitle: `accentAreaModal-div-title`,
    divContent: `accentAreaModal-div-content`,
    divActions: `accentAreaModal-div-actions`,
    btnPrimary: `accentAreaModal-btn-primary`,
    btnSecondary: `accentAreaModal-btn-secondary`,
}

export default idDirectory

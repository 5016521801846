import React from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material'

export const DataDropdownTable = ({
    data,
    dropdownData,
}: {
    data: any
    dropdownData: {
        columns: { [key: string]: string }
        rows: { [key: string]: string }
    }
}) => {
    const { columns, rows } = dropdownData

    const colEntries = Object.entries(columns)
    const rowEntries = Object.entries(rows)

    return (
        <TableContainer
            className={'emp-dataDropdownTable-container'}
            component={Paper}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{''}</TableCell>
                        {colEntries.map((entry, idx) => (
                            <TableCell
                                align="center"
                                key={`${entry[0]}-${idx}`}
                            >
                                {entry[1]}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowEntries.map((entry, idx) => (
                        <TableRow key={`${entry[1]}-${idx}`}>
                            <TableCell align="right">{entry[1]}</TableCell>
                            {colEntries.map((col, idx) => (
                                <TableCell
                                    align="center"
                                    key={`${col[1]}-${idx}`}
                                >
                                    {data[col[0]][entry[0]]}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DataDropdownTable

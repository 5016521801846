import React, { useState } from 'react'
import View from 'views/View'
import { Grid, useTheme, Card } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import ReportsApi from 'api/ReportsApi'
import {
    LoadingIndicator,
    PresetDateRange,
    AccentArea,
    MerchantSwitcher,
    useActiveMerchant,
    SearchableSelect,
} from 'components'
import idDirectory from './idAttributes'
import { IDateRange } from 'components/PresetDateRange'
import { withRequiredRole } from 'components/useRequireRole'
import { format } from 'date-fns'
import EntFeature from 'models/EntFeature'
import { dateUtilities } from 'utils/dateUtilities'
import useIsMounted from 'hooks/utils/useIsMounted'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import useReportsCharts from './Charts/useReportsCharts'
import { ReportsChartVariant } from '../../../shared'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'

const ReportsFilters = ({
    setFilterValues,
    filterValues,
    presetDateRange,
}: {
    setFilterValues: (obj: any) => void
    filterValues: any
    presetDateRange: IDateRange
}): React.ReactElement => {
    const { FILTERS = false } = useFeatureToggle('REPORTS')
    const { ALL_GATEWAY_IDS, ALL_PLATFORMS } = FILTERS
    const [midsearchValue, setMidSearchValue] = useState('')
    const [midSearchLoading, setMidSearchLoading] = useState<boolean>(false)
    const [currencysearchValue, setCurrencySearchValue] = useState('')
    const [currencySearchLoading, setCurrencySearchLoading] = useState<boolean>(false)
    const [value, setValue] = useState({
        mid_id: 'All Mids',
        currency_id: 'All Currencies',
        date_reference: 'Posted Date',
        gateway_id: 'All Gateway IDs',
        platforms: 'All Platforms',
    })
    const [isLoading, setIsLoading] = useState(false)
    const { isMounted } = useIsMounted()

    const { id: merchantId } = useActiveMerchant()

    const [currenciesList, setCurrenciesList] = React.useState([
        {
            value: -1,
            id: -1,
            name: 'All Currencies',
            label: 'All Currencies',
        },
    ])
    const [midsList, setMidsList] = React.useState([
        {
            value: -1,
            id: -1,
            name: 'All Mids',
            label: 'All Mids',
        },
    ])

    const handlePickerChange = (e: any, accessor: string) => {
        setFilterValues({
            ...filterValues,
            [accessor]: e.value,
        })
        setValue({
            ...value,
            [accessor]: e.name,
        })

        if (accessor === 'currency_id' && e.value === -1) setCurrencySearchValue('')
        if (accessor === 'mid_id' && e.value === -1)setMidSearchValue('')
    }

    React.useEffect(() => {
        setIsLoading(true)
        if (merchantId && midsearchValue.length > 1) {
            setMidSearchLoading(true)
            ReportsApi.getMidsList(+merchantId, midsearchValue, 999)
                .then((data) => {
                    let dataWithUnsetOption = data
                    dataWithUnsetOption.unshift({
                        name: 'All MIDs',
                        value: -1,
                        label: 'All MIDs',
                    })
                    if (isMounted) setMidsList(dataWithUnsetOption)
                })
                .finally(() => {
                    setIsLoading(false)
                    setMidSearchLoading(false)
                })
        }
    }, [merchantId, midsearchValue]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setIsLoading(true)
        if (merchantId && currencysearchValue.length > 1) {
            setCurrencySearchLoading(true)
            ReportsApi.getCurrenciesList(999, currencysearchValue)
                .then((data) => {
                    let dataWithUnsetOption = data
                    dataWithUnsetOption.unshift({
                        name: 'All Currencies',
                        value: -1,
                        label: 'All Currencies',
                    })
                    if (isMounted) setCurrenciesList(dataWithUnsetOption)
                })
                .finally(() => {
                    setIsLoading(false)
                    setCurrencySearchLoading(false)
                })
        }
    }, [merchantId, currencysearchValue]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            id={idDirectory.divHeader}
            className={'emp-reportsHeader'}
        >
            <div className={'emp-inputs'}>
                <SearchableSelect
                    value={value.mid_id}
                    onValueChange={(e: any) => handlePickerChange(e, 'mid_id')}
                    options={
                        midsearchValue.length > 1 && !isLoading
                            ? midsList ?? []
                            : []
                    }
                    searchValue={midsearchValue}
                    onSearchChange={setMidSearchValue}
                    accessor={'name'}
                    testId="midId"
                    debounceDelay={200}
                    loadingValues={midSearchLoading}
                >
                    {midsearchValue.length < 2 && (
                        <div className={'emp-searchableText'}>
                            Please enter 2 or more characters
                        </div>
                    )}
                </SearchableSelect>
            </div>
            <div className={'emp-inputs'}>
                <SearchableSelect
                    value={value.currency_id}
                    onValueChange={(e) => handlePickerChange(e, 'currency_id')}
                    searchValue={currencysearchValue}
                    options={
                        currencysearchValue.length > 1 && !isLoading
                            ? currenciesList ?? []
                            : []
                    }
                    onSearchChange={setCurrencySearchValue}
                    accessor={'name'}
                    testId="currencyId"
                    debounceDelay={200}
                    loadingValues={currencySearchLoading}
                >
                    {currencysearchValue.length < 2 && (
                        <div className={'emp-searchableText'}>
                            Please enter 2 or more characters
                        </div>
                    )}
                </SearchableSelect>
            </div>
            {ALL_GATEWAY_IDS.enabled && (
                <div className={'emp-inputs'}>
                    <SearchableSelect
                        value={value.gateway_id}
                        onValueChange={(e) =>
                            handlePickerChange(e, 'gateway_id')
                        }
                        options={[
                            {
                                name: 'All Gateway IDs',
                                value: 0,
                                id: 0,
                                label: 'All Gateway IDs',
                            },
                        ]}
                        hideSearch
                        accessor={'name'}
                        testId="gatewayId"
                    />
                </div>
            )}
            {ALL_PLATFORMS.enabled && (
                <div className={'emp-inputs'}>
                    <SearchableSelect
                        value={value.platforms}
                        onValueChange={(e) =>
                            handlePickerChange(e, 'platforms')
                        }
                        options={[
                            {
                                name: 'All Platforms',
                                value: 0,
                                id: 0,
                                label: 'All Platforms',
                            },
                        ]}
                        hideSearch
                        accessor={'name'}
                        testId="platforms"
                    />
                </div>
            )}
            <div className={'emp-inputs'}>
                <SearchableSelect
                    value={value.date_reference}
                    onValueChange={(e) =>
                        handlePickerChange(e, 'date_reference')
                    }
                    options={[
                        {
                            name: 'Posted Date',
                            value: 0,
                            id: 0,
                            label: 'Posted Date',
                        },
                        {
                            name: 'Transaction Date',
                            value: 1,
                            id: 1,
                            label: 'Transaction Date',
                        },
                    ]}
                    accessor={'name'}
                    hideSearch
                    testId="dateRef"
                />
            </div>
            <div style={{ margin: '5px' }}>
                <PresetDateRange
                    dateRange={presetDateRange}
                    getDateRange={(date: string) => {
                        setFilterValues({
                            ...filterValues,
                            date_range: date,
                        })
                    }}
                    className={'emp-dateRangePicker'}
                    allowFutureDateSelection={false}
                    testId="reports"
                />
            </div>
        </div>
    )
}

export interface IReportFilter {
    merchant_id: number
    mid_id: number
    currency_id: number
    date_reference: number
    date_range: string
    gateway_id: number
    platforms: number
}

export const ReportsView: React.FC = () => {
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
    const { user } = useAuthedUser()
    const { CHARTS = false } = useFeatureToggle('REPORTS')

    const reportsChart =
        //@ts-ignore
        user?.role?.alias === 'merchant_admin'
            ? CHARTS.MERCHANT_ADMIN
            : CHARTS.PARTNER_ADMIN
    const {
        enabled: isReportsEnabled = false,
        charts_and_position = {},
        default_date_range = {},
    } = reportsChart ?? false

    const { id: clientId } = useActiveMerchant()
    const defaultDateRangeOnLoad: {
        to: Date
        from: Date
    } = dateUtilities.getPriorDays(30)
    const { to, from } = defaultDateRangeOnLoad
    const presetDateRangeDate: IDateRange = {
        to: format(to, 'yyyy-MM-dd'),
        from: format(from, 'yyyy-MM-dd'),
    }
    const initialApiDateRange = `${presetDateRangeDate.from}|${presetDateRangeDate.to}`

    const [filterValues, setFilterValues] = React.useState<IReportFilter>({
        merchant_id: +clientId,
        mid_id: -1,
        currency_id: -1,
        date_reference: 0,
        date_range: initialApiDateRange,
        gateway_id: 0,
        platforms: 0,
    })

    const { chartMap, chartSectionKeys } = useReportsCharts({
        filterValues,
        default_date_range,
    })

    React.useEffect(() => {
        setFilterValues({
            ...filterValues,
            merchant_id: +clientId,
        })
    }, [clientId]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <View
            title="Reports"
            breadcrumb="Reports"
            HeaderInterior={<MerchantSwitcher testId="reports" />}
            testId="reports"
        >
            {isReportsEnabled ? (
                <AccentArea
                    title=""
                    hasHeaderDivider={false}
                    headerActionAlign={'center'}
                    headerAction={
                        <ReportsFilters
                            filterValues={filterValues}
                            setFilterValues={setFilterValues}
                            presetDateRange={presetDateRangeDate}
                        />
                    }
                    testId="reports"
                >
                    {!filterValues.merchant_id ? (
                        <div className={'emp-loadingIndicator'}>
                            <LoadingIndicator />
                        </div>
                    ) : (
                        <>
                            {/* Maps over each chart section (defined in features exports) and renders a new Grid section for each - for each chartSectionKeys that are mapped through, all of the charts defined in each sections array is rendered within  */}
                            {chartSectionKeys.map(
                                (chartSection: string, idx) => {
                                    if (
                                        charts_and_position[chartSection]
                                            .type === 'card'
                                    ) {
                                        return (
                                            <Grid
                                                id={`${idDirectory.gridReport}-${chartSection}`}
                                                key={`grid-key-${idx}`}
                                                justifyContent="space-evenly"
                                                container
                                                className={
                                                    'emp-pieChartGrid'
                                                }
                                            >
                                                {charts_and_position[
                                                    chartSection
                                                ].charts.map(
                                                    (
                                                        chartVariant: ReportsChartVariant
                                                    ): React.ReactElement => (
                                                        <React.Fragment
                                                            key={`key-${chartVariant}`}
                                                        >
                                                            {
                                                                chartMap[
                                                                    chartVariant
                                                                ]
                                                            }
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </Grid>
                                        )
                                    } else {
                                        return (
                                            <div
                                                id={`${idDirectory.divReport}-${chartSection}`}
                                                className={
                                                    'emp-chartsGrid'
                                                }
                                                key={`div-key-${idx}`}
                                                style={{
                                                    gridTemplateColumns: isLargeScreen
                                                        ? `repeat(${charts_and_position[chartSection].charts.length}, 1fr)`
                                                        : '1fr',
                                                }}
                                            >
                                                {charts_and_position[
                                                    chartSection
                                                ].charts.map(
                                                    (
                                                        chartVariant: ReportsChartVariant,
                                                        idx: number
                                                    ): React.ReactElement => (
                                                        <Card
                                                            id={`${idDirectory.cardReport}-${chartVariant}`}
                                                            className={
                                                                'emp-chart'
                                                            }
                                                            key={`key-${chartVariant}`}
                                                        >
                                                            {
                                                                chartMap[
                                                                    chartVariant
                                                                ]
                                                            }
                                                        </Card>
                                                    )
                                                )}
                                            </div>
                                        )
                                    }
                                }
                            )}
                        </>
                    )}
                </AccentArea>
            ) : null}
        </View>
    )
}

export default withRequiredRole(EntFeature.REPORTS, ReportsView)

import React, { useCallback, useEffect, useState } from 'react'
import { Menu, MenuItem } from '@mui/material'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'

interface AGDataGridActionsColumnFilterProps {
    anchorEl: null | HTMLElement
    handleCloseDataGridColumnFilter: () => void
    gridRef: any
    gridName: string
    testId: string
}

export const AGDataGridActionsColumnFilter = ({
    anchorEl,
    handleCloseDataGridColumnFilter,
    gridRef,
    gridName,
    testId,
}: AGDataGridActionsColumnFilterProps): React.ReactElement => {
    const [columns, setColumns] = useState<{ [key: string]: any }[]>([])

    const filteredColumns =
        gridRef?.current?.api
            ?.getColumnDefs()
            .filter((col: any) => !col?.suppressColumnsToolPanel) ?? []

    const hiddenColumns =
        gridRef?.current?.api
            ?.getColumnDefs()
            .filter((col: any) => col?.hide) ?? []

    const handleColumnFilter = useCallback((field: string) => {
        const agGridColumnDefs = gridRef?.current?.api?.getColumnDefs()

        agGridColumnDefs.forEach((colDef: any) => {
            if (colDef.field === field) colDef.hide = !colDef.hide
        })

        gridRef.current!.columnApi.applyColumnState({
            state: agGridColumnDefs,
            applyOrder: true,
        })

        // Sorted to keep order in case columns were drag & dropped around.
        setColumns(
            agGridColumnDefs.sort((a: any, b: any) => a.sortIndex - b.sortIndex)
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleResetColumnFilter = () => {
        gridRef.current!.columnApi.resetColumnState()
        gridRef.current!.api.setFilterModel(null)
        gridRef.current!.api.deselectAll(null)
        localStorage.removeItem(`col-${gridName}`)
        localStorage.removeItem(`filter-${gridName}`)

        const columnDefs = gridRef?.current?.api
            ?.getColumnDefs()
            .filter((col: any) => !col?.suppressColumnsToolPanel)
        setColumns(columnDefs)
    }

    useEffect(() => {
        if (filteredColumns) {
            setColumns(
                filteredColumns.sort(
                    (a: any, b: any) => a.sortIndex - b.sortIndex
                )
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredColumns.length, hiddenColumns.length])

    return (
        <Menu
            id={`${idDirectory.popoverColumnFilter}-${testId}`}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseDataGridColumnFilter}
            classes={{ list: 'emp-agDataGridActionsColumnFilter-root' }}
            variant="menu"
            keepMounted
        >
            {columns.map((col: any, idx: number) => {
                return (
                    <MenuItem
                        key={`key-${col.field}-${idx}`}
                        className={`${'emp-agDataGridActionsColumnFilter-btn'} ${
                            col.hide &&
                            'emp-agDataGridActionsColumnFilter-hiddenBtn'
                        }`}
                        sx={[
                            col.hide && {
                                backgroundColor: `${clientTheme.mainLayout.backgroundColor} !important`,
                                '&:hover': {
                                    color: clientTheme.typography.fontColor
                                        .primaryText
                                        ? clientTheme.typography.fontColor
                                              .primaryText
                                        : '#263238',
                                },
                            },
                        ]}
                        onClick={() => handleColumnFilter(col.field)}
                    >
                        {col.headerName ?? ''}
                    </MenuItem>
                )
            })}
            <MenuItem
                onClick={handleResetColumnFilter}
                className={'emp-agDataGridActionsColumnFilter-resetBtn'}
                sx={{
                    '&:hover': {
                        backgroundColor: clientTheme.mainLayout.backgroundColor,
                    },
                }}
                id={`${idDirectory.btnColumnFilterReset}-${testId}`}
            >
                Reset
            </MenuItem>
        </Menu>
    )
}

import { OutlinedTextFieldProps, TextField } from '@mui/material'
import { Skeleton } from '@mui/material'
import React, { FC } from 'react'

interface FieldProps extends OutlinedTextFieldProps {
    loading?: boolean
}

const Field: FC<FieldProps> = ({ loading, ...props }) => {
    if (loading) {
        return <Skeleton className={'emp-field-root'} />
    }
    return <TextField {...props} variant="outlined" />
}

// Split apart this component if the file starts to exceeds 300 lines or pieces are reused in 3 places

export default Field

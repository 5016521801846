import React, { useEffect, useRef, useState } from 'react'
import AlertSnackbar, { alertSnackbarContentProps } from 'components/AlertSnackbar'
import { LoadingIndicator, SearchableSelect, StandardModal  } from 'components'
import {
    Button,
    IconButton,
    TextField,
    Typography,
    Box
} from  '@mui/material'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { clientTheme } from 'theme-exports'

type TSubject = {
    id: number
    name: string
    type: string
    format: string | null
    endpoint: string
}

type TOption = {
    id: number
    name: string
}

interface IConditionSelectInputProps {
    value: string
    endpoint: string
    condition: TCondition
    updateCondition: (vals: TCondition) => void
}

const CoonditionSelectInput = ({
    value,
    endpoint,
    condition,
    updateCondition,
}: IConditionSelectInputProps) => {
    const [options, setOptions] = useState<TOption[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const initialized = useRef<boolean>(false)

    useEffect(() => {
        if (!initialized.current) {
            axios.get(`${endpoint}?limit=999`)
                .then((resp) => {
                    const {
                        data: {
                            data: responseData
                        }
                    } = resp
                    let valuesToSet: TOption[]

                    // Unfortunately the various "gen" endpoints return different data shapes. Need to transform.
                    switch (endpoint) {
                        case '/cm/gen/currencies':
                            valuesToSet = responseData.map((obj: any) => {
                                return {
                                    id: obj.id,
                                    name: `${obj.currency} - ${obj.iso}`
                                }
                            })
                            break
                        case '/cm/gen/rc':
                            valuesToSet =responseData.map((obj: any) => {
                                return {
                                    id: obj.id,
                                    name: obj.code
                                }
                            })
                            break
                        default:
                            valuesToSet = responseData
                    }

                    setOptions([
                        {
                            id: 0,
                            name: 'Please Select'
                        },
                        ...valuesToSet
                    ])
                })
                .catch(() => {})
                .finally(() => {
                    setIsLoading(false)
                    initialized.current = true
                })
        }
        return () => {
            initialized.current = false
        }
    }, [endpoint])

    return (
        <SearchableSelect
            value={options.find((obj) => { return obj.id === +value})?.name || ''}
            onValueChange={(e: any) => {
                updateCondition({
                    ...condition,
                    value: e.id === 0 ? '' : e.id.toString()
                })
            }}
            options={options}
            accessor={'name'}
            isPaginate={false}
            hideSearch
            selectFontSize={clientTheme.typography.body1.fontSize}
            loadingValues={isLoading}
        />
    )
}

type TCondition = {
    subject_id: number
    operator_id: number
    value: string
}

interface IConditionProps {
    idx: number
    subjects: TSubject[]
    operators: TOption[]
    conditions: TCondition[]
    setConditions: (idx: number, vals: TCondition, isDelete?: boolean) => void
}

const Condition = ({
    idx,
    subjects,
    operators,
    conditions,
    setConditions,
}: IConditionProps) => {
    const condition = Object.values(conditions)[idx]
    const {
        subject_id,
        operator_id,
        value
    } = condition

    // Filter out previously selected values, with the exception of default "Please Select"
    /* ** NOTE: This was (temporarily) disabled for now, until some decisions are made **
    const selectedSubjects = conditions.map((obj) => obj.attribute).filter((val) => ![0, attribute].includes(val))
    const filteredSubjects = subjects.filter((obj) => !selectedSubjects.includes(obj.id)) */

    const selectedSubject = subjects.find((obj) => obj.id === subject_id)

    const currencyMask = createNumberMask({
        prefix: '',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ',',
        allowDecimal: true,
        decimalSymbol: '.',
        decimalLimit: 2,
        allowNegative: false,
        allowLeadingZeroes: false,
    })

    const deleteCondition = () => {
        setConditions(idx, condition, true)
    }

    const updateCondition = (vals: TCondition) => {
        setConditions(idx, vals)
    }

    const formControlClasses =
        'MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-root-MuiOutlinedInput-root'
    const formElementClasses =
        'maskedInput MuiInputBase-input MuiOutlinedInput-input MuiInputBase-input-MuiOutlinedInput-input'

    return (
        <div>
            <div>
                <Typography>Attribute:</Typography>
                <div>
                    <SearchableSelect
                        value={
                            subjects.find((obj) => {
                                return obj.id === subject_id
                            })?.name || ''
                        }
                        onValueChange={(e) => {
                            updateCondition({
                                subject_id: e?.id ?? 0,
                                operator_id: 0,
                                value: '',
                            })
                        }}
                        options={subjects}
                        accessor={'name'}
                        isPaginate={false}
                        hideSearch
                        selectFontSize={clientTheme.typography.body1.fontSize}
                    />
                </div>
            </div>
            <div>
                <Typography>Operator:</Typography>
                <div>
                    <SearchableSelect
                        value={
                            operators.find((obj) => {
                                return obj.id === operator_id
                            })?.name || ''
                        }
                        onValueChange={(e) => {
                            updateCondition({
                                ...condition,
                                operator_id: e?.id ?? 0,
                            })
                        }}
                        options={operators}
                        accessor={'name'}
                        isPaginate={false}
                        hideSearch
                        selectFontSize={clientTheme.typography.body1.fontSize}
                    />
                </div>
            </div>
            <div>
                <Typography>Value:</Typography>
                <div>
                    {selectedSubject?.endpoint.trim().length ? (
                        <CoonditionSelectInput
                            value={value}
                            condition={condition}
                            endpoint={selectedSubject.endpoint}
                            updateCondition={updateCondition}
                        />
                    ) : (
                        <>
                            {['string', 'id'].includes(
                                selectedSubject?.type.toLowerCase() ?? ''
                            ) && (
                                <TextField
                                    value={value}
                                    variant="outlined"
                                    placeholder={selectedSubject?.format || ''}
                                    onChange={(e) => {
                                        updateCondition({
                                            ...condition,
                                            value: e.target.value,
                                        })
                                    }}
                                    fullWidth
                                />
                            )}
                            {selectedSubject?.type.toLowerCase() === 'date' && (
                                <div className={formControlClasses}>
                                    <MaskedInput
                                        className={formElementClasses}
                                        mask={[
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            '-',
                                            /\d/,
                                            /\d/,
                                            '-',
                                            /\d/,
                                            /\d/,
                                        ]}
                                        value={value}
                                        placeholder={
                                            selectedSubject?.format || ''
                                        }
                                        onChange={(e) => {
                                            updateCondition({
                                                ...condition,
                                                value: e.target.value,
                                            })
                                        }}
                                    />
                                </div>
                            )}
                            {selectedSubject?.type.toLowerCase() ===
                                'number' && (
                                <div className={formControlClasses}>
                                    <MaskedInput
                                        className={formElementClasses}
                                        mask={currencyMask}
                                        value={value}
                                        onChange={(e) => {
                                            updateCondition({
                                                ...condition,
                                                value: e.target.value,
                                            })
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div>
                <div style={{ minHeight: 20.02 }}></div>
                <div>
                    {/* We were originally disallowing removing the 1st, if no others were created. Leaving here just in case. */}
                    {/* {(idx > 0 || conditions.length > 1) && */}
                    <IconButton
                        onClick={deleteCondition}
                        size="small"
                        style={{ color: '#c00', padding: '5px', width: '27px' }}
                    >
                        <Icon icon={faTimes} />
                    </IconButton>
                    {/* } */}
                </div>
            </div>
        </div>
    )
}

interface IVerifiRDRConfigProps {
    id: number
    isMid: boolean
    isOpen: boolean
    setIsOpen: (val: boolean) => void
}

const VerifiRDRConfig = ({
    id = 0,
    isMid = false,
    isOpen,
    setIsOpen,
}: IVerifiRDRConfigProps) => {
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})
    const [subjects, setSubjects] = useState<TSubject[]>([])
    const [operators, setOperators] = useState<TOption[]>([])
    const [configuredConditions, setConfiguredConditions] = useState<TCondition[]>([])
    const [conditionsLoading, setConditionsLoading] = useState<boolean>(false)
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true)
    const initialized = useRef(false)

    const updateConfiguredConditions = (
        idx: number,
        vals: TCondition,
        isDelete?: boolean
    ) => {
        let newConditions = configuredConditions

        if (isDelete) {
            newConditions.splice(idx, 1)
            return setConfiguredConditions([...newConditions])
        }

        newConditions[idx] = vals
        setConfiguredConditions([...newConditions])
    }

    const addDisabled = configuredConditions.find((obj) => obj.subject_id === 0) ? true : false
    const loadComplete = operators.length && subjects.length && !conditionsLoading

    useEffect(() => {
        const isValid
            = configuredConditions.filter((obj) => obj.subject_id === 0 || obj.operator_id === 0 || !obj.value.length).length ? true : false
        setSubmitDisabled(isValid)
    }, [configuredConditions])

    const getSubjects = () => {
        axios.get('/cm/rules/subjects')
            .then(({data: {data}}) => {
                setSubjects([
                    {
                        id: 0,
                        name: "Please Select",
                        type: "String",
                        format: null,
                        endpoint: "",
                    },
                    ...data
                ])
            })
            .catch(() => {})
    }

    const getOperators = () => {
        axios.get('/cm/rules/operators')
            .then(({data: {data}}) => {
                setOperators([
                    {
                        id: 0,
                        name: "Please Select"
                    },
                    ...data
                ])
            })
            .catch(() => {})
    }

    const getRules = () => {
        const endpoint = isMid ? `/cm/mids/${id}/rules` : `/cm/merchants/${id}/rules`
        setConditionsLoading(true)
        axios.get(endpoint)
            .then(({data: {data}}) => {
                if (!data.length) {
                    return setConfiguredConditions([{
                        subject_id: 0,
                        operator_id: 0,
                        value: ''
                    }])
                }
                setConfiguredConditions(data)
            })
            .catch(() => {})
            .finally(() => setConditionsLoading(false))
    }

    const submitRules = () => {
        setSubmitDisabled(true)
        const endpoint = isMid ? `/cm/mids/${id}/rules/rdr` : `/cm/merchants/${id}/rules/rdr`
        const payloadToSend = {
            rules: [...configuredConditions]
        }

        axios.put(endpoint, payloadToSend)
            .then(() => {
                setAlertSnackbarProps({
                    intent: 'success',
                    message: 'Successfully updated Verifi RDR rules.',
                    title: 'Success',
                })
                setAlertSnackbarOpen(true)
                setTimeout(() => {
                    setAlertSnackbarOpen(false)
                    setIsOpen(false)
                }, 3000)
            })
            .catch(() => {
                setAlertSnackbarProps({
                    intent: 'error',
                    message: 'An error occurred updating Verifi RDR rules.',
                    title: 'Error',
                })
                setAlertSnackbarOpen(true)
                setSubmitDisabled(false)
            })
    }

    useEffect(() => {
        if (isOpen && !initialized.current) {
            getSubjects()
            getOperators()
            getRules()
            initialized.current = true
        }
        return () => {
            setSubjects([])
            setOperators([])
            setConfiguredConditions([])
            initialized.current = false
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    const handleOnClose = () => {
        setIsOpen(false)
    }

    return (
        <StandardModal
            openModal={isOpen}
            onModalClose={handleOnClose}
            modalHeaderTitle={'RDR Rule'}
            testId={'verifiRDRConfig'}
            maxModalWidth={'md'}
            primaryBtnText={'Save Rule'}
            handlePrimaryActionBtn={submitRules}
            inactivatePrimaryActionBtn={submitDisabled}
            handleSecondaryActionBtn={handleOnClose}
            className={'emp-verifiRDRConfig-modalContainer'}
        >
            <div>
                <div
                    className={'emp-verifiRDRConfig-subheadingContainer'}
                    style={{ fontSize: clientTheme.typography.body1.fontSize }}
                >
                    Add Criteria for RDR to automatically refund a transaction.
                </div>

                {!loadComplete ? (
                    <div className={'emp-verifiRDRConfig-loaderContainer'}>
                        <LoadingIndicator text={'Loading Verifi RDR Rules'} />
                    </div>
                ) : (
                    <div
                        className={'emp-verifiRDRConfig-mainContainer'}
                        style={{
                            fontSize: clientTheme.typography.body1.fontSize,
                        }}
                    >
                        <Box
                            className={
                                'emp-verifiRDRConfig-conditionsContainer'
                            }
                            sx={{
                                '& > div': {
                                    '& .MuiOutlinedInput-root input': {
                                        color: clientTheme.typography.fontColor
                                            .primaryText
                                            ? clientTheme.typography.fontColor
                                                  .primaryText
                                            : '#263238',
                                    },

                                    '& .MuiOutlinedInput-root': {
                                        borderRadius:
                                            clientTheme.selectionBox
                                                .borderRadius,
                                    },

                                    '& .maskedInput': {
                                        fontFamily: clientTheme.typography.fontFamily.join(
                                            ','
                                        ),
                                        fontSize:
                                            clientTheme.typography.body1
                                                .fontSize,
                                        borderRadius:
                                            clientTheme.selectionBox
                                                .borderRadius,

                                        '&:focus': {
                                            outline: 'none !important',
                                            lineHeight: '1.2em',
                                            border: `2px solid ${clientTheme.primary}`,
                                            borderRadius:
                                                clientTheme.selectionBox
                                                    .borderRadius,
                                        },
                                    },
                                },
                            }}
                        >
                            {configuredConditions.map((_, idx) => {
                                return (
                                    <Condition
                                        idx={idx}
                                        key={idx}
                                        subjects={subjects}
                                        operators={operators}
                                        conditions={configuredConditions}
                                        setConditions={
                                            updateConfiguredConditions
                                        }
                                    />
                                )
                            })}
                        </Box>
                        <div>
                            <Button
                                variant="outlined"
                                color="secondary"
                                className={'emp-verifiRDRConfig-addButton'}
                                onClick={() => {
                                    setConfiguredConditions([
                                        ...configuredConditions,
                                        {
                                            subject_id: 0,
                                            operator_id: 0,
                                            value: '',
                                        },
                                    ])
                                }}
                                disabled={
                                    addDisabled ||
                                    configuredConditions.length >=
                                        subjects.length - 1
                                }
                            >
                                <Icon icon={faPlusCircle} />
                                Add Condition
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </StandardModal>
    )
}

export default VerifiRDRConfig
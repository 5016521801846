import React from 'react'
import './views.css'
import {
    Card,
    CardContent,
    Typography,
    ListItem,
    ListItemIcon,
    List,
    ListItemText,
    Grid,
    Button,
} from '@mui/material'
import { Link, useHistory } from 'react-router-dom'
import { faHome, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
// import { Logo } from 'components'
// import cb911Logo from 'assets/logos/CB911-logo-RGB.png'

const ListItemLink = ({
    icon,
    primary,
    to,
}: {
    icon?: React.ReactNode
    primary: string
    to: string
}) => {
    // Recommended way of passing a link through via mui
    const renderLink = React.useMemo(
        // @ts-ignore
        () =>
            React.forwardRef((itemProps, ref) => (
                <Link to={to} {...itemProps} />
            )),
        [to]
    )
    return (
        <li>
            <ListItem button component={renderLink}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    )
}

/** Use to create a list item that is actually an anchor */
const ListItemAnchor = ({
    icon,
    primary,
    href,
}: {
    icon?: React.ReactNode
    primary: string
    href: string
}) => {
    return (
        <li>
            <ListItem button component="a" href={href}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    )
}

/**
 * Place for 404 results to display
 */
const NotFound = () => {
    const history = useHistory()

    return (
        <>
            <div className={'emp-notFound-root'}>
                {/* <header className={'emp-notFound-header'}>
                <Link className={'emp-notFound-logoLink'} to="/">
                    <Logo
                        alt="Chargebacks911"
                        aria-label="Banner Logo"
                        src={cb911Logo}
                    />
                </Link>
            </header> */}
                <Grid
                    className={'emp-notFound-gridContainer'}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12} md={9}>
                        <Card>
                            <CardContent>
                                <Typography
                                    className={'emp-notFound-title'}
                                    variant="h1"
                                    gutterBottom
                                    color="error"
                                >
                                    Page Not Found
                                </Typography>
                                <Typography variant="body1">
                                    We were unable to find the content you were
                                    looking for. Here are some things you can
                                    try:
                                </Typography>
                                <List aria-label="main">
                                    {history?.goBack && (
                                        <ListItem>
                                            <Button onClick={history?.goBack}>
                                                Go Back
                                            </Button>
                                        </ListItem>
                                    )}
                                    <ListItemLink
                                        to="/"
                                        icon={<Icon icon={faHome} />}
                                        primary="Go Home"
                                    />
                                    <ListItemAnchor
                                        href="mailto:support@chargebacks911.com"
                                        icon={<Icon icon={faEnvelope} />}
                                        primary="Contact Support"
                                    />
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
// @TODO consider refactoring out the support email

export default NotFound

/**
 * @description Directory for ID Attributes on Searchbar.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
 const idDirectory = {
    input: `searchbar-input-searchField`
}

export default idDirectory

import React, { useState } from 'react'
import {
    Button,
    Typography,
    Divider,
    Dialog,
    DialogContent,
    CircularProgress,
} from '@mui/material'
import { format } from 'date-fns'
import {
    faArrowLeft,
    faCheck,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { faCreditCard } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clientTheme } from 'theme-exports'
import {
    InputCaseBuilderDetailsStep1,
    InputCaseBuilderDetailsStep2,
    UploadCaseBuilderDocumentsStep3,
    ConfirmCaseBuilderSummaryStep4,
    LoadingIndicator,
    ConfirmActionModal,
    AlertSnackbar,
} from 'components'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import { DocumentOption } from '../UploadCaseBuilderDocumentsStep3/UploadCaseBuilderDocumentsStep3'
import { SubmitRepresentmentModal } from './modals/SubmitRepresentmentModal'
import useCaseBuilder from './CaseBuilder.vm'

import idDirectory from './idAttributes'

export interface CaseBuilderProps {
    openCaseBuilder: boolean
    onClose: () => void
    caseInfo: any
    refreshGrid: () => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

const CaseBuilder = ({
    openCaseBuilder,
    onClose,
    caseInfo,
    refreshGrid,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: CaseBuilderProps) => {
    const [
        confirmActionModalOpen,
        setConfirmActionModalOpen,
    ] = useState<boolean>(false)
    const [
        enableBackConfirmActionModal,
        setEnableBackConfirmActionModal,
    ] = useState<boolean>(false)
    const [
        combinedDocumentsListUpdated,
        setCombinedDocumentsListUpdated,
    ] = useState<boolean>(false)
    const [formInputValues, setFormInputValues] = useState({})

    const handleResetCaseBuilder = () => {
        onClose()
        setConfirmActionModalOpen(false)
        setFieldErrorsList([])
        setCombinedDocumentsList([])
        setSavedDocumentsList([])
        setInitialCaseDetails(null)
        setCaseBuilderStep(1)
    }

    const {
        caseBuilderStep,
        setCaseBuilderStep,
        enableSaveAndContinue,
        setEnableSaveAndContinue,
        alertSnackbarProps,
        setAlertSnackbarProps,
        alertSnackbarSubmitRepresentmentOpen,
        setAlertSnackbarSubmitRepresentmentOpen,
        alertSnackbarOpen,
        setAlertSnackbarOpen,
        isLoading,
        isSubmitting,
        isSubmittingRepresentment,
        initialCaseDetails,
        setInitialCaseDetails,
        handleCaseBuilderStepChange,
        handleSaveInputDetails,
        handleSaveUploadDocuments,
        openSubmitRepresentmentModal,
        setOpenSubmitRepresentmentModal,
        combinedDocumentsList,
        setCombinedDocumentsList,
        savedDocumentsList,
        setSavedDocumentsList,
        fieldErrorsList,
        setFieldErrorsList,
        setFormProductTypeId,
        formProductTypeId,
        setSelectedProductDetails,
        selectedProductDetails,
        loadingMaxUploadFileSize,
        maxUploadFileSize,
    } = useCaseBuilder(
        openCaseBuilder,
        caseInfo,
        handleResetCaseBuilder,
        refreshGrid,
        setAlertSnackbarMainProps,
        setAlertSnackbarMainOpen
    )

    const caseDetailPanels = [
        { title: 'Card Number', accessor: 'cc_no' },
        { title: 'Dispute Amount', accessor: 'dispute_amt' },
        { title: 'Purchase Date', accessor: 'date_trans' },
        { title: 'Dispute Date', accessor: 'date_created' },
        { title: 'Due Date', accessor: 'date_due' },
    ]

    const enableLastUpdatedDisplay =
        caseBuilderStep === 1 &&
        !isLoading &&
        initialCaseDetails?.last_updated?.timestamp

    const handleOnCloseCaseBuilderModal = () => {
        const uploadedCaseDocuments = combinedDocumentsList.filter(
            (doc: DocumentOption) => doc.file
        )
        const uploadedSavedCaseDocuments = savedDocumentsList.filter(
            (doc: DocumentOption) => doc.file
        )

        uploadedSavedCaseDocuments.length ||
        (uploadedCaseDocuments.length && caseBuilderStep === 3)
            ? setConfirmActionModalOpen(true)
            : handleResetCaseBuilder()
    }

    const handleSaveAndContinue = () => {
        if (caseBuilderStep === 1 || caseBuilderStep === 2)
            handleSaveInputDetails(formInputValues)
        if (caseBuilderStep === 3) {
            handleSaveUploadDocuments(formInputValues)
        }
        if (caseBuilderStep === 4) handleCaseBuilderStepChange('continue')
    }

    const handleBack = () => {
        const uploadedCaseDocuments = combinedDocumentsList.filter(
            (doc: DocumentOption) => doc.file
        )
        const uploadedSavedCaseDocuments = savedDocumentsList.filter(
            (doc: DocumentOption) => doc.file
        )

        if (
            caseBuilderStep === 3 &&
            ((uploadedCaseDocuments.length &&
                !uploadedSavedCaseDocuments.length) ||
                combinedDocumentsListUpdated)
        ) {
            setConfirmActionModalOpen(true)
            setEnableBackConfirmActionModal(true)
        } else {
            handleCaseBuilderStepChange('back')
        }
    }

    const handleOnCloseAlertSnackbar = () => {
        setAlertSnackbarOpen(false)
        setAlertSnackbarSubmitRepresentmentOpen(false)
    }

    const handleConfirmActionModal = () => {
        if (enableBackConfirmActionModal) {
            setEnableBackConfirmActionModal(false)
            setConfirmActionModalOpen(false)
            setCombinedDocumentsList([])
            handleCaseBuilderStepChange('back')
        } else {
            handleResetCaseBuilder()
        }
    }

    return (
        <Dialog
            open={openCaseBuilder}
            onClose={(_, reason) => {
                if (reason && reason === "backdropClick") return
                handleOnCloseCaseBuilderModal()
            }}
            id={idDirectory.caseBuilder.divRoot}
            maxWidth={'md'}
            fullWidth
        >
            <div
                className={'emp-caseBuilder-topbarContainer'}
                id={idDirectory.caseBuilder.divTopbar}
            >
                <div
                    className={'emp-caseBuilder-topbarTitleContainer'}
                    id={idDirectory.caseBuilder.divTopbarTitle}
                >
                    <div
                        className={
                            'emp-caseBuilder-topbarTitleTypographyContainer'
                        }
                        id={idDirectory.caseBuilder.divTopbarTitleTypography}
                    >
                        <FontAwesomeIcon
                            icon={faCreditCard}
                            className={'emp-caseBuilder-creditCardIcon'}
                        />
                        <Typography
                            variant="h3"
                            className={
                                'emp-caseBuilder-topbarTitleTypography'
                            }
                        >
                            Case Builder - Dispute Assistant
                        </Typography>
                    </div>
                    <div
                        className={
                            'emp-caseBuilder-topbarInfoTypographyContainer'
                        }
                        id={idDirectory.caseBuilder.divTopbarInfoTypography}
                    >
                        <div>
                            <FontAwesomeIcon
                                icon={faTimes}
                                className={`emp-caseBuilder-closeIcon `}
                                onClick={handleOnCloseCaseBuilderModal}
                                id={idDirectory.caseBuilder.iconClose}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className={'emp-caseBuilder-topbarDetailsContainer'}
                    id={idDirectory.caseBuilder.divTopbarDetails}
                >
                    {caseDetailPanels.map((detail, idx) => (
                        <div
                            className={'emp-caseBuilder-topbarDetailPanel'}
                            key={`panel-${idx}`}
                            id={`${idDirectory.caseBuilder.divTopbarDetailPanel}-${detail.accessor}`}
                        >
                            <Typography
                                variant="body1"
                                className={
                                    'emp-caseBuilder-topbarDetailPanelTypography'
                                }
                                id={`${idDirectory.caseBuilder.divTopbarDetailTitle}-${detail.accessor}`}
                            >
                                {detail.title}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={
                                    'emp-caseBuilder-topbarDetailPanelTypography'
                                }
                                id={`${idDirectory.caseBuilder.divTopbarDetailValue}-${detail.accessor}`}
                            >
                                {caseInfo[detail.accessor] ?? ''}
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>
            <DialogContent
                className={'emp-caseBuilder-mainContainer'}
                id={idDirectory.caseBuilder.divMain}
            >
                {/* Add Case Builder Steps Here! */}
                {(isLoading ||
                    (caseBuilderStep === 3 && loadingMaxUploadFileSize)) && (
                    <div className={'emp-caseBuilder-loadingContainer'}>
                        <LoadingIndicator />
                    </div>
                )}
                {caseBuilderStep === 1 && !isLoading && openCaseBuilder && (
                    <InputCaseBuilderDetailsStep1
                        caseInfo={caseInfo}
                        setEnableSaveAndContinue={setEnableSaveAndContinue}
                        initialCaseDetails={initialCaseDetails}
                        setFormInputValues={setFormInputValues}
                        fieldErrorList={fieldErrorsList}
                        setFormProductTypeId={setFormProductTypeId}
                        formProductTypeId={formProductTypeId}
                        setSelectedProductDetails={setSelectedProductDetails}
                    />
                )}
                {caseBuilderStep === 2 && !isLoading && (
                    <InputCaseBuilderDetailsStep2
                        setEnableSaveAndContinue={setEnableSaveAndContinue}
                        initialCaseDetails={initialCaseDetails}
                        setFormInputValues={setFormInputValues}
                        fieldErrorList={fieldErrorsList}
                    />
                )}
                {caseBuilderStep === 3 &&
                    !isLoading &&
                    !loadingMaxUploadFileSize && (
                        <UploadCaseBuilderDocumentsStep3
                            setEnableSaveAndContinue={setEnableSaveAndContinue}
                            combinedDocumentsList={combinedDocumentsList}
                            setCombinedDocumentsList={setCombinedDocumentsList}
                            savedDocumentsList={savedDocumentsList}
                            setAlertSnackbarOpen={setAlertSnackbarOpen}
                            setAlertSnackbarProps={setAlertSnackbarProps}
                            setCombinedDocumentsListUpdated={
                                setCombinedDocumentsListUpdated
                            }
                            initialCaseDetails={initialCaseDetails}
                            setFormInputValues={setFormInputValues}
                            caseId={caseInfo?.id}
                            maxUploadFileSize={maxUploadFileSize}
                        />
                    )}
                {caseBuilderStep === 4 && !isLoading && (
                    <ConfirmCaseBuilderSummaryStep4
                        handleEditLink={() => {
                            setCaseBuilderStep(2)
                        }}
                        caseDetails={initialCaseDetails}
                        selectedProductDetails={selectedProductDetails}
                        setFormInputValues={setFormInputValues}
                    />
                )}
            </DialogContent>
            <div
                className={'emp-caseBuilder-footerContainer'}
                id={idDirectory.caseBuilder.divFooter}
            >
                <Divider className={'emp-caseBuilder-divider'} />
                <div
                    className={'emp-caseBuilder-footerBtnsContainer'}
                    style={{
                        justifyContent: enableLastUpdatedDisplay
                            ? 'space-between'
                            : 'end',
                    }}
                >
                    {enableLastUpdatedDisplay && (
                        <Typography
                            variant="body1"
                            style={{ fontSize: '12px' }}
                        >
                            {`Last updated on ${format(
                                new Date(
                                    initialCaseDetails?.last_updated
                                        ?.timestamp ?? Date.now()
                                ),
                                "MMMM dd, yyyy 'at' K':'mmaaa"
                            )} by ${
                                initialCaseDetails?.last_updated?.username ??
                                'not available'
                            }.`}
                        </Typography>
                    )}
                    <div>
                        {caseBuilderStep !== 1 && (
                            <Button
                                variant="contained"
                                id={idDirectory.caseBuilder.btnBack}
                                className={'emp-caseBuilder-backBtn'}
                                onClick={handleBack}
                                disabled={isLoading}
                                sx={clientTheme.buttons.defaultButton.style}
                            >
                                <FontAwesomeIcon
                                    icon={faArrowLeft}
                                    className={'emp-caseBuilder-btnIcons'}
                                />
                                Back
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            id={idDirectory.caseBuilder.btnSaveContinue}
                            className={'emp-caseBuilder-saveContinueBtn'}
                            onClick={handleSaveAndContinue}
                            disabled={!enableSaveAndContinue || isSubmitting}
                        >
                            {isSubmitting ? (
                                <>
                                    Submitting...{' '}
                                    <CircularProgress
                                        className={
                                            'emp-caseBuilder-circularProgress'
                                        }
                                        color="secondary"
                                    />
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className={'emp-caseBuilder-btnIcons'}
                                    />
                                    Save &amp; Continue
                                </>
                            )}
                        </Button>
                    </div>
                </div>
            </div>
            <SubmitRepresentmentModal
                openModal={openSubmitRepresentmentModal}
                onClose={() => setOpenSubmitRepresentmentModal(false)}
                handleSubmitRepresentment={() => handleSaveInputDetails(formInputValues)}
                isSubmitting={isSubmittingRepresentment}
                alertSnackbarProps={alertSnackbarProps}
                alertSnackbarOpen={alertSnackbarSubmitRepresentmentOpen}
                handleOnCloseAlertSnackbar={handleOnCloseAlertSnackbar}
            />
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={handleOnCloseAlertSnackbar}
                showCloseIcon
            />
            <ConfirmActionModal
                open={confirmActionModalOpen}
                toggleOpen={() => {
                    setConfirmActionModalOpen(false)
                    setEnableBackConfirmActionModal(false)
                }}
                onConfirm={handleConfirmActionModal}
                header={'Are you sure?'}
                message={
                    enableBackConfirmActionModal
                        ? 'Any changes to uploaded documents will be lost.'
                        : 'Uploaded documents will be lost.'
                }
                confirmButtonText={'Continue'}
                testId={'upload-case-builder'}
            />
        </Dialog>
    );
}

export default CaseBuilder

/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
// import { forwardRef } from 'react';
import idDirectory from './idAttributes'
import { NavLink as RouterLink } from 'react-router-dom'
import type { INavLink } from 'NavLinks'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Collapse,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { Skeleton } from '@mui/material';
import EntFeature from 'models/EntFeature'
import User from 'models/User'
import { clientTheme } from 'theme-exports'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import useSidebarNav from './useSidebarNav'

/**
 *
 * If you came here looking for the actual nav links data, look in the ./src/NavLinks.tsx file
 *
 */

const defaultStyles = {
    root: 'emp-sidebarNavDefault-root',
    item: 'emp-sidebarNavDefault-item',
    icon: 'emp-sidebarNavDefault-icon',
    active: 'emp-sidebarNavDefault-active',
    merchant: 'emp-sidebarNavDefault-merchant',
    nested: 'emp-sidebarNavDefault-nested',
    text: 'emp-sidebarNavDefault-text',
    clientThemeItem: {
        '&:hover': {
            backgroundColor: `${clientTheme.sidebar.sideMenuHover.backgroundColor}!important`,
            '& *': {
                color: '#fff !important',
            },
        },
    },
    clientThemeActive: {
        backgroundColor: `${clientTheme.sidebar.sideMenuActive.backgroundColor}!important`, // important used to keep active state over hover.
        fontWeight: 500,
        '& *': {
            color: '#fff !important',
        },
    },
}

const stripeStyles = {
    root: 'emp-sidebarNavStripe-root',
    item: 'emp-sidebarNavStripe-item',
    icon: 'emp-sidebarNavStripe-icon',
    active: 'emp-sidebarNavStripe-active',
    merchant: 'emp-sidebarNavStripe-merchant',
    nested: 'emp-sidebarNavStripe-nested',
    text: 'emp-sidebarNavStripe-text',
    clientThemeItem: {
        '&:hover': {
            borderLeft: `5px solid #999`,
            '& *': {
                color: '#fff !important',
            },
        },
    },
    clientThemeActive: {
        fontWeight: 500,
        '& *': {
            color: '#fff !important',
        },
        borderLeft: `5px solid ${clientTheme.sidebar.sideMenuActive.backgroundColor} !important`,
    },
}

const altStyles = {
    root: 'emp-sidebarNavAlt-root',
    item: 'emp-sidebarNavAlt-item',
    icon: 'emp-sidebarNavAlt-icon',
    active: 'emp-sidebarNavAlt-active',
    merchant: 'emp-sidebarNavAlt-merchant',
    nested: 'emp-sidebarNavAlt-nested',
    text: 'emp-sidebarNavAlt-text',
    clientThemeItem: {
        '&:hover &:not(.active)': {
            backgroundColor: `#3f4143 !important`,
            '& *': {
                color: '#fff !important',
            },
        },
    },
    clientThemeActive: {
        backgroundColor: `red!important`, // important used to keep active state over hover.
        fontWeight: 500,
        '& *': {
            color: '#fff !important',
        },
    },
}

interface AccessCheckProps {
    loading: boolean
    user?: User
    feature: EntFeature | EntFeature[]
    children: React.ReactElement
}

/**
 * Determining if the sidebar part is read to load
 */
const AccessCheck: React.FC<AccessCheckProps> = ({
    loading,
    user = undefined,
    feature,
    children,
}: AccessCheckProps): React.ReactElement => {
    const { allowedResource } = useSidebarNav()
    return (
        <React.Fragment>
            {loading ? (
                <Skeleton />
            ) : allowedResource(feature) ? (
                children
            ) : null}
        </React.Fragment>
    )
}

interface SidebarNavProps {
    navLinks: INavLink[]
    // eslint-disable-next-line react/require-default-props
    className?: string
    // eslint-disable-next-line react/require-default-props
    currentPath?: string
    onSidebarOpen: () => void
    onSidebarClose: () => void
    openSidebar: boolean
    setPageTitle: (title: string) => void
}
/**
 * Housing for the sidebar navigation menu.
 */
const SidebarNav = ({
    className = '',
    navLinks,
    currentPath = '',
    onSidebarOpen,
    onSidebarClose,
    openSidebar,
    setPageTitle,
    ...rest
}: SidebarNavProps): React.ReactElement => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
    const { user, loading } = useAuthedUser()
    const [openMenu, setOpenMenu] = React.useState<string | null>(null)
    const {
        enabled: sideNavigationEnabled,
        client_theme_active: showClientTheme,
        NAV_SELECTION_UI,
    } = useFeatureToggle('MAIN_SIDEBAR')
    const { type: navSelectionUIType } = NAV_SELECTION_UI
    const { enabled: themeVariationEnabled } = useFeatureToggle(
        'HAS_THEME_VARIATIONS'
    )

    // set default open menu.
    React.useEffect(() => {
        navLinks.forEach((i) => {
            if (i.sublinks?.map((sub) => sub.href).includes(currentPath)) {
                setOpenMenu(i.title)
            }
        })
    }, [navLinks, currentPath])

    if (!user?.theme_variant && themeVariationEnabled) {
        return <></>
    }

    const metricStyles =
        user?.theme_variant && user?.theme_variant > 1
            ? altStyles
            : navSelectionUIType === 'box'
            ? defaultStyles
            : stripeStyles

    const isActive = (nLink: Partial<INavLink>): boolean => {
        const { href, sublinks, title } = nLink
        return (
            (!sublinks && currentPath === href) ||
            (openMenu && currentPath === href) ||
            ((!openMenu || openMenu !== title) &&
                sublinks?.map((i) => i.href).includes(currentPath)) ||
            false
        )
    }

    return (
        <>
            {sideNavigationEnabled ? (
                <List
                    id={idDirectory.list}
                    {...rest}
                    className={`ent-sidebar-nav ${metricStyles['root']} ${className}`}
                    style={{ fontSize: clientTheme.sidebar.fontSize }}
                >
                    {navLinks.map((nLink, idx) =>
                        nLink.sublinks ? (
                            // category heading
                            <React.Fragment key={nLink.title}>
                                <AccessCheck
                                    loading={loading}
                                    user={user}
                                    feature={[
                                        nLink.feature,
                                        ...nLink.sublinks.map((i) => i.feature),
                                    ]}
                                >
                                    <React.Fragment>
                                        <ListItem
                                            id={`${idDirectory.listItem}-${nLink.title}`}
                                            className={`${
                                                metricStyles['item']
                                            } ${
                                                isActive(nLink) &&
                                                !showClientTheme &&
                                                metricStyles['active']
                                            } ${
                                                !showClientTheme &&
                                                metricStyles['item']
                                            }`}
                                            sx={[
                                                showClientTheme &&
                                                    metricStyles[
                                                        'clientThemeItem'
                                                    ],
                                                isActive(nLink) &&
                                                    showClientTheme &&
                                                    metricStyles[
                                                        'clientThemeActive'
                                                    ],
                                            ]}
                                            onClick={(): void => {
                                                onSidebarOpen()
                                                setOpenMenu(
                                                    nLink.title === openMenu
                                                        ? null
                                                        : nLink.title
                                                )
                                            }}
                                            button
                                        >
                                            <ListItemIcon
                                                id={`${idDirectory.listItemIcon}-${nLink.title}`}
                                                className={metricStyles['icon']}
                                            >
                                                {nLink.icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                id={`${idDirectory.listItemText}-${nLink.title}`}
                                                primary={nLink.title}
                                                className={metricStyles['text']}
                                                disableTypography
                                            />
                                            {openSidebar ? (
                                                nLink.title === openMenu ? (
                                                    <Icon
                                                        id={`${idDirectory.iconCaretDown}-${nLink.title}`}
                                                        icon={faCaretDown}
                                                    />
                                                ) : (
                                                    <Icon
                                                        id={`${idDirectory.iconCaretRight}-${nLink.title}`}
                                                        icon={faCaretRight}
                                                    />
                                                )
                                            ) : null}
                                        </ListItem>
                                        <Collapse
                                            in={
                                                openSidebar
                                                    ? nLink.title === openMenu
                                                    : false
                                            }
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <List
                                                id={`${idDirectory.subList}-${nLink.title}`}
                                                component="div"
                                                className={`${metricStyles['nested']}`}
                                                style={{
                                                    backgroundColor:
                                                        clientTheme.sidebar
                                                            .darkBackgroundColor,
                                                }}
                                            >
                                                {nLink.sublinks.map(
                                                    (sublink, idx) =>
                                                        sublink.href && (
                                                            <AccessCheck
                                                                key={
                                                                    sublink.title
                                                                }
                                                                loading={
                                                                    loading
                                                                }
                                                                user={user}
                                                                feature={
                                                                    sublink.feature
                                                                }
                                                            >
                                                                <ListItem
                                                                    id={`${idDirectory.subListItem}-${nLink.title}-${sublink.title}`}
                                                                    className={`${
                                                                        metricStyles[
                                                                            'item'
                                                                        ]
                                                                    } ${
                                                                        isActive(
                                                                            sublink
                                                                        ) &&
                                                                        !showClientTheme &&
                                                                        metricStyles[
                                                                            'active'
                                                                        ]
                                                                    } ${
                                                                        !showClientTheme &&
                                                                        metricStyles[
                                                                            'item'
                                                                        ]
                                                                    }`}
                                                                    sx={[
                                                                        showClientTheme &&
                                                                            metricStyles[
                                                                                'clientThemeItem'
                                                                            ],

                                                                        isActive(
                                                                            sublink
                                                                        ) &&
                                                                            showClientTheme &&
                                                                            metricStyles[
                                                                                'clientThemeActive'
                                                                            ],
                                                                    ]}
                                                                    component={
                                                                        RouterLink
                                                                    }
                                                                    to={
                                                                        sublink.href
                                                                    }
                                                                    onClick={() => {
                                                                        sublink.minimizeDrawer ||
                                                                        !isDesktop
                                                                            ? onSidebarClose()
                                                                            : onSidebarOpen()

                                                                        setPageTitle(
                                                                            sublink.title
                                                                        )
                                                                    }}
                                                                    button
                                                                >
                                                                    <ListItemText
                                                                        id={`${idDirectory.subListItemText}-${nLink.title}-${sublink.title}`}
                                                                        primary={
                                                                            sublink.title
                                                                        }
                                                                        className={
                                                                            metricStyles[
                                                                                'text'
                                                                            ]
                                                                        }
                                                                        disableTypography
                                                                    />
                                                                </ListItem>
                                                            </AccessCheck>
                                                        )
                                                )}
                                            </List>
                                        </Collapse>
                                    </React.Fragment>
                                </AccessCheck>
                            </React.Fragment>
                        ) : (
                            // Issues with component passing: https://github.com/mui-org/material-ui/issues/16846
                            // root link item
                            <AccessCheck
                                key={nLink.title}
                                loading={loading}
                                user={user}
                                feature={nLink.feature}
                            >
                                <ListItem
                                    id={`${idDirectory.listItem}-${nLink.title}`}
                                    className={`${metricStyles['item']} ${
                                        isActive(nLink) &&
                                        !showClientTheme &&
                                        metricStyles['active']
                                    }`}
                                    sx={[
                                        showClientTheme &&
                                            metricStyles['clientThemeItem'],
                                        isActive(nLink) &&
                                            showClientTheme &&
                                            metricStyles['clientThemeActive'],
                                    ]}
                                    to={nLink.href || ''}
                                    component={RouterLink}
                                    onClick={() => {
                                        nLink.minimizeDrawer || !isDesktop
                                            ? onSidebarClose()
                                            : onSidebarOpen()

                                        setPageTitle(nLink.title)
                                    }}
                                    button
                                >
                                    <React.Fragment>
                                        <ListItemIcon
                                            id={`${idDirectory.listItemIcon}-${nLink.title}`}
                                            className={metricStyles['icon']}
                                        >
                                            {nLink.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            id={`${idDirectory.listItemText}-${nLink.title}}`}
                                            primary={nLink.title}
                                            className={metricStyles['text']}
                                            disableTypography
                                        />
                                    </React.Fragment>
                                </ListItem>
                            </AccessCheck>
                        )
                    )}
                </List>
            ) : null}
        </>
    )
}

export default SidebarNav

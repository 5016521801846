import { useState } from 'react'
import { AxiosError } from 'axios'
import { useActiveMerchant } from 'components'
import { useSwrData, CaseData, useParams, UserModel } from 'hooks/cb-api'
import { useSWRContext } from 'context/SWRContext'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

type UserId = number | boolean | undefined
type CaseId = number | undefined

interface UseAssignUser {
    searchValue: string | undefined
    users: any[]
    handleSearch: any
    selectedUser: UserModel | null
    setSelectedUser: (value: UserModel | null) => void
    handleChangeValue: any
    isLoadingAssign: boolean
    isLoadingUnassign: boolean
    handleBulkAssignCase: (caseIds: number[]) => void
    handleBulkUnassignCase: (caseIds: number[]) => void
    handleBulkAssignAdminReview: (caseIds: number[]) => void
    handleBulkUnassignAdminReview: (caseIds: number[]) => void
    handleAssignCase: (UserId: UserId, caseId: CaseId) => void
    handleUnassignCase: (UserId: UserId, caseId: CaseId) => void
    handleAssignAdminReview: (UserId: UserId, caseId: CaseId) => void
    handleUnassignAdminReview: (UserId: UserId, caseId: CaseId) => void
}

export type ModalVariant =
    | 'assignUser'
    | 'moveToDnr'
    | 'revertDnr'
    | 'uploadDocuments'
    | 'toggleFlag'

export type ModalMode = 'single' | 'multiselect'

export const useAssignUser = (
    cases: CaseData[],
    enableAdminUsersOnly: boolean,
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void,
    setAlertSnackbarMainOpen: (status: boolean) => void,
    toggleOpen: (variant: ModalVariant, mode?: ModalMode) => void,
    unselectAllRows: () => void
): UseAssignUser => {
    const [selectedUser, setSelectedUser] = useState<UserModel | null>(null)
    const [searchValue, setSearchValue] = useState<string>('')
    const [isLoadingAssign, setIsLoadingAssign] = useState<boolean>(false)
    const [isLoadingUnassign, setIsLoadingUnassign] = useState<boolean>(false)

    const { swrActions } = useSWRContext()

    const { id: merchantId } = useActiveMerchant()
    const { params, setParams } = useParams()

    const usersEndpointCongfig = {
        paramInUrl: false,
        url: 'users/users',
        id: {
            key: 'merchant_id',
            value: merchantId,
        },
    }

    const { data: users } = useSwrData(usersEndpointCongfig, {
        ...params,
        ...(enableAdminUsersOnly && { role: '8,9' }),
    })

    const { caseNetworkActions } = swrActions

    const {
        bulkToggleAssignUserToCases,
        bulkToggleAssignUserToAdminReviews,
        assignUserToCase,
        assignUserToAdminReview,
    } = caseNetworkActions

    const handleSearchChange = (searchVal: string) => {
        setSearchValue(searchVal)
        setParams({ search: searchVal })
    }

    const handleChangeValue = (id?: number) => {
        setSelectedUser(users.find((i: any) => i.id === id) ?? null)
    }

    const handleBulkAssignCase = (caseIds: number[]) => {
        setIsLoadingAssign(true)
        bulkToggleAssignUserToCases(
            caseIds,
            (selectedUser?.id as unknown) as number
        )
            .then(() => {
                setAlertSnackbarMainProps({
                    message: 'The selected cases were successfully assigned!',
                    title: 'Success.',
                    intent: 'success',
                })
                unselectAllRows()
            })
            .catch((error: AxiosError) => {
                setAlertSnackbarMainProps({
                    message:
                        error.response?.data.message ??
                        'There was an error setting the assigned user to bulk cases.',
                    title: 'Error.',
                    intent: 'error',
                })
            })
            .finally(() => {
                setIsLoadingAssign(false)
                setSelectedUser(null)
                toggleOpen('assignUser')
                setAlertSnackbarMainOpen(true)
            })
    }

    const handleBulkUnassignCase = (caseIds: number[]) => {
        setIsLoadingUnassign(true)
        bulkToggleAssignUserToCases(caseIds, false)
            .then(() => {
                setAlertSnackbarMainProps({
                    message: 'The selected cases were successfully unassigned!',
                    title: 'Success.',
                    intent: 'success',
                })
                unselectAllRows()
            })
            .catch((error: AxiosError) => {
                setAlertSnackbarMainProps({
                    message:
                        error.response?.data.message ??
                        'There was an error unassigning the user from bulk cases.',
                    title: 'Error.',
                    intent: 'error',
                })
            })
            .finally(() => {
                setIsLoadingUnassign(false)
                setSelectedUser(null)
                toggleOpen('assignUser')
                setAlertSnackbarMainOpen(true)
            })
    }

    const handleBulkAssignAdminReview = (caseIds: number[]) => {
        setIsLoadingAssign(true)
        bulkToggleAssignUserToAdminReviews(
            caseIds,
            (selectedUser?.id as unknown) as number
        )
            .then(() => {
                setAlertSnackbarMainProps({
                    message: 'The selected cases were successfully assigned!',
                    title: 'Success.',
                    intent: 'success',
                })
            })
            .catch(() => {
                setAlertSnackbarMainProps({
                    message:
                        'There was an error setting the assigned user to bulk cases.',
                    title: 'Error.',
                    intent: 'error',
                })
            })
            .finally(() => {
                setIsLoadingAssign(false)
                setSelectedUser(null)
                toggleOpen('assignUser')
                setAlertSnackbarMainOpen(true)
            })
    }

    const handleBulkUnassignAdminReview = (caseIds: number[]) => {
        setIsLoadingUnassign(true)
        bulkToggleAssignUserToAdminReviews(caseIds, false)
            .then(() => {
                setAlertSnackbarMainProps({
                    message: 'The selected cases were successfully unassigned!',
                    title: 'Success.',
                    intent: 'success',
                })
            })
            .catch(() => {
                setAlertSnackbarMainProps({
                    message:
                        'There was an error unassigning the user from bulk cases.',
                    title: 'Error.',
                    intent: 'error',
                })
            })
            .finally(() => {
                setIsLoadingUnassign(false)
                setSelectedUser(null)
                toggleOpen('assignUser')
                setAlertSnackbarMainOpen(true)
            })
    }

    const handleAssignCase = (UserId: UserId, caseId: CaseId) => {
        setIsLoadingAssign(true)
        assignUserToCase(UserId, caseId)
            .then(() => {
                setAlertSnackbarMainProps({
                    message: 'The case was successfully assigned!',
                    title: 'Success.',
                    intent: 'success',
                })
            })
            .catch((error: AxiosError) => {
                setAlertSnackbarMainProps({
                    message:
                        error.response?.data.message ??
                        'There was an error assigning the case.',
                    title: 'Error.',
                    intent: 'error',
                })
            })
            .finally(() => {
                setIsLoadingAssign(false)
                setSelectedUser(null)
                toggleOpen('assignUser')
                setAlertSnackbarMainOpen(true)
            })
    }

    const handleUnassignCase = (UserId: UserId, caseId: CaseId) => {
        setIsLoadingUnassign(true)
        assignUserToCase(UserId, caseId)
            .then(() => {
                setAlertSnackbarMainProps({
                    message: 'The case was successfully unassigned!',
                    title: 'Success.',
                    intent: 'success',
                })
            })
            .catch((error: AxiosError) => {
                setAlertSnackbarMainProps({
                    message:
                        error.response?.data.message ??
                        'There was an error unassigning the case.',
                    title: 'Error.',
                    intent: 'error',
                })
            })
            .finally(() => {
                setIsLoadingUnassign(false)
                setSelectedUser(null)
                toggleOpen('assignUser')
                setAlertSnackbarMainOpen(true)
            })
    }

    const handleAssignAdminReview = (UserId: UserId, caseId: CaseId) => {
        setIsLoadingAssign(true)
        assignUserToAdminReview(UserId, caseId)
            .then(() => {
                setAlertSnackbarMainProps({
                    message: 'The case was successfully assigned!',
                    title: 'Success.',
                    intent: 'success',
                })
            })
            .catch(() => {
                setAlertSnackbarMainProps({
                    message: 'There was an error assigning the case.',
                    title: 'Error.',
                    intent: 'error',
                })
            })
            .finally(() => {
                setIsLoadingAssign(false)
                setSelectedUser(null)
                toggleOpen('assignUser')
                setAlertSnackbarMainOpen(true)
            })
    }

    const handleUnassignAdminReview = (UserId: UserId, caseId: CaseId) => {
        setIsLoadingUnassign(true)
        assignUserToAdminReview(UserId, caseId)
            .then(() => {
                setAlertSnackbarMainProps({
                    message: 'The case was successfully unassigned!',
                    title: 'Success.',
                    intent: 'success',
                })
            })
            .catch(() => {
                setAlertSnackbarMainProps({
                    message: 'There was an error unassigning the case.',
                    title: 'Error.',
                    intent: 'error',
                })
            })
            .finally(() => {
                setIsLoadingUnassign(false)
                setSelectedUser(null)
                toggleOpen('assignUser')
                setAlertSnackbarMainOpen(true)
            })
    }

    return {
        searchValue,
        users: users,
        handleSearch: handleSearchChange,
        selectedUser,
        setSelectedUser,
        handleChangeValue,
        isLoadingAssign,
        isLoadingUnassign,
        handleBulkAssignCase,
        handleBulkUnassignCase,
        handleBulkAssignAdminReview,
        handleBulkUnassignAdminReview,
        handleAssignCase,
        handleUnassignCase,
        handleAssignAdminReview,
        handleUnassignAdminReview,
    }
}

import React, { useMemo } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {
    Button,
    FormGroup,
    TextField,
    FormLabel,
    FormHelperText,
} from '@mui/material'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { clientTheme } from 'theme-exports'

type TUpdateEmailInputs = {
    email: string
}

interface IUpdateEmailProps {
    initialEmail?: string
    isFederated?: boolean
    onSubmit: (email: string) => void
    className?: string
}

/**
 * Edit an email, validation for email string on submit.
 *
 * @param props - {@link IUpdateEmailProps}
 */
const UpdateEmail: React.FC<IUpdateEmailProps> = ({
    initialEmail = '',
    isFederated = false,
    onSubmit: handleOnSubmit,
    className,
}) => {
    const { style: formVariantStyle = 'outlined' } = useFeatureToggle(
        'FORM_FIELDS'
    )

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('A valid email is required.')
            .required('A valid email is required.'),
    })

    const defaultValues = useMemo<TUpdateEmailInputs>(() => {
        return {
            email: initialEmail ?? '',
        }
    }, [initialEmail])

    const {
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
        // TODO: Known React-Hook-Form typescript issue - Should be resolved with RHF's v8. Once resolved, need to switch typescript's "any" to "TUpdateEmailInputs".
    } = useForm<any>({
        defaultValues: defaultValues,
        values: defaultValues,
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
    })

    const onSubmit: SubmitHandler<TUpdateEmailInputs> = (values) => {
        return handleOnSubmit(values.email)
    }

    return (
        <div className={className}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup
                    className={'emp-updateEmail-container'}
                    sx={{
                        '& .MuiFormLabel-root': {
                            ...clientTheme.formFields.formLabel,
                        },
                    }}
                >
                    <FormLabel>Email</FormLabel>
                    <TextField
                        variant={formVariantStyle}
                        {...register('email')}
                        name="email"
                        type="email"
                        disabled={isFederated}
                        sx={[
                            formVariantStyle === 'standard' && {
                                '& .MuiInputBase-input': {
                                    ...clientTheme.formFields.formText.standard,
                                    borderBottom: '1px solid #84878E',
                                },
                            },
                        ]}
                    />
                    {Boolean(errors?.email?.message) && (
                        <FormHelperText
                            className={'emp-updateEmail-errorText'}
                            error
                        >
                            {errors?.email?.message}
                        </FormHelperText>
                    )}
                </FormGroup>
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting || isFederated || !isValid}
                    style={{ textTransform: 'uppercase' }}
                >
                    Submit
                </Button>
            </form>
        </div>
    )
}

export default UpdateEmail

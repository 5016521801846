/**
 * @description Directory for ID Attributes on File Upload New.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `fileUploadNew-div-root`,
    divTemplateLib: `fileUploadNew-div-templateLib`,
    divTemplateLibSelect: `fileUploadNew-div-templateLibSelect`,
    btnTemplateLibAdd: `fileUploadNew-btn-templateLibAdd`,
    divFiles: `fileUploadNew-div-files`,
    divFile: `fileUploadNew-div-file`,
    divFileInfo: `fileUploadNew-div-fileInfo`,
    divFileDetails: `fileUploadNew-div-fileDetails`,
    btnFileUnstage: `fileUploadNew-btn-fileUnstage`,
    fileDnDNew: {
        divRoot: `fileDnDNew-div-root`,
        divRootCustom: `fileDnDNew-div-rootCustom`,
    },
}

export default idDirectory

import React from 'react'
import {
    IconButton,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'

export interface alertDialogContentProps {
    title?: string
    message?: string
    intent?: string | 'info'
}

interface alertDialogProps {
    content: alertDialogContentProps
    open: boolean
    showClose?: boolean
    autoCloseDelay?: number
    onClose: (e: any) => void
    enableAutoClose?: boolean
    disableBackdropClick?: boolean
    additionalContent?: React.ReactNode
}

const AlertDialog = ({
    content,
    open,
    showClose = true,
    autoCloseDelay = 5000,
    onClose,
    enableAutoClose = true,
    disableBackdropClick = false,
    additionalContent = null,
}: alertDialogProps): React.ReactElement => {
    const { title, message, intent } = content

    // Blueish "Info" type.
    let titleBackgroundColor = '#42a5f5'
    switch (intent) {
        case 'error':
            titleBackgroundColor = '#ef9a9a'
            break
        case 'success':
            titleBackgroundColor = '#3bd3ae'
            break
        default:
            break
    }

    useEffect(() => {
        let closeTimeout: NodeJS.Timeout | undefined = undefined

        if (open && enableAutoClose) {
            closeTimeout = setTimeout(() => {
                onClose(true)
            }, autoCloseDelay) // Close in 5 seconds if not otherwise overidden.
        }

        return () => {
            if (closeTimeout) clearTimeout(closeTimeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, onClose, autoCloseDelay])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                root: 'emp-alertDialog-root',
            }}
        >
            <DialogTitle
                id="alert-dialog-title"
                className={'emp-alertDialog-title'}
                style={{ backgroundColor: titleBackgroundColor }}
            >
                <div>{title}</div>
                {showClose && (
                    <IconButton
                        onClick={onClose}
                        size="small"
                        style={{ color: '#fff' }}
                    >
                        <Icon icon={faTimes} />
                    </IconButton>
                )}
            </DialogTitle>
            <DialogContent className={'emp-alertDialog-content'}>
                {message && (
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                )}

                {additionalContent}
            </DialogContent>
        </Dialog>
    )
}

export default AlertDialog

/**
 * @description Directory for ID Attributes on AuthedUser.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    authedUserContext: {
        divAuthContainer: `authedUserContext-div-authContainer`,
        divLogo: `authedUserContext-div-logo`,
        snackbarAlert: `authedUserContext-snackbar-alert`,
    },
    signIn: {
        divContainer: `signIn-div-container`,
        divLoginContainer: `signIn-div-loginContainer`,
        inputUsername: `signIn-input-username`,
        inputPassword: `signIn-input-password`,
        textForgotPassword: `signIn-text-forgotPassword`,
        btnSignIn: `signIn-btn-signIn`,
    },
    changePassword: {
        divContainer: `changePassword-div-container`,
        divPolicyMsg: `changePassword-div-policyMsg`,
        divResetContainer: `changePassword-div-resetContainer`,
        inputPassword: `changePassword-input-password`,
        inputNewPassword: `changePassword-input-newPassword`,
        inputConfirmPassword: `changePassword-input-confirmPassword`,
        inputUsername: `changePassword-input-username`,
        btnCreate: `changePassword-btn-create`,
    },
    resetPassword: {
        divContainer: `resetPassword-div-container`,
        divResetContainer: `resetPassword-div-resetContainer`,
        inputUsername: `resetPassword-input-username`,
        textLogin: `resetPassword-text-login`,
        btnReset: `resetPassword-btn-reset`,
    },
}

export default idDirectory

import React, { useState } from 'react'
import { Divider } from '@mui/material'
import View from 'views/View'
import EntFeature from 'models/EntFeature'
import idDirectory from './idAttributes'
import { AccentArea, MerchantSwitcher, AlertSnackbar } from 'components'
import { withRequiredRole } from 'components/useRequireRole'
import { AlertsAutoRefundDialog } from './dialogs/AlertsAutoRefundDialog'
import AlertsGrid from './grids/AlertsGrid'
import { useAlerts } from './Alerts.vm'
import FilterAlerts from './actions/FilterAlerts'

const PreChargebacks = () => {

    const [alertsAutoRefundOpen, setAlertsAutoRefundOpen] = useState(false)

    const {
        alertsGridRef,
        gridData,
        dataSource,
        isLoading,
        pageSize,
        page,
        paginationTotal,
        setLocalPage,
        handlePageLimitChange,
        handleSearchField,
        currentPayload,
        refreshAlertsGrid,
        filterValues,
        setFilterValues,
        defaultDateRange,
        // serviceLevelData,
        isSelfService,
        downloadXlsx,
        isLoadingDownloadXlsxAll,
        // downloadCSV,
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
        closeAlertSnackbar,
        setCurrentAlertId,
        currentAlertId,
        toggleModal,
        alertHistoryOpen,
        markOutcomeOpen,
        completeOpen,
        selectMIDOpen,
        alertsOutcomesData,
        midList,
        setSelectedMid,
        selectedAlertInfo,
    } = useAlerts()

    return (
        <View
            title="Pre-Chargebacks"
            breadcrumb="Pre-Chargebacks"
            HeaderInterior={<MerchantSwitcher testId="pre-chargebacks" />}
            testId="pre-chargebacks"
        >
            <div id={idDirectory.divRoot}>
                <AccentArea
                    containsFullWidthGrid={true}
                    testId="pre-chargebacks"
                    hasHeaderDivider={false}
                    className={'emp-accentArea'}
                >
                    <div className={'emp-headerContent '}>
                        <div>
                            <FilterAlerts
                                defaultDateRange={defaultDateRange}
                                setFilterValues={setFilterValues}
                                filterValues={filterValues}
                                setAlertSnackbarMainProps={
                                    setAlertSnackbarMainProps
                                }
                                setAlertSnackbarMainOpen={
                                    setAlertSnackbarMainOpen
                                }
                            />
                        </div>
                        {/* {isSelfService && (
                                <div className={'emp-header}>
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            setAlertsAutoRefundOpen(true)
                                        }
                                        size="small"
                                        color="secondary"
                                        style={{ textTransform: 'capitalize' }}
                                        disabled={!AUTO_REFUND_RULES.enabled}
                                    >
                                        Auto Refund Rules
                                    </Button>
                                </div>
                            )} */}
                    </div>
                    <Divider className={'emp-divider'} />
                    <div>
                        <AlertsGrid
                            testId="pre-chargebacks"
                            downloadXlsx={downloadXlsx}
                            isLoadingDownloadXlsxAll={isLoadingDownloadXlsxAll}
                            setCurrentAlertId={setCurrentAlertId}
                            currentAlertId={currentAlertId}
                            toggleModal={toggleModal}
                            alertHistoryOpen={alertHistoryOpen}
                            markOutcomeOpen={markOutcomeOpen}
                            selectMIDOpen={selectMIDOpen}
                            selectedAlertInfo={selectedAlertInfo}
                            completeOpen={completeOpen}
                            midList={midList}
                            setSelectedMid={setSelectedMid}
                            isSelfService={isSelfService}
                            alertsOutcomesData={alertsOutcomesData}
                            gridRef={alertsGridRef}
                            data={gridData}
                            dataSource={dataSource}
                            isLoading={isLoading}
                            pageSize={pageSize}
                            page={page}
                            paginationTotal={paginationTotal}
                            setLocalPage={setLocalPage}
                            handlePageLimitChange={handlePageLimitChange}
                            handleSearchField={handleSearchField}
                            currentPayload={currentPayload}
                            refreshAlertsGrid={refreshAlertsGrid}
                            setAlertSnackbarMainProps={
                                setAlertSnackbarMainProps
                            }
                            setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                        />
                    </div>
                </AccentArea>
            </div>
            <AlertsAutoRefundDialog
                open={alertsAutoRefundOpen}
                onClose={() => setAlertsAutoRefundOpen(false)}
            />
            <AlertSnackbar
                content={alertSnackbarMainProps}
                onClose={closeAlertSnackbar}
                open={alertSnackbarMainOpen}
                showCloseIcon
                autoHideDuration={
                    alertSnackbarMainProps.title === 'Export started.'
                        ? 30_000
                        : 3_000
                }
            />
        </View>
    )
}

// when whoami is updated with alerts role, this can be uncommented
export default withRequiredRole(EntFeature.PRE_CHARGEBACKS_AGGRID, PreChargebacks)

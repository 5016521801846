import { VerdictWin, VerdictLoss, VerdictPending } from './VerdictIcons'

export interface Verdict {
    name: string
    icon?: any
    aliases?: string[]
}

/**
 * @TODO Remove url's of icons and replace with ds-core icons
 */
export const verdictMap: { [key: string]: Verdict } = {
    default: {
        name: 'Error',
        icon: VerdictLoss,
    },
    willNotRepresent: {
        name: 'Will Not Represent',
        icon: VerdictLoss,
    },
    pending: {
        name: 'Verdict Pending',
        icon: VerdictPending,
    },
    win: {
        name: 'Win',
        icon: VerdictWin,
    },
    loss: {
        name: 'Loss',
        icon: VerdictLoss,
    },
}

export const statusMap: { [key: string]: Verdict } = {
    default: {
        name: 'Error',
        icon: VerdictLoss,
    },
    completed: {
        name: 'Completed',
        icon: VerdictWin,
    },
    alreadyRefunded: {
        name: 'Verdict Pending',
        icon: VerdictPending,
    },
    refunded: {
        name: 'Verdict Pending',
        icon: VerdictPending,
    },
    refundError: {
        name: 'Verdict Pending',
        icon: VerdictPending,
    },
    noMatchFound: {
        name: 'Verdict Pending',
        icon: VerdictPending,
    },
    declinedTransaction: {
        name: 'Verdict Pending',
        icon: VerdictPending,
    },
    alreadyAChargeback: {
        name: 'Verdict Pending',
        icon: VerdictPending,
    },
    noRefund: {
        name: 'Verdict Pending',
        icon: VerdictPending,
    },
    resolvedWithCustomer: {
        name: 'Verdict Pending',
        icon: VerdictPending,
    },
    open: {
        name: 'Verdict Pending',
        icon: VerdictPending,
    },
    notCompleted: {
        name: 'Verdict Pending',
        icon: VerdictPending,
    },
}

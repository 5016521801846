import React, {
    ReactElement,
    useRef,
    useMemo,
    useState,
    useCallback,
} from 'react'
import { AgGridReact } from 'ag-grid-react'
import { CellDoubleClickedEvent } from 'ag-grid-community'
import { AGDataGrid, StandardModal } from 'components'
import { useAGDataGridActions } from 'hooks/useAGDataGridActions/useAGDataGridActions'
import { ExtendedColumn } from 'components/DataGrid/ExtendedColumn'

interface ICaseHistoryModalProps {
    openCaseHistoryModal: boolean
    onClose: () => void
    caseInfo: any
    testId?: string
}

const CaseHistoryModal = ({
    openCaseHistoryModal,
    onClose,
    caseInfo,
    testId = 'caseHistoryModal',
}: ICaseHistoryModalProps): ReactElement => {
    const { id, case_no } = caseInfo
    const gridRef = useRef<AgGridReact>(null)
    const [shouldTruncateUser, setShouldTruncateUser] = useState<boolean>(true)
    const [shouldTruncateAction, setShouldTruncateAction] = useState<boolean>(
        true
    )
    const [
        refreshDataGridColumnsToggle,
        setRefreshDataGridColumnsToggle,
    ] = useState<boolean>(false)

    const filterValuesMemo = useMemo(() => {
        return { limit: undefined }
    }, [])

    const {
        dataSource,
        isLoading,
        pageSize = 5,
        page,
        paginationTotal,
        setLocalPage,
        handlePageLimitChange,
    } = useAGDataGridActions(
        `/cm/cases/${id}/history`,
        gridRef,
        +id,
        filterValuesMemo,
        {}
    )

    const columns: ExtendedColumn[] = [
        {
            Header: 'Timestamp',
            accessor: 'timestamp',
            type: 'string',
            minWidth: 160,
            tooltipField: 'timestamp',
        },
        {
            Header: 'Username',
            accessor: 'user',
            type: 'string',
            autoHeight: true,
            minWidth: 225,
            ...(!shouldTruncateUser && {
                wrapText: true,
                minWidth: 275,
            }),
            tooltipField: 'user',
        },
        {
            Header: 'Action',
            accessor: 'action',
            type: 'string',
            autoHeight: true,
            minWidth: 225,
            ...(!shouldTruncateAction && {
                wrapText: true,
                minWidth: 300,
            }),
            tooltipField: 'action',
        },
    ]

    const defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
        menuTabs: [],
        sortable: false,
    }

    const handleDoubleClickedCell = useCallback((e: CellDoubleClickedEvent) => {
        if (e.colDef.field === 'user') {
            setShouldTruncateUser((prev) => !prev)
            setRefreshDataGridColumnsToggle((prev) => !prev)
        }
        if (e.colDef.field === 'action') {
            setShouldTruncateAction((prev) => !prev)
            setRefreshDataGridColumnsToggle((prev) => !prev)
        }
    }, [])

    const handleOnClose = () => {
        onClose()
        setShouldTruncateUser(true)
        setShouldTruncateAction(true)
    }

    return (
        <StandardModal
            openModal={openCaseHistoryModal}
            onModalClose={handleOnClose}
            modalHeaderTitle={`Case History (Case No: ${case_no})`}
            enablePrimaryActionBtn={false}
            secondaryBtnText={'Close History'}
            handleSecondaryActionBtn={handleOnClose}
            testId={testId}
            className={'emp-caseHistoryModal-modalContainer'}
        >
            <div className={'emp-caseHistoryModal-gridContainer'}>
                <AGDataGrid
                    gridRef={gridRef}
                    gridName={'caseHistoryModal'}
                    fileName={'case-history'}
                    columns={columns}
                    defaultColDef={defaultColDef}
                    dataSource={dataSource}
                    loading={isLoading}
                    testId={'caseHistoryModal'}
                    rowSelectionType={'single'}
                    disableRowClickSelection
                    enableSideBarPanel={false}
                    enableDataGridTopBorder={false}
                    rowsPerPageOptions={[5, 10]}
                    totalEntries={paginationTotal}
                    onPageLimitChange={handlePageLimitChange}
                    pageSize={pageSize}
                    page={page}
                    onPageChange={setLocalPage}
                    paginate={false}
                    handleDoubleClickedCell={handleDoubleClickedCell}
                    className={'emp-caseHistoryModal-dataGrid'}
                    refreshDataGridColumnsToggle={refreshDataGridColumnsToggle}
                    gridThemeType={'material'}
                />
            </div>
        </StandardModal>
    )
}

export default CaseHistoryModal

/**
 * @description Directory for ID Attributes on SidebarNav.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    list: `sidebarNav-list-list`,
    listItem: `sidebarNav-listItem-list`,
    listItemIcon: `sidebarNav-listItemIcon-list`,
    listItemText: `sidebarNav-listItemText-list`,
    iconCaretDown: `sidebarNav-icon-caretDown`,
    iconCaretRight: `sidebarNav-icon-caretRight`,
    subList: `sidebarNav-list-subList`,
    subListItem: `sidebarNav-listItem-subList`,
    subListItemText: `sidebarNav-listItemText-subList`,
}

export default idDirectory

import featuresExports from '../../features-exports'
import React from 'react'
import { FeatureToggleContext } from '../../context/FeatureToggles/FeatureToggleContext'

type FeatureRules = typeof featuresExports

type FeatureToggleInstance<K extends string = string> = {
    [key: string]: any
} & Record<K, unknown>

type UseFeatureToggle = (
    featureName: keyof FeatureRules,
    overrides?: Partial<FeatureRules>
) => FeatureToggleInstance

const useFeatureToggle: UseFeatureToggle = (featureName, overrides = {}) => {
    // pull the feature toggles from its context and overwrite with the inline overrides provided.
    const features = { ...React.useContext(FeatureToggleContext), ...overrides }
    // return the requested feature value or default to no access
    return features[featureName] ?? { isOn: false }
}

export default useFeatureToggle

import React, { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import {
    Divider,
    TextField,
    Table,
    TableBody,
    TablePagination,
    TableRow,
    Skeleton,
} from '@mui/material'
import { PopoverSelect, SelectOption } from '../PopoverSelect'
import { LoadingIndicator } from 'components'
import idDirectory from './idAttributes'

type Option = {
    id: number
} & Record<string, unknown>

type OnValueChange = (value: Option | null) => void

type OnSearchChange = (value: string) => void

interface SearchableSelectProps {
    value?: string
    onValueChange?: OnValueChange
    searchValue?: string
    onSearchChange?: OnSearchChange
    options?: Option[]
    accessor?: string
    placeholder?: string
    required?: boolean
    disabled?: boolean
    hideSearch?: boolean
    loading?: boolean
    className?: string
    testId?: string
    displayType?: string
    isPaginate?: boolean
    debounceDelay?: number
    loadingValues?: boolean
    loadingInitialValue?: boolean
    textFieldLabel?: string
    selectFontSize?: string
    enableSelectedValueTooltip?: boolean
    enableHighlightSelectBorder?: boolean
}

/**
 * Use SearchableSelect to create a select box which when expanded provides a
 * search box for text based filtering of the options list. Filtering of options
 * is controller outside of this component.
 * @param hideSearch - when the search function is disabled, the search input is hidden and the component behaves as a dropdown menu. You can omit onSearchChange and searchValue props when this is disabled as they will not be used. onValueChange and value are both used exlusively when the search field is disabled. Some input endpoints are not searchable but exist in the same space as searchable inputs and this allows us to keep the same dropdown input component across the board.
 */
const SearchableSelect: React.FC<SearchableSelectProps> = ({
    value = '',
    onValueChange = (value: Option | null) => {},
    searchValue = '',
    onSearchChange = (value: string) => {},
    options = [],
    accessor = 'value',
    placeholder = '',
    children,
    required = false,
    disabled = false,
    hideSearch = false,
    loading = false,
    className = '',
    testId = '',
    displayType = 'outlined',
    isPaginate = true,
    debounceDelay = 500,
    loadingValues = false,
    loadingInitialValue = false,
    textFieldLabel = '',
    selectFontSize = '14px',
    enableSelectedValueTooltip = false,
    enableHighlightSelectBorder = false,
}) => {
    const minPageSize = 5
    const [page, setPage] = useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = useState<number>(minPageSize)
    const [globalSearchValue, setGlobalSearchValue] = useState<string>('')
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const sendSearchValue = useCallback(
        debounce((value) => onSearchChange(value), debounceDelay),
        []
    )

    const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setGlobalSearchValue(value)
        sendSearchValue(value)
        setPage(0)
    }

    const optionsToDisplay = isPaginate
        ? options?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : options

    useEffect(() => {
        if (!searchValue.length) setGlobalSearchValue('')
    }, [searchValue])

    if (loading) {
        return (
            <Skeleton
                variant="rectangular"
                className={'emp-searchableSelect-loading'}
            />
        )
    }

    return (
        <PopoverSelect
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            enablePopoverContainerOnClick={true}
            value={value}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            className={`${className}`}
            selectFontSize={selectFontSize}
            testId={testId}
            displayType={displayType}
            enableSelectedValueTooltip={enableSelectedValueTooltip}
            enableHighlightSelectBorder={enableHighlightSelectBorder}
            dropdownWidth={isPaginate && !hideSearch ? '300px' : null}
            pagination={
                isPaginate &&
                options?.length &&
                options.length > minPageSize ? (
                    // The onClick below stops the popover from closing on click.
                    <div
                        className={'emp-searchableSelect-paginationContainer'}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Divider />
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[
                                            minPageSize,
                                            10,
                                            25,
                                        ]}
                                        count={options ? options.length : 0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={(_, val: number) =>
                                            setPage(val)
                                        }
                                        onRowsPerPageChange={(ev) => {
                                            setRowsPerPage(+ev.target.value)
                                            setPage(0)
                                        }}
                                        id={`${idDirectory.divPagination}-${testId}`}
                                        backIconButtonProps={{
                                            id: `${idDirectory.btnPrevious}-${testId}`,
                                        }}
                                        nextIconButtonProps={{
                                            id: `${idDirectory.btnNext}-${testId}`,
                                        }}
                                        SelectProps={{
                                            id: `${idDirectory.selectRowsPerPage}-${testId}`,
                                        }}
                                        labelDisplayedRows={({
                                            from,
                                            to,
                                            count,
                                        }) => (
                                            <span
                                                id={`${idDirectory.textPaginationInfo}-${testId}`}
                                            >{`${from}-${to} of ${count}`}</span>
                                        )}
                                    />
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                ) : null
            }
            searchTextField={
                !hideSearch ? (
                    <TextField
                        value={globalSearchValue}
                        onChange={handleSearchValue}
                        onClick={(e) => e.stopPropagation()}
                        size="small"
                        //@ts-ignore
                        variant={displayType}
                        fullWidth
                        autoFocus
                        id={`${idDirectory.inputSearch}-${testId}`}
                    />
                ) : null
            }
            textFieldLabel={textFieldLabel}
            loadingInitialValue={loadingInitialValue}
        >
            {loadingValues ? (
                <div
                    className={'emp-searchableSelect-loadingContainer'}
                    id={`${idDirectory.divLoading}-${testId}`}
                >
                    <LoadingIndicator />
                </div>
            ) : (
                optionsToDisplay.map((opt, i) => (
                    <SelectOption
                        key={`${i}--selectable_options`}
                        onClick={() => onValueChange(opt)}
                        testId={
                            testId
                                ? `${idDirectory.selectOption}-${testId}-${i}`
                                : ''
                        }
                    >
                        {opt[accessor] as React.ReactNode}
                    </SelectOption>
                ))
            )}

            <div style={{ marginBottom: 10 }}>{children}</div>
        </PopoverSelect>
    )
}

export default SearchableSelect

/**
 * @description Directory for ID Attributes on Help.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    gridContainer: `help-grid-container`,
    gridItem: `help-grid-item`,
}

export default idDirectory

import React, { useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import PieChart from 'components/PieChart/PieChart'
import { IReportFilter } from 'views/Reports'
import { clientTheme } from 'theme-exports'

/**
 * @name ByLocationType
 * @desc Render Card Type chart
 * @filterValues IReportsFilter
 */
const ByLocationType = ({
    filterValues,
}: {
    filterValues: IReportFilter
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ name: string; value: number }[]>([])

    React.useEffect(() => {
        setLoading(true)
        ReportsApi.getByLocationType(filterValues).then((data) => {
            setLoading(false)

            const top5LocationTypes = data.filter(
                (locationtype: any) => locationtype.name !== 'Other Location'
            )

            setData(top5LocationTypes)
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    const byLocationTypeColorPalette =
        clientTheme.charts.pieCharts.byLocationTypePieChart

    return (
        <PieChart
            colorPalette={byLocationTypeColorPalette}
            chartTitle="Domestic vs Offshore"
            data={data}
            loading={loading}
            testId="byLocationType"
        />
    )
}

export default ByLocationType

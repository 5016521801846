import React, { useCallback, useState } from 'react'
import debounce from 'lodash.debounce'
import { TextField, InputAdornment } from '@mui/material'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import idDirectory from './idAttributes'
import { clientTheme } from 'theme-exports'

interface SearchbarProps {
    className?: string
    id?: string
    title?: string
    debounceDelay?: number
    placeholder?: string
    disabled?: boolean
    returnValue: (value: string) => void
    testId?: string
}

/**
 * Use Searchbar to server side search - returns a debounced search value
 */
const Searchbar = ({
    className,
    id,
    debounceDelay = 500,
    placeholder = 'Search...',
    disabled,
    returnValue,
    testId,
    ...rest
}: SearchbarProps) => {
    const { style: formVariantStyle = 'outlined' } = useFeatureToggle(
        'SEARCH_BAR'
    )
    const [globalSearchValue, setGlobalSearchValue] = useState<string>('')

    // debounced value return to parent
    const sendReturnValue = useCallback(
        debounce((nextValue) => returnValue(nextValue), debounceDelay),
        []
    )

    const searchHandler = (e: any): any => {
        const { target } = e
        const { value: nextValue } = target
        setGlobalSearchValue(nextValue)
        sendReturnValue(nextValue)
    }

    return formVariantStyle === 'standard' ? (
        <TextField
            id={`${idDirectory.input}-${testId}`}
            disabled={disabled}
            placeholder={placeholder}
            variant='standard'
            value={globalSearchValue}
            onChange={searchHandler}
            className={'emp-searchbar-inputStandard'}
            {...rest}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <FontAwesomeIcon
                            icon={faSearch}
                            className={'emp-searchbar-inputSearchIcon'}
                        />
                    </InputAdornment>
                ),
            }}
            sx={{
                fontFamily: clientTheme.typography.fontFamily.join(','),
                '& .MuiInputBase-input': {
                    padding: '6px 5px 7px 5px',
                    ...clientTheme.formFields.formText.standard,
                },
            }}
        />
    ) : (
        <input
            id={`${idDirectory.input}-${testId}`}
            disabled={disabled}
            placeholder={placeholder}
            value={globalSearchValue}
            onChange={searchHandler}
            className={`${className}`}
            style={{
                fontFamily: clientTheme.typography.fontFamily.join(','),
                color: clientTheme.typography.fontColor.primaryText
                    ? clientTheme.typography.fontColor.primaryText
                    : '#545454',
            }}
            {...rest}
        />
    )
}

export default Searchbar

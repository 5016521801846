import React from 'react'
import { useHistory } from 'react-router'
import { Tooltip, IconButton } from '@mui/material'
import DocumentIcon from 'assets/icons/doc_review_icon.png'
import CB from 'lib'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'

export const ReviewCell = ({
    caseId,
    setAlertProps,
    setAlertOpen,
    rowIndex,
}: {
    caseId: number
    setAlertProps: (props: any) => void
    setAlertOpen: (val: boolean) => void
    rowIndex: number
}): React.ReactElement => {
    const history = useHistory()
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const handleClick = () => {
        CB.cases.callCasePatch(caseId.toString(), 1, 'qc-review')
        .then(() => {
            history.push({
                pathname: `/case-qc-review/view-case/${caseId}`,
                state: {
                    caseId,
                    reviewType: 'qc-review'
                },
            })
        })
        .catch((err: any) => {
            let message = err.response.data.message ? err.response.data.message : 'An unknown error has occurred.'

            setAlertProps({
                title: 'Error',
                message,
                intent: 'error'
            })
            setAlertOpen(true)
        })
    }

    return (
        <div id={'qcReviewReviewCell'}>
            <Tooltip title={'Review Document'} arrow placement="top">
                <div className={`${isReadOnly ? 'emp-disabledIcon' : ''}`}>
                    <IconButton
                        className={'emp-iconBtn'}
                        onClick={handleClick}
                        disabled={isReadOnly}
                        size="large"
                    >
                        <img
                            className={'emp-actionIcon'}
                            src={DocumentIcon}
                            alt="review document"
                            id={`qcReview-document-icon-${rowIndex}`}
                        />
                    </IconButton>
                </div>
            </Tooltip>
        </div>
    )
}

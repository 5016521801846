import React from 'react'

interface IFooterProps {
    className?: string
    title?: string
    children: React.ReactNode
    [key: string]: any
}

/**
 * Simple footer styling shell
 * Put actual footer content into children
 */
const Footer = ({ className, children, ...rest }: IFooterProps) => {
    return (
        <footer className={`app-footer ${className}`} {...rest}>
            {children}
        </footer>
    )
}

export default Footer

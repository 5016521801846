import React, { useState, useMemo } from 'react'
import { Typography } from '@mui/material'
import { AccentArea, AlertSnackbar } from 'components'
import UpdateMid from 'components/UpdateMid'
import View from '../View'
import useAddEditMid from './AddEditMid.vm'
import { Deflector } from '../MerchantHierarchy/MerchantHierarchy.vm'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface IAddEditMidProps {
    merchantId: number
    isEdit: boolean
    midDetails: any
    toggleMidDrawer: (isEdit: boolean, type?: 'mids' | 'merchant') => void
    forceReload: () => void
    merchantName: string
    availableDeflectors: Deflector[]
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    setVerifiRDRIsOpen: (val: boolean) => void
}

export const AddEditMid = ({
    merchantId: selectedNodeId,
    isEdit = false,
    midDetails,
    toggleMidDrawer,
    forceReload,
    merchantName,
    availableDeflectors,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    setVerifiRDRIsOpen,
}: IAddEditMidProps) => {
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const {
        inputUtils,
        selectedMidMerchantId,
        defaultDeflectorValues,
    } = useAddEditMid({
        isEdit,
        selectedNodeId,
        forceReload,
        midDetails,
        setAlertSnackbarOpen,
        setAlertSnackbarProps,
        // For AlertSnackbar on main view.
        setAlertSnackbarMainProps,
        setAlertSnackbarMainOpen,
    })
    const {
        register,
        watch,
        errors,
        isDirty,
        isValid,
        isSubmitting,
        handleOnChangeValue,
        submitForm,
        reset,
        processors,
    } = useMemo(() => inputUtils, [inputUtils])

    const handleOnClose = () => {
        toggleMidDrawer(isEdit)
        reset()
    }

    return (
        <View
            title="Add/Edit MID"
            breadcrumb={''}
            className={'emp-addEditMid-root'}
        >
            <AccentArea
                title={
                    <Typography className={'emp-addEditMid-heading'}>
                        {isEdit ? 'Edit MID' : `Add MID (${merchantName})`}
                    </Typography>
                }
            >
                <UpdateMid
                    isEdit={isEdit}
                    merchantId={selectedNodeId}
                    selectedMidMerchantId={selectedMidMerchantId}
                    handleOnClose={handleOnClose}
                    processors={processors}
                    values={watch()}
                    errors={errors}
                    register={register}
                    isDirty={isDirty}
                    isValid={isValid}
                    handleOnChangeValue={handleOnChangeValue}
                    isSubmitting={isSubmitting}
                    handleSubmit={submitForm}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                    availableDeflectors={availableDeflectors}
                    defaultDeflectorValues={defaultDeflectorValues}
                    setVerifiRDRIsOpen={setVerifiRDRIsOpen}
                />
            </AccentArea>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </View>
    )
}

export default AddEditMid

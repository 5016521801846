import { LoadingIndicator, SwitchList } from 'components'
import React from 'react'

interface NotificationsAreaProps {
    settings?: any
    loading: boolean
    expectedLength: number
    handleChange?: (key: string) => void
}

const NotificationsArea: React.FC<NotificationsAreaProps> = ({
    settings = [],
    loading,
    expectedLength,
    handleChange = () => {
        /* no-op */
    },
}: NotificationsAreaProps) => {
    return (
        <div id={'settingsNotificationsArea'}>
            {settings.length === 0 ? (
                <div className={'emp-loadingContainer'}>
                    <LoadingIndicator />
                </div>
            ) : (
                <form>
                    <SwitchList
                        onChange={handleChange}
                        // @ts-ignore
                        options={settings}
                        loadingOptions={loading}
                        expectedLength={expectedLength}
                        compact={false}
                    />
                </form>
            )}
        </div>
    )
}

export default NotificationsArea

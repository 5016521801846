import React from 'react'
import { Grid } from '@mui/material'
import { Skeleton } from '@mui/material'
import View from './View'
import MainLayout from 'layouts/MainLayout/MainLayout'

interface ILoadingProps {
    title?: string
    breadcrumb?: any
}

/**
 * Use to display robust lazy loader fallback
 */
const Loading = ({ title = '...', breadcrumb = '...' }: ILoadingProps) => {
    return (
        <MainLayout>
            <View
                title={title}
                loading
                breadcrumb={breadcrumb}
                className={'emp-loading-viewRoot'}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Skeleton
                            variant="rectangular"
                            height="70vh"
                            width="100%"
                        />
                    </Grid>
                </Grid>
            </View>
        </MainLayout>
    )
}

export default Loading

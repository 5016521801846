import {
    faTachometerAlt,
    faUsers,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { dateUtilities } from 'utils/dateUtilities'
import { MetricObject } from '../metrics-config'

const {
    getMonth,
    // getNextDaysDate
} = dateUtilities

export const bluesnap_metrics: MetricObject = {
    chargebacks: {
        title: 'Chargebacks',
        timeframe: '(This Month)',
        link: {
            to: '/chargebacks',
            text: 'MTD Report',
            filter_presets: [
                {
                    filter_name: 'date_created',
                    filter_value: getMonth(),
                },
            ],
        },
        icon: faExclamationTriangle,
        variant: 'alt',
        backgroundColor: '#FDDEC5',
        borderTopColor: '#F88D2B',
    },
    caseFraudPercent: {
        title: 'Percentage of Fraud Chargebacks',
        timeframe: '(This Month)',
        link: null,
        icon: faTachometerAlt,
        variant: 'alt',
        backgroundColor: '#EFBCBC',
        borderTopColor: '#FF0004',
    },
    usersCount: {
        title: 'Users',
        timeframe: null,
        link: {
            to: '/users',
            text: 'View More',
            filter_presets: [],
        },
        icon: faUsers,
        variant: 'alt',
        backgroundColor: '#D7CFE8',
        borderTopColor: '#714EB1',
    },
}

import axios, { AxiosRequestConfig } from 'axios'
import { Auth } from 'aws-amplify'
import config from '../config'
import CB from 'lib'

const axiosRequestInterceptor = async (axiosConfig: AxiosRequestConfig) => {
    const user = await Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken
    const newConfig = { ...axiosConfig }
    if (token) {
        newConfig.headers.Authorization = await CB.auth.idToken()
        newConfig.headers['Content-Type'] = 'application/json'
    }
    return newConfig
}

export default function setupAxios() {
    axios.defaults.baseURL = config['base-api']
    axios.defaults.headers.post.Accept = 'application/json' // default header for all POST request
    axios.interceptors.response?.use((response) => {
        return response
    }, function (error) {
        return Promise.reject(error)
    })
    axios.interceptors.request.use(axiosRequestInterceptor, (error) => {
        // Do something with response error
        return Promise.reject(error)
    })
}

export interface IServerResponse<T> {
    success: boolean;
    data: T;
}

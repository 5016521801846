import React, { createContext, useContext } from 'react'
import { SWRActions } from 'hooks/cb-api/types'

interface ContextType {
    swrActions: SWRActions
}

export const SWRContext = createContext<ContextType>({
    swrActions: {
        total: 0,
        rowsPerPage: 0,
        setRowsPerPage: () => {},
        error: false,
        search: '',
        setSearch: () => {},
        isLoading: false,
        page: 0,
        setPage: () => {},
        setSortBy: (sort, accessor) => {},
        setSortDesc: () => {},
        setSortInfo: () => {},
        sortInfo: {
            sortBy: '',
            sortDesc: 'asc',
        },
        clearError: () => {},
        setDateRange: () => {},
        globalSearch: '',
        setDescriptorFilter: () => {},
        setGlobalSearch: () => {},
        exportAll: () => {},
        setQueryParams: () => {},
        invalidate: () => {},
        mutate: () => {},
        selectRow: (rowIndex: string) => {},
        selectAllRows: (rowIndexList: string[]) => {},
        unselectAllRows: () => {},
        selectedRows: [],
        selectSingleRowClearRest: (rowIndex: string) => {},
        resetEndpoint: (limit: number, start: number) => {},
    },
})

const useSWRContext = () => {
    const swrContext = useContext(SWRContext)
    if (swrContext === undefined) {
        throw new Error('Please provide an SWRInstance to useSWRContext.')
    }
    return swrContext
}

const SWRContextProvider = ({
    children,
    SWRActions,
}: {
    children: React.ReactElement
    SWRActions: any
}) => {
    const value = React.useMemo(() => SWRActions, [SWRActions])
    return (
        <SWRContext.Provider
            value={{
                swrActions: value,
            }}
        >
            {children}
        </SWRContext.Provider>
    )
}

export { useSWRContext, SWRContextProvider }

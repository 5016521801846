import axios from 'axios'
import { SearchableSelect } from 'components'
import React, { FC, useEffect } from 'react'
import useIsMounted from 'hooks/utils/useIsMounted'

type GenericsType = 'currencies' | 'processors'

interface GenericsSelectProps {
    type: GenericsType
    value?: string
    onChange?: (value: any) => void
    testId: string
}

const accesssorMap = {
    currencies: 'currency',
    processors: 'name',
}

// CSS useStyles here should be small enough to be visible in a single screen

/**
 * Use GenericsSelect to ___________________
 */
const GenericsSelect: FC<GenericsSelectProps> = ({
    type,
    onChange,
    value: userDefinedValue,
    testId,
}) => {
    const [value, setValue] = React.useState<any>(null)
    const [options, setOptions] = React.useState([])
    const [search, setSearch] = React.useState('')
    const [error, setError] = React.useState<any>()
    const { isMounted } = useIsMounted()

    // Query the API for the list of currencies with axios
    useEffect(() => {
        const query = async () => {
            try {
                const { data } = await axios.get(`/cm/gen/${type}`, {
                    params: {
                        limit: 10,
                        search,
                    },
                })
                if (isMounted.current) setOptions(data.data)
            } catch (error) {
                setError(error)
            }
        }
        query()
    }, [search, type, isMounted])

    // Set the value of state locally and call the onChange function if it is defined
    const handleChange = (v: any) => {
        setValue(v)
        if (onChange) {
            onChange(v)
        }
    }

    if (error) {
        return (
            <SearchableSelect
                value={`Error loading ${type}`}
                disabled
                testId={testId}
            />
        )
    }

    return (
        <SearchableSelect
            value={
                userDefinedValue ||
                (value ? value[accesssorMap[type]] ?? null : null)
            }
            options={options}
            accessor={accesssorMap[type]}
            onValueChange={handleChange}
            searchValue={search}
            onSearchChange={setSearch}
            testId={testId}
        />
    )
}

// Split apart this component if the file starts to exceeds 300 lines or pieces are reused in 3 places

export default GenericsSelect

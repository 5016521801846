import EntFeature from 'models/EntFeature'
import { useHistory } from 'react-router'
import { useUiStateContext } from 'context/UiState/UiStateContext'

export const useRequireRole = (role: EntFeature): void => {
    const uiState = useUiStateContext()
    const history = useHistory()
    // If not loading and does they user not have a role, if the user exists.
    if (!uiState.loading && ((!uiState.whoami?.hasRole(role) && uiState.whoami?.id) ?? false)) {
        history.push('/not-found')
    }
}

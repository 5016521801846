import BaseService, { PageOf } from 'lib/BaseService'

export interface Merchant {
    alias: string
    business_name: string
    children: any[]
    dba: string
    id: number
    mids: any[]
    parents: any[]
    parent_id: number
    status: {
        id: number
        name: string
    }
    expanded?: boolean
    makeActive?: boolean
    deflectors?: any[]
    deflectorsLoaded?: boolean
}

export interface MerchantInfo {
    parent_id: string
    alias: string
    business_address: string
    business_name: string
    business_phone: string
    city: string
    contact: null | {
        fname: string
        lname: string
        email: string
        phone_number: string
    }
    country: {
        code: string
        id: number
        name: string
    }
    date_created: string
    dba: string
    ers_details: null
    id: number
    partner_company_id: string
    service_level: {
        id: 1
        name: string
    }
    state: string
    status: {
        id: number
        name: string
    }
    zip: string
    children: number[]
    direct_api: boolean
    features: any[]
    deflectors: any[]
}

export class MerchantService extends BaseService {
    basePath = '/cm/merchants'

    /**
     * Get a merchant by ID
     *
     * @param merchantId
     */
    get(merchantId: string) {
        return this.request<Merchant>(`/${merchantId}`)
    }

    /**
     *
     * @param merchantId - parent merchant id
     * @param options { search, limit }
     *
     * @returns Promise resolving children merchant,
     */
    list(merchantId: string, options?: any) {
        return this.request<PageOf<Merchant[]>>(
            this.injectParams({
                merchant_id: merchantId,
                start: 0,
                sort_order: 'asc',
                sort_by: 'business_name',
                ...options,
            })
        )
    }

    /**
     *
     * @param merchantId - merchent to update
     * @param newMerchant - merchant fields with changed values
     */
    update(merchantId: string, newMerchant: Partial<Merchant>) {
        return this.request(`/${merchantId}`, {
            method: 'PATCH',
            body: JSON.stringify(newMerchant),
        })
    }
}

import BaseService, { PageOf } from '../BaseService'

interface Status {
    id: number
    name: string
}

interface Role {
    id: number
    name: string
    description: string
}

interface Resource {}

interface Merchant {
    id: number
    business_name: string
    status: Status
}

interface Feature {
    id: number
    name: string
    alias: string
}

interface User {
    status: Status
    role: Role
    resources: Resource[]
    mids_excluded: any[]
    mids_count: null
    mids_allowed: any[]
    merchant: Merchant
    lname: string
    is_federated: boolean
    is_read_only: boolean
    id: number
    fname: string
    features: Feature[]
    email: string
    deny_resources: any[]
}

interface WhoAmI {
    allow_resources: any[]
    deny_resources: any[]
    email: string
    features: Feature[]
    fname: string
    id: number
    is_federated: boolean
    is_read_only: boolean
    lname: string
    merchant: Merchant
    mids_allowed: any[]
    mids_count: null
    mids_excluded: any[]
    resources: Resource[]
    role: Role
    status: Status
    username: string
    theme_variant: number
}

interface UserServiceInstance {
    get: (userId: number) => Promise<User>
    whoAmI: () => Promise<WhoAmI>
    byMerchant: (merchantId: number) => Promise<PageOf<User[]>>
}

export default class UserService
    extends BaseService
    implements UserServiceInstance {
    async get(userId: number) {
        const r = await this.axios.get<User>(`/users/users/${userId}`)
        return r.data
    }

    async whoAmI() {
        const r = await this.axios.post<WhoAmI>('/users/whoami')
        return r.data
    }

    async byMerchant(merchantId: number) {
        const r = await this.axios.get<PageOf<User[]>>(
            this.injectParams('/users/users', {
                merchant_id: merchantId,
            })
        )
        return r.data
    }
}

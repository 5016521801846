import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import { clientTheme } from 'theme-exports'

export const DataDropdownRows = ({
    data,
    dropdownData,
    overflowColumns,
    noRowSelectionBox,
}: {
    data: any
    dropdownData: {
        columns: { [key: string]: string }
        rows: { [key: string]: string }
    }
    overflowColumns: string[]
    noRowSelectionBox: boolean
}) => {
    const { columns, rows } = dropdownData
    const rowEntries = Object.entries(rows)

    return (
        <>
            {rowEntries.map((entry, idx) => (
                <TableRow key={`${entry[0]}-${idx}`}>
                    <TableCell
                        className={`emp-dataDropdownRows-tableBodyCell`}
                        colSpan={noRowSelectionBox ? 1 : 2}
                        style={{
                            fontSize:
                                clientTheme.typography.dataGrid.tableBody
                                    .fontSize,
                            color:
                                clientTheme.typography.dataGrid.tableBody.color,
                        }}
                    >
                        {entry[1]}
                    </TableCell>

                    {data.cells.map((cell: any, idx: any) => {
                        const column = cell.column as any

                        if (
                            [overflowColumns].includes(column.id) ||
                            column.id === 'selection'
                        )
                            return null

                        return (
                            <TableCell
                                align="center"
                                className={`emp-dataDropdownRows-tableBodyCell`}
                                style={{
                                    textAlign: cell.column.columnAlign,
                                    fontSize:
                                        clientTheme.typography.dataGrid
                                            .tableBody.fontSize,
                                    color:
                                        clientTheme.typography.dataGrid
                                            .tableBody.color,
                                }}
                                key={`${column.id}-${idx}`}
                            >
                                {!Object.keys(columns).includes(
                                    column.id
                                ) ? null : (
                                    <div>
                                        {data.original?.[column.id]?.[
                                            entry[0]
                                        ] ?? ''}
                                    </div>
                                )}
                            </TableCell>
                        )
                    })}
                </TableRow>
            ))}
        </>
    )
}

export default DataDropdownRows

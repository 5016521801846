import BaseService, { PageOf } from 'lib/BaseService'

export type DownloadParams = { [key: string]: any }

export enum CaseStatus {}

export enum CaseStatusId {
    New = 1,
    Working,
    DocUploaded,
    DoNotRepresent,
    Expired,
    DocCompiling,
    DocCompiled,
    TransportReady,
    TransportProcessing,
    TransportCompleted,
}

export enum CaseStatusGroup {
    New = 'New',
    InProgress = 'In Progress',
    Completed = 'Completed',
    Expired = 'Expired',
    DoNotRepresent = 'Do Not Represent',
}

export enum CaseStatusGroupId {
    New = 1,
    InProgress,
    Completed,
    Expired,
    DoNotRepresent,
}

export interface Case {
    arn: string
    assigned_user_id: null | number
    auth_no: string
    case_no: string
    cc_bin: string
    cc_last_four: string
    cc_no: string
    cc_type: string
    cc_type_id: number
    // company_partner_id: null | string
    cycle: string
    cycle_id: number
    date_completed: null | string
    date_due: string
    date_post: string
    date_trans: string
    descriptor: string
    dispute_amt: string
    dispute_currency: string
    id: number
    mid: string
    mid_alias: string
    mid_id: number
    order_id: null | number
    partner_company_id: null | string
    processor: string
    reason_category: string
    reason_category_id: number
    reason_code: string
    reason_code_id: number
    status: CaseStatus
    status_group: CaseStatusGroup
    status_group_id: CaseStatusGroupId
    status_id: CaseStatusId
    trans_amt: string
    trans_currency: string
    trans_id: string
    uuid: null | string
    verdict: string
    verdict_id: number
}

export class CasesService extends BaseService {
    list(params: Record<string, string>) {
        return this.axios
            .get<PageOf<Case[]>>(this.injectParams('cm/cases', params))
            .then((r) => r.data)
    }

    get(caseId: number) {
        return this.axios
            .get<Case>(`api/cm/cases/${caseId}`)
            .then((r) => r.data)
    }

    update(params: Record<string, string>) {
        return this.axios
            .get<Case>(this.injectParams('cm/cases/', params))
            .then((r) => r.data)
    }

    doNotRepresent(caseId: number) {
        return this.axios
            .patch<Case>(`cm/cases/${caseId}`, {
                status_id: CaseStatusId.DoNotRepresent, // moves case to status DNR
            })
            .then((r) => r.data)
    }

    revertDoNotRepresent(caseId: number) {
        return this.axios
            .patch<Case>(`cm/cases/${caseId}`, {
                status_id: CaseStatusId.New, // reverts case back to status new
            })
            .then((r) => r.data)
    }

    download(title: string, params: DownloadParams) {
        return this.request(
            this.injectParams('cm/cases.csv', params),
            undefined,
            title
        )
    }

    callAdminReviewPatch = (id: number, action_id: number) => {
        return this.axios.patch(`/cm/review`, {
            cases: [{
                id,
                action_id
            }]
        })
    }

    callCasePatch = (caseId: string, actionId: number, type?: string) => {
        switch (type) {
            case 'upload':
                return this.callRepresentmentsPatch(caseId, actionId)
            case 'admin':
                return this.callAdminReviewPatch(+caseId, actionId)
            case 'qc-review':
                return this.callQCReviewPatch(+caseId, actionId)
            default:
                return Promise.reject({message: 'Unknown patch type'})
        }
    }

    callQCReviewPatch = (caseId: number, actionId: number) => {
        return this.axios.patch(`/cm/cases/qc`, {
            id: caseId,
            action_id: actionId
        })
    }

    callAdminReviewGet = (id?: number) => {
        if (id) return this.axios.get(`/cm/review/${id}`)
        return this.axios.get(`/cm/review`)
    }

    callQCReviewGet = (id?: number) => {
        if (id) return this.axios.get(`/cm/cases/qc/${id}`)
        return this.axios.get(`/cm/cases/qc`)
    }

    approveQCReview = (id: number) => {
        return this.axios.post(`/docs/representment/transport`, {
            case_id: id
        })
    }

    callRepresentmentsPatch = (caseIds: string, actionId: number) => {
        const patchBody = caseIds.split(',').map((id) => {
            return {
                id,
                status_id: actionId
            }
        })
        return this.axios.patch(`/cm/cases/ui/status`, {cases: [...patchBody]})
    }

    handleAdminCaseReviewVerdict = (
        id: number,
        action_id: number,
        note?: string
    ) => {
        return this.axios.patch(`/cm/review`, {
            cases: [
                {
                    id,
                    action_id,
                    note,
                },
            ],
        })
    }
}

import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import {
    Button,
    Checkbox,
    Collapse,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Divider,
    Switch,
    TextField,
    CircularProgress,
} from '@mui/material'
import MerchantApi from 'api/MerchantApi'
import { LoadingIndicator, SearchableSelect } from 'components'
import CB from 'lib'
import { Merchant } from 'lib/Merchants'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'
import {
    TCountryCode,
    TAddEditMerchantInputs,
} from 'views/AddEditMerchant/AddEditMerchant.vm'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import useIsMounted from 'hooks/utils/useIsMounted'
import { UserStatus, AlertsDeflectorStatus } from '../MerchantEdit/MerchantEdit'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import { Deflector } from 'views/MerchantHierarchy/MerchantHierarchy.vm'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import EntFeature from 'models/EntFeature'

interface AddEditMerchantProps {
    isEdit: boolean
    onClose: () => void
    merchantId: number
    countryCodes: TCountryCode[]
    values: TAddEditMerchantInputs
    errors: { [key: string]: any }
    register: any
    isDirty: boolean
    isValid: boolean
    handleOnChangeValue: (fieldName: string, value: any) => void
    isSubmitting: boolean
    handleSubmit: () => void
    topLevelMerchantID: number
    availableDeflectors: Deflector[]
    defaultDeflectorValues: any[]
    setVerifiRDRIsOpen: (val: boolean) => void
}

// form for adding or editing a merchant
const AddEditForm = ({
    isEdit,
    onClose,
    merchantId,
    countryCodes,
    values,
    errors,
    register,
    isDirty,
    isValid,
    handleOnChangeValue,
    isSubmitting,
    handleSubmit,
    topLevelMerchantID,
    availableDeflectors,
    defaultDeflectorValues,
    setVerifiRDRIsOpen,
}: AddEditMerchantProps): ReactElement => {
    const formType = isEdit ? 'edit' : 'add'
    const {
        MERCHANT_UPDATE,
        DEFLECTORS,
        MERCHANT_ALIAS,
        SHOW_ISSUER_DOC_TOGGLE,
        MERCHANT_SERVICE_LEVELS,
    } = useFeatureToggle('MERCHANT_HIERARCHY')
    const [merchants, setMerchants] = useState<Merchant[] | null>(null)
    const [searchValue, setSearchValue] = useState('')
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(
        false
    )
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})
    const uiState = useUiStateContext()
    const hasEditRDR = uiState.whoami?.hasRole(EntFeature.EDIT_RDR) || false
    const { isMounted } = useIsMounted()

    useEffect(() => {
        if (merchantId) {
            CB.merchants
                .list(topLevelMerchantID.toString(), {
                    merchant_exclude_id: merchantId.toString(),
                    search: searchValue ?? '',
                    show_all: 1,
                    status_id: 1
                })
                .then((res: any) => {
                    if(isMounted.current) setMerchants(res.data)
                })
        }
    }, [merchantId, topLevelMerchantID, searchValue, isMounted])

    const setParentNameVal = (value: any) => {
        handleOnChangeValue('parentId', value.id)
        handleOnChangeValue('merchantParentName', value.business_name)
    }

    const getAddMerchantDefaults = useCallback(async () => {
        return MerchantApi.getEditMerchantInfo(merchantId).then((data) => {
            handleOnChangeValue('parentId', data.id)
            handleOnChangeValue('merchantParentName', data.business_name)
            handleOnChangeValue('merchantServiceLevel', '1')
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantId])

    useEffect(() => {
        if (!isEdit) {
            getAddMerchantDefaults()
        }
    }, [isEdit, getAddMerchantDefaults])

    const setActiveDeflectors = (id: number, value: boolean) => {
        if (!value) {
            handleOnChangeValue(
                'deflectors',
                values?.deflectors?.filter((obj: Deflector) => obj.id !== id) ??
                    []
            )
        } else {
            let addedDeflector = availableDeflectors.find(
                (obj: Deflector) => obj.id === id
            ) as Deflector

            addedDeflector.status = {
                id: 2,
                name: 'Pending Activation',
            }

            handleOnChangeValue('deflectors', [
                ...((values.deflectors &&
                    values.deflectors?.filter(
                        (obj: Deflector) => obj.id !== id
                    )) ||
                    []),
                addedDeflector,
            ])
        }
    }

    const MerchantAddressAddOn = (
        <>
            <FormControl className={'emp-updateMerchant-formControl'} fullWidth>
                <FormLabel
                    className={'emp-updateMerchant-inputLabel'}
                    id={`${formType}-${idDirectory.labelHeader}-business-city`}
                >
                    Business City
                </FormLabel>
                <TextField
                    variant="outlined"
                    className={`${'emp-updateMerchant-background'} ${'emp-updateMerchant-textFieldInput'}`}
                    required
                    type="text"
                    {...register('businessCity')}
                    name="businessCity"
                    id={`${formType}-${idDirectory.inputField}-business-city`}
                />
                {Boolean(errors?.businessCity?.message) && (
                    <FormHelperText error>
                        {errors?.businessCity?.message}
                    </FormHelperText>
                )}
            </FormControl>
            <div className={'emp-updateMerchant-inputHalfContainer'}>
                {/* *********** Country *************/}
                {!countryCodes.length ?
                    <LoadingIndicator text="Loading Countries..." />
                :
                    <FormControl
                        className={`${'emp-updateMerchant-formControl'} ${'emp-updateMerchant-inputHalf'}`}
                    >
                        <FormLabel
                            className={'emp-updateMerchant-inputLabel'}
                            id={`${formType}-${idDirectory.labelHeader}-business-country`}
                        >
                            Business Country
                        </FormLabel>
                        <Select
                            name="businessCountry"
                            value={values?.businessCountry || ''}
                            variant="outlined"
                            className={`${'emp-updateMerchant-background'} ${'emp-updateMerchant-textFieldInput'}`}
                            onChange={(e) =>
                                handleOnChangeValue(
                                    'businessCountry',
                                    e.target.value
                                )
                            }
                            required
                            id={`${formType}-${idDirectory.selectField}-business-country`}
                        >
                            {countryCodes.map((country, i) => (
                                <MenuItem
                                    key={`${country.name}-${i}`}
                                    value={country.code}
                                >
                                    <span>{country.name}</span>
                                </MenuItem>
                            ))}
                        </Select>
                        {Boolean(errors?.businessCountry?.message) && (
                            <FormHelperText error>
                                {errors?.businessCountry?.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                }
                <FormControl
                    className={`${'emp-updateMerchant-formControl'} ${'emp-updateMerchant-inputHalf'}`}
                >
                    <FormLabel
                        className={'emp-updateMerchant-inputLabel'}
                        id={`${formType}-${idDirectory.labelHeader}-business-phone`}
                    >
                        Business Phone
                    </FormLabel>
                    <TextField
                        variant="outlined"
                        className={`${'emp-updateMerchant-background'} ${'emp-updateMerchant-textFieldInput'}`}
                        required
                        type="text"
                        {...register('businessPhone')}
                        name="businessPhone"
                        id={`${formType}-${idDirectory.inputField}-business-phone`}
                    />
                    {Boolean(errors?.businessPhone?.message) && (
                        <FormHelperText error>
                            {errors?.businessPhone?.message}
                        </FormHelperText>
                    )}
                </FormControl>
            </div>
        </>
    )

    return (
        <>
            <form className={'emp-updateMerchant-root'} onSubmit={handleSubmit}>
                <div className={'emp-updateMerchant-formHeader'}>
                    <span>Merchant Details:</span>
                </div>

                <div className={'emp-updateMerchant-status'}>
                    <FormControl>
                        <FormLabel
                            className={'emp-updateMerchant-inputLabel'}
                            id={`${formType}-${idDirectory.labelHeader}-overall-status`}
                        >
                            Overall Status
                        </FormLabel>
                        <UserStatus
                            status={values?.status ?? false}
                            testId={`${formType}-${idDirectory.statusField}-overall-status`}
                        />
                    </FormControl>

                    <FormControl>
                        <Switch
                            className={'emp-updateMerchant-switchBtn'}
                            checked={values?.status || false}
                            onChange={(e) =>
                                handleOnChangeValue('status', e.target.checked)
                            }
                            name={'status'}
                            size={'small'}
                            id={`${formType}-${idDirectory.switchField}-overall-status`}
                            color="secondary"
                        />
                    </FormControl>
                </div>
                <Divider className={'emp-updateMerchant-divider'} />
                <div className={'emp-updateMerchant-addEditForm'}>
                    <div className={'emp-updateMerchant-formContainer'}>
                        {!MERCHANT_ALIAS?.enabled ? (
                            <FormControl
                                className={'emp-updateMerchant-formControl'}
                                fullWidth
                            >
                                <FormLabel
                                    className={'emp-updateMerchant-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-merchant-business-name`}
                                >
                                    Merchant Business Name
                                </FormLabel>
                                <TextField
                                    variant="outlined"
                                    className={`${'emp-updateMerchant-background'} ${'emp-updateMerchant-textFieldInput'}`}
                                    required
                                    type="text"
                                    {...register('merchantBusinessName')}
                                    name="merchantBusinessName"
                                    id={`${formType}-${idDirectory.inputField}-merchant-business-name`}
                                />
                                {Boolean(
                                    errors?.merchantBusinessName?.message
                                ) && (
                                    <FormHelperText error>
                                        {errors?.merchantBusinessName?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        ) : (
                            <div
                                className={
                                    'emp-updateMerchant-inputHalfContainer'
                                }
                            >
                                <FormControl
                                    className={`${'emp-updateMerchant-formControl'} ${'emp-updateMerchant-inputHalf'}`}
                                >
                                    <FormLabel
                                        className={
                                            'emp-updateMerchant-inputLabel'
                                        }
                                        id={`${formType}-${idDirectory.labelHeader}-merchant-business-name`}
                                    >
                                        Merchant Business Name
                                    </FormLabel>
                                    <TextField
                                        variant="outlined"
                                        className={`${'emp-updateMerchant-background'} ${'emp-updateMerchant-textFieldInput'}`}
                                        required
                                        type="text"
                                        {...register('merchantBusinessName')}
                                        name="merchantBusinessName"
                                        id={`${formType}-${idDirectory.inputField}-merchant-business-name`}
                                    />
                                    {Boolean(
                                        errors?.merchantBusinessName?.message
                                    ) && (
                                        <FormHelperText error>
                                            {
                                                errors?.merchantBusinessName
                                                    ?.message
                                            }
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl
                                    className={`${'emp-updateMerchant-formControl'} ${'emp-updateMerchant-inputHalf'}`}
                                >
                                    <FormLabel
                                        className={
                                            'emp-updateMerchant-inputLabel'
                                        }
                                        id={`${formType}-${idDirectory.labelHeader}-${MERCHANT_ALIAS.input_edit_label}`}
                                    >
                                        {MERCHANT_ALIAS.input_edit_label}
                                    </FormLabel>
                                    <TextField
                                        variant="outlined"
                                        className={`${'emp-updateMerchant-background'} ${'emp-updateMerchant-textFieldInput'}`}
                                        required
                                        type="text"
                                        {...register('merchantAlias')}
                                        name="merchantAlias"
                                        id={`${formType}-${idDirectory.inputField}-${MERCHANT_ALIAS.input_edit_label}`}
                                    />
                                    {Boolean(
                                        errors?.merchantAlias?.message
                                    ) && (
                                        <FormHelperText error>
                                            {errors?.merchantAlias?.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </div>
                        )}
                        <div
                            className={'emp-updateMerchant-inputHalfContainer'}
                        >
                            <FormControl
                                className={`${'emp-updateMerchant-formControl'} ${'emp-updateMerchant-inputHalf'}`}
                            >
                                <FormLabel
                                    className={'emp-updateMerchant-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-merchant-parent-name`}
                                >
                                    Merchant Parent Name
                                </FormLabel>
                                <SearchableSelect
                                    value={values?.merchantParentName || ''}
                                    onValueChange={setParentNameVal}
                                    searchValue={searchValue}
                                    onSearchChange={setSearchValue}
                                    options={(merchants as any) ?? []}
                                    accessor="business_name"
                                    testId={`merchant-parent-name`}
                                />
                            </FormControl>
                            <FormControl
                                className={`${'emp-updateMerchant-formControl'} ${'emp-updateMerchant-inputHalf'}`}
                            >
                                <FormLabel
                                    className={'emp-updateMerchant-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-${MERCHANT_UPDATE.input_edit_label}`}
                                >
                                    {MERCHANT_UPDATE.input_edit_label}
                                </FormLabel>
                                <TextField
                                    variant="outlined"
                                    className={`${'emp-updateMerchant-background'} ${'emp-updateMerchant-textFieldInput'}`}
                                    type="text"
                                    {...register('partnerCompanyId')}
                                    name="partnerCompanyId"
                                    id={`${formType}-${idDirectory.inputField}-${MERCHANT_UPDATE.input_edit_label}`}
                                />
                                {Boolean(errors?.partnerCompanyId?.message) && (
                                    <FormHelperText error>
                                        {errors?.partnerCompanyId?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </div>
                        <FormControl
                            className={'emp-updateMerchant-formControl'}
                            fullWidth
                        >
                            <FormLabel
                                className={'emp-updateMerchant-inputLabel'}
                                id={`${formType}-${idDirectory.labelHeader}-business-address`}
                            >
                                Business Address
                            </FormLabel>
                            <TextField
                                variant="outlined"
                                className={`${'emp-updateMerchant-background'} ${'emp-updateMerchant-textFieldInput'}`}
                                required
                                type="text"
                                {...register('businessAddress')}
                                name="businessAddress"
                                id={`${formType}-${idDirectory.inputField}-business-address`}
                            />
                            {Boolean(errors?.businessAddress?.message) && (
                                <FormHelperText error>
                                    {errors?.businessAddress?.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <div
                            className={'emp-updateMerchant-inputHalfContainer'}
                        >
                            <FormControl
                                className={`${'emp-updateMerchant-formControl'} ${'emp-updateMerchant-inputHalf'}`}
                            >
                                <FormLabel
                                    className={'emp-updateMerchant-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-business-region-state`}
                                >
                                    Business Region/State
                                </FormLabel>
                                <TextField
                                    variant="outlined"
                                    className={`${'emp-updateMerchant-background'} ${'emp-updateMerchant-textFieldInput'}`}
                                    required
                                    type="text"
                                    {...register('businessRegionOrState')}
                                    name="businessRegionOrState"
                                    id={`${formType}-${idDirectory.inputField}-business-region-state`}
                                />
                                {Boolean(
                                    errors?.businessRegionOrState?.message
                                ) && (
                                    <FormHelperText error>
                                        {errors?.businessRegionOrState?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                className={`${'emp-updateMerchant-formControl'} ${'emp-updateMerchant-inputHalf'}`}
                            >
                                <FormLabel
                                    className={'emp-updateMerchant-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-business-zip-code`}
                                >
                                    Business Zip Code
                                </FormLabel>
                                <TextField
                                    variant="outlined"
                                    className={`${'emp-updateMerchant-background'} ${'emp-updateMerchant-textFieldInput'}`}
                                    required
                                    type="text"
                                    {...register('businessZipCode')}
                                    name="businessZipCode"
                                    id={`${formType}-${idDirectory.inputField}-business-zip-code`}
                                />
                                {Boolean(errors?.businessZipCode?.message) && (
                                    <FormHelperText error>
                                        {errors?.businessZipCode?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </div>
                        {DEFLECTORS.enabled && MerchantAddressAddOn}
                    </div>
                    <div
                        className={`${'emp-updateMerchant-formContainer'} ${'emp-updateMerchant-formContainer2'}`}
                    >
                        <div className={'emp-updateMerchant-switchContainer'}>
                            <FormControl
                                className={'emp-updateMerchant-formControl'}
                                fullWidth
                            >
                                <FormLabel
                                    className={'emp-updateMerchant-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-direct-api`}
                                >
                                    Direct API
                                </FormLabel>
                                <UserStatus
                                    status={values?.directAPI ?? false}
                                    testId={`${formType}-${idDirectory.statusField}-direct-api`}
                                />
                            </FormControl>

                            <FormControl>
                                <Switch
                                    className={'emp-updateMerchant-switchBtn'}
                                    checked={values?.directAPI || false}
                                    onChange={(e) =>
                                        handleOnChangeValue(
                                            'directAPI',
                                            e.target.checked
                                        )
                                    }
                                    name={'directAPI'}
                                    size={'small'}
                                    id={`${formType}-${idDirectory.switchField}-direct-api`}
                                    color="secondary"
                                />
                            </FormControl>
                        </div>
                        {SHOW_ISSUER_DOC_TOGGLE.enabled && (
                            <div
                                className={'emp-updateMerchant-switchContainer'}
                            >
                                <FormControl
                                    className={'emp-updateMerchant-formControl'}
                                    fullWidth
                                >
                                    <FormLabel
                                        className={
                                            'emp-updateMerchant-inputLabel'
                                        }
                                        id={`${formType}-${idDirectory.labelHeader}-has-issuer-docs`}
                                    >
                                        Issuer Docs
                                    </FormLabel>
                                    <UserStatus
                                        status={values?.hasIssuerDocs ?? false}
                                        testId={`${formType}-${idDirectory.statusField}-has-issuer-docs`}
                                    />
                                </FormControl>

                                <FormControl>
                                    <Switch
                                        className={
                                            'emp-updateMerchant-switchBtn'
                                        }
                                        checked={values?.hasIssuerDocs || false}
                                        onChange={(e) =>
                                            handleOnChangeValue(
                                                'hasIssuerDocs',
                                                e.target.checked
                                            )
                                        }
                                        name={'hasIssuerDocs'}
                                        size={'small'}
                                        id={`${formType}-${idDirectory.switchField}-has-issuer-docs`}
                                        color="secondary"
                                    />
                                </FormControl>
                            </div>
                        )}
                        <FormControl
                            className={'emp-updateMerchant-formControl'}
                            fullWidth
                        >
                            <FormLabel
                                className={'emp-updateMerchant-inputLabel'}
                                id={`${formType}-${idDirectory.labelHeader}-merchant-service-level`}
                            >
                                Merchant Service Level
                            </FormLabel>
                            <RadioGroup
                                aria-label="service level"
                                name={'merchantServiceLevel'}
                                value={values?.merchantServiceLevel || ''}
                                onChange={(e) => {
                                    handleOnChangeValue(
                                        'merchantServiceLevel',
                                        e.target.value
                                    )
                                }}
                                row
                                className={'emp-updateMerchant-radioContainer'}
                            >
                                {MERCHANT_SERVICE_LEVELS.basic_service_enabled && (
                                    <FormControlLabel
                                        value={'1'}
                                        control={<Radio color="secondary" />}
                                        label="Basic Service"
                                        id={`${formType}-${idDirectory.radioField}-basic-service`}
                                    />
                                )}
                                {MERCHANT_SERVICE_LEVELS.pro_service_enabled && (
                                    <FormControlLabel
                                        value={'5'}
                                        control={<Radio color="secondary" />}
                                        label="Pro Service"
                                        id={`${formType}-${idDirectory.radioField}-pro-service`}
                                    />
                                )}
                                {MERCHANT_SERVICE_LEVELS.full_service_enabled && (
                                    <FormControlLabel
                                        value={'3'}
                                        control={<Radio color="secondary" />}
                                        label="Full Service"
                                        id={`${formType}-${idDirectory.radioField}-full-service`}
                                    />
                                )}
                            </RadioGroup>
                            <Divider />
                            {DEFLECTORS.enabled &&
                                availableDeflectors?.map(
                                    (availableDeflector, idx) => {
                                        // Statuses 1: Inactive - 2: Pending Activation - 3: Active
                                        let foundDeflector = undefined as any
                                        try {
                                            foundDeflector =
                                                values.deflectors &&
                                                values.deflectors.find(
                                                    (obj: any) => {
                                                        return (
                                                            obj.id ===
                                                            availableDeflector.id
                                                        )
                                                    }
                                                )
                                        } catch (e) {}

                                        const isChecked = foundDeflector
                                            ? foundDeflector.status.id === 1
                                                ? false
                                                : true
                                            : false

                                        const defaultDeflectorStatusId = defaultDeflectorValues.find(
                                            (obj: Deflector) => {
                                                return (
                                                    obj.id ===
                                                    availableDeflector.id
                                                )
                                            }
                                        )?.status?.id

                                        return (
                                            <div
                                                className={
                                                    'emp-updateMerchant-deflectors'
                                                }
                                                key={`key-deflector-${idx}`}
                                            >
                                                <FormControl>
                                                    <FormLabel
                                                        className={
                                                            'emp-updateMerchant-inputLabel'
                                                        }
                                                        id={`${formType}-${idDirectory.labelHeader}-${availableDeflector.name}`}
                                                    >
                                                        {
                                                            availableDeflector.name
                                                        }
                                                    </FormLabel>
                                                    <AlertsDeflectorStatus
                                                        status={
                                                            foundDeflector
                                                                ? foundDeflector
                                                                      .status.id
                                                                : 1
                                                        }
                                                        label={
                                                            foundDeflector
                                                                ? foundDeflector
                                                                      ?.status
                                                                      ?.id === 2
                                                                    ? 'Pending Activation'
                                                                    : foundDeflector
                                                                          ?.status
                                                                          ?.id ===
                                                                      3
                                                                    ? 'Active'
                                                                    : 'Inactive'
                                                                : 'Inactive'
                                                        }
                                                        testId={`${idDirectory.statusField}-${availableDeflector.name}`}
                                                    />
                                                </FormControl>
                                                <div>
                                                    {availableDeflector.alias ===
                                                        'verifi_rdr' &&
                                                        hasEditRDR &&
                                                        isChecked && (
                                                            <Button
                                                                className={
                                                                    'emp-updateMerchant-editRDRBtn'
                                                                }
                                                                color={
                                                                    'secondary'
                                                                }
                                                                onClick={() => {
                                                                    setVerifiRDRIsOpen(
                                                                        true
                                                                    )
                                                                }}
                                                            >
                                                                Edit RDR
                                                            </Button>
                                                        )}
                                                    <FormControl>
                                                        <Switch
                                                            className={
                                                                'emp-updateMerchant-switchBtn'
                                                            }
                                                            checked={isChecked}
                                                            onChange={(e) => {
                                                                if (
                                                                    isEdit &&
                                                                    isChecked &&
                                                                    [
                                                                        2,
                                                                        3,
                                                                    ].includes(
                                                                        defaultDeflectorStatusId
                                                                    )
                                                                ) {
                                                                    setAlertSnackbarProps(
                                                                        {
                                                                            title:
                                                                                'Error',
                                                                            message: `Please open an issue at Jira Service Management, for further assistance.`,
                                                                            intent:
                                                                                'error',
                                                                        }
                                                                    )
                                                                    return setAlertSnackbarOpen(
                                                                        true
                                                                    )
                                                                }
                                                                setActiveDeflectors(
                                                                    availableDeflector.id,
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }}
                                                            name={`deflector-${idx}`}
                                                            size={'small'}
                                                            id={`${formType}-${idDirectory.switchField}-${availableDeflector.name}`}
                                                            color="secondary"
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        )
                                    }
                                )}
                        </FormControl>
                        {!DEFLECTORS.enabled && MerchantAddressAddOn}
                    </div>
                </div>

                <FormControl
                    className={`${'emp-updateMerchant-formControl'} ${'emp-updateMerchant-inputHalf'}`}
                >
                    <FormControlLabel
                        {...register('addContactInfo')}
                        name="addContactInfo"
                        id={`${formType}-${idDirectory.checkboxAddContact}`}
                        control={
                            <Checkbox
                                checked={Boolean(values?.addContactInfo)}
                                color={'secondary'}
                            />
                        }
                        label="Add Contact Information"
                        disabled={Boolean(
                            values?.addContactInfo &&
                                isEdit &&
                                values.hasContactInfo
                        )}
                    />
                    {Boolean(errors?.addContactInfo?.message) && (
                        <FormHelperText error>
                            {errors?.addContactInfo?.message}
                        </FormHelperText>
                    )}
                </FormControl>

                <Collapse in={values?.addContactInfo}>
                    <div className={'emp-updateMerchant-formHeader'}>
                        <span>Main Admin User:</span>
                    </div>

                    <div className={'emp-updateMerchant-formContainer'}>
                        <div className={'emp-updateMerchant-formBlockFull'}>
                            <FormControl
                                className={`${'emp-updateMerchant-formControl'}`}
                                style={{ width: '49%' }}
                            >
                                <FormLabel
                                    className={'emp-updateMerchant-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-contact-first-name`}
                                >
                                    Contact First Name
                                </FormLabel>
                                <TextField
                                    variant="outlined"
                                    className={`${'emp-updateMerchant-background'} ${'emp-updateMerchant-textFieldInput'} `}
                                    type="text"
                                    {...register('fname')}
                                    name="fname"
                                    id={`${formType}-${idDirectory.inputField}-contact-first-name`}
                                />
                                {Boolean(errors?.fname?.message) && (
                                    <FormHelperText error>
                                        {errors?.fname?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <FormControl
                                className={`${'emp-updateMerchant-formControl'}`}
                                style={{ width: '49%' }}
                            >
                                <FormLabel
                                    className={'emp-updateMerchant-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-contact-last-name`}
                                >
                                    Contact Last Name
                                </FormLabel>
                                <TextField
                                    variant="outlined"
                                    className={`${'emp-updateMerchant-background'} ${'emp-updateMerchant-textFieldInput'} `}
                                    type="text"
                                    {...register('lname')}
                                    name="lname"
                                    id={`${formType}-${idDirectory.inputField}-contact-last-name`}
                                />
                                {Boolean(errors?.lname?.message) && (
                                    <FormHelperText error>
                                        {errors?.lname?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </div>
                    </div>

                    <div className={'emp-updateMerchant-formContainer'}>
                        <div className={'emp-updateMerchant-formBlockFull'}>
                            <FormControl
                                className={`${'emp-updateMerchant-formControl'}`}
                                style={{ width: '49%' }}
                            >
                                <FormLabel
                                    className={'emp-updateMerchant-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-contact-email`}
                                >
                                    Contact Email
                                </FormLabel>
                                <TextField
                                    variant="outlined"
                                    className={`${'emp-updateMerchant-background'} ${'emp-updateMerchant-textFieldInput'} `}
                                    type="text"
                                    {...register('email')}
                                    name="email"
                                    id={`${formType}-${idDirectory.inputField}-contact-email`}
                                />
                                {Boolean(errors?.email?.message) && (
                                    <FormHelperText error>
                                        {errors?.email?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl
                                className={`${'emp-updateMerchant-formControl'}`}
                                style={{ width: '49%' }}
                            >
                                <FormLabel
                                    className={'emp-updateMerchant-inputLabel'}
                                    id={`${formType}-${idDirectory.labelHeader}-contact-phone`}
                                >
                                    Contact Phone Number
                                </FormLabel>
                                <TextField
                                    variant="outlined"
                                    className={`${'emp-updateMerchant-background'} ${'emp-updateMerchant-textFieldInput'} `}
                                    type="text"
                                    {...register('phone_number')}
                                    name="phone_number"
                                    id={`${formType}-${idDirectory.inputField}-contact-phone`}
                                />
                                {Boolean(errors?.phone_number?.message) && (
                                    <FormHelperText error>
                                        {errors?.phone_number?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </div>
                    </div>
                </Collapse>
                <div className={'emp-updateMerchant-actionBtnContainer'}>
                    <Button
                        variant="contained"
                        onClick={onClose}
                        id={`${formType}-${idDirectory.btnCancel}`}
                        sx={clientTheme.buttons.defaultButton.style}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting || !isValid || !isDirty}
                        id={`${formType}-${idDirectory.btnSubmit}`}
                    >
                        {isSubmitting ? (
                            <div
                                className={
                                    'emp-updateMerchant-loadingContainer'
                                }
                            >
                                <CircularProgress
                                    size={22}
                                    color="secondary"
                                    style={{
                                        position: 'absolute',
                                        left: '25%',
                                    }}
                                />
                                <div>Submit</div>
                            </div>
                        ) : (
                            'Submit'
                        )}
                    </Button>
                </div>
            </form>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => {
                    setAlertSnackbarOpen(false)
                }}
                showCloseIcon
            />
        </>
    )
}

const UpdateMerchant = ({
    isEdit,
    onClose,
    merchantId,
    countryCodes,
    values,
    errors,
    register,
    isDirty,
    isValid,
    handleOnChangeValue,
    isSubmitting,
    handleSubmit,
    topLevelMerchantID,
    availableDeflectors,
    defaultDeflectorValues,
    setVerifiRDRIsOpen,
}: AddEditMerchantProps): ReactElement => {
    return (
        <AddEditForm
            isEdit={isEdit}
            onClose={onClose}
            merchantId={merchantId}
            countryCodes={countryCodes}
            values={values}
            errors={errors}
            register={register}
            isDirty={isDirty}
            isValid={isValid}
            handleOnChangeValue={handleOnChangeValue}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            topLevelMerchantID={topLevelMerchantID}
            availableDeflectors={availableDeflectors}
            defaultDeflectorValues={defaultDeflectorValues}
            setVerifiRDRIsOpen={setVerifiRDRIsOpen}
        />
    )
}

export default UpdateMerchant

import {
    Merchant,
    SelectMerchant,
    SelectMid,
    SetActiveNodeId,
    SetChildParentMerchant,
    SetOwnParentMerchant,
    UseMidsForMerchant,
} from 'views/MerchantHierarchy/MerchantHierarchy.vm'
import React, { useEffect, useState } from 'react'
import { Alert } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
    faChevronDown,
    faChevronRight,
    faEllipsisV,
    faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { Button, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { LoadingIndicator, Pagination } from 'components'
import { clientTheme } from 'theme-exports'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import EntFeature from 'models/EntFeature'
import idDirectory from '../idAttributes'

export const LoadingNode = () => (
    <li className={'emp-loadingNode'}>
        <LoadingIndicator />
    </li>
)

const makeNodeId = (id: number, type: 'mid' | 'merchant') => `${type}-${id}`

interface MerchantNodeProps {
    depth?: number
    merchant: Merchant
    merchants: Merchant[]
    setOwnParentMerchant: SetOwnParentMerchant
    setChildParentMerchant: SetChildParentMerchant
    selectMid: SelectMid
    selectMerchant: SelectMerchant
    useMidsForMerchant: UseMidsForMerchant
    activeNodeId: string
    setActiveNodeId: SetActiveNodeId
    open?: boolean
    toggleMerchantDrawer: (isEdit: boolean, type?: 'mids' | 'merchant') => void
    setMerchantName?: (name: string) => void
    setStart?: (val: number) => void
    pagination?: {
        start: number
        pageSize: number
        totalEntries: number
        pageCount: number
    }
    isPaginate?: boolean
    activeMerchantId: number
    setMerchantDetails: (merchant: Merchant) => void
    rowIndex: number | string
}

export const MerchantNode: React.FC<MerchantNodeProps> = ({
    depth = 1,
    merchant,
    merchants,
    setChildParentMerchant,
    setOwnParentMerchant,
    selectMid,
    selectMerchant,
    useMidsForMerchant,
    activeNodeId,
    setActiveNodeId,
    children,
    open = false,
    toggleMerchantDrawer,
    setMerchantName,
    setStart = () => {},
    pagination = { start: 0, pageSize: 10, totalEntries: 0, pageCount: 1 },
    isPaginate = false,
    activeMerchantId,
    setMerchantDetails,
    rowIndex,
}) => {
    const uiState = useUiStateContext()
    const { user } = useAuthedUser()

    const isReadOnly = user?.is_read_only
    const isReadOnlyAddMerchant = !uiState.whoami?.hasRole(
        EntFeature.CREATE_MERCHANT
    )
    const isReadOnlyAddMid = !uiState.whoami?.hasRole(EntFeature.CREATE_MID)

    const [isOpen, setIsOpen] = useState(open)
    const { mids, isMidsLoading, midsError } = useMidsForMerchant(
        merchant.id,
        isOpen
    )
    const { pageSize, totalEntries, pageCount } = pagination
    const [contextAnchorEl, setContextAnchorEl] = React.useState(null)

    const merchantsList: Merchant[] = merchants.filter(
        (i) => i.id === merchant.id
    )[0]?.children

    const contextMenuOpen = Boolean(contextAnchorEl)

    const handleAddMerchantClick = (e: any, type: 'merchant' | 'mids') => {
        e.stopPropagation()
        toggleMerchantDrawer(false, type)
        setContextAnchorEl(null)
        setMerchantName && setMerchantName(merchant.business_name)
    }

    const handleContextMenuClick = (e: any) => {
        e.stopPropagation()
        setActiveNodeId(makeNodeId(merchant.id, 'merchant'))
        selectMerchant(merchant.id)
        setContextAnchorEl(e.currentTarget)
    }

    const handleContextMenuClose = (e: any) => {
        e.stopPropagation()
        setContextAnchorEl(null)
    }

    useEffect(() => {
        if (merchant.makeActive) {
            setActiveNodeId(makeNodeId(merchant.id, 'merchant'))
            selectMerchant(merchant.id)
            setMerchantName && setMerchantName(merchant.business_name)
            setMerchantDetails(merchant)
        }
    }, [merchant, selectMerchant, setActiveNodeId, setMerchantDetails, setMerchantName])

    return (
        <div id={`${idDirectory.merchantNode.divMerchant}-${merchant.id}`}>
            <div id={'merchantHierarchyMerchantNode'}>
                <li
                    onClick={
                        contextMenuOpen && isReadOnly
                            ? () => {}
                            : () => {
                                  // Don't collapse root merchant on click.
                                  if (merchant.parent_id) {
                                      setIsOpen(!isOpen)
                                  }
                                  setActiveNodeId(
                                      makeNodeId(merchant.id, 'merchant')
                                  )
                                  selectMerchant(merchant.id)
                                  setMerchantName &&
                                      setMerchantName(merchant.business_name)
                                  setMerchantDetails(merchant)
                              }
                    }
                    className={`${'emp-hoverGrey'} ${
                        activeNodeId === makeNodeId(merchant.id, 'merchant') &&
                        'active'
                    }`}
                    style={{
                        display: 'grid',
                        padding: '0.5rem',
                        paddingLeft: 20 * depth,
                        border: 'solid 1px lightgray',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ paddingRight: '0.5rem' }}>
                                {isOpen ? (
                                    <Icon icon={faChevronDown} />
                                ) : (
                                    <Icon icon={faChevronRight} />
                                )}
                            </span>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <Typography
                                    style={{ color: 'inherit' }}
                                    variant="h5"
                                >
                                    {merchant.business_name}
                                </Typography>
                                <span
                                    style={{
                                        fontSize:
                                            clientTheme.typography.subtitle4
                                                .fontSize,
                                    }}
                                >
                                    {merchant.alias}
                                </span>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'grid',
                            }}
                            id={`${idDirectory.merchantNode.divEllipsis}-${merchant.id}`}
                        >
                            {(!isReadOnlyAddMerchant || !isReadOnlyAddMid) && (
                                <Tooltip title="">
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            width: '20px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                        }}
                                        onClick={handleContextMenuClick}
                                    >
                                        <Icon icon={faEllipsisV} />
                                    </span>
                                </Tooltip>
                            )}
                            <Menu
                                anchorEl={contextAnchorEl}
                                keepMounted
                                open={contextMenuOpen}
                                onClose={handleContextMenuClose}
                                id={'merchantHierarchyMerchantNodePopover'}
                            >
                                {!isReadOnlyAddMerchant && (
                                    <MenuItem
                                        className={'emp-contextMenuItem'}
                                        disabled={isReadOnly}
                                    >
                                        <Button
                                            className={'emp-contextBtn'}
                                            onClick={(e) =>
                                                handleAddMerchantClick(
                                                    e,
                                                    'merchant'
                                                )
                                            }
                                            size="small"
                                            name="add-merchant-button"
                                            id={`${idDirectory.merchantNode.btnAddMerchant}-${merchant.id}`}
                                            disabled={isReadOnly}
                                        >
                                            <Icon
                                                icon={faPlus}
                                                className={`${
                                                    isReadOnly &&
                                                    'emp-disabledIcon'
                                                }`}
                                            />{' '}
                                            Add Merchant
                                        </Button>
                                    </MenuItem>
                                )}
                                {!isReadOnlyAddMid && (
                                    <MenuItem
                                        className={'emp-contextMenuItem'}
                                        disabled={isReadOnly}
                                    >
                                        <Button
                                            className={'emp-contextBtn'}
                                            onClick={(e) =>
                                                handleAddMerchantClick(
                                                    e,
                                                    'mids'
                                                )
                                            }
                                            size="small"
                                            name="add-mids-button"
                                            id={`${idDirectory.merchantNode.btnAddMid}-${merchant.id}`}
                                            disabled={isReadOnly}
                                        >
                                            <Icon
                                                icon={faPlus}
                                                className={`${
                                                    isReadOnly &&
                                                    'emp-disabledIcon'
                                                }`}
                                            />{' '}
                                            Add MID
                                        </Button>
                                    </MenuItem>
                                )}
                            </Menu>
                        </div>
                    </div>
                </li>
                {isOpen && (
                    <>
                        {children
                            ? children
                            : merchantsList
                                  .filter((i) => i.parent_id === merchant.id)
                                  .map((i, idx) => (
                                      <MerchantNode
                                          key={i.id}
                                          merchant={i}
                                          merchants={merchantsList}
                                          selectMid={selectMid}
                                          setOwnParentMerchant={
                                              setOwnParentMerchant
                                          }
                                          setChildParentMerchant={
                                              setChildParentMerchant
                                          }
                                          open={i.expanded}
                                          useMidsForMerchant={
                                              useMidsForMerchant
                                          }
                                          depth={depth + 1}
                                          activeNodeId={activeNodeId}
                                          setActiveNodeId={setActiveNodeId}
                                          selectMerchant={selectMerchant}
                                          toggleMerchantDrawer={
                                              toggleMerchantDrawer
                                          }
                                          setMerchantName={setMerchantName}
                                          activeMerchantId={activeMerchantId}
                                          setMerchantDetails={
                                              setMerchantDetails
                                          }
                                          rowIndex={idx}
                                      />
                                  ))}
                        {isMidsLoading ? (
                            <li
                                style={{
                                    padding: '0.5rem',
                                    paddingLeft: 20 * depth + 16,
                                    border: 'solid 1px lightgray',
                                }}
                            >
                                <LoadingIndicator />
                            </li>
                        ) : (
                            <>
                                {midsError && !isMidsLoading && (
                                    <li>
                                        <Alert color="error">
                                            {midsError?.message}
                                        </Alert>
                                    </li>
                                )}
                                {mids.map((i) => (
                                    <li
                                        key={i.id}
                                        id={`${idDirectory.merchantNode.listItemMid}-${i.id}`}
                                        onClick={() => {
                                            selectMid(i.id)
                                            setActiveNodeId(
                                                makeNodeId(i.id, 'mid')
                                            )
                                            setMerchantName &&
                                                setMerchantName(i.mid)
                                        }}
                                        className={`${'emp-hoverGrey'} ${
                                            activeNodeId ===
                                                makeNodeId(i.id, 'mid') &&
                                            'active'
                                        }`}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '0.5rem',
                                            paddingLeft: 20 * depth + 16,
                                            border: 'solid 1px lightgray',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {i.mid}
                                    </li>
                                ))}
                            </>
                        )}

                        {isPaginate && (
                            <Pagination
                                pageCount={pageCount}
                                pageSize={pageSize}
                                totalEntries={totalEntries}
                                setStart={setStart}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

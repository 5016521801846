import AccountSetupIcon from 'assets/accountSetupIcons/icon-level-selfservice.png'
import MidsIcon from 'assets/accountSetupIcons/data-source_merchant-account-provider.svg'
import ProductsIcon from 'assets/accountSetupIcons/product-type_physical_goods.svg'
import TemplatesIcon from 'assets/accountSetupIcons/data-source_sales-information-resource.svg'

const mockSetupLinks = [
    {
        path: '/accountsetup',
        icon: AccountSetupIcon,
        title: 'Account Setup',
    },
    {
        path: '/mids',
        icon: MidsIcon,
        title: `MID's`,
    },
    {
        path: '/products',
        icon: ProductsIcon,
        title: 'Products',
    },
    {
        path: '/Templates',
        icon: TemplatesIcon,
        title: 'Templates',
    },
]

interface AccountSetup {
    accountSetupLinks: {
        path: string
        icon: string
        title: string
    }[]
}

/**
 * Used to get the account settings paths and corresponding image representations
 */

class AccountSetupApi {
    static accountSetupLinks(): Promise<AccountSetup> {
        return new Promise<AccountSetup>((resolve) => {
            setTimeout(resolve, 1000)
        }).then(() => ({
            accountSetupLinks: mockSetupLinks,
        }))
    }
}

export default AccountSetupApi

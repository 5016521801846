/**
 * @description Directory for ID Attributes (DataGrid).
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    dataGridActions: {
        divRoot: `dataGridActions-div-root`,
        btnColFilter: `dataGridActions-btn-colFilter`,
        btnCopy: `dataGridActions-btn-copy`,
        btnCSV: `dataGridActions-btn-CSV`,
        btnExcel: `dataGridActions-btn-excel`,
        btnExportAll: `dataGridActions-btn-exportAll`,
        btnExportAllXlsx: `dataGridActions-btn-exportAll`,
        btnPrint: `dataGridActions-btn-print`,
        btnColumn: `dataGridActions-btn-column`,
        btnSwitchGrid: `dataGridActions-btn-switch-grid`,
    },
    dataGridSlim: {
        divRoot: `dataGridSlim-div-root`,
        tableContainer: `dataGridSlim-table-container`,
        tableHead: `dataGridSlim-table-head`,
        tableHeadCell: `dataGridSlim-tableCell-header`,
    },
    dataGridSlimMTD: {
        divRoot: `dataGridSlimMTD-div-root`,
        tableContainer: `dataGridSlimMTD-table-container`,
        tableHead: `dataGridSlimMTD-table-head`,
        tableHeadCell: `dataGridSlimMTD-tableCell-header`,
        divEntries: `dataGridSlimMTD-div-entries`,
    },
    dataGridEditable: {
        divRoot: `dataGridEditable-div-root`,
        tableContainer: `dataGridEditable-table-container`,
        tableHead: `dataGridEditable-table-head`,
        tableHeadCell: `dataGridEditable-tableCell-header`,
    },
}

export default idDirectory

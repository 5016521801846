import React, { memo, useRef } from 'react'
import { ValueFormatterParams } from 'ag-grid-community'
import {
    CreditCardProcessorType,
    DataGridActions,
    Searchbar,
    VerdictType,
    AGDataGrid,
} from 'components'
import { ExtendedColumn } from 'components/DataGrid/ExtendedColumn'
import { amountValueFormatter } from '../../ChargebacksAGGrid/grids/ChargebacksGrid'
import { OutcomeCell, ActionsCell, SelectMidCell } from './cells'
import { AlertHistory, MarkAlert, SelectMID, CompleteAlert } from '../modals'
import { ModalVariant, Download, MidList } from '../Alerts.vm'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface IAlertsGridProps {
    testId: string
    downloadXlsx: Download
    isLoadingDownloadXlsxAll: boolean
    setCurrentAlertId: (val: number | undefined) => void
    currentAlertId: number | undefined
    toggleModal: (variant: ModalVariant) => void
    alertHistoryOpen: boolean
    markOutcomeOpen: boolean
    completeOpen: boolean
    selectMIDOpen: boolean
    midList: MidList[]
    setSelectedMid: (mid: MidList) => void
    isSelfService: boolean
    alertsOutcomesData: any
    selectedAlertInfo: any
    gridRef: any
    data: { [key: string]: any }[]
    dataSource: any
    isLoading: boolean
    pageSize: number
    page: number
    paginationTotal: number
    setLocalPage: (page: number) => void
    handlePageLimitChange: (pageSize: number) => void
    handleSearchField: (value: string) => void
    currentPayload: { [key: string]: any } | undefined
    refreshAlertsGrid: () => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export type CloseAndInvalidate = (
    name: ModalVariant,
    shouldInvalidate?: boolean
) => void

const AlertsGrid = ({
    testId,
    downloadXlsx,
    isLoadingDownloadXlsxAll,
    setCurrentAlertId,
    currentAlertId,
    toggleModal,
    alertHistoryOpen,
    markOutcomeOpen,
    completeOpen,
    selectMIDOpen,
    midList,
    setSelectedMid,
    isSelfService,
    alertsOutcomesData,
    selectedAlertInfo,
    gridRef,
    data,
    dataSource,
    isLoading,
    pageSize,
    page,
    paginationTotal,
    setLocalPage,
    handlePageLimitChange,
    handleSearchField,
    currentPayload,
    refreshAlertsGrid,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: IAlertsGridProps) => {
    const tableOuterContainerEL = useRef<HTMLDivElement>(null)

    const closeAndInvalidate: CloseAndInvalidate = (name, shouldInvalidate) => {
        if (shouldInvalidate) refreshAlertsGrid()
        toggleModal(name)
    }

    const columnsFullService: ExtendedColumn[] = [
        {
            Header: 'Account',
            accessor: 'account',
            type: 'string',
            minWidth: 105,
            sortable: true,
        },
        {
            Header: 'Alert ID',
            accessor: 'alert_id',
            type: 'string',
            minWidth: 100,
            sortable: true,
        },
        {
            Header: 'Source',
            accessor: 'source',
            type: 'string',
            minWidth: 105,
            sortable: true,
        },
        {
            Header: 'Order ID',
            accessor: 'order_id',
            type: 'string',
            minWidth: 125,
            sortable: true,
        },
        {
            Header: 'Trans Amt',
            accessor: 'trans_amount',
            type: 'number',
            minWidth: 120,
            sortable: true,
            Cell: memo((params: ValueFormatterParams) => (
                <span>{amountValueFormatter(params.data.trans_amount)}</span>
            )),
        },
        {
            Header: 'Currency',
            accessor: 'currency',
            type: 'string',
            minWidth: 115,
            sortable: true,
        },
        {
            Header: 'Trans. Date',
            accessor: 'trans_date',
            type: 'string',
            minWidth: 130,
            sortable: true,
        },
        {
            Header: 'CC #',
            accessor: 'cc_num',
            type: 'string',
            minWidth: 140,
            sortable: true,
        },
        {
            Header: 'CC Type',
            accessor: 'cc_type',
            type: 'icon',
            minWidth: 115,
            sortable: true,
            Cell: memo((params: ValueFormatterParams) => (
                <CreditCardProcessorType
                    processor={params.data.cc_type}
                    size="medium"
                    className={
                        'emp-cardTypeIcon'
                    }
                />
            )),
        },
        {
            Header: 'Descriptor',
            accessor: 'descriptor',
            type: 'string',
            minWidth: 230,
            sortable: true,
        },
        {
            Header: 'MID',
            accessor: 'mid',
            type: 'string',
            minWidth: 135,
            sortable: true,
        },
        {
            Header: 'Alert Date',
            accessor: 'alert_date',
            type: 'string',
            minWidth: 155,
            sortable: true,
        },
        {
            Header: 'Outcome',
            accessor: 'outcome',
            type: 'icon',
            sortable: true,
            minWidth: 165,
            maxWidth: 165,
            pinned: 'right',
            lockPinned: true,
            menuTabs: [],
            cellStyle: { textAlign: 'center' },
            Cell: memo((params: ValueFormatterParams) => (
                <OutcomeCell value={params.data.outcome} />
            )),
        },
    ]

    const columnsSelfService: ExtendedColumn[] = [
        {
            Header: 'Status',
            accessor: 'status',
            type: 'icon',
            minWidth: 95,
            sortable: true,
            Cell: memo((params: ValueFormatterParams) => (
                <VerdictType
                    verdict={params.data.outcome}
                    size="small"
                    label={'status'}
                    className={'emp-verdictIcon'}
                />
            )),
        },
        {
            Header: 'Account',
            accessor: 'account',
            type: 'string',
            minWidth: 105,
            sortable: true,
        },
        {
            Header: 'Alert ID',
            accessor: 'alert_id',
            type: 'string',
            minWidth: 100,
            sortable: true,
        },
        {
            Header: 'Source',
            accessor: 'source',
            type: 'string',
            minWidth: 105,
            sortable: true,
        },
        {
            Header: 'Order ID',
            accessor: 'order_id',
            type: 'string',
            minWidth: 125,
            sortable: true,
        },
        {
            Header: 'Trans Amt',
            accessor: 'trans_amount',
            type: 'number',
            minWidth: 120,
            sortable: true,
            Cell: memo((params: ValueFormatterParams) => (
                <span>{amountValueFormatter(params.data.trans_amount)}</span>
            )),
        },
        {
            Header: 'Currency',
            accessor: 'currency',
            type: 'string',
            minWidth: 115,
            sortable: true,
        },
        {
            Header: 'Trans. Date',
            accessor: 'trans_date',
            type: 'string',
            minWidth: 130,
            sortable: true,
        },
        {
            Header: 'CC #',
            accessor: 'cc_num',
            type: 'string',
            minWidth: 140,
            sortable: true,
        },
        {
            Header: 'CC Type',
            accessor: 'cc_type',
            type: 'icon',
            minWidth: 115,
            sortable: true,
            Cell: memo((params: ValueFormatterParams) => (
                <CreditCardProcessorType
                    processor={params.data.cc_type}
                    size="medium"
                    className={
                        'emp-cardTypeIcon'
                    }
                />
            )),
        },
        {
            Header: 'Descriptor',
            accessor: 'descriptor',
            type: 'string',
            minWidth: 230,
            sortable: true,
        },
        {
            Header: 'MID',
            accessor: 'mid',
            type: 'icon',
            minWidth: 140,
            sortable: true,
            Cell: memo((params: ValueFormatterParams) =>
                params.data.mid === '' || params.data.mid === null ? (
                    <SelectMidCell
                        alertsModal={params.data}
                        setCurrentAlertId={setCurrentAlertId}
                        toggleModal={toggleModal}
                    />
                ) : (
                    params.data.mid
                )
            ),
        },
        {
            Header: 'Alert Date',
            accessor: 'alert_date',
            type: 'string',
            minWidth: 155,
            sortable: true,
        },
        {
            Header: 'Expiration Date',
            accessor: 'expiration_date',
            type: 'string',
            minWidth: 140,
            sortable: true,
        },
        {
            Header: 'Completed By',
            accessor: 'completed_date',
            type: 'string',
            minWidth: 140,
            sortable: true,
        },
        {
            Header: 'Outcome',
            accessor: 'outcome',
            type: 'icon',
            sortable: true,
            minWidth: 165,
            // maxWidth: 165,
            // pinned: 'right',
            // lockPinned: true,
            // menuTabs: [],
            cellStyle: {
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            Cell: memo((params: ValueFormatterParams) => (
                <OutcomeCell value={params.data.outcome} />
            )),
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            type: 'icon',
            minWidth: 120,
            maxWidth: 120,
            pinned: 'right',
            lockPinned: true,
            menuTabs: [],
            cellStyle: {
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            Cell: memo((params: ValueFormatterParams) => (
                <ActionsCell
                    alertId={params.data.alert_id}
                    toggleModal={toggleModal}
                    setCurrentAlertId={setCurrentAlertId}
                    value={params.data.outcome}
                    completed={params.data.completed}
                />
            )),
        },
    ]

    const columns = isSelfService ? columnsSelfService : columnsFullService

    const defaultColDef = {
        flex: 1,
        minWidth: 120,
        resizable: true,
    }

    return (
        <div id={'preChargebacksAGAlertsGrid'}>
            <div className={'emp-searchbarContainer'}>
                <Searchbar
                    className={'emp-cssOutlinedInput'}
                    debounceDelay={200}
                    returnValue={handleSearchField}
                    testId={testId}
                />
                <DataGridActions
                    printId="alerts-dg"
                    tableData={data}
                    dataForExportAll={data}
                    columns={columns}
                    testId={testId}
                    dataActionExcel={false}
                    dataActionExportAll={false}
                    dataActionExportAllExcel={true}
                    dataActionPDF={true}
                    dataActionSwitchGrids={true}
                    dataActionColumnFilter={false}
                    onExportAllXlsx={() =>
                        downloadXlsx({
                            export_total: paginationTotal,
                            ...currentPayload,
                        })
                    }
                    onExportAllXlsxLoading={isLoadingDownloadXlsxAll}
                    gridName="alerts"
                    fileName={'Alerts'}
                />
            </div>
            <div ref={tableOuterContainerEL}>
                <AGDataGrid
                    gridRef={gridRef}
                    gridName={'alerts'}
                    fileName={'alerts'}
                    columns={columns}
                    dataSource={dataSource}
                    loading={isLoading}
                    testId={testId}
                    onPageLimitChange={handlePageLimitChange}
                    pageSize={pageSize}
                    page={page}
                    onPageChange={setLocalPage}
                    totalEntries={paginationTotal}
                    defaultColDef={defaultColDef}
                    disableRowClickSelection
                    className={'emp-grid'}
                    fullHeightContainerRef={tableOuterContainerEL}
                />
            </div>
            <AlertHistory
                alertId={currentAlertId}
                open={alertHistoryOpen}
                onClose={closeAndInvalidate}
            />
            <CompleteAlert
                alertId={currentAlertId}
                open={completeOpen}
                onClose={closeAndInvalidate}
                setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
            />
            <MarkAlert
                alertId={currentAlertId}
                open={markOutcomeOpen}
                onClose={closeAndInvalidate}
                alertsOutcomesData={alertsOutcomesData}
                setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
            />
            <SelectMID
                selectedAlertInfo={selectedAlertInfo}
                open={selectMIDOpen}
                onClose={closeAndInvalidate}
                midList={midList}
                setSelectedMid={setSelectedMid}
                setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
            />
        </div>
    )
}

export default AlertsGrid

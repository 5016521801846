import { Tooltip } from '@mui/material'
import React from 'react'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import standardProcessorMap, { Processor } from './data/Processors'

export interface CreditCardProcessorTypeProps {
    className?: string
    id?: string
    processor: string
    size?: 'small' | 'medium' | 'large' | null
    processorMap?: {
        [key: string]: Processor
    }
}

/**
 * Normalize a string for use as a key
 * @param dirty Initial string of whatever casing
 */
const processorNormalization = (dirty: string) => {
    return (dirty && dirty.toLowerCase().replace(/[^a-zA-Z]/g, '')) || ''
}

/**
 * Find a matching processor from a string
 * @param search string
 * @param space
 */
const processorMatch = (
    search: string,
    space: CreditCardProcessorTypeProps['processorMap']
) => {
    const normalized = processorNormalization(search)
    let match = null

    if (space)
        Object.entries(space)?.map(([key, entry]) => {
            if (normalized === processorNormalization(key)) {
                match = entry
            } else if (entry?.aliases?.includes(normalized)) {
                match = entry
            }
            return entry
        })

    return match
}

/**
 * Displaying custom branding for each processor (or a fallback if none match)
 */
const CreditCardProcessorType = ({
    className = '',
    id,
    processor,
    processorMap = standardProcessorMap,
    size = 'small',
}: CreditCardProcessorTypeProps) => {
    const creditCardSize = {
        small: 'emp-creditCardProcessorType-small',
        medium: 'emp-creditCardProcessorType-medium',
        large: 'emp-creditCardProcessorType-large',
    }

    const finalProcessor =
        processorMatch(processor, processorMap) || processorMap.default
    const name =
        (finalProcessor?.name &&
            finalProcessor?.name !== 'Credit Card Processor' &&
            finalProcessor?.name) ||
        processor

    // Display an icon for the default, or an svg or image otherwise
    const brand =
        finalProcessor?.name === 'Credit Card Processor' ? (
            <Icon
                icon={finalProcessor.icon as IconDefinition}
                className={`${
                    size && creditCardSize[size]
                } emp-creditCardProcessorType-icon`}
            />
        ) : (
            <>
                <img
                    className={`${size && creditCardSize[size]}`}
                    src={
                        (finalProcessor?.icon ||
                            finalProcessor?.image) as string
                    }
                    alt={name}
                />
            </>
        )
    return (
        <div
            className={`${'emp-creditCardProcessorType-img'} ${className} ccImage`}
            id={id}
        >
            <Tooltip arrow placement="top" title={name ?? ''}>
                <span>{brand}</span>
            </Tooltip>
        </div>
    )
}

export default CreditCardProcessorType

/**
 * @description Directory for ID Attributes (View).
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    view: {
        divRoot: `view-div-root`,
        divGrid: `view-div-grid`,
        linkContent: `view-link-content`,
        linkBreadcrumb: `view-link-breadcrumb`,
        spanHeaderInterior: `view-span-headerInterior`,
        divChildren: `view-div-children`,
    },

}

export default idDirectory

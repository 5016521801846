import { useState } from 'react'
import CB from 'lib'

export type Download = () => Promise<any>

interface RepresentmentInstance {
    download: Download
    isLoadingRepresentment: boolean
}

export type UseRepresentment = (caseId: number) => RepresentmentInstance

export const useRepresentment: UseRepresentment = (caseId) => {
    const [isLoading, setIsLoading] = useState(false)

    const download: Download = () => {
        setIsLoading(true)
        return CB.documents
            .representment(caseId)
            .then((r) => {
                var a = document.createElement('a')
                a.href = r.link
                a.target = '_blank'
                document.body.appendChild(a)
                a.click()
                a.remove()
                return
            })
            .finally(() => setIsLoading(false))
    }
    return {
        download,
        isLoadingRepresentment: isLoading,
    }
}

import React, { useEffect, useState } from 'react'
import { Tooltip } from '@mui/material'
import {
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Skeleton } from '@mui/material'
import { clientTheme } from 'theme-exports'
import Icon from 'components/Icon'
import idDirectory from './idAttributes'

export interface PaginationGridProps {
    paginationOptions: number[]
    pageCount: number
    gotoPage: (page: number) => void
    setPageSize: (size: number) => void
    page: number
    totalEntries: number
    tableState: {
        pageSize: number
        rowsLength: number
    }
    loading: boolean
    testId: string
    config?: {
        pageBlockLength: number
    }
    // Use if resetting pagination to page 1 and NOT using SWR
    resetPagination?: boolean
    handleResetPagination?: (val: boolean) => void
    clearSelectedRows?: () => void
    skeletonWidth?: string
    enablePaginationRefreshBtn?: boolean
    handlePaginationRefreshBtn?: () => void
    loadingRefreshBtn?: boolean
}

/**
 * Pagination is used to paginate data on 'react-table' data grid.
 */
const PaginationGrid = ({
    paginationOptions,
    pageCount,
    gotoPage,
    setPageSize,
    page: currentPage,
    totalEntries,
    tableState,
    loading,
    testId,
    config = {
        pageBlockLength: 6,
    },
    resetPagination = false,
    handleResetPagination = () => {},
    clearSelectedRows = () => {},
    skeletonWidth = '10px',
    enablePaginationRefreshBtn = true,
    handlePaginationRefreshBtn = () => {},
    loadingRefreshBtn = false,
}: PaginationGridProps) => {
    const { pageBlockLength } = config
    const tooltipDelayTime = 2_000

    const [inputPage, setInputPage] = useState<string>(
        currentPage ? String(currentPage) : '1'
    )
    const [pageIndexRef, setPageIndexRef] = useState({
        start: 0,
        end: pageBlockLength,
        pageActive: 1,
    })

    const pageCountCeiling = Math.ceil(pageCount)
    const { pageSize, rowsLength } = tableState
    const pageActive = pageIndexRef.pageActive
    const showingStartValue =
        pageSize * pageIndexRef.pageActive - (pageSize - 1)
    const showingEndValue =
        pageSize * pageActive <= totalEntries
            ? pageSize * pageActive
            : totalEntries

    useEffect(() => {
        if (
            (pageActive > 1 && rowsLength === 0 && totalEntries) ||
            resetPagination
        ) {
            setPageIndexRef(() => ({
                start: 0,
                end: pageBlockLength,
                pageActive: 1,
            }))
            gotoPage(1)
            handleResetPagination(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageActive, rowsLength, totalEntries, gotoPage, pageBlockLength])

    const handlePageChange = (newPage: number) => {
        setInputPage(String(newPage))
        gotoPage(newPage)
        setPageIndexRef((prev) => ({
            ...prev,
            pageActive: newPage,
        }))
        clearSelectedRows()
    }

    const handleInputPageChange = (e: any) => {
        const value = Number(e.target.value)
        if (value <= pageCountCeiling) {
            gotoPage(value)
            setPageIndexRef((prev) => ({
                ...prev,
                pageActive: value,
            }))
            clearSelectedRows()
        } else {
            setInputPage(String(currentPage))
        }
    }

    useEffect(() => {
        currentPage && setInputPage(String(currentPage))
    }, [currentPage, pageSize])

    return (
        <div
            className={'emp-paginationGrid-root'}
            id={`${idDirectory.divRoot}-${testId}`}
            style={{ fontSize: clientTheme.typography.body1.fontSize }}
        >
            <div
                className={'emp-paginationGrid-infoContainer'}
                id={`${idDirectory.divPageInfo}-${testId}`}
            >
                <div
                    className={'emp-paginationGrid-resultsDisplay'}
                    id={`${idDirectory.divPageResults}-${testId}`}
                >
                    Displaying
                    <span className={'emp-paginationGrid-resultsText'}>
                        {loading ? (
                            <Skeleton variant="text" width={skeletonWidth} />
                        ) : (
                            showingStartValue.toLocaleString('en-US')
                        )}
                    </span>
                    -
                    <span className={'emp-paginationGrid-resultsText'}>
                        {loading ? (
                            <Skeleton variant="text" width={skeletonWidth} />
                        ) : (
                            showingEndValue.toLocaleString('en-US')
                        )}
                    </span>
                    of
                    <span className={'emp-paginationGrid-resultsTextLast'}>
                        {!loading ? (
                            totalEntries.toLocaleString('en-US')
                        ) : (
                            <Skeleton variant="text" width={skeletonWidth} />
                        )}
                    </span>
                </div>
                <div
                    className={'emp-paginationGrid-sizeDisplay'}
                    id={`${idDirectory.divPageSize}-${testId}`}
                    style={{ fontSize: clientTheme.typography.body1.fontSize }}
                >
                    Page Size
                    <select
                        value={pageSize}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            setPageSize(Number(e.target.value))
                            setPageIndexRef((prev) => ({
                                ...prev,
                                pageActive: 1,
                            }))
                        }}
                    >
                        {paginationOptions.map((option: number) => {
                            return (
                                <option
                                    key={`paginate-${option}`}
                                    value={option}
                                >
                                    {option}
                                </option>
                            )
                        })}
                    </select>
                </div>
            </div>
            <div
                className={'emp-paginationGrid-navContainer'}
                id={`${idDirectory.divPageNav}-${testId}`}
            >
                <div
                    className={'emp-paginationGrid-navBackwardControls'}
                    id={`${idDirectory.divPageNavBackward}-${testId}`}
                >
                    <Tooltip
                        arrow
                        placement="top"
                        title={'First Page'}
                        enterNextDelay={tooltipDelayTime}
                        enterDelay={tooltipDelayTime}
                    >
                        <div
                            className={'emp-paginationGrid-tooltipContainer'}
                        >
                            <FontAwesomeIcon
                                icon={faAngleDoubleLeft}
                                className={
                                    currentPage === 1 || !currentPage
                                        ? 'emp-paginationGrid-inactiveIcon'
                                        : 'emp-paginationGrid-activeIcon'
                                }
                                onClick={() => handlePageChange(1)}
                                id={`${idDirectory.btnFirst}-${testId}`}
                            />
                        </div>
                    </Tooltip>
                    <Tooltip
                        arrow
                        placement="top"
                        title={'Previous Page'}
                        enterNextDelay={tooltipDelayTime}
                        enterDelay={tooltipDelayTime}
                    >
                        <div
                            className={'emp-paginationGrid-tooltipContainer'}
                        >
                            <FontAwesomeIcon
                                icon={faAngleLeft}
                                className={
                                    currentPage === 1 || !currentPage
                                        ? 'emp-paginationGrid-inactiveIcon'
                                        : 'emp-paginationGrid-activeIcon'
                                }
                                onClick={() => {
                                    if (currentPage && currentPage !== 1) {
                                        handlePageChange(currentPage - 1)
                                    }
                                }}
                                id={`${idDirectory.btnPrevious}-${testId}`}
                            />
                        </div>
                    </Tooltip>
                </div>
                <div
                    className={'emp-paginationGrid-navDisplayContainer'}
                    id={`${idDirectory.divPageNavDisplay}-${testId}`}
                >
                    Page{' '}
                    <Tooltip
                        arrow
                        placement="top"
                        title={`Page ${currentPage}`}
                        enterNextDelay={tooltipDelayTime}
                        enterDelay={tooltipDelayTime}
                    >
                        <div>
                            <input
                                className={'emp-paginationGrid-input'}
                                onChange={(e) => {
                                    const value = e.target.value
                                    // checking to make sure only number are types in page input box
                                    if (Number(value) || value === '')
                                        setInputPage(e.target.value)
                                }}
                                onBlur={(e) =>
                                    inputPage.length
                                        ? handleInputPageChange(e)
                                        : setInputPage(String(currentPage))
                                }
                                onKeyPress={(e) => {
                                    if (e.key.toLowerCase() === 'enter') {
                                        inputPage.length
                                            ? handleInputPageChange(e)
                                            : setInputPage(String(currentPage))
                                    }
                                }}
                                value={inputPage}
                                style={{
                                    width: `${
                                        20 +
                                        (inputPage.length > 2
                                            ? inputPage.length * 7
                                            : 5)
                                    }px`,
                                }}
                            />
                        </div>
                    </Tooltip>
                    of{' '}
                    <span
                        style={{ marginLeft: 6 }}
                        className={'emp-paginationGrid-boldText'}
                    >
                        {loading ? (
                            <Skeleton variant="text" width={skeletonWidth} />
                        ) : (
                            pageCountCeiling.toLocaleString('en-US')
                        )}
                    </span>
                </div>
                <div
                    className={`emp-paginationGrid-navForwardControls ${
                        !enablePaginationRefreshBtn &&
                        'emp-paginationGrid-navForwardControlsNoRefreshBtn'
                    }`}
                    id={`${idDirectory.divPageNavForward}-${testId}`}
                >
                    <Tooltip
                        arrow
                        placement="top"
                        title={'Next Page'}
                        enterNextDelay={tooltipDelayTime}
                        enterDelay={tooltipDelayTime}
                    >
                        <div
                            className={'emp-paginationGrid-tooltipContainer'}
                        >
                            <FontAwesomeIcon
                                icon={faAngleRight}
                                className={
                                    currentPage === pageCountCeiling ||
                                    !pageCountCeiling
                                        ? 'emp-paginationGrid-inactiveIcon'
                                        : 'emp-paginationGrid-activeIcon'
                                }
                                onClick={() => {
                                    if (
                                        currentPage &&
                                        currentPage !== pageCountCeiling
                                    ) {
                                        handlePageChange(currentPage + 1)
                                    }
                                }}
                                id={`${idDirectory.btnNext}-${testId}`}
                            />
                        </div>
                    </Tooltip>
                    <Tooltip
                        arrow
                        placement="top"
                        title={'Last Page'}
                        enterNextDelay={tooltipDelayTime}
                        enterDelay={tooltipDelayTime}
                    >
                        <div
                            className={'emp-paginationGrid-tooltipContainer'}
                        >
                            <FontAwesomeIcon
                                icon={faAngleDoubleRight}
                                className={`${
                                    currentPage === pageCountCeiling ||
                                    !pageCountCeiling
                                        ? 'emp-paginationGrid-inactiveIcon'
                                        : 'emp-paginationGrid-activeIcon'
                                }`}
                                onClick={() => {
                                    if (!pageCountCeiling) return
                                    handlePageChange(pageCountCeiling)
                                }}
                                id={`${idDirectory.btnLast}-${testId}`}
                            />
                        </div>
                    </Tooltip>
                </div>
                {enablePaginationRefreshBtn && (
                    <div
                        className={'emp-paginationGrid-navRefreshContainer'}
                        id={`${idDirectory.divPageNavRefresh}-${testId}`}
                    >
                        <Tooltip
                            arrow
                            placement="top"
                            title={'Refresh'}
                            enterNextDelay={tooltipDelayTime}
                            enterDelay={tooltipDelayTime}
                        >
                            <div>
                                <Icon
                                    className={`fas fa-circle-arrow ${
                                        loading
                                            ? 'emp-paginationGrid-inactiveIcon'
                                            : 'emp-paginationGrid-activeIcon'
                                    } ${loadingRefreshBtn && 'fa-spin'}`}
                                    onClick={handlePaginationRefreshBtn}
                                    id={`${idDirectory.btnRefresh}-${testId}`}
                                />
                            </div>
                        </Tooltip>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PaginationGrid

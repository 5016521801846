import React, { useState } from 'react'
import { IconButton } from '@mui/material'
import { faTimes, faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { deliveryStatuses } from './VisaCE.vm'

interface VisaCEUndisputedTransactionDetailsProps {
    currencies: any
    undisputedCases: any
    deleteUndisputedCases: (value: string) => void
}

const VisaCEUndisputedTransactionDetails = ({
    currencies = [],
    undisputedCases,
    deleteUndisputedCases,
} : VisaCEUndisputedTransactionDetailsProps) => {
    const [detailsExpanded, setDetailsExpanded] = useState<boolean>(false)
    let currencyDisplay = '?'

    try {
        currencyDisplay = currencies.find((obj: any) => obj.value === undisputedCases.historical_tran_currency).name
    } catch(e) {}

    return (
        <div className={`emp-visaCEUndisputedTransactionDetails-transactionDetailsRoot`}>
            <div className={`emp-visaCEUndisputedTransactionDetails-transactionDetailsGridContainer top`}>
                <div>
                    <div>&nbsp;</div>
                    <div>
                        <IconButton
                            aria-haspopup="true"
                            onClick={() => {
                                deleteUndisputedCases(undisputedCases.historical_transaction_id)
                            }}
                            size="small"
                            color="primary"
                        >
                            <Icon icon={faTimes} />
                        </IconButton>
                    </div>
                </div>
                <div>
                    <div>ARN/Transaction ID</div>
                    <div>{undisputedCases.historical_arn_or_transaction_id}</div>
                </div>
                <div>
                    <div>Transaction Amount</div>
                    <div>{(Math.round(undisputedCases.historical_tran_amount * 100) / 100).toFixed(2)}</div>
                </div>
                <div>
                    <div>Transaction Currency</div>
                    <div>{currencyDisplay}</div>
                </div>
                <div>
                    <div>Transaction Date</div>
                    <div>{undisputedCases.historical_tran_date}</div>
                </div>
                <div>
                    <div>Order ID</div>
                    <div>{undisputedCases.historical_order_id}</div>
                </div>
                <div>
                    <div>&nbsp;</div>
                    <div>
                        <IconButton
                            aria-haspopup="true"
                            onClick={() => {
                                setDetailsExpanded(!detailsExpanded)
                            }}
                            size="small"
                            color="secondary"
                        >
                            <Icon icon={detailsExpanded ? faAngleUp : faAngleDown} />
                        </IconButton>
                    </div>
                </div>
            </div>
            {detailsExpanded &&
                <>
                    <div className={`emp-visaCEUndisputedTransactionDetails-transactionDetailsGridContainer detail`}>
                        <div>
                            <div>Nerchandise or Services</div>
                            <div>{undisputedCases.merchandise_or_services}</div>
                        </div>
                        <div>
                            <div>Delivery Status</div>
                            <div>{deliveryStatuses.find((obj) => obj.id === undisputedCases.shipping_delivery_status)?.name ?? ''}</div>
                        </div>
                        <div>
                            <div>Tracking Number</div>
                            <div>{undisputedCases.tracking_information}</div>
                        </div>
                    </div>
                    <div className={`emp-visaCEUndisputedTransactionDetails-transactionDetailsGridContainer detail`}>
                        <div>
                            <div>Merchandise or Services Description</div>
                            <div>{undisputedCases.merchandise_or_services_description}</div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default VisaCEUndisputedTransactionDetails

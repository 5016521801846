import axios from 'axios'

type TGetStackConfigSlim = () => Promise<any>

interface IConfigApi {
    getStackConfigSlim: TGetStackConfigSlim
}

class ConfigApi implements IConfigApi {
    getStackConfigSlim() {
        return axios
            .get(`/config/cfg/stack_config_be/slim`)
            .then(({ data }) => data)
    }
}

export default new ConfigApi()

import { useEffect, useState } from 'react'
import axios from 'axios'
import useIsMounted from 'hooks/utils/useIsMounted'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import { FilterValues } from './Alerts.vm'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

export type AlertsFilters = 'descriptor'

interface UseFilterAlerts {
    descriptorOptions: { id: number; name: string }[]
    descriptorSearchValue: string
    setDescriptorSearchValue: (value: string) => void
    setDateRange: (date: string) => void
    setFilters: (
        accessor: AlertsFilters,
        filterValue: string | undefined
    ) => void
}

export const useFilterAlerts = (
    setFilterValues: (value: FilterValues) => void,
    filterValues: FilterValues,
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void,
    setAlertSnackbarMainOpen: (status: boolean) => void
): UseFilterAlerts => {
    const [descriptorOptions, setDescriptorOptions] = useState<
        { id: number; name: string }[]
    >([{ id: 0, name: 'All Descriptors' }])
    const [descriptorSearchValue, setDescriptorSearchValue] = useState<string>(
        ''
    )

    const uiState = useUiStateContext()

    const { isMounted } = useIsMounted()

    useEffect(() => {
        const getDescriptorOptions = async () => {
            const descriptors = axios.get('/cm/svc/descriptors', {
                params: {
                    merchant_id:
                        uiState.activeMerchant?.id ??
                        uiState.whoami?.merchant?.id,
                    search: descriptorSearchValue,
                },
            })

            return await descriptors
        }
        // only GETS descriptiors if the uiState has an active merchant id
        if (uiState.activeMerchant?.id || uiState.whoami?.merchant?.id) {
            getDescriptorOptions()
                .then(({ data: descriptors }) => {
                    if (isMounted)
                        setDescriptorOptions([
                            { id: 0, name: 'All Descriptors' },
                            ...descriptors.data.map(
                                (descriptor: string, index: number) => ({
                                    id: index++,
                                    name: descriptor,
                                })
                            ),
                        ])
                })
                .catch(() => {
                    setAlertSnackbarMainProps({
                        title: 'Error',
                        message:
                            'The was a problem fetching the descriptor options.',
                        intent: 'error',
                    })
                    setAlertSnackbarMainOpen(true)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isMounted,
        uiState.activeMerchant,
        uiState.whoami,
        descriptorSearchValue,
    ])

    const setFilters = (
        accessor: AlertsFilters,
        filterValue: string | undefined
    ) => {
        setFilterValues({ ...filterValues, [accessor]: filterValue })
    }

    const setDateRange = (date: string) => {
        setFilterValues({ ...filterValues, date_range: date })
    }

    return {
        descriptorOptions,
        descriptorSearchValue,
        setDescriptorSearchValue,
        setDateRange,
        setFilters,
    }
}

import React, { useEffect, useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import PieChart from 'components/PieChart/PieChart'
import { IReportFilter } from 'views/Reports'
import { clientTheme } from 'theme-exports'
/**
 * @name ByWinLoss
 * @desc Render 'Win/Loss'
 * @filterValues IReportsFilter
 */
const ByWinLoss = ({
    filterValues,
}: {
    filterValues: IReportFilter
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ name: string; value: number }[]>([])

    useEffect(() => {
        setLoading(true)
        ReportsApi.getByWinLoss(filterValues).then((data) => {
            setLoading(false)
            setData(data)
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    const colorPalette = clientTheme.charts.pieCharts.byWinLossPieChart

    return (
        <PieChart
            chartTitle="Win/Loss"
            data={data}
            loading={loading}
            colorPalette={colorPalette}
            showLegend={true}
            testId="byWinLoss"
        />
    )
}

export default ByWinLoss

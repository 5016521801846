/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-key */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Collapse,
    IconButton,
    Tooltip,
} from '@mui/material'
import { Skeleton } from '@material-ui/lab'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Row } from 'react-table'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { ErrorBoundary, DataDropdownRows, DataDropdownTable } from 'components'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import { clientTheme } from 'theme-exports'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'

interface ExpandableRowProps {
    rowProps: any
    row: Row<{
        original?: Record<string, string | number>
    }>
    loading: boolean
    expandable: boolean
    /** Determines if table row should be striped */
    isRowZebraStriped: boolean
    selectedRow: string[]
    rowId: number
    overflowColumns: any
    enableDataDropdown: Boolean
    dropdownData: any
    hiddenColumns: string[]
    noRowSelectionBox: boolean
    setAlertSnackbarOpen: (value: boolean) => void
    setAlertSnackbarProps: (value: alertSnackbarContentProps) => void
}

/**
 *
 * Table row that offers and expandable drawer for showing additional data.
 *
 */
 export const ExpandableRow = React.memo(({
    rowProps,
    row,
    loading = false,
    expandable,
    isRowZebraStriped,
    selectedRow,
    rowId,
    overflowColumns = [],
    enableDataDropdown,
    dropdownData,
    hiddenColumns,
    noRowSelectionBox,
    setAlertSnackbarOpen,
    setAlertSnackbarProps,
}: ExpandableRowProps): JSX.Element => {
    const {
        ZEBRA_STRIPED_ROWS,
        ENABLE_OVERFLOW_COLUMNS
    } = useFeatureToggle('DATAGRID')
    const [open, setOpen] = useState(false)
    const [openDropdownData, setOpenDropdownData] = useState(false)
    const [showOverFlowTextTooltip, setOverFlowTextTooltip] = useState(false)
    const [expandColumnCells, setExpandColumnCells] = useState<string[]>([])

    const { original = {} } = row

    const isEllipsisActive = (event: any) =>
        event.offsetWidth > event.scrollWidth
            ? setOverFlowTextTooltip(true)
            : setOverFlowTextTooltip(false)

    const dataDropdownColumns: string[] = Object.keys(
        dropdownData?.columns ?? {}
    )
    const enableDataDropdownIcon: boolean = dataDropdownColumns.some(
        (col: string) => !hiddenColumns.includes(col)
    )

    const handleCopyCell = (cellText: string | number | null | undefined) => {
        if (cellText || cellText === 0) {
            setAlertSnackbarProps({
                title: 'Success',
                message: `'${cellText}' copied.`,
                intent: 'success',
            })
        } else {
            setAlertSnackbarProps({
                title: 'Copy to clipboard',
                message: `No data to copy.`,
                intent: 'error',
            })
        }
        setAlertSnackbarOpen(true)
    }
   

    return (
        // eslint-disable-next-line react/jsx-fragments
        <React.Fragment>
            <TableRow
                {...rowProps}
                className={`${
                    isRowZebraStriped && ZEBRA_STRIPED_ROWS.enabled
                        ? 'emp-expandableRow-zebraStripe'
                        : ''
                } ${'emp-expandableRow-styledTableRow'} 
                ${
                    selectedRow.includes(rowId.toString())
                        ? 'emp-expandableRow-selectionStripe'
                        : ''
                }
                ${
                    open ? 'emp-expandableRow-isOpen' : ''
                }`}
            >
                {/* Special column used for the expansion open/close control */}
                {expandable && (
                    <TableCell
                        className={'emp-expandableRow-tableBodyCell'}
                        style={{
                            fontSize:
                                clientTheme.typography.dataGrid.tableBody
                                    .fontSize,
                            color:
                                clientTheme.typography.dataGrid.tableBody.color,
                        }}
                        classes={{
                            root: `${
                                selectedRow.includes(rowId.toString())
                                    ? 'emp-expandableRow-tableBodyCellSelected'
                                    : ''
                            }`,
                        }}
                    >
                        {overflowColumns.length === 0 ||
                        overflowColumns[0] === undefined ? (
                            <div
                                aria-label="expand row"
                                className={'emp-expandableRow-iconHover'}
                            />
                        ) : (
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={(): void => setOpen(!open)}
                                className={'emp-expandableRow-iconHover'}
                            >
                                {open ? (
                                    <Icon icon={faAngleDown} />
                                ) : (
                                    <Icon icon={faAngleRight} />
                                )}
                            </IconButton>
                        )}
                    </TableCell>
                )}
                {enableDataDropdown && (
                    <TableCell
                        className={'emp-expandableRow-tableBodyCell'}
                        style={{
                            fontSize:
                                clientTheme.typography.dataGrid.tableBody
                                    .fontSize,
                            color:
                                clientTheme.typography.dataGrid.tableBody.color,
                        }}
                        classes={{
                            root: `${
                                selectedRow.includes(rowId.toString())
                                    ? 'emp-expandableRow-tableBodyCellSelected'
                                    : ''
                            }`,
                        }}
                    >
                        {enableDataDropdownIcon || expandable ? (
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                className={'emp-expandableRow-iconHover'}
                                onClick={(): void =>
                                    setOpenDropdownData(!openDropdownData)
                                }
                            >
                                {openDropdownData ? (
                                    <Icon icon={faAngleDown} />
                                ) : (
                                    <Icon icon={faAngleRight} />
                                )}
                            </IconButton>
                        ) : (
                            <div
                                aria-label="expand row"
                                className={'emp-expandableRow-iconHover'}
                            />
                        )}
                    </TableCell>
                )}
                {row.cells.map((cell: any, i: any) => {
                    // The central cells
                    const { style, ...rowCellRest } = cell.getCellProps()
                    const column = cell.column as any
                    if (overflowColumns.includes(cell.column.id)) return null
                    if (column?.ellipsifyText) {
                        return (
                            <TableCell
                                className={'emp-expandableRow-tableBodyCell'}
                                classes={{
                                    root: `${
                                        selectedRow.includes(rowId.toString())
                                            ? 'emp-expandableRow-tableBodyCellSelected'
                                            : ''
                                    }`,
                                }}
                                {...rowCellRest}
                                onMouseEnter={(e: any) => isEllipsisActive(e)}
                                onMouseLeave={() =>
                                    setOverFlowTextTooltip(false)
                                }
                                style={{
                                    maxWidth: column.width,
                                    fontSize:
                                        clientTheme.typography.dataGrid
                                            .tableBody.fontSize,
                                    color:
                                        clientTheme.typography.dataGrid
                                            .tableBody.color,
                                }}
                                key={`key-cell-${i}`}
                            >
                                <ErrorBoundary area="the data cell">
                                    {loading ? (
                                        <Skeleton />
                                    ) : showOverFlowTextTooltip ? (
                                        <Tooltip
                                            key={`tooltip-${i}`}
                                            title={cell.render('Cell') || ''}
                                            placement="top"
                                            arrow
                                            classes={{
                                                tooltip:
                                                    'emp-expandableRow-tooltipPopper',
                                                arrow:
                                                    'emp-expandableRow-tooltipPopperArrow',
                                            }}
                                        >
                                            <div>{cell.render('Cell')}</div>
                                        </Tooltip>
                                    ) : (
                                        <div
                                            className={
                                                'emp-expandableRow-hoverWithExpand'
                                            }
                                            style={{
                                                textAlign:
                                                    column.columnAlign ??
                                                    'center',
                                                position: 'relative',
                                            }}
                                        >
                                            {!expandColumnCells.includes(
                                                column.id
                                            ) ? (
                                                <div
                                                    className={'ellipsify-text'}
                                                >
                                                    <Tooltip
                                                        key={`tooltip-${i}`}
                                                        title={cell.value || ''}
                                                        placement="top"
                                                        arrow
                                                        classes={{
                                                            tooltip:
                                                                'emp-expandableRow-tooltipPopper',
                                                            arrow:
                                                                'emp-expandableRow-tooltipPopperArrow',
                                                        }}
                                                    >
                                                        <div
                                                            onClick={() =>
                                                                setExpandColumnCells(
                                                                    [
                                                                        ...expandColumnCells,
                                                                        column.id,
                                                                    ]
                                                                )
                                                            }
                                                        >
                                                            {cell.render(
                                                                'Cell'
                                                            )}
                                                        </div>
                                                    </Tooltip>

                                                    <div
                                                        className={`copy-icon-container`}
                                                    >
                                                        <CopyToClipboard
                                                            text={
                                                                cell.value || ''
                                                            }
                                                            onCopy={() =>
                                                                handleCopyCell(
                                                                    cell.value
                                                                )
                                                            }
                                                        >
                                                            <Tooltip
                                                                title={'Copy'}
                                                                placement="top"
                                                                arrow
                                                                classes={{
                                                                    tooltip:
                                                                        'emp-expandableRow-tooltipPopper',
                                                                    arrow:
                                                                        'emp-expandableRow-tooltipPopperArrow',
                                                                }}
                                                            >
                                                                <div>
                                                                    <Icon
                                                                        icon={
                                                                            faCopy
                                                                        }
                                                                        className={
                                                                            'emp-expandableRow-copyIcon'
                                                                        }
                                                                    />
                                                                </div>
                                                            </Tooltip>
                                                        </CopyToClipboard>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    className={'expanded-text'}
                                                >
                                                    <div
                                                        onClick={() =>
                                                            setExpandColumnCells(
                                                                expandColumnCells.filter(
                                                                    (col) =>
                                                                        col !==
                                                                        column.id
                                                                )
                                                            )
                                                        }
                                                    >
                                                        {cell.render('Cell')}
                                                    </div>
                                                    <div
                                                        className={`copy-icon-container`}
                                                    >
                                                        <CopyToClipboard
                                                            text={
                                                                cell.value || ''
                                                            }
                                                            onCopy={() =>
                                                                handleCopyCell(
                                                                    cell.value
                                                                )
                                                            }
                                                        >
                                                            <Tooltip
                                                                title={'Copy'}
                                                                placement="top"
                                                                arrow
                                                                classes={{
                                                                    tooltip:
                                                                        'emp-expandableRow-tooltipPopper',
                                                                    arrow:
                                                                        'emp-expandableRow-tooltipPopperArrow',
                                                                }}
                                                            >
                                                                <div>
                                                                    <Icon
                                                                        icon={
                                                                            faCopy
                                                                        }
                                                                        className={
                                                                            'emp-expandableRow-copyIcon'
                                                                        }
                                                                    />
                                                                </div>
                                                            </Tooltip>
                                                        </CopyToClipboard>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </ErrorBoundary>
                            </TableCell>
                        )
                    }

                    return (
                        <TableCell
                            align="center"
                            className={'emp-expandableRow-tableBodyCell'}
                            classes={{
                                root: `${
                                    selectedRow.includes(rowId.toString())
                                        ? 'emp-expandableRow-tableBodyCellSelected'
                                        : ''
                                }`,
                            }}
                            {...rowCellRest}
                            onMouseEnter={(e: any) => isEllipsisActive(e)}
                            onMouseLeave={() => setOverFlowTextTooltip(false)}
                            style={{
                                textAlign: cell.column.columnAlign,
                                fontSize:
                                    clientTheme.typography.dataGrid.tableBody
                                        .fontSize,
                                color:
                                    clientTheme.typography.dataGrid.tableBody
                                        .color,
                            }}
                        >
                            <ErrorBoundary area="the data cell">
                                {loading ? (
                                    <Skeleton />
                                ) : showOverFlowTextTooltip ? (
                                    <Tooltip
                                        key={`tooltip-${i}`}
                                        title={cell.render('Cell') || ''}
                                        placement="top"
                                        arrow
                                        classes={{
                                            tooltip:
                                                'emp-expandableRow-tooltipPopper',
                                            arrow:
                                                'emp-expandableRow-tooltipPopperArrow',
                                        }}
                                    >
                                        <div>{cell.render('Cell')}</div>
                                    </Tooltip>
                                ) : (
                                    <div>{cell.render('Cell')}</div>
                                )}
                            </ErrorBoundary>
                        </TableCell>
                    )
                })}
            </TableRow>
            {/* Expansion area in a table row, displays a row of data in a column */}
            {expandable && (
                <TableRow>
                    <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={Object.values(original).length}
                    >
                        <ErrorBoundary area="the expandable data area">
                            <Collapse className='emp-expandableRow-collapseContainer' in={open} timeout="auto" unmountOnExit>
                                {ENABLE_OVERFLOW_COLUMNS.enabled ? (
                                    <div
                                        className={
                                            'emp-expandableRow-expandableGridContainer'
                                        }
                                    >
                                        {row.cells.map((cell, i) => {
                                            const {
                                                style,
                                            } = cell.getCellProps()
                                            if (
                                                !overflowColumns.includes(
                                                    cell.column.id
                                                )
                                            )
                                                return null
                                            return (
                                                <div
                                                    key={`key-expandable-row-${i}`}
                                                >
                                                    <div
                                                        className={
                                                            'emp-expandableRow-expandableRowHeader'
                                                        }
                                                        style={{
                                                            fontSize:
                                                                clientTheme
                                                                    .typography
                                                                    .dataGrid
                                                                    .tableExpandable
                                                                    .fontSize,
                                                        }}
                                                    >
                                                        {typeof cell.column
                                                            .Header ===
                                                        'string' ? (
                                                            <>
                                                                {
                                                                    cell.column
                                                                        .Header
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                {
                                                                cell?.column
                                                                    ?.Header
                                                                    // @ts-ignore
                                                                    ?.props
                                                                    ?.value ||
                                                                    ''}
                                                            </>
                                                        )}:
                                                    </div>
                                                    <div>
                                                        <ErrorBoundary area="the data cell">
                                                            {loading ? (
                                                                <Skeleton />
                                                            ) : showOverFlowTextTooltip ? (
                                                                <Tooltip
                                                                    key={`tooltip-${i}`}
                                                                    title={
                                                                        cell.render(
                                                                            'Cell'
                                                                        ) || ''
                                                                    }
                                                                    placement="top"
                                                                    arrow
                                                                    classes={{
                                                                        tooltip:
                                                                            'emp-expandableRow-tooltipPopper',
                                                                        arrow:
                                                                            'emp-expandableRow-tooltipPopperArrow',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            fontSize:
                                                                                clientTheme
                                                                                    .typography
                                                                                    .dataGrid
                                                                                    .tableExpandable
                                                                                    .fontSize,
                                                                        }}
                                                                    >
                                                                        {cell.render(
                                                                            'Cell'
                                                                        )}
                                                                    </div>
                                                                </Tooltip>
                                                            ) : (
                                                                <div
                                                                    style={{
                                                                        fontSize:
                                                                            clientTheme
                                                                                .typography
                                                                                .dataGrid
                                                                                .tableExpandable
                                                                                .fontSize,
                                                                    }}
                                                                >
                                                                    {cell.render(
                                                                        'Cell'
                                                                    )}
                                                                </div>
                                                            )}
                                                        </ErrorBoundary>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                ) : (
                                    <TableContainer>
                                        <Table size="small">
                                            <TableBody>
                                                {row.cells.map((cell, i) => {
                                                    const {
                                                        style,
                                                        ...rowCellRest
                                                    } = cell.getCellProps()
                                                    if (
                                                        !overflowColumns.includes(
                                                            cell.column.id
                                                        )
                                                    )
                                                        return null
                                                    return (
                                                        <TableRow
                                                            key={`overflow-cell-${i}`}
                                                        >
                                                            <TableCell
                                                                className={`${'emp-expandableRow-extraDataTableHeader'} ${'emp-expandableRow-maxWidth'}`}
                                                                variant="head"
                                                            >
                                                                <div
                                                                    className={`${'emp-expandableRow-expandableTableRow '} ${'emp-expandableRow-expandableRowHeader'}`}
                                                                    style={{
                                                                        fontSize:
                                                                            clientTheme
                                                                                .typography
                                                                                .dataGrid
                                                                                .tableExpandable
                                                                                .fontSize,
                                                                    }}
                                                                >
                                                                    {typeof cell
                                                                        .column
                                                                        .Header ===
                                                                    'string' ? (
                                                                        <>
                                                                            {
                                                                                cell
                                                                                    .column
                                                                                    .Header
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                            cell
                                                                                ?.column
                                                                                ?.Header
                                                                                // @ts-ignore
                                                                                ?.props
                                                                                ?.value ||
                                                                                ''}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                className={
                                                                    'emp-expandableRow-tableBodyOverflowingRow'
                                                                }
                                                                style={{
                                                                    fontSize:
                                                                        clientTheme
                                                                            .typography
                                                                            .dataGrid
                                                                            .tableExpandable
                                                                            .fontSize,
                                                                    color:
                                                                        clientTheme
                                                                            .typography
                                                                            .dataGrid
                                                                            .tableBody
                                                                            .color,
                                                                }}
                                                                {...rowCellRest}
                                                            >
                                                                <ErrorBoundary area="the data cell">
                                                                    {loading ? (
                                                                        <Skeleton />
                                                                    ) : showOverFlowTextTooltip ? (
                                                                        <Tooltip
                                                                            key={`tooltip-${i}`}
                                                                            title={
                                                                                cell.render(
                                                                                    'Cell'
                                                                                ) ||
                                                                                ''
                                                                            }
                                                                            placement="top"
                                                                            arrow
                                                                            classes={{
                                                                                tooltip:
                                                                                    'emp-expandableRow-tooltipPopper',
                                                                                arrow:
                                                                                    'emp-expandableRow-tooltipPopperArrow',
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                {cell.render(
                                                                                    'Cell'
                                                                                )}
                                                                            </div>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <div>
                                                                            {cell.render(
                                                                                'Cell'
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </ErrorBoundary>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Collapse>
                        </ErrorBoundary>
                        {enableDataDropdown && (
                            <Collapse
                                in={openDropdownData}
                                timeout="auto"
                                unmountOnExit
                            >
                                <DataDropdownTable
                                    data={original}
                                    dropdownData={dropdownData}
                                />
                            </Collapse>
                        )}
                    </TableCell>
                </TableRow>
            )}
            {!expandable &&
                enableDataDropdown &&
                enableDataDropdownIcon &&
                openDropdownData && (
                    <DataDropdownRows
                        data={row}
                        dropdownData={dropdownData}
                        overflowColumns={overflowColumns}
                        noRowSelectionBox={noRowSelectionBox}
                    />
                )}
        </React.Fragment>
    )
})


// Strips object properties with undefined values
const parseParams = (params: any): Record<string, string> => {
    return Object.keys(params).reduce((acc, key) => {
        if (Boolean(params[key])) {
            acc[key] = params[key]
        }
        return acc
    }, {} as Record<string, string>)
}

// Takes a url and params and returns a url with the params appended to the end
export const useURL = (
    url: string,
    params: Record<string, string | undefined | number> = {}
): string => {
    return `${url}?${new URLSearchParams(parseParams(params)).toString()}`
}

export default useURL

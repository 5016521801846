/**
 * @description Directory for ID Attributes on MerchantEdit.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    labelHeader: `merchantDetails-label-header`,
    textField: `merchantDetails-text-field`,
    statusField: `merchantDetails-status-field`,
    switchField: `merchantDetails-switch-field`,
    radioField: `merchantDetails-radio-field`,
}

export default idDirectory

import React from 'react'

interface IIconProps {
    className: string
    id?: string
    onClick?: () => void
    iconColor?: string
}
const Icon = ({
    className,
    id,
    onClick = () => {},
    iconColor = '',
}: IIconProps) => {
    return (
        <span
            id={id}
            className={className}
            onClick={onClick}
            style={{ color: iconColor ? iconColor : '' }}
        />
    )
}

export default Icon

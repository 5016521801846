import React from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { iconsTheme } from 'theme-exports'

const cls1 = { fill: '#707070' }
const cls2 = { fill: '#24874B' }

const VerdictWin = (): React.ReactElement => {
    return iconsTheme.win ? (
        <Icon icon={faCheck} style={{ fontSize: '18px', color: '#3c763d' }} />
    ) : (
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.06 24.06">
                <defs></defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path
                            style={cls1}
                            d="M12,24.06a12,12,0,1,1,12-12A12,12,0,0,1,12,24.06ZM12,1.25A10.78,10.78,0,1,0,22.81,12,10.8,10.8,0,0,0,12,1.25Z"
                        />
                        <path
                            style={cls2}
                            d="M10.53,16.85a.67.67,0,0,1-.38-.13L5.6,13.26a.62.62,0,0,1,.75-1l4.1,3.11,7.17-8a.63.63,0,0,1,.89,0,.64.64,0,0,1,0,.89L11,16.64A.6.6,0,0,1,10.53,16.85Z"
                        />
                    </g>
                </g>
            </svg>
        </span>
    )
}

export default VerdictWin

/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
import React from 'react'
import { Typography, TypographyVariant, Skeleton } from '@mui/material'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import { AccentArea } from 'components'
import { Link } from 'react-router-dom'
import caretRight from './icon-caret-right.svg'
import { clientTheme } from 'theme-exports'
import EntFeature from 'models/EntFeature'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import idDirectory from './idAttributes'

// TODO: Turn this into an enum on the model
interface MetricProps {
    testId: string
    className?: string
    loading: boolean
    metric: Record<string, any>
    metricValue?: string | number
}

const defaultStyles: { [key: string]: string } = {
    root: 'emp-metricDefault-root',
    metricWrap: 'emp-metricDefault-metricWrap',
    iconTextContainer: 'emp-metricDefault-iconTextContainer',
    icon: 'emp-metricDefault-icon',
    content: 'emp-metricDefault-content',
    noPadding: 'emp-metricDefault-noPadding',
    metricTimeFrame: 'emp-metricDefault-metricTimeFrame',
    metricTitle: 'emp-metricDefault-metricTitle',
    metricValue: 'emp-metricDefault-metricValue',
    altLink: 'emp-metricDefault-altLink',
    altLinkText: 'emp-metricDefault-altLinkText ',
    altIcon: 'emp-metricDefault-altIcon',
}

const altStyles: { [key: string]: string } = {
    root: 'emp-metricAlt-root',
    metricWrap: 'emp-metricAlt-metricWrap',
    iconTextContainer: 'emp-metricAlt-iconTextContainer',
    icon: 'emp-metricAlt-icon',
    content: 'emp-metricAlt-content',
    noPadding: 'emp-metricAlt-noPadding',
    metricTimeFrame: 'emp-metricAlt-metricTimeFrame',
    metricTitle: 'emp-metricAlt-metricTitle',
    metricValue: 'emp-metricAlt-metricValue',
    altLink: 'emp-metricAlt-altLink',
    altLinkText: 'emp-metricAlt-altLinkText ',
    altIcon: 'emp-metricAlt-altIcon',
}

interface AsyncContentProps {
    testId: string
    loading?: boolean
    variant?: TypographyVariant
    className?: string
}

/**
 * Provide loading skeleton of the text
 */
const AsyncText: React.FC<AsyncContentProps> = ({
    testId,
    children,
    loading = false,
    variant,
    className,
}) => (
    <Typography
        className={className}
        variant={variant}
        id={`${idDirectory.textValue}-${testId}`}
    >
        {loading ? <Skeleton variant="text" /> : children}
    </Typography>
)
/**
 * The Metric can be used to showcase a summary number that links to more info
 */
export const Metric: React.FC<MetricProps> = ({
    testId,
    metric,
    loading,
    metricValue,
}) => {
    const metricStyles = metric.variant !== 'default' ? altStyles : defaultStyles

    const uiState = useUiStateContext()
    const hasMerchantHierarchy = uiState.whoami?.hasRole(EntFeature.MERCHANT_HIERARCHY) || false

    return (
        <AccentArea
            hasHeaderDivider={false}
            className={`${metricStyles['noPadding']} ${metricStyles['metricWrap']}`}
            borderTopColor={metric.borderTopColor}
            testId={testId}
        >
            {/* TODO - This is for a hotfix that needs done ASAP. Will do this properly with properties on the metrics next. */}
            {metric.link &&
            !(metric.title === 'MIDS' && !hasMerchantHierarchy) ? (
                <Link
                    to={{
                        pathname: `${metric.link.to}`,
                        state: {
                            preset_filter: metric.link.filter_presets,
                        },
                    }}
                    id={`${idDirectory.aLink}-${testId}`}
                >
                    <div
                        className={metricStyles['root']}
                        style={{ background: metric.backgroundColor }}
                        id={`${idDirectory.divRoot}-${testId}`}
                    >
                        <div
                            className={metricStyles['iconTextContainer']}
                            id={`${idDirectory.divInfo}-${testId}`}
                        >
                            <div
                                className={metricStyles['icon']}
                                id={`${idDirectory.divIcon}-${testId}`}
                            >
                                {typeof metric.icon === 'string' ? (
                                    <img
                                        src={metric.icon}
                                        alt="metric icon"
                                        id={`${idDirectory.imgIcon}-${testId}`}
                                    />
                                ) : (
                                    <Icon
                                        size="6x"
                                        icon={metric.icon}
                                        id={`${idDirectory.imgIcon}-${testId}`}
                                    />
                                )}
                            </div>
                            <div
                                className={metricStyles['content']}
                                id={`${idDirectory.divContent}-${testId}`}
                            >
                                <AsyncText
                                    className={metricStyles['metricValue']}
                                    loading={loading}
                                    variant="h4"
                                    testId={`value-${testId}`}
                                >
                                    {metricValue}
                                </AsyncText>
                                <AsyncText
                                    className={metricStyles['metricTitle']}
                                    variant="body1"
                                    testId={`title-${testId}`}
                                >
                                    {metric.title}
                                </AsyncText>
                                <AsyncText
                                    className={metricStyles['metricTimeFrame']}
                                    variant="body1"
                                    testId={`timeFrame-${testId}`}
                                >
                                    {metric.timeframe} &nbsp;
                                </AsyncText>
                            </div>
                        </div>
                        {metric.variant === 'default' && (
                            <img
                                style={{
                                    ...(metric.variant === 'default' && {
                                        width:
                                            clientTheme.metricTile.arrow.width,
                                        height:
                                            clientTheme.metricTile.arrow.height,
                                        paddingLeft: 5,
                                    }),
                                }}
                                src={caretRight}
                                alt=""
                                id={`${idDirectory.imgCaretRight}-${testId}`}
                            />
                        )}
                    </div>
                    {metric.variant !== 'default' && (
                        <div
                            className={metricStyles['altLink']}
                            style={{ background: metric.backgroundColor }}
                            id={`${idDirectory.divAltLink}-${testId}`}
                        >
                            <AsyncText
                                variant="body1"
                                className={metricStyles['altLinkText']}
                                testId={`linkText-${testId}`}
                            >
                                {metric.link.text}
                            </AsyncText>
                            <Icon
                                icon={faArrowAltCircleRight}
                                className={metricStyles['altIcon']}
                                id={`${idDirectory.imgArrowRight}-${testId}`}
                            />
                        </div>
                    )}
                </Link>
            ) : (
                <>
                    <div
                        className={metricStyles['root']}
                        style={{ background: metric.backgroundColor }}
                        id={`${idDirectory.divRoot}-${testId}`}
                    >
                        <div
                            className={metricStyles['iconTextContainer']}
                            id={`${idDirectory.divInfo}-${testId}`}
                        >
                            <div
                                className={metricStyles['icon']}
                                id={`${idDirectory.divIcon}-${testId}`}
                            >
                                {typeof metric.icon === 'string' ? (
                                    <img
                                        src={metric.icon}
                                        alt="metric icon"
                                        id={`${idDirectory.imgIcon}-${testId}`}
                                    />
                                ) : (
                                    <Icon
                                        size="6x"
                                        icon={metric.icon}
                                        id={`${idDirectory.imgIcon}-${testId}`}
                                    />
                                )}
                            </div>
                            <div
                                className={metricStyles['content']}
                                id={`${idDirectory.divContent}-${testId}`}
                            >
                                <AsyncText
                                    className={metricStyles['metricValue']}
                                    loading={loading}
                                    variant="h4"
                                    testId={`value-${testId}`}
                                >
                                    {metricValue}
                                </AsyncText>
                                <AsyncText
                                    className={metricStyles['metricTitle']}
                                    variant="body1"
                                    testId={`title-${testId}`}
                                >
                                    {metric.title}
                                </AsyncText>
                                <AsyncText
                                    className={metricStyles['metricTimeFrame']}
                                    variant="body1"
                                    testId={`timeFrame-${testId}`}
                                >
                                    {metric.timeframe} &nbsp;
                                </AsyncText>
                            </div>
                        </div>
                    </div>
                    {metric.variant !== 'default' && (
                        <div
                            className={metricStyles['altLink']}
                            style={{ background: metric.backgroundColor }}
                            id={`${idDirectory.divAltLink}-${testId}`}
                        >
                            <AsyncText
                                variant="body1"
                                className={metricStyles['altLinkText']}
                                testId={`linkText-${testId}`}
                            >
                                &nbsp;
                            </AsyncText>
                        </div>
                    )}
                </>
            )}
        </AccentArea>
    )
}

export default Metric

import React, { useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import LineChart from 'components/LineChart'
import AreaChart from 'components/AreaChart'
import { IReportFilter } from 'views/Reports'

/**
 * @name ByTransactionDateWeek
 * @desc Render Card Type chart
 * @filterValues IReportsFilter
 */
const ByTransactionDateWeek = ({
    filterValues,
    isLineChart = false,
}: {
    filterValues: IReportFilter
    isLineChart?: boolean
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ name: string; val: number }[]>([])

    React.useEffect(() => {
        setLoading(true)
        ReportsApi.getCTRByTransactionDateWeek(filterValues).then((data) => {
            setLoading(false)
            setData(data)
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    return isLineChart ? (
        <LineChart
            chartTitle="Chargeback Transaction Ratio (CTR) by Transaction Date Week"
            data={data}
            loading={loading}
            returnCard={false}
            toolTipValueName="Days"
        />
    ) : (
        <AreaChart
            chartTitle="Chargeback Transaction Ratio (CTR) by Transaction Date Week"
            data={data}
            loading={loading}
            returnCard={false}
            toolTipValueName="Days"
        />
    )
}

export default ByTransactionDateWeek

import React from 'react'
import {
    Button,
} from '@mui/material'

interface CompellingEvidenceLauncherProps {
    isRemedyPossible: boolean,
    setCompellingEvidenceModalOpen: (open: boolean, closeRepresentment?: boolean) => void
}

const CompellingEvidenceLauncher = ({
    isRemedyPossible,
    setCompellingEvidenceModalOpen,
}: CompellingEvidenceLauncherProps) => {

    return (
        <div className={'emp-compellingEvidenceLauncher-root'}>
            {isRemedyPossible ? (
                <>
                    <p className={'emp-compellingEvidenceLauncher-paragraph'}>
                        You have a better chance to win this reason code if you
                        can prove the same card has participated in previously
                        undisputed transactions which meet similar criteria.
                    </p>
                    <p className={'emp-compellingEvidenceLauncher-paragraph'}>
                        You can proceed with this option by selection Remedy
                        below.
                    </p>
                    <Button
                        size={'small'}
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            setCompellingEvidenceModalOpen(true)
                        }}
                        className={
                            'emp-compellingEvidenceLauncher-submitBtn'
                        }
                    >
                        Remedy
                    </Button>
                </>
            ) : (
                <>
                    <p className={'emp-compellingEvidenceLauncher-paragraph'}>
                        Unfortunately this Case does not qualify for the Remedy
                        option. You can still defend the Dispute with documents.
                    </p>
                </>
            )}
        </div>
    )
}

export default CompellingEvidenceLauncher

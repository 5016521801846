/**
 * @description Directory for ID Attributes on MainLayout.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `mainLayout-div-root`,
    header: `mainLayout-header-topbar`,
    main: `mainLayout-main-children`,
    footer: `mainLayout-footer-standardFooter`,
}

export default idDirectory

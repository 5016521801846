/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable new-cap */
import React, { useState } from 'react'
import View from 'views/View'
import { faSearch, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Collapse, Grid, Slide } from '@mui/material'
import {
    AdvancedFilters,
    UploadRepresentmentsRouter,
    AccentArea,
    MerchantSwitcher,
    CaseFilter,
    FilterAlert,
} from 'components'
import idDirectory from './idAttributes'
import { withRequiredRole } from 'components/useRequireRole'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import EntFeature from 'models/EntFeature'
import { useChargebacks } from './Chargebacks.vm'
import { BulkCloseDialog } from './dialogs/BulkCloseDialog'
import { ChargebacksGrid } from './grids/ChargebacksGrid'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { SWRContextProvider as ChargebacksContextProvider } from 'context/SWRContext/SWRContext'
import { AdvancedSearchValues } from 'hooks/cb-api/types'
import { clientTheme } from 'theme-exports'
import AlertSnackbar from 'components/AlertSnackbar'
import { EditChargeback } from './modals/EditChargeback'

/**
 * The Chargebacks view with a datagrid
 */
const Chargebacks = ({ ...props }: any) => {
    const {
        activeMerchantId,
        cases,
        advanceSearchFields,
        advancedSearchValues,
        handleAdvancedSearchSubmit,
        handleAdvancedSearchClear,
        contextMenuItems,
        isUploadOpen,
        closeUpload,
        selectedCaseIds,
        clearSelectedRows,
        handleCaseFilterChange,
        rehydrateView,
        canViewPartnerCompanyId,
        canViewIssuerDocs,
        canViewOutcomeVerdicts,
        canViewPlatform,
        disableSelectAll,
        swrActions,
        metricPresets,
        setMetricPresets,
        collapseOpen,
        setCollapseOpen,
        params,
        isLoadingAdvFilterSelect,
        setIsLoadingAdvFilterSelect,
        openEditChargebacksModal,
        setOpenEditChargebacksModal,
        isLoadingAdvFilterFields,
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
    } = useChargebacks(props)

    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const [showCaseFiltersDrawer, setCaseFiltersDrawer] = useState(false)
    const [bulkCloseOpen, setBulkCloseOpen] = useState(false)
    const { CASE_FILTERS, AUTO_ACCEPT, HELP_LINK } = useFeatureToggle('CHARGEBACKS')
    const { enabled: showCaseFilterButton } = CASE_FILTERS
    const {
        enabled: helpLinkEnabled,
        text: helpLinkText,
        url: helpLinkURL
    } = HELP_LINK
    const [alertFilterOpen, setAlertFilterOpen] = useState<boolean>(false)

    const { unselectAllRows } = swrActions

    const handleFilterAlert = (filters: AdvancedSearchValues) => {
        Object.values(filters).some((item) => item.length && item !== 'eq')
            ? setAlertFilterOpen(true)
            : setAlertFilterOpen(false)
    }

    return (
        <ChargebacksContextProvider SWRActions={swrActions}>
            <View
                title="Chargebacks"
                breadcrumb="Chargebacks"
                HeaderInterior={<MerchantSwitcher testId="chargebacks" />}
                testId="chargebacks"
            >
                <Grid
                    id={idDirectory.gridContainer}
                    container
                    spacing={1}
                    className={'emp-gridWrapperDefault'}
                >
                    <Grid
                        id={`${idDirectory.gridItem}-chargebacks`}
                        item
                        xs={12}
                        lg={
                            showCaseFiltersDrawer
                                ? 9
                                : collapseOpen
                                ? 12
                                : undefined
                        }
                    >
                        <AccentArea
                            testId={'chargebacks-cbHeader'}
                            className={'emp-accentArea'}
                            containsFullWidthGrid={true}
                            headerAction={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div className={'emp-topContainer'}>
                                        <div>
                                            <Button
                                                id={idDirectory.btnAdvSearch}
                                                variant="contained"
                                                color="secondary"
                                                onClick={() =>
                                                    setCollapseOpen(
                                                        !collapseOpen
                                                    )
                                                }
                                                className={`emp-advSearchBtn`}
                                                style={{ marginRight: 4 }}
                                                disabled={
                                                    isLoadingAdvFilterFields ||
                                                    !advanceSearchFields.length
                                                }
                                            >
                                                <span
                                                    className={
                                                        'emp-advSearchIcon'
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faSearch}
                                                    />
                                                </span>
                                                &nbsp; Advanced Search
                                            </Button>
                                            {showCaseFilterButton && (
                                                <Button
                                                    id={
                                                        idDirectory.btnCaseFilter
                                                    }
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() =>
                                                        setCaseFiltersDrawer(
                                                            !showCaseFiltersDrawer
                                                        )
                                                    }
                                                    className={`emp-advSearchBtn`}
                                                    sx={[
                                                        showCaseFiltersDrawer && {
                                                            backgroundColor:
                                                                clientTheme.secondaryDark,
                                                        },
                                                    ]}
                                                >
                                                    Case Filters
                                                </Button>
                                            )}
                                            {!AUTO_ACCEPT.enabled ? null : (
                                                <Button
                                                    id={
                                                        idDirectory.btnAutoAccept
                                                    }
                                                    variant="contained"
                                                    onClick={() =>
                                                        setBulkCloseOpen(true)
                                                    }
                                                    className={
                                                        'emp-autoAcceptBtn'
                                                    }
                                                    disabled={isReadOnly}
                                                    sx={
                                                        clientTheme.buttons
                                                            .greyButton.style
                                                    }
                                                >
                                                    Auto Accept
                                                </Button>
                                            )}
                                        </div>
                                        {!helpLinkEnabled ? null : (
                                            <a
                                                className={'emp-helpLink'}
                                                href={helpLinkURL}
                                                rel="external noopener noreferrer"
                                                target="_blank"
                                            >
                                                {helpLinkText}
                                            </a>
                                        )}
                                        <div
                                            className={
                                                'emp-filterAlertContainer'
                                            }
                                        >
                                            {alertFilterOpen && <FilterAlert />}
                                        </div>
                                    </div>
                                </div>
                            }
                        >
                            <Collapse in={collapseOpen} timeout="auto">
                                <div
                                    className={'emp-advancedFilterContent'}
                                    style={{
                                        background:
                                            clientTheme.mainLayout
                                                .backgroundColor,
                                    }}
                                >
                                    {Boolean(advanceSearchFields.length) && (
                                        <AdvancedFilters
                                            fields={advanceSearchFields}
                                            metricPresets={metricPresets}
                                            validate={() => ({})}
                                            onSubmit={(filters) => {
                                                handleFilterAlert(filters)
                                                handleAdvancedSearchSubmit(
                                                    filters,
                                                    true
                                                )
                                            }}
                                            onClear={() => {
                                                setAlertFilterOpen(false)
                                                handleAdvancedSearchClear()
                                            }}
                                            initialValues={
                                                advancedSearchValues as Record<
                                                    string,
                                                    string
                                                >
                                            }
                                            isActiveAdvSearchFilters={
                                                alertFilterOpen
                                            }
                                            setIsLoadingAdvFilterSelect={
                                                setIsLoadingAdvFilterSelect
                                            }
                                            inactivateSearchBtn={
                                                swrActions.isLoading
                                            }
                                        />
                                    )}
                                </div>
                            </Collapse>
                            {/* isMultiSelect is always true as this upload modal opens from the context menu, not the manual upload icon */}
                            <UploadRepresentmentsRouter
                                cases={cases}
                                caseIds={selectedCaseIds}
                                openModal={isUploadOpen}
                                toggleModal={closeUpload}
                                rehydrateView={rehydrateView}
                                isMultiSelect
                                setAlertSnackbarMainProps={
                                    setAlertSnackbarMainProps
                                }
                                setAlertSnackbarMainOpen={
                                    setAlertSnackbarMainOpen
                                }
                                unselectAllRows={unselectAllRows}
                            />
                            <ChargebacksGrid
                                data={cases}
                                contextMenuItems={contextMenuItems}
                                canViewPartnerCompanyId={
                                    canViewPartnerCompanyId
                                }
                                canViewIssuerDocs={canViewIssuerDocs}
                                canViewOutcomeVerdicts={canViewOutcomeVerdicts}
                                canViewPlatform={canViewPlatform}
                                disableSelectAllCheckbox={disableSelectAll}
                                params={params}
                                clearSelectedRows={clearSelectedRows}
                                setAlertSnackbarMainProps={
                                    setAlertSnackbarMainProps
                                }
                                setAlertSnackbarMainOpen={
                                    setAlertSnackbarMainOpen
                                }
                                isLoadingAdvFilters={
                                    isLoadingAdvFilterSelect || isLoadingAdvFilterFields
                                }
                            />
                        </AccentArea>
                    </Grid>
                    <Slide
                        direction="left"
                        in={showCaseFiltersDrawer}
                        mountOnEnter
                        unmountOnExit
                        style={{
                            position: 'sticky',
                            top: '60px',
                            zIndex: 999,
                        }}
                    >
                        <Grid
                            id={`${idDirectory.gridItem}-caseFilter`}
                            item
                            xs={12}
                            lg={3}
                        >
                            <AccentArea
                                testId={'chargebacks-caseFilter'}
                                title={
                                    <>
                                        <FontAwesomeIcon
                                            icon={faFilter}
                                            className={'emp-marginRight'}
                                        />
                                        <span>Case Filters</span>
                                    </>
                                }
                                className={'emp-caseFilterArea'}
                            >
                                <CaseFilter
                                    title="Queue"
                                    clientId={activeMerchantId}
                                    onFilterChange={handleCaseFilterChange}
                                    onClose={() => setCaseFiltersDrawer(false)}
                                    clearAdvanceSearch={() => {
                                        setAlertFilterOpen(false)
                                        handleAdvancedSearchClear()
                                    }}
                                    isActiveAdvSearchFilters={alertFilterOpen}
                                    params={params}
                                    setMetricPresets={setMetricPresets}
                                    setAlertFilterOpen={setAlertFilterOpen}
                                    setAlertSnackbarMainProps={
                                        setAlertSnackbarMainProps
                                    }
                                    setAlertSnackbarMainOpen={
                                        setAlertSnackbarMainOpen
                                    }
                                />
                            </AccentArea>
                        </Grid>
                    </Slide>
                </Grid>
                <BulkCloseDialog
                    open={bulkCloseOpen}
                    onClose={() => setBulkCloseOpen(false)}
                />
                {openEditChargebacksModal && (
                    <EditChargeback
                        openModal={openEditChargebacksModal}
                        onClose={() => setOpenEditChargebacksModal(false)}
                        caseIds={selectedCaseIds}
                        rehydrateView={rehydrateView}
                        setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                        setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                        enableBulkAction
                        unselectAllRows={unselectAllRows}
                    />
                )}
                <AlertSnackbar
                    content={alertSnackbarMainProps}
                    open={alertSnackbarMainOpen}
                    onClose={() => setAlertSnackbarMainOpen(false)}
                    showCloseIcon
                />
            </View>
        </ChargebacksContextProvider>
    )
}

export default withRequiredRole(EntFeature.CHARGEBACKS, Chargebacks)

import React from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    CircularProgress,
} from '@mui/material'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'

export interface StandardModalProps {
    openModal: boolean
    onModalClose: () => void
    modalHeaderTitle: string
    children: React.ReactNode
    testId: string
    maxModalWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
    enableErrorTitleStyling?: boolean
    enablePrimaryActionBtn?: boolean
    primaryBtnText?: string
    handlePrimaryActionBtn?: () => void
    inactivatePrimaryActionBtn?: boolean
    isLoadingPrimaryActionBtn?: boolean
    enableSecondaryActionBtn?: boolean
    secondaryBtnText?: string
    handleSecondaryActionBtn?: () => void
    inactivateSecondaryActionBtn?: boolean
    isLoadingSecondaryActionBtn?: boolean
    disableBackdropClick?: boolean
    showClientThemeTopBorder?: boolean
    className?: string
}

const StandardModal = ({
    openModal,
    onModalClose,
    modalHeaderTitle,
    children,
    testId,
    maxModalWidth = 'md',
    enableErrorTitleStyling = false,
    enablePrimaryActionBtn = true,
    primaryBtnText = 'Submit',
    handlePrimaryActionBtn = () => {},
    inactivatePrimaryActionBtn = false, // Shows primary button but will disable it
    isLoadingPrimaryActionBtn = false,
    enableSecondaryActionBtn = true,
    secondaryBtnText = 'Cancel',
    handleSecondaryActionBtn = () => {},
    inactivateSecondaryActionBtn = false, // Shows secondary button but will disable it
    isLoadingSecondaryActionBtn = false,
    disableBackdropClick = true,
    showClientThemeTopBorder = false,
    className = '',
}: StandardModalProps): React.ReactElement => {
    return (
        <Dialog
            fullWidth
            open={openModal}
            onClose={disableBackdropClick ? () => {} : onModalClose}
            className={className}
            maxWidth={maxModalWidth}
            id={`${idDirectory.divRoot}-${testId}`}
        >
            <DialogTitle
                className={`${'emp-standardModal-dialogTitle'} ${
                    enableErrorTitleStyling &&
                    'emp-standardModal-errorDialogTitle'
                }`}
                style={{
                    ...(showClientThemeTopBorder && {
                        borderTop: `3px solid ${clientTheme.main}`,
                    }),
                }}
                id={`${idDirectory.divTitle}-${testId}`}
            >
                {modalHeaderTitle}
                <FontAwesomeIcon
                    icon={faTimes}
                    className={'emp-standardModal-modalClose'}
                    onClick={onModalClose}
                />
            </DialogTitle>
            <DialogContent
                className={'emp-standardModal-dialogContent'}
                id={`${idDirectory.divContent}-${testId}`}
            >
                {children}
            </DialogContent>
            {(enablePrimaryActionBtn || enableSecondaryActionBtn) && (
                <DialogActions
                    className={'emp-standardModal-dialogActions'}
                    id={`${idDirectory.divActions}-${testId}`}
                >
                    {enableSecondaryActionBtn && (
                        <Button
                            variant="contained"
                            sx={clientTheme.buttons.defaultButton.style}
                            onClick={handleSecondaryActionBtn}
                            id={`${idDirectory.btnSecondary}-${testId}`}
                            disabled={inactivateSecondaryActionBtn}
                        >
                            {isLoadingSecondaryActionBtn ? (
                                <>
                                    Submitting...{' '}
                                    <CircularProgress
                                        className={
                                            'emp-standardModal-circularProgress'
                                        }
                                        color="secondary"
                                    />
                                </>
                            ) : (
                                secondaryBtnText
                            )}
                        </Button>
                    )}
                    {enablePrimaryActionBtn && (
                        <Button
                            variant="contained"
                            onClick={handlePrimaryActionBtn}
                            color={'secondary'}
                            id={`${idDirectory.btnPrimary}-${testId}`}
                            disabled={inactivatePrimaryActionBtn}
                        >
                            {isLoadingPrimaryActionBtn ? (
                                <>
                                    Submitting...{' '}
                                    <CircularProgress
                                        className={
                                            'emp-standardModal-circularProgress'
                                        }
                                        color="secondary"
                                    />
                                </>
                            ) : (
                                primaryBtnText
                            )}
                        </Button>
                    )}
                </DialogActions>
            )}
        </Dialog>
    )
}

export default StandardModal

import { useEffect, useRef } from 'react'

/**
 *
 * @desc returns a component's 'mount' reference so that you can check if your component is mounted or unmounted. Checking if your component is mounted before updating state is important. Updating state in a promise return on an unmounted component can cause a memory leak and will throw a warning.
 */
const useIsMounted = () => {
    const _isMounted = useRef(false)
    useEffect(() => {
        if (!_isMounted.current) _isMounted.current = true
        return () => {
            _isMounted.current = false
        }
    }, [])

    return {
        isMounted: _isMounted,
    }
}

export default useIsMounted

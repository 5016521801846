/**
 * @description Directory for ID Attributes on Merchant Settings.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    gridContainer: `merchantSettings-grid-container`,
    gridItem: `merchantSettings-grid-item`,
    merchantDetails: {
        labelHeader: `merchantDetails-label-header`,
        divLoadingStatus: `merchantDetails-div-loadingStatus`,
        divStatus: `merchantDetails-div-status`,
        btnMerchantMenu: `merchantDetails-btn-merchantMenu`,
        menuItemMerchantInfo: `merchantDetails-menuItem-merchantInfo`,
        menuItemMerchantHistory: `merchantDetails-menuItem-merchantHistory`,
        menuItemEditMerchant: `merchantDetails-menuItem-editMerchant`,
        menuItemAddMerchant: `merchantDetails-menuItem-addMerchant`,
    },
    actionsBar: {
        btnBulkUploadMids: `actionsBar-btn-bulkUploadMids`,
        btnAddMid: `actionsBar-btn-addMid`,
    },
    merchantHistory: {
        divRoot: `merchantHistory-div-root`,
    },
    midHistory: {
        divRoot: `midHistory-div-root`,
        divUnavailable: `midHistory-div-unavailable`,
    },
    merchantInfo: {
        divRoot: `merchantInfo-div-root`,
        labelHeader: `merchantInfo-label-header`,
        textField: `merchantInfo-text-field`,
    },
    merchantTreeHeader: {
        textFieldSearch: `merchantTreeHeader-textField-search`,
    },
    merchantMidsGridDetails: {
        divRoot: `merchantMidsGridDetails-div-root`,
        btnEdit: `merchantMidsGridDetails-btn-edit`,
        btnMidHistory: `merchantMidsGridDetails-btn-midHistory`,
        divNoSelectedRow: `merchantMidsGridDetails-div-noSelectedRow`,
    },
    addEditMerchant: {
        labelHeader: `addEditMerchant-label-header`,
        inputField: `addEditMerchant-input-field`,
        statusField: `addEditMerchant-status-field`,
        switchField: `addEditMerchant-switch-field`,
        radioField: `addEditMerchant-radio-field`,
        selectField: `addEditMerchant-select-field`,
        checkboxAddContact: `addEditMerchant-checkbox-addContact`,
    },
    addEditMid: {
        labelHeader: `addEditMid-label-header`,
        inputField: `addEditMid-input-field`,
        statusField: `addEditMid-status-field`,
        switchField: `addEditMid-switch-field`,
        radioField: `addEditMid-radio-field`,
        selectField: `addEditMid-select-field`,
        divBulkUpload: `addEditMid-div-bulkUpload`,
    },
    alertActions: {
        divRoot: `alertActions-div-root`,
        divMessage: `alertActions-div-message`,
        divLoading: `alertActions-div-loading`,
        divError: `alertActions-div-error`,
        divAlertActions: `alertActions-div-alertActions`,
        divAlert: `alertActions-div-alert`,
        divPendingActivation: `alertActions-div-pendingActivation`,
        radioField: `alertActions-radio-field`,
        divConfirmActions: `alertActions-radio-confirmActions`,
    },
    bulkUploadMids: {
        divRoot: `bulkUploadMids-div-root`,
        linkTemplate: `bulkUploadMids-link-template`,
        btnUploadFile: `bulkUploadMids-btn-uploadFile`,
    },
}

export default idDirectory

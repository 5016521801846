/**
 * @description Directory for ID Attributes on Case QC Review.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    divHeaderContainer: `caseQCReview-div-headerContainer`,
    divFooterContainer: `caseQCReview-div-footerContainer`,
    divCaseInfoContainer: `caseQCReview-div-caseInfoContainer`,
    divCasePreviewContainer: `caseQCReview-div-casePreviewContainer`,
}

export default idDirectory

import React, { useState } from 'react'
import idDirectory from '../idAttributes'
import { useFilterAlerts } from '../useFilterAlerts'
import { PresetDateRange, SearchableSelect } from 'components'
import { IDateRange } from 'components/PresetDateRange'
import { FilterValues } from '../Alerts.vm'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface FilterAlertsProps {
    defaultDateRange: IDateRange
    setFilterValues: (filter: FilterValues) => void
    filterValues: FilterValues
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

type Option = {
    id: number
} & Record<string, unknown>

interface SelectInputProps {
    inputOptionAccessor: string
    optionList: Option[]
    placeholder?: string
    alertsAccessor: string
    [key: string]: any
}

const SelectInput = ({
    inputOptionAccessor,
    optionList,
    handleSearch,
    searchValue,
    setFilters,
    alertsAccessor,
}: SelectInputProps) => {
    const [inputValue, setInputValue] = useState<string>('')

    const handleChange = (val: Option | null) => {
        // checks if All Descriptors is selected, if so, this will send an empty string as a search value to reset alerts query to all descriptos
        if (val?.name === 'All Descriptors') {
            setInputValue('All Descriptors')
            setFilters(alertsAccessor, undefined)
        } else {
            setInputValue(val?.name as string)
            setFilters(alertsAccessor, val?.name as string)
        }
    }

    const value = React.useMemo(() => {
        // returns first option in list array if no search value
        if (!inputValue) return optionList[0][inputOptionAccessor] as string
        else return inputValue
        // eslint-disable-next-line
    }, [inputValue])

    return (
        <div
            id={idDirectory.filterAlerts.divDescriptors}
            className={'emp-inputContainer'}
        >
            <SearchableSelect
                value={value}
                onValueChange={handleChange}
                options={optionList ?? []}
                searchValue={searchValue}
                onSearchChange={handleSearch}
                accessor={inputOptionAccessor}
            />
        </div>
    )
}

const FilterAlerts = ({
    defaultDateRange,
    setFilterValues,
    filterValues,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: FilterAlertsProps) => {

    const {
        descriptorOptions,
        descriptorSearchValue,
        setDescriptorSearchValue,
        setDateRange,
        setFilters,
    } = useFilterAlerts(
        setFilterValues,
        filterValues,
        setAlertSnackbarMainProps,
        setAlertSnackbarMainOpen
    )

    return (
        <div id={'preChargebacksAGFilterAlerts'}>
            <label className={'emp-filterLabel'}>Filter By: </label>
            <div className={'emp-filterSortContainer'}>
                <SelectInput
                    setFilters={setFilters}
                    placeholder="All Descriptors"
                    inputOptionAccessor="name"
                    optionList={descriptorOptions}
                    handleSearch={setDescriptorSearchValue}
                    alertsAccessor={'descriptor'}
                    searchValue={descriptorSearchValue}
                />
                <PresetDateRange
                    getDateRange={(date: string) => setDateRange(date)}
                    dateRange={defaultDateRange}
                    className={'emp-dateRangePicker'}
                    testId="filterAlerts"
                    allowFutureDateSelection={false}
                />
            </div>
        </div>
    )
}

export default FilterAlerts

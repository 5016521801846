import { ExtendedColumn } from 'components'
import SortComponent from 'components/DataGrid/SortComponent'
import React, { useEffect, useState } from 'react'
import { CellProps, Renderer } from 'react-table'
import mockPartnerAdminChargebackPayload from './__mocks__/partner-admin/chargebacks.json'
import mockPartnerAdminCaseManagementPayload from './__mocks__/partner-admin/case-management.json'
import mockPartnerAdminQCReviewPayload from './__mocks__/partner-admin/qc-review.json'
import mockPartnerAdminAdminReviewPayload from './__mocks__/partner-admin/admin-review.json'
import mockMerchantAdminChargebackPayload from './__mocks__/merchant-admin/chargebacks.json'
import mockMerchantAdminCaseManagementPayload from './__mocks__/merchant-admin/case-management.json'
import mockMerchantAdminQCReviewPayload from './__mocks__/merchant-admin/qc-review.json'
import mockMerchantAdminAdminReviewPayload from './__mocks__/merchant-admin/admin-review.json'
import mockPartnerUserChargebackPayload from './__mocks__/partner-user/chargebacks.json'
import mockPartnerUserCaseManagementPayload from './__mocks__/partner-user/case-management.json'
import mockPartnerUserQCReviewPayload from './__mocks__/partner-user/qc-review.json'
import mockPartnerUserAdminReviewPayload from './__mocks__/partner-user/admin-review.json'
import mockMerchantUserChargebackPayload from './__mocks__/merchant-user/chargebacks.json'
import mockMerchantUserCaseManagementPayload from './__mocks__/merchant-user/case-management.json'
import mockMerchantUserQCReviewPayload from './__mocks__/merchant-user/qc-review.json'
import mockMerchantUserAdminReviewPayload from './__mocks__/merchant-user/admin-review.json'
import mockCB911ManagerChargebackPayload from './__mocks__/cb911-manager/chargebacks.json'
import mockCB911ManagerCaseManagementPayload from './__mocks__/cb911-manager/case-management.json'
import mockCB911ManagerQCReviewPayload from './__mocks__/cb911-manager/qc-review.json'
import mockCB911ManagerAdminReviewPayload from './__mocks__/cb911-manager/admin-review.json'
import mockCB911AgentChargebackPayload from './__mocks__/cb911-agent/chargebacks.json'
import mockCB911AgentCaseManagementPayload from './__mocks__/cb911-agent/case-management.json'
import mockCB911AgentQCReviewPayload from './__mocks__/cb911-agent/qc-review.json'
import mockCB911AgentAdminReviewPayload from './__mocks__/cb911-agent/admin-review.json'

export interface ColumnConfig {
    label: string
    sortable: boolean
    hidden: boolean
    accessor: string | null
    colId?: string
    cell: string | null
    priority?: number | null
    ellipsifyText?: boolean | null
    width: number
    minWidth?: number
    maxWidth?: number
    type?: string
    columnAlign?: string
    cellStyle?: any
    pinned?: any
    lockPinned?: boolean
    disableColumnFilter?: boolean
    menuTabs?: string[]
}

interface ColConfigRes {
    key: string
    data_endpoint: string
    columns: ColumnConfig[]
}

interface ColumnDefsProps {
    colDefs: ExtendedColumn[]
    hiddenColumns: string[]
    setHiddenColumns: (columns: string[]) => void
}
interface ColumnDefsPropsAGGrid {
    colDefs: ExtendedColumn[]
}

export type GridContentType = 'chargebacks' | 'caseManagement' | 'qcReview' | 'adminReview'

export interface GridCellMap<D extends object = {}> {
    [key: string]: Renderer<CellProps<D>>
}

export interface Options<D extends object> {
    cells: GridCellMap<D>
    setSortBy: (sortOrder: boolean, accessor: string) => void
    sortInfo: any
    selectedTabFilter: string
}

// Query function to mimic the API call
export const getColDefs = (type: GridContentType, role: string) =>
    new Promise<ColConfigRes>((resolve) => {
        setTimeout(() => {
            if (['chargebacks', 'caseManagement', 'qcReview', 'adminReview'].includes(type)) {
                switch (role) {
                    case 'merchant_admin':
                        switch (type) {
                            case 'caseManagement':
                                resolve(mockMerchantAdminCaseManagementPayload)
                                break
                            case 'chargebacks':
                                resolve(mockMerchantAdminChargebackPayload)
                                break
                            case 'adminReview':
                                resolve(mockMerchantAdminAdminReviewPayload)
                                break
                            default:
                                resolve(mockMerchantAdminQCReviewPayload)
                                break
                        }
                        break
                    case 'merchant_user':
                        switch (type) {
                            case 'caseManagement':
                                resolve(mockMerchantUserCaseManagementPayload)
                                break
                            case 'chargebacks':
                                resolve(mockMerchantUserChargebackPayload)
                                break
                            case 'adminReview':
                                resolve(mockMerchantUserAdminReviewPayload)
                                break
                            default:
                                resolve(mockMerchantUserQCReviewPayload)
                                break
                        }
                        break
                    case 'partner_user':
                        switch (type) {
                            case 'caseManagement':
                                resolve(mockPartnerUserCaseManagementPayload)
                                break
                            case 'chargebacks':
                                resolve(mockPartnerUserChargebackPayload)
                                break
                            case 'adminReview':
                                resolve(mockPartnerUserAdminReviewPayload)
                                break
                            default:
                                resolve(mockPartnerUserQCReviewPayload)
                                break
                        }
                        break
                    case 'partner_admin':
                        switch (type) {
                            case 'caseManagement':
                                resolve(mockPartnerAdminCaseManagementPayload)
                                break
                            case 'chargebacks':
                                resolve(mockPartnerAdminChargebackPayload)
                                break
                            case 'adminReview':
                                resolve(mockPartnerAdminAdminReviewPayload)
                                break
                            default:
                                resolve(mockPartnerAdminQCReviewPayload)
                                break
                        }
                        break

                    case 'cb911_manager':
                        switch (type) {
                            case 'caseManagement':
                                resolve(mockCB911ManagerCaseManagementPayload)
                                break
                            case 'chargebacks':
                                resolve(mockCB911ManagerChargebackPayload)
                                break
                            case 'adminReview':
                                resolve(mockCB911ManagerAdminReviewPayload)
                                break
                            default:
                                resolve(mockCB911ManagerQCReviewPayload)
                                break
                        }
                        break

                    case 'cb911_agent':
                        switch (type) {
                            case 'caseManagement':
                                resolve(mockCB911AgentCaseManagementPayload)
                                break
                            case 'chargebacks':
                                resolve(mockCB911AgentChargebackPayload)
                                break
                            case 'adminReview':
                                resolve(mockCB911AgentAdminReviewPayload)
                                break
                            default:
                                resolve(mockCB911AgentQCReviewPayload)
                                break
                        }
                        break

                    default:
                        switch (type) {
                            case 'caseManagement':
                                resolve(mockPartnerAdminCaseManagementPayload)
                                break
                            case 'chargebacks':
                                resolve(mockPartnerAdminChargebackPayload)
                                break
                            case 'adminReview':
                                resolve(mockPartnerAdminAdminReviewPayload)
                                break
                            default:
                                resolve(mockPartnerAdminQCReviewPayload)
                                break
                        }
                }
            } else {
                throw new Error()
            }
        }, 0)
    })

export const useColumnDefs = <D extends object = {}>(
    grid: GridContentType,
    role: string,
    { setSortBy = () => {}, sortInfo = {}, cells = {} }: Partial<Options<D>>,
    selectedTabFilter?: string
): ColumnDefsProps => {
    const [colDefs, setColDefs] = useState<ExtendedColumn[]>([])
    const [hiddenColumns, setHiddenColumns] = React.useState<string[] | []>([])
    // Fetch the column configs from the API
    useEffect(() => {
        getColDefs(grid, role).then((res) => {
            const cols = res.columns.map((col, idx) => ({
                Header:
                    col.sortable && col.accessor ? (
                        <SortComponent
                            sortHandler={setSortBy}
                            // allows the accessor string to be different than the cell value: ie: status_group accessor but status is passed as the sort accessor
                            accessor={col.cell ?? col.accessor}
                            value={col.label}
                            sortInfo={sortInfo}
                        />
                    ) : (
                        col.label
                    ),
                accessor: col.accessor ?? idx.toString(),
                hidden: col.hidden,
                priority: col?.priority ?? null,
                ellipsifyText: col?.ellipsifyText ?? null,
                width: col.width,
                type: col.type ?? 'string',
                columnAlign: col.columnAlign ?? 'none',
                Cell:
                    // select from the cell map the component to render based on
                    // the cell id then accessor value. Defaulting to the accessor value data.
                    cells[col.cell ?? col.accessor ?? 'default'] ??
                    ((props: CellProps<D>) => props.value),
            }))
            setColDefs(cols)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortInfo.sortBy, role, selectedTabFilter])

    useEffect(() => {
        const localStorageHiddenColumns = localStorage.getItem(
            `col-filter-${grid}`
        )

        getColDefs(grid, role).then((res) => {
            const defaultHiddenColumn = res.columns
                .filter((i) => i.hidden)
                .map((i) => i.accessor ?? '')

            localStorageHiddenColumns
                ? setHiddenColumns(JSON.parse(localStorageHiddenColumns))
                : setHiddenColumns(defaultHiddenColumn)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // return hydrated column definitions
    return { colDefs, hiddenColumns, setHiddenColumns }
}

export const useColumnDefsAGGrid = <D extends object = {}>(
    grid: GridContentType,
    role: string,
    { cells = {}, selectedTabFilter = '' }: Partial<Options<D>>
): ColumnDefsPropsAGGrid => {
    const [colDefs, setColDefs] = useState<ExtendedColumn[]>([])

    useEffect(() => {
        getColDefs(grid, role).then((res) => {
            const cols = res.columns.map((col, idx) => ({
                Header: col.label,
                sortable: col.sortable,
                accessor:
                    col.accessor ??
                    col.label.toLowerCase().replace(' ', '_') ??
                    idx.toString(),
                colId: col.colId,
                hide: col.hidden,
                width: col.width,
                minWidth: col.minWidth,
                maxWidth: col.maxWidth,
                pinned: col.pinned,
                lockPinned: col.lockPinned,
                disableColumnFilter: col.disableColumnFilter,
                menuTabs: col.menuTabs,
                type: col.type ?? '',
                sortIndex: col.priority ?? undefined,
                cellStyle: col.columnAlign
                    ? { textAlign: col.columnAlign ?? 'none', fontSize: '14px' }
                    : undefined,
                Cell: cells[col.cell ?? col.accessor ?? 'default'] ?? undefined,
            }))
            setColDefs(cols)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role, selectedTabFilter])

    // return hydrated column definitions
    return { colDefs }
}

import React, { useMemo, useState } from 'react'
import { Tooltip, IconButton, Menu, MenuItem } from '@mui/material'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clientTheme } from 'theme-exports'
import { CaseHistoryPopover, CaseHistoryModal, Icon } from 'components'

interface CaseMenuProps {
    row: any
    rowIndex: number
    onCaseHistoryOpen: (rowIndex: number) => void
    onCaseHistoryClose: () => void
    caseHistoryDisplayType?: 'modal' | 'popover'
    testId: string
    className?: string
}

const CaseMenu = ({
    row,
    rowIndex,
    onCaseHistoryOpen,
    onCaseHistoryClose,
    caseHistoryDisplayType = 'modal',
    testId,
    className,
}: CaseMenuProps): React.ReactElement => {
    const [openCaseHistoryModal, setOpenCaseHistoryModal] = useState<boolean>(
        false
    )
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [
        anchorElCaseHistory,
        setAnchorElCaseHistory,
    ] = useState<null | HTMLElement>(null)

    const memoRow = useMemo(() => row, [row])

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const handleClickCaseHistoryPopover = (event: any) => {
        setAnchorElCaseHistory(event.currentTarget)
    }

    const handleCloseCaseHistoryPopover = () => {
        setAnchorElCaseHistory(null)
    }

    const handleCaseHistory = (event: any) => {
        onCaseHistoryOpen(rowIndex)
        caseHistoryDisplayType === 'modal'
            ? setOpenCaseHistoryModal(true)
            : handleClickCaseHistoryPopover(event)
        handleCloseMenu()
    }

    return (
        <>
            <Tooltip arrow placement="top" title={'Case Menu'}>
                <IconButton
                    className={`emp-caseMenu-menuIconBtn ${className}`}
                    onClick={handleClickMenu}
                >
                    <FontAwesomeIcon
                        icon={faEllipsisV}
                        id={`${testId}-menu-icon-${memoRow.id}`}
                        className={'emp-caseMenu-menuIcon'}
                    />
                </IconButton>
            </Tooltip>
            <Menu
                id={`${testId}-case-menu-${memoRow.id}`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{ list: 'emp-caseMenu-menuRoot' }}
                variant="menu"
                keepMounted
            >
                <MenuItem
                    onClick={handleCaseHistory}
                    className={`emp-caseMenu-menuItem`}
                    sx={{
                        '& > span': {
                            color: clientTheme.secondary,
                        },
                        '&:hover': {
                            background: clientTheme.secondaryLight,
                            color: '#fff',
                            '& > span': {
                                color: '#fff',
                            },
                        },
                    }}
                    id={`${testId}-case-menu-case-history-${memoRow.id}`}
                >
                    <span>
                        <Icon
                            className={`fa fa-history emp-caseMenu-menuItemIcon`}
                        />
                    </span>
                    Case History
                </MenuItem>
            </Menu>
            {Boolean(anchorElCaseHistory) &&
                caseHistoryDisplayType === 'popover' && (
                    <CaseHistoryPopover
                        anchorEl={anchorElCaseHistory}
                        onClose={() => {
                            handleCloseCaseHistoryPopover()
                            onCaseHistoryClose()
                        }}
                        caseInfo={memoRow}
                        testId={`caseHistory-${rowIndex}`}
                    />
                )}
            {openCaseHistoryModal && caseHistoryDisplayType === 'modal' && (
                <CaseHistoryModal
                    openCaseHistoryModal={openCaseHistoryModal}
                    onClose={() => {
                        setOpenCaseHistoryModal(false)
                        onCaseHistoryClose()
                    }}
                    caseInfo={memoRow}
                    testId={`caseHistory-${rowIndex}`}
                />
            )}
        </>
    )
}

export default CaseMenu

/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react'
import axios from 'axios'
import { Button, Link } from '@mui/material'
import MerchantApi from 'api/MerchantApi'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { clientTheme } from 'theme-exports'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'

type TCSVMidUploadInputs = {
    merchant_id: number
    mids: null | File
}

/**
 * Component to facilitate bulk upload of new new MIDS.
 */
export const CSVMidUpload = ({
    merchantId,
    onModalClose,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: {
    merchantId: number
    onModalClose: () => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}): React.ReactElement => {
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const validationSchema = Yup.object().shape({
        merchant_id: Yup.number().positive().required(),
        mids: Yup.mixed().required(),
    })

    const defaultValues = useMemo<TCSVMidUploadInputs>(() => {
        return {
            merchant_id: merchantId,
            mids: null,
        }
    }, [merchantId])

    const {
        reset,
        watch,
        handleSubmit,
        setValue,
        formState: { isSubmitting, isValid },
        // TODO: Known React-Hook-Form typescript issue - Should be resolved with RHF's v8. Once resolved, need to switch typescript's "any" to "TCSVMidUploadInputs".
    } = useForm<any>({
        defaultValues: defaultValues,
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
    })

    const onSubmit: SubmitHandler<TCSVMidUploadInputs> = (values) => {
        return MerchantApi.addBulkMids(values)
            .then((data) => {
                setAlertSnackbarMainProps({
                    title: 'Success',
                    message: 'MIDs successfully uploaded.',
                    intent: 'success',
                })
                setAlertSnackbarMainOpen(true)
                handleOnClose()
            })
            .catch((err) => {
                setAlertSnackbarProps({
                    title: 'Error',
                    message:
                        err.response?.data?.message ??
                        'An error occurred while uploading MIDs.',
                    intent: 'error',
                })
                setAlertSnackbarOpen(true)
            })
    }

    const handleMidsCsvTemplate = async () => {
        try {
            const response = await axios.get(`/cm/templates/mids.csv`, {
                responseType: 'blob',
                headers: {
                    'Content-Type':
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
            })
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `mids-template.csv`)
            document.body.appendChild(link)
            link.click()
            link.remove()
            return
        } catch (error) {
            setAlertSnackbarProps({
                message: 'An unknown error has occurred.',
                title: 'Error.',
                intent: 'error',
            })
            setAlertSnackbarOpen(true)
        }
    }

    const handleSelectedValue = (key: 'mids', value: File | undefined) => {
        setValue(key, value ?? null, {
            shouldValidate: true,
            shouldDirty: true,
        })
    }

    const handleOnClose = () => {
        onModalClose()
        reset()
    }

    return (
        <div className={'emp-csvMidUpload-root'}>
            <Link
                className={'emp-csvMidUpload-downloadTemplateLink'}
                onClick={handleMidsCsvTemplate}
                sx={{ color: clientTheme.secondaryLight }}
            >
                Download Template
            </Link>
            <div className={'emp-csvMidUpload-root'}>
                <input
                    accept="text/csv"
                    id="bulk-mid-csv-file"
                    name="mids"
                    type="file"
                    className={'emp-csvMidUpload-fileInput'}
                    onChange={(e: any) => {
                        handleSelectedValue('mids', e.target.files[0])
                    }}
                />
                <label htmlFor="bulk-mid-csv-file">
                    <Button
                        className={'emp-csvMidUpload-csvBtn'}
                        variant="contained"
                        component="span"
                        sx={clientTheme.buttons.defaultButton.style}
                    >
                        + Add CSV
                    </Button>
                </label>
                {watch('mids') && (
                    <label>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                            disabled={isSubmitting || !isValid}
                        >
                            Submit
                        </Button>
                    </label>
                )}
            </div>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </div>
    )
}

export default CSVMidUpload

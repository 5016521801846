import React, { useEffect, useCallback, useState } from 'react'
import { Button, Tooltip } from '@mui/material';
import {
    DataGridActions,
    DataGridSlim,
    ExtendedColumn,
    ErrorBoundary,
    Searchbar,
} from 'components'
import { faLock, faUnlockAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import ProfileApi from 'api/ProfileApi'
import idDirectory from './idAttributes'
import { clientTheme } from 'theme-exports'

interface AccountHistoryProps {
    userId: number
    showUniqueLogins: boolean
    userIp?: string
}

const ToggleLockIconAction = ({
    blockId,
    blockIp,
    defaultLocked,
    setAlertSnackbarOpen,
    setAlertSnackbarProps,
    loadHistoryData,
    rowIndex,
}: {
    blockId: number
    blockIp: string
    defaultLocked: boolean
    setAlertSnackbarOpen: (status: boolean) => void
    setAlertSnackbarProps: (value: alertSnackbarContentProps) => void
    loadHistoryData: Function
    rowIndex: number
}): React.ReactElement => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const handleToggleIpLock = () => {
        ProfileApi.setLockLogin(defaultLocked, blockId, blockIp)
            .then(() => {
                loadHistoryData()
            })
            .catch(() => {
                setAlertSnackbarOpen(true)
                setAlertSnackbarProps({
                    title: 'Error',
                    message: `There was a problem ${
                        defaultLocked ? 'unlocking' : 'locking'
                    } this IP.`,
                    intent: 'error',
                })
            })
    }

    return (
        <Tooltip
            arrow
            placement="top"
            title={
                defaultLocked
                    ? 'Currently Locked, Click to Unlock'
                    : 'Currently Unlocked, Click To Lock'
            }
        >
            <Button
                id={`${idDirectory.btnLockAndUnlock}-${rowIndex}`}
                onClick={handleToggleIpLock}
                variant="contained"
                color="primary"
                className={`emp-accountHistory-lockUnlockBtn`}
                disabled={isReadOnly}
                sx={[
                    defaultLocked
                        ? clientTheme.lockAndUnlockButton.lockButton
                        : clientTheme.lockAndUnlockButton.unlockButton,
                ]}
            >
                {defaultLocked ? (
                    <>
                        <Icon icon={faUnlockAlt} id={idDirectory.iconUnlock} />
                        <span
                            className={
                                'emp-accountHistory-lockUnlockBtnText'
                            }
                        >
                            Unlock
                        </span>
                    </>
                ) : (
                    <>
                        <Icon icon={faLock} id={idDirectory.iconLock} />
                        <span
                            className={
                                'emp-accountHistory-lockUnlockBtnText'
                            }
                        >
                            Lock
                        </span>
                    </>
                )}
            </Button>
        </Tooltip>
    )
}

/**
 * Displays a users account ip access history
 */
const AccountHistory = ({ userId, showUniqueLogins }: AccountHistoryProps) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true)
    const [historyData, setHistoryData] = React.useState<any>([])
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})
    const [rowsPerPage, setRowsPerPage] = React.useState(25)
    const [pageStart, setPageStart] = React.useState(0)
    const [totalRecords, setTotalRecords] = React.useState(0)
    const [loginSearch, setLoginSearch] = React.useState<string>('')
    const loginSearchSetter = (value: string): void => {
        value.trim().length === 0 ? setLoginSearch('') : setLoginSearch(value)
    }

    const loadHistoryData = useCallback(() => {
        setIsLoading(true)
        const startingIndex = pageStart * rowsPerPage
        ProfileApi.getLoginEvents(
            userId,
            rowsPerPage,
            startingIndex,
            showUniqueLogins
        )
            .then((data) => {
                setHistoryData(data.data)
                setTotalRecords(data.pagination.total)
                setIsLoading(false)
            })
            .catch((err) => err)
    }, [pageStart, rowsPerPage, userId, showUniqueLogins])

    useEffect(() => {
        loadHistoryData()
    }, [userId, pageStart, rowsPerPage, loadHistoryData, showUniqueLogins])

    const handlePageSizeChange = (val: number) => {
        setRowsPerPage(val)
        setPageStart(0)
    }

    const handlePageChange = (val: number) => {
        setPageStart(val - 1)
    }

    const columnDefinitions: ExtendedColumn[] = [
        {
            Header: 'IP Address',
            accessor: 'ipAddress',
            type: 'string',
        },
        {
            Header: 'Date',
            accessor: 'date',
            type: 'string',
        },
        {
            Header: '',
            accessor: 'locked',
            type: 'icon',
            Cell: (params: any) => {
                return (
                    <ToggleLockIconAction
                        defaultLocked={params.row.original.locked}
                        blockId={params.row.original.blockId}
                        blockIp={params.row.original.ipAddress}
                        setAlertSnackbarProps={setAlertSnackbarProps}
                        setAlertSnackbarOpen={setAlertSnackbarOpen}
                        loadHistoryData={loadHistoryData}
                        rowIndex={params.row.id}
                    />
                )
            },
        },
    ]

    const [hiddenColumns, setHiddenColumns] = React.useState([])

    return (
        <ErrorBoundary area="the account history data grid">
            <div className={'emp-accountHistory-searchbarContainer'}>
                <Searchbar
                    className={'emp-accountHistory-searchbarInput'}
                    debounceDelay={200}
                    returnValue={loginSearchSetter}
                />
                <DataGridActions
                    gridName="loginHistory"
                    columns={columnDefinitions.filter(column => column.accessor !== 'locked')}
                    tableData={historyData}
                    hiddenColumns={hiddenColumns}
                    setHiddenColumns={setHiddenColumns}
                    dataActionExportAll={false}
                    dataActionPrint={true}
                    dataActionExcel={false}
                    onExportXlsxLoading={false}
                    // dataActionSwitchGrids={true}
                    testId="loginHx"
                    fileName={'Settings'}
                />
            </div>
            <DataGridSlim
                columns={columnDefinitions}
                data={historyData.filter((obj: any) => {
                    return obj.ipAddress.includes(loginSearch)
                })}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                expandable={false}
                slim={false}
                loading={isLoading}
                onPageLimitChange={handlePageSizeChange}
                onPageChange={handlePageChange}
                totalEntries={totalRecords}
                pageSize={rowsPerPage}
                page={pageStart + 1}
                noSelection={true}
                hiddenColumns={hiddenColumns}
                hiddenColumnsEnabled={true}
                testId="loginHx"
                stickyHeaders={false}
                refreshDataGrid={() => loadHistoryData()}
            />
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </ErrorBoundary>
    )
}

export default AccountHistory

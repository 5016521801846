/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import idDirectory from './idAttributes'
import {
    AppBar,
    Avatar,
    Button,
    Hidden,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Divider,
    // Link as MUILink,
    Typography,
} from '@mui/material'
import { Skeleton } from '@mui/material'
import { faIndent, faOutdent } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EntFeature from 'models/EntFeature'
import {
    Logo,
    ModalArea,
    // ERTNotificationAlert
} from 'components'
import { Profile, ProfileVariant } from 'components' // Keep using ent profile
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
// To prevent circular dependency, we don't import from components directly
import AccountSetup from 'components/AccountSetup'
import { clientTheme, iconsTheme } from 'theme-exports'
import Icon from 'components/Icon'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import { Helmet } from 'react-helmet'

export interface ITopBarProps {
    className?: string
    onSidebarOpen: () => void
    onSidebarClose: () => void
    openSidebar: boolean
    SignOutComponent?: React.ReactNode
    handleSignout: () => void
    pageTitle: string
    [key: string]: any
}

/**
 * Used mainly to pass through signout/logout button
 * e.g. from amplify
 * @todo Make the signout component a requirement in the future
 */
interface IUserMenuProps {
    className?: string
    id?: string
    SignOutComponent?: React.FC | React.ReactNode
}

/**
 * Open and close the profile and signout area
 */
export const UserMenu: React.FC<IUserMenuProps> = ({
    className = '',
    id = 'app-user-menu',
    SignOutComponent,
}: IUserMenuProps) => {
    const { user, loading } = useAuthedUser()

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )

    // Handle simply opening and closing of the avatar menu
    const handleUserMenuClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ): void => {
        setAnchorEl(event.currentTarget)
    }
    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Menu
                id={id}
                className={className}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <div>
                    <Profile variant={ProfileVariant.compact} />
                </div>
                <Divider />
                {!user?.is_federated && (
                    <>
                        <MenuItem
                            className={'emp-topBar-menuItem'}
                            onClick={handleMenuClose}
                        >
                            <Link
                                className={`${'emp-topBar-menuLink'} accented`}
                                to="/settings"
                            >
                                Profile
                            </Link>
                        </MenuItem>
                        <Divider />
                    </>
                )}
                <MenuItem
                    className={`${'emp-topBar-menuItem'} ${'emp-topBar-signOutBtn'}`}
                    onClick={handleMenuClose}
                >
                    {SignOutComponent}
                </MenuItem>
            </Menu>
            <Button
                aria-controls={id}
                aria-haspopup="true"
                onClick={handleUserMenuClick}
            >
                <Avatar
                    sx={(theme) => ({
                        color: theme.palette.getContrastText(
                            clientTheme.primaryDark
                        ),
                        backgroundColor: clientTheme.primaryDark,
                    })}
                >
                    {loading ? (
                        <Skeleton variant="text">&nbsp;&nbsp;</Skeleton>
                    ) : (
                        user?.initials() ?? user?.usernameInitials() ?? ''
                    )}
                </Avatar>
            </Button>
        </>
    )
}

/**
 * Top/header bar for app
 */
const TopBar = ({
    className = '',
    onSidebarOpen,
    onSidebarClose,
    openSidebar,
    SignOutComponent,
    handleSignout,
    pageTitle,
    ...rest
}: ITopBarProps): React.ReactElement => {
    const history = useHistory()
    const { invalidate, whoami } = useUiStateContext()
    const enableProfileLink = whoami?.hasRole(EntFeature.PROFILE) ?? false
    const { LOGO_IMAGE, LOGO_TEXT, PAGE_TITLE, TEXT_TITLE } = useFeatureToggle(
        'MAIN_TOPBAR'
    )
    const { enabled: themeVariationEnabled } = useFeatureToggle('HAS_THEME_VARIATIONS')
    const [openAccountSetup, setopenAccountSetup] = useState(false)
    const {
        user,
        loading,
        selectedUserId,
        selectedUsername,
        setSelectedUserId,
        setSelectedUsername,
    } = useAuthedUser()
    const closeModal = (): void => setopenAccountSetup((prev) => !prev)
    const cacheBusterVal = Math.round(new Date().getTime() / 1000)
    const publicUrl = process.env.PUBLIC_URL
    const BrandLogoPath = !themeVariationEnabled ?
        publicUrl + '/images/logos/brand-logo-lg.png'
    :
        user?.theme_variant && user.theme_variant > 1 ?
            publicUrl + `/images/logos/brand-logo-lg_${user.theme_variant}.png`
        :
            publicUrl + '/images/logos/brand-logo-lg.png'
    if (!user?.theme_variant && themeVariationEnabled) {
        return <></>
    }

    const enableAltThemeVariant = Boolean(user?.theme_variant && user?.theme_variant > 1)
    
    // Pull in the list ot notification alerts
    const FinalLogo = () => {
        if (!user?.theme_variant && themeVariationEnabled) return <></>
        return (
            <Logo
                alt="Chargebacks911"
                aria-label="Banner Logo"
                src={BrandLogoPath}
                className={`emp-topBar-cb911Logo`}
            />
        )
    }

    const handleBackasOriginalUser = async () => {
        if (setSelectedUserId) await setSelectedUserId(0)
        if (setSelectedUsername) await setSelectedUsername('')
        localStorage.removeItem('selectedUser')
        localStorage.removeItem('selectedUsername')

        await invalidate('whoami')

        history.push('/users')
    }

    // <UserMenu SignOutComponent={SignOutComponent} />
    //   <Typography
    //       component={MUILink}
    //       color="secondary"
    //       className={`emp-topBar-userSettingsLink`}
    //       style={{
    //           color: clientTheme.topBar.contrastText,
    //       }}
    //       href="#"
    //       onClick={() => setopenAccountSetup(true)}
    //   >
    //       Account Setup
    //   </Typography>
   
    return (
        <AppBar
            {...rest}
            className={`app-top-bar ${'emp-topBar-root'} ${className}`}
            style={{
                backgroundColor: clientTheme.topBar.backgroundColor,
                color: clientTheme.topBar.contrastText,
            }}
            id={idDirectory.appbar}
        >
            {themeVariationEnabled && user?.theme_variant ? (
                <Helmet>
                    <link
                        data-react-helmet="true"
                        rel="icon"
                        href={
                            user.theme_variant > 1
                                ? publicUrl +
                                  `/images/favicon_${user.theme_variant}.png?cb=${cacheBusterVal}`
                                : publicUrl +
                                  `/images/favicon_default.png?cb=${cacheBusterVal}`
                        }
                    />
                    <link
                        data-react-helmet="true"
                        rel="apple-touch-icon"
                        href={
                            user.theme_variant > 1
                                ? publicUrl +
                                  `/images/touch-icon_${user.theme_variant}.png?cb=${cacheBusterVal}`
                                : publicUrl +
                                  `/images/touch-icon_default.png?cb=${cacheBusterVal}`
                        }
                    />
                </Helmet>
            ) : (
                <Helmet>
                    <link
                        data-react-helmet="true"
                        rel="icon"
                        href={
                            publicUrl +
                            `/images/favicon.png?cb=${cacheBusterVal}`
                        }
                    />
                    <link
                        data-react-helmet="true"
                        rel="apple-touch-icon"
                        href={
                            publicUrl +
                            `/images/touch-icon.png?cb=${cacheBusterVal}`
                        }
                    />
                </Helmet>
            )}
            <Toolbar id={idDirectory.toolbar}>
                {/* Hamburger sidebar-open icon */}
                <IconButton
                    id={idDirectory.btnIconBars}
                    color="inherit"
                    onClick={openSidebar ? onSidebarClose : onSidebarOpen}
                    size="large"
                >
                    {openSidebar ? (
                        iconsTheme.bars ? (
                            <Icon className={`${iconsTheme.bars} `} />
                        ) : (
                            <FontAwesomeIcon
                                className={'emp-topBar-menuBarIcon'}
                                icon={faOutdent}
                                id={idDirectory.iconBars}
                                style={{
                                    color: enableAltThemeVariant
                                        ? '#afafaf'
                                        : clientTheme.topBar.menuBarIcon.color,
                                }}
                            />
                        )
                    ) : (
                        <FontAwesomeIcon
                            className={'emp-topBar-menuBarIcon'}
                            icon={faIndent}
                            id={idDirectory.iconBars}
                            style={{
                                color: enableAltThemeVariant
                                    ? '#afafaf'
                                    : clientTheme.topBar.menuBarIcon.color,
                            }}
                        />
                    )}
                </IconButton>

                {LOGO_IMAGE.enabled && (
                    <Link
                        id={idDirectory.linkFinalLogo}
                        className={'emp-topBar-logoLink'}
                        to="/"
                    >
                        {FinalLogo()}
                    </Link>
                )}
                {LOGO_TEXT.enabled && (
                    <Link
                        id={idDirectory.linkFinalLogoText}
                        className={'emp-topBar-logoLinkText'}
                        to="/"
                    >
                        <Typography
                            color={LOGO_TEXT.logo_color}
                            variant="h2"
                            style={{
                                marginLeft: LOGO_IMAGE.enabled ? '8px' : 0,
                                fontWeight: LOGO_IMAGE.enabled ? 500 : 600,
                            }}
                        >
                            {LOGO_TEXT.logo_text}
                        </Typography>
                    </Link>
                )}
                {PAGE_TITLE.enabled && (
                    <div className={'emp-topBar-pageTitleContainer'}>
                        <Typography
                            className={'emp-topBar-pageTitleText'}
                            variant="h4"
                        >
                            |
                        </Typography>
                        <Typography
                            className={'emp-topBar-pageTitleText'}
                            variant="h5"
                        >
                            {pageTitle}
                        </Typography>
                    </div>
                )}
                {TEXT_TITLE.enabled && (
                    <div className={'emp-topBar-pageTitleContainer'}>
                        <Typography
                            className={'emp-topBar-pageTitleText'}
                            variant="h4"
                        >
                            |
                        </Typography>
                        <Typography
                            className={'emp-topBar-pageTitleText'}
                            variant="h5"
                        >
                            {TEXT_TITLE.text_title}
                        </Typography>
                    </div>
                )}
                {/* flexGrow to up one ratio unit of space */}
                <div
                    id={idDirectory.divFlexGrow}
                    className={'emp-topBar-flexGrow'}
                />
                {/* <ERTNotificationAlert /> */}
                <Hidden smDown>
                    <div
                        id={idDirectory.divUserSettings}
                        className={'emp-topBar-userSettings'}
                    >
                        <div
                            id={idDirectory.divUserSettingsRow}
                            className={'emp-topBar-userSettingsRow'}
                        >
                            {loading ? (
                                <Skeleton
                                    id={idDirectory.skeleton}
                                    variant="text"
                                    width={120}
                                />
                            ) : (
                                <div
                                    id={idDirectory.divIcon}
                                    className={'emp-topBar-icon'}
                                >
                                    <Icon
                                        id={idDirectory.iconUser}
                                        className={`${
                                            iconsTheme.singleConsumerStyle
                                        } ${'emp-topBar-userIcon'}`}
                                        iconColor={clientTheme.topBar.userIcon}
                                    />
                                    <Typography
                                        id={idDirectory.typographyUser}
                                        color="textPrimary"
                                        className={'emp-topBar-username'}
                                        style={{
                                            color:
                                                clientTheme.topBar.contrastText,
                                        }}
                                    >
                                        {user?.personRepresentation()}
                                        {/* //todo change to selected user name */}
                                        {selectedUserId !== 0 &&
                                            ` as (${selectedUsername})`}
                                    </Typography>
                                </div>
                            )}
                        </div>
                        <div
                            id={idDirectory.divUser}
                            className={'emp-topBar-userSettingsRow'}
                        >
                            {/* Remove Tooltips when link paths are active */}
                            {!user?.is_federated && enableProfileLink && (
                                <Link
                                    id={idDirectory.linkProfile}
                                    to="/settings"
                                >
                                    <Typography
                                        className={`emp-topBar-userSettingsLink`}
                                        style={{
                                            color:
                                                clientTheme.topBar
                                                    .contrastLinkText,
                                        }}
                                    >
                                        Profile
                                    </Typography>
                                </Link>
                            )}
                            {!user?.is_federated && (
                                <Link
                                    id={idDirectory.typographySignout}
                                    to="#"
                                    onClick={handleSignout}
                                >
                                    <Typography
                                        className={`emp-topBar-userSettingsLink`}
                                        style={{
                                            color:
                                                clientTheme.topBar.contrastLinkText,
                                        }}
                                    >
                                        Sign Out
                                    </Typography>
                                </Link>
                            )}
                            {selectedUsername && (
                                <Typography
                                    id={idDirectory.typographyBackAsUser}
                                    className={`emp-topBar-userSettingsLink`}
                                    style={{
                                        color:
                                            clientTheme.topBar.contrastLinkText,
                                    }}
                                    onClick={handleBackasOriginalUser}
                                >
                                    {`Back as ${user?.personRepresentation()}`}
                                </Typography>
                            )}
                        </div>
                    </div>
                </Hidden>
                <ModalArea
                    id={idDirectory.modalArea}
                    title="Account Setup"
                    open={openAccountSetup}
                    closeModal={closeModal}
                    maxWidth="md"
                >
                    <AccountSetup />
                </ModalArea>
            </Toolbar>
        </AppBar>
    )
}

export default TopBar

/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button, IconButton, Typography, Box, Tooltip } from '@mui/material'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import uploadIcon from 'assets/icons/upload-icon-bg-alt.png'
import useIsMounted from 'hooks/utils/useIsMounted'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { clientTheme } from 'theme-exports'
import { SearchableSelect, LoadingIndicator } from 'components'
import idDirectory from './idAttributes'

export type TTemplateOption = {
    id: number
    name?: string
    file?: { [key: string]: any }
} & Record<string, unknown>

export interface FileDnDNewProps {
    onDrop: (fileList: File[]) => void
    icon?: string
    accepts?: string[] | string
    fileUploadInstructionText?: string
    fileUploadCriteria?: string
    maxUploadFileSize?: number
    enableCustomFileDnDFeature?: boolean
    customFileDnDFeature?: React.ReactNode
}

/**
 * Drag and drop file upload area.
 *
 * @param props {@link FileDnDNewProps}
 */
export const FileDnDNew = ({
    onDrop,
    icon = uploadIcon,
    accepts,
    fileUploadInstructionText,
    fileUploadCriteria,
    maxUploadFileSize = 5242880,
    enableCustomFileDnDFeature = false,
    customFileDnDFeature,
}: FileDnDNewProps): JSX.Element => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: accepts,
        maxSize: maxUploadFileSize,
        maxFiles: 1,
        onDrop,
        disabled: isReadOnly,
    })

    return !enableCustomFileDnDFeature ? (
        <Box
            id={idDirectory.fileDnDNew.divRoot}
            {...(getRootProps() as any)} // TODO: figure why this type error is happening
            className={`${'emp-fileDnDNew-root'} ${
                isReadOnly && 'emp-fileDnDNew-disabledRoot'
            }`}
            style={{ backgroundImage: `url(${icon})` }}
            sx={{
                '&:hover': {
                    borderColor: clientTheme.uploadDnD.borderColor,
                    backgroundColor: clientTheme.uploadDnD.backgroundColor,
                    backgroundSize: 240,

                    '& .MuiTypography-body1': {
                        color: clientTheme.uploadDnD.labelColor,
                    },
                },
            }}
        >
            <input id="dz-input" {...getInputProps()} />
            <div className={'emp-fileDnDNew-label'}>
                {isDragActive ? (
                    <Typography variant="h6">
                        Drop the files here ...
                    </Typography>
                ) : (
                    <React.Fragment>
                        <Typography>
                            {fileUploadInstructionText
                                ? fileUploadInstructionText
                                : 'Drag & drop to upload your case document or click to browse'}
                        </Typography>
                        {fileUploadCriteria && (
                            <Typography
                                className={'emp-fileDnDNew-fileUploadCriteria'}
                            >
                                {fileUploadCriteria}
                            </Typography>
                        )}
                    </React.Fragment>
                )}
            </div>
        </Box>
    ) : (
        <div
            className={'emp-fileDnDNew-customFileDnDFeatureContainer'}
            id={idDirectory.fileDnDNew.divRootCustom}
        >
            {customFileDnDFeature}
        </div>
    )
}

interface FileUploadNewProps {
    onFileDragAndDrop: (fileList: File[]) => void
    loadingFileDragAndDrop: boolean
    onUnstage: (val: any) => void
    /** file types to accept */
    accepts: string | string[]
    onTemplateListSelect?: (template: any) => void
    onReorderCombinedDocuments?: (val: any) => void
    /** Text to display in file upload component */
    fileUploadInstructionText?: string
    /** Is this running in Chargebacks - upload representment modal? */
    isRepresentmentModal?: boolean
    /** Will be passed if this is running in representment upload modal */
    templatesList?: any
    loadingTemplatesList?: boolean
    combinedDocuments?: any
    isMultiSelect?: boolean
    enableFullWidth?: boolean
    isLargeScreen?: boolean
}

/**
 * helper to format byte size to a human readable form.
 *
 * @param bytes - size of file in number of bytes.
 *
 * @returns {string} - human readable size string i.e 1.3KB, 1MB.
 */
function bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`
}

/**
 * Use to upload files in the browser.
 *
 */
export const FileUploadNew = ({
    onFileDragAndDrop,
    loadingFileDragAndDrop,
    onUnstage,
    accepts,
    onTemplateListSelect = () => {},
    onReorderCombinedDocuments = () => {},
    fileUploadInstructionText,
    isRepresentmentModal = false,
    templatesList,
    loadingTemplatesList = false,
    combinedDocuments,
    isMultiSelect = false,
    enableFullWidth = true,
    isLargeScreen = true,
}: FileUploadNewProps): JSX.Element => {
    const { client } = useFeatureToggle('CLIENT')

    const [
        selectedTemplate,
        setSelectedTemplate,
    ] = useState<TTemplateOption | null>(null)
    const [documentDisplayTitles, setDocumentDisplayTitles] = useState([])

    const { isMounted } = useIsMounted()

    // with multiple cases are selected for doc upload, each case gets the same document which results in duplicate combined files - this removes any duplicates so that the document names can be displayed correctly in the DnD below
    useEffect(() => {
        if (isMounted.current && combinedDocuments?.length) {
            // if multi select, the combined documents come in as an array of case ids with attached files, we just need the file names, so we can hard target the first index case of combinedDocuments to read the file array within to display what files have been uploaded
            isMultiSelect
                ? setDocumentDisplayTitles(combinedDocuments[0].files)
                : setDocumentDisplayTitles(combinedDocuments)
        }
    }, [combinedDocuments, isMultiSelect, isMounted])

    return (
        <div
            className={'emp-fileUploadNew-root'}
            id={idDirectory.divRoot}
            style={{ fontSize: clientTheme.typography.body1.fontSize }}
        >
            {isRepresentmentModal && (
                <div id={idDirectory.divTemplateLib}>
                    <Typography className={'emp-fileUploadNew-templateTitle'}>
                        Template Documents
                    </Typography>
                    <div
                        className={
                            'emp-fileUploadNew-templateLibSelectContainer'
                        }
                        id={idDirectory.divTemplateLibSelect}
                    >
                        <SearchableSelect
                            accessor="name"
                            value={selectedTemplate?.name}
                            options={templatesList ?? []}
                            hideSearch
                            onValueChange={(e) => {
                                setSelectedTemplate(e)
                            }}
                            placeholder={'Select a template'}
                            testId="templateDocuments"
                            loadingValues={loadingTemplatesList}
                            selectFontSize={
                                clientTheme.typography.body1.fontSize
                            }
                        >
                            {!templatesList.length && !loadingTemplatesList && (
                                <div
                                    className={
                                        'emp-fileUploadNew-searchableSelectText'
                                    }
                                >
                                    No templates available.
                                </div>
                            )}
                        </SearchableSelect>

                        <Button
                            id={idDirectory.btnTemplateLibAdd}
                            color="secondary"
                            variant="contained"
                            onClick={() =>
                                onTemplateListSelect(selectedTemplate)
                            }
                            sx={[
                                client === 'jpmc' &&
                                    clientTheme.buttons.greyButton.style,
                            ]}
                            disabled={!selectedTemplate}
                        >
                            Add
                        </Button>
                    </div>
                </div>
            )}
            <FileDnDNew
                fileUploadInstructionText={fileUploadInstructionText}
                onDrop={onFileDragAndDrop}
                accepts={accepts}
                fileUploadCriteria="Supports: PDF, JPEG"
                maxUploadFileSize={5242880 * 2}
            />
            {loadingFileDragAndDrop && (
                <div className="emp-fileUploadNew-loadingContainer">
                    <LoadingIndicator text={'Uploading...'} />
                </div>
            )}
            {documentDisplayTitles.length ? (
                <DragDropContext onDragEnd={onReorderCombinedDocuments}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={'emp-fileUploadNew-filesContainer'}
                                style={{
                                    gridTemplateColumns:
                                        documentDisplayTitles.length < 2
                                            ? '1fr'
                                            : '1fr 1fr',
                                }}
                                id={idDirectory.divFiles}
                            >
                                {documentDisplayTitles.map(
                                    (doc: any, idx: number) => (
                                        <Draggable
                                            key={`key-doc-${idx}`}
                                            draggableId={`doc-${idx}`}
                                            index={idx}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div
                                                        className={
                                                            'emp-fileUploadNew-fileContainer '
                                                        }
                                                        id={`${idDirectory.divFile}-${idx}`}
                                                    >
                                                        <div
                                                            className={
                                                                'emp-fileUploadNew-fileInfoContainer'
                                                            }
                                                            id={`${idDirectory.divFileInfo}-${idx}`}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faCheckCircle
                                                                }
                                                                className={
                                                                    'emp-fileUploadNew-fileCheckIcon'
                                                                }
                                                            />
                                                            <div
                                                                className={
                                                                    'emp-fileUploadNew-fileDetailsContainer '
                                                                }
                                                                id={`${idDirectory.divFileDetails}-${idx}`}
                                                            >
                                                                <Tooltip
                                                                    title={
                                                                        doc.label
                                                                    }
                                                                    placement="top"
                                                                    arrow
                                                                >
                                                                    <Typography
                                                                        sx={[
                                                                            isLargeScreen
                                                                                ? {
                                                                                      width:
                                                                                          documentDisplayTitles.length <
                                                                                          2
                                                                                              ? enableFullWidth
                                                                                                  ? '475px'
                                                                                                  : '335px'
                                                                                              : enableFullWidth
                                                                                              ? '195px'
                                                                                              : '125px',
                                                                                  }
                                                                                : {
                                                                                      width:
                                                                                          documentDisplayTitles.length <
                                                                                          2
                                                                                              ? '325px'
                                                                                              : '120px',
                                                                                  },
                                                                        ]}
                                                                    >
                                                                        {
                                                                            doc.label
                                                                        }
                                                                    </Typography>
                                                                </Tooltip>
                                                                <Typography
                                                                    style={{
                                                                        fontSize:
                                                                            clientTheme
                                                                                .typography
                                                                                .body2
                                                                                .fontSize,
                                                                    }}
                                                                >
                                                                    {`(${bytesToSize(
                                                                        doc.size
                                                                    )})`}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <IconButton
                                                                size="small"
                                                                onClick={(): void =>
                                                                    onUnstage(
                                                                        idx
                                                                    )
                                                                }
                                                                className={
                                                                    'emp-fileUploadNew-fileUnstageIcon'
                                                                }
                                                                id={`${idDirectory.btnFileUnstage}-${idx}`}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faTimes
                                                                    }
                                                                />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : null}
        </div>
    )
}

export default FileUploadNew

import React from 'react'
import { Button, Typography } from '@mui/material'
import { clientTheme } from 'theme-exports'

interface CompellingEvidenceLauncherNewProps {
    isRemedyPossible: boolean
    setCompellingEvidenceModalOpen: (
        open: boolean,
        closeRepresentment?: boolean
    ) => void
}

const CompellingEvidenceLauncherNew = ({
    isRemedyPossible,
    setCompellingEvidenceModalOpen,
}: CompellingEvidenceLauncherNewProps) => {
    return (
        <div
            className={'emp-compellingEvidenceLauncherNew-root'}
            style={{ fontSize: clientTheme.typography.body1.fontSize }}
        >
            {isRemedyPossible ? (
                <div>
                    <Typography
                        className={'emp-compellingEvidenceLauncherNew-title'}
                    >
                        Eligible for CE3.0 Remedy
                    </Typography>
                    <div
                        className={
                            'emp-compellingEvidenceLauncherNew-paragraphContainer'
                        }
                    >
                        <p
                            className={
                                'emp-compellingEvidenceLauncherNew-paragraph'
                            }
                        >
                            This transaction is eligible to be remedied under
                            the new rules from Visa. Compelling Evidence 3.0
                            defends against illegitimate claims of fraud using
                            Visa Reason Code 10.4 where there is a history of
                            prior transactions that were not disputed by the
                            cardholder. Merchants will need to identify two
                            additional transactions processed between 120 and
                            365 days prior to the disputed transaction with
                            matching details.
                        </p>
                        <p
                            className={
                                'emp-compellingEvidenceLauncherNew-paragraph'
                            }
                        >
                            Proceed with a new dispute remedy below.
                        </p>
                    </div>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            setCompellingEvidenceModalOpen(true)
                        }}
                    >
                        Start a CE 3.0 Remedy
                    </Button>
                </div>
            ) : (
                <div>
                    <p
                        className={
                            'emp-compellingEvidenceLauncherNew-paragraph'
                        }
                    >
                        Unfortunately this Case does not qualify for the Remedy
                        option. You can still defend the Dispute with documents.
                    </p>
                </div>
            )}
        </div>
    )
}

export default CompellingEvidenceLauncherNew

import React from 'react'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import { MidPermissions as MidPermissionsOptions, ModalArea } from 'components'

interface IMidPermissionsProps {
    userModel: any
    modalOpen: boolean
    setModalOpen: (isopen: boolean) => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export const MidPermissions: React.FC<IMidPermissionsProps> = ({
    userModel,
    modalOpen,
    setModalOpen,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}) => {
    const merchantId = userModel.merchant.id
    const userId = userModel.id

    return (
        <ModalArea
            title="MID Permissions"
            open={modalOpen}
            closeModal={() => setModalOpen(false)}
            maxWidth="sm"
        >
            <div id={'usersMidPermissions'}>
                <MidPermissionsOptions
                    label="MID Permissions"
                    variant="outlined"
                    merchantId={merchantId}
                    userId={userId}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                    setModalOpen={setModalOpen}
                />
            </div>
        </ModalArea>
    )
}

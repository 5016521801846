import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import { clientTheme } from 'theme-exports'
import { SearchableSelect } from 'components'
import React, { useState } from 'react'
import {
    AlertsAutoRefundParam,
    Param,
    useAlertsAutoRefund,
} from './useAlertsAutoRefund'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { PopoverRangeSelect } from '../BulkClose/PopoverRangeSelect'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'

interface AlertsAutoRefundModalProps {
    onClose: () => void
}

export const AlertsAutoRefundModal: React.FC<AlertsAutoRefundModalProps> = ({
    onClose,
}) => {
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const {
        getParamByKey,
        params,
        addParam,
        submit,
        confirm,
        removeParam,
        setCurrenciesSearchValue,
        currenciesSearchValue,
        currencies,
        setSourcesSearchValue,
        sourcesSearchValue,
        sources,
        setCardTypesSearchValue,
        cardTypesSearchValue,
        cardTypes,
        midDescriptors,
        setMidDescriptorsSearchValue,
        midDescriptorsSearchValue,
        jobConfirmation,
        cancel,
        isSubmitDisabled,
        currentScheduledJob,
        lastScheduledJob,
    } = useAlertsAutoRefund()

    const handleConfirm = () => {
        confirm()
            .then(() => {
                setAlertSnackbarProps({
                    intent: 'success',
                    message: 'Successfully submitted job',
                    title: 'Success',
                })
            })
            .catch(() => {
                setAlertSnackbarProps({
                    intent: 'error',
                    message: 'Error confirming job',
                    title: 'Error',
                })
            })
            .finally(() => {
                setAlertSnackbarOpen(true)
                cancel()
            })
    }

    return (
        <div className={'emp-alertsAutoRefundModal-root'}>
            <h4>Criteria</h4>
            <div className={'emp-alertsAutoRefundModal-selectors'}>
                <PopoverRangeSelect
                    placeholder="Amount"
                    min={
                        getParamByKey(AlertsAutoRefundParam.AMOUNT)?.value
                            .min === 'undefined'
                            ? ''
                            : getParamByKey(AlertsAutoRefundParam.AMOUNT)?.value
                                  .min
                    }
                    max={
                        getParamByKey(AlertsAutoRefundParam.AMOUNT)?.value
                            .max === 'undefined'
                            ? ''
                            : getParamByKey(AlertsAutoRefundParam.AMOUNT)?.value
                                  .max
                    }
                    onChange={(range) =>
                        addParam(AlertsAutoRefundParam.AMOUNT, range)
                    }
                    disabled={Boolean(currentScheduledJob)}
                    required
                    testId={'amount'}
                />
                <SearchableSelect
                    placeholder="Currency"
                    value={
                        getParamByKey(AlertsAutoRefundParam.CURRENCY)
                            ?.displayValue === 'undefined - undefined'
                            ? ''
                            : getParamByKey(AlertsAutoRefundParam.CURRENCY)
                                  ?.displayValue
                    }
                    onValueChange={(value: any) =>
                        addParam(AlertsAutoRefundParam.CURRENCY, value.currency)
                    }
                    options={currencies as any}
                    onSearchChange={setCurrenciesSearchValue}
                    searchValue={currenciesSearchValue}
                    accessor="currency"
                    disabled={Boolean(currentScheduledJob)}
                    required
                />
                {/* //todo: Replace with sources endpoint */}
                <SearchableSelect
                    placeholder="Source"
                    value={
                        getParamByKey(AlertsAutoRefundParam.SOURCE)
                            ?.displayValue === 'undefined - undefined'
                            ? ''
                            : getParamByKey(AlertsAutoRefundParam.SOURCE)
                                  ?.displayValue
                    }
                    onValueChange={(value: any) =>
                        addParam(AlertsAutoRefundParam.SOURCE, value.code)
                    }
                    options={sources as any}
                    onSearchChange={setSourcesSearchValue}
                    searchValue={sourcesSearchValue}
                    accessor="code"
                    disabled={Boolean(currentScheduledJob)}
                    required
                />
                <SearchableSelect
                    placeholder="Card Type"
                    value={
                        getParamByKey(AlertsAutoRefundParam.CARD_TYPE)
                            ?.displayValue === 'undefined - undefined'
                            ? ''
                            : getParamByKey(AlertsAutoRefundParam.CARD_TYPE)
                                  ?.displayValue
                    }
                    onValueChange={(value: any) =>
                        addParam(AlertsAutoRefundParam.CARD_TYPE, value.name)
                    }
                    options={cardTypes as any}
                    onSearchChange={setCardTypesSearchValue}
                    searchValue={cardTypesSearchValue}
                    accessor="name"
                    disabled={Boolean(currentScheduledJob)}
                    required
                />
                <SearchableSelect
                    placeholder="MID-Descriptor"
                    value={
                        getParamByKey(AlertsAutoRefundParam.MID_DESCRIPTOR)
                            ?.displayValue === 'undefined - undefined'
                            ? ''
                            : getParamByKey(
                                  AlertsAutoRefundParam.MID_DESCRIPTOR
                              )?.displayValue
                    }
                    onValueChange={(value: any) =>
                        addParam(
                            AlertsAutoRefundParam.MID_DESCRIPTOR,
                            value.descriptor
                        )
                    }
                    options={midDescriptors as any}
                    onSearchChange={setMidDescriptorsSearchValue}
                    searchValue={midDescriptorsSearchValue}
                    accessor="descriptor"
                    disabled={Boolean(currentScheduledJob)}
                    required
                />
            </div>
            <h4>Applied Criteria</h4>
            <div className={'emp-alertsAutoRefundModal-badges'}>
                {params
                    .filter((param) => param.value)
                    .map((i) => (
                        <Badge
                            key={i.name}
                            param={i}
                            onClear={(i) => removeParam(i.name)}
                            disabled={Boolean(currentScheduledJob)}
                        />
                    ))}
            </div>

            <div className={'emp-alertsAutoRefundModal-actions'}>
                {currentScheduledJob && (
                    <span>
                        <i>{currentScheduledJob}</i>
                    </span>
                )}
                {lastScheduledJob && (
                    <span
                        className={
                            'emp-alertsAutoRefundModal-lastScheduledJob'
                        }
                    >
                        <i>{lastScheduledJob}</i>
                    </span>
                )}
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={submit}
                    disabled={isSubmitDisabled}
                >
                    Run Action
                </Button>
            </div>
            {Boolean(jobConfirmation) && (
                <Dialog open={true}>
                    <DialogTitle>Confirm Action</DialogTitle>
                    <DialogContent>
                        <>
                            {jobConfirmation?.records_num
                                ? `This will effect ${jobConfirmation?.records_num} records.`
                                : `No records will be affected. Please try different criteria.`}
                        </>
                        <DialogActions>
                            <Button
                                variant="contained"
                                onClick={cancel}
                                sx={clientTheme.buttons.defaultButton.style}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={!jobConfirmation?.records_num}
                                variant="contained"
                                color="secondary"
                                onClick={handleConfirm}
                            >
                                Accept
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            )}
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </div>
    )
}

interface BadgeProps {
    param: Param
    onClear?: (badge: Param) => void
    disabled?: boolean
}

const Badge: React.FC<BadgeProps> = ({
    param,
    onClear = () => {},
    disabled = false,
}) => {
    return (
        <div
            className={'emp-alertsAutoRefundModal-badgeRoot'}
            style={{
                color: disabled ? 'gray' : 'inherit',
                border: disabled
                    ? 'solid 1px gray'
                    : 'solid 1px rgb(98, 194, 234)',
            }}
        >
            <span className={'emp-alertsAutoRefundModal-badgeText'}>
                <b>{param.label}:</b>
                {param.displayValue}
            </span>
            <Icon
                icon={faTimes}
                className={'emp-alertsAutoRefundModal-badgeCloseIcon'}
                onClick={() => {
                    if (!disabled) {
                        onClear(param)
                    }
                }}
                style={{ cursor: disabled ? 'default' : 'pointer' }}
            />
        </div>
    )
}

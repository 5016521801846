import React, { useMemo } from 'react'
import ByAverageSpan from './ByAverageSpan'
import ByBin from './ByBin'
import ByIssuer from './ByIssuer'
import ByCardType from './ByCardType'
import ByPlatform from './ByPlatform'
import ByPlatformPerCardType from './ByPlatformPerCardType'
import ByReasonCodeDistribution from './ByReasonCodeDistribution'
import ByReasonCode from './ByReasonCode'
import ByTransactionAmount from './ByTransactionAmount'
import ByTransactionMonth from './ByTransactionMonth'
import ByLocationType from './ByLocationType'
import ByNonUniqueCustomer from './ByNonUniqueCustomer'
import ByTransactionDateWeek from './ByTransactionDateWeek'
import ByStatus from './ByStatus/ByStatus'
import ByWinLoss from './ByWinLoss/ByWinLoss'
import { IReportFilter } from '../Reports'
import { ReportsChartVariant } from '../../../../shared'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'

interface ReportsCharts {
    chartMap: ChartVariantMap<React.ReactElement>
    chartSectionKeys: string[]
}

interface ReportsChartsProps {
    filterValues: IReportFilter
    default_date_range: {
        [key: string]: {
            enabled: boolean
            value: number
        }
    }
}

type ChartVariantMap<Chart> = { [chart in ReportsChartVariant]: Chart }

const useReportsCharts = ({
    filterValues,
    default_date_range,
}: ReportsChartsProps): ReportsCharts => {
    const { user } = useAuthedUser()
    const { CHARTS = false } = useFeatureToggle('REPORTS')

    const {
        merchant_id,
        mid_id,
        currency_id,
        date_reference,
        date_range,
        gateway_id,
        platforms,
    } = filterValues

    const filterValuesMemo = useMemo(
        () => filterValues,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            merchant_id,
            mid_id,
            currency_id,
            date_reference,
            date_range,
            gateway_id,
            platforms,
        ]
    )

    const chartMap: ChartVariantMap<React.ReactElement> = {
        reasonCodePctTop5Donut: (
            <ByReasonCode filterValues={filterValuesMemo} />
        ),
        cbByIssuerPctTop5Donut: (
            <ByIssuer filterValues={filterValuesMemo} topN={5} />
        ),
        cbByIssuerPctTop10Donut: (
            <ByIssuer filterValues={filterValuesMemo} topN={10} />
        ),
        cardTypeDonut: <ByCardType filterValues={filterValuesMemo} />,
        reasonCodeDistributionDonut: (
            <ByReasonCodeDistribution filterValues={filterValuesMemo} />
        ),
        cbByTransMonthPctLine: (
            <ByTransactionMonth
                filterValues={filterValuesMemo}
                isLineChart
                // defaultDateRange={default_date_range?.cbByTransMonthPctLine}
            />
        ),
        cbByTransMonthPctArea: (
            <ByTransactionMonth filterValues={filterValuesMemo} />
        ),
        cbByTransAmountPctBar: (
            <ByTransactionAmount filterValues={filterValuesMemo} />
        ),
        averageSpanCbTransPostDatebyWeekPctLine: (
            <ByAverageSpan filterValues={filterValuesMemo} isLineChart />
        ),
        averageSpanCbTransPostDatebyWeekPctArea: (
            <ByAverageSpan filterValues={filterValuesMemo} />
        ),
        cbByCardTypePerPlatformPctTop5Bar: (
            <ByPlatformPerCardType filterValues={filterValuesMemo} />
        ),
        cbByPlatformPctTop5Bar: <ByPlatform filterValues={filterValuesMemo} />,
        cbByBinPctTop5Bar: <ByBin filterValues={filterValuesMemo} topN={5} />,
        cbByBinPctTop10Bar: <ByBin filterValues={filterValuesMemo} topN={10} />,
        domesticVsOffshore: <ByLocationType filterValues={filterValuesMemo} />,
        cbTransRatioBytransDateWeekPctLine: (
            <ByTransactionDateWeek
                filterValues={filterValuesMemo}
                isLineChart
            />
        ),
        cbTransRatioBytransDateWeekPctArea: (
            <ByTransactionDateWeek filterValues={filterValuesMemo} />
        ),
        percentCBNonUniqueCustomer: (
            <ByNonUniqueCustomer filterValues={filterValuesMemo} />
        ),
        cbByStatus: <ByStatus filterValues={filterValuesMemo} />,
        cbByWinLoss: <ByWinLoss filterValues={filterValuesMemo} />,
    }

    // returns section keys used to create sections in the reports view

    const chartSectionKeys =
        //@ts-ignore
        user?.role?.alias === 'merchant_admin'
            ? Object.keys(CHARTS.MERCHANT_ADMIN?.charts_and_position ?? {})
            : Object.keys(CHARTS.PARTNER_ADMIN?.charts_and_position ?? {})

    return {
        chartMap,
        chartSectionKeys,
    }
}

export default useReportsCharts

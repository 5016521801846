import React from 'react'
import {
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material'
import { LoadingIndicator } from 'components'

interface MUIDataGridProps {
    className?: string
    data?: any[]
    columns?: any[]
    testId?: string
    loading?: boolean
    pageSize?: number
    maxGridHeight?: number
}

const MUIDataGrid = ({
    className,
    data,
    columns,
    testId,
    loading = false,
    pageSize = 5,
    maxGridHeight = 192,
}: MUIDataGridProps): React.ReactElement => {
    const [page, setPage] = React.useState<number>(0)
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(pageSize)

    return (
        <div className={`${className}`} {...(testId ? { id: testId } : {})}>
            <TableContainer
                className={'emp-muiDataGrid-tableContainer'}
                style={{ maxHeight: maxGridHeight }}
            >
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns &&
                                columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell
                                    colSpan={columns ? columns.length : 1}
                                    align="center"
                                >
                                    <LoadingIndicator />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {data?.length ? (
                                    data
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row: any, idx: number) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={`${row.code}-${idx}`}
                                                >
                                                    {columns &&
                                                        columns.map(
                                                            (column) => {
                                                                const value =
                                                                    row[
                                                                        column
                                                                            .id
                                                                    ]
                                                                return (
                                                                    <TableCell
                                                                        key={
                                                                            column.id
                                                                        }
                                                                        align={
                                                                            column.align
                                                                        }
                                                                    >
                                                                        {column.format &&
                                                                        typeof value ===
                                                                            'number'
                                                                            ? column.format(
                                                                                  value
                                                                              )
                                                                            : value}
                                                                    </TableCell>
                                                                )
                                                            }
                                                        )}
                                                </TableRow>
                                            )
                                        })
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={
                                                columns ? columns.length : 1
                                            }
                                            align="center"
                                        >
                                            No Records Found!
                                        </TableCell>
                                    </TableRow>
                                )}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Divider />
            <div className={'emp-muiDataGrid-tablePaginationContainer'}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    count={data ? data.length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(_, val: number) => setPage(val)}
                    onRowsPerPageChange={(ev) => {
                        setRowsPerPage(+ev.target.value)
                        setPage(0)
                    }}
                />
            </div>
        </div>
    )
}

export default MUIDataGrid

import React, { useState } from 'react'
import { Dialog, DialogContent, Button, TextField } from '@mui/material'
import { clientTheme } from 'theme-exports'
import useFlagCaseModal from './useFlagCaseModal'
import { ModalMode, ModalVariant, CaseData } from 'hooks/cb-api'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface FlagModalProps {
    open: boolean
    toggleOpen: (variant: ModalVariant, mode?: ModalMode) => void
    isMultiSelectMode: boolean
    selectedCaseIds: number[]
    caseModel?: CaseData
    rehydrateView: () => void
    unflagCases: boolean
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    unselectAllRows?: () => void
}

/**
 * @name FlagCaseModal
 * @description - The AssignUser modal component consists of a searchable select input for users, and the ability to assign a user to case(s)
 * @param open - bool that opens or closes the modal view
 * @param toggleOpen -  takes in a variant and toggles the modal open
 * @param isMultiSelectMode - bool that tells AssignUser rather it was opened with multiple case selections or just a single
 * @param selectedCaseIds - array of selected case ids should any be selected in the datagrid
 * @param caseModel - optional - if the AssignUser was opened in single select from a datagrid action cell it will be passed a caseModel
 * @param rehydrateView - reloads datagrid
 *  */

const FlagCaseModal = ({
    open = false,
    toggleOpen,
    isMultiSelectMode,
    selectedCaseIds,
    caseModel,
    rehydrateView,
    unflagCases,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    unselectAllRows = () => {},
}: FlagModalProps) => {
    const { handleBulkFlagToggle } = useFlagCaseModal()

    const [comment, setComment] = useState('')

    const handleFlag = (flag: boolean) => {
        if (!isMultiSelectMode) caseModel?.toggleFlag(comment)
        else {
            handleBulkFlagToggle(selectedCaseIds, flag, comment)
                .then(() => {
                    setAlertSnackbarMainProps({
                        message: 'Your action was a success!',
                        title: 'Success.',
                        intent: 'success',
                    })
                    unselectAllRows()
                })
                .catch(() => {
                    setAlertSnackbarMainProps({
                        message: flag
                            ? 'There was an error bulk flagging the cases.'
                            : 'There was an error bulk unflagging the cases.',
                        title: 'Error.',
                        intent: 'error',
                    })
                })
                .finally(() => {
                    handleOnClose()
                    setAlertSnackbarMainOpen(true)
                })
        }
    }

    const handleOnClose = () => {
        setComment('')
        toggleOpen('toggleFlag')
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleOnClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogContent className={'emp-flagCaseModal-content'}>
                    {caseModel?.flag?.is_flagged || unflagCases ? (
                        <>
                            <h2 className={'emp-flagCaseModal-title'}>
                                Unflag Chargeback(s)
                            </h2>
                            <div className={'emp-flagCaseModal-actions'}>
                                <Button
                                    className={'emp-flagCaseModal-btn'}
                                    variant="contained"
                                    onClick={handleOnClose}
                                    sx={clientTheme.buttons.defaultButton.style}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={'emp-flagCaseModal-btn'}
                                    onClick={() => {
                                        handleFlag(false)
                                        setComment('')
                                    }}
                                >
                                    Continue
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <h2 className={'emp-flagCaseModal-title'}>
                                Flag Chargeback(s)
                            </h2>
                            <h4 className={'emp-flagCaseModal-subtitle'}>
                                Comment
                            </h4>
                            <TextField
                                variant="outlined"
                                onChange={(e) => setComment(e.target.value)}
                                value={comment}
                                placeholder="Comment"
                                multiline
                                rows={4}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius:
                                            clientTheme.selectionBox
                                                .borderRadius,
                                    },
                                }}
                            />
                            <div className={'emp-flagCaseModal-actions'}>
                                <Button
                                    className={'emp-flagCaseModal-btn'}
                                    variant="contained"
                                    onClick={handleOnClose}
                                    sx={clientTheme.buttons.defaultButton.style}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={'emp-flagCaseModal-btn'}
                                    onClick={() => handleFlag(true)}
                                >
                                    Flag
                                </Button>
                            </div>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
}

export default FlagCaseModal

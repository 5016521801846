import React from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { iconsTheme } from 'theme-exports'

const cls1 = { fill: '#c80000' }
const cls2 = { fill: '#707070' }

const VerdictLoss = () => {
    return iconsTheme.loss ? (
        <Icon icon={faTimes} style={{ fontSize: '18px', color: '#a94442' }} />
    ) : (
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.06 24.06">
                <defs></defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path
                            style={cls1}
                            d="M8,16.65a.62.62,0,0,1-.44-.18.61.61,0,0,1,0-.88l8-8a.62.62,0,1,1,.88.88l-8,8A.62.62,0,0,1,8,16.65Z"
                        />
                        <path
                            style={cls1}
                            d="M16,16.65a.62.62,0,0,1-.44-.18l-8-8a.62.62,0,0,1,.88-.88l8,8a.61.61,0,0,1,0,.88A.62.62,0,0,1,16,16.65Z"
                        />
                        <path
                            style={cls2}
                            d="M12,24.06a12,12,0,1,1,12-12A12,12,0,0,1,12,24.06ZM12,1.25A10.78,10.78,0,1,0,22.81,12,10.8,10.8,0,0,0,12,1.25Z"
                        />
                    </g>
                </g>
            </svg>
        </span>
    )
}

export default VerdictLoss

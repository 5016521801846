import React, { useEffect } from 'react'
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import { merchandiseOrServicesValues, deliveryStatuses, useDebounce } from './VisaCE.vm'
import { useFormik } from 'formik'
import * as Yup from 'yup'

interface VisaCETopSectionProps {
    caseDetails: any
    formStep: number
    shippingInfo: any
    setShippingInfo: (values: any) => void
    setSubmitDisabled: (value: boolean) => void
}

const VisaCETopSection = ({
    caseDetails,
    formStep,
    shippingInfo,
    setShippingInfo,
    setSubmitDisabled,
}: VisaCETopSectionProps) => {

    const {
        values,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        isValid,
        setFieldTouched,
    } = useFormik({
        validationSchema: Yup.object().shape({
            shipping_delivery_status: Yup.string().required().label('Shipping Delivery Status'),
            merchandise_or_services: Yup.string().required().label('Merchandise or Services'),
            merchandise_or_services_description: Yup.string().required().label('Merchandise or Services Description'),
        }),
        validateOnMount: true,
        enableReinitialize: true,
        initialValues: {
            shipping_delivery_status: shippingInfo?.shipping_delivery_status ?? '',
            tracking_information: shippingInfo?.tracking_information ?? '',
            merchandise_or_services: shippingInfo?.merchandise_or_services ?? '',
            merchandise_or_services_description: shippingInfo?.merchandise_or_services_description ?? '',
            isValid: shippingInfo?.isValid ? true : false
        },
        onSubmit: (values) => {
            setShippingInfo({...values, isValid: isValid})
        },
    })

    const handleValueUpdate = useDebounce(() => {
        if (isValid) {
            return handleSubmit()
        }
        setSubmitDisabled(true)
    }, 500)

    useEffect(() => {
        handleValueUpdate()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, isValid])

    return (
        <div className={`emp-visaCETopSection-root`}>
            <div className={`emp-visaCETopSection-summary`}>
                <div className={`emp-visaCETopSection-summary-header`}>
                    Disputed Transaction
                </div>    
                <div className={'emp-visaCETopSection-gridContainer'}>
                    <div>
                        <strong>Case Number</strong>: {caseDetails?.id}
                    </div>
                    <div>
                        <strong>Date of Transaction</strong>: {caseDetails?.date_trans}
                    </div>
                    <div>
                        <strong>Transaction Amount</strong>: {caseDetails?.trans_amt}
                    </div>
                </div>
                {formStep > 1 && formStep < 6 ?
                    <>
                        <div className={'emp-visaCETopSection-gridContainer'}>
                            <div>
                                <strong>Merchandise or Services</strong>: {shippingInfo?.merchandise_or_services}
                            </div>
                            <div>
                                {shippingInfo?.shipping_delivery_status && shippingInfo?.merchandise_or_services !== 'Services' &&
                                    <><strong>Delivery Status</strong>: {deliveryStatuses.find(obj => obj.id === shippingInfo?.shipping_delivery_status)?.name ?? ''}</>
                                }
                            </div>
                            <div>
                                {shippingInfo?.tracking_information &&
                                    <><strong>Tracking Number</strong>: {shippingInfo?.tracking_information}</>
                                }
                            </div>
                        </div>
                        <div className={'emp-visaCETopSection-gridContainer'}>
                            <div>
                                <strong>Description</strong>: {shippingInfo?.merchandise_or_services_description}
                            </div>
                        </div>
                    </>
                :
                    <></>
                }
            </div>
            {formStep === 1 &&
                <form onSubmit={handleSubmit}>
                    <div className={`emp-visaCETopSection-form-container`}>
                        <div className={`emp-visaCETopSection-form-container-header`}>
                            <div>Additonal Transaction Details</div>
                            <div>Add additional information about the above disputed transaction.</div>
                        </div>
                        <div className={'emp-visaCETopSection-formFieldsContainer'}>
                            <div>
                                <FormControl fullWidth  variant="outlined" margin="dense" size="small" style={{maxWidth: '100%'}}>
                                    <InputLabel
                                        shrink
                                    >
                                        Merchandise or Services Provided:
                                    </InputLabel>
                                    <Select
                                        name={`merchandise_or_services`}
                                        value={values.merchandise_or_services ?? ''}
                                        label="Merchandise or Services Provided:"
                                        variant="outlined"
                                        fullWidth
                                        onChange={(e) => {
                                            setFieldValue('merchandise_or_services', e.target.value)
                                            setFieldValue('shipping_delivery_status', e.target.value === 'Services' ? 'O' : '' )
                                            setFieldValue('tracking_information', '')
                                            // Why? 'Cuz Formik.
                                            setTimeout(() => {
                                                setFieldTouched('shipping_delivery_status')
                                                setFieldTouched('tracking_information')
                                            }, 10)
                                        }}
                                    >
                                        <MenuItem value={''}>
                                            -
                                        </MenuItem>
                                        {merchandiseOrServicesValues.map(({value, name}: any, idx: number) => (
                                            <MenuItem key={idx} value={value}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                {values.merchandise_or_services === 'Merchandise' ?
                                    <FormControl fullWidth  variant="outlined" margin="dense" size="small" style={{maxWidth: '100%'}}>
                                        <InputLabel
                                            shrink
                                        >
                                            Delivery Status:
                                        </InputLabel>
                                        <Select
                                            name={`shipping_delivery_status`}
                                            value={values.shipping_delivery_status ?? ''}
                                            label="Delivery Status:"
                                            variant="outlined"
                                            fullWidth
                                            disabled={!values.merchandise_or_services}
                                            onChange={(e) => {
                                                setFieldValue('shipping_delivery_status', e.target.value)

                                                if (!e.target.value) {
                                                    setFieldValue('tracking_information', '')
                                                }
                                            }}
                                        >
                                            <MenuItem value={''}>
                                                -
                                            </MenuItem>
                                            {deliveryStatuses.map(({value, name}: any, idx: number) => (
                                                <MenuItem key={idx} value={value}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                :
                                    <></>
                                }
                                
                            </div>
                            <div>
                                {values.merchandise_or_services === 'Merchandise' ?
                                    <TextField
                                        label="Tracking Number:"
                                        name={`tracking_information`}
                                        placeholder="Tracking Number"
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        value={values.tracking_information ?? ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={!values.shipping_delivery_status}
                                    />
                                :
                                    <></>
                                }
                            </div>
                        </div>
                        <div className={'emp-visaCETopSection-descriptionContainer'}>
                            <TextField
                                label="Merchandise or Services Description:"
                                name={`merchandise_or_services_description`}
                                placeholder="Description"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                value={values.merchandise_or_services_description ?? ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </div>
                    </div>
                </form>
            }
        </div>
    )
}

export default VisaCETopSection


/**
 * @description Directory for ID Attributes on Preset Date Range.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    divContainer: `presetDateRange-div-container`,
    
}

export default idDirectory

import React, { useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import PieChart from 'components/PieChart/PieChart'
import { IReportFilter } from 'views/Reports'

/**
 * @name ByIssuer
 * @desc Render % of Chargebacks by Issuer (Top N) chart
 * @filterValues IReportsFilter
 */
const ByIssuer = ({
    filterValues,
    topN = 5,
}: {
    filterValues: IReportFilter
    topN?: number
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ name: string; value: number }[]>([])

    React.useEffect(() => {
        setLoading(true)
        ReportsApi.getByIssuer(filterValues, topN).then((data) => {
            setLoading(false)
            setData(data)
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <PieChart
            chartTitle={`% of Chargebacks by Issuer (Top ${topN})`}
            data={data}
            loading={loading}
            testId={`byIssuerTop${topN}`}
            tooltipPercent
            height={400}
        />
    )
}

export default ByIssuer

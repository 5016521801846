import React from 'react'
import { Switch, FormControlLabel } from '@mui/material'
import { Skeleton } from '@mui/material'
import { clientTheme } from 'theme-exports'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'

/**
 * @desc - Material UI Switch at it's core is a checkbox input - FormControlLabel is simply a label.
 * @params name - the human readable name of the switch list option
 * @params key - the object key accessor used in state
 * @params label - the switch list option label
 * @params value - the boolean value of the switch list option
 */
export interface SwitchListOptions {
    [key: string]: {
        name: string
        key: string
        label: string
        isChecked: boolean
    }
}

/**
 * @params options - object containing switch list options passed in from the parent
 * @params returnUpdatedSwitchValue - function the parent passes in
 */
export interface SwitchListProps {
    id?: string
    options: SwitchListOptions
    onChange: (key: string) => void
    loadingOptions: boolean
    loadingUpdate?: boolean
    compact?: boolean
    expectedLength?: number
}

/**
 * A way to get toggles with an identity per toggle and extract the current toggles state
 */
const SwitchList = ({
    id,
    options,
    onChange,
    loadingOptions,
    loadingUpdate = false,
    compact = true,
    expectedLength = 20,
}: SwitchListProps): React.ReactElement => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const listOptions = loadingOptions
        ? Array(expectedLength).fill([])
        : Object.entries(options)

    return (
        <div
            id={id}
            className={`${'emp-switchList-container'} ${
                compact ? 'emp-switchList-compact' : ''
            }`}
        >
            {listOptions.map(([key, value], idx) => (
                <FormControlLabel
                    key={`key-${key}-${idx}`}
                    control={
                        <Switch
                            key={`key-${key}-${idx}`}
                            checked={loadingOptions ? false : value.isChecked}
                            disabled={
                                loadingOptions || isReadOnly || loadingUpdate
                            }
                            onChange={(): void => onChange(key)}
                            name={value?.name}
                            color="primary"
                            sx={[
                                {
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: clientTheme.switch,
                                    },
                                },
                                {
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                        backgroundColor: clientTheme.switch,
                                    },
                                },
                            ]}
                        />
                    }
                    label={
                        loadingOptions ? <Skeleton width={100} /> : value.label
                    }
                    className={'emp-switchList-formLabel'}
                />
            ))}
        </div>
    )
}

export default SwitchList

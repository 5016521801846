import featuresExports from 'features-exports'
import React from 'react'

export type Features = Partial<typeof featuresExports>

interface FeatureToggleProviderProps {
    features: Features
}

export const FeatureToggleContext = React.createContext<Features>({})

export const FeatureToggleProvider: React.FC<FeatureToggleProviderProps> = ({
    children,
    features,
}) => {
    return (
        <FeatureToggleContext.Provider value={features}>
            {children}
        </FeatureToggleContext.Provider>
    )
}

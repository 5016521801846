/* eslint-disable no-console */
import config from '../config'

// Allow turning on a debugger
const logger = (info: any, info2: any = null, externalDebug = false): void => {
    const noop = (): void => { /* no-op */ }
    const { log = noop } = window?.console || {} // Fallback noop if no window.console.log
    if (config?.debug || externalDebug) {
        if (info2 !== null) {
            log('DS logger:', info, info2)
        } else {
            log('DS logger:', info)
        }
    }
}
// TODO: Replace this with a real logging library

export default logger

import ConfigObject from './TConfigObject'

const config: ConfigObject = {
    'base-api': '/api/',
    'base-legacy': 'https://ds-f.chargebacks911.com/',
    branding: process?.env?.NODE_ENV !== 'development', // Use the CB911 branding
    debug: false,
    mock: false,
}
export default config

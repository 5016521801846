import React, { useRef, useState } from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
    faLongArrowAltUp,
    faLongArrowAltDown,
} from '@fortawesome/free-solid-svg-icons'
import { clientTheme } from 'theme-exports'

// Handles dislaying a sort icon and all of its internal logic, allows you to sort a column of data by ascending or descending
const SortComponent = ({
    accessor,
    value,
    sortHandler,
    sortInfo: activeSort,
}: {
    accessor: string
    value: string
    sortHandler: (sort: boolean, accessor: string) => any
    sortInfo?: {
        sortDesc: string
        sortBy: string
    }
}) => {
    const [sort, setSort] = useState(false) // false is desc true is asc
    const firstMount = useRef(true)

    const toggleSort = (): void => {
        firstMount.current = false
        setSort((prev) => !prev)
        sortHandler(sort, accessor)
    }

    // On first mount, the sort icons are in a default inactive state. The first sort order after becoming active is ascending
    return (
        <div className={'emp-sortComponent-root'} onClick={toggleSort}>
            <p>{value}</p>
            <Icon
                className={`${'emp-sortComponent-sortIcon'}`}
                icon={faLongArrowAltDown}
                style={{
                    color:
                        !firstMount.current && activeSort?.sortBy === accessor
                            ? sort
                                ? clientTheme.secondary
                                : '#bfc9cc'
                            : '#bfc9cc',
                }}
            />
            <Icon
                className={`${'emp-sortComponent-sortIcon'}`}
                icon={faLongArrowAltUp}
                style={{
                    color:
                        !firstMount.current && activeSort?.sortBy === accessor
                            ? !sort
                                ? clientTheme.secondary
                                : '#bfc9cc'
                            : '#bfc9cc',
                }}
            />
        </div>
    )
}

export default SortComponent

/**
 * @description Directory for ID Attributes on AG Data Grid Cell Editors.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    agDataGridSelectCellEditor: {
        divRoot: `agDataGridSelectCellEditor-div-root`,
        inputSearch: `agDataGridSelectCellEditor-input-search`,
        divLoading: `agDataGridSelectCellEditor-div-loading`,
        divDefaultText: `agDataGridSelectCellEditor-div-defaultText`,
        divOptions: `agDataGridSelectCellEditor-div-options`,
        selectOption: `agDataGridSelectCellEditor-select-option`,
        divMinSearchCharText: `agDataGridSelectCellEditor-div-minSearchCharText`,
        divNoResults: `agDataGridSelectCellEditor-div-notResults`,
        divPagination: `agDataGridSelectCellEditor-div-pagination`,
    },
}

export default idDirectory

import React, { createContext, useContext } from 'react'
import { UiStateStore } from 'hooks/cb-api/types'

const UiStateContext = createContext<UiStateStore>({
  whoami: null,
  activeMerchant: null,
  setActiveMerchant: (merchantId) => {},
  loading: false,
  error: null,
  invalidate: () => {},
})

const useUiStateContext = () => {
  const uiStateContext = useContext(UiStateContext)
  if (uiStateContext === undefined) {
    throw new Error('Please provide an UIStateInstance to UIStateContext.')
  }
  return uiStateContext
}

const UiStateContextProvider = ({
  children,
  value
}: {
  children: React.ReactElement
  value: any
}) => {
  return (
    <UiStateContext.Provider value={value}>
      {children}
    </UiStateContext.Provider>
  )
}

export { useUiStateContext, UiStateContextProvider }

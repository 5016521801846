import React from 'react'
import { Typography } from '@mui/material'
import {
    AccentArea,
    MerchantSwitcher,
    SearchableSelect,
    AlertSnackbar,
} from 'components'
import { withRequiredRole } from 'components/useRequireRole'
import idDirectory from './idAttributes'
import EntFeature from 'models/EntFeature'
import View from 'views/View'
import ResultantKpiAreaChart from './charts/ResultantKpiAreaChart'
import ResultantKpiStackedBarChart from './charts/ResultantKpiStackedBarChart'
import useResultantKpi from './ResultantKpi.vm'
import { iconsTheme } from 'theme-exports'
import Icon from 'components/Icon'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'

interface ResultantKpiProps {
    handleFilterUpdate: (
        e: { id: number; name: string },
        key: 'currency_id' | 'platform_id'
    ) => void
    selectedValue: {
        currency_id: {
            name: string
            id: string
        }
        platform_id: {
            name: string
            id: string
        }
    }
    currencySearchValue: string
    setCurrencySearchValue: (value: string) => void
    currenciesList: {
        id: number
        name: string
    }[]
    platformsList: {
        id: number
        name: string
    }[]
    optionsListLoading: boolean
}

const AlertsAccentAreaTitle = (): React.ReactElement => {
    const { title_icon: titleIconEnabled } = useFeatureToggle('ACCENT_AREA')

    return (
        <div style={{ display: 'flex', placeItems: 'center', marginRight: 8 }}>
            {titleIconEnabled && (
                <Icon
                    className={`${
                        iconsTheme.bell
                    } ${'emp-marginRight'}`}
                />
            )}
            <span>Alerts</span>
        </div>
    )
}

const ChargebacksAccentAreaTitle = (): React.ReactElement => {
    const { title_icon: titleIconEnabled } = useFeatureToggle('ACCENT_AREA')

    return (
        <>
            {titleIconEnabled && (
                <Icon
                    className={`${
                        iconsTheme.exchange
                    } ${'emp-marginRight'}`}
                />
            )}
            <span>Chargebacks</span>
        </>
    )
}

const ResultantKpiFilters = ({
    handleFilterUpdate,
    selectedValue,
    currencySearchValue,
    setCurrencySearchValue,
    currenciesList,
    platformsList,
    optionsListLoading,
}: ResultantKpiProps): React.ReactElement => {

    return (
        <div className={'emp-container'}>
            <Typography
                className={'emp-filterByLabel'}
                color="textSecondary"
                variant="subtitle1"
            >
                Filter By:
            </Typography>
            <div className={'emp-inputs'}>
                <SearchableSelect
                    value={selectedValue.currency_id.name}
                    onValueChange={(e: any) =>
                        handleFilterUpdate(e, 'currency_id')
                    }
                    options={
                        currencySearchValue.length > 1 && !optionsListLoading
                            ? currenciesList ?? []
                            : []
                    }
                    searchValue={currencySearchValue}
                    onSearchChange={setCurrencySearchValue}
                    accessor={'name'}
                    testId="currency"
                    debounceDelay={200}
                >
                    {currencySearchValue.length < 2 && (
                        <div className={'emp-searchableText'}>
                            Please enter 2 or more characters
                        </div>
                    )}
                </SearchableSelect>
            </div>
            <div className={'emp-inputs'}>
                <SearchableSelect
                    value={selectedValue.platform_id.name}
                    onValueChange={(e: any) =>
                        handleFilterUpdate(e, 'platform_id')
                    }
                    options={platformsList ?? []}
                    accessor={'name'}
                    hideSearch
                    testId="platform"
                />
            </div>
        </div>
    )
}

/**
 * The Resultant KPI view and it's graphs
 */
export const ResultantKpi: React.FC = () => {
    const {
        resultantKpiAlerts,
        resultantKpiChargebacks,
        handleFilterUpdate,
        loading,
        selectedValue,
        currencySearchValue,
        setCurrencySearchValue,
        currenciesList,
        platformsList,
        optionsListLoading,
        alertSnackbarMainProps,
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
    } = useResultantKpi()


    return (
        <>
            <View
                title="Resultant KPI"
                breadcrumb="Resultant KPI"
                HeaderInterior={<MerchantSwitcher testId="resultantKPI" />}
                testId="resultantKPI"
            >
                <div
                    id={idDirectory.divContainer}
                    style={{ display: 'grid', gap: 16 }}
                >
                    <AccentArea
                        title={<AlertsAccentAreaTitle />}
                        headerAction={
                            <ResultantKpiFilters
                                handleFilterUpdate={handleFilterUpdate}
                                selectedValue={selectedValue}
                                currencySearchValue={currencySearchValue}
                                setCurrencySearchValue={setCurrencySearchValue}
                                currenciesList={currenciesList}
                                platformsList={platformsList}
                                optionsListLoading={optionsListLoading}
                            />
                        }
                        testId="resultantKPIAlerts"
                    >
                        <ResultantKpiAreaChart
                            data={resultantKpiAlerts}
                            loading={loading}
                        />
                    </AccentArea>
                    <AccentArea
                        title={<ChargebacksAccentAreaTitle />}
                        testId="resultantKPIChargebacks"
                    >
                        <ResultantKpiStackedBarChart
                            data={resultantKpiChargebacks}
                            loading={loading}
                        />
                    </AccentArea>
                </div>
                <AlertSnackbar
                    content={alertSnackbarMainProps}
                    open={alertSnackbarMainOpen}
                    onClose={() => setAlertSnackbarMainOpen(false)}
                    showCloseIcon
                />
            </View>
        </>
    )
}

export default withRequiredRole(EntFeature.RESULTANT_KPI, ResultantKpi)

/**
 * @description Directory for ID Attributes on InputCaseBuilderDetailsStep1.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `inputCaseBuilderDetailsStep1-div-root`,
    divSectionOne: `inputCaseBuilderDetailsStep1-div-sectionOne`,
    divSectionTwo: `inputCaseBuilderDetailsStep1-div-sectionTwo`,
    divSectionThree: `inputCaseBuilderDetailsStep1-div-sectionThree`,
    divReasonCode: `inputCaseBuilderDetailsStep1-div-reasonCode`,
    divQualityNotes: `inputCaseBuilderDetailsStep1-div-qualityNotes`,
    divSalesMethods: `inputCaseBuilderDetailsStep1-div-saleMethods`,
    btnSalesMethod: `inputCaseBuilderDetailsStep1-btn-saleMethod`,
    divCustomerInfo: `inputCaseBuilderDetailsStep1-div-customerInfo`,
    divTransactionInfo: `inputCaseBuilderDetailsStep1-div-transactionInfo`,
    divtransactionQuestion: `inputCaseBuilderDetailsStep1-div-transactionQuestion`,
    divProductInfo: `inputCaseBuilderDetailsStep1-div-productInfo`,
    typographyHeader: `inputCaseBuilderDetailsStep1-typography-header`,
    tabProductContainer: `inputCaseBuilderDetailsStep1-tab-productContainer`,
    tabProductItem: `inputCaseBuilderDetailsStep1-tab-productItem`,
    
}

export default idDirectory

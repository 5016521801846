import React, { useEffect } from 'react'
import { Dialog, DialogTitle } from '@mui/material'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

export interface MarkReviewedModalProps {
    open: boolean
    toggleOpen: () => void
    errorAlert: boolean
    errorMessage?: string
    successMessage?: string
    onClose?: () => void
}

/**
 * @name MarkReviewedModal
 * @description - Mark selected report as reviewed.
 * @param open - bool that opens or closes the modal view
 * @param toggleOpen, - toggles the modal open or close
 * @param errorAlert - bool that indicates if either error or success message with be shown
 * @param errorMessage - optional- customize error message
 * @param successMessage - optional- customize success message
 * @param onClose - optional- function invoked on close of Modal
 *  */

const MarkReviewedModal = ({
    open,
    toggleOpen,
    errorAlert,
    errorMessage = 'There was an issue. Please try again.',
    successMessage = 'The selected row(s) has been reviewed.',
    onClose = () => {},
}: MarkReviewedModalProps) => {
    useEffect(() => {
        if (open) {
            setTimeout(() => {
                !errorAlert && onClose && onClose()
                toggleOpen()
            }, 2000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <Dialog open={open}>
            {errorAlert ? (
                <div className={'emp-markReviewedModal-dialog'}>
                    <div className={`emp-markReviewedModal-errorIcon`}>
                        <span>
                            <Icon icon={faTimes} />
                        </span>
                    </div>
                    <DialogTitle
                        className={`emp-markReviewedModal-dialogTitle`}
                    >
                        <p>Error!</p>
                    </DialogTitle>
                    <div
                        className={`emp-markReviewedModal-dialogContentText`}
                    >
                        <p className={`emp-markReviewedModal-contentText`}>
                            {errorMessage}
                        </p>
                    </div>
                </div>
            ) : (
                <div className={'emp-markReviewedModal-dialog'}>
                    <div className={`emp-markReviewedModal-successIcon`}>
                        <span>
                            <Icon icon={faCheck} />
                        </span>
                    </div>
                    <DialogTitle
                        className={`emp-markReviewedModal-dialogTitle`}
                    >
                        <p>Success!</p>
                    </DialogTitle>
                    <div
                        className={`emp-markReviewedModal-dialogContentText`}
                    >
                        <p className={`emp-markReviewedModal-contentText`}>
                            {successMessage}
                        </p>
                    </div>
                </div>
            )}
        </Dialog>
    )
}

export default MarkReviewedModal

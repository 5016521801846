/**
 * @description Directory for ID Attributes on UploadCaseBuilderDocumentsStep3.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    //UploadCaseBuilderDocumentsStep3:
    divRoot: `uploadCaseBuilderDocumentsStep3-div-root`,
    divTemplates: `uploadCaseBuilderDocumentsStep3-div-templates`,
    divTemplatesLibrary: `uploadCaseBuilderDocumentsStep3-div-templatesLibrary`,
    divUploadTemplates: `uploadCaseBuilderDocumentsStep3-div-uploadTemplates`,
    divUpload: `uploadCaseBuilderDocumentsStep3-div-upload`,
    divUploadFile: `uploadCaseBuilderDocumentsStep3-div-uploadFile`,
    divTemplatesAndFiles: `uploadCaseBuilderDocumentsStep3-div-templatesAndFiles`,
    divDocumentTypes: `uploadCaseBuilderDocumentsStep3-div-documentTypes`,
    //UploadCaseBuilderFileDnD:
    btnCancel: 'uploadCaseBuilderFileDnD-btn-cancel',
    btnUpload: 'uploadCaseBuilderFileDnD-btn-upload',
}

export default idDirectory

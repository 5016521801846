import React from 'react'
import FlexAForm from './FlexAForm'
import {
    IAdvancedFilterConfig,
    IFormField
} from 'hooks/cb-api'

export const formatAdvancedFilterFields = (fields: IAdvancedFilterConfig[]) => {
    const updatedFields: IFormField[] = []

    fields
        .filter((field: IAdvancedFilterConfig) => field.is_enabled)
        .forEach((field: IAdvancedFilterConfig) => {
            updatedFields.push({
                key: field.alias ?? '',
                filterOverrideName: field.alias_override,
                name: field.label ?? '',
                initialValue: field.initial_value ?? '',
                type: field.type,
                apiValuesPath: field.endpoint,
                defaultValueOptions: field.default_options,
                disableMultipleSelections: !field.is_multiple_choice,
                clearDateIcon: field.is_clear_date_icon,
                hideField: false,
            })

            // Need to add field for compare operator when filter type is 'amount'.
            if (field.type === 'amount') {
                updatedFields.push({
                    key: field.alias ? `${field.alias}_op` : '',
                    filterOverrideName: field.alias_override
                        ? `${field.alias_override}_op`
                        : undefined,
                    name: '',
                    initialValue: field.initial_compare_value ?? 'eq',
                    type: 'select',
                    apiValuesPath: undefined,
                    defaultValueOptions: undefined,
                    disableMultipleSelections: false,
                    clearDateIcon: false,
                    hideField: true,
                })
            }
        })

    return updatedFields
}

interface IAdvancedFiltersProps {
    className?: string
    id?: string
    onSubmit: (value: Record<string, string>) => void
    fields: IFormField[]
    metricPresets?: any[] | null
    validate: () => Record<string, string>
    initialValues: Record<string, string>
    onClear: () => void
    selectedTabFilter?: string | number
    isActiveAdvSearchFilters?: boolean
    setIsLoadingAdvFilterSelect?: (loading: boolean) => void
    inactivateSearchBtn?: boolean
}

/**
 * Render the various advanced filters for Chargebacks
 * @note Mocked, not operational
 * @todo Complete this functionality
 */
const AdvancedFilters = ({
    className = '',
    id,
    onSubmit,
    selectedTabFilter = '',
    ...rest
}: IAdvancedFiltersProps) => {

    return (
        <>
            <FlexAForm
                {...rest}
                onSubmit={onSubmit}
                selectedTabFilter={selectedTabFilter}
            />
        </>
    )
}

export default AdvancedFilters

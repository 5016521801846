import React from 'react'
import { Dialog, Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle as farTimesCircle } from '@fortawesome/free-regular-svg-icons'

interface SelectedRowWarningProps {
    open: boolean
    toggleOpen: () => void
    message?: string
    btnText?: string
}

/**
 * @name SelectedRowWarningModal
 * @description - a warning modal for when you use an operator in the context menu on selected rows with types that do not match
 * @example will show when the bulk toggle flag operator is used on selected cases that are both flagged and unflagged.
 *
 */
const SelectedRowWarningModal = ({
    open,
    toggleOpen,
    btnText = 'Ok',
    message = 'You can only operate on cases that share a single type.',
}: SelectedRowWarningProps) => {
    return (
        <>
            <Dialog open={open} onClose={toggleOpen} maxWidth="xs" fullWidth>
                <div className={'emp-selectedRowWarningModal-content'}>
                    <FontAwesomeIcon
                        icon={farTimesCircle}
                        size="6x"
                        color="#F27474"
                    />
                    <p style={{ fontSize: 17, textAlign: 'center' }}>
                        {message}
                    </p>
                    <Button
                        color="secondary"
                        style={{ fontSize: 18, fontWeight: 600 }}
                        variant="contained"
                        onClick={toggleOpen}
                    >
                        {btnText}
                    </Button>
                </div>
            </Dialog>
        </>
    )
}

export default SelectedRowWarningModal

import React, { useMemo, useRef } from 'react'
import { Typography } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { AGDataGrid, StandardModal } from 'components'
import { useAGDataGridActions } from 'hooks/useAGDataGridActions/useAGDataGridActions'
import { ExtendedColumn } from 'components/DataGrid/ExtendedColumn'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import idDirectory from '../idAttributes'

interface MidHistoryProps {
    openMidHistory: boolean
    onClose: () => void
    midId: number
}

export const MidHistory: React.FC<MidHistoryProps> = ({
    openMidHistory,
    onClose,
    midId,
}) => {
    const gridRef = useRef<AgGridReact>(null)
    const { user } = useAuthedUser()
    const { SHOW_MID_HISTORY } = useFeatureToggle('MERCHANT_HIERARCHY')
    const { enabled: showMIDHistory } = SHOW_MID_HISTORY

    const filterValuesMemo = useMemo(() => {
        return {}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {
        dataSource,
        isLoading,
        pageSize,
        page,
        paginationTotal,
        setLocalPage,
        handlePageLimitChange,
    } = useAGDataGridActions(
        `/cm/mids/${midId}/history`,
        gridRef,
        midId,
        filterValuesMemo,
        { defaultLimit: 5 }
    )

    const columns: ExtendedColumn[] = [
        {
            Header: 'User',
            accessor: 'username',
            type: 'string',
            minWidth: 250,
        },
        {
            Header: 'Date',
            accessor: 'date_created',
            type: 'string',
            minWidth: 170,
        },
        {
            Header: 'Field',
            accessor: 'field',
            type: 'string',
            minWidth: 200,
        },
        {
            Header: 'Old Value',
            accessor: 'old_value',
            type: 'string',
            minWidth: 100,
        },
        {
            Header: 'New Value',
            accessor: 'new_value',
            type: 'string',
            minWidth: 100,
        },
    ]

    const defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
        menuTabs: [],
        sortable: false,
    }

    return (
        <StandardModal
            openModal={openMidHistory}
            onModalClose={onClose}
            modalHeaderTitle={'MID History'}
            secondaryBtnText={'Close History'}
            handleSecondaryActionBtn={onClose}
            enablePrimaryActionBtn={false}
            testId={'midHistory'}
        >
            <div id={'merchantSettingsMidHistory'}>
                <div
                    id={idDirectory.midHistory.divRoot}
                    className={'emp-historyGridContainer'}
                >
                    {showMIDHistory &&
                    ['cb911 admin', 'partner admin', 'partner user'].includes(
                        user?.role?.name.toLowerCase() ?? ''
                    ) ? (
                        <AGDataGrid
                            gridRef={gridRef}
                            gridName={'midHistory'}
                            fileName={'mid-history'}
                            columns={columns}
                            dataSource={dataSource}
                            loading={isLoading}
                            testId={`midHistory`}
                            rowSelectionType={'single'}
                            defaultColDef={defaultColDef}
                            disableRowClickSelection
                            enableSideBarPanel={false}
                            enableDataGridTopBorder={false}
                            rowsPerPageOptions={[5, 10, 25]}
                            totalEntries={paginationTotal}
                            onPageLimitChange={handlePageLimitChange}
                            pageSize={pageSize}
                            page={page}
                            onPageChange={setLocalPage}
                            gridThemeType={'material'}
                        />
                    ) : (
                        <div
                            className={'emp-notAvailable'}
                            id={idDirectory.midHistory.divUnavailable}
                        >
                            <Typography variant="h5">Not available.</Typography>
                        </div>
                    )}
                </div>
            </div>
        </StandardModal>
    )
}

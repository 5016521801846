import React, { useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import PieChart from 'components/PieChart/PieChart'
import { IReportFilter } from 'views/Reports'
import { clientTheme } from 'theme-exports'
/**
 * @name ByReasonCodeDistribution
 * @desc Render Reason Code Distribution
 * @filterValues IReportsFilter
 */
const ByReasonCodeDistribution = ({
    filterValues,
}: {
    filterValues: IReportFilter
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<
        {
            name: string
            value: number
            tooltipName?: string
        }[]
    >([])

    React.useEffect(() => {
        setLoading(true)
        ReportsApi.getByReasonCode(filterValues, true).then((data) => {
            setLoading(false)
            setData(data)
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    const colorPalette = clientTheme.charts.pieCharts.byReasonCodeDistributionPieChart

    return (
        <PieChart
            chartTitle="Reason Code Distribution"
            data={data}
            loading={loading}
            colorPalette={colorPalette}
            showLegend
            testId="byReasonCodeDistribution"
        />
    )
}

export default ByReasonCodeDistribution

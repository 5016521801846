/**
 * @description Directory for ID Attributes on topbar.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    appbar: `topbar-appbar-appbar`,
    toolbar: `topbar-toolbar-toolbar`,
    btnIconBars: `topbar-btn-iconBars`,
    iconBars: `topbar-icon-faBars`,
    linkFinalLogo: `topbar-link-finalLogo`,
    linkFinalLogoText: `topbar-link-finalLogoText`,
    divFlexGrow: `topbar-div-flexGrow`,
    divUserSettings: `topbar-div-userSettings`,
    divUserSettingsRow: `topbar-div-userSettingsRow`,
    skeleton: `topbar-skeleton-skeleton`,
    divIcon: `topbar-div-icon`,
    iconUser: `topbar-icon-user`,
    typographyUser: `topbar-typography-user`,
    divUser: `topbar-div-user`,
    linkProfile: `topbar-link-profile`,
    typographySignout: `topbar-typography-signout`,
    typographyBackAsUser: `topbar-typography-backasuser`,
    modalArea: `topbar-modalArea-AccountSetup`,
}

export default idDirectory

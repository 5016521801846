import React, { useState } from 'react'
import { Button, Typography } from '@mui/material'
import UploadIcon from 'assets/icons/upload-icon-bg-alt.png'
import { bytesToSize } from 'utils/bytesToSize'
import { clientTheme } from 'theme-exports'
import useIsMounted from 'hooks/utils/useIsMounted'
import CB from 'lib'
import { SearchableSelect } from 'components'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import { DocumentOption } from './UploadCaseBuilderDocumentsStep3'
import idDirectory from './idAttributes'

interface UploadCaseBuilderFileDnDProps {
    documentTypesList: DocumentOption[]
    selectedDocumentType: DocumentOption | null
    setSelectedDocumentType: (e: DocumentOption | null) => void
    currentFileDnDInfo: { [key: string]: any } | null
    setCurrentFileDnDInfo: (val: { [key: string]: any } | null) => void
    combinedDocumentsList: DocumentOption[]
    setCombinedDocumentsList: (val: DocumentOption[]) => void
    setAlertSnackbarOpen: (val: boolean) => void
    setAlertSnackbarProps: (props: alertSnackbarContentProps) => void
    handleEnableSaveAndContinueBtn: (documents: DocumentOption[]) => void
    setCombinedDocumentsListUpdated: (value: boolean) => void
    caseId: number | undefined
}

export const UploadCaseBuilderFileDnD = ({
    selectedDocumentType,
    documentTypesList,
    setSelectedDocumentType,
    currentFileDnDInfo,
    setCurrentFileDnDInfo,
    combinedDocumentsList,
    setCombinedDocumentsList,
    setAlertSnackbarOpen,
    setAlertSnackbarProps,
    handleEnableSaveAndContinueBtn,
    setCombinedDocumentsListUpdated,
    caseId,
}: UploadCaseBuilderFileDnDProps) => {
    const { isMounted } = useIsMounted()

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleOnCancel = () => {
        setSelectedDocumentType(null)
        setCurrentFileDnDInfo(null)
    }

    //TODO- setup
    const handleUploadDocument = () => {
        if (!selectedDocumentType) {
            setAlertSnackbarProps({
                title: 'Error',
                message: 'Please select document type.',
                intent: 'error',
            })
            return setAlertSnackbarOpen(true)
        }

        if (currentFileDnDInfo) {
            setIsLoading(true)

            CB.documentsCaseBuilder
                //@ts-ignore
                .uploadCaseBuilderFile([currentFileDnDInfo], caseId)
                .then((data: any) => {
                    if (isMounted.current) {
                        const updatedCombinedDocumentsList = combinedDocumentsList.map(
                            (doc: DocumentOption) =>
                                doc.id === selectedDocumentType.id
                                    ? {
                                          ...doc,
                                          file: data,
                                      }
                                    : doc
                        )
                        setCombinedDocumentsList(updatedCombinedDocumentsList)
                        handleEnableSaveAndContinueBtn(
                            updatedCombinedDocumentsList
                        )
                        setCombinedDocumentsListUpdated(true)
                    }
                })
                .catch(() => {
                    if (isMounted.current) {
                        setAlertSnackbarProps({
                            title: 'Error',
                            message:
                                'An error occurred. Please try again later.',
                            intent: 'error',
                        })
                        setAlertSnackbarOpen(true)
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                    setSelectedDocumentType(null)
                    setCurrentFileDnDInfo(null)
                })
        }
    }

    return (
        <>
            <div
                className={'emp-uploadCaseBuilderFileDnD-uploadIcon'}
                style={{
                    backgroundImage: `url(${UploadIcon})`,
                }}
            />
            <div style={{ textAlign: 'center' }}>
                <Typography variant="h6" style={{ fontWeight: 600 }}>
                    Set the document type
                </Typography>
            </div>
            <div
                className={
                    'emp-uploadCaseBuilderFileDnD-documentTypeInfoContainer'
                }
            >
                <Typography variant="h6">
                    File Name:
                    <span className={'emp-uploadCaseBuilderFileDnD-info'}>
                        {currentFileDnDInfo?.name ?? ''}
                    </span>
                </Typography>
                <Typography variant="h6">
                    File Size:{' '}
                    <span className={'emp-uploadCaseBuilderFileDnD-info'}>
                        {bytesToSize(currentFileDnDInfo?.size) ?? ''}
                    </span>
                </Typography>
                <div style={{ marginTop: 10 }}>
                    <SearchableSelect
                        accessor="name"
                        value={selectedDocumentType?.name}
                        options={documentTypesList ?? []}
                        hideSearch
                        onValueChange={(e) => setSelectedDocumentType(e)}
                        required={true}
                        placeholder={'Select document type.'}
                        testId="documentType"
                    />
                </div>
            </div>
            <div className={'emp-uploadCaseBuilderFileDnD-btnsContainer'}>
                <Button
                    variant="contained"
                    className={'emp-uploadCaseBuilderFileDnD-btn'}
                    onClick={handleOnCancel}
                    sx={clientTheme.buttons.defaultButton.style}
                    id={idDirectory.btnCancel}
                >
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    className={'emp-uploadCaseBuilderFileDnD-btn'}
                    type="submit"
                    onClick={handleUploadDocument}
                    disabled={isLoading}
                    id={idDirectory.btnUpload}
                >
                    {isLoading ? 'Uploading file...' : 'Upload'}
                </Button>
            </div>
        </>
    )
}

import { DataGridActions, DataGridSlim, Lamp, Searchbar } from 'components'
import { ExtendedColumn } from 'components/DataGrid/ExtendedColumn'
import SortComponent from 'components/DataGrid/SortComponent'
import React, { useMemo, useState } from 'react'
import { MidHealthData } from '../MidHealth.vm'

interface IMidHealthGridProps {
    data: MidHealthData[]
    sortInfo: { sortBy: string; sortDesc: string }
    setSearch: (search: string) => void
    total: number
    onPageSizeChange: (size: number) => void
    loading: boolean
    page: number
    rowsPerPage: number
    onPageChange: (page: number) => void
    onSortChange: (sort: boolean, accessor: string) => void
    onSortDirectionChange: (value: 'asc' | 'desc') => void
    globalSearch: string
    onSearchChange: (search: string) => void
    testId: string
    rehydrateView: () => void
}

const MidHealthGrid = ({
    sortInfo,
    data,
    onSearchChange,
    total,
    onPageSizeChange,
    loading,
    page,
    rowsPerPage,
    onPageChange,
    onSortChange,
    testId,
    rehydrateView,
}: IMidHealthGridProps) => {
    const sortHandler = React.useCallback(
        (isAsc: boolean, column: string) => {
            onSortChange(isAsc, column)
        },
        [onSortChange]
    )

    const columns: ExtendedColumn[] = useMemo(
        () => [
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="rating"
                        value="Rating"
                        sortInfo={sortInfo}
                    />
                ),
                Cell: (cell: any) => {
                    switch (cell.row.original.health) {
                        case 'Good':
                            return <Lamp color="green" />
                        case 'Low':
                            return <Lamp color="yellow" />
                        case 'Medium':
                            return <Lamp color="orange" />
                        case 'High':
                            return <Lamp color="red" />
                        default:
                            return <>-</>
                    }
                },
                accessor: 'rating',
                type: 'icon',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="gateway_id"
                        value="Gateway ID"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'gateway_id',
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="mid_group"
                        value="MID Group"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'mid_group',
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="mid"
                        value="MID"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'mid',
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="mid_alias"
                        value="MID Alias"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'mid_alias',
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="platform"
                        value="Processor"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'platform',
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="transactions"
                        value="Transactions"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'transactions',
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="chargebacks"
                        value="Chargebacks"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'chargebacks',
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="ratio"
                        value="CTR Ratio"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'ratio',
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="ethoca_count"
                        value="Ethoca"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'ethoca_count',
                type: 'number',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="cdrn_count"
                        value="CDRN"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'cdrn_count',
                type: 'number',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="order_insight_count"
                        value="Order Insight"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'order_insight_count',
                type: 'number',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="rdr_count"
                        value="Consumer Clarity"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'rdr_count',
                type: 'number',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="other_count"
                        value="Direct"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'other_count',
                type: 'number',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="alert_count"
                        value="Alert Count"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'alert_count',
                type: 'number',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="alert_trans_ratio"
                        value="Alert Trans Ratio"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'alert_trans_ratio',
                type: 'string',
            },
            {
                Header: (
                    <SortComponent
                        sortHandler={sortHandler}
                        accessor="alert_chargebacks_ratio"
                        value="Alert Chargebacks Ratio"
                        sortInfo={sortInfo}
                    />
                ),
                accessor: 'alert_chargebacks_ratio',
                type: 'string',
            },
        ],
        // eslint-disable-next-line
        []
    )
    const [hiddenColumns, setHiddenColumns] = useState([])

    return (
        <div id={'midHealthGrid'}>
            <div className={'emp-searchbarContainer'}>
                <Searchbar
                    className={'emp-cssOutlinedInput'}
                    debounceDelay={200}
                    returnValue={onSearchChange}
                    testId={testId}
                />
                <DataGridActions
                    gridName="midshealth"
                    printId="cb-cases-dg"
                    tableData={data}
                    dataForExportAll={() => {}}
                    columns={columns}
                    hiddenColumns={hiddenColumns}
                    setHiddenColumns={setHiddenColumns}
                    onExportAll={() => {}}
                    onExportAllLoading={false}
                    dataActionExcel={false}
                    fileName={'MID-Health'}
                    testId={testId}
                    // dataActionSwitchGrids={true}
                />
            </div>
            <div style={{ overflowX: 'auto' }}>
                <DataGridSlim
                    columns={columns}
                    data={data}
                    defaultRows={50}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    loading={loading}
                    onPageChange={onPageChange}
                    onPageLimitChange={onPageSizeChange}
                    totalEntries={total}
                    pageSize={rowsPerPage}
                    hiddenColumns={hiddenColumns}
                    hiddenColumnsEnabled={true}
                    stickyHeaders={false}
                    expandable={false}
                    slim={false}
                    page={page}
                    fixedLoadingIndicator={true}
                    noSelection
                    testId={testId}
                    refreshDataGrid={() => rehydrateView()}
                />
            </div>
        </div>
    )
}

export default MidHealthGrid

import React from 'react'
import {
    Checkbox,
    FormControlLabel,
} from '@mui/material'
import { deliveryStatuses } from './VisaCE.vm'

interface SummaryColumnProps {
    caseDetails?: any
    undisputedCase?: any
    countries: any
    shippingInfo: any
    matchingTransactionDetails: any
}

const SummaryColumn = ({
    caseDetails,
    undisputedCase,
    countries,
    shippingInfo,
    matchingTransactionDetails,
}: SummaryColumnProps) => {
    if (!caseDetails && !undisputedCase) return <></>
    const disputed = Boolean(caseDetails)
    const shippingStatusText = disputed ?
            deliveryStatuses.find((obj) => obj.id === shippingInfo.shipping_delivery_status)?.name ?? ''
        :
            deliveryStatuses.find((obj: any) => obj.id === undisputedCase.shipping_delivery_status)?.name ?? ''

    return (
        <div>
            <div>
                <div>{caseDetails?.date_trans ?? undisputedCase?.historical_tran_date}</div>
                {caseDetails?.cc_last_four ? <div>Pan: XXXX-{caseDetails.cc_last_four}</div> : <div>&nbsp;</div>}
            </div>
            <div>
                {matchingTransactionDetails.ipActive &&
                    <div>
                        <div>IP Address</div>
                        <div>{matchingTransactionDetails.ip_address_details}</div>
                    </div>
                }
                {matchingTransactionDetails.idActive &&
                    <div>
                        <div>Device ID</div>
                        <div>{matchingTransactionDetails.device_id_details}</div>
                    </div>
                }
                {matchingTransactionDetails.fingerprintActive &&
                    <div>
                        <div>Device Fingerprint</div>
                        <div>{matchingTransactionDetails.device_fingerprinting_details}</div>
                    </div>
                }
                {matchingTransactionDetails.userIdActive &&
                    <div>
                        <div>User ID</div>
                        <div>{matchingTransactionDetails.customer_account_login_id_details}</div>
                    </div>
                }
                {matchingTransactionDetails.addressActive &&
                    <div>
                        <div>Shipping Address</div>
                        <div>
                            {matchingTransactionDetails.address}<br />
                            {matchingTransactionDetails.address2 &&
                                <>{matchingTransactionDetails.address2}<br /></>
                            }
                            {matchingTransactionDetails.city}, {matchingTransactionDetails.state_region} {matchingTransactionDetails.postal_code}<br />
                            {countries.find((obj: any) => obj.value === matchingTransactionDetails.country)?.name ?? ''}
                            </div>
                    </div>
                }
                <div>
                    <div>Transaction Amount</div>
                    <div>{caseDetails?.trans_amt ?? (Math.round(undisputedCase?.historical_tran_amount* 100) / 100).toFixed(2).toString()}</div>
                </div>
                <div>
                    <div>Transaction Date</div>
                    <div>{caseDetails?.date_trans ?? undisputedCase?.historical_tran_date}</div>
                </div>
                {disputed ? (
                    <div>
                        <div>ARN</div>
                        <div>{caseDetails?.arn ?? ''}</div>
                    </div>
                ) : (
                    <div>
                        <div>ARN/Transaction ID</div>
                        <div>
                            {undisputedCase?.historical_arn_or_transaction_id ??
                                ''}
                        </div>
                    </div>
                )}
                {undisputedCase?.historical_order_id &&
                    <div>
                        <div>Order ID</div>
                        <div>{undisputedCase?.historical_order_id}</div>
                    </div>
                }
                <div>
                    <div>Merchandise or Service</div>
                    <div>{undisputedCase?.merchandise_or_services ?? shippingInfo?.merchandise_or_services}</div>
                </div>
                <div>
                    <div>Delivery Status</div>
                    <div>{shippingStatusText}</div>
                </div>
                {(undisputedCase?.tracking_information || shippingInfo?.tracking_information) &&
                    <div>
                        <div>Tracking Number</div>
                        <div>{undisputedCase?.tracking_information ?? shippingInfo?.tracking_information}</div>
                    </div>
                }
                <div>
                    <div>Description</div>
                    <div>{undisputedCase?.merchandise_or_services_description ?? shippingInfo?.merchandise_or_services_description}</div>
                </div>
            </div>
        </div>
    )
}

interface VisaCESummaryProps {
    caseDetails: any
    shippingInfo: any
    countries: any
    undisputedCases: any
    matchingTransactionDetails: any
    isCompleteEnabled: boolean
    setIsCompleteEnabled: (val: boolean) => void
}

const VisaCESummary = ({
    caseDetails,
    shippingInfo,
    countries,
    undisputedCases,
    matchingTransactionDetails,
    isCompleteEnabled,
    setIsCompleteEnabled,
}: VisaCESummaryProps) => {
    if (!caseDetails) return <></>
    return (
        <div className={`emp-visaCESummary-container`}>
            <div className={`emp-visaCESummary-top-section`}>
                <div>
                    <div><strong>Merchant Name:</strong> {caseDetails.merchant_name}</div>
                </div>
                <div>
                    <div><strong>MID: </strong> {caseDetails.mid}</div>
                </div>
                <div>
                    <div><strong>Case Number: </strong> {caseDetails.case_no}</div>
                </div>
            </div>
            <div className={`emp-visaCESummary-main`}>
                <div>
                    <div>Undisputed Transactions</div>
                    <div>
                        {undisputedCases.map((caseInfo: any, idx: number) => {
                            return (
                                <SummaryColumn
                                    key={idx}
                                    undisputedCase={caseInfo}
                                    shippingInfo={shippingInfo}
                                    matchingTransactionDetails={matchingTransactionDetails}
                                    countries={countries}
                                />
                            )
                        })}
                    </div>
                </div>
                <div>
                    <div>Disputed Transactions</div>
                    <div>
                        <SummaryColumn
                            caseDetails={caseDetails}
                            shippingInfo={shippingInfo}
                            matchingTransactionDetails={matchingTransactionDetails}
                            countries={countries}
                        />
                    </div>
                </div>
            </div>
            <FormControlLabel control={
                <Checkbox
                    checked={isCompleteEnabled}
                    color='secondary'
                    onChange={(e) => {
                        setIsCompleteEnabled(e.target.checked)
                    }}
                />
            } label="I have reviewed the above information carefully and hereby certify that it is true and accurate. By submitting this form, I acknowledge that I cannot provide additional data nor file a traditional representment. I understand this remedy is only guaranteed if the data is accurate and compliant with the CE3.0 requirements. I am aware that submitting false information could result in fines or additional penalties." />
        </div>
    )
}

export default VisaCESummary

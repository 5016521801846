import React, { useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import BarChart from 'components/BarChart'
import { IReportFilter } from 'views/Reports'
import { clientTheme } from 'theme-exports'

/**
 * @name ByBin
 * @desc Render % of Chargebacks by BIN (Top N) chart
 * @filterValues IReportsFilter
 */
const ByBin = ({
    filterValues,
    topN = 5,
}: {
    filterValues: IReportFilter
    topN?: number
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ name: string; value: number }[]>([])

    React.useEffect(() => {
        setLoading(true)
        ReportsApi.getByIssuer(filterValues, topN).then((data) => {
            setLoading(false)
            // removes the 'Others' data object from the chart data
            const topNIssuers = data.filter((bin: any) => bin.name !== 'Others')

            setData(topNIssuers)
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    const colorPalette = clientTheme.charts.barChart.byBinBarChart

    return (
        <BarChart
            chartTitle={`% of Chargebacks by BIN (Top ${topN})`}
            data={data}
            loading={loading}
            valueDataKey="value"
            barLineColor={colorPalette}
            dataValuePercent
            showLegend={false}
            tooltipPercent
            disableAutoDomain
            //Remove if no showLegend
            // height={280}
        />
    )
}

export default ByBin

import React from 'react'
import {
    Grid,
    CardContent,
    Typography,
    CardHeader,
    Card,
} from '@mui/material'
import 'views/views.css'
import {
    AreaChart as RechartAreaChart,
    XAxis,
    YAxis,
    Tooltip,
    Area,
    ResponsiveContainer,
    Legend,
    Label,
    CartesianGrid,
} from 'recharts'
import { LoadingIndicator } from 'components'
import { clientTheme } from 'theme-exports'

/**
 * @name AreaChart
 * @desc AreaChart wrapper
 * @param chartTitle - String that represents the title of the chart
 * @param chartActions - Extended functionality that can exist in the header of the chart container - for example a 'update range' button
 * @param data - Array of data, containing [{name: foo, value: 99},...]
 * @param loading - Bool
 * @param returnCard - Some charts need to be rendered wrapped in a <Grid> component and not a <Card> component. This prop toggles between the two.
 */
const AreaChart = ({
    chartTitle,
    chartActions,
    data = [],
    loading = false,
    returnCard,
    yAxisPercent = false,
    toolTipValueName = 'Value',
    tickCount = 5,
    disableAutoDomain = false,
}: {
    chartTitle: string
    chartActions?: React.ReactElement
    data: { name: string; val: string | number }[]
    loading?: boolean
    returnCard: boolean // Returns a Card element on true | else Grid element
    yAxisPercent?: boolean
    toolTipValueName?: string
    tickCount?: number
    disableAutoDomain?: boolean // Disable 0 - 100% domain on some percentage charts.
}): React.ReactElement => {
    const CustomTooltipValue = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className={'emp-areaChart-tooltipContainer'}>
                    <table style={{ borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr style={{ backgroundColor: '#b3b3b3' }}>
                                <td
                                    colSpan={2}
                                    className={
                                        'emp-areaChart-tooltipTableHeader'
                                    }
                                >
                                    {label}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    className={
                                        'emp-areaChart-tooltipTableRow'
                                    }
                                    style={{
                                        borderRight: '1px dotted #b3b3b3',
                                        textAlign: 'center',
                                    }}
                                >
                                    {toolTipValueName}
                                </td>
                                <td
                                    className={
                                        'emp-areaChart-tooltipTableRow'
                                    }
                                    style={{
                                        textAlign: 'right',
                                    }}
                                >
                                    {payload[0].value}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }

        return null
    }

    const CustomTooltipPercent = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className={'emp-areaChart-tooltipContainer'}>
                    <table style={{ borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr style={{ backgroundColor: '#b3b3b3' }}>
                                <td
                                    className={
                                        'emp-areaChart-tooltipTableHeader'
                                    }
                                >
                                    {label}
                                </td>
                            </tr>

                            <tr>
                                <td
                                    className={
                                        'emp-areaChart-tooltipTableRow'
                                    }
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    {`${payload[0].value}%`}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }

        return null
    }

    return returnCard ? (
        <Card
            className={`graph-placeholder`}
            style={{ borderTop: `3px solid ${clientTheme.primaryDark}` }}
        >
            <CardHeader
                className={'emp-areaChart-header'}
                title={
                    <>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography
                                color="textSecondary"
                                variant="h6"
                                style={{ fontWeight: 600 }}
                            >
                                {/* <Icon icon={faCreditCard} /> */}
                                {chartTitle}
                            </Typography>
                            {chartActions}
                        </Grid>
                    </>
                }
            />
            <CardContent className={'emp-areaChart-content'}>
                {!loading ? (
                    <>
                        {data.length ? (
                            <ResponsiveContainer aspect={3} width="100%">
                                <RechartAreaChart
                                    data={data}
                                    stackOffset="expand"
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <XAxis
                                        dataKey="name"
                                        tick={{
                                            fontSize:
                                                clientTheme.typography.label
                                                    .fontSize,
                                        }}
                                    />
                                    <YAxis
                                        tick={{
                                            fontSize: '14px',
                                        }}
                                    />
                                    <Tooltip />
                                    <Area
                                        dot={{
                                            stroke: '#b71c1c',
                                            fill: '#b71c1c',
                                        }}
                                        dataKey="val"
                                        stroke="#b71c1c"
                                        fill="#ef9a9a"
                                    />
                                </RechartAreaChart>
                                <Legend
                                    verticalAlign="bottom"
                                    align="center"
                                    wrapperStyle={{
                                        fontSize: '14px',
                                    }}
                                />
                            </ResponsiveContainer>
                        ) : (
                            <ResponsiveContainer aspect={3} width="100%">
                                <RechartAreaChart
                                    data={[]}
                                    stackOffset="expand"
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <XAxis ticks={['No Data']}>
                                        <Label
                                            value="No Data"
                                            offset={-100}
                                            position="insideTop"
                                            style={{ fontSize: '14px' }}
                                        />
                                    </XAxis>
                                    <YAxis
                                        tick={{
                                            fontSize: '14px',
                                        }}
                                    />
                                    <Area dataKey="val" />
                                </RechartAreaChart>
                            </ResponsiveContainer>
                        )}
                    </>
                ) : (
                    <div className={'emp-areaChart-loadingContainer'}>
                        <LoadingIndicator />
                    </div>
                )}
            </CardContent>
        </Card>
    ) : (
        <Grid className={`graph-placeholder emp-areaChart-root`} xs={12} item>
            <CardHeader
                className={'emp-areaChart-header'}
                title={
                    <>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography
                                color="textSecondary"
                                variant="h6"
                                style={{ fontWeight: 600 }}
                            >
                                {chartTitle}
                            </Typography>
                            {chartActions}
                        </Grid>
                    </>
                }
            />
            <CardContent className={'emp-areaChart-content'}>
                {!loading ? (
                    <>
                        {data.length ? (
                            <ResponsiveContainer height={265} width="100%">
                                <RechartAreaChart
                                    data={data}
                                    margin={{
                                        right: 30,
                                        bottom: 5,
                                        top: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="name"
                                        angle={90}
                                        textAnchor="start"
                                        height={70}
                                        tick={{
                                            fontSize:
                                                clientTheme.typography.label
                                                    .fontSize,
                                        }}
                                    />

                                    {yAxisPercent ? (
                                        <YAxis
                                            {...(disableAutoDomain
                                                ? { interval: 0 }
                                                : {})}
                                            tickCount={tickCount}
                                            tickFormatter={(tick): string =>
                                                `${tick}%`
                                            }
                                            domain={
                                                yAxisPercent
                                                    ? [0, 100]
                                                    : undefined
                                            }
                                            tick={{
                                                fontSize: '14px',
                                            }}
                                        />
                                    ) : (
                                        <YAxis
                                            tick={{
                                                fontSize: '14px',
                                            }}
                                        />
                                    )}

                                    {yAxisPercent ? (
                                        <Tooltip
                                            content={<CustomTooltipPercent />}
                                        />
                                    ) : (
                                        <Tooltip
                                            content={<CustomTooltipValue />}
                                        />
                                    )}
                                    <Area
                                        dot={{
                                            stroke:
                                                clientTheme.charts.areaChart
                                                    .stroke,
                                            fill:
                                                clientTheme.charts.areaChart
                                                    .stroke,
                                        }}
                                        dataKey="val"
                                        stroke={
                                            clientTheme.charts.areaChart.stroke
                                        }
                                        fill={clientTheme.charts.areaChart.fill}
                                        stackId="a"
                                    />
                                </RechartAreaChart>
                            </ResponsiveContainer>
                        ) : (
                            <ResponsiveContainer height={225} width="100%">
                                <RechartAreaChart
                                    data={[]}
                                    stackOffset={
                                        yAxisPercent ? 'expand' : 'none'
                                    }
                                >
                                    <XAxis ticks={['No Data']}>
                                        <Label
                                            value="No Data"
                                            offset={-100}
                                            position="insideTop"
                                            style={{ fontSize: '14px' }}
                                        />
                                    </XAxis>
                                    {yAxisPercent ? (
                                        <YAxis
                                            tickFormatter={(tick): string =>
                                                `${tick}%`
                                            }
                                            domain={
                                                yAxisPercent
                                                    ? [0, 100]
                                                    : undefined
                                            }
                                            tick={{
                                                fontSize: '14px',
                                            }}
                                        />
                                    ) : (
                                        <YAxis
                                            tick={{
                                                fontSize: '14px',
                                            }}
                                        />
                                    )}
                                    <Area dataKey="val" stackId="a" />
                                </RechartAreaChart>
                            </ResponsiveContainer>
                        )}
                    </>
                ) : (
                    <div className={'emp-areaChart-loadingContainer'}>
                        <LoadingIndicator />
                    </div>
                )}
            </CardContent>
        </Grid>
    )
}

export default AreaChart

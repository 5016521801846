import React, { useRef, useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Typography, Divider } from '@mui/material'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import idDirectory from './idAttributes'
import {
    ConfirmCaseBuilderSummaryAGGrid,
    // ConfirmCaseBuilderSummaryGrid,
} from './grids'
import { CaseDetails, ProductDetails } from '../CaseBuilder/CaseBuilder.vm'
import { salesMethodsList } from '../InputCaseBuilderDetailsStep1/InputCaseBuilderDetailsStep1'

interface CustomerInfo {
    firstName: string
    lastName: string
}

export interface ConfirmCaseBuilderSummaryStep4Props {
    className?: string
    handleEditLink: () => void
    caseDetails: CaseDetails | null
    selectedProductDetails: ProductDetails | undefined
    setFormInputValues: (values: { [key: string]: any }) => void
}

const ConfirmCaseBuilderSummaryStep4 = ({
    className = '',
    handleEditLink,
    caseDetails,
    selectedProductDetails,
    setFormInputValues,
}: ConfirmCaseBuilderSummaryStep4Props) => {
    const confirmCaseBuilderSummaryGridRef = useRef<AgGridReact>(null)
    const [customerInfo, setCustomerInfo] = useState<CustomerInfo>({
        firstName: '',
        lastName: '',
    })

    useEffect(() => {
        if (caseDetails?.groups.length) {
            const infoObj: CustomerInfo = { firstName: '', lastName: '' }

            caseDetails?.groups[0].fields.forEach((field: any) => {
                if (field.id === 11) infoObj['firstName'] = field.value
                if (field.id === 12) infoObj['lastName'] = field.value
            })
            setCustomerInfo(infoObj)

            setFormInputValues({
                product_id: caseDetails?.product_id ?? null,
                category_id: null,
                sale_type: caseDetails?.sale_type ?? null,
                is_shippable: caseDetails?.is_shippable ?? false,
                is_service: caseDetails?.is_service ?? false,
                is_subscription: caseDetails?.is_subscription ?? false,
                new_product_name: '',
                new_product_description: '',
                type_id: caseDetails?.is_shippable
                    ? 1
                    : caseDetails?.is_service
                    ? 4
                    : 2,
                fields: [],
            })
        }
        // eslint-disable-next-line
    }, [caseDetails])

    return (
        <>
            <div id={idDirectory.divRoot} className={`${className}`}>
                <Typography variant="h1">Case Summary</Typography>
                <Divider
                    className={
                        'emp-confirmCaseBuilderSummaryStep4-divider'
                    }
                />
                {/* SECTION ONE */}
                <div
                    className={
                        'emp-confirmCaseBuilderSummaryStep4-sectionOneContainer'
                    }
                    id={idDirectory.divSectionOne}
                >
                    <div id={idDirectory.divCustomerProductDetails}>
                        <Typography variant="h5">
                            {`Customer: ${customerInfo.firstName} ${customerInfo.lastName}`}
                        </Typography>
                        <Typography variant="h5">
                            {`Product: ${
                                selectedProductDetails?.name.split(': ')[0] ?? ''
                            }`}
                        </Typography>
                        <Typography variant="h5">
                            {`Sales Method: ${
                                salesMethodsList.find(
                                    (method) =>
                                        method.id === caseDetails?.sale_type
                                )?.name ?? ''
                            }`}
                        </Typography>
                    </div>
                    <div id={idDirectory.divCustomerService}>
                        <Typography variant="h5">
                            Customer Service Notes:{' '}
                            <span
                                className={
                                    'emp-confirmCaseBuilderSummaryStep4-editLink'
                                }
                                onClick={handleEditLink}
                            >
                                Edit
                            </span>
                        </Typography>
                    </div>
                </div>
                {/* SECTION TWO */}
                <div
                    className={
                        'emp-confirmCaseBuilderSummaryStep4-sectionTwoContainer'
                    }
                    id={idDirectory.divSectionTwo}
                >
                    <div
                        className={
                            'emp-confirmCaseBuilderSummaryStep4-casesHeaderContainer'
                        }
                        id={idDirectory.divCasesHeader}
                    >
                        <div id={idDirectory.divCasesMainHeader}>
                            <Typography variant="h4">
                                Use Current Case as Template for Following
                                Cases:
                            </Typography>
                        </div>
                        <div
                            style={{ display: 'flex', alignItems: 'center' }}
                            id={idDirectory.divCasesAlertHeader}
                        >
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                                className={
                                    'emp-confirmCaseBuilderSummaryStep4-exclamationCircleIcon'
                                }
                            />
                            <Typography variant="h5">
                                Additional disputes for this credit card number
                                were found.
                            </Typography>
                        </div>
                    </div>
                    <div
                        className={
                            'emp-confirmCaseBuilderSummaryStep4-casesGridContainer'
                        }
                        id={idDirectory.divCasesGrid}
                    >
                        {/* AG GRID */}
                        <ConfirmCaseBuilderSummaryAGGrid
                            gridRef={confirmCaseBuilderSummaryGridRef}
                            testId={'confirmCaseBuilderSummaryStep4'}
                        />

                        {/* React-Tables GRID */}
                        {/* <ConfirmCaseBuilderSummaryGrid
                            testId={'confirmCaseBuilderSummaryStep4'}
                        /> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConfirmCaseBuilderSummaryStep4

import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material'
import { AlertsAutoRefundModal } from 'components/AlertsAutoRefundModal/AlertsAutoRefundModal'
import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { clientTheme } from 'theme-exports'

interface AlertsAutoRefundDialogProps {
    open: boolean
    onClose: () => void
}



export const AlertsAutoRefundDialog: React.FC<AlertsAutoRefundDialogProps> = ({
    open,
    onClose = () => {},
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            id={'preChargebacksAGAlertsAutoRefundDialog'}
        >
            <DialogTitle
                className={'emp-title'}
                style={{ borderTop: `solid 3px ${clientTheme.main}` }}
            >
                Auto Refund Rules
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={onClose}
                >
                    <span>
                        <Icon icon={faTimes} />
                    </span>
                </IconButton>
            </DialogTitle>
            <DialogContent className={'emp-content'}>
                <AlertsAutoRefundModal onClose={onClose} />
            </DialogContent>
        </Dialog>
    )
}

import { useState } from 'react'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'

export interface Topic {
    id: string
    title: string
    description: string
    videoUrl?: string
    videoType?: string
    priority?: number
    outro?: string
    createdAt?: string
    updatedAt?: string
    testId?: string
}

interface HelpInstance {
    topics: Topic[]
}

type HelpVM = () => HelpInstance

export const useHelp: HelpVM = () => {
    const { ADD_ONS } = useFeatureToggle('HELP')
    const [topics] = useState([
        {
            id: '1',
            title: 'Chargebacks',
            description:
                'This video walks merchants though the standard process of viewing their chargebacks and case outcomes. Merchants will get an overview of navigation techniques, including using the Advanced Search feature, field sorting, and field selection for fast, simple access to specific information.',
            videoUrl: ADD_ONS.videoURLs.chargebacks,
            videoType: 'video/mp4',
            priority: 99,
            createdAt: '2020-07-19T15:28:53.517Z',
            updatedAt: '2020-07-21T15:28:53.517Z',
            testId: 'chargebacks',
        },
        {
            id: '2',
            title: 'Alerts',
            description:
                'The Alerts page allows merchants who subscribe to an alert service to monitor and track their alerts. This video walks merchants through the process of monitoring and viewing alerts, including how to filter out specific groups, and how to sort groups by descriptor and date range.',
            videoUrl: ADD_ONS.videoURLs.alerts,
            videoType: 'video/mp4',
            testId: 'alerts',
        },
        {
            id: '3',
            title: 'Case Management',
            description:
                'This video explores the Case Management module, which allows specialist teams to easily manage the workflow of dispute cases. Topics covered include assigning cases for individual specialists, using bulk speed and efficiency, and building representments with or without the Case Builder feature.',
            videoUrl: ADD_ONS.videoURLs.caseManagement,
            videoType: 'video/mp4',
            testId: 'caseMgmt',
        },
        {
            id: '4',
            title: 'User Management',
            description:
                'While administrators have global access to the portal, the User Management tool allows them to manage user access and user permissions, controlling personnel access to the client portal for their company. This video demonstrates how to set up individual password-protected access levels.',
            videoUrl: ADD_ONS.videoURLs.userManagement,
            videoType: 'video/mp4',
            priority: 3,
            createdAt: '2020-05-11T15:28:53.517Z',
            updatedAt: '2020-06-23T15:28:53.517Z',
            testId: 'userMgmt',
        },
        {
            id: '5',
            title: 'Profile Settings',
            description:
                'The client portal allows for the creation of custom user profiles including options to receive notification emails and/or block IP addresses. This video shows the viewer how basic user profiles can be created, managed, and monitored.',
            videoUrl: ADD_ONS.videoURLs.profileSettings,
            videoType: 'video/mp4',
            priority: 3,
            createdAt: '2020-05-11T15:28:53.517Z',
            updatedAt: '2020-06-23T15:28:53.517Z',
            testId: 'profileSetting',
        },
        {
            id: '6',
            title: 'ERTs',
            description:
                'This video explains how ERT notifications work in the dispute merchant portal.',
            videoUrl: ADD_ONS.videoURLs.ertsFullService,
            videoType: 'video/mp4',
            testId: 'ertsFullService',
        },
        {
            id: '7',
            title: 'Review and Response Features',
            description:
                'This video describes the typical user process for reviewing and responding to chargeback cases at login.',
            videoUrl: ADD_ONS.videoURLs.reviewAndResponse,
            videoType: 'video/mp4',
            testId: 'reviewAndResponse',
        },
        // {
        //     id: '6',
        //     title: 'Case Builder',
        //     description:
        //         'This video shows the screens used in Case Builder to create an optimized defense to a chargeback. At the completion of the case builder, the system will compile a file that will be sent back to represent a case.',
        //     videoUrl: ADD_ONS.videoURLs.caseBuilder,
        //     videoType: 'video/mp4',
        //     testId: 'caseBuilder',
        // },
    ])

    return {
        topics,
    }
}

import React, { useState, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { CaseBuilderFormBuilder } from 'components'
import { CaseDetails, FormBuilderField } from '../CaseBuilder/CaseBuilder.vm'
import idDirectory from './idAttributes'

type TInputCaseBuilderDetailsStep2Inputs = {
    product_id: number | null
    category_id: number | null
    sale_type: number | null
    is_shippable: boolean
    is_service: boolean
    is_subscription: boolean
    new_product_name: string
    new_product_description: string
    type_id: number
}

export interface IInputCaseBuilderDetailsStep1Props {
    setEnableSaveAndContinue: (val: boolean) => void
    initialCaseDetails: CaseDetails | null
    setFormInputValues: (values: { [key: string]: any }) => void
    fieldErrorList?: any
}

const InputCaseBuilderDetailsStep2 = ({
    setEnableSaveAndContinue,
    initialCaseDetails,
    setFormInputValues,
    fieldErrorList = [],
}: IInputCaseBuilderDetailsStep1Props) => {
    const [isFormBuilderValid, setIsFormBuilderValid] = useState<boolean>(true)
    const [formBuilderValues, setFormBuilderValues] = useState<FormBuilderField[]>([])
    const [formBuilderInitialized, setFormBuilderInitialized] = useState<boolean>(false)

    const checkFormBuilderHasInvalidFields = (fields: any) => {
        return !fields.find((obj: FormBuilderField) => obj.required && !obj.value)
            ? true
            : false
    }
        
    const handleFormBuilderValueChange = (field: number, value: any) => {
        const newFormBuilderValues = formBuilderValues.map((obj: FormBuilderField) => obj.id === field ? {
            ...obj,
            value
        } : obj)
        const hasInvalidFields = checkFormBuilderHasInvalidFields(newFormBuilderValues)

        setFormBuilderValues(newFormBuilderValues)
        setIsFormBuilderValid(hasInvalidFields)
    }

    const defaultValues = useMemo<TInputCaseBuilderDetailsStep2Inputs>(() => {
        return {
            product_id: initialCaseDetails?.product_id ?? null,
            category_id: null,
            sale_type: initialCaseDetails?.sale_type ?? null,
            is_shippable: initialCaseDetails?.is_shippable ?? false,
            is_service: initialCaseDetails?.is_service ?? false,
            is_subscription: initialCaseDetails?.is_subscription ?? false,
            new_product_name: '',
            new_product_description: '',
            type_id: initialCaseDetails?.is_shippable
                ? 1
                : initialCaseDetails?.is_service
                ? 4
                : 2,
        }
    }, [initialCaseDetails])

    const { watch } = useForm<TInputCaseBuilderDetailsStep2Inputs>({
        defaultValues: defaultValues,
        values: defaultValues,
        mode: 'onChange',
    })

    useEffect(() => {
        const groupFields: FormBuilderField[] = []
        if (initialCaseDetails && !formBuilderInitialized) {
            initialCaseDetails.groups.map((group: any) => {
                return group.fields.map((field: any) => {
                    if (isFormBuilderValid && field.is_required && !field.value) setIsFormBuilderValid(false)
                    return groupFields.push({
                        id: field.id,
                        value: field.value,
                        required: field.is_required,
                    })
                })
            })
            setFormBuilderInitialized(true)
            setFormBuilderValues(groupFields)
            setIsFormBuilderValid(checkFormBuilderHasInvalidFields(groupFields))
        }
    }, [initialCaseDetails, isFormBuilderValid, formBuilderInitialized])

    useEffect(() => {
        const isEnableSaveAndContinue = isFormBuilderValid
        setEnableSaveAndContinue(isEnableSaveAndContinue)
        setFormInputValues({
            ...watch(),
            fields: [
                ...formBuilderValues.map((obj: FormBuilderField) => {
                    const { id, value } = obj

                    return { id, value }
                }),
            ],
        })
        // eslint-disable-next-line
    }, [watch, isFormBuilderValid, formBuilderValues])

    return (
        <div id={idDirectory.divRoot}>
            <CaseBuilderFormBuilder
                fieldGroups={initialCaseDetails?.groups || []}
                handleFormBuilderValueChange={handleFormBuilderValueChange}
                fieldErrorList={fieldErrorList}
            />
        </div>
    )
}

export default InputCaseBuilderDetailsStep2

import React, { useState } from 'react'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { IconButton, Tooltip } from '@mui/material'
import {
    DataGridSlim,
    Lamp,
    Searchbar,
    DataGridActions,
    AlertSnackbar
} from 'components'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { ExtendedColumn } from 'components/DataGrid/ExtendedColumn'
import { RemoveMidAccessConfirmation } from '../dialogs/RemoveMidAccessConfirmation'
import UsersApi from 'api/UsersApi'
import { User as Users } from 'hooks/cb-api'
import { useUsersDownload } from '../../Users/Users.vm'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import EntFeature from 'models/EntFeature'

interface RemoveAccessActionProps {
    title: string
    icon: any
    message?: string
    userId: number
    setForceReload: (val: boolean) => void
}

// Action icons for the User List DataGrid
const RemoveAccessAction: React.FC<RemoveAccessActionProps> = ({
    title,
    icon,
    message = '',
    userId,
    setForceReload,
}) => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const [openModal, setOpenModal] = useState(false)
    const [error, setError] = useState<Error | null>(null)

    const toggleModal = (): void => setOpenModal((prev) => !prev)

    const actionConfirmClicked = (): void => {
        UsersApi.lockUser(userId)
            .then(() => {
                toggleModal()
                //@ts-ignore
                setForceReload((prev) => !prev)
            })
            .catch(() => setError(new Error('Error removing user.')))
    }

    return (
        <>
            <RemoveMidAccessConfirmation
                open={openModal}
                actionConfirmListener={actionConfirmClicked}
                closeModal={toggleModal}
                error={error}
            />

            <Tooltip title={title} placement="top" arrow>
                <div>
                    <IconButton
                        className={
                            'emp-actionIcon'
                        }
                        onClick={toggleModal}
                        disabled={isReadOnly}
                        size="large"
                    >
                        <Icon
                            icon={icon}
                            className={`${
                                isReadOnly &&
                                'emp-disabledIcon'
                            }`}
                        />
                        {message}
                    </IconButton>
                </div>
            </Tooltip>
        </>
    )
}

interface UserStatusProps {
    state: boolean
}

// User Status
const UserStatus: React.FC<UserStatusProps> = ({ state }) => {
    return (
        <div
            className={
                'emp-lampWrapper'
            }
        >
            <Lamp
                className={'emp-lamp'}
                color={state ? 'green' : 'red'}
            />
            {state ? 'Active' : 'Inactive'}
        </div>
    )
}

type ContextMenuItem = {
    value: string
    operator: (selectedRows: any) => void
}

interface MerchantUsersGridProps {
    users?: Users[]
    midId: number
    loading: boolean
    contextMenuItems: ContextMenuItem[]
    setForceReload: (val: boolean) => void
    setSearch: (query: string) => void
    testId: string
    page: number
    pageSize: number
    onPageSizeChange: (size: number) => void
    onPageChange: (page: number) => void
    total: number
    params: { [key: string]: any }
    rehydrateView: () => void
    merchantDetails: any
}

enum UserStatuses {
    ACTIVE = 1,
    DELETED = 2,
    DISABLED = 3,
    EXPIRED = 4,
    PASSWORD = 5,
    LOCKED_OUT = 6,
}

// The datagrid to display selectable users of a chosen merchant/mid
export const MerchantUsersGrid: React.FC<MerchantUsersGridProps> = ({
    users,
    midId,
    loading,
    // contextMenuItems,
    setForceReload,
    setSearch,
    testId,
    page,
    pageSize,
    onPageSizeChange,
    onPageChange,
    total,
    params,
    rehydrateView,
    merchantDetails
}) => {
    const [hiddenColumns, setHiddenColumns] = useState([])
    const {
        downloadXlsx,
        isLoadingDownloadXlsx,
        isEmailExport,
        setIsEmailExport
    } = useUsersDownload()
    const uiState = useUiStateContext()
    const hasUsersExportButton = uiState.whoami?.hasRole(EntFeature.USERS_EXPORT_BUTTON) || false

    //TODO: Re-add missing columns
    const columnDefinitions: ExtendedColumn[] = [
        {
            Header: 'Role',
            accessor: 'role.name',
            type: 'string',
        },
        {
            Header: 'MID(s) Assigned',
            accessor: 'mids_count',
            type: 'string',
            Cell: (params) => {
                const { mids_allowed } = params.row.original as any
                if (midId) {
                    return <>{mids_allowed.includes(midId) ? '1/1' : '0/1'}</>
                }
                return (
                    <>{`${mids_allowed.length ?? 0}/${
                        merchantDetails?.mids?.length ?? 0
                    }`}</>
                )
            },
        },
        {
            Header: 'First Name',
            accessor: 'fname',
            type: 'string',
        },
        {
            Header: 'Last Name',
            accessor: 'lname',
            type: 'string',
        },
        {
            Header: 'Username',
            accessor: 'username',
            type: 'string',
        },
        {
            Header: 'User Status',
            accessor: 'status',
            type: 'string',
            Cell: (params) => (
                <UserStatus state={params.value.name === 'Active'} />
            ),
        },
        {
            Header: 'Remove MID Access',
            accessor: 'userPayload',
            type: 'icon',
            Cell: (params) => {
                // mids is an array of mids the user has access to
                // userId identifies the user with said access.... uhhhhh duh? lol

                const rowData = params.row.original as any
                const isActive = rowData.status.id === UserStatuses.ACTIVE

                // Don't display enable/disable user on MIDs users grid
                // or if user is already inactive  per EXT-1164
                if (midId || !isActive) return <></>

                return (
                    <>
                        <RemoveAccessAction
                            title="Remove Access to All MIDs"
                            icon={faTimesCircle}
                            userId={rowData.id}
                            setForceReload={setForceReload}
                        />
                    </>
                )
            },
        },
    ]

    const columns = React.useMemo(() => columnDefinitions, [columnDefinitions])

    const headersXlsx = ['role', 'fname', 'lname', 'username', 'status']

    return (
        <div id={'merchantHierarchyMerchantUsersGrid'}>
            <div className={'emp-searchbarContainer'}>
                <Searchbar
                    className={'emp-cssOutlinedInput'}
                    debounceDelay={200}
                    returnValue={setSearch}
                    testId={testId}
                />
                <DataGridActions
                    gridName="merchantSettingsUsers"
                    columns={columns}
                    tableData={users}
                    hiddenColumns={hiddenColumns}
                    setHiddenColumns={setHiddenColumns}
                    dataActionExportAll={!hasUsersExportButton ? false : true}
                    dataActionCSV={!hasUsersExportButton ? true : false}
                    dataActionPrint={false}
                    onExportXlsx={() =>
                        downloadXlsx(
                            {
                                export_type: 'page',
                                export_total: total,
                                ...params,
                            },
                            'Merchant-Settings-Users',
                            true,
                            headersXlsx,
                            merchantDetails?.id
                        )
                    }
                    onExportAll={() => {
                        downloadXlsx(
                            {
                                export_type: 'all',
                                export_total: total,
                                ...params,
                            },
                            'Merchant-Settings-Users',
                            false,
                            headersXlsx,
                            merchantDetails?.id
                        )
                    }}
                    onExportAllLoading={isLoadingDownloadXlsx}
                    onExportXlsxLoading={isLoadingDownloadXlsx}
                    printId="cb-cases-dg"
                    testId={testId}
                    fileName={'Merchant-Settings-Users'}
                />
            </div>
            <div style={{ overflowX: 'auto' }}>
                <DataGridSlim
                    //TODO: Need to setup context menu
                    // contextMenu
                    // contextMenuItems={contextMenuItems}
                    loading={loading}
                    columns={columns}
                    data={users}
                    defaultRows={25}
                    expandable={false}
                    stickyHeaders={false}
                    hiddenColumns={hiddenColumns}
                    testId={testId}
                    hideRowCheckboxes
                    disableSelectAllCheckbox
                    page={page}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    pageSize={pageSize}
                    onPageLimitChange={onPageSizeChange}
                    onPageChange={onPageChange}
                    totalEntries={total}
                    refreshDataGrid={() => rehydrateView()}
                />
            </div>
            <AlertSnackbar
                content={{
                    message:
                        'This may take up to 15 minutes. We will send you an email with a link to download your export when it’s ready. Thank you!',
                    title: 'Export started.',
                    intent: 'success',
                }}
                onClose={() => setIsEmailExport(false)}
                autoHideDuration={5_000}
                open={isEmailExport}
                showCloseIcon
            />
        </div>
    )
}

/**
 * @description Directory for ID Attributes on ConfirmActionModal.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `confirmActionModal-div-root`,
    divHeader: `confirmActionModal-div-title`,
    divContent: `confirmActionModal-div-content`,
    divActions: `confirmActionModal-div-actions`,
    btnCancel: `confirmActionModal-btn-cancel`,
    btnConfirm: `confirmActionModal-btn-confirm`,
}

export default idDirectory

import React, { useState, useCallback } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'
import ReactPaginate from 'react-paginate'
import idDirectory from './idAttributes'
import { clientTheme } from 'theme-exports'

interface PaginateProps {
    paginationOptions: number[]
    pageCount: any
    gotoPage: any
    nextPage: any
    previousPage: any
    setPageSize: any
    tableState: {
        pageSize: number
        rowsLength: number
    }
    config?: {
        pageBlockLength: number
    }
    page?: number
    loading: boolean
    testId?: string
    // Use if resetting pagination to page 1 and NOT using SWR
    resetPagination?: boolean
    handleResetPagination?: (val: boolean) => void
    [key: string]: any
}

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        '& > li': {
            padding: '4px 4px',
            textTransform: 'capitalize',
            color: '#797979',
            fontSize: clientTheme.typography.subtitle3.fontSize,
            fontWeight: 500,
            background: '#fff',
        },
        '& a': {
            color: clientTheme.hyperlink,
        },
    },
}))

/**
 * Paginate is used to paginate data server side, it currently is setup to only show 8 page blocks at a time, in the future this should be setup to accept a page block length prop
 */
const Paginate = ({
    paginationOptions,
    pageCount,
    gotoPage,
    setPageSize,
    tableState,
    totalEntries,
    config = {
        pageBlockLength: 6,
    },
    page,
    clearSelectedRows,
    loading,
    testId,
    resetPagination = false,
    handleResetPagination = () => {},
}: PaginateProps) => {
    const classes = useStyles()
    const { pageBlockLength } = config
    const [pageIndexRef, setPageIndexRef] = useState({
        start: 0,
        end: pageBlockLength,
        pageActive: 1,
    })

    const pageCountCeiling = Math.ceil(pageCount)
    const { pageSize, rowsLength } = tableState
    const pageActive = pageIndexRef.pageActive
    const showingStartValue =
        pageSize * pageIndexRef.pageActive - (pageSize - 1)
    const showingEndValue =
        pageSize * pageActive <= totalEntries
            ? pageSize * pageActive
            : totalEntries

    // This is not pretty but will suffice until we've either a) planned on how to address this
    // more efficiently in DataGrid or b) leverage a more robust 3rd party data grid similar to
    // AGGrid, etc. This combats a potential issue between filtering and being on pages 2+.
    React.useEffect(() => {
        if (
            (pageActive > 1 && rowsLength === 0 && totalEntries) ||
            resetPagination
        ) {
            setPageIndexRef(() => ({
                start: 0,
                end: pageBlockLength,
                pageActive: 1,
            }))
            gotoPage(1)
            handleResetPagination(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageActive, rowsLength, totalEntries, gotoPage, pageBlockLength])


    const handlePageChange = useCallback(
        (page: number) => {
            gotoPage(page + 1)
            setPageIndexRef((prev) => ({
                ...prev,
                pageActive: page + 1,
            }))
            // clears selected row indexs in DataGrid state on every page change
            clearSelectedRows()
        },
        [gotoPage, clearSelectedRows]
    )

    return (
        <div>
            <div
                id={`${idDirectory.paginate.divEntries}-${testId}`}
                className={'emp-paginate-showingResults'}
                style={{ fontSize: clientTheme.typography.subtitle3.fontSize }}
            >
                <div style={{ display: 'flex' }}>
                    <span>Showing </span>
                    <span className={'emp-paginate-paginationText'}>
                        {!loading ? (
                            showingStartValue.toLocaleString('en-US')
                        ) : (
                            <Skeleton variant="text" width="20px" />
                        )}
                    </span>
                    <span> to </span>
                    <span className={'emp-paginate-paginationText'}>
                        {!loading ? (
                            showingEndValue.toLocaleString('en-US')
                        ) : (
                            <Skeleton variant="text" width="20px" />
                        )}
                    </span>
                    <span> of </span>
                    <span className={'emp-paginate-paginationText'}>
                        {!loading ? (
                            totalEntries.toLocaleString('en-US')
                        ) : (
                            <Skeleton variant="text" width="30px" />
                        )}
                    </span>
                    <span>entries</span>
                </div>
            </div>
            <div className={'emp-paginate-root'}>
                <div
                    id={`${idDirectory.paginate.divShowEntries}-${testId}`}
                    className={'emp-paginate-entriesContainer'}
                >
                    <div
                        className={'emp-paginate-entriesDropdown'}
                        style={{
                            fontSize: clientTheme.typography.subtitle3.fontSize,
                        }}
                        id={`${idDirectory.paginate.divDropdown}-${testId}`}
                    >
                        <p>Show</p>
                        <select
                            value={pageSize}
                            onChange={(
                                e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                                setPageSize(Number(e.target.value))
                                setPageIndexRef((prev) => ({
                                    ...prev,
                                    pageActive: 1,
                                }))
                            }}
                        >
                            {paginationOptions.map((option: number) => {
                                return (
                                    <option
                                        key={`paginate-${option}`}
                                        value={option}
                                    >
                                        {option}
                                    </option>
                                )
                            })}
                        </select>
                        <p>entries</p>
                    </div>
                </div>
                <div
                    className={'emp-paginate-paginateControlsContainer'}
                    id={`${idDirectory.paginate.divPaginationContainer}-${testId}`}
                >
                    <div
                        id={`${idDirectory.paginate.divPagination}-${testId}`}
                        className={'emp-paginate-paginationControl'}
                    >
                        {totalEntries ? (
                            <ReactPaginate
                                previousLabel="Previous"
                                previousClassName="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedContained MuiButtonGroup-groupedContainedHorizontal MuiButtonGroup-groupedContained MuiButton-disableElevation"
                                previousLinkClassName="MuiButton-label"
                                nextClassName="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedContained MuiButtonGroup-groupedContainedHorizontal MuiButtonGroup-groupedContained MuiButton-disableElevation"
                                nextLinkClassName={'MuiButton-label'}
                                disabledClassName={'Mui-disabled'}
                                pageClassName="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedContained MuiButtonGroup-groupedContainedHorizontal MuiButtonGroup-groupedContained MuiButton-disableElevation"
                                pageLinkClassName="MuiButton-label"
                                nextLabel="Next"
                                breakLabel="..."
                                breakClassName="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedContained MuiButtonGroup-groupedContainedHorizontal MuiButtonGroup-groupedContained MuiButton-disableElevation Mui-disabled"
                                pageCount={pageCountCeiling}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={5}
                                onPageChange={({ selected }) => {
                                    handlePageChange(selected)
                                }}
                                containerClassName={`${'emp-paginate-btnGrpContained'} ${
                                    classes.btnGroup
                                } ${'emp-paginate-btnGrp'} MuiButtonGroup-root MuiButtonGroup-disableElevation MuiButtonGroup-contained`}
                                activeClassName={'emp-paginate-activePageBtn'}
                                forcePage={(page ?? 1) - 1}
                            />
                        ) : (
                            <ul
                                className={`${'emp-paginate-btnGrpContained'} ${
                                    classes.btnGroup
                                } ${'emp-paginate-btnGrp'} MuiButtonGroup-root MuiButtonGroup-disableElevation MuiButtonGroup-contained`}
                            >
                                <li
                                    className={
                                        'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedContained MuiButtonGroup-groupedContainedHorizontal MuiButtonGroup-groupedContained MuiButton-disableElevation Mui-disabled dummy'
                                    }
                                >
                                    Previous
                                </li>
                                <li
                                    className={
                                        'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedContained MuiButtonGroup-groupedContainedHorizontal MuiButtonGroup-groupedContained MuiButton-disableElevation Mui-disabled dummy'
                                    }
                                >
                                    1
                                </li>
                                <li
                                    className={
                                        'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButtonGroup-grouped MuiButtonGroup-groupedHorizontal MuiButtonGroup-groupedContained MuiButtonGroup-groupedContainedHorizontal MuiButtonGroup-groupedContained MuiButton-disableElevation Mui-disabled dummy'
                                    }
                                >
                                    Next
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Paginate

import {
    addDays,
    subDays,
    startOfMonth,
    endOfDay,
    startOfDay,
    toDate,
    compareAsc,
    subMonths,
} from 'date-fns'

// always returns what should be expected: a Date type
// if a component needs a date string, it can handle it's formatting

interface DateRange {
    (days?: number): {
        to: Date
        from: Date
    }
}

interface DateUtilities {
    getToday: DateRange
    getPriorDays: DateRange
    getPriorMonths: DateRange
    getPriorDaysDate: (days: number) => { to: string; from: string }
    getPriorAndFutureDays: (from: number, to: number) => { to: Date; from: Date }
    getNextDays: DateRange
    getNextDaysDate: (days: number) => { to: string; from: string }
    getMonth: () => { to: string; from: string }
    normalizeDateOffset: (date: Date) => Date
    differenceInDays: (currentDate: Date, endDate: Date) => any
}

export const dateUtilities: DateUtilities = {
    // the current day from 12:01am to 11:59pm
    getToday: () => {
        return {
            to: endOfDay(new Date()), // current day
            from: startOfDay(new Date()), // current day
        }
    },
    // returns from the start of (12:01am) either yesterday, last 7 days and last 30 days up to 11:59pm of the current day - defaults to return yesterday (- 1)
    getPriorDays: (days = 1) => {
        const subtractedDays = subDays(new Date(), days)
        return {
            to: endOfDay(new Date()), // current day
            from: startOfDay(subtractedDays), // starting date
        }
    },
    getPriorMonths: (months = 1) => {
        const subtractedMonths = subMonths(new Date(), months)
        return {
            to: endOfDay(new Date()), // current day
            from: startOfDay(subtractedMonths), // starting date
        }
    },
    getPriorDaysDate: (days = 1) => {
        const subtractedDays = subDays(new Date(), days)
        return {
            to: new Date().toISOString().split('T')[0], // current day
            from: startOfDay(subtractedDays).toISOString().split('T')[0], // starting date
        }
    },
    getPriorAndFutureDays: (priorDays = 1, futureDays = 1) => {
        const priorDaysDate = subDays(new Date(), priorDays)
        const futureDaysDate = addDays(new Date(), futureDays)
        return {
            from: endOfDay(priorDaysDate),
            to: startOfDay(futureDaysDate),
        }
    },
    // returns from the start of today, up to 11:59pm on the following nth day, determined by passing the days argument (default to 1).
    getNextDays: (days = 1) => {
        const futureDaysDate = addDays(new Date(), days)
        return {
            to: endOfDay(futureDaysDate), // x days from today
            from: startOfDay(new Date()), // starting date
        }
    },
    getNextDaysDate: (days = 1) => {
        const futureDaysDate = addDays(new Date(), days)
        return {
            to: endOfDay(futureDaysDate).toISOString().split('T')[0], // x days from today
            from: startOfDay(new Date()).toISOString().split('T')[0], // starting date
        }
    },
    // returns the first of the current month from 12:01am up to 11:59pm of the current day.
    getMonth: () => {
        const formatMonth = (date: Date) => {
            const offset = date.getTimezoneOffset()
            date = new Date(date.getTime() - offset * 60 * 1000)
            return date.toISOString().split('T')[0]
        }

        return {
            to: formatMonth(endOfDay(new Date())),
            from: formatMonth(startOfMonth(new Date())),
        }
    },
    // returns a normalized date with no timezone offsets - ie. eleminates JS issue of returning a day ahead or behind depending on timezone
    normalizeDateOffset: (date: Date): Date =>
        new Date(date.getTime() - date.getTimezoneOffset() * -60000),

    differenceInDays: (currentDate: Date, endDate: Date): any => {
        const dateLeft = toDate(currentDate)
        const dateRight = toDate(endDate)

        const getTimezoneOffsetInMilliseconds = (date: Date) => {
            const MILLISECONDS_IN_MINUTE = 60000
            const freshDate = new Date(date.getTime())
            const baseTimezoneOffset = date.getTimezoneOffset()
            freshDate.setSeconds(0, 0)
            const millisecondsPartOfTimezoneOffset =
                freshDate.getTime() % MILLISECONDS_IN_MINUTE

            return (
                baseTimezoneOffset * MILLISECONDS_IN_MINUTE +
                millisecondsPartOfTimezoneOffset
            )
        }

        const calendarDayDiff = (dateStart: Date, dateEnd: Date): any => {
            const MILLISECONDS_IN_DAY = 86400000
            const startOfDayLeft = startOfDay(dateStart)
            const startOfDayRight = startOfDay(dateEnd)

            const timestampLeft =
                startOfDayLeft.getTime() -
                getTimezoneOffsetInMilliseconds(startOfDayLeft)
            const timestampRight =
                startOfDayRight.getTime() -
                getTimezoneOffsetInMilliseconds(startOfDayRight)

            // Round the number of days to the nearest integer
            // because the number of milliseconds in a day is not constant
            return Math.round(
                (timestampLeft - timestampRight) / MILLISECONDS_IN_DAY
            )
        }

        const sign = compareAsc(dateLeft, dateRight)
        const difference = Math.abs(calendarDayDiff(dateLeft, dateRight))

        dateLeft.setDate(dateLeft.getDate() - sign * difference)

        // Math.abs(diff in full days - diff in calendar days) === 1 if last calendar day is not full
        // If so, result must be decreased by 1 in absolute value
        const isLastDayNotFull: any = compareAsc(dateLeft, dateRight) === -sign
        const result = sign * (difference - isLastDayNotFull)
        // Prevent negative zero
        return result
    },
}

import React, { useState } from 'react'
import {
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons'
import { clientTheme } from 'theme-exports'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface PaginateProps {
    pageCount: any
    pageSize: number
    totalEntries: number
    setStart: (val: number) => void
    className?: string
}

/**
 * Pagination is used to paginate data server side
 */
const Pagination = ({
    pageCount,
    setStart,
    pageSize,
    totalEntries,
    className,
}: PaginateProps) => {
    const [currentPage, setCurrentPage] = useState(1)

    const showingStartValue = pageSize * currentPage - (pageSize - 1)
    const showingEndValue =
        pageSize * currentPage <= totalEntries
            ? pageSize * currentPage
            : totalEntries

    const handlePageChange = (move: string) => {
        if (!pageCount) return
        if (move === 'next' && currentPage !== pageCount) {
            setCurrentPage((prev) => prev + 1)
        }
        if (move === 'previous' && currentPage !== 1) {
            setCurrentPage((prev) => prev - 1)
        }
    }

    React.useEffect(() => {
        const start = pageSize * (currentPage - 1)
        setStart(start)
        // eslint-disable-next-line
    }, [currentPage])

    React.useEffect(() => {
        setCurrentPage(1)
    }, [totalEntries])

    return (
        <div className={`emp-pagination-root ${className}`}>
            <div className={'emp-pagination-showingResults'}>
                <p>
                    <span>Showing </span>
                    <span>{showingStartValue}</span>
                    <span> to </span>
                    <span>{showingEndValue}</span>
                    <span> of </span>
                    <span>{totalEntries.toLocaleString('en-US')}</span>
                    <span> entries</span>
                </p>
            </div>

            <div className={'emp-pagination-paginationControl'}>
                <p>
                    <span
                        className={
                            currentPage === 1
                                ? 'emp-pagination-inactiveIcon'
                                : 'emp-pagination-activeIcon'
                        }
                        style={{
                            color: currentPage === 1 ? '' : clientTheme.main,
                        }}
                        onClick={() => setCurrentPage(1)}
                    >
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                    </span>
                    <span
                        className={
                            currentPage === 1
                                ? 'emp-pagination-inactiveIcon'
                                : 'emp-pagination-activeIcon'
                        }
                        style={{
                            color: currentPage === 1 ? '' : clientTheme.main,
                        }}
                        onClick={() => handlePageChange('previous')}
                    >
                        {' '}
                        <FontAwesomeIcon icon={faAngleLeft} />{' '}
                    </span>
                    <span>Page </span>
                    <span>{currentPage}</span>
                    <span> of </span>
                    <span>{pageCount}</span>
                    <span> page{pageCount !== 1 && 's'} </span>
                    <span
                        className={
                            currentPage === pageCount || !pageCount
                                ? 'emp-pagination-inactiveIcon'
                                : 'emp-pagination-activeIcon'
                        }
                        style={{
                            color:
                                currentPage === pageCount || !pageCount
                                    ? ''
                                    : clientTheme.main,
                        }}
                        onClick={() => handlePageChange('next')}
                    >
                        {' '}
                        <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                    <span
                        className={
                            currentPage === pageCount || !pageCount
                                ? 'emp-pagination-inactiveIcon'
                                : 'emp-pagination-activeIcon'
                        }
                        style={{
                            color:
                                currentPage === pageCount || !pageCount
                                    ? ''
                                    : clientTheme.main,
                        }}
                        onClick={() => {
                            if (!pageCount) return
                            setCurrentPage(pageCount)
                        }}
                    >
                        {' '}
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                    </span>
                </p>
            </div>
        </div>
    )
}

export default Pagination

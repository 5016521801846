import { useState, useEffect } from 'react'
import axios from 'axios'
import useIsMounted from 'hooks/utils/useIsMounted'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import { DocumentOption } from '../UploadCaseBuilderDocumentsStep3/UploadCaseBuilderDocumentsStep3'
import { CaseData } from 'hooks/cb-api'

export interface ProductDetails {
    id: number
    category: null | string
    delivery: null | string
    description: string
    mid_id: number
    name: string
    originalName: string
    sku: null | number
    url: null | string
    type: {
        id: number
        description: string
        is_tangible: boolean
        name: string
        type: number
    }
}

interface Field {
    id: number
    label: string
    is_required: boolean
    type: string
    endpoint: null | string
    value: null | string
    errors: string[]
}

interface Group {
    label: string
    fields: Field[]
}

export interface CaseDetails {
    page: number
    product_id: null | number
    sale_type: null | number
    is_shippable: boolean
    is_service: boolean
    is_subscription: boolean
    groups: Group[]
    last_updated: {
        username: null | string
        timestamp: null | string | Date
    }
}

export interface FormBuilderField {
    id: number
    value: any
    required: boolean | undefined
}

interface CaseBuilderVM {
    caseBuilderStep: number
    setCaseBuilderStep: (step: number) => void
    enableSaveAndContinue: boolean
    setEnableSaveAndContinue: (value: boolean) => void
    alertSnackbarProps: alertSnackbarContentProps
    setAlertSnackbarProps: (value: alertSnackbarContentProps) => void
    alertSnackbarSubmitRepresentmentOpen: boolean
    setAlertSnackbarSubmitRepresentmentOpen: (value: boolean) => void
    alertSnackbarOpen: boolean
    setAlertSnackbarOpen: (value: boolean) => void
    isLoading: boolean
    isSubmitting: boolean
    isSubmittingRepresentment: boolean
    initialCaseDetails: CaseDetails | null
    setInitialCaseDetails: (caseDetails: CaseDetails | null) => void
    responseCaseDetails: CaseDetails | null
    handleCaseBuilderStepChange: (move: 'back' | 'continue') => void
    handleInitialGetCaseDetails: (page: number) => void
    handleSaveInputDetails: (values: { [key: string]: any }) => void
    handleSaveUploadDocuments: (values: { [key: string]: any }) => void
    openSubmitRepresentmentModal: boolean
    setOpenSubmitRepresentmentModal: (value: boolean) => void
    combinedDocumentsList: DocumentOption[]
    setCombinedDocumentsList: (item: DocumentOption[]) => void
    savedDocumentsList: DocumentOption[]
    setSavedDocumentsList: (item: DocumentOption[]) => void
    fieldErrorsList: { id: number; errors: string[] }[]
    setFieldErrorsList: (value: { id: number; errors: string[] }[]) => void
    setFormProductTypeId: (value: number | undefined) => void
    formProductTypeId: number | undefined
    setSelectedProductDetails: (value: ProductDetails) => void
    selectedProductDetails: ProductDetails | undefined
    loadingMaxUploadFileSize: boolean
    maxUploadFileSize: number
}

const useCaseBuilder = (
    openCaseBuilder: boolean,
    caseInfo: CaseData,
    handleResetCaseBuilder: () => void,
    refreshGrid: () => void,
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void,
    setAlertSnackbarMainOpen: (status: boolean) => void
): CaseBuilderVM => {
    const { isMounted } = useIsMounted()
    const {
        id: case_id,
        mid_id
    } = caseInfo
    const [caseBuilderStep, setCaseBuilderStep] = useState<number>(1)
    const [enableSaveAndContinue, setEnableSaveAndContinue] = useState<boolean>(
        false
    )
    const [
        alertSnackbarSubmitRepresentmentOpen,
        setAlertSnackbarSubmitRepresentmentOpen,
    ] = useState<boolean>(false)
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})
    const [
        openSubmitRepresentmentModal,
        setOpenSubmitRepresentmentModal,
    ] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [isSubmittingRepresentment, setIsSubmittingRepresentment] = useState<boolean>(false)
    const [
        initialCaseDetails,
        setInitialCaseDetails,
    ] = useState<CaseDetails | null>(null)
    const [
        responseCaseDetails,
        setResponseCaseDetails,
    ] = useState<CaseDetails | null>(null)
    const [combinedDocumentsList, setCombinedDocumentsList] = useState<
        DocumentOption[]
    >([])
    const [savedDocumentsList, setSavedDocumentsList] = useState<
        DocumentOption[]
    >([])
    const [fieldErrorsList, setFieldErrorsList] = useState<
        { id: number; errors: string[] }[]
    >([])
    const [formProductTypeId, setFormProductTypeId] = useState<number | undefined>(undefined)
    const [selectedProductDetails, setSelectedProductDetails] = useState<
        ProductDetails | undefined
    >(undefined)
    const [
        loadingMaxUploadFileSize,
        setLoadingMaxUploadFileSize,
    ] = useState<boolean>(false)
    const [maxUploadFileSize, setMaxUploadFileSize] = useState<number>(0)

    const handleCaseBuilderStepChange = (move: 'back' | 'continue') => {
        if (move === 'continue' && caseBuilderStep === 4) {
            setOpenSubmitRepresentmentModal(true)
        }
        if (move === 'continue' && caseBuilderStep !== 4) {
            setInitialCaseDetails(null)
            setCaseBuilderStep(caseBuilderStep + 1)
        }
        if (move === 'back' && caseBuilderStep !== 1) {
            setInitialCaseDetails(null)
            setCaseBuilderStep(caseBuilderStep - 1)
        }
    }

    const handleInitialGetCaseDetails = async (page: number) => {
        if (openCaseBuilder) {
            setIsLoading(true)
            setEnableSaveAndContinue(false)
            try {
                const response = await axios.get(
                    `/builder/case/${case_id}?page=${page === 4 ? 1 : page}`
                )
                const data = response.data
                if (isMounted.current) setInitialCaseDetails(data)
            } catch (error) {
                if (isMounted.current) {
                    setAlertSnackbarProps({
                        title: 'Error',
                        message: `An error occurred. Please try again later.`,
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                }
            } finally {
                if (isMounted.current) {
                    setIsLoading(false)
                    caseBuilderStep === 4 && setEnableSaveAndContinue(true)
                }
            }
        }
    }

    useEffect(() => {
        if (openCaseBuilder)
            handleInitialGetCaseDetails(caseBuilderStep)
        // eslint-disable-next-line
    }, [openCaseBuilder, caseBuilderStep])

    const handleSaveInputDetails = async (values: { [key: string]: any }) => {
        let newProductId: number | undefined = undefined

        caseBuilderStep === 4
            ? setIsSubmittingRepresentment(true)
            : setIsSubmitting(true)

        try {
            if (!values.product_id && caseBuilderStep === 1) {
                const response = await axios.post(`/builder/products`, {
                    mid_id,
                    name: values.new_product_name,
                    description: values.new_product_description,
                    type: values.type_id,
                    category: values.category_id,
                })
                newProductId = response.data.id
                setSelectedProductDetails({
                    ...response.data,
                    name: `${response.data.name}: ${response.data.type.name}`,
                    originalName: response.data.name,
                })
            }
            if (
                values.product_id
                && caseBuilderStep === 1
                && (formProductTypeId !== selectedProductDetails?.type?.id)
            ) {
                const response = await axios.post(`/builder/products`, {
                    mid_id,
                    name: selectedProductDetails?.originalName || null,
                    description: selectedProductDetails?.description || null,
                    type: values.type_id,
                    category: selectedProductDetails?.category || null,
                })
                newProductId = response.data.id
            }
            const response = await axios.put(`/builder/case/${case_id}`, {
                ...values,
                page: caseBuilderStep + 1,
                product_id: newProductId ?? values.product_id,
                type_id: undefined,
                category_id: undefined,
                new_product_name: undefined,
                new_product_description: undefined,
                fields: values.fields ? [...values.fields] : [],
            })

            if (isMounted.current) {
                if (caseBuilderStep === 4) {
                    setOpenSubmitRepresentmentModal(false)
                    handleResetCaseBuilder()
                    setAlertSnackbarMainProps({
                        title: 'Success',
                        message: 'Successfully submitted representment',
                        intent: 'success',
                    })
                    setAlertSnackbarMainOpen(true)
                    refreshGrid()
                } else {
                    setResponseCaseDetails(response.data)
                    setFieldErrorsList([])
                    setEnableSaveAndContinue(false)
                    handleCaseBuilderStepChange('continue')
                }
            }
        } catch (error) {
            const typedError = error as any
            const fieldErrorsResponse: Field[] =
                typedError?.response?.data?.data?.fields ?? []

            if (isMounted.current) {
                if (fieldErrorsResponse.length) {
                    const fieldErrors = fieldErrorsResponse.map(
                        (field: Field) => {
                            return {
                                id: field.id,
                                errors: field.errors,
                            }
                        }
                    )

                    setFieldErrorsList(fieldErrors)
                    setEnableSaveAndContinue(false)
                } else {
                    setAlertSnackbarProps({
                        title: 'Error',
                        message: `An error occurred. Please try again later.`,
                        intent: 'error',
                    })
                    caseBuilderStep === 4 && openSubmitRepresentmentModal
                        ? setAlertSnackbarSubmitRepresentmentOpen(true)
                        : setAlertSnackbarOpen(true)
                   
                }
            }
        } finally {
            if (isMounted.current) {
                setIsSubmitting(false)
                setIsSubmittingRepresentment(false)
            }
        }
    }

    const handleSaveUploadDocuments = async (formValues: { [key: string]: any }) => {
        setSavedDocumentsList(combinedDocumentsList)
        setIsSubmitting(true)

        const filteredCaseDocuments = combinedDocumentsList.filter(
            (doc: DocumentOption) => doc.file
        )

        const filesArray: { [key: string]: number | string }[] = []
        const templatesArray: { [key: string]: number }[] = []

        filteredCaseDocuments.forEach((doc: DocumentOption, idx: number) => {
            const order = idx + 1
            doc.default
                ? filesArray.push({
                      ...doc.file,
                      order_num: order,
                      upload_doc_type_id: doc.id,
                  })
                : templatesArray.push({
                      id: doc.id,
                      order_num: order,
                      upload_doc_type_id: 9,
                  })
        })

        try {
            await axios.post(`/docs/builder/upload`, {
                case_id: case_id,
                files: filesArray,
                templates: templatesArray,
            })
            handleSaveInputDetails(formValues)
        } catch (error) {
            if (isMounted.current) {
                setAlertSnackbarProps({
                    title: 'Error',
                    message: `An error occurred. Please try again later.`,
                    intent: 'error',
                })
                setAlertSnackbarOpen(true)
            }
        } finally {
        }
    }

    useEffect(() => {
        if (openCaseBuilder && caseBuilderStep === 3) {
            setLoadingMaxUploadFileSize(true)
            axios
                .get(`/config/cfg/stack_config_be`)
                .then((res) => {
                    const maxTotalBytes =
                        res.data?.attr?.max_representment_upload_size

                    setMaxUploadFileSize(maxTotalBytes)
                })
                .finally(() => setLoadingMaxUploadFileSize(false))
        }
    }, [openCaseBuilder, caseBuilderStep])

    return {
        caseBuilderStep,
        setCaseBuilderStep,
        enableSaveAndContinue,
        setEnableSaveAndContinue,
        alertSnackbarProps,
        setAlertSnackbarProps,
        alertSnackbarSubmitRepresentmentOpen,
        setAlertSnackbarSubmitRepresentmentOpen,
        alertSnackbarOpen,
        setAlertSnackbarOpen,
        isLoading,
        isSubmitting,
        isSubmittingRepresentment,
        initialCaseDetails,
        setInitialCaseDetails,
        responseCaseDetails,
        handleCaseBuilderStepChange,
        handleInitialGetCaseDetails,
        handleSaveInputDetails,
        handleSaveUploadDocuments,
        openSubmitRepresentmentModal,
        setOpenSubmitRepresentmentModal,
        combinedDocumentsList,
        setCombinedDocumentsList,
        savedDocumentsList,
        setSavedDocumentsList,
        fieldErrorsList,
        setFieldErrorsList,
        setFormProductTypeId,
        formProductTypeId,
        setSelectedProductDetails,
        selectedProductDetails,
        loadingMaxUploadFileSize,
        maxUploadFileSize,
    }
}

export default useCaseBuilder

import React, {  useState, useCallback } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { IconButton, Tooltip } from '@mui/material'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { iconsTheme, clientTheme } from 'theme-exports'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { Icon, AlertSnackbar, useActiveMerchant } from 'components'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import AuthCombinedApi from 'api/AuthCombinedApi'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import { MidPermissions, SitePermissions, TempPasswordRequest } from '../modals'
import UsersApi from 'api/UsersApi'

interface EditIconProps {
    id?: string | number
    disabled?: boolean
    rowIndex: string
}

export const EditIconAction = ({
    id = '',
    disabled = false,
    rowIndex,
}: EditIconProps): React.ReactElement => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    return (
        <div id={'usersEditIconAction'}>
            {!disabled && !isReadOnly ? (
                <Tooltip arrow placement="top" title="Edit User">
                    <Link to={`/user/update/${id}`}>
                        <IconButton size="small">
                            <Icon
                                className={`${iconsTheme.edit}`}
                                id={`user-edit-icon-${rowIndex}`}
                                iconColor={clientTheme.icons.editUserIcon}
                            />
                        </IconButton>
                    </Link>
                </Tooltip>
            ) : (
                <Tooltip arrow placement="top" title="Edit User (disabled)">
                    <div>
                        <IconButton size="small" disabled={true}>
                            <Icon
                                className={`${
                                    iconsTheme.edit
                                } ${'emp-disabledIcon'}`}
                                id={`user-edit-disabled-icon-${rowIndex}`}
                                iconColor={clientTheme.icons.editUserIcon}
                            />
                        </IconButton>
                    </div>
                </Tooltip>
            )}
        </div>
    )
}

export const ToggleLockIconAction = ({
    isCurrentUser,
    defaultLocked,
    user,
    rowIndex,
}: {
    isCurrentUser: boolean
    defaultLocked: boolean
    user: any
    rowIndex: number
}): React.ReactElement => {
    const { user: currentUser } = useAuthedUser()
    const isReadOnly = currentUser?.is_read_only

    const [lockedState, setLockedState] = useState(defaultLocked)
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const handleChangeStatus = useCallback((user, isLocked) => {
        if (isLocked) {
            UsersApi.lockUser(user.id)
                .then(() => {
                    setLockedState(true)
                    setAlertSnackbarProps({
                        title: 'Success',
                        message: 'User has been successfully locked.',
                        intent: 'success',
                    })
                })
                .catch(() => {
                    setAlertSnackbarProps({
                        title: 'Error',
                        message: `An error occurred locking user. Please try again later.`,
                        intent: 'error',
                    })
                })
                .finally(() => {
                    setAlertSnackbarOpen(true)
                })
        } else {
            UsersApi.enableUser(user.id)
                .then(() => {
                    setLockedState(false)
                    setAlertSnackbarProps({
                        title: 'Success',
                        message: 'User has been successfully unlocked.',
                        intent: 'success',
                    })
                })
                .catch(() => {
                    setAlertSnackbarProps({
                        title: 'Error',
                        message: `An error occurred unlocking user. Please try again later.`,
                        intent: 'error',
                    })
                })
                .finally(() => {
                    setAlertSnackbarOpen(true)
                })
        }
    }, [])

    const toggleLock = (): void => {
        handleChangeStatus(user, !lockedState)
    }

    return (
        <div id={'usersToggleLockIconAction'}>
            {/* User should not be able to lock their own account. */}
            {isCurrentUser ? (
                <Tooltip
                    arrow
                    placement="top"
                    title="You cannot lock your own account"
                >
                    <div>
                        <IconButton size="small" disabled>
                            <Icon
                                className={`${
                                    iconsTheme.toggleUnlock
                                } ${'emp-disabledIcon'}`}
                                id={`user-toggle-unlock-disabled-icon-${rowIndex}`}
                                iconColor={clientTheme.icons.toggleUnlockIcon}
                            />
                        </IconButton>
                    </div>
                </Tooltip>
            ) : lockedState ? (
                <Tooltip
                    arrow
                    placement="top"
                    title="Currently Locked, Click to Unlock"
                >
                    <div>
                        <IconButton
                            onClick={toggleLock}
                            size="small"
                            disabled={isReadOnly}
                        >
                            <Icon
                                className={`${iconsTheme.toggleLock} ${
                                    isReadOnly && 'emp-disabledIcon'
                                }`}
                                id={`user-toggle-lock-icon-${rowIndex}`}
                                iconColor={clientTheme.icons.toggleLockIcon}
                            />
                        </IconButton>
                    </div>
                </Tooltip>
            ) : (
                <Tooltip
                    arrow
                    placement="top"
                    title="Currently Unlocked, Click to Lock"
                >
                    <div>
                        <IconButton
                            onClick={toggleLock}
                            size="small"
                            disabled={isReadOnly}
                        >
                            <Icon
                                className={`${iconsTheme.toggleUnlock} ${
                                    isReadOnly && 'emp-disabledIcon'
                                }`}
                                id={`user-toggle-unlock-icon-${rowIndex}`}
                                iconColor={clientTheme.icons.toggleUnlockIcon}
                            />
                        </IconButton>
                    </div>
                </Tooltip>
            )}
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </div>
    )
}

interface IMidPermissionsIconAction {
    userModel: any
    rowIndex: number
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

/**
 * @desc Icon Actions are small components that handle an action event triggered by clocking on the action icons
 * @param rowData: row data in which the action icon resides - this row data is responsible for setting any defaults when actions are opened
 **/
export const MidPermissionsIconAction: React.FC<IMidPermissionsIconAction> = ({
    userModel,
    rowIndex,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}) => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const [modalOpen, setModalOpen] = useState(false)
    const toggleModal = () => setModalOpen((prev) => !prev)

    return (
        <div id={'usersMidPermissionsIconAction'}>
            <Tooltip arrow placement="top" title="Set MID Permissions">
                <div>
                    <IconButton
                        onClick={toggleModal}
                        size="small"
                        disabled={isReadOnly}
                    >
                        <Icon
                            className={`${iconsTheme.midPermission} ${
                                isReadOnly && 'emp-disabledIcon'
                            }`}
                            id={`user-mid-permissions-icon-${rowIndex}`}
                            iconColor={clientTheme.icons.midPermissionIcon}
                        />
                    </IconButton>
                </div>
            </Tooltip>
            {modalOpen && (
                <MidPermissions
                    userModel={userModel}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                />
            )}
        </div>
    )
}

export const SitePermissionsIconAction = ({
    user,
    rowData,
    rowIndex,
}: {
    user: any
    rowData: any
    rowIndex: string
}): React.ReactElement => {
    const { user: currentUser } = useAuthedUser()
    const isReadOnly = currentUser?.is_read_only

    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const toggleModal = () => setModalOpen((prev) => !prev)

    return (
        <div id={'usersSitePermissionsIconAction'}>
            {/* Disable editing of site permissions if editing your own user or if the user
            is a CB911 Admin (will always be role.id 1). The service does not allow you
            to limit permissions to users with that role. */}
            {user.id === rowData.original.id ||
            rowData.original.role.id === 1 ? (
                <Tooltip
                    arrow
                    placement="top"
                    title="Cannot change this user's site permissions."
                >
                    <div>
                        <IconButton disabled size="small">
                            <Icon
                                className={`${
                                    iconsTheme.sitePermission
                                } ${'emp-disabledIcon '}`}
                                id={`user-site-permissions-disabled-icon-${rowIndex}`}
                                iconColor={clientTheme.icons.sitePermissionIcon}
                            />
                        </IconButton>
                    </div>
                </Tooltip>
            ) : (
                <Tooltip arrow placement="top" title="Set Site Permissions">
                    <div>
                        <IconButton
                            onClick={isReadOnly ? () => {} : toggleModal}
                            size="small"
                            disabled={isReadOnly}
                        >
                            <Icon
                                className={`${iconsTheme.sitePermission} ${
                                    isReadOnly && 'emp-disabledIcon'
                                }`}
                                id={`user-site-permissions-icon-${rowIndex}`}
                                iconColor={clientTheme.icons.sitePermissionIcon}
                            />
                        </IconButton>
                    </div>
                </Tooltip>
            )}
            {modalOpen && (
                <SitePermissions
                    rowData={rowData}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                />
            )}
        </div>
    )
}

interface ITempPasswordRequestIconActionProps {
    isCurrentUser: boolean
    user: any
    disabled?: boolean
    rowIndex: string
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export const TempPasswordRequestIconAction = ({
    isCurrentUser,
    user,
    disabled = false,
    rowIndex,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: ITempPasswordRequestIconActionProps): React.ReactElement => {
    const { user: currentUser } = useAuthedUser()
    const isReadOnly = currentUser?.is_read_only

    const [tempPasswordUser, setTempPasswordUser] = useState<string>('')
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const titleString = isCurrentUser
        ? 'You cannot send temporary password to your own account'
        : 'Send Temporary Password (disabled)'

        const openTempPasswordModal = (userName: string) => {
            setTempPasswordUser(userName)
            setModalOpen(true)
        }

    return (
        <div id={'usersTempPasswordRequestIconAction'}>
            {isCurrentUser || disabled ? (
                <Tooltip arrow placement="top" title={titleString}>
                    <div>
                        <IconButton disabled size="small">
                            <Icon
                                className={`${
                                    iconsTheme.tempPassword
                                } ${'emp-disabledIcon'}`}
                                id={`user-temp-password-disabled-icon-${rowIndex}`}
                                iconColor={clientTheme.icons.tempPasswordIcon}
                            />
                        </IconButton>
                    </div>
                </Tooltip>
            ) : (
                <Tooltip arrow placement="top" title="Send Temporary Password">
                    <div>
                        <IconButton
                            onClick={
                                isReadOnly
                                    ? () => {}
                                    : () => {
                                          openTempPasswordModal(user.username)
                                      }
                            }
                            size="small"
                            disabled={isReadOnly}
                        >
                            <Icon
                                className={`${iconsTheme.tempPassword} ${
                                    isReadOnly && 'emp-disabledIcon'
                                }`}
                                id={`user-temp-password-icon-${rowIndex}`}
                                iconColor={clientTheme.icons.tempPasswordIcon}
                            />
                        </IconButton>
                    </div>
                </Tooltip>
            )}
            {modalOpen && (
                <TempPasswordRequest
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    tempPasswordUser={tempPasswordUser}
                    setTempPasswordUser={setTempPasswordUser}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                />
            )}
        </div>
    )
}

interface LoginAsIconProps {
    loginAsUserId: number
    loginAsUsername: string
    disabled?: boolean
    rowIndex: string
}

export const LoginAsIconAction = ({
    loginAsUserId,
    loginAsUsername,
    disabled = false,
    rowIndex,
}: LoginAsIconProps): React.ReactElement => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only
    const isCurrentUser = user?.id === loginAsUserId

    const history = useHistory()
    const { setSelectedUserId, setSelectedUsername } = useAuthedUser()
    const { invalidate } = useUiStateContext()
    const {
        setMerchantSwitchToggle,
        userDefaultMerchant,
        id: selectedId,
        setActiveMerchant,
    } = useActiveMerchant()

    const handleLoginAsUser = async () => {
        if (setSelectedUserId) await setSelectedUserId(loginAsUserId)
        if (setSelectedUsername) await setSelectedUsername(loginAsUsername)

        await invalidate('whoami')

        AuthCombinedApi.authedUser().then((userInfo) => {
            // Reset merchant switcher
            if (userDefaultMerchant === selectedId) {
                setActiveMerchant({
                    name: userInfo?.merchant?.business_name ?? '',
                    id: userInfo?.merchant?.id.toString() ?? '1',
                })
            }
            setMerchantSwitchToggle(true)

            const hasDashboardAccess = userInfo?.resources.some(
                (resource) => resource.url === '/dashboard'
            )
            hasDashboardAccess
                ? history.push('/')
                : history.push(userInfo?.resources[0].url ?? '/')
        })
    }

    return (
        <div id={'usersLoginAsIconAction'}>
            <Tooltip
                arrow
                placement="top"
                title={
                    disabled || isReadOnly || isCurrentUser ? 'Login As (disabled)' : 'Login As'
                }
            >
                <span>
                    <IconButton
                        size="small"
                        disabled={disabled || isReadOnly || isCurrentUser}
                        onClick={handleLoginAsUser}
                    >
                        <FontAwesomeIcon
                            icon={faSignInAlt}
                            className={`${'emp-signInIcon'} ${
                                (disabled || isReadOnly || isCurrentUser) && 'emp-disabledIcon'
                            }`}
                            id={
                                disabled
                                    ? `user-login-as-disabled-icon-${rowIndex}`
                                    : `user-login-as-icon-${rowIndex}`
                            }
                        />
                    </IconButton>
                </span>
            </Tooltip>
        </div>
    )
}

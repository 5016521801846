import { useSWRContext } from 'context/SWRContext'

interface UseDnrModal {
    handleBulkDnr: (slectedCaseIds: number[]) => Promise<any>
    loading: boolean
}

const useDnrModal = (): UseDnrModal => {
    const { swrActions } = useSWRContext()
    const { caseNetworkActions, casesLoading } = swrActions

    const { bulkDoNotRepresent } = caseNetworkActions

    return {
        handleBulkDnr: bulkDoNotRepresent,
        loading: casesLoading,
    }
}

export default useDnrModal

import React, { useState } from 'react'
import { Tooltip, IconButton, Button } from '@mui/material'
import ViewIconActive from 'assets/icons/view-icon-active.svg'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { useRepresentment } from './useRepresentment'
import { DnrActionIcon, VerdictType, LoadingIndicator } from 'components'
import { iconsTheme } from 'theme-exports'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import Icon from 'components/Icon'
import { EditChargeback } from '../modals/EditChargeback'
import { IssuerDocs } from '../popovers/IssuerDocs'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

export interface ViewDocumentIconActionProps {
    caseId: number
    disabled?: boolean
    rowIndex: number
    testId: string
    enableDocsCompilingIcon?: boolean
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export const ViewDocumentIconAction: React.FC<ViewDocumentIconActionProps> = ({
    caseId,
    disabled = false,
    rowIndex,
    testId,
    enableDocsCompilingIcon = false,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}) => {
    const { download, isLoadingRepresentment } = useRepresentment(caseId)

    const handleDownload = () => {
        download().catch(() => {
            setAlertSnackbarMainProps({
                message: 'The document has been archived.',
                title: 'Error.',
                intent: 'error',
            })
            setAlertSnackbarMainOpen(true)
        })
    }

    return (
        <div id={'chargebacksAGViewDocumentIconAction'}>
            {enableDocsCompilingIcon || isLoadingRepresentment ? (
                <Tooltip
                    title={
                        isLoadingRepresentment
                            ? 'Downloading...'
                            : 'Doc(s) compiling...'
                    }
                    arrow
                    placement="top"
                >
                    <div>
                        <LoadingIndicator
                            iconOnly
                            className={'emp-loadingIcon'}
                        />
                    </div>
                </Tooltip>
            ) : (
                <Tooltip
                    title={disabled ? 'Document Unavailable' : 'View Document'}
                    arrow
                    placement="top"
                >
                    <div>
                        <IconButton
                            className={
                                iconsTheme.document
                                    ? 'emp-documentIconThemeBtn'
                                    : 'emp-documentIconBtn'
                            }
                            onClick={handleDownload}
                            disabled={disabled}
                        >
                            {iconsTheme.document ? (
                                <Icon
                                    className={`${'icon-document-small'} ${
                                        disabled && 'emp-disabledIcon'
                                    }`}
                                    id={`${testId}-document-icon-${rowIndex}`}
                                />
                            ) : (
                                <img
                                    className={`emp-actionIcon ${
                                        disabled && 'emp-disabledIcon'
                                    }`}
                                    src={ViewIconActive}
                                    alt="view document"
                                    id={`${testId}-document-icon-${rowIndex}`}
                                />
                            )}
                        </IconButton>
                    </div>
                </Tooltip>
            )}
        </div>
    )
}

enum Verdict {
    WillNotRepresent = 'Will Not Represent',
    DoNotRepresent = 'Do Not Represent',
    VerdictPending = 'Verdict Pending',
    Win = 'Win',
    Loss = 'Loss',
    Pending = 'Pending',
    Default = 'default',
}

interface VerdictCellProps {
    verdict: Verdict
}

export const VerdictCell = ({
    verdict,
}: VerdictCellProps): React.ReactElement => {
    return (
        <div id={'chargebacksAGVerdictCell'}>
            <VerdictType size="small" verdict={verdict} />
        </div>
    )
}

interface ActionCellProps {
    rowData: any
    rehydrateView: () => void
    testId: string
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export const ActionsCell: React.FC<ActionCellProps> = ({
    rowData,
    rehydrateView,
    testId,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}) => {
    return (
        <div id={'chargebacksAGActionsCell'} className={`ccImage`}>
            <DnrActionIcon
                row={{ id: rowData.rowIndex, original: rowData.data }}
                rehydrateView={rehydrateView}
                testId={testId}
                setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
            />
        </div>
    )
}

interface EditChargebackCellProps {
    rowData: { [key: string]: any }
    refreshGrid: () => void
    rowIndex: number
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export const EditChargebackCell = ({
    rowData,
    refreshGrid,
    rowIndex,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: EditChargebackCellProps) => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only
    const { DATA_GRID } = useFeatureToggle('CHARGEBACKS')
    const { EDIT_CHARGEBACKS_BUTTON } = DATA_GRID

    const [editCBModalOpen, setEditCBModalOpen] = useState(false)

    const disableEditBtn = EDIT_CHARGEBACKS_BUTTON.disable_status_groups.includes(
        rowData.status_group.toLowerCase()
    )

    return (
        <div id={'chargebacksAGEditChargebacksCell'}>
            <Button
                variant="contained"
                color="secondary"
                size="small"
                className={'emp-btn'}
                onClick={() => setEditCBModalOpen(true)}
                id={`chargebacks-edit-button-${rowIndex}`}
                disabled={isReadOnly || disableEditBtn}
            >
                Edit
            </Button>

            {editCBModalOpen && (
                <EditChargeback
                    openModal={editCBModalOpen}
                    onClose={() => setEditCBModalOpen(false)}
                    chargebackData={rowData}
                    refreshGrid={refreshGrid}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                    editVerdictOnly={EDIT_CHARGEBACKS_BUTTON.edit_verdict_only}
                />
            )}
        </div>
    )
}

interface IssuerDocsCellProps {
    issuerDocs: {
        date_created: string
        filename: string
        id: number
    }[]
    caseId: number
    rowIndex: number
    testId: string
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export const IssuerDocsCell = ({
    issuerDocs,
    caseId,
    rowIndex,
    testId,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: IssuerDocsCellProps) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const disableIcon = !issuerDocs.length

    const handleClickIssuerDocPopover = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    return (
        <div id={'chargebacksAGIssuerDocsCell'}>
            <Tooltip title={'Issuer Docs'} arrow placement="top">
                {iconsTheme.document ? (
                    <IconButton
                        className={'emp-documentIconThemeBtn'}
                        onClick={handleClickIssuerDocPopover}
                        disabled={disableIcon}
                    >
                        <Icon
                            className={`${'icon-document-small'} ${
                                disableIcon && 'emp-disabledIcon'
                            }`}
                            id={`${testId}-issuer-doc-icon-${rowIndex}`}
                        />
                    </IconButton>
                ) : (
                    <IconButton
                        className={'emp-documentIconBtn'}
                        onClick={handleClickIssuerDocPopover}
                    >
                        <img
                            className={`${'emp-actionIcon'} ${
                                disableIcon && 'emp-disabledIcon'
                            }`}
                            src={ViewIconActive}
                            alt="view issuer doc"
                            id={`${testId}-issuer-doc-icon-${rowIndex}`}
                        />
                    </IconButton>
                )}
            </Tooltip>
            <IssuerDocs
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                issuerDocs={issuerDocs}
                caseId={caseId}
                rowIndex={rowIndex}
                testId={testId}
                setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
            />
        </div>
    )
}

import palette from './palette'
import { clientTheme } from 'theme-exports'

export default {
    fontFamily: clientTheme.typography.fontFamily.join(','),
    h1: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: clientTheme.typography.h1.fontSize,
        letterSpacing: '-0.24px',
        lineHeight: '40px',
    },
    h2: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: clientTheme.typography.h2.fontSize,
        letterSpacing: '-0.24px',
        lineHeight: '32px',
    },
    h3: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: clientTheme.typography.h3.fontSize,
        letterSpacing: '-0.06px',
        lineHeight: '28px',
    },
    h4: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: clientTheme.typography.h4.fontSize,
        letterSpacing: '-0.06px',
        lineHeight: '24px',
    },
    h5: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: clientTheme.typography.h5.fontSize,
        letterSpacing: '-0.05px',
        lineHeight: '20px',
    },
    h6: {
        color: palette.text.primary,
        fontWeight: 500,
        fontSize: clientTheme.typography.h6.fontSize,
        letterSpacing: '-0.05px',
        lineHeight: '20px',
    },
    subtitle1: {
        color: palette.text.primary,
        fontSize: clientTheme.typography.subtitle1.fontSize,
        letterSpacing: '-0.05px',
        lineHeight: '25px',
    },
    subtitle2: {
        color: palette.text.secondary,
        fontWeight: 400,
        fontSize: clientTheme.typography.subtitle2.fontSize,
        letterSpacing: '-0.05px',
        lineHeight: '21px',
    },
    body1: {
        color: palette.text.primary,
        fontSize: clientTheme.typography.body1.fontSize,
        letterSpacing: '-0.05px',
        lineHeight: '21px',
    },
    body2: {
        color: palette.text.secondary,
        fontSize: clientTheme.typography.body2.fontSize,
        letterSpacing: '-0.04px',
        lineHeight: '18px',
    },
    button: {
        color: palette.text.primary,
        fontSize: clientTheme.typography.button1.fontSize,
    },
    caption: {
        color: palette.text.secondary,
        fontSize: clientTheme.typography.caption.fontSize,
        letterSpacing: '0.33px',
        lineHeight: '13px',
    },
    overline: {
        color: palette.text.secondary,
        fontSize: clientTheme.typography.overline.fontSize,
        fontWeight: 500,
        letterSpacing: '0.33px',
        lineHeight: '13px',
        // textTransform: 'uppercase'
    }
}

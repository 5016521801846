// We're missing some properties we need from the endpoints involved with case builder,
// so we'll (unfortunately) need to add those here. 

interface caseBuilderFieldMapType {
    [key: number]: {
        name: string,
        isFullWidth: boolean
    }
}

export const caseBuilderFieldMap: caseBuilderFieldMapType = {
    1: {
        name: 'loc_address',
        isFullWidth: true,
    },
    2: {
        name: 'term_id',
        isFullWidth: false,
    },
    3: {
        name: 'auth_num',
        isFullWidth: false,
    },
    4: {
        name: 'avs',
        isFullWidth: false,
    },
    5: {
        name: 'b_address1',
        isFullWidth: true,
    },
    6: {
        name: 'b_country_id',
        isFullWidth: false,
    },
    7: {
        name: 'b_zip',
        isFullWidth: false,
    },
    8: {
        name: 'cc_exp',
        isFullWidth: false,
    },
    9: {
        name: 'cs_notes',
        isFullWidth: true,
    },
    10: {
        name: 'email',
        isFullWidth: false,
    },
    11: {
        name: 'fname',
        isFullWidth: false,
    },
    12: {
        name: 'lname',
        isFullWidth: false,
    },
    13: {
        name: 'customer_num',
        isFullWidth: false,
    },
    14: {
        name: 'order_id',
        isFullWidth: false,
    },
    15: {
        name: 'phone',
        isFullWidth: false,
    },
    16: {
        name: 'profile_chat_logs',
        isFullWidth: true,
    },
    17: {
        name: 'profile_login_name',
        isFullWidth: false,
    },
    18: {
        name: 's_address1',
        isFullWidth: true,
    },
    19: {
        name: 's_country_id',
        isFullWidth: false,
    },
    20: {
        name: 's_zip',
        isFullWidth: false,
    },
    21: {
        name: 'social_media_acc_name',
        isFullWidth: false,
    },
    22: {
        name: 'trans_id',
        isFullWidth: false,
    },
    23: {
        name: 'vbv_code',
        isFullWidth: false,
    },
    24: {
        name: 'digital_download_num',
        isFullWidth: false,
    },
    25: {
        name: 'last_use_date',
        isFullWidth: false,
    },
    26: {
        name: 'orig_use_date',
        isFullWidth: false,
    },
    27: {
        name: 'vox_sign',
        isFullWidth: false,
    },
    28: {
        name: 'has_rma',
        isFullWidth: false,
    },
    29: {
        name: 'tracking_number',
        isFullWidth: false,
    },
    30: {
        name: 'cancel_date',
        isFullWidth: false,
    },
    31: {
        name: 'date_first_trans',
        isFullWidth: false,
    },
    32: {
        name: 'sub_id',
        isFullWidth: false,
    },
    33: {
        name: 'acc_login_id',
        isFullWidth: false,
    },
    34: {
        name: 'service_date',
        isFullWidth: false,
    },
    35: {
        name: 'confirm_num',
        isFullWidth: false,
    },
    36: {
        name: 'event_date',
        isFullWidth: false,
    },
    37: {
        name: 'manifest',
        isFullWidth: true,
    },
    38: {
        name: 'name_ticket',
        isFullWidth: false,
    },
    39: {
        name: 'ticket_itin_num',
        isFullWidth: false,
    },
    40: {
        name: 'device_id',
        isFullWidth: false,
    },
    41: {
        name: 'device_name',
        isFullWidth: false,
    },
    42: {
        name: 'ip_address',
        isFullWidth: false,
    },
    43: {
        name: 'ip_location',
        isFullWidth: false,
    },
    47: {
        name: 'descriptor',
        isFullWidth: false,
    },
    48: {
        name: 'case_no',
        isFullWidth: false,
    },
    49: {
        name: 'reason_code_id',
        isFullWidth: false,
    },
    50: {
        name: 'date_post',
        isFullWidth: false,
    },
    51: {
        name: 'date_trans',
        isFullWidth: false,
    },
    52: {
        name: 'date_due',
        isFullWidth: false,
    },
    53: {
        name: 'amount',
        isFullWidth: false,
    },
    54: {
        name: 'case_amt',
        isFullWidth: false,
    },
    55: {
        name: 'currency_id',
        isFullWidth: false,
    },
    58: {
        name: 'cc_type',
        isFullWidth: false,
    },
    59: {
        name: 'cc_no',
        isFullWidth: false,
    },
    60: {
        name: 'cc_last_four',
        isFullWidth: false,
    },
    65: {
        name: 's_city',
        isFullWidth: false,
    },
    66: {
        name: 's_state',
        isFullWidth: false,
    },
    70: {
        name: 'b_city',
        isFullWidth: false,
    },
    71: {
        name: 'b_state',
        isFullWidth: false,
    },
    78: {
        name: 'name',
        isFullWidth: false,
    },
    79: {
        name: 'description',
        isFullWidth: false,
    },
    80: {
        name: 'customer_type_id',
        isFullWidth: false,
    },
    81: {
        name: 'offer_type_id',
        isFullWidth: false,
    },
    82: {
        name: 'is_refund',
        isFullWidth: false,
    },
    83: {
        name: 'crm_amt',
        isFullWidth: false,
    },
    84: {
        name: 'crm_currency_id',
        isFullWidth: false,
    },
    85: {
        name: 'is_name_in_email',
        isFullWidth: false,
    },
    87: {
        name: 'is_mailed',
        isFullWidth: false,
    },
    89: {
        name: 'ship_carrier',
        isFullWidth: false,
    },
    90: {
        name: 'first_order_date',
        isFullWidth: false,
    },
    91: {
        name: 'approved_orders_no',
        isFullWidth: false,
    },
    93: {
        name: 'customer_contact_no',
        isFullWidth: false,
    },
    94: {
        name: 'case_history',
        isFullWidth: true,
    },
    95: {
        name: 'business_name',
        isFullWidth: false,
    },
    96: {
        name: 'business_phone',
        isFullWidth: false,
    },
    97: {
        name: 'business_address',
        isFullWidth: true,
    },
    98: {
        name: 'fullname',
        isFullWidth: false,
    },
    99: {
        name: 'account_creation_date',
        isFullWidth: false,
    },
    100: {
        name: 'trav_sales_agent',
        isFullWidth: false,
    },
    101: {
        name: 'tick_event_name',
        isFullWidth: false,
    },
    102: {
        name: 'tick_delivery_method',
        isFullWidth: false,
    },
    103: {
        name: 'tick_redemption_code',
        isFullWidth: false,
    },
    104: {
        name: 'tick_sales_agent',
        isFullWidth: false,
    },
    105: {
        name: 'tick_redemption_status',
        isFullWidth: false,
    },
    106: {
        name: 'tick_event_date',
        isFullWidth: false,
    },
    107: {
        name: 's_fname',
        isFullWidth: false,
    },
    108: {
        name: 's_lname',
        isFullWidth: false,
    },
    109: {
        name: 'b_fname',
        isFullWidth: false,
    },
    110: {
        name: 'b_lname',
        isFullWidth: false,
    },
    111: {
        name: 'sale_method',
        isFullWidth: false,
    },
    112: {
        name: 'is_credited',
        isFullWidth: false,
    },
    113: {
        name: 'date_credited',
        isFullWidth: false,
    },
    114: {
        name: 'entry_notes',
        isFullWidth: false,
    },
    115: {
        name: 'type_id',
        isFullWidth: false,
    },
    116: {
        name: 'category_id',
        isFullWidth: false,
    },
    117: {
        name: 'country_id',
        isFullWidth: false,
    },
}

export default caseBuilderFieldMap

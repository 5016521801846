/**
 * @description Directory for ID Attributes on Upload Representments New.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `uploadRepresentmentsVC3-div-root`,
    divLoading: `uploadRepresentmentsVC3-div-loading`,
    divError: `uploadRepresentmentsVC3-div-error`,
    divErrorMsg: `uploadRepresentmentsVC3-div-errorMsg`,
    btnBack: `uploadRepresentmentsVC3-btn-back`,
    divMain: `uploadRepresentmentsVC3-div-main`,
    divUpload: `uploadRepresentmentsVC3-div-upload`,
    divCaseComment: `uploadRepresentmentsVC3-div-caseComment`,
    divQCReview: `uploadRepresentmentsVC3-div-qcReview`,
    btnUpload: `uploadRepresentmentsVC3-btn-upload`,
    divCE: `uploadRepresentmentsVC3-div-CE`,
}

export default idDirectory

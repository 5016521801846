import React, { useEffect, useState } from 'react'
import { Tooltip, useTheme, Box, Skeleton, Alert } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clientTheme } from 'theme-exports'
import Icon from 'components/Icon'
import idDirectory from './idAttributes'

export interface IPaginationAlertContent {
    enable: boolean
    message: string
    info?: string | React.ReactNode
    intent?: 'error' | 'info' | 'success' | 'warning'
}

export interface IPaginationAGGridProps {
    pageCount: number
    pageSize: number
    totalEntries: number
    setStart?: (val: number) => void
    currentPage: number
    setCurrentPage: (page: number) => void
    testId: string
    paginationOptions?: number[]
    onPageLimitChange?: (size: number) => void
    loading?: boolean
    skeletonWidth?: string
    enablePageLimitOptions?: boolean
    enablePaginationPageChange?: boolean
    enablePaginationRefreshBtn?: boolean
    handlePaginationRefreshBtn?: () => void
    enablePaginationDisplayingText?: boolean
    enableBasicThemePagination?: boolean
    enablePaginationBorder?: boolean
    paginationAlertContent?: IPaginationAlertContent
    disablePaginationBtns?: boolean
}

/**
 * Pagination is used to paginate data AG Grid server side
 */
const PaginationAGGrid = ({
    pageCount,
    setStart,
    pageSize,
    totalEntries,
    currentPage,
    setCurrentPage,
    testId,
    paginationOptions = [10, 25, 50, 100],
    onPageLimitChange = () => {},
    loading = false,
    skeletonWidth = '10px',
    enablePageLimitOptions = false,
    enablePaginationPageChange = true,
    enablePaginationRefreshBtn = true,
    handlePaginationRefreshBtn = () => {},
    enablePaginationDisplayingText = true,
    enableBasicThemePagination = false,
    enablePaginationBorder = true,
    paginationAlertContent = {
        enable: false,
        message: '',
        info: '',
        intent: 'info',
    },
    disablePaginationBtns = false,
}: IPaginationAGGridProps) => {
    const enablePaginationAlert = paginationAlertContent.enable
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up(1460))

    const tooltipDelayTime = 2_000

    const [inputPage, setInputPage] = useState(String(currentPage))

    const showingStartValue = pageSize * currentPage - (pageSize - 1)
    const showingEndValue =
        pageSize * currentPage <= totalEntries
            ? pageSize * currentPage
            : totalEntries

    const handlePageChange = (move: string) => {
        if (!pageCount) return
        if (move === 'previous' && currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
        if (move === 'next' && currentPage !== pageCount) {
            setCurrentPage(currentPage + 1)
        }
    }

    useEffect(() => {
        const start = pageSize * (currentPage - 1)
        setStart && setStart(start)
        // eslint-disable-next-line
    }, [currentPage])

    const handleInputPageChange = (e: any) => {
        const value = Number(e.target.value)
        if (value <= pageCount) {
            setCurrentPage(value)
        } else {
            setInputPage(String(currentPage))
        }
    }

    useEffect(() => {
        setInputPage(String(currentPage))
    }, [currentPage, pageSize])

    return enableBasicThemePagination ? (
        <div
            className={`${'emp-paginationAGGrid-rootBasicTheme'} ${
                enablePaginationBorder &&
                'emp-paginationAGGrid-paginationBorder'
            }`}
            style={{
                fontSize: clientTheme.typography.body1.fontSize,
                color: clientTheme.typography.fontColor.primaryText
                    ? clientTheme.typography.fontColor.primaryText
                    : '#181D1F',
            }}
            id={`${idDirectory.divRoot}-${testId}`}
        >
            <div
                className={'emp-paginationAGGrid-pageInfoBasicThemeContainer'}
                id={`${idDirectory.divPageInfo}-${testId}`}
            >
                <div
                    className={
                        'emp-paginationAGGrid-pageResultsBasicThemeDisplay'
                    }
                    id={`${idDirectory.divPageResults}-${testId}`}
                >
                    <span className={'emp-paginationAGGrid-pageResultsText'}>
                        {loading ? (
                            <Skeleton variant="text" width={skeletonWidth} />
                        ) : (
                            showingStartValue.toLocaleString('en-US')
                        )}
                    </span>
                    -
                    <span className={'emp-paginationAGGrid-pageResultsText'}>
                        {loading ? (
                            <Skeleton variant="text" width={skeletonWidth} />
                        ) : (
                            showingEndValue.toLocaleString('en-US')
                        )}
                    </span>
                    of
                    <span
                        className={'emp-paginationAGGrid-pageResultsTextLast'}
                    >
                        {!loading ? (
                            totalEntries.toLocaleString('en-US')
                        ) : (
                            <Skeleton variant="text" width={skeletonWidth} />
                        )}
                    </span>
                </div>
            </div>
            {enablePaginationPageChange && (
                <div
                    className={
                        'emp-paginationAGGrid-pageNavBasicThemeContainer'
                    }
                    id={`${idDirectory.divPageNav}-${testId}`}
                >
                    <div
                        className={
                            'emp-paginationAGGrid-pageNavBackwardControls'
                        }
                        id={`${idDirectory.divPageNavBackward}-${testId}`}
                    >
                        <Tooltip
                            arrow
                            placement="top"
                            title={'Previous Page'}
                            enterNextDelay={tooltipDelayTime}
                            enterDelay={tooltipDelayTime}
                        >
                            <div
                                className={
                                    'emp-paginationAGGrid-tooltipContainer'
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faAngleLeft}
                                    className={
                                        currentPage === 1 ||
                                        !currentPage ||
                                        disablePaginationBtns
                                            ? 'emp-paginationAGGrid-iconInactive'
                                            : 'emp-paginationAGGrid-iconActive'
                                    }
                                    style={{
                                        color: clientTheme.typography.fontColor
                                            .primaryText
                                            ? clientTheme.typography.fontColor
                                                  .primaryText
                                            : '#181D1F',
                                    }}
                                    onClick={
                                        disablePaginationBtns
                                            ? () => {}
                                            : () => handlePageChange('previous')
                                    }
                                    id={`${idDirectory.btnPrevious}-${testId}`}
                                />
                            </div>
                        </Tooltip>
                    </div>
                    <div
                        className={
                            'emp-paginationAGGrid-pageNavDisplayContainer'
                        }
                        id={`${idDirectory.divPageNavDisplay}-${testId}`}
                    >
                        Page{' '}
                        <Tooltip
                            arrow
                            placement="top"
                            title={`Page ${currentPage}`}
                            enterNextDelay={tooltipDelayTime}
                            enterDelay={tooltipDelayTime}
                        >
                            <div>
                                <input
                                    className={'emp-paginationAGGrid-pageInput'}
                                    onChange={(e) => {
                                        const value = e.target.value
                                        // checking to make sure only number are types in page input box
                                        if (Number(value) || value === '')
                                            setInputPage(e.target.value)
                                    }}
                                    onBlur={(e) =>
                                        inputPage.length
                                            ? handleInputPageChange(e)
                                            : setInputPage(String(currentPage))
                                    }
                                    onKeyPress={(e) => {
                                        if (e.key.toLowerCase() === 'enter') {
                                            inputPage.length
                                                ? handleInputPageChange(e)
                                                : setInputPage(
                                                      String(currentPage)
                                                  )
                                        }
                                    }}
                                    value={inputPage}
                                    disabled={disablePaginationBtns}
                                    style={{
                                        width: `${
                                            20 +
                                            (inputPage.length > 2
                                                ? inputPage.length * 7
                                                : 5)
                                        }px`,
                                    }}
                                />
                            </div>
                        </Tooltip>
                        of{' '}
                        <span
                            style={{ marginLeft: 6 }}
                            className={'emp-paginationAGGrid-boldText'}
                        >
                            {loading ? (
                                <Skeleton
                                    variant="text"
                                    width={skeletonWidth}
                                />
                            ) : (
                                pageCount.toLocaleString('en-US')
                            )}
                        </span>
                    </div>
                    <div
                        className={`${'emp-paginationAGGrid-pageNavForwardControls'} ${
                            !enablePaginationRefreshBtn &&
                            'emp-paginationAGGrid-pageNavForwardControlsNoRefreshBtn'
                        }`}
                        id={`${idDirectory.divPageNavForward}-${testId}`}
                    >
                        <Tooltip
                            arrow
                            placement="top"
                            title={'Next Page'}
                            enterNextDelay={tooltipDelayTime}
                            enterDelay={tooltipDelayTime}
                        >
                            <div
                                className={
                                    'emp-paginationAGGrid-tooltipContainer'
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faAngleRight}
                                    className={
                                        currentPage === pageCount ||
                                        !pageCount ||
                                        disablePaginationBtns
                                            ? 'emp-paginationAGGrid-iconInactive'
                                            : 'emp-paginationAGGrid-iconActive'
                                    }
                                    style={{
                                        color: clientTheme.typography.fontColor
                                            .primaryText
                                            ? clientTheme.typography.fontColor
                                                  .primaryText
                                            : '#181D1F',
                                    }}
                                    onClick={
                                        disablePaginationBtns
                                            ? () => {}
                                            : () => handlePageChange('next')
                                    }
                                    id={`${idDirectory.btnNext}-${testId}`}
                                />
                            </div>
                        </Tooltip>
                    </div>
                </div>
            )}
        </div>
    ) : (
        <Box
            className={`${'emp-paginationAGGrid-root'} ${
                enablePaginationBorder &&
                'emp-paginationAGGrid-paginationBorder'
            }`}
            id={`${idDirectory.divRoot}-${testId}`}
            sx={(theme) => ({
                fontSize: clientTheme.typography.body1.fontSize,
                color: clientTheme.typography.fontColor.primaryText
                    ? clientTheme.typography.fontColor.primaryText
                    : '#181D1F',
                justifyContent: 'space-between',
                height: '50px',
                margin: 0,
                [theme.breakpoints.down(700)]: {
                    justifyContent:
                        enablePageLimitOptions && enablePaginationPageChange
                            ? 'center'
                            : 'space-between',
                    height: enablePageLimitOptions ? '65px' : '50px',
                },
                [theme.breakpoints.down(525)]: {
                    justifyContent:
                        enablePaginationAlert ||
                        (enablePageLimitOptions && enablePaginationPageChange)
                            ? 'center'
                            : 'space-between',
                    height: enablePaginationAlert
                        ? '80px'
                        : enablePageLimitOptions
                        ? '65px'
                        : '50px',
                    marginBottom: enablePaginationAlert ? '10px !important' : 0,
                },
            })}
        >
            <div
                className={'emp-paginationAGGrid-pageInfoContainer'}
                id={`${idDirectory.divPageInfo}-${testId}`}
            >
                <div
                    className={'emp-paginationAGGrid-pageResultsDisplay'}
                    id={`${idDirectory.divPageResults}-${testId}`}
                >
                    {enablePaginationDisplayingText && 'Displaying'}
                    <span
                        className={
                            enablePaginationDisplayingText
                                ? 'emp-paginationAGGrid-pageResultsText'
                                : 'emp-paginationAGGrid-pageResultsTextWithoutDisplayingText'
                        }
                    >
                        {loading ? (
                            <Skeleton variant="text" width={skeletonWidth} />
                        ) : (
                            showingStartValue.toLocaleString('en-US')
                        )}
                    </span>
                    -
                    <span className={'emp-paginationAGGrid-pageResultsText'}>
                        {loading ? (
                            <Skeleton variant="text" width={skeletonWidth} />
                        ) : (
                            showingEndValue.toLocaleString('en-US')
                        )}
                    </span>
                    of
                    <span
                        className={'emp-paginationAGGrid-pageResultsTextLast'}
                    >
                        {!loading ? (
                            totalEntries.toLocaleString('en-US')
                        ) : (
                            <Skeleton variant="text" width={skeletonWidth} />
                        )}
                    </span>
                </div>
                {enablePageLimitOptions && (
                    <div
                        className={'emp-paginationAGGrid-pageSizeDisplay'}
                        style={{
                            fontSize: clientTheme.typography.body1.fontSize,
                        }}
                        id={`${idDirectory.divPageSize}-${testId}`}
                    >
                        Page Size
                        <select
                            value={pageSize}
                            onChange={(
                                e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                                onPageLimitChange(Number(e.target.value))
                            }}
                            disabled={disablePaginationBtns}
                            style={{ width: pageSize > 999 ? '60px' : '53px' }}
                        >
                            {paginationOptions.map((option: number) => {
                                return (
                                    <option
                                        key={`paginate-${option}`}
                                        value={option}
                                    >
                                        {option}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                )}
            </div>
            {paginationAlertContent.enable && (
                <Box
                    className={'emp-paginationAGGrid-paginationAlertContainer'}
                    sx={{
                        '& .MuiAlert-root': {
                            fontSize: clientTheme.typography.body1.fontSize,
                            fontFamily: 'inherit',
                        },
                    }}
                >
                    {isLargeScreen ? (
                        <Alert severity={paginationAlertContent.intent}>
                            <div style={{ display: 'flex' }}>
                                <div> {paginationAlertContent.message}</div>
                                {paginationAlertContent.info && (
                                    <Tooltip
                                        arrow
                                        placement="top"
                                        title={paginationAlertContent.info}
                                    >
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faInfoCircle}
                                                style={{ marginLeft: '4px' }}
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </Alert>
                    ) : (
                        <Tooltip
                            arrow
                            placement="top"
                            title={paginationAlertContent.message}
                        >
                            <Alert severity={paginationAlertContent.intent}>
                                {''}
                            </Alert>
                        </Tooltip>
                    )}
                </Box>
            )}
            <div
                className={'emp-paginationAGGrid-pageNavContainer'}
                id={`${idDirectory.divPageNav}-${testId}`}
            >
                {enablePaginationPageChange && (
                    <>
                        <div
                            className={
                                'emp-paginationAGGrid-pageNavBackwardControls'
                            }
                            id={`${idDirectory.divPageNavBackward}-${testId}`}
                        >
                            <Tooltip
                                arrow
                                placement="top"
                                title={'First Page'}
                                enterNextDelay={tooltipDelayTime}
                                enterDelay={tooltipDelayTime}
                            >
                                <div
                                    className={
                                        'emp-paginationAGGrid-tooltipContainer'
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faAngleDoubleLeft}
                                        className={
                                            currentPage === 1 ||
                                            !currentPage ||
                                            disablePaginationBtns
                                                ? 'emp-paginationAGGrid-iconInactive'
                                                : 'emp-paginationAGGrid-iconActive'
                                        }
                                        style={{
                                            color: clientTheme.typography
                                                .fontColor.primaryText
                                                ? clientTheme.typography
                                                      .fontColor.primaryText
                                                : '#181D1F',
                                        }}
                                        onClick={
                                            disablePaginationBtns
                                                ? () => {}
                                                : () => setCurrentPage(1)
                                        }
                                        id={`${idDirectory.btnFirst}-${testId}`}
                                    />
                                </div>
                            </Tooltip>
                            <Tooltip
                                arrow
                                placement="top"
                                title={'Previous Page'}
                                enterNextDelay={tooltipDelayTime}
                                enterDelay={tooltipDelayTime}
                            >
                                <div
                                    className={
                                        'emp-paginationAGGrid-tooltipContainer'
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faAngleLeft}
                                        className={
                                            currentPage === 1 ||
                                            !currentPage ||
                                            disablePaginationBtns
                                                ? 'emp-paginationAGGrid-iconInactive'
                                                : 'emp-paginationAGGrid-iconActive'
                                        }
                                        style={{
                                            color: clientTheme.typography
                                                .fontColor.primaryText
                                                ? clientTheme.typography
                                                      .fontColor.primaryText
                                                : '#181D1F',
                                        }}
                                        onClick={
                                            disablePaginationBtns
                                                ? () => {}
                                                : () =>
                                                      handlePageChange(
                                                          'previous'
                                                      )
                                        }
                                        id={`${idDirectory.btnPrevious}-${testId}`}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                        <div
                            className={
                                'emp-paginationAGGrid-pageNavDisplayContainer'
                            }
                            id={`${idDirectory.divPageNavDisplay}-${testId}`}
                        >
                            Page{' '}
                            <Tooltip
                                arrow
                                placement="top"
                                title={`Page ${currentPage}`}
                                enterNextDelay={tooltipDelayTime}
                                enterDelay={tooltipDelayTime}
                            >
                                <div>
                                    <input
                                        className={
                                            'emp-paginationAGGrid-pageInput'
                                        }
                                        onChange={(e) => {
                                            const value = e.target.value
                                            // checking to make sure only number are types in page input box
                                            if (Number(value) || value === '')
                                                setInputPage(e.target.value)
                                        }}
                                        onBlur={(e) =>
                                            inputPage.length
                                                ? handleInputPageChange(e)
                                                : setInputPage(
                                                      String(currentPage)
                                                  )
                                        }
                                        onKeyPress={(e) => {
                                            if (
                                                e.key.toLowerCase() === 'enter'
                                            ) {
                                                inputPage.length
                                                    ? handleInputPageChange(e)
                                                    : setInputPage(
                                                          String(currentPage)
                                                      )
                                            }
                                        }}
                                        value={inputPage}
                                        disabled={disablePaginationBtns}
                                        style={{
                                            width: `${
                                                20 +
                                                (inputPage.length > 2
                                                    ? inputPage.length * 7
                                                    : 5)
                                            }px`,
                                        }}
                                    />
                                </div>
                            </Tooltip>
                            of{' '}
                            <span
                                style={{ marginLeft: 6 }}
                                className={'emp-paginationAGGrid-boldText'}
                            >
                                {loading ? (
                                    <Skeleton
                                        variant="text"
                                        width={skeletonWidth}
                                    />
                                ) : (
                                    pageCount.toLocaleString('en-US')
                                )}
                            </span>
                        </div>
                        <div
                            className={`${'emp-paginationAGGrid-pageNavForwardControls'} ${
                                !enablePaginationRefreshBtn &&
                                'emp-paginationAGGrid-pageNavForwardControlsNoRefreshBtn'
                            }`}
                            id={`${idDirectory.divPageNavForward}-${testId}`}
                        >
                            <Tooltip
                                arrow
                                placement="top"
                                title={'Next Page'}
                                enterNextDelay={tooltipDelayTime}
                                enterDelay={tooltipDelayTime}
                            >
                                <div
                                    className={
                                        'emp-paginationAGGrid-tooltipContainer'
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faAngleRight}
                                        className={
                                            currentPage === pageCount ||
                                            !pageCount ||
                                            disablePaginationBtns
                                                ? 'emp-paginationAGGrid-iconInactive'
                                                : 'emp-paginationAGGrid-iconActive'
                                        }
                                        style={{
                                            color: clientTheme.typography
                                                .fontColor.primaryText
                                                ? clientTheme.typography
                                                      .fontColor.primaryText
                                                : '#181D1F',
                                        }}
                                        onClick={
                                            disablePaginationBtns
                                                ? () => {}
                                                : () => handlePageChange('next')
                                        }
                                        id={`${idDirectory.btnNext}-${testId}`}
                                    />
                                </div>
                            </Tooltip>
                            <Tooltip
                                arrow
                                placement="top"
                                title={'Last Page'}
                                enterNextDelay={tooltipDelayTime}
                                enterDelay={tooltipDelayTime}
                            >
                                <div
                                    className={
                                        'emp-paginationAGGrid-tooltipContainer'
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faAngleDoubleRight}
                                        className={
                                            currentPage === pageCount ||
                                            !pageCount ||
                                            disablePaginationBtns
                                                ? 'emp-paginationAGGrid-iconInactive'
                                                : 'emp-paginationAGGrid-iconActive'
                                        }
                                        style={{
                                            color: clientTheme.typography
                                                .fontColor.primaryText
                                                ? clientTheme.typography
                                                      .fontColor.primaryText
                                                : '#181D1F',
                                        }}
                                        onClick={
                                            disablePaginationBtns
                                                ? () => {}
                                                : () => {
                                                      if (!pageCount) return
                                                      setCurrentPage(pageCount)
                                                  }
                                        }
                                        id={`${idDirectory.btnLast}-${testId}`}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    </>
                )}
                {enablePaginationRefreshBtn && (
                    <div
                        className={
                            'emp-paginationAGGrid-pageNavRefreshContainer'
                        }
                        id={`${idDirectory.divPageNavRefresh}-${testId}`}
                    >
                        <Tooltip
                            arrow
                            placement="top"
                            title={'Refresh'}
                            enterNextDelay={tooltipDelayTime}
                            enterDelay={tooltipDelayTime}
                        >
                            <div>
                                <Icon
                                    className={`fas fa-circle-arrow ${
                                        loading || disablePaginationBtns
                                            ? 'emp-paginationAGGrid-iconInactive'
                                            : 'emp-paginationAGGrid-iconActive'
                                    } ${loading && 'fa-spin'}`}
                                    iconColor={
                                        clientTheme.typography.fontColor
                                            .primaryText
                                            ? clientTheme.typography.fontColor
                                                  .primaryText
                                            : '#181D1F'
                                    }
                                    onClick={
                                        loading || disablePaginationBtns
                                            ? () => {}
                                            : () => handlePaginationRefreshBtn()
                                    }
                                    id={`${idDirectory.btnRefresh}-${testId}`}
                                />
                            </div>
                        </Tooltip>
                    </div>
                )}
            </div>
        </Box>
    )
}

export default PaginationAGGrid

import { useState } from 'react'
import axios from 'axios'
import { useSWRActions, useSwrData } from 'hooks/cb-api'
import useParams from 'hooks/cb-api/useParams'
import { useHistory } from 'react-router'
import useIsMounted from 'hooks/utils/useIsMounted'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

export interface IPagination {
    start: number
    limit: number
    total: number
}

interface EditOnboardingRow {
    [key: string]: {
        value: number | string
        err_messages: string[]
    }
}
interface EditOnboardingColumns {
    accessor: string
    Header: string
}

interface EditOnboarding {
    title: string
    date_created: string
    date_updated: string
    uploaded_by: { id: number; username: string }
    is_valid: boolean
    total_failing_validation: number
}

interface Records {
    id: number
    [key: string]: string | number
}

interface EditOnboardingVM {
    uploadData:
        | EditOnboarding
        | {
              title: string
              is_valid: boolean
              total_failing_validation: number | undefined
          }
    pagination: IPagination | {}
    rows: EditOnboardingRow[]
    columns: EditOnboardingColumns[]
    isLoadingData: boolean
    isLoadingRequest: boolean
    updateRecords: (id: number, update: Records | {}) => void
    finalizeOnboarding: (id: number) => void
    deleteOrUndoDeleteRecord: (id: number, status: number) => void
    alertSnackbarMainProps: alertSnackbarContentProps
    alertSnackbarMainOpen: boolean
    setAlertSnackbarMainOpen: (status: boolean) => void
}

const useEditOnBoarding = (onboardingId = null): EditOnboardingVM => {
    const history = useHistory()
    const { isMounted } = useIsMounted()

    const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false)
    const [alertSnackbarMainOpen, setAlertSnackbarMainOpen] = useState<boolean>(
        false
    )
    const [
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
    ] = useState<alertSnackbarContentProps>({})

    const { params, setQueryParams, setParams, reset } = useParams({
        limit: 999,
    })

    const endpointConfig = {
        paramInUrl: false,
        url: `/cm/onboarding/${onboardingId}/records`,
        id: {
            value: 'onboardingEdit',
        },
    }

    const onboardingEditData = useSwrData(endpointConfig, params)
    const swrActions = useSWRActions(onboardingEditData, {
        setParams,
        setQueryParams,
        params,
        reset,
    })
    const { invalidate } = swrActions

    const updateRecords = (id: number, update: Records | {}) => {
        setIsLoadingRequest(true)
        axios
            .patch(`/cm/onboarding/${id}/records`, { records: [update] })
            .then((res) => {
                if (isMounted.current)
                    setAlertSnackbarMainProps({
                        title: 'Success',
                        message: 'Successfully updated record.',
                        intent: 'success',
                    })
            })
            .catch((e) =>
                setAlertSnackbarMainProps({
                    message: 'There was an error, try again.',
                    title: 'Error.',
                    intent: 'error',
                })
            )
            .finally(() => {
                if (isMounted.current) {
                    setIsLoadingRequest(false)
                    setAlertSnackbarMainOpen(true)
                    invalidate()
                }
            })
    }

    const deleteOrUndoDeleteRecord = (id: number, status: Records | {}) => {
        axios
            .patch(`/cm/onboarding/${id}/records`, { records: [status] })
            .then((res) => {
                if (isMounted.current)
                    setAlertSnackbarMainProps({
                        title: 'Success',
                        message: 'Your action was a success!',
                        intent: 'success',
                    })
            })
            .catch((e) =>
                setAlertSnackbarMainProps({
                    message: 'There was an error, try again.',
                    title: 'Error.',
                    intent: 'error',
                })
            )
            .finally(() => {
                if (isMounted.current) {
                    setAlertSnackbarMainOpen(true)
                    invalidate()
                }
            })
    }

    const finalizeOnboarding = (id: number) => {
        setIsLoadingRequest(true)
        axios
            .patch(`/cm/onboarding/${id}`, {
                status_id: 3,
            })
            .then((res) => {
                if (isMounted.current)
                    setAlertSnackbarMainProps({
                        title: 'Success',
                        message: 'Your action was a success!',
                        intent: 'success',
                    })
                history.push('/onboarding')
            })
            .catch((e) =>
                setAlertSnackbarMainProps({
                    message: 'There was an error, try again.',
                    title: 'Error.',
                    intent: 'error',
                })
            )
            .finally(() => {
                if (isMounted.current) {
                    setIsLoadingRequest(false)
                    setAlertSnackbarMainOpen(true)
                }
            })
    }

    const refactorColumns = onboardingEditData?.payload?.columns.map(
        (col: any) => {
            return {
                accessor: col.label,
                Header: col.name,
            }
        }
    )

    return {
        uploadData: {
            title: onboardingEditData?.payload?.title,
            date_created: onboardingEditData?.payload?.date_created,
            date_updated: onboardingEditData?.payload?.date_updated,
            uploaded_by: onboardingEditData?.payload?.uploaded_by,
            is_valid: Boolean(
                !onboardingEditData?.payload?.total_failing_validation
            ),
            total_failing_validation:
                onboardingEditData?.payload?.total_failing_validation,
        } ?? {
            title: '',
            is_valid: false,
            total_failing_validation: undefined,
        },
        rows: onboardingEditData.data ?? [],
        columns: refactorColumns ?? [],
        isLoadingData: Boolean(!onboardingEditData.data?.length),
        isLoadingRequest,
        pagination: onboardingEditData?.payload?.pagination ?? {},
        updateRecords,
        finalizeOnboarding,
        deleteOrUndoDeleteRecord,
        alertSnackbarMainProps,
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
    }
}

export default useEditOnBoarding

import React, { useState } from 'react'
import axios from 'axios'
import { TextField, Box } from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { format } from 'date-fns'
import { clientTheme } from 'theme-exports'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { useActiveMerchant } from 'components'
import { StandardModal } from 'components'

type TAddErtInputs = {
    content: string
}

interface IAddErtProps {
    openAddERT: boolean
    onClose: () => void
    caseId: number
    setRefreshCaseInfo: (toggleRefresh: boolean) => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export const AddErt: React.FC<IAddErtProps> = ({
    openAddERT,
    onClose,
    caseId,
    setRefreshCaseInfo,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}) => {
    const { user } = useAuthedUser()
    const { id: merchantId } = useActiveMerchant()

    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const validationSchema = Yup.object().shape({
        content: Yup.string().required(),
    })

    const {
        reset,
        register,
        handleSubmit,
        formState: { isSubmitting, isDirty, isValid },
        // TODO: Known React-Hook-Form typescript issue - Should be resolved with RHF's v8. Once resolved, need to switch typescript's "any" to "TAddErtInputs".
    } = useForm<any>({
        defaultValues: {
            content: '',
        },
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
    })

    const onSubmit: SubmitHandler<TAddErtInputs> = (values) => {
        return axios
            .post(`/ert/case/${caseId}/erts`, {
                status_id: 1,
                is_active: true,
                level_id: 1,
                type_id: 7,
                user_id: user?.id,
                merchant_id: +merchantId,
                ...values,
            })
            .then((res) => {
                const { cb_id, created_by, date_created } = res.data

                // Add flag to case:
                axios
                    .post(`cm/cases/${caseId}/flag`, {
                        flag: true,
                        comment: `ERT created (Case ID: ${cb_id}. Created by: ${
                            created_by?.name
                        }. Date created: ${format(
                            new Date(date_created),
                            'yyyy-MM-dd'
                        )}.).`,
                    })
                    .then(() => {
                        //@ts-ignore
                        setRefreshCaseInfo((prev) => !prev)
                    })

                setAlertSnackbarMainProps({
                    title: 'Success',
                    intent: 'success',
                    message: 'ERT record successfully saved.',
                })
                setAlertSnackbarMainOpen(true)
                handleOnClose()
            })
            .catch(() => {
                setAlertSnackbarProps({
                    title: 'Error',
                    intent: 'error',
                    message: 'An error occurred while adding this ERT record.',
                })
                setAlertSnackbarOpen(true)
            })
    }

    const handleOnClose = () => {
        onClose()
        reset()
    }

    return (
        <StandardModal
            openModal={openAddERT}
            onModalClose={handleOnClose}
            modalHeaderTitle={'Add ERT'}
            maxModalWidth={'sm'}
            testId={'addErtQCReview'}
            primaryBtnText={'Add'}
            handlePrimaryActionBtn={handleSubmit(onSubmit)}
            inactivatePrimaryActionBtn={!isDirty || isSubmitting || !isValid}
            isLoadingPrimaryActionBtn={isSubmitting}
            handleSecondaryActionBtn={handleOnClose}
        >
            <div id={'caseQCReviewAddErt'}>
                <div className={'emp-modalContainer'}>
                    <Box
                        className={'emp-inputContainer'}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius:
                                    clientTheme.selectionBox.borderRadius,
                            },
                        }}
                    >
                        <label>
                            Content:<span style={{ color: 'red' }}>*</span>
                        </label>
                        <div>
                            <TextField
                                {...register('content')}
                                name="content"
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                            />
                        </div>
                    </Box>
                </div>
            </div>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </StandardModal>
    )
}

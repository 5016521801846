import React, { useEffect, useState } from 'react'
import {
    // SelectedRowWarningModal,
    UploadRepresentments,
    UploadRepresentmentsVC3
} from 'components'
import { CaseData } from 'hooks/cb-api'
import {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'

interface UploadRepresentmentsRouterProps {
    className?: string
    id?: string
    title?: string
    cases: CaseData[]
    caseIds: number[]
    openModal: boolean
    clientId?: number
    toggleModal: (
        variant:
            | 'assignUser'
            | 'moveToDnr'
            | 'revertDnr'
            | 'uploadDocuments'
            | 'toggleFlag',
        mode?: 'multiselect' | 'single'
    ) => void
    toggleUploadSuccess?: () => void
    isMultiSelect?: boolean
    rehydrateView?: () => void
    isReupload?: boolean
    comments?: string
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    unselectAllRows?: () => void
    disableRemedy?: boolean
    fromUploadIcon?: boolean
    rowIndex?: number
    selectSingleRowClearRest?: (index: string) => void
}

interface UploadRepresentmentsRouterState {
    filteredSelectedCases: CaseData[]
    filteredHasRemedyCases: CaseData[]
    totalRemedyCases: number
    totalRegularCases: number
    hasRemedy: boolean
    isMixed: boolean
}

const UploadRepresentmentsRouter = ({
    cases,
    caseIds,
    openModal,
    clientId = 0,
    toggleModal,
    toggleUploadSuccess = () => {},
    isMultiSelect = false,
    rehydrateView,
    isReupload = false,
    comments = '',
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    unselectAllRows = () => {},
    disableRemedy = false,
    fromUploadIcon = false,
    rowIndex = 0,
    selectSingleRowClearRest = () => {},
}: UploadRepresentmentsRouterProps) => {
    const [uploadProps, setUploadProps] = useState<UploadRepresentmentsRouterState|undefined>(undefined)

    // [TODO] = This needs fixed for AG Grid version of grids. 
    useEffect(() => {
        if (openModal) {
            const filteredSelectedCases = cases.filter((obj) => { return caseIds.includes(obj.id) })
            const filteredHasRemedyCases = filteredSelectedCases.filter((obj) => { return obj.properties.can_remedy })

            if (fromUploadIcon) {
                selectSingleRowClearRest(rowIndex.toString())
            }

            if (filteredHasRemedyCases.length && (filteredSelectedCases.length !== filteredHasRemedyCases.length)) {
                return setUploadProps({
                    filteredSelectedCases: filteredSelectedCases,
                    filteredHasRemedyCases: filteredHasRemedyCases,
                    totalRegularCases: filteredSelectedCases.length - filteredHasRemedyCases.length,
                    totalRemedyCases: filteredHasRemedyCases.length,
                    hasRemedy: true,
                    isMixed: true
                })
            }

            setUploadProps({
                filteredSelectedCases: filteredSelectedCases,
                filteredHasRemedyCases: filteredHasRemedyCases,
                totalRegularCases: filteredSelectedCases.length,
                totalRemedyCases: 0,
                hasRemedy: filteredHasRemedyCases.length ? true : false,
                isMixed: false
            })
        }
        return () => setUploadProps(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal])

    if (!uploadProps) return <></>

    // --- We decided to not go with this, but going to leave it here for now. There's a chance
    // --- that we may have to go back to this idea.
    // if (uploadProps.isMixed) {
    //     return (
    //         <SelectedRowWarningModal
    //             open={uploadProps.isMixed}
    //             toggleOpen={() => setUploadProps(undefined)}
    //             message={`Selection contains ${uploadProps.totalRemedyCases} remedy ready
    //                 and ${uploadProps.totalRegularCases} non-remedy ready cases. Please narrow your selection.`}
    //         />
    //     )
    // }

    if (caseIds.length === 1 && !disableRemedy && !uploadProps.isMixed && uploadProps.hasRemedy) {
        return (
            <UploadRepresentmentsVC3
                caseIds={caseIds}
                clientId={clientId}
                openModal={openModal}
                toggleModal={toggleModal}
                rehydrateView={rehydrateView}
                isReupload={isReupload}
                comments={comments}
                isMultiSelect={isMultiSelect}
                setAlertSnackbarMainProps={
                    setAlertSnackbarMainProps
                }
                setAlertSnackbarMainOpen={
                    setAlertSnackbarMainOpen
                }
                unselectAllRows={unselectAllRows}
                toggleUploadSuccess={toggleUploadSuccess}
                isVisaCE={uploadProps.hasRemedy}
            />
        )
    }

    return (
        <UploadRepresentments
            caseIds={caseIds}
            clientId={clientId}
            openModal={openModal}
            toggleModal={toggleModal}
            rehydrateView={rehydrateView}
            isReupload={isReupload}
            comments={comments}
            isMultiSelect
            setAlertSnackbarMainProps={
                setAlertSnackbarMainProps
            }
            setAlertSnackbarMainOpen={
                setAlertSnackbarMainOpen
            }
            unselectAllRows={unselectAllRows}
            toggleUploadSuccess={toggleUploadSuccess}
        />
    )
}

export default UploadRepresentmentsRouter
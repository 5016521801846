import { useSWRContext } from 'context/SWRContext'

interface IUseRevertDnrModal {
    handleBulkRevertDnr: (slectedCaseIds: number[]) => Promise<any>
    loading: boolean
    error: any
}

const UseRevertDnrModal = (): IUseRevertDnrModal => {
    const { swrActions } = useSWRContext()
    const { caseNetworkActions, casesLoading, caseActionError } = swrActions

    const { bulkRevertToNew } = caseNetworkActions

    return {
        handleBulkRevertDnr: bulkRevertToNew,
        loading: casesLoading,
        error: caseActionError,
    }
}

export default UseRevertDnrModal

import React, { useState } from 'react'
import CB from 'lib'
import { ConfirmActionModal } from 'components'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'

interface IDoNotRepresentProps {
    openDNR: boolean
    onClose: () => void
    caseId: number
    getNextQCReviewCase: () => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export const DoNotRepresent: React.FC<IDoNotRepresentProps> = ({
    openDNR,
    onClose,
    caseId,
    getNextQCReviewCase,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}) => {
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})
    const [isLoadingDNR, setIsLoadingDNR] = useState<boolean>(false)

    const handleDoNotRepresent = async () => {
        setIsLoadingDNR(true)
        try {
            await CB.cases.doNotRepresent(caseId)
            
            handleOnClose()
            setAlertSnackbarMainProps({
                title: 'Success',
                message: `Case ${caseId} was successfully moved to Do Not Represent. Loading next...`,
                intent: 'success',
            })
            setAlertSnackbarMainOpen(true)
            getNextQCReviewCase()
        } catch (error) {
            setAlertSnackbarProps({
                title: 'Error',
                intent: 'error',
                message: `An error occurred processing this request. Please try again later.`,
            })
            setAlertSnackbarOpen(true)
        } finally {
            setIsLoadingDNR(false)
        }
    }

    const handleOnClose = () => {
        onClose()
    }

    return (
        <div id={'caseQCReviewDNR'}>
            <ConfirmActionModal
                open={openDNR}
                maxModalWidth={'sm'}
                toggleOpen={handleOnClose}
                onConfirm={handleDoNotRepresent}
                header={`Move Case to Do Not Represent`}
                message={
                    'You are about to move this case to do not represent and mark as a loss, would you like to proceed?'
                }
                confirmButtonText={'Continue'}
                inactivateConfirmButton={isLoadingDNR}
                isLoadingConfirmButton={isLoadingDNR}
                testId={'caseQCReviewDNR'}
            />
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </div>
    )
}

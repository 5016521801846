/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Avatar, Typography, Chip, Link as AnchorLink, Card } from '@mui/material';
import { Skeleton } from '@mui/material'
import { clientTheme } from 'theme-exports'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import MerchantAvatar from 'components/MerchantAvatar/MerchantAvatar'


// eslint-disable-next-line no-shadow
export enum ProfileVariant {
    bounded, // card
    compact, // thin div
}

interface ProfileProps {
    // eslint-disable-next-line react/require-default-props
    className?: string;
    // eslint-disable-next-line react/require-default-props
    variant?: ProfileVariant;
}

interface WrapperProps {
    children: React.ReactNode;
    className: string;
}

/**
 * Display Avatar and various information about a user's account
 */
const Profile = ({
    className = '',
    variant = ProfileVariant.bounded,
}: ProfileProps): React.ReactElement => {
    const { user, loading } = useAuthedUser() // Get user from context
    const merchant = { name: user?.getActiveMerchantName() ?? '' }
    const addedClasses =
        ProfileVariant.compact === variant
            ? `profile-compact ${'emp-profile-thin '}`
            : `profile-bounded ${'emp-profile-thick'}` // If we need more variants turn this into a switch

    const Wrapper = variant === ProfileVariant.compact ? ({ children, className: cl }: WrapperProps) => {
        return (
            <div className={cl}>
                {children}
            </div>
        )
    } : ({ children, className: cl }: WrapperProps) => {
        return (
            <Card className={cl}>
                {children}
            </Card>
        )
    }

    return (
        <>
            <Wrapper className={`profile-area ${addedClasses} ${className}`}>
                <div className={`profile-person ${'emp-profile-avatarFrame'}`}>
                    <Avatar
                        className={'emp-profile-avatar'}
                        style={{ backgroundColor: clientTheme.primaryDark }}
                        alt={!loading ? user?.fullName() ?? '' : ''}
                    >
                        {user?.initials() ?? user?.usernameInitials() ?? ''}
                    </Avatar>
                    <div className={'emp-profile-accountOwner'}>
                        <div>
                            <Typography variant="h3">
                                {loading ? <Skeleton /> : user?.fullName()}
                            </Typography>
                        </div>
                        <div
                            className={'emp-profile-username'}
                            style={{ color: clientTheme.primary }}
                        >
                            <Typography variant="body1" color="textPrimary">
                                <strong>
                                    {loading ? (
                                        <Skeleton />
                                    ) : (
                                        user?.loginRepresentation()
                                    )}
                                </strong>
                            </Typography>
                        </div>
                        <div>
                            {loading ? (
                                <Skeleton />
                            ) : (
                                <AnchorLink
                                    color="secondary"
                                    href={`mailto:${user?.email}`}
                                >
                                    {' '}
                                    {user?.email}
                                </AnchorLink>
                            )}
                        </div>
                    </div>
                </div>
                <div className={'emp-profile-accountParts'}>
                    <div>
                        <MerchantAvatar loading={loading} merchant={merchant} />
                    </div>
                </div>
                <section className={`profile-roles ${'emp-profile-roles'}`}>
                    <Chip
                        className={'emp-profile-role'}
                        label={
                            loading ? (
                                <Skeleton variant="text">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </Skeleton>
                            ) : (
                                user?.role?.name
                            )
                        }
                    />
                </section>
            </Wrapper>
        </>
    )
}

export default Profile

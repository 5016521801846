import React, { useEffect, useState } from 'react'
import View from '../View'
import { Checkbox, Grid } from '@mui/material'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import idDirectory from './idAttributes'
import { AccentArea, AccountHistory, Alert, PasswordChange } from 'components'
import ProfileApi from 'api/ProfileApi'
import { withRequiredRole } from 'components/useRequireRole'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import EntFeature from 'models/EntFeature'
import logger from 'utils/logger'
import '../views.css'
import { iconsTheme } from 'theme-exports'
import Icon from 'components/Icon'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import NotificationsArea from './NotificationsArea/NotificationsArea'
import UsersApi from 'api/UsersApi'
import UpdateEmail from 'components/UpdateEmail'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'

interface SwitchListOptions {
    [key: string]: {
        name: string
        key: string
        label: string
        isChecked: boolean
    }
}

interface SettingsViewData {
    loading: boolean
    emailSettings: any
}

interface Notification {
    message: string
    severity: 'error' | 'success' | 'info' | 'warning' | undefined
}

/**
 * The Profile settings view for a person, with their account information
 */
export const Settings: React.FC = () => {
    const { title_icon: titleIconEnabled } = useFeatureToggle('ACCENT_AREA')
    const { user, loading, refreshAuthedUser } = useAuthedUser()
    const isFederatedUser = user?.is_federated
    const [notification, setNotification] = React.useState<Notification>()
    const [showUniqueLogins, setShowUniqueLogins] = React.useState<boolean>(
        false
    )
    const [settingsData, setSettingsData] = React.useState<SettingsViewData>({
        loading: true,
        emailSettings: null,
    })
    const [alertSnackbarMainOpen, setAlertSnackbarMainOpen] = useState<boolean>(
        false
    )
    const [
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
    ] = useState<alertSnackbarContentProps>({})

    const { invalidate } = useUiStateContext()

    useEffect(() => {
        if (refreshAuthedUser) refreshAuthedUser()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (user) obtainSettings(user)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id])

    const obtainSettings = (user: any) => {
        // Load in the profile api info
        ProfileApi.getNotifications(user?.allow_resources)
            .then((data) => {
                setSettingsData({
                    emailSettings: data,
                    loading: false,
                })
            })
            .catch((err) => {
                setNotification({
                    message: 'Unable to get user settings.',
                    severity: 'error',
                })
                logger(
                    `Error: Unable to get user notifications settings: ${err?.message}`
                )
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    // Change my own password
    const handlePasswordChange = ({
        currentPassword,
        newPassword,
    }: {
        currentPassword: string
        newPassword: string
        confirmPassword: string
    }): Promise<void> => {
        return ProfileApi.setCognitoPassword(currentPassword, newPassword)
            .then((data) => {
                setAlertSnackbarMainProps({
                    title: 'Success',
                    message: 'Password was successfully updated.',
                    intent: 'success',
                })
                setAlertSnackbarMainOpen(true)
                logger(data)
            })
            .catch((error) => {
                setAlertSnackbarMainProps({
                    title: 'Error',
                    message: error?.message,
                    intent: 'error',
                })
                setAlertSnackbarMainOpen(true)
                logger(error?.message)
            })
    }

    const transformedSettings:
        | SwitchListOptions
        | Record<string, unknown>
        | undefined = settingsData.emailSettings?.reduce(
        (container: any, sett: any) => {
            // @ts-ignore
            container[sett.identity] = {
                name: sett.identity,
                key: sett.identity,
                label: sett.text,
                isChecked: sett.isOn,
            }
            return container
        },
        {}
    )

    const handleNotificationChange = (key: string) => {
        const settingToUpdate = settingsData.emailSettings.find(
            (setting: any) => setting.identity === key
        )
        const { id, isOn } = settingToUpdate

        ProfileApi.setNotificationSetting(user?.id, id, !isOn)
            .then(() => {
                settingToUpdate.isOn = !isOn
                setSettingsData({
                    ...settingsData,
                    emailSettings: [...settingsData.emailSettings],
                })
                if (refreshAuthedUser) refreshAuthedUser()
                setAlertSnackbarMainProps({
                    title: 'Success',
                    message: 'Permission was successfully updated.',
                    intent: 'success',
                })
            })
            .catch((e) => {
                setAlertSnackbarMainProps({
                    title: 'Error',
                    message:
                        'An error occurred updating permission. Please try again later.',
                    intent: 'error',
                })
                logger(e?.message)
            })
            .finally(() => {
                setAlertSnackbarMainOpen(true)
            })
    }

    const handleEmailChange = (email: string) => {
        if (user && user.id) {
            return UsersApi.editEmail(user?.id, email)
                .then(() => {
                    setAlertSnackbarMainProps({
                        title: 'Success',
                        message: 'Your email has been successfully updated.',
                        intent: 'success',
                    })
                    setAlertSnackbarMainOpen(true)
                })
                .catch((e) => {
                    setAlertSnackbarMainProps({
                        title: 'Error',
                        message: 'There was an error changing your email.',
                        intent: 'error',
                    })
                    setAlertSnackbarMainOpen(true)
                    logger(e?.message)
                })
                .finally(() => {
                    ProfileApi.refreshTokens().then(() => {
                        setTimeout(() => {
                            if (refreshAuthedUser) refreshAuthedUser()
                            invalidate('whoami')
                        }, 1000)
                    })
                })
        } else {
            setAlertSnackbarMainProps({
                title: 'Error',
                message: 'There was an error changing your email.',
                intent: 'error',
            })
            return setAlertSnackbarMainOpen(true)
        }
    }

    return (
        <View title="Settings" breadcrumb="Settings" testId="settings">
            {notification?.message && (
                <Alert
                    severity={notification?.severity}
                    style={{ marginBottom: 10 }}
                >
                    {notification?.message}
                </Alert>
            )}
            <Grid id={idDirectory.gridContainer} container spacing={4}>
                <Grid item xs={12} md={6} id={`${idDirectory.gridItem}-left`}>
                    <AccentArea
                        title={
                            <div className={'emp-icon'}>
                                {titleIconEnabled && (
                                    <Icon
                                        className={`${
                                            iconsTheme.singleConsumer
                                        } ${'emp-marginRight'}`}
                                    />
                                )}
                                <span>User Settings</span>
                            </div>
                        }
                        testId="userSettings"
                    >
                        {!loading && (
                            <section className={'emp-ladder'}>
                                <div className={'emp-lead'}>
                                    <UpdateEmail
                                        className={'emp-outlinedInputs'}
                                        onSubmit={handleEmailChange}
                                        initialEmail={user?.email}
                                        isFederated={isFederatedUser}
                                    />
                                </div>
                            </section>
                        )}
                        <section className={'emp-ladder'}>
                            <div className={'emp-lead'}>
                                <PasswordChange
                                    color={'secondary'}
                                    className={'emp-outlinedInputs'}
                                    onSubmit={handlePasswordChange}
                                    isFederated={isFederatedUser}
                                />
                            </div>
                        </section>
                    </AccentArea>
                </Grid>
                <Grid item xs={12} md={6} id={`${idDirectory.gridItem}-right`}>
                    {(
                        transformedSettings
                            ? Object.keys(transformedSettings).length
                            : false
                    ) ? (
                        <Grid item style={{ marginBottom: 20 }}>
                            <AccentArea
                                title={
                                    <div className={'emp-icon'}>
                                        {titleIconEnabled && (
                                            <Icon
                                                className={`${
                                                    iconsTheme.notification
                                                } ${'emp-marginRight'}`}
                                            />
                                        )}
                                        <span>Notifications</span>
                                    </div>
                                }
                                testId="notifications"
                            >
                                <NotificationsArea
                                    settings={transformedSettings}
                                    loading={settingsData?.loading}
                                    expectedLength={4}
                                    handleChange={handleNotificationChange}
                                />
                            </AccentArea>
                        </Grid>
                    ) : (
                        <></>
                    )}

                    <Grid item id={`${idDirectory.gridItem}-3`}>
                        <AccentArea
                            className={'emp-historySection'}
                            title={
                                <div className={'emp-icon'}>
                                    {titleIconEnabled && (
                                        <Icon
                                            className={`${
                                                iconsTheme.clock
                                            } ${'emp-marginRight'}`}
                                        />
                                    )}
                                    <span>Login History</span>
                                </div>
                            }
                            headerAction={
                                <label
                                    style={{
                                        display: 'flex',
                                        placeItems: 'center',
                                        fontSize: '14px',
                                    }}
                                >
                                    <Checkbox
                                        className="reduce-vertical-padding"
                                        checked={showUniqueLogins}
                                        onChange={() =>
                                            setShowUniqueLogins(
                                                !showUniqueLogins
                                            )
                                        }
                                        color="secondary"
                                        id={idDirectory.checkboxUnique}
                                    />{' '}
                                    Show Unique Only
                                </label>
                            }
                            testId="loginHx"
                        >
                            {user && user.id && (
                                <AccountHistory
                                    userId={user.id}
                                    showUniqueLogins={showUniqueLogins}
                                />
                            )}
                        </AccentArea>
                    </Grid>
                </Grid>
            </Grid>
            <AlertSnackbar
                content={alertSnackbarMainProps}
                open={alertSnackbarMainOpen}
                onClose={() => setAlertSnackbarMainOpen(false)}
                showCloseIcon
            />
        </View>
    )
}

export default withRequiredRole(EntFeature.PROFILE, Settings)

import axios from 'axios'

type GetCountryCodes = () => Promise<any>

interface UtilityApiInterface {
    getCountryCodes: GetCountryCodes
}

class UtilityApi implements UtilityApiInterface {
    getCountryCodes() {
        return axios
            .get(`/cm/gen/countries`, {
                params: {
                    start: 0,
                    limit: 999,
                },
            })
            .then((res) => res.data.data)
    }

    getProcessors() {
        return axios
            .get(`/cm/gen/processors/`, {
                params: {
                    start: 0,
                    limit: 999,
                },
            })
            .then((res) => res.data)
    }
}

export default new UtilityApi()

/**
 * @description Directory for ID Attributes on Resultant KPI.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    divContainer: `resultantKPI-div-container`,
}

export default idDirectory

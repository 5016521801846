import React from 'react'
import { LoadingIndicator } from 'components'
import idDirectory from './idAttributes'

export const AGDataGridLoadingIndicator = () => {
    return (
        <div
            id={idDirectory.agDataGridLoadingIndicator.divLoading}
            className="ag-overlay-loading-center"
            style={{ marginTop: 50 }}
        >
            <LoadingIndicator />
        </div>
    )
}



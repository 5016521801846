import React from 'react'
import { Auth } from 'aws-amplify'
import {
    TextField,
    Typography,
    Button,
    Link,
    CircularProgress,
    Box,
} from '@mui/material'
import { FormState } from './AuthedUserContext'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'

interface ResetPasswordProps {
    formState: FormState
    setFormState: (val: any) => void
    setSnackbar: (val: { isOpen: boolean; errorMessage: any }) => void
    isSubmit: boolean
    setIsSubmit: (val: boolean) => void
    handleOnChange: (e: any) => void
}

const ResetPassword = ({
    formState,
    setFormState,
    setSnackbar,
    isSubmit,
    setIsSubmit,
    handleOnChange,
}: ResetPasswordProps) => {
    const { username } = formState

    const handleResetPassword = async (e: any) => {
        e.preventDefault()
        setIsSubmit(true)
        try {
            await Auth.forgotPassword(username)
            setFormState(() => ({ ...formState, formType: 'forgotPassword' }))
            setIsSubmit(false)
        } catch (err) {
            setSnackbar({ isOpen: true, errorMessage: err.message })
            setIsSubmit(false)
        }
    }

    return (
        <form onSubmit={handleResetPassword} id={'resetPassword'}>
            <div
                className={'emp-container'}
                id={idDirectory.resetPassword.divContainer}
            >
                <div
                    className={'emp-resetContainer'}
                    id={idDirectory.resetPassword.divResetContainer}
                >
                    <div className={'emp-inputs'}>
                        <Typography variant={'h6'} className={'emp-textInput'}>
                            Username
                        </Typography>
                        <Box
                            className={'emp-textField'}
                            sx={{
                                '& .MuiInput-underline:after': {
                                    border: `1px solid ${clientTheme.textField}`,
                                },
                            }}
                        >
                            <TextField
                                type="username"
                                name="username"
                                variant="standard"
                                onChange={handleOnChange}
                                value={username}
                                fullWidth
                                id={idDirectory.resetPassword.inputUsername}
                            />
                        </Box>
                        <Typography id={idDirectory.resetPassword.textLogin}>
                            <Link
                                onClick={() =>
                                    setFormState(() => ({
                                        ...formState,
                                        formType: 'signIn',
                                        password: '',
                                    }))
                                }
                                className={'emp-link'}
                                sx={{
                                    '&:hover': {
                                        color: `${clientTheme.textField} !important`,
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                Login Instead
                            </Link>
                        </Typography>
                    </div>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={'emp-btn'}
                        type="submit"
                        fullWidth
                        id={idDirectory.resetPassword.btnReset}
                    >
                        {isSubmit ? (
                            <CircularProgress
                                className={'emp-circularProgress'}
                                color="secondary"
                            />
                        ) : (
                            'Reset'
                        )}
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default ResetPassword

import React, { useState, useEffect, useCallback, useRef } from 'react'
import debounce from 'lodash.debounce'
import { TextField, Divider } from '@mui/material'
import { ICellEditorParams } from 'ag-grid-community'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clientTheme } from 'theme-exports'
import { SelectOption, LoadingIndicator } from 'components'
import idDirectory from './idAttributes'

export type TOption = {
    id: number | undefined
    name: string
}

interface IAGDataGridSelectCellEditorProps {
    testId: string
    props: ICellEditorParams
    handleSelectedValue: (value: TOption) => void
    loadingOptions: boolean
    options: TOption[]
    setOptions?: (options: TOption[]) => void
    showNoResultsText?: boolean
    setNoResults?: (noResults: boolean) => void
    showSearchBox?: boolean
    setSearchValue?: (value: string) => void
    showMinSearchCharText?: boolean
    showDefaultText?: boolean
    defaultText?: string
    showPagination?: boolean
}

export const AGDataGridSelectCellEditor = ({
    testId,
    props,
    handleSelectedValue,
    loadingOptions,
    options,
    setOptions = () => {}, // Needed when showSearchBox AND showMinSearchCharText are true.
    showNoResultsText = false,
    setNoResults = () => {}, // Needed when showSearchBox AND showMinSearchCharText are true.
    showSearchBox = false,
    setSearchValue = () => {},
    showMinSearchCharText = false,
    showDefaultText = false,
    defaultText = 'Select',
    showPagination = false,
}: IAGDataGridSelectCellEditorProps): React.ReactElement => {
    const refContainer = useRef(null)
    const [currentPage, setCurrentPage] = React.useState(1)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [textFieldValue, setTextFieldValue] = useState<string>('')
    const [stopEditing, setStopEditing] = useState<boolean>(false)

    const optionsToDisplay = showPagination
        ? options?.slice(
              (currentPage - 1) * rowsPerPage,
              (currentPage - 1) * rowsPerPage + rowsPerPage
          )
        : options
    const totalEntries = options.length
    const pageCount = Math.ceil(totalEntries / rowsPerPage)
    const startValue = rowsPerPage * currentPage - (rowsPerPage - 1)
    const endValue =
        rowsPerPage * currentPage <= totalEntries
            ? rowsPerPage * currentPage
            : totalEntries

    const handlePageChange = (move: string) => {
        if (!pageCount) return
        if (move === 'previous' && currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
        if (move === 'next' && currentPage !== pageCount) {
            setCurrentPage(currentPage + 1)
        }
    }
    useEffect(() => {
        if (stopEditing) {
            props.stopEditing()
        }
    }, [stopEditing]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        ;(refContainer.current as any).focus()
    }, [])

    const sendSearchValue = useCallback(
        debounce((value) => {
            setSearchValue(value)
            setCurrentPage(1)
        }, 500),
        []
    )

    const handleSearchValue = (e: any) => {
        const inputValue = e.target.value
        if (inputValue.length < 2 && showMinSearchCharText) {
            setOptions([])
            setNoResults(false)
        }
        sendSearchValue(inputValue)
        setTextFieldValue(inputValue)
    }

    return (
        <div
            id={`${idDirectory.agDataGridSelectCellEditor.divRoot}-${testId}`}
            className="emp-agDataGridSelectCellEditor-root"
            ref={refContainer}
            style={{ minWidth: showSearchBox ? '300px' : '200px' }}
        >
            {showSearchBox ? (
                <TextField
                    id={`${idDirectory.agDataGridSelectCellEditor.inputSearch}-${testId}`}
                    value={textFieldValue}
                    onChange={handleSearchValue}
                    onClick={(e) => e.stopPropagation()}
                    size="small"
                    variant="outlined"
                    fullWidth
                    autoFocus
                    className="emp-agDataGridSelectCellEditor-searchField"
                />
            ) : null}
            <div>
                {loadingOptions ? (
                    <div
                        id={`${idDirectory.agDataGridSelectCellEditor.divLoading}-${testId}`}
                        className={
                            'emp-agDataGridSelectCellEditor-loadingContainer'
                        }
                    >
                        <LoadingIndicator />
                    </div>
                ) : (
                    <>
                        {showDefaultText && !showNoResultsText && (
                            <div
                                id={`${idDirectory.agDataGridSelectCellEditor.divDefaultText}-${testId}`}
                                className="emp-agDataGridSelectCellEditor-defaultText"
                            >
                                {defaultText}
                            </div>
                        )}
                        {optionsToDisplay.length ? (
                            <ul
                                id={`${idDirectory.agDataGridSelectCellEditor.divOptions}-${testId}`}
                                className={
                                    'emp-agDataGridSelectCellEditor-options'
                                }
                                style={{
                                    color:
                                        clientTheme.formFields.formText.standard
                                            .color,
                                }}
                            >
                                {optionsToDisplay.map(
                                    (opt: TOption, idx: number) => (
                                        <SelectOption
                                            key={`key-select-option-${idx}`}
                                            testId={`${idDirectory.agDataGridSelectCellEditor.selectOption}-${testId}-${idx}`}
                                            onClick={() => {
                                                handleSelectedValue(opt)
                                                setStopEditing(true)
                                            }}
                                        >
                                            {opt.name}
                                        </SelectOption>
                                    )
                                )}
                            </ul>
                        ) : null}
                        {showMinSearchCharText && textFieldValue.length < 2 && (
                            <div
                                id={`${idDirectory.agDataGridSelectCellEditor.divMinSearchCharText}-${testId}`}
                                className="emp-agDataGridSelectCellEditor-minSearchCharText"
                            >
                                Please enter 2 or more characters
                            </div>
                        )}
                        {showNoResultsText && (
                            <div
                                id={`${idDirectory.agDataGridSelectCellEditor.divNoResults}-${testId}`}
                                className="emp-agDataGridSelectCellEditor-noResultsText"
                            >
                                No results found
                            </div>
                        )}
                        {showPagination && !showNoResultsText && (
                            <div
                                id={`${idDirectory.agDataGridSelectCellEditor.divPagination}-${testId}`}
                                className={
                                    'emp-agDataGridSelectCellEditor-paginationRoot'
                                }
                                style={{
                                    fontSize:
                                        clientTheme.typography.body1.fontSize,
                                    color: clientTheme.typography.fontColor
                                        .primaryText
                                        ? clientTheme.typography.fontColor
                                              .primaryText
                                        : '#181D1F',
                                }}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Divider />
                                <div className="emp-agDataGridSelectCellEditor-paginationContainer">
                                    <div className="emp-agDataGridSelectCellEditor-pageSize">
                                        <select
                                            value={rowsPerPage}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLSelectElement>
                                            ) => {
                                                setRowsPerPage(
                                                    Number(e.target.value)
                                                )
                                                setCurrentPage(1)
                                            }}
                                        >
                                            {[5, 10, 25].map((size: number) => {
                                                return (
                                                    <option
                                                        key={`key-page-size-${size}`}
                                                        value={size}
                                                    >
                                                        {size}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="emp-agDataGridSelectCellEditor-pageInfo">
                                        <div className="emp-agDataGridSelectCellEditor-pageResultsText">
                                            <span>
                                                {startValue.toLocaleString(
                                                    'en-US'
                                                )}
                                            </span>
                                            {' - '}
                                            <span>
                                                {endValue.toLocaleString(
                                                    'en-US'
                                                )}
                                            </span>
                                            {' of '}
                                            <span>
                                                {totalEntries.toLocaleString(
                                                    'en-US'
                                                )}
                                            </span>
                                        </div>
                                        <div className="emp-agDataGridSelectCellEditor-pageNav">
                                            <div
                                                onClick={() =>
                                                    handlePageChange('previous')
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faAngleLeft}
                                                    className={
                                                        currentPage === 1 ||
                                                        !currentPage
                                                            ? 'emp-agDataGridSelectCellEditor-iconInactive'
                                                            : 'emp-agDataGridSelectCellEditor-iconActive'
                                                    }
                                                />
                                            </div>
                                            <div
                                                onClick={() =>
                                                    handlePageChange('next')
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faAngleRight}
                                                    className={
                                                        currentPage ===
                                                            pageCount ||
                                                        !pageCount
                                                            ? 'emp-agDataGridSelectCellEditor-iconInactive'
                                                            : 'emp-agDataGridSelectCellEditor-iconActive'
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

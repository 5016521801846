import React from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { iconsTheme } from 'theme-exports'

const cls1 = { fill: '#707070' }
const cls2 = { fill: '#CC440D' }

const VerdictPending = (): React.ReactElement => {
    return iconsTheme.inProgress ? (
        <Icon icon={faClock} style={{ fontSize: '18px', color: '#75797b' }} />
    ) : (
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.06 24.06">
                <defs></defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path
                            style={cls1}
                            d="M12,24.06a12,12,0,1,1,12-12A12,12,0,0,1,12,24.06ZM12,1.25A10.78,10.78,0,1,0,22.81,12,10.8,10.8,0,0,0,12,1.25Z"
                        />
                        <path
                            style={cls1}
                            d="M12,12.66a.63.63,0,0,1-.62-.63V4.55a.63.63,0,0,1,1.25,0V12A.63.63,0,0,1,12,12.66Z"
                        />
                        <path
                            style={cls2}
                            d="M16.14,16.9a.6.6,0,0,1-.45-.19l-4.11-4.25a.62.62,0,1,1,.9-.86l4.11,4.24a.63.63,0,0,1,0,.89A.65.65,0,0,1,16.14,16.9Z"
                        />
                    </g>
                </g>
            </svg>
        </span>
    )
}

export default VerdictPending

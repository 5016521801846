import React, { useState } from 'react'
import { Button, Dialog, DialogContent, TextField } from '@mui/material'
import { clientTheme } from 'theme-exports'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import { useSWRContext } from 'context/SWRContext'

interface IFlagCaseProps {
    row: any
    open: boolean
    setOpen: (isOpen: boolean) => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export const FlagCase: React.FC<IFlagCaseProps> = ({
    row,
    open,
    setOpen,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}) => {
    const [comment, setComment] = useState('')

    const { swrActions } = useSWRContext()

    const { caseNetworkActions } = swrActions
    const { flagCase, unFlagCase } = caseNetworkActions

    const { id: caseId } = row

    const handleFlag = () => {
        if (row.flag?.is_flagged) {
            unFlagCase(caseId, row.flag.id)
                .then(() => {
                    setAlertSnackbarMainProps({
                        message: 'Your action was a success!',
                        title: 'Success.',
                        intent: 'success',
                    })
                })
                .catch(() => {
                    setAlertSnackbarMainProps({
                        message: 'There was an error unflagging the case.',
                        title: 'Error.',
                        intent: 'error',
                    })
                })
                .finally(() => {
                    setComment('')
                    setOpen(false)
                    setAlertSnackbarMainOpen(true)
                })
        } else {
            flagCase(caseId, comment)
                .then(() => {
                    setAlertSnackbarMainProps({
                        message: 'Your action was a success!',
                        title: 'Success.',
                        intent: 'success',
                    })
                })
                .catch(() => {
                    setAlertSnackbarMainProps({
                        message: 'There was an error flagging the case.',
                        title: 'Error.',
                        intent: 'error',
                    })
                })
                .finally(() => {
                    handleOnClose()
                    setAlertSnackbarMainOpen(true)
                })
        }
    }

    const handleOnClose = () => {
        setComment('')
        setOpen(false)
    }

    return (
        <Dialog
            open={open}
            onClose={handleOnClose}
            maxWidth="sm"
            fullWidth
            id={'caseMgmtFlagCase'}
        >
            <DialogContent className={'emp-content '}>
                {row.flag?.is_flagged ? (
                    <>
                        <h2 className={'emp-title'}>Unflag Chargeback</h2>
                        <div className={'emp-actions'}>
                            <Button
                                style={{ width: '100%' }}
                                variant="contained"
                                onClick={handleOnClose}
                                sx={clientTheme.buttons.defaultButton.style}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ width: '100%' }}
                                onClick={handleFlag}
                            >
                                Continue
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <h2 className={'emp-title '}>Flag Chargeback</h2>
                        <h4 className={'emp-subtitle '}>Comment</h4>
                        <TextField
                            variant="outlined"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setComment(e.target.value)}
                            value={comment}
                            placeholder="Comment"
                            multiline
                            rows={4}
                        />
                        <div className={'emp-actions'}>
                            <Button
                                style={{ width: '100%' }}
                                variant="contained"
                                onClick={() => setOpen(false)}
                                sx={clientTheme.buttons.defaultButton.style}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ width: '100%' }}
                                onClick={handleFlag}
                            >
                                Flag
                            </Button>
                        </div>
                    </>
                )}
            </DialogContent>
        </Dialog>
    )
}

/**
 * @description Directory for ID Attributes on CaseBuilderFormBuilder.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `caseBuilderFormBuilder-div-root`,
    divSection: `caseBuilderFormBuilder-div-section`,
    typographyHeader: `caseBuilderFormBuilder-typography-header`,
    iconHeader: `caseBuilderFormBuilder-icon-header`,
    divField: `caseBuilderFormBuilder-div-field`,
    divFieldErrors: `caseBuilderFormBuilder-div-fieldErrors`,
    textField: `caseBuilderFormBuilder-text-field`,
    textAreaField: `caseBuilderFormBuilder-textArea-field`,
    numberField: `caseBuilderFormBuilder-number-field`,
    dateField: `caseBuilderFormBuilder-date-field`,
    selectField: `caseBuilderFormBuilder-select-field`,
    invalidField: `caseBuilderFormBuilder-invalid-field`,
}

export default idDirectory

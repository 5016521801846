import React, { useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import LineChart from 'components/LineChart'
import AreaChart from 'components/AreaChart'
import { IReportFilter } from 'views/Reports'

/**
 * @name ByAverageSpan
 * @desc Render Average Span Between Chargeback Transaction and Post Date by Week
 * @filterValues IReportsFilter
 */
const ByAverageSpan = ({
    filterValues,
    isLineChart = false,
}: {
    filterValues: IReportFilter
    isLineChart?: boolean
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ name: string; val: number }[]>([])

    React.useEffect(() => {
        setLoading(true)
        ReportsApi.getAverageSpanBetweenTransactionAndPostDate(
            filterValues
        ).then((data) => {
            setLoading(false)
            setData(data)
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    return isLineChart ? (
        <LineChart
            chartTitle="Average Span Between Chargeback Transaction and Post Date by Week"
            data={data}
            loading={loading}
            returnCard={false}
            toolTipValueName="Days"
        />
    ) : (
        <AreaChart
            chartTitle="Average Span Between Chargeback Transaction and Post Date by Week"
            data={data}
            loading={loading}
            returnCard={false}
            toolTipValueName="Days"
        />
    )
}

export default ByAverageSpan

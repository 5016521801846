import React from 'react'
import { Card, Typography, Divider } from '@mui/material';
import idDirectory from './idAttributes'
import { clientTheme } from 'theme-exports'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'

export interface IAccentAreaProps {
    title?: string | React.ReactNode
    className?: string
    helper?: React.ReactNode
    headerAction?: React.ReactNode
    children: React.ReactNode
    flipHeaderRow?: boolean
    hasHeaderDivider?: boolean
    borderTopColor?: string
    containsFullWidthGrid?: boolean
    testId?: string
    hideHeader?: boolean
    headerActionAlign?: 'center' | 'space-between' | 'flex-start' | 'flex-end'
}

/**
 *  A themed visual section within the views
 */
const AccentArea = ({
    title,
    className = '',
    helper,
    headerAction,
    children,
    flipHeaderRow,
    hasHeaderDivider = true,
    borderTopColor = 'default',
    containsFullWidthGrid = false,
    testId,
    hideHeader = false,
    headerActionAlign = 'space-between',
}: IAccentAreaProps) => {
    const { user } = useAuthedUser()
    const {
        client_theme_top_border: showClientThemeTopBorder,
    } = useFeatureToggle('ACCENT_AREA')
    const { enabled: themeVariationEnabled } = useFeatureToggle('HAS_THEME_VARIATIONS')
    if (!user?.theme_variant && themeVariationEnabled) {
        return <></>
    }
    const enableDefaultStyle = !Boolean(user?.theme_variant && user?.theme_variant > 1 )
    
    return (
        <Card
            id={`${idDirectory.card}-${testId}`}
            className={`${'emp-accentArea-root'} ${className} ${
                containsFullWidthGrid &&
                'emp-accentArea-containsFullWidthGrid'
            }`}
            style={{
                ...(showClientThemeTopBorder && {
                    borderRadius: clientTheme.accentAreaTopBorderAccent.borderRadius,
                    borderTop: `3px solid ${clientTheme.accentAreaTopBorderAccent.backgroundColor} !important`,
                }),
                borderTop: `${
                    borderTopColor !== 'default'
                    ? `3px solid ${borderTopColor}`
                    : enableDefaultStyle
                    ? `3px solid ${clientTheme.primaryLight}`
                    : `3px solid #ff0000`
                }`,
            }}
            component="section"
        >
            {!hideHeader && (
                <>
                    <div
                        id={`${idDirectory.divHeader}-${testId}`}
                        className={`${'emp-accentArea-actionsWrapper'} ${
                            flipHeaderRow ? 'emp-accentArea-rowReverse' : ''
                        }`}
                        style={{ justifyContent: headerActionAlign }}
                    >
                        {title && (
                            <header
                                id={`${idDirectory.headerHelper}-${testId}`}
                                className={'emp-accentArea-header'}
                            >
                                <Typography variant="h5">{title}</Typography>
                                {helper && <span>{helper}</span>}
                            </header>
                        )}
                        {headerAction && (
                            <div
                                id={`${idDirectory.divAction}-${testId}`}
                                className={'emp-accentArea-headerAction'}
                            >
                                {headerAction}
                            </div>
                        )}
                    </div>
                    {hasHeaderDivider ? (
                        <Divider className={'emp-accentArea-divider'} />
                    ) : null}
                </>
            )}

            <div
                id={`${idDirectory.divChildren}-${testId}`}
                className="accent-area-content"
            >
                {children}
            </div>
        </Card>
    )
}

export default AccentArea

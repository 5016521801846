import { useRef, useState } from 'react'

interface DataGridSlimVM {
    handleExpandableStuff: () => void
    isResizing: boolean
    setIsResizing: (val: boolean) => void
    overflowColumns: string[]
    tableOuterContainerEL: any
    resizeObserver: any
    resizeTimerRef: any
}

interface headerColumnELsSortProps {
    element: string
    elPriority: number
    elWidth: number
}

const useDataGridSlim = (expandable: boolean): DataGridSlimVM => {
    const [overflowColumns, setOverflowColumns] = useState<string[]>([])
    const dataGridWidth = useRef(0)
    const appZoomLevel = useRef(0)
    const resizeTimerRef = useRef<NodeJS.Timeout | null>(null)
    const tableOuterContainerEL = useRef<HTMLDivElement>(null)
    const [isResizing, setIsResizing] = useState<boolean>(
        expandable ? true : false
    )
    const handleExpandableStuff = () => {
        setOverflowColumns([])
        const headerColumnELs = document.querySelectorAll(
            '[class*="header-column-"]'
        )
        const tmpOverflowColumns: any[] = []
        dataGridWidth.current = tableOuterContainerEL?.current?.offsetWidth ?? 0
        appZoomLevel.current = (window.outerWidth - 10) / window.innerWidth
        const refactorHeaderColumnELs: headerColumnELsSortProps[] = []

        headerColumnELs.forEach((el: any) => {
            refactorHeaderColumnELs.push({
                element: el.getAttribute('data-id'),
                elPriority: +el.getAttribute('priority-id'),
                elWidth: +el.getAttribute('input-width'),
            })
        })

        const headerColumnELsSort = refactorHeaderColumnELs.sort(
            (a: headerColumnELsSortProps, b: headerColumnELsSortProps) =>
                a.elPriority - b.elPriority
        )

        const containerWidth = tableOuterContainerEL?.current?.offsetWidth ?? 0
        const containerLeftOffset =
            tableOuterContainerEL?.current?.offsetLeft ?? 0

        let currentElsWidthTotal = 0

        for (let i = 0; i < headerColumnELsSort.length; i++) {
            const elWidth = headerColumnELsSort[i].elWidth

            if (
                currentElsWidthTotal + elWidth <
                containerWidth - containerLeftOffset
            ) {
                currentElsWidthTotal += elWidth
            } else {
                const elementHeaders = headerColumnELsSort.map(
                    (val) => val.element
                )
                tmpOverflowColumns.push(...elementHeaders.slice(i))
                break
            }
        }

        setOverflowColumns(tmpOverflowColumns)
        setIsResizing(false)
    }

    const resizeObserver = new ResizeObserver(() => {
        const browserZoomLevel = (window.outerWidth - 10) / window.innerWidth

        if (!tableOuterContainerEL.current) return false
        const caseFilterOpen = document.getElementById('accentArea-card-card-chargebacks-caseFilter')
            ? true
            : false

        const hasZoomLevelChanged = appZoomLevel.current !== browserZoomLevel

        const hasGridSizeChanged =
            tableOuterContainerEL.current.offsetWidth !== dataGridWidth.current

        const checkSmallerScreenSizes =
            window.innerWidth < 1280 &&
            !(
                tableOuterContainerEL.current.offsetWidth !==
                dataGridWidth.current + 15
            )
        if (
            (
                hasGridSizeChanged &&
                !checkSmallerScreenSizes &&
                hasZoomLevelChanged
            ) || (
                hasGridSizeChanged &&
                caseFilterOpen
            )
        ) {
            setIsResizing(true)
            if (resizeTimerRef.current) clearTimeout(resizeTimerRef.current)
            resizeTimerRef.current = setTimeout(handleExpandableStuff, 250)
        }
    })

    return {
        handleExpandableStuff,
        isResizing,
        setIsResizing,
        overflowColumns,
        tableOuterContainerEL,
        resizeObserver,
        resizeTimerRef,
    }
}

export default useDataGridSlim

import React from 'react'
import { Snackbar } from '@mui/material'
import { Alert } from '@mui/material'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'

export interface alertSnackbarContentProps {
    title?: string
    message?: string | { label: string; text: string }[]
    intent?: 'error' | 'info' | 'success' | 'warning'
}

export interface alertSnackbarProps {
    content: alertSnackbarContentProps
    open: boolean
    onClose: (e: any) => void
    autoHideDuration?: number
    showCloseIcon?: boolean
    position?: {
        vertical: 'bottom' | 'top'
        horizontal: 'center' | 'left' | 'right'
    }
    testId?: string
}

const AlertSnackbar = ({
    open,
    content,
    onClose,
    autoHideDuration = 3_000,
    showCloseIcon,
    position,
    testId = '',
}: alertSnackbarProps): React.ReactElement => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{
                vertical: position?.vertical ?? 'bottom',
                horizontal: position?.horizontal ?? 'center',
            }}
            onClose={onClose}
            id={`${idDirectory.divSnackbar}${testId && `-${testId}`}`}
        >
            {showCloseIcon ? (
                <Alert
                    severity={content.intent}
                    onClose={onClose}
                    style={{
                        fontFamily: clientTheme.typography.fontFamily.join(','),
                        fontSize: clientTheme.typography.body1.fontSize,
                    }}
                    id={`${idDirectory.divAlert}${testId && `-${testId}`}`}
                >
                    {typeof content.message === 'string' ? (
                        <div
                            id={`${idDirectory.divMsg}${
                                testId && `-${testId}`
                            }`}
                        >
                            {content.message}
                        </div>
                    ) : (
                        <div
                            id={`${idDirectory.divMsg}${
                                testId && `-${testId}`
                            }`}
                        >
                            {/* Note this is currently ONLY for Visa Compelling evidence form. */}
                            {content.message?.map(
                                (
                                    obj: { label: string; text: string },
                                    idx: number
                                ) => {
                                    return (
                                        <div key={idx}>
                                            {obj.label.length ? (
                                                <strong>{obj.label} </strong>
                                            ) : (
                                                ''
                                            )}
                                            {obj.text}
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    )}
                </Alert>
            ) : (
                <Alert
                    severity={content.intent}
                    id={`${idDirectory.divAlert}${testId && `-${testId}`}`}
                >
                    <div id={`${idDirectory.divMsg}${testId && `-${testId}`}`}>
                        {content.message}
                    </div>
                </Alert>
            )}
        </Snackbar>
    )
}

export default AlertSnackbar

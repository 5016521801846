import {
    faCreditCard,
    faTachometerAlt,
    faUsers,
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import { MetricObject } from '../metrics-config'

export const alt_metrics: MetricObject = {
    chargebacks: {
        title: 'Chargebacks',
        timeframe: '(This Month)',
        link: {
            to: '/chargebacks',
            text: 'MTD Reports',
            filter_presets: [],
        },
        icon: faExclamationTriangle,
        variant: 'alt',
        backgroundColor: '#FDDEC5',
        borderTopColor: '#F88D2B',
    },
    caseFraudPercent: {
        title: 'Percentage of Fraudulent Chargebacks',
        timeframe: '(This Month)',
        icon: faTachometerAlt,
        variant: 'alt',
        link: null,
        backgroundColor: '#EFBCBC',
        borderTopColor: '#FF0004',
    },
    chargebacksExpiring: {
        title: 'Users',
        timeframe: null,
        link: {
            to: '/chargebacks',
            text: 'View More',
            filter_presets: [],
        },
        icon: faUsers,
        variant: 'alt',
        backgroundColor: '#D7CFE8',
        borderTopColor: '#714EB1',
    },
    mids: {
        title: 'MIDS',
        timeframe: null,
        link: {
            to: '/chargebacks',
            text: 'View More',
            filter_presets: [
                {
                    filter_name: 'mids_tile_clicked',
                    filter_value: null,
                },
            ],
        },
        icon: faCreditCard,
        variant: 'alt',
        backgroundColor: '#C5DAE4',
        borderTopColor: '#2A7EA0',
    },
}

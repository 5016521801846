/**
 * @description Directory for ID Attributes on UpdateMid.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    labelHeader: `mid-label-header`,
    inputField: `mid-input-field`,
    statusField: `mid-status-field`,
    switchField: `mid-switch-field`,
    radioField: `mid-radio-field`,
    selectField: `mid-select-field`,
    divBulkUpload: `updateMid-div-bulkUpload`,
    btnCancel: `mid-btn-cancel`,
    btnSubmit: `mid-btn-submit`,
}

export default idDirectory

/**
 * @description Directory for ID Attributes on PaginationGrid.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `paginationGrid-div-root`,
    divPageInfo: `paginationGrid-div-pageInfo`,
    divPageResults: `paginationGrid-div-pageResults`,
    divPageSize: `paginationGrid-div-pageSize`,
    divPageNav: `paginationGrid-div-pagenav`,
    divPageNavBackward: `paginationGrid-div-pageNavBackward`,
    divPageNavForward: `paginationGrid-div-pageNavForward`,
    divPageNavDisplay: `paginationGrid-div-pageNavDisplay`,
    divPageNavRefresh: `paginationGrid-div-pageNavRefresh`,
    btnFirst: `paginationGrid-btn-first`,
    btnPrevious: `paginationGrid-btn-previous`,
    btnNext: `paginationGrid-btn-next`,
    btnLast: `paginationGrid-btn-last`,
    btnRefresh: `paginationGrid-btn-refresh`,
}

export default idDirectory

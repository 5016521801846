import React from 'react'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { Skeleton, Box } from '@mui/material'
import { clientTheme } from 'theme-exports'

interface IMerchantAvatarProps {
    className?: string
    loading: boolean
    merchant: {
        name: string
    }
    darkMode?: boolean
}

/**
 * Summary of a merchant in a compact space
 */
const MerchantAvatar = ({
    className,
    loading,
    merchant,
    darkMode = false,
}: IMerchantAvatarProps): React.ReactElement => {

    return (
        <Box
            className={`${'emp-merchantAvatar-root'} ${className}`}
            style={{
                color: clientTheme.primaryDark,
                backgroundColor: clientTheme.mainLayout.backgroundColor,
            }}
            sx={[
                darkMode && {
                    color: '#fff',
                    backgroundColor: clientTheme.primaryDark,
                },
            ]}
        >
            <span className={'emp-merchantAvatar-spaced'}>
                <Icon icon={faBuilding} />
            </span>
            <span>
                {loading ? (
                    <Skeleton
                        className={'emp-merchantAvatar-skeleton'}
                        variant="text"
                    >
                        &nbsp;&nbsp;&nbsp;
                    </Skeleton>
                ) : (
                    merchant?.name
                )}
            </span>
        </Box>
    )
}

export default MerchantAvatar

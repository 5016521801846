import { createTheme } from '@mui/material';
import { clientTheme } from 'theme-exports'
import palette from './palette'
import typography from './typography'
import overrides from './overrides'

const theme = createTheme({
    palette,
    typography,
    ...overrides,
    components: {
        MuiButton: {
            //@ts-ignore
            styleOverrides: {
                ...clientTheme.buttons.styleButton,
            },
        },
    },
    zIndex: {
        appBar: 1200,
        drawer: 1100,
    },
})

export default theme

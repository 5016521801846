import { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import axios from 'axios'
import { AgGridReact } from 'ag-grid-react'
import {
    GetContextMenuItemsParams,
    ProcessCellForExportParams,
} from 'ag-grid-community'
import CB from 'lib'
import { Merchant } from 'lib/Merchants'
import useIsMounted from 'hooks/utils/useIsMounted'
import UtilityApi from 'api/UtilityApi'
import { useActiveMerchant } from 'components/ActiveMerchantContext'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { useAGDataGridActions } from 'hooks/useAGDataGridActions/useAGDataGridActions'
import { IFilterFormField, IFilterOption } from 'components/AGDataGridActionsBar'

export type { Merchant } from 'lib/Merchants'
export type { Mid } from 'lib/Mids'

export type SetActiveNodeId = (nodeId: string) => void

export type SetMerchantSearchValue = (value: string) => void

export type SetForceReload = (value: boolean) => void

export type Deflector = {
    alias: string
    id: number
    name: string
    status: {
        id: number
        name: string
    }
}

export const deflectorStatusIdToText = (statusId: number) => {
    // Statuses 1: Inactive - 2: Pending Activation - 3: Active
    return statusId === 2
        ? 'Pending Activation'
        : statusId === 3
        ? 'Active'
        : 'Inactive'
}

interface MerchantSettingsVM {
    merchants: Merchant[]
    selectedMerchantId: number | null
    setSelectedMerchantId: (id: number | null) => void
    rootMerchant: Merchant | null
    isMerchantsLoading: boolean
    activeNodeId: string
    setActiveNodeId: SetActiveNodeId
    merchantSearchValue: string
    setMerchantSearchValue: SetMerchantSearchValue
    setForceReload: SetForceReload
    setMerchantTreeStart: (val: number) => void
    setMerchantTreeLimit: (val: number) => void
    merchantTreePagination: {
        start: number
        pageSize: number
        totalEntries: number
        pageCount: number
    }
    wasMidTileClicked: boolean
    midsMerchantId: number
    setWasMidTileClickReset: (val: boolean) => void
    wasMidTileClickReset: boolean
    availableDeflectors: Deflector[]
    merchantsTreeRef: any
    setRefreshDataTree: (value: boolean) => void
    midsGridRef: any
    midsGridName: string
    midsFileName: string
    midsDataSource: any
    midsIsLoading: boolean
    midsPageSize: number
    midsPage: number
    midsPaginationTotal: number
    midsSetLocalPage: (page: number) => void
    midsHandlePageLimitChange: (pageSize: number) => void
    midsHandleSearchField: (value: string) => void
    midsRefreshAGGrid: () => void
    midsFilterFormFields: IFilterFormField[]
    midsFilterValues: { [key: string]: string | number | undefined }
    setMidsFilterValues: (filter: {
        [key: string]: string | number | undefined
    }) => void
    getContextMenuMidsItems: (params: GetContextMenuItemsParams) => void
    openAlertActionsModal: boolean
    setOpenAlertActionsModal: (status: boolean) => void
    isAllMidsSelected: boolean
    setIsAllMidsSelected: (isChecked: boolean) => void
    midsHandleFormatExportCells: (params: ProcessCellForExportParams) => void
    usersGridRef: any
    usersGridName: string
    usersFileName: string
    usersDataSource: any
    usersIsLoading: boolean
    usersPageSize: number
    usersPage: number
    usersPaginationTotal: number
    usersSetLocalPage: (page: number) => void
    usersHandlePageLimitChange: (pageSize: number) => void
    usersHandleSearchField: (value: string) => void
    usersFilterFormFields: IFilterFormField[]
    usersFilterValues: { [key: string]: string | number | undefined }
    setUsersFilterValues: (filter: {
        [key: string]: string | number | undefined
    }) => void
    getContextMenuUsersListItems: (params: GetContextMenuItemsParams) => void
    enableBacktoParentMerchant: boolean
    disableAlertActions: boolean
    setDisableAlertActions: (status: boolean) => void
}

export const useMerchantSettings = (props: any): MerchantSettingsVM => {
    const { user } = useAuthedUser()
    const { id } = useActiveMerchant()
    const { DEFLECTORS } = useFeatureToggle('MERCHANT_HIERARCHY')
    const isReadOnly = user?.is_read_only

    const midsGridRef = useRef<AgGridReact>(null)
    const usersGridRef = useRef<AgGridReact>(null)
    const merchantsTreeRef = useRef<AgGridReact>(null)

    const midsGridName = 'mids'
    const midsFileName = 'mids'
    const usersGridName = 'usersList'
    const usersFileName = 'users-list'

    const [activeNodeId, setActiveNodeId] = useState(
        `merchant-${user?.merchant?.id ?? 1}`
    )
    const [rootMerchant, setRootMerchant] = useState<Merchant | null>(null)
    const [merchants, setMerchants] = useState<Merchant[]>([])
    const [selectedMerchantId, setSelectedMerchantId] = useState<number | null>(
        null
    )
    const [isMerchantsLoading, setIsMerchantsLoading] = useState<boolean>(false)
    const [merchantSearchValue, setMerchantSearchValue] = useState<string>('')

    const [wasMidTileClicked, setWasMidTileClicked] = useState<boolean>(false)
    const [wasMidTileClickReset, setWasMidTileClickReset] = useState<boolean>(
        false
    )
    const [forceReload, setForceReload] = useState<boolean>(false)
    const [refreshDataTree, setRefreshDataTree] = useState<boolean>(false)

    const [merchantTreeStart, setMerchantTreeStart] = useState<number>(0)
    const [merchantTreeLimit, setMerchantTreeLimit] = useState<number>(20)
    const [merchantTreePagination, setmerchantTreePagination] = useState({
        start: 0,
        limit: merchantTreeLimit,
        total: 0,
    })
    const [availableDeflectors, setAvailableDeflectors] = useState<Deflector[]>(
        []
    )
    const [openAlertActionsModal, setOpenAlertActionsModal] = useState<boolean>(
        false
    )
    const [isAllMidsSelected, setIsAllMidsSelected] = useState<boolean>(false)
    const [disableAlertActions, setDisableAlertActions] = useState<boolean>(
        false
    )

    const rootMerchantId = rootMerchant?.id
    const userId = user?.id
    const activeNodeIdActual = +/[0-9]+$/.exec(activeNodeId)![0] || 1

    const [midsFilterValues, setMidsFilterValues] = useState<{
        [key: string]: any
    }>({})
    const [usersFilterValues, setUsersFilterValues] = useState({})
    const [platformList, setPlatformList] = useState<IFilterOption[]>([
        { id: 0, name: 'Select All', value: undefined },
    ])

    const midsTileClicked = props.location.state?.preset_filter.find(
        (filter: any) => filter.filter_name === 'mids_tile_clicked'
    )
        ? true
        : false

    useEffect(() => {
        if (midsTileClicked && user && +id !== user?.merchant?.id) {
            setWasMidTileClicked(true)
        }
    }, [setWasMidTileClicked, midsTileClicked, id, user])

    const { isMounted } = useIsMounted()

    useEffect(() => {
        UtilityApi.getProcessors()
            .then(({ data }) => {
                const formattedPlatformList = data.map(
                    (data: { id: number; alias: string; name: string }) => {
                        return {
                            id: data.id,
                            name: data.name,
                            value: data.id,
                        }
                    }
                )
                setPlatformList([
                    { id: 0, name: 'Select All', value: undefined },
                    ...formattedPlatformList,
                ])
            })
            .catch((err) => err)
    }, [])

    useEffect(() => {
        if (user && user.merchant) {
            CB.merchants.get(user.merchant.id.toString()).then((r) => {
                if (isMounted.current) setRootMerchant(r)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, isMounted])

    useEffect(() => {
        if (rootMerchant) {
            // Merchant Tree data
            if (!rootMerchant) return
            setIsMerchantsLoading(true)
            setMerchants([])
            axios
                .get('/cm/merchants/list', {
                    params: {
                        merchant_id:
                            selectedMerchantId && !merchantSearchValue
                                ? selectedMerchantId.toString()
                                : rootMerchant.id.toString(),
                        start: merchantTreeStart,
                        limit: merchantTreeLimit,
                        sort_order: 'asc',
                        sort_by: 'business_name',
                        search: merchantSearchValue
                            ? merchantSearchValue
                            : undefined,
                        search_merchant_id:
                            midsTileClicked &&
                            +id !== rootMerchant.id &&
                            !wasMidTileClickReset
                                ? +id
                                : null,
                    },
                })
                .then((res) => {
                    if (isMounted.current) {
                        setMerchants(res.data.data)
                        setmerchantTreePagination(res.data.pagination)
                    }
                })
                .finally(() => {
                    if (isMounted.current) setIsMerchantsLoading(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        id,
        midsTileClicked,
        wasMidTileClickReset,
        rootMerchantId,
        merchantSearchValue,
        forceReload,
        isMounted,
        merchantTreeStart,
        merchantTreeLimit,
        refreshDataTree,
        // selectedMerchantId,
    ])

    useEffect(() => {
        if (DEFLECTORS.enabled) {
            axios.get('/cm/gen/deflectors').then((res) => {
                setAvailableDeflectors(res.data.data)
            })
        }
    }, [DEFLECTORS.enabled])

    const midsFilterValuesMemo = useMemo(() => {
        if (!DEFLECTORS.enabled) return midsFilterValues

        const formattedFilterValues: {
            [key: string]: any
        } = {}

        // Needed to format deflectors for request endpoint.
        for (const key in midsFilterValues) {
            const selectedDeflector = availableDeflectors.find(
                (deflector) => deflector.alias === key
            )

            if (selectedDeflector && midsFilterValues[key]) {
                if (formattedFilterValues.deflector) {
                    formattedFilterValues.deflector = [
                        ...formattedFilterValues.deflector,
                        selectedDeflector.id,
                    ]
                    formattedFilterValues.deflector_status = [
                        ...formattedFilterValues.deflector_status,
                        midsFilterValues[key],
                    ]
                } else {
                    formattedFilterValues.deflector = [selectedDeflector.id]
                    formattedFilterValues.deflector_status = [
                        midsFilterValues[key],
                    ]
                }
            } else {
                formattedFilterValues[key] = midsFilterValues[key]
            }
        }
        return {
            ...formattedFilterValues,
            ...(formattedFilterValues.deflector && {
                deflector: String(formattedFilterValues.deflector),
            }),
            ...(formattedFilterValues.deflector_status && {
                deflector_status: String(
                    formattedFilterValues.deflector_status
                ),
            }),
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [midsFilterValues])

    const midsHandleFormatExportCells = (params: any) => {
        const deflectorAlias = availableDeflectors.map(
            (deflector) => deflector.alias
        )
        if (deflectorAlias.includes(params.column.colId)) {
            const currentDeflectorCellStatusId = params.node.data.deflectors.find(
                (deflector: Deflector) =>
                    deflector.alias === params.column.colId
            )?.status?.id

            return deflectorStatusIdToText(currentDeflectorCellStatusId)
        }
        return params.value
    }
    

    const {
        dataSource: midsDataSource,
        isLoading: midsIsLoading,
        pageSize: midsPageSize,
        page: midsPage,
        paginationTotal: midsPaginationTotal,
        setLocalPage: midsSetLocalPage,
        handlePageLimitChange: midsHandlePageLimitChange,
        handleSearchField: midsHandleSearchField,
        csvContextMenuItem: midsCsvContextMenuItem,
        excelContextMenuItem: midsExcelContextMenuItem,
        refreshAGGrid: midsRefreshAGGrid,
        resetDataGridContextMenuItem: midsResetDataGridContextMenuItem,
    } = useAGDataGridActions(
        `/cm/merchants/${activeNodeIdActual}/mids`,
        midsGridRef,
        activeNodeIdActual,
        midsFilterValuesMemo,
        {
            defaultLimit: 50,
            handleFormatExportCells: midsHandleFormatExportCells,
        }
    )

    const midsFilterFormFields: IFilterFormField[] = [
        {
            label: 'MID',
            accessor: 'mid',
            inputType: 'text',
        },
        {
            label: 'Alias',
            accessor: 'alias',
            inputType: 'text',
        },
        {
            label: 'Descriptor',
            accessor: 'descriptor',
            inputType: 'text',
        },
        {
            label: 'Platform',
            accessor: 'processor',
            inputType: 'select',
            selectList: platformList,
        },
        {
            label: 'MCC',
            accessor: 'mcc',
            inputType: 'text',
        },
        {
            label: 'CAID',
            accessor: 'caid',
            inputType: 'text',
        },
        {
            label: 'Service Level',
            accessor: 'service_level',
            inputType: 'select',
            selectList: [
                { id: 0, name: 'Select All', value: undefined },
                { id: 1, name: 'Basic Service', value: 1 },
                { id: 2, name: 'Self Service', value: 2 },
                { id: 3, name: 'Full Service', value: 3 },
                { id: 5, name: 'Pro Service', value: 5 },
            ],
        },
        {
            label: 'Date Onboarded',
            accessor: 'date_created',
            inputType: 'date',
        },
    ]

    const deflectorFilterFormFields: IFilterFormField[] = availableDeflectors?.map(
        (deflector: Deflector) => {
            return {
                label: deflector.name,
                accessor: deflector.alias,
                inputType: 'select',
                selectList: [
                    {
                        id: 0,
                        name: 'Select All',
                        value: undefined,
                    },
                    { id: 1, name: 'Inactive', value: 1 },
                    { id: 2, name: 'Pending Activation', value: 2 },
                    { id: 3, name: 'Active', value: 3 },
                ],
            }
        }
    )

    const formattedMidsFilterFormFields = DEFLECTORS.enabled
        ? [...midsFilterFormFields, ...deflectorFilterFormFields]
        : midsFilterFormFields

    const getContextMenuMidsItems = useCallback(
        (params: GetContextMenuItemsParams) => {
            return [
                {
                    name: `Alert Actions`,
                    action: () => {
                        setOpenAlertActionsModal(true)
                    },
                    disabled:
                        Boolean(
                            !midsGridRef.current!.api.getSelectedRows().length
                        ) ||
                        isReadOnly ||
                        disableAlertActions,
                },
                'separator',
                midsResetDataGridContextMenuItem(midsGridName),
                'separator',
                'copy',
                midsCsvContextMenuItem(midsFileName),
                midsExcelContextMenuItem(midsFileName),
            ]
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [availableDeflectors, disableAlertActions, isReadOnly]
    )

    const usersFilterValuesMemo = useMemo(() => {
        return { merchant_id: activeNodeIdActual, ...usersFilterValues }
    }, [activeNodeIdActual, usersFilterValues])

    const {
        dataSource: usersDataSource,
        isLoading: usersIsLoading,
        pageSize: usersPageSize,
        page: usersPage,
        paginationTotal: usersPaginationTotal,
        setLocalPage: usersSetLocalPage,
        handlePageLimitChange: usersHandlePageLimitChange,
        handleSearchField: usersHandleSearchField,
        csvContextMenuItem: usersCsvContextMenuItem,
        excelContextMenuItem: usersExcelContextMenuItem,
        resetDataGridContextMenuItem: usersResetDataGridContextMenuItem,
    } = useAGDataGridActions(
        'users/users',
        usersGridRef,
        activeNodeIdActual,
        usersFilterValuesMemo,
        {}
    )

    const usersFilterFormFields: IFilterFormField[] = [
        {
            label: 'Role',
            accessor: 'role',
            inputType: 'text',
        },
        {
            label: 'First Name',
            accessor: 'fname',
            inputType: 'text',
        },
        {
            label: 'Last Name',
            accessor: 'lname',
            inputType: 'text',
        },
        {
            label: 'Username',
            accessor: 'username',
            inputType: 'text',
        },
        {
            label: 'User Status',
            accessor: 'status',
            inputType: 'text',
        },
    ]

    const getContextMenuUsersListItems = useCallback(
        (params: GetContextMenuItemsParams) => {
            return [
                {
                    name: `Assign to User's (All Merchants)`,
                    action: () => {
                        console.log(
                            'Assign to User- assignAllMerchantsToUser()'
                        )
                    },
                },
                {
                    name: `Remove Permissions (All Merchants)`,
                    action: () => {
                        console.log(
                            'Remove Permissions- removeAllMerchantsFromUser()'
                        )
                    },
                },
                {
                    name: `Assign to Merchant (All MIDS)`,
                    action: () => {
                        console.log('Assign to Merchant- assignAllMidsToUser()')
                    },
                },
                {
                    name: `Remove Permissions (All MIDS)`,
                    action: () => {
                        console.log(
                            'Remove Permissions- removeAllMidsFromUser()'
                        )
                    },
                },
                'separator',
                usersResetDataGridContextMenuItem(usersGridName),
                'separator',
                'copy',
                usersCsvContextMenuItem(usersFileName),
                usersExcelContextMenuItem(usersFileName),
            ]
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    return {
        activeNodeId,
        rootMerchant,
        merchants,
        selectedMerchantId,
        setSelectedMerchantId,
        isMerchantsLoading,
        merchantSearchValue,
        setMerchantSearchValue,
        setActiveNodeId,
        setForceReload,
        setMerchantTreeStart,
        setMerchantTreeLimit,
        merchantTreePagination: {
            start: merchantTreePagination.start,
            pageSize: merchantTreePagination.limit,
            totalEntries: merchantTreePagination.total,
            pageCount: Math.ceil(
                merchantTreePagination.total / merchantTreePagination.limit
            ),
        },
        wasMidTileClicked,
        midsMerchantId: +id ?? undefined,
        setWasMidTileClickReset,
        wasMidTileClickReset,
        availableDeflectors,
        merchantsTreeRef,
        setRefreshDataTree,
        midsGridRef,
        midsGridName,
        midsFileName,
        midsDataSource,
        midsIsLoading,
        midsPageSize,
        midsPage,
        midsPaginationTotal,
        midsSetLocalPage,
        midsHandlePageLimitChange,
        midsHandleSearchField,
        midsRefreshAGGrid,
        midsFilterFormFields: formattedMidsFilterFormFields,
        midsFilterValues,
        setMidsFilterValues,
        getContextMenuMidsItems,
        openAlertActionsModal,
        setOpenAlertActionsModal,
        isAllMidsSelected,
        setIsAllMidsSelected,
        midsHandleFormatExportCells,
        usersGridRef,
        usersGridName,
        usersFileName,
        usersDataSource,
        usersIsLoading,
        usersPageSize,
        usersPage,
        usersPaginationTotal,
        usersSetLocalPage,
        usersHandlePageLimitChange,
        usersHandleSearchField,
        usersFilterFormFields,
        usersFilterValues,
        setUsersFilterValues,
        getContextMenuUsersListItems,
        enableBacktoParentMerchant: !Boolean(
            rootMerchant?.id === selectedMerchantId || !selectedMerchantId
        ),
        disableAlertActions,
        setDisableAlertActions,
    }
}

export default useMerchantSettings

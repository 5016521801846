import React from 'react'
import VisaCEAddUndistputedTransactions from './VisaCEAddUndisputedTransactions'
import VisaCETopSection from './VisaCETopSection'
import VisaCEUndisputedTransactionForm from './VisaCEUndisputedTransactionForm'
import MatchingTransactionDetails from './MatchingTransactionDetails'
import VisaCESummary from './VisaCESummary'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import useVisaCE from './VisaCE.vm'
import { AlertSnackbar, LoadingIndicator, StandardModal } from 'components'

interface VisaCEProps {
    caseId: number
    open: boolean
    setCompellingEvidenceModalOpen: (open: boolean, closeRepresentment?: boolean) => void
}

const VisaCE = ({
    caseId,
    open = false,
    setCompellingEvidenceModalOpen,
} : VisaCEProps) => {
    const {
        caseDetails,
        isSubmitting,
        isCompleted,
        submitDisabled,
        currencies,
        countries,
        matchingTransactionDetails,
        undisputedCases,
        alertSnackbarOpen,
        shippingInfo,
        addUndisputedCases,
        setShippingInfo,
        deleteUndisputedCases,
        alertSnackbarProps,
        setAlertSnackbarOpen,
        formStep,
        primaryButtonText,
        handlePrimaryButtonClick,
        handleBackButtonClick,
        handlelMatchingTransactionDetails,
        isCompleteEnabled,
        setIsCompleteEnabled,
        setSubmitDisabled,
        sendNewRelicEvent,
    } = useVisaCE({
        caseId,
        open,
        setCompellingEvidenceModalOpen,
    })

    return (
        <StandardModal
            openModal={open}
            onModalClose={() => {
                sendNewRelicEvent('onClick', 'Compelling Evidence Modal Closed')
                setCompellingEvidenceModalOpen(false)
            }}
            modalHeaderTitle={'CE 3.0 Remedy Assistant'}
            testId={'visaCEModal'}
            maxModalWidth={'md'}
            primaryBtnText={primaryButtonText}
            secondaryBtnText={'Back'}
            handlePrimaryActionBtn={handlePrimaryButtonClick}
            inactivatePrimaryActionBtn={submitDisabled || isSubmitting || isCompleted}
            inactivateSecondaryActionBtn={isSubmitting}
            handleSecondaryActionBtn={handleBackButtonClick}
            className={`emp-visaCE-modal`}
            // primaryBtnHasTooltip
            // primaryBtnInactiveTooltipMsg={`Complete required fields above to continue`}
        >
            {isCompleted ?
                <div className={`emp-visaCE-isCompletedContainer`}>
                    <Icon
                        icon={faCheckCircle}
                        className={`emp-visaCE-isCompletedIcon`}
                    />
                    Evidence Submitted Successfully
                </div>
            : <></>}
            {isSubmitting && !isCompleted ?
                <div className={`emp-visaCE-loadingIndicatorContainer`}>
                    <LoadingIndicator text="Please wait while we submit the evidence." />
                </div>
            :<></>}
            {!isSubmitting && !isCompleted ?
                <>
                    {formStep < 5 ?
                        <>
                            <VisaCETopSection
                                caseDetails={caseDetails}
                                formStep={formStep}
                                shippingInfo={shippingInfo}
                                setShippingInfo={setShippingInfo}
                                setSubmitDisabled={setSubmitDisabled}
                            />
                            {formStep >= 2 && formStep <= 4 &&
                                <>
                                    <div className='emp-visaCE-section-header'>
                                        <div>Undisputed Transactions</div>
                                        <div>Locate two or more transactions that were processed on the same MID, at least 120 days prior (but not more than 365) and weren’t disputed.</div>
                                    </div>
                                    {formStep >= 3 && formStep <= 4 &&
                                        <>
                                            {undisputedCases.map((caseInfo: any, idx: number) => {
                                                if (formStep === 3 && idx > 0) return <div key={idx}></div>
                                                return (
                                                    <div key={idx}>
                                                        <VisaCEUndisputedTransactionForm
                                                            countries={countries}
                                                            caseInfo={caseInfo}
                                                            currencies={currencies}
                                                            deleteUndisputedCases={deleteUndisputedCases}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </>
                                    }
                                    {formStep >= 2 && formStep <= 3  && (
                                        <>
                                            <VisaCEAddUndistputedTransactions
                                                caseDetails={caseDetails}
                                                undisputedCases={undisputedCases}
                                                setUndisputedCases={addUndisputedCases}
                                                currencies={currencies}
                                                formStep={formStep}
                                                setSubmitDisabled={setSubmitDisabled}
                                            />
                                        </>
                                    )}
                                </>
                            }
                            {formStep === 4 && 
                                <MatchingTransactionDetails
                                    countries={countries}
                                    matchingTransactionDetails={matchingTransactionDetails}
                                    handlelMatchingTransactionDetails={handlelMatchingTransactionDetails}
                                    setSubmitDisabled={setSubmitDisabled}
                                    sendNewRelicEvent={sendNewRelicEvent}
                                />
                            }
                        </>
                    :
                        <VisaCESummary
                            caseDetails={caseDetails}
                            shippingInfo={shippingInfo}
                            countries={countries}
                            undisputedCases={undisputedCases}
                            matchingTransactionDetails={matchingTransactionDetails}
                            isCompleteEnabled={isCompleteEnabled}
                            setIsCompleteEnabled={setIsCompleteEnabled}
                        />
                    }
                </>
            : <></>}
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => {
                    setAlertSnackbarOpen(false)
                }}
                showCloseIcon
                autoHideDuration={10_000}
            />
        </StandardModal>
    )
}

export default VisaCE
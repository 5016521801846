import React, { useEffect, useState } from 'react'
import { useActiveMerchant } from 'components/ActiveMerchantContext'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { withRequiredRole } from 'components/useRequireRole'
import EntFeature from 'models/EntFeature'
import axios from 'axios'
import md5 from 'md5'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'

// --- We now have 2 iframe domains. This is a temporary workaround, until we can get this on a service.
const getIFrameHost = () => {
    const hostSubDomain = window.location.hostname.split('.')[0]
    const devDomainsIdentifiers = [
        '-sbx',
        '-stg',
        '-dev',
        '-qa',
        '-uat',
        '-demo',
        'localhost',
        'devtools'
    ]
    const isDev = devDomainsIdentifiers.some(v => hostSubDomain.indexOf(v) >= 0)
    return `${isDev && 'dev-'}embed.merchant-dispute.com`
}

const AlertsIFrame: React.FC = () => {
    const [configData, setConfigData] = useState<any>(undefined)
    const [loading, setLoading] = useState<boolean>(true)
    const { id: merchantId } = useActiveMerchant()
    const { client } = useFeatureToggle('CLIENT')
    const iframeHost = getIFrameHost()
    const themeNameToSend = client === '' ? 'default' : client
    const { user } = useAuthedUser()

    useEffect(() => {
        if ( merchantId && user ) {
            axios.get(`/cm/merchants/${merchantId}/express-resolve`)
            .then((res) => {
                setConfigData(res.data)
            })
            .catch(() => {})
            .finally(() => setLoading(false))
        }
    }, [merchantId, user])

    if (loading) return <></>

    const {
        ers_client_id: ersMerchantId,
        token
    } = configData

    const tmpID = '48875'
    const tmpHash = md5(`com.expressresolve.partner${tmpID}`)
    const hostname = document.location.host
    const tmpLink = user?.merchant?.id === 409 ?
        `https://dev-deflector.merchant-dispute.com/deflector/preview?client_id=${tmpID}&type=client&verify=${tmpHash}&embedded=1&user_id=1&redirect_url=${hostname}`
    :
        `https://${iframeHost}/dispute-inquiries?embedded=1&user_id=1&client_id=${ersMerchantId}&verify=${token}&theme=${themeNameToSend}&redirect_url=${hostname}`

    return (
        <div className={'emp-alertsIframe-root'}>
             <iframe
                className={'emp-alertsIframe-container'}
                src={tmpLink}
                title='Alerts'
            >
                <div>Loading alerts...</div>
            </iframe>
        </div>
    )
}

export default withRequiredRole(EntFeature.ALERTS, AlertsIFrame)

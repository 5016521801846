/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-key */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    Snackbar,
} from '@mui/material'
import { useTable, Row, useAbsoluteLayout, useColumnOrder } from 'react-table'
import idDirectory from './idAttributes'
import { DragDropContext, Droppable, Draggable, DroppableProvided, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import {
    ContextMenu,
    LoadingIndicator,
    ExpandableRow,
    Alert,
    PaginationGrid,
    AlertSnackbar,
} from 'components'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import useDataGridSlim from './DataGridSlim.vm'
import { ExtendedColumn } from './ExtendedColumn'
import { clientTheme } from 'theme-exports'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'

interface DataGridProps {
    className?: string
    data?: any[]
    columns?: ExtendedColumn[]
    searchable?: boolean
    paginate?: boolean
    slim?: boolean
    loading?: boolean
    /** TODO - Find and remove all instances of this being used. pageSize now determines default. */
    defaultRows?: 5 | 10 | 25 | 50 | 100
    rowsPerPageOptions?: number[]
    /**Use to turn on every row's expansion area */
    expandable?: boolean
    /** id of the DataGrid table to be used to target it for printing or other actions */
    printId?: string
    /** returns the page number switched to in the pagination back to the parent (for server side pagination) */
    onPageChange?: any
    /** data length */
    totalEntries: number
    /** returns the page limit input value back to the parent (for server side pagination) */
    onPageLimitChange: any
    pageSize?: number
    /** toggles the checkboxes (you will not be able to use the context menu multi-select with checkboxes off - the context menu will only operate on the right-clicked row) */
    noSelection?: boolean
    /** context menu item */
    contextMenuItems?: ContextMenuItem[]
    /** toggles the context menu on or off */
    contextMenu?: boolean
    hiddenColumns?: string[]
    hiddenColumnsEnabled?: boolean
    stickyHeaders?: boolean
    page?: number
    /** Apply this to grids that we know are likely going to container horizontal overflow. */
    fixedLoadingIndicator?: boolean
    // Allows you to clear all selected rows
    clearSelectedRows?: boolean
    selectAllRowsInStore?: (rowIndexList: string[]) => void
    unselectAllRowsInStore?: () => void
    enableDataDropdown?: Boolean
    dropdownData?: object
    disableSelectAllCheckbox?: boolean
    allowOnlyOneSelectCheckbox?: boolean
    hideRowCheckboxes?: boolean
    testId?: string
    handleRowSelection?: (caseId: string) => void
    rowSelections?: string[]
    // Use if resetting pagination to page 1 and NOT using SWR
    resetPagination?: boolean
    handleResetPagination?: (val: boolean) => void
    enablePaginationRefreshBtn?: boolean
    refreshDataGrid?: () => void
}

interface ContextMenuItem {
    /** display value */
    value: string
    icon?: string
    isDisabled?: boolean
    /** menu item onclick action */
    operator: (selectedRows: ContextMenuRowData) => void
}

interface ContextMenuState {
    mouseX: null | number
    mouseY: null | number
}

interface ContextMenuRowData {
    /** row id's selected in the datagrid */
    selectedRows: any[]
    /** use row id's to access the inteded row(s) from the array */
    rows: any[]
}

const RowCheckbox: any = ({ ...rest }: any) => (
    <input className="row-select-checkbox" {...rest} />
)

// default context menu mouse position on load
const defaultMousePosition = {
    mouseX: null,
    mouseY: null,
}

/**
 *
 * Flexible data grid for both consuming myriad of data and displaying as a table
 * @remarks
 * Need to add keyboard accessibility
 *
 * @beta - Refine
 * @param data - row data
 * @param columns - column definitions of the data grid
 * @param slim - if true decreases margins and paddings of the cells
 * @param loading - data loading state
 * @param expandable - if true turns on expandable rows
 * @param printId - id of the DataGrid table to be used to target it for printing or other actions
 * @param onPageChange - returns the page number switched to in the pagination back to the parent (for server side pagination)
 * @param totalEntries - entire data length
 * @param noSelection - toggles the checkboxes (you will not be able to use the context menu multi-select with checkboxes off)
 * @param contextMenuItems - array of objects used to define the ContextMenu options
 * @param contextMenu - toggles the ContextMenu on and off
 */
export const DataGridSlim: React.FC<DataGridProps> = 
    ({
        className = '',
        data = [],
        columns = [],
        slim = false,
        loading = true,
        rowsPerPageOptions,
        totalEntries,
        expandable = false,
        printId = '',
        onPageChange,
        onPageLimitChange,
        noSelection = false,
        pageSize = 50,
        contextMenuItems = [],
        hiddenColumns = [],
        hiddenColumnsEnabled = false,
        contextMenu = false,
        paginate = true,
        stickyHeaders = true,
        page = 0,
        fixedLoadingIndicator = false,
        selectAllRowsInStore = () => {},
        unselectAllRowsInStore = () => {},
        enableDataDropdown = false,
        dropdownData = {},
        disableSelectAllCheckbox = false,
        allowOnlyOneSelectCheckbox = false,
        testId = '',
        rowSelections,
        handleRowSelection = () => {},
        hideRowCheckboxes = false,
        resetPagination = false,
        handleResetPagination = () => {},
        enablePaginationRefreshBtn = true,
        refreshDataGrid = () => {},
    }) => {
        const { ENABLE_HEADER_WRAP } = useFeatureToggle('DATAGRID')
        const [
            contextMenuMousePosition,
            setMousePosition,
        ] = useState<ContextMenuState>(defaultMousePosition)
        const [loadingRefreshBtn, setLoadingRefreshBtn] = useState<boolean>(
            false
        )
        const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(
            false
        )
        const [
            alertSnackbarProps,
            setAlertSnackbarProps,
        ] = useState<alertSnackbarContentProps>({})

        const {
            handleExpandableStuff,
            isResizing,
            setIsResizing,
            overflowColumns,
            tableOuterContainerEL,
            resizeObserver,
            resizeTimerRef,
        } = useDataGridSlim(expandable)

        // used to pass reference to table for print action
        const ref = useRef<HTMLTableElement>(null)
      
        const [checkAllCheckboxState, setCheckAllCheckboxState] = useState<boolean>(false)
        
        const columnLength = useRef(0)

        useEffect(() => {
            setCheckAllCheckboxState(false)
        }, [totalEntries])

        // page size updates trigger a few actions, we want this memoized to only update if needed
        const pageCount = useMemo(() => totalEntries / pageSize, [
            totalEntries,
            pageSize,
        ])

        React.useEffect(() => {
            const containerEl = tableOuterContainerEL.current as any
            if (!tableOuterContainerEL || !expandable) return
            // TODO [damon] This is an ugly hack to cause the grid to resize itself, upon first render. Find a better way.
            setTimeout(() => {
                resizeObserver.observe(containerEl)
            }, 2000)
            return () => {
                resizeObserver.unobserve(containerEl)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [tableOuterContainerEL, expandable])

        const {
            getTableProps,
            headerGroups,
            rows,
            prepareRow,
            setHiddenColumns,
            setColumnOrder,
            visibleColumns
        } = useTable(
            {
                columns,
                data,
                manualPagination: true,
                initialState: { hiddenColumns },
            } as any,
            useColumnOrder,
            useAbsoluteLayout,
            noSelection
                ? () => {}
                : (hooks: any) => {
                      hooks.visibleColumns.push((columns: any) => [
                          // Let's make a column for selection
                          {
                              id: 'selection',
                              // The header can use the table's getToggleAllRowsSelectedProps method
                              // to render a checkbox
                              Header: (prop: any) => (
                                  <div>
                                      {disableSelectAllCheckbox ||
                                      !columns.length ? null : (
                                          <RowCheckbox
                                              type="checkbox"
                                              checked={checkAllCheckboxState}
                                              style={{
                                                  transform: 'scale(1.4)',
                                              }}
                                              onClick={(e: any) => {
                                                  handleAllRowSelect(
                                                      e,
                                                      prop.data
                                                  )
                                              }}
                                              onChange={() => {}}
                                          />
                                      )}
                                  </div>
                              ),
                              ...(!hideRowCheckboxes && {
                                  Cell: ({ row }: any) => {
                                      const isChecked = Boolean(
                                          rowSelections?.filter(
                                              (rowId) => rowId === row.id
                                          ).length
                                      )

                                      return (
                                          <div>
                                              <RowCheckbox
                                                  type="checkbox"
                                                  checked={isChecked}
                                                  style={{
                                                      transform: 'scale(1.4)',
                                                  }}
                                                  onChange={(e: any) => {
                                                      if (
                                                          allowOnlyOneSelectCheckbox &&
                                                          rowSelections?.length &&
                                                          !isChecked
                                                      )
                                                          return
                                                      handleRowSelection(row.id)
                                                  }}
                                              />
                                          </div>
                                      )
                                  },
                              }),
                          },
                          ...columns,
                      ])
                  }
        ) as any

        const currentColOrder: any = useRef()

        const getItemStyle = ({ isDragging, isDropAnimating }:{ isDragging: boolean, isDropAnimating: boolean }, draggableStyle: any) => ({
            ...draggableStyle,
            userSelect: 'none',

            backgroundColor: isDragging && "transparent",
            border: isDragging && "none",
         
            ...(!isDragging && { transform: 'translate(0,0)' }),
            ...(isDropAnimating && { transitionDuration: '0.001s' }),   
        })

        useEffect(() => {
            expandable && setIsResizing(true)
            columnLength.current = columns.length
            hiddenColumnsEnabled && setHiddenColumns(hiddenColumns)
            
            if (hiddenColumnsEnabled && expandable) {
                if (resizeTimerRef.current) clearTimeout(resizeTimerRef.current)
                resizeTimerRef.current = setTimeout(handleExpandableStuff, 100)
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [hiddenColumns, setHiddenColumns, hiddenColumnsEnabled, data])

        const handleAllRowSelect = useCallback((event: any, data: any) => {
            const { target } = event
            const { checked } = target

            const caseRows = data

            let selectedRows: string[] = []

            // record checked state on event listener to state in order to toggle the select all checkbox
            setCheckAllCheckboxState(checked)

            // checks if case status_id is equal to New (1) and if so, the case is selectable. Otherwise, not selectable.
            caseRows.forEach((row: any, id: number) => {
                selectedRows.push(id.toString())
            })

            // adds all selected checkboxes to state
            if (checked) {
                // passes max index of caseRows
                selectAllRowsInStore(selectedRows)
            }
            // removes all selected at once
            else {
                unselectAllRowsInStore()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const initRow: Row[] = Array(pageSize).fill(
            columns.map((i) => i.accessor)
        )
        // eslint-disable-next-line no-nested-ternary
        const pageRow = loading ? initRow : rows

        const { style, ...tableRest } = getTableProps()
        const defaultPaginationOptions = [25, 50, 100]
        const paginationOptions = rowsPerPageOptions ?? defaultPaginationOptions

        const contextMenuRowData = useMemo(
            () => ({
                rowSelections, //index
                rows, // original rows
            }),
            [rowSelections, rows]
        )

        const handleContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
            const overlayExists =
                document.getElementsByClassName('MuiDialog-container').length ||
                document.getElementsByClassName('emp-standardPopover-root')
                    .length
            if (overlayExists) return

            event.preventDefault()
            // the offsets are required to render the context menu with it's top left corner positioned at the mouse pointer
            setMousePosition({
                mouseX: event.clientX - 2, // -2px offset
                mouseY: event.clientY - 4, // -4px offset
            })
        }

        // calls the context menu item operator with the selected row data
        const handleClose = (operator?: (selectedRows: any) => any) => {
            typeof operator === 'function' && operator(contextMenuRowData)
            setMousePosition(defaultMousePosition)
        }

        const handleRefreshDataGrid = async () => {
            setLoadingRefreshBtn(true)
            await refreshDataGrid()
            setLoadingRefreshBtn(false)
        }

        return (
            <div
                id={`${idDirectory.dataGridSlim.divRoot}-${testId}`}
                className={`${className} ${isResizing ? 'emp-dataGridSlim-resizing' : ''}`}
            >
                {!loading && expandable && (
                    <div
                        className={`resize-indicator ${'emp-dataGridSlim-resizeIndicator'}`}
                    >
                        <LoadingIndicator />
                    </div>
                )}
                {contextMenu && (
                    <>
                        <ContextMenu
                            handleClose={handleClose}
                            noRowSelections={
                                contextMenuRowData.rowSelections?.length === 0
                            } //flags if no rows selected
                            menuItems={contextMenuItems}
                            mousePosition={contextMenuMousePosition}
                        />
                        <Snackbar
                            autoHideDuration={3000}
                            open={
                                contextMenuRowData.rowSelections?.length ===
                                    0 &&
                                contextMenuMousePosition.mouseY !== null
                            }
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                        >
                            <div>
                                <Alert severity="info">
                                    {'You must select at least one case.'}
                                </Alert>
                            </div>
                        </Snackbar>
                    </>
                )}

                <TableContainer
                     id={`${idDirectory.dataGridSlim.tableContainer}-${testId}`}
                     onContextMenu={handleContextMenu}
                     component={Paper}
                     className={`table-outer-container ${
                         'emp-dataGridSlim-tableContainer'
                     }
                     ${expandable ? 'emp-dataGridSlim-expandableOverflow' : ''}
                     ${(
                        expandable
                        && !isResizing
                        && hideRowCheckboxes
                        && !overflowColumns.length
                     ) ? '_hideArrowAndCBColumns' : ''}`}
                     sx={[
                        {
                            '& .MuiTableCell-head': {
                                lineHeight: '1.2rem',
                                '& > span': {
                                    whiteSpace: ENABLE_HEADER_WRAP?.enabled
                                        ? 'break-spaces'
                                        : 'nowrap',
                                },
                            },
                        },
                    ]}
                     ref={tableOuterContainerEL}
                >
                    <Table
                        size={slim ? 'small' : 'medium'}
                        ref={ref}
                        {...tableRest}
                        id={printId}
                        stickyHeader={stickyHeaders}
                        {...getTableProps()}
                    >
                        <TableHead
                        id={`${idDirectory.dataGridSlim.tableHead}-${testId}`}
                            className={`${'emp-dataGridSlim-tableHeader'} ${
                                stickyHeaders && 'emp-dataGridSlim-stickyHeaders'
                            }`}
                        >
                            {headerGroups.map((headerGroup: any, idx:number) => {
                                const {
                                    style,
                                    ...headerGroupRest
                                } = headerGroup.getHeaderGroupProps()
                                const headers = headerGroup.headers

                                return (
                                    <DragDropContext
                                        key={`key-DnD-${idx}`}
                                        onDragStart={() => {
                                            currentColOrder.current = visibleColumns.map(
                                                (item: any) => item.id
                                            )
                                        }}
                                        onDragUpdate={(dragUpdateObj: any) => {
                                            const colOrder = [
                                                ...currentColOrder.current,
                                            ]
                                            const sIndex =
                                                dragUpdateObj.source.index
                                            const dIndex =
                                                dragUpdateObj.destination &&
                                                dragUpdateObj.destination.index

                                            if (
                                                typeof sIndex === 'number' &&
                                                typeof dIndex === 'number'
                                            ) {
                                                colOrder.splice(sIndex, 1)
                                                colOrder.splice(
                                                    dIndex,
                                                    0,
                                                    dragUpdateObj.draggableId
                                                )
                                                setColumnOrder(colOrder)
                                            }
                                        }}
                                        onDragEnd={() => {}}
                                    >
                                        <Droppable
                                            droppableId="droppable"
                                            direction="horizontal"
                                        >
                                            {(droppableProvided: DroppableProvided) => (
                                                <TableRow
                                                    className={'emp-dataGridSlim-headerRow'}
                                                    {...headerGroup.getHeaderGroupProps()}
                                                    ref={
                                                        droppableProvided.innerRef
                                                    }
                                                >
                                                    {
                                                        expandable && (
                                                            <TableCell
                                                                className={
                                                                    'emp-dataGridSlim-tableHeaderCellWidth'
                                                                }
                                                                sx={[
                                                                    {
                                                                        '& p': {
                                                                            whiteSpace: ENABLE_HEADER_WRAP?.enabled
                                                                                ? 'break-spaces'
                                                                                : 'nowrap',
                                                                        },
                                                                    },
                                                                ]}
                                                            />
                                                        ) // Provide empty header for expandable actions
                                                    }
                                                    {
                                                        enableDataDropdown && (
                                                            <TableCell
                                                                className={
                                                                    'emp-dataGridSlim-tableHeaderCellWidth'
                                                                }
                                                            />
                                                        ) // Provide empty header for dropdown action
                                                    }
                                                    {headers
                                                        .filter((col: any) => {
                                                            return !overflowColumns.includes(
                                                                col.id
                                                            )
                                                        })
                                                        .map(
                                                            (
                                                                col: any,
                                                                idx: number
                                                            ) => {
                                                                return (
                                                                    <Draggable
                                                                        key={
                                                                            col.id
                                                                        }
                                                                        draggableId={
                                                                            col.id
                                                                        }
                                                                        index={
                                                                            idx
                                                                        }
                                                                        isDragDisabled={
                                                                            !col.accessor
                                                                        }
                                                                    >
                                                                        {(
                                                                            provided: DraggableProvided,
                                                                            snapshot: DraggableStateSnapshot
                                                                        ) => {
                                                                            const {
                                                                                style,
                                                                                ...headerRest
                                                                            } = col.getHeaderProps()

                                                                            const renderedTitle =
                                                                                col
                                                                                    .Header
                                                                                    ?.props
                                                                                    ?.value ||
                                                                                col.render(
                                                                                    'Header'
                                                                                )
                                                                            if (
                                                                                typeof renderedTitle ===
                                                                                'object'
                                                                            ) {
                                                                                return (
                                                                                    <TableCell
                                                                                    style={{fontSize: clientTheme.typography.dataGrid.tableHeader.fontSize}}
                                                                                        className={`
                                                                                            ${
                                                                                                'emp-dataGridSlim-tableHeaderCell'
                                                                                            } 
                                                                                            header-column-${idx}
                                                                                         ${
                                                                                             col.id ===
                                                                                                 'selection' &&
                                                                                             'emp-dataGridSlim-tableHeaderCellWidth'
                                                                                         }`}
                                                                                        data-id={
                                                                                            col.id
                                                                                        }
                                                                                        {...headerRest}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        ref={
                                                                                            provided.innerRef
                                                                                        }
                                                                                    >
                                                                                        <span>
                                                                                            {col.render(
                                                                                                'Header'
                                                                                            )}
                                                                                        </span>
                                                                                    </TableCell>
                                                                                )
                                                                            }

                                                                            return (
                                                                                <Tooltip
                                                                                    key={`tooltip-${col.id}`}
                                                                                    title={
                                                                                        renderedTitle
                                                                                    }
                                                                                    placement="top"
                                                                                    arrow
                                                                                    classes={{
                                                                                        tooltip:
                                                                                            'emp-dataGridSlim-tooltip-popper',
                                                                                        arrow:
                                                                                            'emp-dataGridSlim-tooltipPopperArrow',
                                                                                    }}
                                                                                >
                                                                                    <TableCell
                                                                                        id={`${idDirectory.dataGridSlim.tableHeadCell}-${testId}-${col.id}`}
                                                                                        className={`
                                                                                            ${'emp-dataGridSlim-tableHeaderCell'} 
                                                                                            header-column-${idx}
                                                                                        `}
                                                                                        data-id={
                                                                                            col.id
                                                                                        }
                                                                                        priority-id={col.priority}
                                                                                        input-width={col.width}
                                                                                        {...headerRest}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        ref={
                                                                                            provided.innerRef
                                                                                        }
                                                                                        style={{
                                                                                            ...getItemStyle(
                                                                                                snapshot,
                                                                                                provided
                                                                                                    .draggableProps
                                                                                                    .style
                                                                                            ),
                                                                                            fontSize: clientTheme.typography.dataGrid.tableHeader.fontSize,
                                                                                            color: "#545454",
                                                                                            fontWeight: 600,
                                                                                            fontFamily: [
                                                                                                'chargebacks',
                                                                                                'caseManagement',
                                                                                            ].includes(
                                                                                                testId
                                                                                            )
                                                                                                ? 'Helvetica Neue,Helvetica,Arial,sans-serif'
                                                                                                : null,
                                                                                        }}
                                                                                    >
                                                                                        <span>
                                                                                            {col.render(
                                                                                                'Header'
                                                                                            )}
                                                                                        </span>
                                                                                    </TableCell>
                                                                                </Tooltip>
                                                                            )
                                                                        }}
                                                                    </Draggable>
                                                                )
                                                            }
                                                        )}
                                                        {/* {droppableProvided.placeholder} */}
                                                </TableRow>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                )
                            })}
                        </TableHead>
                        <TableBody>
                            {!loading && !loadingRefreshBtn && data.length ? (
                                <>
                                    {pageRow.map((row: any, i: number) => {
                                        prepareRow(row)
                                        const {
                                            style,
                                            key,
                                            ...rowRest
                                        } = row.getRowProps()
                                        return (
                                            <ExpandableRow
                                                key={
                                                    key
                                                        .toString()
                                                        .includes('undefined')
                                                        ? `key-${i}`
                                                        : key
                                                }
                                                rowProps={rowRest}
                                                row={row}
                                                loading={loading}
                                                expandable={expandable}
                                                isRowZebraStriped={i % 2 !== 0}
                                                selectedRow={rowSelections || []}
                                                rowId={i}
                                                overflowColumns={
                                                    overflowColumns
                                                }
                                                enableDataDropdown={enableDataDropdown}
                                                dropdownData={dropdownData}
                                                hiddenColumns={hiddenColumns}
                                                noRowSelectionBox={noSelection}
                                                setAlertSnackbarOpen={setAlertSnackbarOpen}
                                                setAlertSnackbarProps={setAlertSnackbarProps}
                                            />
                                        )
                                    })}
                                </>
                            ) : (
                                <>
                                    {!loading && !loadingRefreshBtn && (
                                        <TableRow>
                                            <TableCell
                                                style={{ textAlign: 'center' }}
                                                colSpan={columns.length}
                                            >
                                                No Records Found!
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {(loading || loadingRefreshBtn) && (
                    <div style={{ height: '20px ' }}>
                        <div
                            className={
                                fixedLoadingIndicator
                                    ? 'emp-dataGridSlim-loadingContainerFixed'
                                    : 'emp-dataGridSlim-loadingContainer'
                            }
                        >
                            <LoadingIndicator />
                        </div>
                    </div>
                )}    
            {paginate && (
                    <PaginationGrid
                        paginationOptions={paginationOptions}
                        loading={loading}
                        pageCount={pageCount}
                        gotoPage={(val: number) => onPageChange(val)} // val comes back from the page block click as a non-zero index because the page blocks return their inner value - which is non-zero indexed for display purposes.
                        setPageSize={onPageLimitChange}
                        totalEntries={totalEntries}
                        page={page}
                        tableState={{
                            pageSize,
                            rowsLength: rows.length,
                        }}
                        clearSelectedRows={unselectAllRowsInStore}
                        testId={testId}
                        resetPagination={resetPagination}
                        handleResetPagination={handleResetPagination}
                        enablePaginationRefreshBtn={enablePaginationRefreshBtn}
                        handlePaginationRefreshBtn={handleRefreshDataGrid}
                        loadingRefreshBtn={loadingRefreshBtn}
                    />
                )}
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
            </div>
        )
    }


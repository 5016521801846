import React from 'react'
import { useParams } from 'react-router'
import EntFeature from 'models/EntFeature'
import { AccentArea, UpdateUser } from 'components'
import { withRequiredRole } from 'components/useRequireRole'
import { useSwrData, User } from 'hooks/cb-api'
import View from '../View'

interface AddEditUserUrlParams {
    userId?: number
}

export const AddEditUserView = ({ ...props }): React.ReactElement => {
    const { userId } = useParams() as AddEditUserUrlParams
    const endpointConfig = {
        paramInUrl: userId ? true : false,
        url: `/users/users/${userId}`,
        id: {
            value: userId?.toString(),
        },
    }

    const { data: user }: { data: User } = useSwrData(endpointConfig)

    return (
        <View title="Add/Edit User" breadcrumb={''}>
            <AccentArea
                title={`${userId ? 'Edit' : 'Add'} User`}
                className={'emp-accentArea'}
            >
                <UpdateUser existingUser={user} />
            </AccentArea>
        </View>
    )
}

export default withRequiredRole(EntFeature.USERS, AddEditUserView)

import BaseService from 'lib/BaseService'

export class AuthService extends BaseService {
    idToken() {
        return this.getIdToken()
    }

    accessToken() {
        return this.getAccessToken()
    }
}

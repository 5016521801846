import axios from 'axios'
import { IReportFilter } from 'views/Reports'

type TReportData = any

type TCurrencyData = {
    id: number
    code: string
    currency: string
    decimal_digits: number | null
    iso: string
    symbol: string
}

type TGetMidsList = (
    clientId: number,
    search?: string,
    limit?: number,
    start?: number
) => Promise<TReportData>
type TGetCurrenciesList = (
    limit?: number,
    search?: string,
    start?: number
) => Promise<TReportData>
type TGetByTransactionMonth = (filterData: IReportFilter) => Promise<TReportData>
type TGetByTransactionAmount = (filterData: IReportFilter) => Promise<TReportData>
type TGetByIssuer = (
    filterData: IReportFilter,
    limit: number
) => Promise<TReportData>
type TGetByReasonCode = (filterData: IReportFilter) => Promise<TReportData>
type TGetByCardType = (filterData: IReportFilter) => Promise<TReportData>
type TGetAverageSpanBetweenTransactionAndPostDate = (
    filterData: IReportFilter
) => Promise<TReportData>

interface IReportsApi {
    getMidsList: TGetMidsList
    getCurrenciesList: TGetCurrenciesList
    getByTransactionMonth: TGetByTransactionMonth
    getByTransactionAmount: TGetByTransactionAmount
    getByIssuer: TGetByIssuer
    getByReasonCode: TGetByReasonCode
    getByCardType: TGetByCardType
    getAverageSpanBetweenTransactionAndPostDate: TGetAverageSpanBetweenTransactionAndPostDate
}

class ReportsApi implements IReportsApi {
    // TODO - Let's see if we can get this normalized in the payloads at some point.
    private handleNoData = (data: any) => {
        const hasData =
            data.filter((row: any) => row?.val > 0 || row?.value > 0).length ||
            false
        return hasData ? data : []
    }

    getMidsList(
        clientId?: number,
        search?: string,
        limit?: number,
        start?: number
    ) {
        const returnData: { name: string; value: number; label: string }[] = []
        return axios
            .get(`/cm/mids`, {
                params: {
                    merchant_id: clientId,
                    limit: limit,
                    start: start,
                    search: search,
                },
            })
            .then(({ data }) => {
                data.data.forEach(
                    (data: { id: number; alias: string; mid: string }) => {
                        returnData.push({
                            name: `${data.alias} - ${data.mid}`,
                            value: data.id,
                            label: `${data.alias} - ${data.mid}`,
                        })
                    }
                )
                return returnData
            })
            .catch(() => returnData)
    }

    getCurrenciesList(limit?: number, search?: string, start?: number) {
        const returnData: { name: string; value: number; label: string }[] = []
        return axios
            .get(`/cm/gen/currencies`, {
                params: {
                    limit: limit,
                    start: start,
                    search: search,
                },
            })
            .then(({ data: { data } }) => {
                data.sort((a: TCurrencyData, b: TCurrencyData) =>
                    a.currency > b.currency ? 1 : -1
                ).forEach((data: TCurrencyData) => {
                    returnData.push({
                        name: `${data.currency} - ${data.iso}`,
                        value: data.id,
                        label: `${data.currency} - ${data.iso}`,
                    })
                })

                // Manually bring USD, GBP and EUR to top of list, before returning
                const priorityCurrencies = returnData.filter(
                    (i) =>
                        i.name.startsWith('USD') ||
                        i.name.startsWith('GBP') ||
                        i.name.startsWith('EUR')
                )

                const otherCurrencies = returnData.filter(
                    (i) =>
                        !i.name.startsWith('USD') &&
                        !i.name.startsWith('GBP') &&
                        !i.name.startsWith('EUR')
                )

                return [...priorityCurrencies, ...otherCurrencies]
            })
            .catch(() => returnData)
    }

    getByTransactionMonth(filterData: IReportFilter) {
        const returnData: { name: string; val: string }[] = []
        return axios
            .get(`/cm/cases/reports/cb-by-trans-month`, {
                params: {
                    ...filterData,
                    mid_id:
                        filterData.mid_id > -1 ? filterData.mid_id : undefined,
                    currency_id:
                        filterData.currency_id > -1
                            ? filterData.currency_id
                            : undefined,
                },
            })
            .then(({ data }) => {
                const totalCount = data.reduce(
                    (acc: number, cur: { name: string; total: number }) =>
                        acc + cur.total || 0,
                    0
                )
                data.forEach((data: { name: string; total: number }) => {
                    const val = ((data.total / totalCount) * 100).toFixed(2)
                    // Remove zero vals per EXT-4674
                    if (val === '0.00') return

                    returnData.push({
                        name: data.name,
                        val,
                    })
                })
                return this.handleNoData(returnData)
            })
            .catch(() => returnData)
    }

    getByTransactionAmount(filterData: IReportFilter) {
        const returnData: { name: string; val: number }[] = []
        return axios
            .get(
                `/cm/cases/reports/percent-chargebacks-by-trans-amount-range`,
                {
                    params: {
                        ...filterData,
                        mid_id:
                            filterData.mid_id > -1
                                ? filterData.mid_id
                                : undefined,
                        currency_id:
                            filterData.currency_id > -1
                                ? filterData.currency_id
                                : undefined,
                    },
                }
            )
            .then(({ data }) => {
                data.forEach((data: { name: string; total: number }) => {
                    returnData.push({
                        name: data.name,
                        val: data.total,
                    })
                })
                return this.handleNoData(returnData)
            })
            .catch(() => returnData)
    }

    getByIssuer(filterData: IReportFilter, limit: number) {
        const returnData: { name: string; value: number }[] = []
        return axios
            .get(`/cm/cases/reports/chargebacks-by-issuer`, {
                params: {
                    ...filterData,
                    mid_id:
                        filterData.mid_id > -1 ? filterData.mid_id : undefined,
                    currency_id:
                        filterData.currency_id > -1
                            ? filterData.currency_id
                            : undefined,
                    limit: limit,
                    label: 'bin',
                },
            })
            .then(({ data }) => {
                data.forEach((data: { name: string; pct: number }) => {
                    returnData.push({
                        name: data.name,
                        value: data.pct,
                    })
                })
                return this.handleNoData(returnData)
            })
            .catch(() => [])
    }

    getAverageSpanBetweenTransactionAndPostDate(filterData: IReportFilter) {
        const returnData: { name: string; val: number }[] = []
        return axios
            .get(`/cm/cases/reports/span-between-post-trans-date`, {
                params: {
                    ...filterData,
                    mid_id:
                        filterData.mid_id > -1 ? filterData.mid_id : undefined,
                    currency_id:
                        filterData.currency_id > -1
                            ? filterData.currency_id
                            : undefined,
                },
            })
            .then(({ data }) => {
                data.forEach((data: { chart_date: string; days: number }) => {
                    returnData.push({
                        name: data.chart_date,
                        val: data.days,
                    })
                })
                return this.handleNoData(returnData)
            })
            .catch(() => returnData)
    }

    getCTRByTransactionDateWeek(filterData: IReportFilter) {
        const returnData: { name: string; val: number }[] = []
        return axios
            .get(
                `/cm/cases/reports/chargeback-transaction-ratio-by-transaction-date-week`,
                {
                    params: {
                        ...filterData,
                        client_id: filterData.merchant_id,
                        mid_id:
                            filterData.mid_id > -1
                                ? filterData.mid_id
                                : undefined,
                        currency_id:
                            filterData.currency_id > -1
                                ? filterData.currency_id
                                : undefined,
                    },
                }
            )
            .then(({ data }) => {
                data.forEach((data: { date: string; days: string }) => {
                    returnData.push({
                        name: data.date,
                        val: +data.days,
                    })
                })
                return this.handleNoData(returnData)
            })
            .catch(() => returnData)
    }

    getByNonUniqueCustomer(filterData: IReportFilter) {
        const returnData: { date: string; value: number; count: number }[] = []
        return axios
            .get(`/cm/cases/reports/cb-non-unique-customers`, {
                params: {
                    ...filterData,
                    mid_id:
                        filterData.mid_id > -1 ? filterData.mid_id : undefined,
                    client_id: filterData.merchant_id,
                    currency_id:
                        filterData.currency_id > -1
                            ? filterData.currency_id
                            : undefined,
                },
            })
            .then(({ data }) => {
                data.forEach(
                    (data: { date: string; ratio: number; total: number }) => {
                        returnData.push({
                            date: data.date,
                            value: +data.ratio,
                            count: +data.total,
                        })
                    }
                )
                return returnData
            })
            .catch(() => returnData)
    }

    getByReasonCode(
        filterData: IReportFilter,
        enableTooltipName: boolean = false
    ) {
        const returnData: {
            name: string
            value: number
            tooltipName?: string
        }[] = []
        return axios
            .get(`/cm/cases/reports/cb-by-reason-code`, {
                params: {
                    ...filterData,
                    mid_id:
                        filterData.mid_id > -1 ? filterData.mid_id : undefined,
                    currency_id:
                        filterData.currency_id > -1
                            ? filterData.currency_id
                            : undefined,
                    limit: 5,
                },
            })
            .then(({ data }) => {
                data.forEach(
                    (data: {
                        code: string
                        description: string
                        count: number
                    }) => {
                        enableTooltipName
                            ? returnData.push({
                                  name: data.code,
                                  value: data.count,
                                  tooltipName: data.code
                                      .trim()
                                      .toLowerCase()
                                      .startsWith('other')
                                      ? `${data.code}`
                                      : `${data.code}: ${data.description}`,
                              })
                            : returnData.push({
                                  name: data.code
                                      .trim()
                                      .toLowerCase()
                                      .startsWith('other')
                                      ? `${data.code}`
                                      : `${data.code}: ${data.description}`,
                                  value: data.count,
                              })
                    }
                )
                return this.handleNoData(returnData)
            })
            .catch(() => returnData)
    }

    getByCardType(filterData: IReportFilter) {
        const returnData: { name: string; value: number }[] = []
        return axios
            .get(`/cm/cases/reports/card-type`, {
                params: {
                    ...filterData,
                    mid_id:
                        filterData.mid_id > -1 ? filterData.mid_id : undefined,
                    currency_id:
                        filterData.currency_id > -1
                            ? filterData.currency_id
                            : undefined,
                    limit: 5,
                },
            })
            .then(({ data }) => {
                data.forEach((data: { name: string; count: number }) => {
                    returnData.push({
                        name: data.name,
                        value: data.count,
                    })
                })
                return this.handleNoData(returnData)
            })
            .catch(() => returnData)
    }

    getByStatus(filterData: IReportFilter) {
        const returnData: { name: string; value: number }[] = []
        return axios
            .get(`/cm/cases/reports/cases-status-totals`, {
                params: {
                    ...filterData,
                    client_id: filterData.merchant_id,
                    mid_id:
                        filterData.mid_id > -1 ? filterData.mid_id : undefined,
                    currency_id:
                        filterData.currency_id > -1
                            ? filterData.currency_id
                            : undefined,
                },
            })
            .then(({ data }) => {
                data.forEach((data: { case_status: string; count: number }) => {
                    returnData.push({
                        name: data.case_status,
                        value: data.count,
                    })
                })
                return this.handleNoData(returnData)
            })
            .catch(() => returnData)
    }

    getByWinLoss(filterData: IReportFilter) {
        const returnData: { name: string; value: number }[] = []
        return axios
            .get(`/cm/cases/reports/verdict`, {
                params: {
                    ...filterData,
                    mid_id:
                        filterData.mid_id > -1 ? filterData.mid_id : undefined,
                    currency_id:
                        filterData.currency_id > -1
                            ? filterData.currency_id
                            : undefined,
                    limit: 5,
                },
            })
            .then(({ data }) => {
                data.forEach((data: { name: string; count: number }) => {
                    returnData.push({
                        name: data.name,
                        value: data.count,
                    })
                })
                return this.handleNoData(returnData)
            })
            .catch(() => returnData)
    }

    getByLocationType(filterData: IReportFilter) {
        const returnData: { name: string; value: number }[] = []

        return axios
            .get(`/cm/cases/reports/location-type`, {
                params: {
                    ...filterData,
                    client_id: filterData.merchant_id,
                    mid_id:
                        filterData.mid_id > -1 ? filterData.mid_id : undefined,
                    currency_id:
                        filterData.currency_id > -1
                            ? filterData.currency_id
                            : undefined,
                    limit: 5,
                },
            })
            .then(({ data }) => {
                data.forEach((data: { name: string; total: string }) => {
                    returnData.push({
                        name: data.name,
                        value: +data.total,
                    })
                })
                return this.handleNoData(returnData)
            })
            .catch(() => returnData)
    }

    getByCardTypePerPlatform(filterData: IReportFilter) {
        
        return axios
            .get(
                `/cm/cases/reports/percent-chargebacks-by-processor-by-cc-type`,
                {
                    params: {
                        ...filterData,
                        mid_id:
                            filterData.mid_id > -1
                                ? filterData.mid_id
                                : undefined,
                        currency_id:
                            filterData.currency_id > -1
                                ? filterData.currency_id
                                : undefined,
                        limit: 4,
                    },
                }
            )
            .then(({ data }) => {
                if (data.length) {
                    let newDataShape: any = {}
                    let valueKeys

                    const dataKeys = Object.keys(data)

                    dataKeys.forEach((key: any) => {
                        data[key].value === 'Others'
                            ? (newDataShape.Others = data[key].count)
                            : (newDataShape[data[key].cc_type_name] =
                                  data[key].count)
                    })

                    // capture value keys for card type entries before the name prop is applied below
                    valueKeys = Object.entries(newDataShape)

                    // hardcoded just for chase
                    newDataShape.name = 'Chase'

                    return [newDataShape, valueKeys]
                } else {
                    return []
                }
            })
            .catch(() => [])
    }

    getByPlatform(filterData: IReportFilter) {
        const returnData: { name: string; value: number }[] = []
        return axios
            .get(`/cm/cases/reports/cases-by-platform`, {
                params: {
                    ...filterData,
                    mid_id:
                        filterData.mid_id > -1 ? filterData.mid_id : undefined,
                    currency_id:
                        filterData.currency_id > -1
                            ? filterData.currency_id
                            : undefined,
                    limit: 5,
                },
            })
            .then(({ data }) => {
                const totalCount = data.reduce(
                    (acc: number, cur: { name: string; count: number }) =>
                        acc + cur.count || 0,
                    0
                )

                data.forEach((data: { name: string; count: number }) => {
                    returnData.push({
                        name: data.name,
                        value: (data.count / totalCount) * 100,
                    })
                })
                return this.handleNoData(returnData)
            })
            .catch(() => returnData)
    }
}

export default new ReportsApi()

import React from 'react'
import { Link } from 'react-router-dom'
import BannerImage from './DisputesDeflectorBanner.png'

export const DisputesDeflectorBanner = () => {
    return (
        <div className={'emp-disputesDeflectorBanner-root'}>
            <Link to="/pre-chargebacks">
                <img src={BannerImage} alt="" />
            </Link>
        </div>
    )
}

export default DisputesDeflectorBanner

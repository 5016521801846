import EntFeature from 'models/EntFeature'
import React from 'react'
import { useRequireRole } from './useRequireRole'


export const withRequiredRole = (role: EntFeature, C: React.FC) => {
    return (props: any) => {
        useRequireRole(role)
        return <C {...props} />
    }
}

import { useEffect, useState, useMemo } from 'react'
import MerchantApi from 'api/MerchantApi'
import UtilityApi from 'api/UtilityApi'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { MerchantInfo } from 'lib/Merchants'
import useIsMounted from 'hooks/utils/useIsMounted'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import { Deflector } from 'views/MerchantHierarchy/MerchantHierarchy.vm'

export type { Merchant } from 'lib/Merchants'
export type { Mid } from 'lib/Mids'

export type TCountryCode = {
    code: string
    id: number
    name: string
    number_code: string
}

type TFeature = {
    id: number
    name: string
}

interface IAddEditMerchantVM {
    inputUtils: {
        register: any
        watch: any
        errors: { [key: string]: any }
        isDirty: boolean
        isValid: boolean
        isSubmitting: boolean
        handleOnChangeValue: (fieldName: string, value: any) => void
        submitForm: () => void
        reset: () => void
        countryCodes: TCountryCode[]
    }
    defaultDeflectorValues: Deflector[]
    // merchantSchema: MerchantSchema
}

interface IAddEditMerchantProps {
    isEdit: boolean
    merchantId: number
    forceReload: () => void
    merchantDetails: MerchantInfo
    merchantParentName: string
    setAlertSnackbarProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarOpen: (status: boolean) => void
    // For AlertSnackbar on main view.
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export type TAddEditMerchantInputs = Partial<{
    merchantBusinessName: string
    merchantParentName: string
    partnerCompanyId: string
    businessAddress: string
    businessRegionOrState: string
    businessZipCode: string
    merchantServiceLevel: string
    businessCity: string
    businessCountry: string
    businessPhone: string
    directAPI?: boolean
    hasIssuerDocs?: boolean
    statusId?: string
    parentId?: string
    fname?: string
    lname?: string
    email?: string
    phone_number?: string
    platform?: string
    // Should the form include the admin user contact form
    addContactInfo?: boolean
    // Is the admin user contact already added
    hasContactInfo?: boolean
    status?: boolean
    deflectors: any[]
    merchantAlias?: string
    features: TFeature[]
}>

// form validation schema
const validationSchema = Yup.object().shape({
    merchantBusinessName: Yup.string().required('Field required!'),
    merchantParentName: Yup.string().required('Field required!'),
    businessAddress: Yup.string().required('Field required!'),
    businessRegionOrState: Yup.string().required('Field required!'),
    businessZipCode: Yup.string().required('Field required!'),
    merchantServiceLevel: Yup.string().required('Field required!'),
    businessCity: Yup.string().required('Field required!'),
    businessCountry: Yup.string().required('Field required!'),
    businessPhone: Yup.string().required('Field required!'),
    addContactInfo: Yup.boolean().default(false),
    hasContactInfo: Yup.boolean().default(false),
    fname: Yup.string().nullable(),
    lname: Yup.string().nullable(),
    email: Yup.string()
        .nullable()
        .when('addContactInfo', {
            is: true,
            then: Yup.string().email('Please enter a valid email.'),
        }),
    phone_number: Yup.string().nullable(),
})

const useAddEditMerchant = ({
    isEdit,
    merchantId,
    forceReload,
    merchantDetails,
    merchantParentName,
    setAlertSnackbarProps,
    setAlertSnackbarOpen,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: IAddEditMerchantProps): IAddEditMerchantVM => {
    const { isMounted } = useIsMounted()
    const [countryCodes, setCountryCodes] = useState<TCountryCode[]>([])

    useEffect(() => {
        UtilityApi.getCountryCodes().then((res: TCountryCode[]) => {
            if (isMounted.current) {
                setCountryCodes(res)
            }
        })
    }, [isMounted])

    const defaultValues = useMemo<TAddEditMerchantInputs>(() => {
        const {
            business_address,
            business_name,
            business_phone,
            city,
            country,
            deflectors,
            parent_id,
            partner_company_id,
            service_level,
            state,
            zip,
            contact,
            status,
            direct_api,
            alias,
            features,
        } = merchantDetails

        if (isEdit) {
            return {
                merchantBusinessName: business_name ?? '',
                merchantParentName: merchantParentName ?? '',
                partnerCompanyId: partner_company_id ?? '',
                businessAddress: business_address ?? '',
                businessRegionOrState: state ?? '',
                businessZipCode: zip ?? '',
                merchantServiceLevel: service_level.id.toString() ?? '',
                businessCity: city ?? '',
                businessCountry: country.code ?? '',
                businessPhone: business_phone ?? '',
                directAPI: direct_api ?? false,
                hasIssuerDocs:
                    features?.some((obj: TFeature) => obj.id === 1) ?? false,
                statusId: '',
                parentId: parent_id ?? '',
                // Admin contact for merchant
                fname: contact?.fname ?? '',
                lname: contact?.lname ?? '',
                email: contact?.email ?? '',
                phone_number: contact?.phone_number ?? '',
                addContactInfo:
                    Boolean(contact?.email) ||
                    Boolean(contact?.fname) ||
                    Boolean(contact?.lname) ||
                    Boolean(contact?.phone_number),
                hasContactInfo:
                    Boolean(contact?.email) ||
                    Boolean(contact?.fname) ||
                    Boolean(contact?.lname) ||
                    Boolean(contact?.phone_number),
                status: status.id === 1 ? true : false,
                deflectors: deflectors ?? [],
                merchantAlias: alias ?? '',
            }
        } else {
            return {
                merchantBusinessName: '',
                merchantParentName: '',
                partnerCompanyId: '',
                businessAddress: '',
                businessRegionOrState: '',
                businessZipCode: '',
                merchantServiceLevel: '1',
                businessCity: '',
                businessCountry: '',
                businessPhone: '',
                directAPI: false,
                hasIssuerDocs: false,
                statusId: '',
                parentId: '',
                // Admin contact for merchant
                fname: '',
                lname: '',
                email: '',
                phone_number: '',
                addContactInfo: false,
                hasContactInfo: false,
                status: true,
                deflectors: [],
                merchantAlias: '',
            }
        }
    }, [merchantDetails, isEdit, merchantParentName])

    const {
        register,
        trigger,
        watch,
        setValue,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting, isValid, isDirty },
        // TODO: Known React-Hook-Form typescript issue - Should be resolved with RHF's v8. Once resolved, need to switch typescript's "any" to "TAddEditMerchantInputs".
    } = useForm<any>({
        defaultValues: defaultValues,
        values: defaultValues,
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
    })

    const onSubmit: SubmitHandler<TAddEditMerchantInputs> = async (
        inputValues
    ) => {
   

        // Again, we're doing something we should not be doing and translating
        // the shape of data, but we're doing this for now...
        const deflectorsToSend = inputValues?.deflectors?.map((obj: any) => {
            return {
                id: obj.id,
                status: obj.status.id,
            }
        })

        // TODO: Add a strict type for the request body
        let body: { [key: string]: any } = {
            business_name: inputValues.merchantBusinessName,
            dba: inputValues.merchantBusinessName,
            status_id: inputValues.status ? 1 : 2,
            service_level_id: parseInt(
                inputValues?.merchantServiceLevel ?? '1'
            ),
            partner_company_id: inputValues.partnerCompanyId,
            business_address: inputValues.businessAddress,
            city: inputValues.businessCity,
            state: inputValues.businessRegionOrState,
            zip: inputValues.businessZipCode,
            business_phone: inputValues.businessPhone,
            country_code: inputValues?.businessCountry,
            deflectors: deflectorsToSend,
            direct_api: inputValues.directAPI,
            parent_id: inputValues.parentId,
            alias: inputValues?.merchantAlias?.length
                ? inputValues.merchantAlias
                : undefined,
            features: inputValues.hasIssuerDocs ? [1] : [],
            // using email as it's the only field related to cognito
        }

        // This is to get around a strange bug in the BE, until they have a fix.
        if (
            merchantDetails &&
            inputValues.parentId !== merchantDetails?.parent_id
        ) {
            body.parent_id = inputValues.parentId
        }
        if (
            inputValues?.email ||
            inputValues?.fname ||
            inputValues?.lname ||
            inputValues?.phone_number
        ) {
            body.contact = {
                fname: inputValues?.fname,
                lname: inputValues?.lname,
                email: inputValues?.email,
                phone_number: inputValues?.phone_number,
            }
        }

        try {
            if (isEdit) {
                await MerchantApi.editMerchantInfo(+merchantId, body)
            } else {
                await MerchantApi.addMerchant(body)
                reset()
            }
            setAlertSnackbarMainProps({
                title: 'Success',
                intent: 'success',
                message: isEdit
                    ? 'Merchant successfully updated!'
                    : 'Merchant successfully added!',
            })
            setAlertSnackbarMainOpen(true)
            forceReload()
        } catch (err) {
            const { response } = err as any
            setAlertSnackbarProps({
                title: 'Error',
                intent: 'error',
                message:
                    response.data.message ??
                    `An error occurred. Please try again later.`,
            })
            setAlertSnackbarOpen(true)
        }
    }

    const handleOnChangeValue = (fieldName: any, value: any) => {
        setValue(fieldName, value, { shouldValidate: true, shouldDirty: true })
    }

    // Needed to trigger validation on first render of form.
    useEffect(() => {
        const validateForm = async () => {
            await reset()
            trigger()
        }
        validateForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        inputUtils: {
            register,
            watch,
            errors,
            isDirty,
            isValid,
            isSubmitting,
            handleOnChangeValue,
            submitForm: handleSubmit(onSubmit),
            reset,
            countryCodes,
        },
        defaultDeflectorValues: defaultValues.deflectors ?? [],
    }
}

export default useAddEditMerchant

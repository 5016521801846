import React, { useState } from 'react'
import {
    TextField,
    CircularProgress,
    Typography,
    Autocomplete,
} from '@mui/material'
import { clientTheme } from 'theme-exports'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import { CloseAndInvalidate } from '../grids/AlertsGrid'
import { StandardModal } from 'components'

interface MidList {
    name: string
    value: string
    id: string
    descriptor: string
}

interface SelectMIDProps {
    open: boolean
    onClose: CloseAndInvalidate
    midList: MidList[]
    setSelectedMid: (mid: MidList) => any
    selectedAlertInfo: any
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

/**
 * @name SelectMID
 * @description - Complete an alert
 * @param open - bool: Determines whether modal is to be open
 * @param onClose - func: toggle open state
 * @param midList - array: Array of MIDs
 * @param setSelectedMid - func: handler for submit
 * @param selectedAlertInfo - object: contains info about the currently selected MID
 */
const SelectMID: React.FC<SelectMIDProps> = ({
    open,
    onClose,
    midList,
    setSelectedMid,
    selectedAlertInfo,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [queryText, setQueryText] = useState('')
    const [activeMid, setActiveMid] = useState<any>([])
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const selectMidHandler = () => {
        setIsSubmitting(true)
        setSelectedMid(activeMid)
            .then(() => {
                setAlertSnackbarMainProps({
                    title: 'Success',
                    message: `MID ${activeMid.name} successfully applied.`,
                    intent: 'success',
                })
                setAlertSnackbarMainOpen(true)
                onClose('selectMid', true)
            })
            .catch(() => {
                setAlertSnackbarProps({
                    title: 'Error',
                    message: `There was a problem applying ${activeMid.name} MID.`,
                    intent: 'error',
                })
                setAlertSnackbarOpen(true)
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    const handleOnClose = () => {
        onClose('selectMid')
        setQueryText('')
        setActiveMid([])
    }

    return (
        <StandardModal
            openModal={open}
            onModalClose={handleOnClose}
            modalHeaderTitle={'Select MID'}
            maxModalWidth={'xs'}
            testId={'preChargebacksSelectMid'}
            primaryBtnText={'Save'}
            handlePrimaryActionBtn={selectMidHandler}
            inactivatePrimaryActionBtn={isSubmitting}
            isLoadingPrimaryActionBtn={isSubmitting}
            enableSecondaryActionBtn={false}
        >
            <div id={'preChargebacksAGAlertsSelectMid'}>
                <div>
                    <Typography className="emp-label">Descriptor:</Typography>
                    <Typography>{selectedAlertInfo.descriptor}</Typography>
                </div>
                <div>
                    <Typography className="emp-label">MID:</Typography>
                    <Autocomplete
                        className={'emp-autocomplete'}
                        autoComplete
                        limitTags={4}
                        inputValue={queryText}
                        getOptionLabel={(option): string => option.name ?? ''}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        options={midList}
                        loading={loading}
                        onChange={(_e, val) => {
                            setActiveMid(val)
                        }}
                        value={activeMid}
                        onInputChange={(_, value): void => {
                            setLoading(true)
                            setQueryText(value)
                            setLoading(false)
                        }}
                        renderInput={(params: any): React.ReactNode => (
                            <TextField
                                {...params}
                                placeholder="Select MID"
                                variant="outlined"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius:
                                            clientTheme.selectionBox
                                                .borderRadius,
                                    },
                                }}
                            />
                        )}
                    />
                </div>
            </div>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </StandardModal>
    )
}

export default SelectMID

/**
 * @description Directory for ID Attributes on Settings.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    divContainer: `settings-div-container`,
    gridContainer: `settings-grid-container`,
    gridItem: `settings-grid-item`,
    checkboxUnique: `settings-checkbox-unique`,
}

export default idDirectory

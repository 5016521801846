import React from 'react'
import { Tooltip } from '@mui/material'
import { verdictMap, statusMap } from './data/Verdicts'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'

export enum Verdict {
    WillNotRepresent = 'Will Not Represent',
    DoNotRepresent = 'Do Not Represent',
    VerdictPending = 'Verdict Pending',
    Win = 'Win',
    Loss = 'Loss',
    Pending = 'Pending',
    Default = 'default',
}

interface VerdictTypeProps {
    className?: string
    id?: string
    size?: 'small' | 'medium' | 'large' | undefined
    verdict?: Verdict
    label?: string
}

const camelize = (str: string) =>
    str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
            index === 0 ? word.toLowerCase() : word.toUpperCase()
        )
        .replace(/\s+/g, '')

/**
 * Shows a verdict type icon
 */
const VerdictType = ({
    verdict = Verdict.Default,
    size = 'small',
    label = 'verdict',
    className = '',
}: VerdictTypeProps): React.ReactElement => {
    const { client } = useFeatureToggle('CLIENT')

    const verdictSize = {
        small: 'emp-verdictType-small',
        medium: 'emp-verdictType-medium',
        large: 'emp-verdictType-large',
    }

    // This should NEVER happen. Temporary hack due to data errors during UAT on 8/11/21
    // Remove this after EXT-1137 case service ticket has been completed.
    if (!verdict) return <></>

    const enableNotApplicableVerdict =
        client === 'bluesnap' &&
        ![Verdict.Win, Verdict.Pending, Verdict.Loss].includes(verdict)

    const search = camelize(verdict)
    const FinalVerdict =
        label === 'status' ? statusMap[search]?.icon : verdictMap[search]?.icon

    return (
        <div className={`emp-verdictType-container ${className}`}>
            {enableNotApplicableVerdict || !FinalVerdict ? (
                <div>N/A</div>
            ) : (
                <Tooltip placement="top" arrow title={verdict}>
                    <div
                        className={`emp-verdictType-root ${verdictSize[size]}`}
                    >
                        <FinalVerdict />
                    </div>
                </Tooltip>
            )}
        </div>
    )
}

export default VerdictType


/**
 * @description Directory for ID Attributes on Alerts.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `alerts-div-root`,
    filterAlerts: {
        divDescriptors: `filterAlerts-div-selectDescriptors`,
    }
}

export default idDirectory

import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import { RowModel } from '@material-ui/data-grid'
import { Tooltip } from '@mui/material'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { DataGridSlim, Searchbar } from 'components'
import SortComponent from 'components/DataGrid/SortComponent'

interface OnboardingProps {
    data?: RowModel[]
    sortInfo: { sortBy: string; sortDesc: string }
    setSearch: (search: string) => void
    total: number
    onPageSizeChange: (size: number) => void
    loading: boolean
    page: number
    rowsPerPage: number
    onPageChange: (page: number) => void
    onSortChange: (sort: boolean, accessor: string) => void
    onSortDirectionChange: (value: 'asc' | 'desc') => void
    globalSearch: string
    onSearchChange: (search: string) => void
    testId: string
    rehydrateView: () => void
}

export const OnboardingGrid = ({
    sortInfo,
    data,
    onSearchChange,
    total,
    onPageSizeChange,
    loading,
    page,
    rowsPerPage,
    onPageChange,
    onSortChange,
    testId,
    rehydrateView,
}: OnboardingProps) => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const sortHandler = React.useCallback(
        (isAsc: boolean, column: string) => {
            onSortChange(isAsc, column)
        },
        [onSortChange]
    )

    const columns: any = [
        {
            Header: (
                <SortComponent
                    sortHandler={sortHandler}
                    accessor="merchant"
                    value="Merchant"
                    sortInfo={sortInfo}
                />
            ),
            accessor: 'merchant',
            width: 158,
            type: 'string',
        },
        {
            Header: (
                <SortComponent
                    sortHandler={sortHandler}
                    accessor="username"
                    value="User Name"
                    sortInfo={sortInfo}
                />
            ),
            accessor: 'username',
            width: 121,
            type: 'string',
        },
        {
            Header: (
                <SortComponent
                    sortHandler={sortHandler}
                    accessor="upload_type"
                    value="Upload Type"
                    sortInfo={sortInfo}
                />
            ),
            accessor: 'upload_type',
            width: 138,
            type: 'string',
        },
        {
            Header: (
                <SortComponent
                    sortHandler={sortHandler}
                    accessor="upload_date"
                    value="Upload Date"
                    sortInfo={sortInfo}
                />
            ),
            accessor: 'upload_date',
            width: 120,
            type: 'string',
        },
        {
            Header: (
                <SortComponent
                    sortHandler={sortHandler}
                    accessor="finalized_date"
                    value="Finalized Date"
                    sortInfo={sortInfo}
                />
            ),
            accessor: 'finalized_date',
            width: 150,
            type: 'string',
        },
        {
            Header: (
                <SortComponent
                    sortHandler={sortHandler}
                    accessor="uploaded_rows"
                    value="Rows in Upload"
                    sortInfo={sortInfo}
                />
            ),
            accessor: 'uploaded_rows',
            width: 159,
            type: 'string',
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ cell }: any) => (
                <>
                    {cell.row.original.actions.isEdit && !isReadOnly ? (
                        <Tooltip
                            title={'Edit and finalize onboarding'}
                            placement="top"
                            arrow
                        >
                            <Link
                                to={`/onboarding/edit/${cell.row.original.id}`}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    style={{
                                        textTransform: 'capitalize',
                                        marginLeft: 5,
                                    }}
                                    size="small"
                                >
                                    Edit
                                </Button>
                            </Link>
                        </Tooltip>
                    ) : (
                        <Button
                            color="primary"
                            variant="contained"
                            style={{
                                textTransform: 'capitalize',
                                marginLeft: 5,
                            }}
                            size="small"
                            disabled={true}
                        >
                            Edit
                        </Button>
                    )}
                </>
            ),
            width: 98,
            type: 'icon',
        },
    ]

    return (
        <div id={'onboardingGrid'}>
            <div>
                <Searchbar
                    className={'emp-cssOutlinedInput'}
                    debounceDelay={200}
                    returnValue={onSearchChange}
                    testId={testId}
                />
            </div>
            <DataGridSlim
                page={page}
                columns={columns}
                data={data}
                rowsPerPageOptions={[10, 25, 50, 100]}
                pageSize={rowsPerPage}
                expandable={false}
                slim={false}
                loading={loading}
                onPageLimitChange={onPageSizeChange}
                onPageChange={onPageChange}
                totalEntries={total}
                fixedLoadingIndicator
                stickyHeaders={false}
                testId={testId}
                noSelection
                refreshDataGrid={() => rehydrateView()}
            />
        </div>
    )
}

import React from 'react'
import { Drawer, Typography, Skeleton } from '@mui/material'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'
import Icon from 'components/Icon'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'

interface ISidebarProps {
    className?: string
    children: React.ReactNode
    open: boolean
    variant?: 'permanent' | 'persistent' | 'temporary'
    onClose: () => void
    openSidebar: boolean
    [key: string]: any
}

/**
 * Allow side nav content to slide out on request
 */
const Sidebar = ({
    className,
    children,
    open,
    variant,
    onClose,
    openSidebar,
    ...rest
}: ISidebarProps) => {
    const { MERCHANT_NAME } = useFeatureToggle('MAIN_SIDEBAR')
    const { enabled: mainFooterEnabled } = useFeatureToggle('MAIN_FOOTER')
    const { user, loading } = useAuthedUser()

    return (
        <Drawer
            id={idDirectory.drawer}
            anchor="left"
            className={
                openSidebar
                    ? 'emp-sidebar-drawerOpen'
                    : 'emp-sidebar-drawerClose'
            }
            classes={{
                paper: openSidebar
                    ? 'emp-sidebar-drawerOpen'
                    : 'emp-sidebar-drawerClose',
            }}
            onClose={onClose}
            open={open}
            variant={variant}
            sx={[
                openSidebar
                    ? (theme) => ({
                          transition: theme.transitions.create('width', {
                              easing: theme.transitions.easing.sharp,
                              duration:
                                  theme.transitions.duration.enteringScreen,
                          }),
                          '& .MuiDrawer-paper': {
                              transition: theme.transitions.create('width', {
                                  easing: theme.transitions.easing.sharp,
                                  duration:
                                      theme.transitions.duration.enteringScreen,
                              }),
                              backgroundColor:
                                  clientTheme.sidebar.backgroundColor,
                          },
                      })
                    : (theme) => ({
                          transition: theme.transitions.create('width', {
                              easing: theme.transitions.easing.sharp,
                              duration:
                                  theme.transitions.duration.leavingScreen,
                          }),
                          '& .MuiDrawer-paper': {
                              transition: theme.transitions.create('width', {
                                  easing: theme.transitions.easing.sharp,
                                  duration:
                                      theme.transitions.duration.leavingScreen,
                              }),
                              backgroundColor:
                                  clientTheme.sidebar.backgroundColor,
                          },
                      }),
            ]}
        >
            <div
                id={idDirectory.divRoot}
                {...rest}
                className={`${openSidebar && 'emp-sidebar-root'} ${className}`}
                style={{
                    backgroundColor: clientTheme.sidebar.backgroundColor,
                    marginTop: '64px',
                    marginBottom: mainFooterEnabled ? '26px' : '0px',
                }}
            >
                {openSidebar && MERCHANT_NAME.enabled && (
                    <div className={'emp-sidebar-merchantContainer'}>
                        {loading ? (
                            <Skeleton
                                variant="text"
                                style={{
                                    backgroundColor: 'rgb(76 78 81)',
                                    width: '100%',
                                }}
                            />
                        ) : (
                            <>
                                <Icon className={'icon-building'} />
                                <Typography
                                    variant="body1"
                                    className={'emp-sidebar-merchantText'}
                                >
                                    {user?.merchant?.business_name}
                                </Typography>
                            </>
                        )}
                    </div>
                )}
                {children}
            </div>
        </Drawer>
    )
}

export default Sidebar

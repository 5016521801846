import React from 'react'
import useDnrModal from './useDnrModal'
import { ModalMode, ModalVariant } from 'hooks/cb-api'
import { ConfirmActionModal } from 'components'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface DnrProps {
    open: boolean
    toggleOpen: (variant: ModalVariant, mode?: ModalMode) => void
    isMultiSelectMode: boolean
    selectedCaseIds: number[]
    rehydrateView: () => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    unselectAllRows?: () => void
}

const DnrModal = ({
    toggleOpen,
    isMultiSelectMode,
    selectedCaseIds,
    rehydrateView,
    open,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    unselectAllRows = () => {},
}: DnrProps): React.ReactElement => {
    const { handleBulkDnr, loading } = useDnrModal()

    const handleMoveToDnr = () => {
        if (isMultiSelectMode) {
            handleBulkDnr(selectedCaseIds)
                .then(() => {
                    setAlertSnackbarMainProps({
                        message: 'Your action was a success!',
                        title: 'Success.',
                        intent: 'success',
                    })
                    unselectAllRows()
                })
                .catch(() => {
                    setAlertSnackbarMainProps({
                        message:
                            "There was an error when running the bulk 'Do Not Represent' action.",
                        title: 'Error.',
                        intent: 'error',
                    })
                })
                .finally(() => {
                    toggleOpen('moveToDnr')
                    setAlertSnackbarMainOpen(true)
                })
        }
    }

    return (
        <div className='emp-dnrModal-root'>
            <ConfirmActionModal
                open={open}
                maxModalWidth={'sm'}
                toggleOpen={() => toggleOpen('moveToDnr')}
                onConfirm={handleMoveToDnr}
                header={`Move Case to Do Not Represent`}
                message={
                    'You are about to move this case to do not represent and mark as a loss, would you like to proceed?'
                }
                confirmButtonText={'Continue'}
                inactivateConfirmButton={loading}
                isLoadingConfirmButton={loading}
                testId={'doNotRepresent'}
            />
        </div>
    )
}

export default DnrModal

/**
 * @description Directory for ID Attributes on Users.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    btnAddUser: `users-btn-addUser`,
}

export default idDirectory

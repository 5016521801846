import React, { useEffect, useState } from 'react'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { withRequiredRole } from 'components/useRequireRole'
import EntFeature from 'models/EntFeature'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import axios from 'axios'

const getIFrameHost = () => {
    const hostSubDomain = window.location.hostname.split('.')[0]
    const devDomainsIdentifiers = [
        '-sbx',
        '-stg',
        '-dev',
        '-qa',
        '-uat',
        '-demo',
        'localhost',
        'devtools'
    ]
    const isDev = devDomainsIdentifiers.some(v => hostSubDomain.indexOf(v) >= 0)
    return `${isDev ? 'dev-' : ''}embed.merchant-dispute.com`
}

const PreChargebacksIFrame: React.FC = () => {
    const [configData, setConfigData] = useState<any>(undefined)
    const [loading, setLoading] = useState<boolean>(true)
    const { client } = useFeatureToggle('CLIENT')
    const iframeHost = getIFrameHost()
    const themeNameToSend = client === '' ? 'default' : client
    const { user } = useAuthedUser()
    const [fixedUser, setFixedUser] = useState({
        id: null,
        username: '',
        email: '',
    })

    const parentMerchantId = user?.merchant?.id

    useEffect(() => {
        if (parentMerchantId && user) {
            axios
                .get(`/cm/merchants/${parentMerchantId}/express-resolve`)
                .then((res) => {
                    setConfigData(res.data)
                })
                .catch(() => {})
                .finally(() => setLoading(false))

            // TODO - This is a workaround until we figure out why the information in
            // authedUserContext is messed up.
            axios
                .post('/users/whoami')
                .then((res) => {
                    setFixedUser({
                        id: res.data.id,
                        username: res.data.username,
                        email: res.data.email,
                    })
                })
                .catch(() => {})
        }
    }, [parentMerchantId, user])

    if (loading || !fixedUser.id) return <></>
    
    const {
        ers_client_id: ersMerchantId,
        token,
        identity,
        first_name,
        last_name,
        session_token,
    } = configData

    const userString = `&username=${fixedUser.username}&email=${fixedUser.email}&fname=${first_name}&lname=${last_name}&identity=${identity}&session_token=${session_token}`
    const hostname = document.location.host
    const pathAndParams = user?.merchant?.show_alerts_banner ?
        `/deflector/preview?client_id=${ersMerchantId}&type=client&verify=${token}&embedded=1&user_id=1${userString}&redirect_url=${hostname}`
    :
        `/dispute-inquiries?embedded=1&user_id=1&client_id=${ersMerchantId}&verify=${token}&theme=${themeNameToSend}${userString}&redirect_url=${hostname}`

    return (
        <div className={'emp-preChargebacksIframe-alertsIframe-root'}>
            <iframe
                className={'emp-preChargebacksIframe-alertsIframe-container'}
                src={`https://${iframeHost}${pathAndParams}`}
                title="Alerts"
            >
                <div>Loading alerts...</div>
            </iframe>
        </div>
    )
}

export default withRequiredRole(EntFeature.PRE_CHARGEBACKS, PreChargebacksIFrame)

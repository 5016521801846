import useSWR from 'swr'
import fetcher from './fetcher'
import useURL from './utils/useURL'

// paramInUrl is a boolean that indicates if the param is in the url for endpoint requests that require it. For exmaple, calling a single user looks like: users/users/:id where calling all users requires a merchant_id parameter to be passed as a param object.
type EndpointConfig = {
    paramInUrl: boolean
    url: string
    id: {
        key?: string
        value: string | number | undefined
    }
}

export const useSwrData = (
    endpointConfig: EndpointConfig,
    params?: { [key: string]: any }
) => {
    const url = useURL(endpointConfig.url, {
        ...(params && { ...params }),
        ...(endpointConfig.id.key &&
            !endpointConfig.paramInUrl && {
                [endpointConfig.id.key]: endpointConfig.id.value,
            }),
    })

    const swrOptions = {
        revalidateOnFocus: false,
        dedupingInterval: 2000,
    }

    // by exporting mutate here, we bind this method to the SWR data key provided as the url here
    const { data: payload, isValidating, mutate, error, ...rest } = useSWR<any>(
        endpointConfig.id.value ? url : null,
        fetcher,
        swrOptions
    )

    // payload.data ? ... checks if payload has a data property. If it does, it will return the data property. If it doesn't, it will return the payload object.
    return {
        payload: payload,
        data: payload?.data ? payload?.data : payload || [],
        pagination: payload?.pagination,
        isValidating,
        mutate,
        error,
        ...rest,
    }
}

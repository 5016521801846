import axios from 'axios'
import { PaginatedResponse } from '../models/Server/PaginatedResponse'

type User = any

type GetUsers = (
    merchantId: number,
    search: string | null,
    start: number,
    limit: number,
    sortBy: string,
    sortOrder: string
) => Promise<PaginatedResponse<User>>
type AddUser = (
    firstName: string,
    lastName: string,
    email: string,
    username: string,
    role: number,
    merchantId: number,
    isReadOnly: boolean
) => Promise<void>
type EditUser = (
    userId: number,
    firstName: string,
    lastName: string,
    email: string,
    role: number,
    merchantId: number,
    isReadOnly: boolean
) => Promise<void>
type EditEmail = (
    userId: number,
    email: string,
) => Promise<void>
type LockUser = (userId: number) => Promise<void>
type EnableUser = (userId: number) => Promise<void>
type GetUser = (userId: number) => Promise<User>
type GetLoginHistory = (userId: number) => Promise<User>
type ResetPassword = (username: string) => Promise<void>
type GetRoles = () => Promise<User>
type GetRolesForMerchant = (merchantId: number) => Promise<User>
type GetSitePermissions = (userId: number, roleId: number) => Promise<User>
type SetSitePermission = (
    userId: number,
    resourceId: number,
    enabled: boolean
) => Promise<User>
type GetMids = (merchantId: number, search?: string) => Promise<User>
type SetMidPermissions = (userId: number, payload: any) => Promise<User>
type GetUserMids = (userId: number) => Promise<User>
type GetMerchants = (merchantId: number, search: string) => Promise<User>

interface IUsersApi {
    addUser: AddUser
    getUser: GetUser
    getUsers: GetUsers
    getLoginHistory: GetLoginHistory
    editUser: EditUser
    editEmail: EditEmail
    lockUser: LockUser
    enableUser: EnableUser
    resetPassword: ResetPassword
    getRoles: GetRoles
    getRolesForMerchant: GetRolesForMerchant
    getSitePermissions: GetSitePermissions
    setSitePermission: SetSitePermission
    getMids: GetMids
    setMidPermissions: SetMidPermissions
    getUserMids: GetUserMids
    getMerchants: GetMerchants
}

class UsersApi implements IUsersApi {
    addUser(
        firstName: string,
        lastName: string,
        email: string,
        username: string,
        role: number,
        merchantId: number,
        isReadOnly: boolean
    ): Promise<void> {
        return axios.post('/users/users', {
            fname: firstName,
            lname: lastName,
            username,
            email,
            role,
            groups: [0],
            merchant: merchantId,
            status: 1,
            is_read_only: isReadOnly,
        })
    }

    editUser(
        userId: number,
        firstName: string,
        lastName: string,
        email: string,
        role: number,
        merchantId: number,
        isReadOnly: boolean
    ): Promise<void> {
        return axios.patch(`/users/users/${userId}`, {
            fname: firstName,
            lname: lastName,
            email: email,
            role: role,
            merchant: merchantId,
            is_read_only: isReadOnly,
        })
    }

    editEmail(
        userId: number,
        email: string,
    ): Promise<void> {
        return axios.patch(`/users/users/${userId}`, {
            email: email,
        })
    }

    enableUser(userId: number): Promise<void> {
        return axios.patch(`/users/users/${userId}`, {
            status: 1,
        })
    }

    lockUser(userId: number): Promise<void> {
        return axios.patch(`/users/users/${userId}`, {
            status: 6,
        })
    }

    getUser(userId: number) {
        return axios.get(`/users/users/${userId}`).then(({ data }) => data)
    }

    getUsers(
        merchantId: number,
        search: string | null,
        start: number,
        limit: number,
        sortBy: string,
        sortOrder: string
    ): Promise<PaginatedResponse<User>> {
        return axios
            .get('/users/users', {
                params: {
                    merchant_id: `${merchantId}`,
                    limit: limit,
                    start: start,
                    sort_by: sortBy,
                    sort_order: sortOrder,
                    search: search,
                },
            })
            .then(({ data }) => data)
    }

    getMerchants(
        merchantId: number,
        search: string | null,
        excludeServiceLevelIds? : number[] 
    ): Promise<PaginatedResponse<User>> {
        return axios
            .get('/cm/merchants', {
                params: {
                    merchant_id: `${merchantId}`,
                    limit: 999,
                    search: search,
                    show_all: 1,
                    exclude_service_level_id: excludeServiceLevelIds?.join(','),
                },
            })
            .then(({ data }) => data)
    }

    resetPassword(username: string): Promise<void> {
        return axios.post('/users/pass', {
            username,
        })
    }

    getLoginHistory(userId: number) {
        return axios
            .get(`/users/users/${userId}/login-history`)
            .then(({ data }) => data)
    }

    getRoles() {
        return axios.get(`/users/roles`).then(({ data }) => data)
    }

    getMerchantDomainWhitelist(merchantId: number) {
        return axios
            .get(`/cm/merchants/${merchantId}`)
            .then(({ data }) => data.domain_whitelist)
    }

    getRolesForMerchant(merchantId: number) {
        return axios
            .get(`/users/roles?merchant_id=${merchantId}`)
            .then(({ data }) => data)
    }

    getSitePermissions(userId: number, roleId: number) {
        return this.getUser(userId).then((dataUser) => {
            return axios
                .get(`/users/resources`, {
                    params: {
                        role_id: roleId,
                        is_menu_resource: 1,
                    },
                })
                .then(({ data: { data } }) => {
                    return data.map(
                        (data: {
                            id: number
                            name: string
                            alias: string
                            is_enabled: boolean
                        }) => {
                            const foundInUserResources = dataUser?.resources?.find(
                                (resource: any) => resource.id === data.id
                            )
                                ? true
                                : false
                            return {
                                id: data.id,
                                name: data.name,
                                label: data.name,
                                key: data.alias,
                                isChecked: foundInUserResources,
                            }
                        }
                    )
                })
        })
    }

    setSitePermission(userId: number, resourceId: number, enabled: boolean) {
        return axios
            .put(`/users/${userId}/resources/${resourceId}`, {
                is_allowed: enabled,
            })
            .then((data) => data)
    }

    getMids(merchantId: number, search?: string) {
        return axios
            .get(`/cm/mids`, {
                params: {
                    merchant_id: merchantId,
                    start: 0,
                    limit: 10,
                    search,
                },
            })
            .then(({ data: { data, pagination } }) => {
                return data.map(
                    (data: { alias: string; mid: string; id: number }) => {
                        return {
                            name: `${data.mid} - ${data.alias}`,
                            value: data.id,
                            id: data.id,
                        }
                    }
                )
            })
    }

    setMidPermissions(userId: number, payload: any) {
        return axios
            .put(`/cm/mids/permissions/${userId}`, {
                mids: payload.map((perm: any) => perm.id),
            })
            .then((data) => data)
    }

    getUserMids(userId: number) {
        return axios
            .get(`/cm/mids/permissions/${userId}?start=0&limit=999`)
            .then(({ data: { data } }) => {
                return data.map(
                    (data: { alias: string; mid: string; id: number }) => {
                        return {
                            name: `${data.mid} - ${data.alias}`,
                            value: data.id,
                            id: data.id,
                        }
                    }
                )
            })
    }
}

export default new UsersApi()

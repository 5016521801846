/**
 * @description Directory for ID Attributes on AG Data Grid Actions Bar.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `agDataGridActionsBar-div-root`,
    btnSearch: `agDataGridActionsBar-btn-search`,
    divFilterTags: `agDataGridActionsBar-div-filterTags`,
    btnFilter: `agDataGridActionsBar-btn-filter`,
    popoverFilter: `agDataGridActionsBar-popover-filter`,
    divFilters: `agDataGridActionsBar-div-filters`,
    inputFilter: `agDataGridActionsBar-input-filter`,
    btnFilterClear: `agDataGridActionsBar-btn-filterClear`,
    btnFilterSearch: `agDataGridActionsBar-btn-filterSearch`,
    btnColumnFilter: `agDataGridActionsBar-btn-columnFilter`,
    popoverColumnFilter: `agDataGridActionsBar-popover-ColumnFilter`,
    btnColumnFilterReset: `agDataGridActionsBar-btn-ColumnFilterReset`,
    btnCsv: `agDataGridActionsBar-btn-csv`,
    btnExcel: `agDataGridActionsBar-btn-excel`,
    divAdditionalContent: `agDataGridActionsBar-div-additionalContent`,
}

export default idDirectory

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
    faTimes,
    faGripVertical,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Typography, Divider, IconButton } from '@mui/material'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { clientTheme } from 'theme-exports'
import UploadIcon from 'assets/icons/upload-icon-bg-alt.png'
import useIsMounted from 'hooks/utils/useIsMounted'
import {
    FileDnD,
    Lamp,
    SearchableSelect,
    LoadingIndicator,
    useActiveMerchant,
} from 'components'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import idDirectory from './idAttributes'
import { UploadCaseBuilderFileDnD } from './UploadCaseBuilderFileDnD'
import { CaseDetails } from '../CaseBuilder/CaseBuilder.vm'

export type DocumentOption = {
    id: number
    name?: string
    file?: { [key: string]: any }
    required?: boolean
    default?: boolean // Use to separate 'document type'(default: true) from 'basic templates'(default:false) when in 'combinedDocumentsList'[].
} & Record<string, unknown>

export interface UploadCaseBuilderDocumentsStep3Props {
    className?: string
    setEnableSaveAndContinue: (val: boolean) => void
    combinedDocumentsList: DocumentOption[]
    setCombinedDocumentsList: (item: DocumentOption[]) => void
    savedDocumentsList: DocumentOption[]
    setAlertSnackbarOpen: (value: boolean) => void
    setAlertSnackbarProps: (value: alertSnackbarContentProps) => void
    setCombinedDocumentsListUpdated: (value: boolean) => void
    initialCaseDetails: CaseDetails | null
    setFormInputValues: (values: { [key: string]: any }) => void
    caseId: number | null
    maxUploadFileSize: number
}

const UploadCaseBuilderDocumentsStep3 = ({
    className = '',
    setEnableSaveAndContinue,
    combinedDocumentsList,
    setCombinedDocumentsList,
    savedDocumentsList,
    setAlertSnackbarOpen,
    setAlertSnackbarProps,
    setCombinedDocumentsListUpdated,
    initialCaseDetails,
    setFormInputValues,
    caseId,
    maxUploadFileSize,
}: UploadCaseBuilderDocumentsStep3Props) => {
    const { id: merchantId } = useActiveMerchant()
    const { isMounted } = useIsMounted()
    const { client } = useFeatureToggle('CLIENT')

    const [basicTemplatesList, setBasicTemplatesList] = useState<
        DocumentOption[]
    >([])
    const [
        loadingBasicTemplatesList,
        setLoadingBasicTemplatesList,
    ] = useState<boolean>(false)
    const [documentTypesList, setDocumentTypesList] = useState<
        DocumentOption[]
    >([])
    const [
        loadingDocumentTypesList,
        setLoadingDocumentTypesList,
    ] = useState<boolean>(false)
    const [
        selectedBasicTemplate,
        setSelectedBasicTemplate,
    ] = useState<DocumentOption | null>(null)
    const [
        selectedDocumentType,
        setSelectedDocumentType,
    ] = useState<DocumentOption | null>(null)
    const [currentFileDnDInfo, setCurrentFileDnDInfo] = useState<{
        [key: string]: any
    } | null>(null)

    const formattedMaxUploadFileSize = Math.floor(maxUploadFileSize / 1048576)

    const getBasicTemplates = async () =>
        await axios.get('/docs/templates', {
            params: {
                limit: 999,
                merchant_id: merchantId,
            },
        })

    const getDocumentTypes = async () =>
        await axios.get('/builder/gen/doctypes', {
            params: {
                case_id: caseId,
                limit: 999,
            },
        })

    useEffect(() => {
        setLoadingBasicTemplatesList(true)
        getBasicTemplates()
            .then(({ data: { data } }) => {
                if (isMounted.current)
                    setBasicTemplatesList(
                        data.map((data: any) => {
                            return {
                                id: data.id,
                                name: data.file.filename,
                                file: data.file,
                                required: false,
                                default: false,
                            }
                        })
                    )
            })
            .catch((err) => {})
            .finally(() => {
                if (isMounted.current) setLoadingBasicTemplatesList(false)
            })

        setLoadingDocumentTypesList(true)
        getDocumentTypes()
            .then(({ data: { data } }) => {
                if (isMounted.current) {
                    const formattedData = data
                        .map((data: any) => {
                            return {
                                id: data.id,
                                name: data.name,
                                file: undefined,
                                required: data.required,
                                default: true,
                            }
                        })
                        // Sort to put 'required' document types on top in list.
                        .sort((doc: any) =>
                            doc.required > !doc.required ? -1 : 1
                        )

                    setDocumentTypesList(formattedData)
                    if (!savedDocumentsList.length) {
                        setCombinedDocumentsList(formattedData)
                        handleEnableSaveAndContinueBtn(formattedData)
                    } else {
                        setCombinedDocumentsList(savedDocumentsList)
                        handleEnableSaveAndContinueBtn(savedDocumentsList)
                    }
                }
            })
            .catch((err) => {})
            .finally(() => {
                if (isMounted.current) setLoadingDocumentTypesList(false)
            })

        // eslint-disable-next-line
    }, [])

    const handleReorderCombinedDocumentsList = (result: any) => {
        const to = result.destination.index
        const from = result.source.index

        combinedDocumentsList.splice(
            to,
            0,
            combinedDocumentsList.splice(from, 1)[0]
        )
        if (isMounted.current) {
            const updatedCombinedDocumentsList = [...combinedDocumentsList]
            setCombinedDocumentsList(updatedCombinedDocumentsList)
            updatedCombinedDocumentsList.some(
                (doc: DocumentOption) => doc.file
            ) || savedDocumentsList.some((doc: DocumentOption) => doc.file)
                ? setCombinedDocumentsListUpdated(true)
                : setCombinedDocumentsListUpdated(false)
        }
    }

    const isExceedingMaxTotalUploadLimit = (selectedFileSize: number) => {
        if (!maxUploadFileSize) {
            setAlertSnackbarProps({
                title: 'Error',
                message: `An error occurred. Please try again later.`,
                intent: 'error',
            })
            setAlertSnackbarOpen(true)
            return true
        }

        const totalSize =
            combinedDocumentsList.reduce((sum, document: any) => {
                return sum + (document.file?.size ?? 0)
            }, 0) + selectedFileSize

        if (totalSize > maxUploadFileSize) {
            setAlertSnackbarProps({
                title: 'Error',
                message: `Files exceed ${formattedMaxUploadFileSize}MB total limit.`,
                intent: 'error',
            })
            setAlertSnackbarOpen(true)

            return true
        }

        return false
    }

    const handleAddBasicTemplate = () => {
        if (selectedBasicTemplate) {
            if (
                isExceedingMaxTotalUploadLimit(selectedBasicTemplate.file?.size)
            )
                return

            // Check if file has already been added to CombinedDocumentsList[].
            const isDuplicateFile = combinedDocumentsList.some(
                (doc: DocumentOption) => doc.id === selectedBasicTemplate.id
            )
            if (isDuplicateFile) {
                setAlertSnackbarProps({
                    title: 'Error',
                    message: 'Template already added.',
                    intent: 'error',
                })
                return setAlertSnackbarOpen(true)
            }

            setCombinedDocumentsList([
                ...combinedDocumentsList,
                selectedBasicTemplate,
            ])
            setSelectedBasicTemplate(null)
            setCombinedDocumentsListUpdated(true)
        }
    }

    const handleFileDragAndDrop = (file: File[]) => {
        if (isExceedingMaxTotalUploadLimit(file[0]?.size)) return
        if (!file.length) {
            setAlertSnackbarProps({
                title: 'Error',
                message:
                    `Only single JPG files of no more than ${formattedMaxUploadFileSize}MB in total size are allowed.`,
                intent: 'error',
            })
            return setAlertSnackbarOpen(true)
        }

        setCurrentFileDnDInfo(file[0])
    }

    const handleEnableSaveAndContinueBtn = (documents: DocumentOption[]) => {
        const disableSaveAndContinue = documents
            .filter((doc: DocumentOption) => doc.required)
            .some((doc: DocumentOption) => !doc.file)
        setEnableSaveAndContinue(!disableSaveAndContinue)
        !disableSaveAndContinue && setCombinedDocumentsListUpdated(false)
    }

    const handleUnstageDocument = (unstageDoc: DocumentOption) => {
        let updatedCombinedDocumentsList: DocumentOption[] = []
        if (!unstageDoc.default) {
            updatedCombinedDocumentsList = combinedDocumentsList.filter(
                (doc: DocumentOption) => doc.id !== unstageDoc.id
            )
        } else {
            updatedCombinedDocumentsList = combinedDocumentsList.map(
                (doc: DocumentOption) =>
                    doc.id === unstageDoc.id
                        ? {
                              ...doc,
                              file: undefined,
                          }
                        : doc
            )
        }
        setCombinedDocumentsList(updatedCombinedDocumentsList)
        handleEnableSaveAndContinueBtn(updatedCombinedDocumentsList)
        updatedCombinedDocumentsList.some((doc: DocumentOption) => doc.file) ||
        savedDocumentsList.some((doc: DocumentOption) => doc.file)
            ? setCombinedDocumentsListUpdated(true)
            : setCombinedDocumentsListUpdated(false)
    }

    const formatBytes = (bytes: number, decimals = 2) => {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    useEffect(() => {
        if (initialCaseDetails) {
            setFormInputValues({
                product_id: initialCaseDetails?.product_id ?? null,
                category_id: null,
                sale_type: initialCaseDetails?.sale_type ?? null,
                is_shippable: initialCaseDetails?.is_shippable ?? false,
                is_service: initialCaseDetails?.is_service ?? false,
                is_subscription: initialCaseDetails?.is_subscription ?? false,
                new_product_name: '',
                new_product_description: '',
                type_id: initialCaseDetails?.is_shippable
                    ? 1
                    : initialCaseDetails?.is_service
                    ? 4
                    : 2,
                fields: []
            })
        }
        // eslint-disable-next-line
    }, [
        initialCaseDetails,
    ])

    return (
        <>
            <div id={idDirectory.divRoot} className={`${className}`}>
                {/* SECTION ONE */}
                <div id={idDirectory.divTemplates}>
                    <Typography
                        variant="h5"
                        className={
                            'emp-uploadCaseBuilderDocumentsStep3-typography'
                        }
                    >
                        Basic Templates to Include:
                    </Typography>
                    <div
                        className={
                            'emp-uploadCaseBuilderDocumentsStep3-basicUploadContainer'
                        }
                    >
                        <div
                            id={idDirectory.divTemplatesLibrary}
                            className={
                                'emp-uploadCaseBuilderDocumentsStep3-templateLibraryContainer'
                            }
                        >
                            <SearchableSelect
                                accessor="name"
                                value={selectedBasicTemplate?.name}
                                options={basicTemplatesList ?? []}
                                hideSearch
                                onValueChange={(e) =>
                                    setSelectedBasicTemplate(e)
                                }
                                placeholder={'Choose a template from list.'}
                                testId="basicTemplates"
                                loadingValues={loadingBasicTemplatesList}
                            >
                                {!basicTemplatesList.length &&
                                    !loadingBasicTemplatesList && (
                                        <div
                                            className={
                                                'emp-uploadCaseBuilderDocumentsStep3-searchableSelectText'
                                            }
                                        >
                                            No basic templates available.
                                        </div>
                                    )}
                            </SearchableSelect>

                            <Button
                                size={'small'}
                                color="secondary"
                                variant="contained"
                                onClick={handleAddBasicTemplate}
                                sx={[
                                    client === 'jpmc' &&
                                        clientTheme.buttons.greyButton.style,
                                ]}
                            >
                                Add
                            </Button>
                        </div>
                        <div
                            id={idDirectory.divUploadTemplates}
                            className={
                                'emp-uploadCaseBuilderDocumentsStep3-uploadTemplatesContainer'
                            }
                        >
                            {combinedDocumentsList
                                .filter((doc: DocumentOption) => !doc.default)
                                .map((doc: DocumentOption, idx: number) => {
                                    return (
                                        <div
                                            key={`key-templates-tag-${idx}`}
                                            className={
                                                'emp-uploadCaseBuilderDocumentsStep3-templatesTagContainer'
                                            }
                                        >
                                            {doc.name}
                                            <FontAwesomeIcon
                                                icon={faTimesCircle}
                                                className={
                                                    'emp-uploadCaseBuilderDocumentsStep3-timesIcon'
                                                }
                                                onClick={(): void =>
                                                    handleUnstageDocument(doc)
                                                }
                                            />
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>
                <Divider
                    className={'emp-uploadCaseBuilderDocumentsStep3-divider'}
                />
                {/* SECTION TWO */}
                <div
                    id={idDirectory.divUpload}
                    className={
                        'emp-uploadCaseBuilderDocumentsStep3-documentUploadContainer'
                    }
                >
                    {/* SECTION TWO - LEFT SIDE */}
                    <div id={idDirectory.divUploadFile}>
                        <div>
                            <FileDnD
                                accepts={['.jpg']}
                                onDrop={handleFileDragAndDrop}
                                icon={UploadIcon}
                                fileUploadInstructionText="Drag & Drop to upload your JPG file, or browse"
                                fileUploadCriteria={`Files should not exceed ${formattedMaxUploadFileSize}MB total. Allowed format: JPG`}
                                maxUploadFileSize={maxUploadFileSize}
                                enableCustomFileDnDFeature={Boolean(
                                    currentFileDnDInfo
                                )}
                                customFileDnDFeature={
                                    <UploadCaseBuilderFileDnD
                                        caseId={caseId ?? 0}
                                        documentTypesList={documentTypesList}
                                        selectedDocumentType={
                                            selectedDocumentType
                                        }
                                        setSelectedDocumentType={
                                            setSelectedDocumentType
                                        }
                                        currentFileDnDInfo={currentFileDnDInfo}
                                        setCurrentFileDnDInfo={
                                            setCurrentFileDnDInfo
                                        }
                                        combinedDocumentsList={
                                            combinedDocumentsList
                                        }
                                        setCombinedDocumentsList={
                                            setCombinedDocumentsList
                                        }
                                        setAlertSnackbarOpen={
                                            setAlertSnackbarOpen
                                        }
                                        setAlertSnackbarProps={
                                            setAlertSnackbarProps
                                        }
                                        handleEnableSaveAndContinueBtn={
                                            handleEnableSaveAndContinueBtn
                                        }
                                        setCombinedDocumentsListUpdated={
                                            setCombinedDocumentsListUpdated
                                        }
                                    />
                                }
                            />
                        </div>
                    </div>
                    {/* SECTION TWO - RIGHT SIDE */}
                    <div id={idDirectory.divTemplatesAndFiles}>
                        <Typography
                            variant="h6"
                            className={
                                'emp-uploadCaseBuilderDocumentsStep3-typography'
                            }
                        >
                            {`Upload Additional Documents (Maximum total size of
                            ${formattedMaxUploadFileSize}MB):`}
                        </Typography>
                        {loadingDocumentTypesList ? (
                            <div
                                className={
                                    'emp-uploadCaseBuilderDocumentsStep3-loadingContainer '
                                }
                            >
                                <LoadingIndicator />
                            </div>
                        ) : documentTypesList.length ? (
                            <DragDropContext
                                onDragEnd={handleReorderCombinedDocumentsList}
                            >
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            id={idDirectory.divDocumentTypes}
                                            className={
                                                'emp-uploadCaseBuilderDocumentsStep3-documentTypesContainer'
                                            }
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {combinedDocumentsList.map(
                                                (
                                                    doc: DocumentOption,
                                                    idx: number
                                                ) => (
                                                    <Draggable
                                                        key={`doc-${idx}`}
                                                        draggableId={`doc-${idx}`}
                                                        index={idx}
                                                    >
                                                        {(
                                                            provided,
                                                            snapshot
                                                        ) => (
                                                            <div
                                                                className={
                                                                    'emp-uploadCaseBuilderDocumentsStep3-documentType'
                                                                }
                                                                key={`document-${doc.id}`}
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <div
                                                                    className={
                                                                        'emp-uploadCaseBuilderDocumentsStep3-documentTypeTitleName'
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faGripVertical
                                                                        }
                                                                        className={
                                                                            'emp-uploadCaseBuilderDocumentsStep3-verticalGripIcon'
                                                                        }
                                                                    />
                                                                    <Lamp
                                                                        color={
                                                                            doc.file
                                                                                ? 'green'
                                                                                : 'grey'
                                                                        }
                                                                        size={
                                                                            'sm'
                                                                        }
                                                                        className={
                                                                            'emp-uploadCaseBuilderDocumentsStep3-lampIcon'
                                                                        }
                                                                    />
                                                                    {doc.name}
                                                                    <span
                                                                        style={{
                                                                            color:
                                                                                '#c00',
                                                                        }}
                                                                    >
                                                                        {doc.required &&
                                                                            '*'}
                                                                    </span>
                                                                </div>

                                                                {doc.file
                                                                    ?.filename && (
                                                                    <div
                                                                        className={
                                                                            'emp-uploadCaseBuilderDocumentsStep3-documentTypeFileName'
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {doc
                                                                                .file
                                                                                ?.filename ??
                                                                                ''}
                                                                        </span>
                                                                        {doc.file?.size ? ` - ${formatBytes(doc.file?.size)}` : ''}
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={(): void =>
                                                                                handleUnstageDocument(
                                                                                    doc
                                                                                )
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={
                                                                                    faTimes
                                                                                }
                                                                            />
                                                                        </IconButton>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        ) : (
                            <Typography
                                variant="h6"
                                className={
                                    'emp-uploadCaseBuilderDocumentsStep3-noDocTypesFoundTypography'
                                }
                            >
                                No document types found.
                            </Typography>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default UploadCaseBuilderDocumentsStep3

/**
 * @description Directory for ID Attributes on Alert Snackbar.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    divSnackbar: `alertSnackbar-div-snackbar`,
    divAlert: `alertSnackbar-div-alert`,
    divMsg: `alertSnackbar-div-message`,
}

export default idDirectory

/**
 * @description Directory for ID Attributes on Onboarding.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    onboarding: {
        btnOnboarding: `onboarding-btn-initiateOnboarding`,
        divContainer: `onboarding-div-container`,
    },
    editOnboarding:{
        btnFinalizeOnboarding: `editOnboarding-btn-finalizeOnboarding`,
        dialogModal: `editOnboarding-dialog-modal`,
        btnConfirm: `editOnboarding-btn-confrim`,
        btnCancel: `editOnboarding-btn-cancel`,
    }
}

export default idDirectory

/**
 * @description Directory for ID Attributes on Advanced Filters.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    flexAForm: {
        divRoot: `flexAForm-div-root`,
        formContainer: `flexAForm-form-container`,
        divFilterContainer: `flexAForm-div-filterContainer`,
        divFilterField: `flexAForm-div-filterField`,
        labelFilterTitle: `flexAForm-label-filterTitle`,
    },
}

export default idDirectory

import {
    alt_metrics,
    default_metrics,
    worldpay_metrics,
    bluesnap_metrics,
    merchantE_metrics,
} from './clients/index'

export interface MetricLinkFilterPreset {
    filter_name: string
    filter_value: string | number | any
}

interface Metric {
    title: string
    timeframe: string | null
    link: {
        to: string
        text?: string
        filter_presets: MetricLinkFilterPreset[]
    } | null
    icon: any // to accomodate the FontAwesome icons
    variant: 'default' | 'alt'
    backgroundColor?: string
    borderTopColor?: string
}

export interface MetricObject {
    chargebacks: Metric
    caseFraudPercent: Metric
    chargebacksExpiring?: Metric
    mids?: Metric
    newChargebacks?: Metric
    usersCount?: Metric
}

export type MetricVariant =
    | 'alt'
    | 'cb911'
    | 'worldpay'
    | 'tillpayments'
    | 'central'
    | 'bluesnap'
    | 'paynetworx'
    | 'elavon'

type MetricDefinitionsMap = { [variant in MetricVariant]: MetricObject }

const metricsMap: MetricDefinitionsMap = {
    alt: alt_metrics,
    cb911: default_metrics,
    worldpay: worldpay_metrics,
    tillpayments: default_metrics,
    central: merchantE_metrics,
    bluesnap: bluesnap_metrics,
    paynetworx: default_metrics,
    elavon: default_metrics,
}

export const getMetrics = (variant: MetricVariant) => metricsMap[variant]

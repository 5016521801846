/**
 * @description Directory for ID Attributes on StandardFooter.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    footer: `standardFooter-footer-footer`,
    link: `standardFooter-link-footerTitle`,
}

export default idDirectory

/**
 * @description Directory for ID Attributes on UploadingOnboarding.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    dialogModal: `uploadingOnboarding-dialog-modal`,
    divRoot: `uploadingOnboarding-div-root`,
    divUploadFile: `uploadingOnboarding-div-uploadFile`,
    divTemplatesAndFiles: `uploadingOnboarding-div-templatesAndFiles`,
    btnMerchantTemplate: `uploadingOnboarding-btn-merchantTemplate`,
    btnMIDTemplate: `uploadingOnboarding-btn-MIDTemplate`,
    divFileList: `uploadingOnboarding-div-fileList`,
    btnUpload: `uploadingOnboarding-btn-upload`,
    btnUploadDisable: `uploadingOnboarding-btn-uploadDisable`,
    divUpload: `uploadingOnboarding-div-upload`,
    divTitle: `uploadingOnboarding-div-titleTemplates`,
}

export default idDirectory

import axios from 'axios'
import { useActiveMerchant } from 'components/ActiveMerchantContext'
import { useEffect, useState } from 'react'

export enum CaseFilters {
    Primary = 1,
    Secondary = 2,
    RFIS = 9,
}

export const useCaseFilterValue = (
    params: any,
    value: CaseFilters | null,
    isFraud: number | null = null,
    dayValue: number | null = null
) => {
    const { id: merchantId } = useActiveMerchant()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [total, setTotal] = useState(0)

    if (params) {
        Object.keys(params).forEach((key) => {
            if (params[key] === '') delete params[key]
        })
    }

    useEffect(() => {
        if (merchantId) {
            setLoading(true)
            axios
                .get('cm/cases', {
                    params: {
                        ...params,
                        start: undefined,
                        sort_by: undefined,
                        sort_order: undefined,
                        client_id: merchantId,
                        limit: 0,
                        is_fraud: isFraud,
                        cycle: value,
                        primary_case_due_days: dayValue,
                        filter_group:
                            dayValue !== null ? 'primary_case_expire' : null,
                    },
                })
                .then((res) => setTotal(res.data.pagination.total))
                .catch((err) => setError(err))
                .finally(() => setLoading(false))
        }
    }, [merchantId, isFraud, value, dayValue, params])

    return {
        loading,
        error,
        totalCases: total,
    }
}

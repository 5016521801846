/**
 * @description Directory for ID Attributes on PaginationAGGrid.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `PaginationAGGrid-div-root`,
    divPageInfo: `PaginationAGGrid-div-pageInfo`,
    divPageResults: `PaginationAGGrid-div-pageResults`,
    divPageSize: `PaginationAGGrid-div-pageSize`,
    divPageNav: `PaginationAGGrid-div-pagenav`,
    divPageNavBackward: `PaginationAGGrid-div-pageNavBackward`,
    divPageNavForward: `PaginationAGGrid-div-pageNavForward`,
    divPageNavDisplay: `PaginationAGGrid-div-pageNavDisplay`,
    divPageNavRefresh: `PaginationAGGrid-div-pageNavRefresh`,
    btnFirst: `PaginationAGGrid-btn-first`,
    btnPrevious: `PaginationAGGrid-btn-previous`,
    btnNext: `PaginationAGGrid-btn-next`,
    btnLast: `PaginationAGGrid-btn-last`,
    btnRefresh: `PaginationAGGrid-btn-refresh`,
}

export default idDirectory

import axios from 'axios'

type OnboardingData = any

type GetServiceLevelList = () => Promise<OnboardingData>
type GetPlatformList = () => Promise<OnboardingData>

interface IOnboardingApi {
    getServiceLevelList: GetServiceLevelList
    getPlatformList: GetPlatformList
}

export interface SelectOptions {
    name: string
    value: string
    label: string
}

export const capitalizeFunc = (word: string) => {
    return word
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
}

class OnboardingApi implements IOnboardingApi {
    getServiceLevelList() {
        const serviceLevelList: SelectOptions[] = [
            {
                name: 'Pick Service Level',
                value: 'Pick Service Level',
                label: 'Pick Service Level',
            },
        ]

        return axios
            .get(`/cm/gen/service-levels`)
            .then(({ data }) => {
                data.data.forEach((data: { id: number; name: string }) => {
                    serviceLevelList.push({
                        name: capitalizeFunc(data.name),
                        value: capitalizeFunc(data.name),
                        label: capitalizeFunc(data.name),
                    })
                })
                return {
                    service_level: { list: serviceLevelList, text: 'capital' },
                }
            })
            .catch(() => {
                return {
                    service_level: { list: serviceLevelList, text: 'capital' },
                }
            })
    }

    getPlatformList() {
        const platformList: SelectOptions[] = [
            {
                name: 'Pick Platform',
                value: 'Pick Platform',
                label: 'Pick Platform',
            },
        ]
        return axios
            .get(`/cm/gen/processors`)
            .then(({ data }) => {
                data.data.forEach(
                    (data: { id: number; alias: string; name: string }) => {
                        platformList.push({
                            name: data.name.toUpperCase(),
                            value: data.name.toUpperCase(),
                            label: data.alias.toUpperCase(),
                        })
                    }
                )
                return {
                    platform: { list: platformList, text: 'upper case' },
                }
            })
            .catch(() => {
                return {
                    platform: { list: platformList, text: 'upper case' },
                }
            })
    }
}

export default new OnboardingApi()

import gauge from 'assets/icons/icon-gauge.svg'
import merchant from 'assets/icons/icon-merchant.svg'
import timer from 'assets/icons/icon-timer.svg'
import warning from 'assets/icons/icon-warning.svg'
import { dateUtilities } from 'utils/dateUtilities'
import { MetricObject } from '../metrics-config'
import { format } from 'date-fns'

const { getMonth, getNextDays } = dateUtilities

export const formatGetNextDays = (days: number) => {
    const d = getNextDays(days)
    return {
        from: format(d.from, 'yyyy-MM-dd'),
        to: format(d.to, 'yyyy-MM-dd'),
    }
}

export const default_metrics: MetricObject = {
    chargebacks: {
        title: 'Chargebacks',
        timeframe: '(1st Chargebacks This Month)',
        link: {
            to: '/chargebacks',
            text: 'Chargebacks',
            filter_presets: [
                {
                    filter_name: 'cycle_id',
                    filter_value: [
                        {
                            name: 'First Chargeback',
                            id: 1,
                            value: 1,
                        },
                    ],
                },
                {
                    filter_name: 'post_date',
                    filter_value: getMonth(),
                },
            ],
        },
        variant: 'default',
        icon: gauge,
    },
    caseFraudPercent: {
        title: 'Fraudulent Chargebacks',
        timeframe: '(This Month)',
        link: {
            to: '/chargebacks',
            text: 'View More',
            filter_presets: [
                {
                    filter_name: 'cycle_id',
                    filter_value: [
                        {
                            name: 'First Chargeback',
                            id: 1,
                            value: 1,
                        },
                    ],
                },
                {
                    filter_name: 'post_date',
                    filter_value: getMonth(),
                },
                {
                    filter_name: 'reason_code',
                    filter_value: [
                        {
                            name: 'is_fraud',
                            id: 1,
                            value: 'is_fraud',
                        },
                    ],
                },
            ],
        },
        variant: 'default',
        icon: warning,
    },
    chargebacksExpiring: {
        title: 'Chargebacks Expiring',
        timeframe: '(72hrs)',
        link: {
            to: '/chargebacks',
            text: 'View More',
            filter_presets: [
                {
                    filter_name: 'status_group_id',
                    filter_value: [
                        {
                            name: 'New',
                            id: 1,
                            value: '1,2',
                        },
                    ],
                },
                {
                    filter_name: 'date_due',
                    filter_value: formatGetNextDays(3),
                },
            ],
        },
        variant: 'default',
        icon: timer,
    },
    mids: {
        title: 'MIDS',
        timeframe: null,
        link: {
            to: '/merchant-hierarchy',
            text: 'View More',
            filter_presets: [
                {
                    filter_name: 'mids_tile_clicked',
                    filter_value: null,
                },
            ],
        },
        variant: 'default',
        icon: merchant,
    },
}

import React, { useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import LineBarChart from 'components/LineBarChart'
import { IReportFilter } from 'views/Reports'
import { clientTheme } from 'theme-exports'

/**
 * @name ByNonUniqueCustomer
 * @desc Render Card Type chart
 * @filterValues IReportsFilter
 */
const ByNonUniqueCustomer = ({
    filterValues,
}: {
    filterValues: IReportFilter
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<
        { date: string; value: number; count: number }[]
    >([])

    React.useEffect(() => {
        setLoading(true)
        ReportsApi.getByNonUniqueCustomer(filterValues).then((data) => {
            setLoading(false)
            setData(data)
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <LineBarChart
            chartTitle="Percentage of Chargebacks originating from non-unique customers"
            data={data}
            loading={loading}
            barColor={
                clientTheme.charts.lineBarCharts.byNonUniqueCustomerLineBarChart
                    .barColor
            }
            lineColor={
                clientTheme.charts.lineBarCharts.byNonUniqueCustomerLineBarChart
                    .lineColor
            }
            leftYAxisLabel={'Ratio'}
            rightYAxisLabel={'Count'}
            yAxisPercent
        />
    )
}

export default ByNonUniqueCustomer

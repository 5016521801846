/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:2f02ef55-bfdf-453f-bbcb-4a77b4a00079",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_AY29n81DI",
    "aws_user_pools_web_client_id": "3tevmkmqu8de8hu539mtltqj11",
    "sso_auth_key": "",
    "oauth": {},
    "aws_content_delivery_bucket": "worldpay-dev-eu-west-1-website",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://worldpay-dev.merchant-dispute.com"
};


export default awsmobile;

import React, { useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import PieChart from 'components/PieChart/PieChart'
import { IReportFilter } from 'views/Reports'
import { clientTheme } from 'theme-exports'
/**
 * @name ByReasonCode
 * @desc Render % of Chargebacks by Reason Code (Top 5)
 * @filterValues IReportsFilter
 */
const ByReasonCode = ({
    filterValues,
}: {
    filterValues: IReportFilter
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<
        {
            name: string
            value: number
            tooltipName?: string
        }[]
    >([])

    React.useEffect(() => {
        setLoading(true)
        ReportsApi.getByReasonCode(filterValues).then((data) => {
            setLoading(false)
            setData(data)
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    const colorPalette = clientTheme.charts.pieCharts.byReasonCodePieChart

    return (
        <PieChart
            chartTitle="% of Chargebacks by Reason Code (Top 5)"
            data={data}
            loading={loading}
            colorPalette={colorPalette}
            showLegend={true}
            testId="byReasonCodeTop5"
            height={425}
        />
    )
}

export default ByReasonCode

import React, { useState, useEffect } from 'react'
import UsersApi from 'api/UsersApi'
import { ModalArea, SwitchList, AlertSnackbar } from 'components'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface ISitePermissions {
    [key: string]: {
        id: number
        name: string
        key: string
        label: string
        isChecked: boolean
    }
}

interface ISitePermissionsProps {
    rowData: any
    modalOpen: boolean
    setModalOpen: (isopen: boolean) => void
}

export const SitePermissions: React.FC<ISitePermissionsProps> = ({
    rowData,
    modalOpen,
    setModalOpen,
}) => {
    const [options, setOptions] = useState<ISitePermissions>({})
    const [loadingOptions, setLoadingOptions] = useState<boolean>(false)
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false)
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const roleId = rowData.original.role.id
    const userId = rowData.original.id

    useEffect(() => {
        if (modalOpen) {
            setLoadingOptions(true)
            UsersApi.getSitePermissions(userId, roleId)
                .then(setOptions)
                .finally(() => {
                    setLoadingOptions(false)
                })
        }
    }, [modalOpen, userId, roleId])

    const handleChange = (key: string): void => {
        const updatedOptions = {
            ...options,
            [key]: {
                ...options[key],
                isChecked: !options[key].isChecked,
            },
        }
        setLoadingUpdate(true)
        UsersApi.setSitePermission(
            userId,
            updatedOptions[key].id,
            updatedOptions[key].isChecked
        )
            .then(() => {
                setOptions(updatedOptions)
                setAlertSnackbarProps({
                    title: 'Success',
                    message: 'Site permission was successfully updated.',
                    intent: 'success',
                })
            })
            .catch(() => {
                setAlertSnackbarProps({
                    title: 'Error',
                    message: `An error occurred updating site permission. Please try again later.`,
                    intent: 'error',
                })
            })
            .finally(() => {
                setAlertSnackbarOpen(true)
                setLoadingUpdate(false)
            })
    }

    return (
        <ModalArea
            title="Site Permissions"
            open={modalOpen}
            closeModal={() => setModalOpen(false)}
            maxWidth="sm"
        >
            <div id={'usersSitePermissions'}>
                <SwitchList
                    id="site-permissions-list"
                    onChange={handleChange}
                    options={options}
                    loadingOptions={loadingOptions}
                    loadingUpdate={loadingUpdate}
                />
            </div>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </ModalArea>
    )
}

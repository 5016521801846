import React from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    CircularProgress,
    Typography,
    Divider,
} from '@mui/material'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clientTheme } from 'theme-exports'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import idDirectory from './idAttributes'

export interface IAccentAreaModalProps {
    openModal: boolean
    onModalClose: () => void
    modalHeaderTitle: string
    children: React.ReactNode
    testId: string
    maxModalWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
    enablePrimaryActionBtn?: boolean
    primaryBtnText?: string
    handlePrimaryActionBtn?: () => void
    inactivatePrimaryActionBtn?: boolean
    isLoadingPrimaryActionBtn?: boolean
    enableSecondaryActionBtn?: boolean
    secondaryBtnText?: string
    handleSecondaryActionBtn?: () => void
    inactivateSecondaryActionBtn?: boolean
    isLoadingSecondaryActionBtn?: boolean
    disableBackdropClick?: boolean
    className?: string
    borderTopColor?: string
}

const AccentAreaModal = ({
    openModal,
    onModalClose,
    modalHeaderTitle,
    children,
    testId,
    maxModalWidth = 'md',
    enablePrimaryActionBtn = true,
    primaryBtnText = 'Submit',
    handlePrimaryActionBtn = () => {},
    inactivatePrimaryActionBtn = false, // Shows primary button but will disable it
    isLoadingPrimaryActionBtn = false,
    enableSecondaryActionBtn = true,
    secondaryBtnText = 'Cancel',
    handleSecondaryActionBtn = () => {},
    inactivateSecondaryActionBtn = false, // Shows secondary button but will disable it
    isLoadingSecondaryActionBtn = false,
    disableBackdropClick = true,
    className = '',
    borderTopColor = 'default',
}: IAccentAreaModalProps): React.ReactElement => {
    const { user } = useAuthedUser()
    const {
        client_theme_top_border: showClientThemeTopBorder,
    } = useFeatureToggle('ACCENT_AREA')
    const { enabled: themeVariationEnabled } = useFeatureToggle(
        'HAS_THEME_VARIATIONS'
    )
    const enableDefaultStyle = !Boolean(
        user?.theme_variant && user?.theme_variant > 1
    )

    if (!user?.theme_variant && themeVariationEnabled) {
        return <></>
    }

    return (
        <Dialog
            fullWidth
            open={openModal}
            onClose={disableBackdropClick ? () => {} : onModalClose}
            className={className}
            maxWidth={maxModalWidth}
            id={`${idDirectory.divRoot}-${testId}`}
        >
            <DialogTitle
                className={`${'emp-accentAreaModal-dialogTitle'}`}
                style={{
                    ...(showClientThemeTopBorder && {
                        borderRadius:
                            clientTheme.accentAreaTopBorderAccent.borderRadius,
                        borderTop: `3px solid ${clientTheme.accentAreaTopBorderAccent.backgroundColor} !important`,
                    }),
                    borderTop: `${
                        borderTopColor !== 'default'
                            ? `3px solid ${borderTopColor}`
                            : enableDefaultStyle
                            ? `3px solid ${clientTheme.primaryLight}`
                            : `3px solid #ff0000`
                    }`,
                }}
                id={`${idDirectory.divTitle}-${testId}`}
            >
                <Typography className={'emp-accentAreaModal-modalTitle'}>
                    {modalHeaderTitle}
                </Typography>
                <FontAwesomeIcon
                    icon={faTimes}
                    className={'emp-accentAreaModal-modalClose'}
                    onClick={onModalClose}
                />
            </DialogTitle>
            <Divider style={{ margin: '0px 16px' }} />
            <DialogContent
                className={'emp-accentAreaModal-dialogContent'}
                id={`${idDirectory.divContent}-${testId}`}
            >
                {children}
            </DialogContent>
            {(enableSecondaryActionBtn || enablePrimaryActionBtn) && (
                <DialogActions
                    className={'emp-accentAreaModal-dialogActions'}
                    id={`${idDirectory.divActions}-${testId}`}
                >
                    {enableSecondaryActionBtn && (
                        <Button
                            variant="contained"
                            sx={clientTheme.buttons.defaultButton.style}
                            onClick={handleSecondaryActionBtn}
                            id={`${idDirectory.btnSecondary}-${testId}`}
                            disabled={inactivateSecondaryActionBtn}
                        >
                            {isLoadingSecondaryActionBtn ? (
                                <>
                                    Submitting...{' '}
                                    <CircularProgress
                                        className={
                                            'emp-accentAreaModal-circularProgress'
                                        }
                                        color="secondary"
                                    />
                                </>
                            ) : (
                                secondaryBtnText
                            )}
                        </Button>
                    )}
                    {enablePrimaryActionBtn && (
                        <Button
                            variant="contained"
                            onClick={handlePrimaryActionBtn}
                            color={'secondary'}
                            id={`${idDirectory.btnPrimary}-${testId}`}
                            disabled={inactivatePrimaryActionBtn}
                        >
                            {isLoadingPrimaryActionBtn ? (
                                <>
                                    Submitting...{' '}
                                    <CircularProgress
                                        className={
                                            'emp-accentAreaModal-circularProgress'
                                        }
                                        color="secondary"
                                    />
                                </>
                            ) : (
                                primaryBtnText
                            )}
                        </Button>
                    )}
                </DialogActions>
            )}
        </Dialog>
    )
}

export default AccentAreaModal

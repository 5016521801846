/**
 * @description Directory for ID Attributes on Case Builder.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    caseBuilder: {
        divRoot: `caseBuilder-div-root`,
        divCaseBuilder: `caseBuilder-div-caseBuilder`,
        iconClose: `caseBuilder-icon-close`,
        divTopbar: `caseBuilder-div-topbar`,
        divTopbarTitle: `caseBuilder-div-topbarTitle`,
        divTopbarTitleTypography: `caseBuilder-div-topbarTitleTypography`,
        divTopbarInfoTypography: `caseBuilder-div-topbarInfoTypography`,
        divTopbarDetails: `caseBuilder-div-topbarDetails`,
        divTopbarDetailPanel: `caseBuilder-div-topbarDetailPanel`,
        divTopbarDetailTitle: `caseBuilder-div-topbarDetailTitle`,
        divTopbarDetailValue: `caseBuilder-div-topbarDetailValue`,
        divMain: `caseBuilder-div-main`,
        divFooter: `caseBuilder-div-footer`,
        btnBack: `caseBuilder-btn-back`,
        btnSaveContinue: `caseBuilder-btn-SaveContinue`,
    },
    submitRepresentmentModal: {
        dialogRoot: `submitRepresentmentModal-dialog-root`,
        divContent: `submitRepresentmentModal-div-content`,
        divImage: `submitRepresentmentModal-div-image`,
        divTitle: `submitRepresentmentModal-div-title`,
        divContentText: `submitRepresentmentModal-div-contentText`,
        divBtns: `submitRepresentmentModal-div-btns`,
        btnBack: `submitRepresentmentModal-btns-back`,
        btnSubmit: `submitRepresentmentModal-btns-submit`,
    },
}

export default idDirectory

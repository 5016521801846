/**
 * @description Directory for ID Attributes on Account History.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    btnLockAndUnlock: `accountHistory-btn-lockAndUnlock`,
    iconLock: `accountHistory-icon-lock`,
    iconUnlock: `accountHistory-icon-unlock`,
}

export default idDirectory

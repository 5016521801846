import React, { useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import PieChart from 'components/PieChart/PieChart'
import { IReportFilter } from 'views/Reports'
import { clientTheme } from 'theme-exports'
/**
 * @name ByStatus
 * @desc Render 'Chargebacks By Status'
 * @filterValues IReportsFilter
 */
const ByStatus = ({
    filterValues,
}: {
    filterValues: IReportFilter
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ name: string; value: number }[]>([])

    React.useEffect(() => {
        setLoading(true)
        ReportsApi.getByStatus(filterValues).then((data) => {
            setLoading(false)
            setData(data)
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    const statusColorPalette = clientTheme.charts.pieCharts.byStatusPieChart

    return (
        <PieChart
            chartTitle="Case Status"
            data={data}
            loading={loading}
            colorPalette={statusColorPalette}
            showLegend={true}
            testId="byStatus"
        />
    )
}

export default ByStatus

/**
 * @description Directory for ID Attributes on Dashboard.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    divGrid: `dashboard-div-grid`,
    divMetric: `dashboard-div-metric`,
}

export default idDirectory

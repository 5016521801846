/**
 * @description Directory for ID Attributes on ConfirmCaseBuilderSummaryStep4.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `confirmCaseBuilderSummaryStep4-div-root`,
    divSectionOne: `confirmCaseBuilderSummaryStep4-div-sectionOne`,
    divSectionTwo: `confirmCaseBuilderSummaryStep4-div-sectionTwo`,
    divCustomerProductDetails: `confirmCaseBuilderSummaryStep4-div-customerProductDetails`,
    divCustomerService: `confirmCaseBuilderSummaryStep4-div-customerService`,
    divCasesHeader: `confirmCaseBuilderSummaryStep4-div-casesHeader`,
    divCasesMainHeader: `confirmCaseBuilderSummaryStep4-div-casesMainHeader`,
    divCasesAlertHeader: `confirmCaseBuilderSummaryStep4-div-casesAlertHeader`,
    divCasesGrid: `confirmCaseBuilderSummaryStep4-div-casesGrid`,
}

export default idDirectory

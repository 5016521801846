/* eslint-disable no-shadow */
/**
 *  What features the app has that can be turned on or off by data
 */
export enum EntFeature {
    UNIVERSAL = 'universal',
    DASHBOARD = 'dashboard',
    CHARGEBACKS = 'chargebacks',
    CHARGEBACKS_AGGRID = 'chargebacks',
    ALERTS = 'alerts',
    ALERTS_AGGRID = 'alerts',
    CASE_MANAGEMENT = 'case-management',
    CASE_MANAGEMENT_AGGRID = 'case-management',
    REPORTS = 'reports',
    MID_HEALTH = 'midhealth',
    MID_HEALTH_AGGRID = 'midhealth',
    RESULTANT_KPI = 'resultant',
    ERT = 'ert',
    ERT_LEGACY = 'ert-legacy',
    ERT_INTERNAL = 'ert-internal',
    ERT_BULK_UNPLOAD = 'ert-bulk-upload',
    ERT_APPROVE = 'ert-approve',
    ERT_ADD = 'ert-add',
    USERS = 'users',
    USERS_AGGRID = 'users',
    SETTINGS = 'settings',
    MERCHANT_HIERARCHY = 'hierarchy',
    MERCHANT_HIERARCHY_AGGRID = 'hierarchy-ag-grid',
    TEMPLATE_LIBRARY = 'templatelib',
    TEMPLATE_LIBRARY_AGGRID = 'templatelib',
    CLIENT_CONFIGURATION = 'client-configuration',
    FEATURES_LIST = 'features-list',
    HELP = 'help',
    VIEW_USERS = 'view-users',
    EDIT_USERS = 'edit-users',
    CREATE_USERS = 'create-users',
    VIEW_PLATFORM_NAME = 'view-platform-name',
    PROFILE = 'profile',
    PROFILE_AGGRID = 'profile',
    MTD_REPORTS = 'mtd',
    CASE_BUILDER = 'case-builder',
    RECONCILIATION_REPORT = 'reconciliation-report',
    RECONCILIATION_REPORT_AGGRID = 'reconciliation-report',
    CREATE_MERCHANT = 'ms-merchant-add',
    EDIT_MERCHANT = 'ms-merchant-edit',
    CREATE_MID = 'ms-mid-add',
    EDIT_MID = 'ms-mid-edit',
    MS_VIEW_DETAILS = 'ms-view-details',
    MERCHANT_MID_ONBOARDING = 'merchant-mid-onboarding',
    ONBOARDING = 'onboarding',
    ONBOARDING_LEGACY = 'onboarding-legacy',
    ALERTS_REPORT = 'alerts-reporting',
    QC_REVIEW = 'qc-review',
    QC_REVIEW_AGGRID = 'qc-review',
    QC_REVIEW_CHECKBOX = 'qc-review-checkbox',
    QC_REVIEW_ADD_ERT = 'qc-review-add-ert',
    INVALID_CHARGEBACKS = 'cm-invalid-case',
    INVALID_CHARGEBACKS_AGGRID = 'cm-invalid-case',
    ADMIN_REVIEW = 'review',
    ADMIN_REVIEW_LEGACY = 'review-legacy',
    CASE_ADMIN_REVIEW = 'review-case',
    WEBHOOKS = 'webhooks',
    API_DOCUMENTATION = 'resource-api-documentation',
    CASE_UPDATER = 'case-updater',
    CASE_UPDATER_LEGACY = 'case-updater-legacy',
    PRE_CHARGEBACKS = 'pre-chargebacks',
    PRE_CHARGEBACKS_AGGRID = 'pre-chargebacks-aggrid',
    MONITORING = 'monitoring',
    EDIT_RDR = 'edit_rdr',
    USERS_EXPORT_BUTTON = 'users-export-button',
}

export default EntFeature

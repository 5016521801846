import React from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    CircularProgress,
} from '@mui/material'
import { clientTheme } from 'theme-exports'
import ConfirmImage from 'assets/icons/case-builder__confirm.png'
import idDirectory from '../idAttributes'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'

export interface SubmitRepresentmentModalProps {
    openModal: boolean
    onClose: () => void
    handleSubmitRepresentment: () => void
    isSubmitting: boolean
    alertSnackbarProps: alertSnackbarContentProps
    alertSnackbarOpen: boolean
    handleOnCloseAlertSnackbar: () => void
}

export const SubmitRepresentmentModal = ({
    openModal,
    onClose,
    handleSubmitRepresentment,
    isSubmitting,
    alertSnackbarProps,
    alertSnackbarOpen,
    handleOnCloseAlertSnackbar,
}: SubmitRepresentmentModalProps) => {
    return (
        <Dialog
            open={openModal}
            id={idDirectory.submitRepresentmentModal.dialogRoot}
        >
            <DialogContent
                classes={{
                    root: 'emp-submitRepresentmentModal-dialogContent',
                }}
                id={idDirectory.submitRepresentmentModal.divContent}
            >
                <div id={idDirectory.submitRepresentmentModal.divImage}>
                    <img
                        src={ConfirmImage}
                        alt="case-builder-confirm"
                        className={'emp-submitRepresentmentModal-image'}
                    />
                </div>

                <div
                    className={`emp-submitRepresentmentModal-title`}
                    id={idDirectory.submitRepresentmentModal.divTitle}
                >
                    Your Representment is Almost Ready!
                </div>

                <div
                    className={`emp-submitRepresentmentModal-content`}
                    id={idDirectory.submitRepresentmentModal.divContentText}
                >
                    <div>
                        If all of the information on the representment is
                        correct, it can be compiled and sent. If you wish to
                        review the information, go back.
                    </div>
                    <div style={{ fontWeight: 600 }}>
                        Do you wish to continue?
                    </div>
                </div>
            </DialogContent>
            <DialogActions
                className={`emp-submitRepresentmentModal-dialogActions`}
                id={idDirectory.submitRepresentmentModal.divBtns}
            >
                <Button
                    variant="contained"
                    onClick={onClose}
                    id={idDirectory.submitRepresentmentModal.btnBack}
                    sx={clientTheme.buttons.defaultButton.style}
                >
                    Go Back
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleSubmitRepresentment}
                    id={idDirectory.submitRepresentmentModal.btnSubmit}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? (
                        <>
                            Submitting...{' '}
                            <CircularProgress
                                className={
                                    'emp-submitRepresentmentModal-circularProgress'
                                }
                                color="secondary"
                            />
                        </>
                    ) : (
                        <>Submit Representment</>
                    )}
                </Button>
            </DialogActions>

            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={handleOnCloseAlertSnackbar}
                showCloseIcon
            />
        </Dialog>
    )
}

import { KeyedMutator } from 'swr'
import EntFeature from 'models/EntFeature'

interface PageInfo {
    total: number
    start: number
    limit: number
}

interface PropertiesData {
    is_doc_available: boolean | null
    is_assign_user_enabled: boolean | null
    is_assign_user_visible: boolean | null
    is_dnr_enabled: boolean | null
    is_dnr_visible: boolean | null
    is_revert_dnr_enabled: boolean | null
    is_revert_dnr_visible: boolean | null
    is_upload_representment_enabled: boolean | null
    is_upload_representment_visible: boolean | null
    can_remedy: boolean | null
}

export interface Pagination {
    start: number
    limit: number
    total: number
}

export interface Page<D> {
    data: D[]
    pagination: PageInfo
}

export interface AssignedInfo {
    id: string
    fname: string
    lname: string
}


export enum CaseStatusGroup {
    New = 'New',
    InProgress = 'In Progress',
    Completed = 'Completed',
    Expired = 'Expired',
    DoNotRepresent = 'Do Not Represent',
}

export enum CaseStatusGroupId {
    New = '1',
    InProgress = '2',
    Completed = '3',
    Expired = '4',
    DoNotRepresent = '5',
}

export enum CaseStatusId {
    New = 1,
    Working,
    DocUploaded,
    DoNotRepresent,
    Expired,
    DocCompiling,
    DocCompiled,
    TransportReady,
    TransportProcessing,
    TransportCompleted,
}

export type Cases = Page<Cases>

export enum CaseStatusGroupFilter {
    Working = 'working_cases',
    New = 'new_cases',
    Expiring = 'expiring_cases',
    PrimaryCaseExpire = 'primary_case_expire',
    Assigned = 'assigned',
    DoNotRepresent = 'dnr',
    Expired = 'expired',
    InProgress = 'in_progress',
    Completed = 'completed',
}

export enum VerdictId {
    WillNotRepresent = 1,
    DoNotRepresent,
    VerdictPending,
    Win,
    Loss,
    Pending,
    Default,
}

export interface Flag {
    id: number
    is_flagged: boolean
    comment: string
}

export interface AssignedUser {
    id: number
    fname: string
    lname: string
    date_created: string | null
}

export enum Verdict {
    WillNotRepresent = 'Will Not Represent',
    DoNotRepresent = 'Do Not Represent',
    VerdictPending = 'Verdict Pending',
    Win = 'Win',
    Loss = 'Loss',
    Pending = 'Pending',
    Default = 'default',
}

// Merchant
export interface Merchant {
    alias: string
    business_name: string
    children: any[]
    dba: string
    id: number
    mids: any[]
    parents: any[]
    parent_id: number
    status: {
        id: number
        name: string
    }
}

export type Merchants = Page<Merchant>

// Users
export interface Status {
    id: number
    name: string
}

export interface Role {
    id: number
    name: string
    description: string
}

export interface Resource {}

// [TODO - Rename this to avoid no-redelcare warning]
// eslint-disable-next-line
export interface Merchant {
    id: number
    business_name: string
    status: Status
}

export interface Feature {
    id: number
    name: string
    alias: string
}

export interface User {
    status: Status
    role: Role
    resources: Resource[]
    mids_excluded: any[]
    mids_count: null
    mids_allowed: any[]
    merchant: Merchant
    lname: string
    is_federated: boolean
    is_read_only: boolean
    id: number
    fname: string
    username: string
    features: Feature[]
    email: string
    deny_resources: any[]
    is_read_only: boolean
}

export type Users = Page<User>

export interface UsersInstance {
    data?: Users['data']
    pagination?: Pagination
    error?: any
    mutate: KeyedMutator<Users>
    isValidating: boolean
}

export interface Alert {
    account: string
    alert_id: string
    source: string
    order_id: string
    trans_amount: string
    currency: string
    trans_date: string
    cc_num: string
    cc_type: string
    descriptor: string
    mid: string
    alert_date: string
    outcome: OutcomeCellValue | string
}

export interface AlertsServiceLevel {
    client_id: string | null
    service_level: string | null
    service_level_id: string | null
}

export type Alerts = Page<Alert>

interface AlertsInstance {
    data?: Alerts['data']
    pagination?: Pagination
    error?: any
    mutate: KeyedMutator<Alerts>
    isValidating: boolean
    // swrActions: SWRActions
}

export type OutcomeCellValue =
    | 'Already Refunded'
    | 'Refunded'
    | 'Refund Error'
    | 'No Match Found'
    | 'Declined Transaction'
    | 'Already a Chargeback'
    | 'No Refund'
    | 'Completed'
    | 'Resolved With Customer'
    | 'Open'
    | 'Not Completed'

export interface IDateRange {
    to: string
    from: string
}

export type SetSortInfo = (sortInfo: SortInfo) => void

interface SortInfo {
    sortBy: string
    sortDesc: 'asc' | 'desc'
}

export type Params = {
    // record offset to start at (default: 0)
    start?: number
    // number of records to return (default: 20)
    limit?: number
    // sort field (default: id)
    sort_by?: string
    // sort direction (default: asc)
    sort_order?: 'asc' | 'desc'
    // search term
    search?: string
    // date range 12-11-21|12-25-21
    date_range?: string
    descriptor?: string
}

export interface SWRActions {
    total: number
    rowsPerPage: number
    setRowsPerPage: (size: number) => void
    error: boolean
    search: string
    setSearch: (query: string) => void
    isLoading: boolean
    page: number
    setPage: (page: number) => void
    setSortBy: (sort: boolean, accessor: string) => void
    setSortDesc: (isDesc: 'desc' | 'asc') => void
    setSortInfo: SetSortInfo
    sortInfo: SortInfo
    clearError: () => void
    globalSearch: string
    setDescriptorFilter: (descriptor: string) => void
    setGlobalSearch: (query: string) => void
    setDateRange: (dateRange: string) => void
    exportAll: () => void
    setQueryParams: (params: any) => void
    invalidate: () => void
    selectRow: (rowIndex: string) => void
    selectAllRows: (rowIndexList: string[]) => void
    unselectAllRows: () => void
    selectedRows: string[]
    selectSingleRowClearRest: (rowIndex: string) => void
    resetEndpoint: (limit: number, start: number) => void
    [key: string]: any
    // export all
}

export interface CaseActions {
    caseViewActions: {
        [key: string]: any
    }
    caseNetworkActions: {
        [key: string]: any
    }
    casesLoading: boolean
    caseActionError: boolean
    caseActionSetError: (val: boolean) => void
    caseActionErrorMessage: string
    invalidate: () => void
}

export interface ParamSetter {
    setParams: (params: Partial<Params>) => void
    setQueryParams: (queryParams: any[]) => void
    params: Params
    reset: (limit: number, start: number) => void
}

export interface WhoAmI {
    id: number | undefined
    allow_resources: number[]
    deny_resources: number[]
    email: string | undefined
    features: Feature[]
    fname: string | undefined
    lname: string | undefined
    is_federated: boolean | undefined
    is_read_only: boolean | undefined
    merchant: Merchant | undefined
    mids_allowed: number[]
    mids_count: number | null
    mids_excluded: number[]
    resources: Resource[]
    role: Role | undefined
    roles: any[]
    status: Status | undefined
    username: string | undefined
    fullName: () => void
    hasRole: (role: EntFeature | EntFeature[]) => void
    theme_variant: number | undefined
}

export interface ActiveMerchant {
    id: number
    business_name: string
    status: Status
    isRootMerchant: boolean
    setActiveMerchant: (merchant: ActiveMerchant) => void
}

export interface UiStateStore {
    whoami: WhoAmI | null
    activeMerchant: ActiveMerchant | null
    setActiveMerchant: (merchantId: number | null) => void
    loading: boolean
    error: string | null
    invalidate: (type: 'whoami') => void
}

export interface DefaultValueOption {
    id: number
    value: boolean | number | string
    name: string
}

export type TFieldType =
    | 'select'
    | 'text'
    | 'userDefinedMultiSelect'
    | 'amount'
    | 'date' // Use if user cannot pick a future date for date range.
    | 'due-date' // Use if user can pick a future date for date range.

export interface IAdvancedFilterConfig {
    alias: string
    alias_override: string | undefined
    label: string
    initial_value: string
    initial_compare_value: string | undefined // Use if type = "amount".
    type: TFieldType
    endpoint: string | undefined
    default_options: DefaultValueOption[] | undefined // Needed if type = "select" AND endpoint = undefined.
    is_multiple_choice: boolean
    is_clear_date_icon: boolean
    is_enabled: boolean
}

// Form Fields for advanced search
export interface IFormField {
    key: string
    name: string
    initialValue: string
    type: TFieldType
    filterOverrideName: string | undefined
    apiValuesPath: string | undefined
    hideField: boolean
    defaultValueOptions: DefaultValueOption[] | undefined
    clearDateIcon: boolean
    disableMultipleSelections: boolean
    initialCompareValue?: string | undefined // Use if type = "amount".
}

export type AdvancedSearchValues = Record<string, any>

export type HandleAdvancedSearchSubmit = (
    params: AdvancedSearchValues,
    reload: boolean
) => { values: any }

export interface FilterTabDefinitions {
    name: string
    value: string | number
    icon: string
    filterGroup: CaseStatusGroupFilter
}

type SetSelectedTabFilter = (value: CaseStatusGroupFilter) => void

export type ModalVariant =
    | 'assignUser'
    | 'moveToDnr'
    | 'revertDnr'
    | 'uploadDocuments'
    | 'toggleFlag'
    | 'disabledToggleFlag'
    | 'markReviewed'
    | 'disabledMarkReviewed'

export type ModalMode = 'single' | 'multiselect' | undefined

export interface ContextMenuOption {
    value: string
    icon: IconDefinition
    isDisabled?: boolean
    isHidden?: boolean
    operator: (args: any) => any
}

export interface ErtData {
    id: number
    merchant: {
        id: number
        business_name: string
    }
    type: {
        id: number
        name: string
    }
    status: {
        id: number
        name: string
    }
    content: string
    is_active: boolean
    result: string | null // possible null from api
    is_approved: boolean
    approved_by: {
        id: number
        name: string
    }
    date_approved: string
    resolved_by: string | null // possible null from api
    date_resolved: string
    is_notification_sent: boolean
    comments: string[]
    level: {
        id: number
        name: string
    }
    properties: {
        is_approve_enabled: boolean
        is_approve_visible: boolean
        is_archive_enabled: boolean
        is_archive_visible: boolean
        is_case_details_enabled: boolean
        is_case_details_visible: boolean
        is_edit_enabled: boolean
        is_edit_visible: boolean
        is_resolve_enabled: boolean
        is_resolve_visible: boolean
        is_view_enabled: boolean
        is_view_visible: boolean
    }
    // MISSING FROM API RESPONSE
    created_by: {
        id: number
        name: string
    }
    date_created: string
    date_archived: string
    archived_by: string
}

// [TODO - Rename this to avoid no-redelcare warning]
// eslint-disable-next-line
export interface Status {
    id: number
    name: string
}

// [TODO - Rename this to avoid no-redelcare warning]
// eslint-disable-next-line
export interface Role {
    id: number
    name: string
    description: string
}

// [TODO - Rename this to avoid no-redelcare warning]
// eslint-disable-next-line
export interface Resource {}

// [TODO - Rename this to avoid no-redelcare warning]
// eslint-disable-next-line
export interface Merchant {
    id: number
    business_name: string
    status: Status
    features?: any[] | null
}

// [TODO - Rename this to avoid no-redelcare warning]
// eslint-disable-next-line
export interface Feature {
    id: number
    name: string
    alias: string
}

export interface UserModel {
    status: Status
    role: Role
    resources: Resource[]
    mids_excluded: any[]
    mids_count: null
    mids_allowed: any[]
    merchant: Merchant
    lname: string
    is_federated: boolean
    is_read_only: boolean
    id: number
    fname: string
    username: string
    features: Feature[]
    email: string
    deny_resources: any[]
    is_read_only: boolean
}

export interface CaseData {
    admin_review: null | {
        assigned_by: AssignedInfo
        assigned_to: AssignedInfo
        date_created: string
        date_updated: string
    }
    arn: string
    assigned_user_id: null | number
    assigned_user: AssignedUser | null
    auth_no: null | string
    case_no: string
    cc_bin: string
    cc_last_four: null | string
    cc_no: null | string
    cc_type: null | string
    cc_type_id: null | number
    // company_partner_id: null | string
    cycle: string
    cycle_id: number
    date_completed: null | string
    date_due: string
    date_post: string
    date_trans: string
    descriptor: string
    dispute_amt: string
    dispute_currency: string
    external_uuid: null | string
    flag: Flag | null
    id: number
    is_credit: null | boolean
    is_refund: null | boolean
    mid: string
    mid_alias: string
    mid_id: number
    order_id: null | number
    partner_company_id: null | string
    processor: string
    reason_category: string
    reason_category_id: number
    reason_category_description: string
    reason_code: string
    reason_code_id: number
    status: string
    status_group: string
    status_group_id: number
    status_id: number
    trans_amt: string
    trans_currency: string
    trans_id: null | string
    uuid: null | string
    verdict: null | Verdict
    verdict_id: null | VerdictId
    properties: PropertiesData
    toggleFlag: (comment?: string) => void
    doNotRepresent: () => void
    issuer_docs: {
        date_created: string
        filename: string
        id: number
    }[]
}

export interface IFilePlusPages extends File {
    pages: number
}

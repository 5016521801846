/**
 * @description Directory for ID Attributes on AG Data Grid.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    agDataGrid: {
        divRoot: `agDataGrid-div-root`,
        divBottomBar: `agDataGrid-div-bottomBar`,
        divPagination: `agDataGrid-div-pagination`,
    },
    agDataGridLoadingIndicator: {
        divLoading: `agDataGridLoadingIndicator-div-loading`,
    },
    agDataGridSelectAllCheckbox: {
        iconChecked: `agDataGridSelectAllCheckbox-icon-checked`,
        iconNotChecked: `agDataGridSelectAllCheckbox-icon-notChecked`,
    },
}

export default idDirectory

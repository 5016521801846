import { LoadingIndicator } from 'components'
import PiePlot from 'components/PieChart/PiePlot'
import React, { useMemo } from 'react'

interface Datum {
    case_status: string
    count: number
}

interface ChargebacksByStatusProps {
    data: Datum[]
    loading?: boolean
    error?: Error
}

export const ChargebacksByStatusPie: React.FC<ChargebacksByStatusProps> = ({
    data,
    loading = false,
    error = null,
}) => {

    const _data = useMemo(
        () =>
            data.map((i, idx) => ({
                label: i.case_status,
                value: i.count,
                id: idx,
            })),
        [data]
    )

    return (
        <div id={'dashboardChargebacksByStatus'}>
            {loading ? (
                <div className={'emp-loading'}>
                    <LoadingIndicator />
                </div>
            ) : (
                <div className={'emp-root'}>
                    <PiePlot data={_data} />
                </div>
            )}
        </div>
    )
}

import React from 'react'
import { Tooltip } from '@mui/material'

export interface IEllipsifyProps {
    value: string
    testId?: string
    className?: string
    fontSize?: string
}

const Ellipsify = ({
    value,
    testId = '',
    className = '',
    fontSize = '14px',
}: IEllipsifyProps) => {
    return (
        <Tooltip title={value} placement="top-start" arrow>
            <div
                className={`${'emp-ellipsify-container'} ${className}`}
                {...(testId && { id: testId })}
                style={{ fontSize }}
            >
                {value}
            </div>
        </Tooltip>
    )
}

export default Ellipsify

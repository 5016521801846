import React, {useState} from 'react'
import {
    Divider,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material'
import 'views/views.css'
import MerchantApi, { MIDInfo, MIDHistory } from 'api/MerchantApi'
import { format } from 'date-fns'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import { LoadingIndicator, Lamp, Ellipsify, MUIDataGrid } from 'components'
import { AlertsDeflectorStatus } from 'components/MerchantEdit/MerchantEdit'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import EntFeature from 'models/EntFeature'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import { Deflector } from 'views/MerchantHierarchy/MerchantHierarchy.vm'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import idDirectory from './idAttributes'

const UserStatus = ({
    status,
    testId,
}: {
    status: boolean
    testId: string
}) => {
    return (
        <div className={'emp-merchantMidsDetails-lampWrapper'} id={testId}>
            <Lamp
                className={'emp-merchantMidsDetails-lamp'}
                color={status ? 'green' : 'red'}
            />
            {status ? 'Active' : 'Inactive'}
        </div>
    )
}

const EditLabel = ({
    label,
    testId,
}: {
    label: any
    testId: string
}): React.ReactElement => {
    return (
        <div className={'emp-merchantMidsDetails-label'} id={testId}>
            {label}
        </div>
    )
}

/**
 * @name MerchantMIDSDetails
 * @desc Edit MIDS component used in merchant settings (for Chase)
 */
const MerchantMIDSDetails = ({
    midId,
    midDetails,
    availableDeflectors,
}: {
    midId: number
    midDetails: any
    availableDeflectors: Deflector[]
}): React.ReactElement => {
    const uiState = useUiStateContext()
    const { user } = useAuthedUser()

    const isHiddenViewDetails = !uiState.whoami?.hasRole(
        EntFeature.MS_VIEW_DETAILS
    )

    const enableMidHistoryForRole = [
        'cb911 admin',
        'partner admin',
        'partner user',
    ].includes(user?.role?.name.toLowerCase() ?? '')

    const {
        MID_DETAILS,
        DEFLECTORS,
        SHOW_HELIX_MID = false,
        SHOW_MIDS_WPG_FIELD = false,
        INHERIT_MERCHANT_SETTING,
        SHOW_MID_HISTORY,
        MID_UPDATE,
        MID_DETAILS_ID,
        MERCHANT_SERVICE_LEVELS,
    } = useFeatureToggle('MERCHANT_HIERARCHY')
    const {
        enabled: helixMIDEnabled,
        input_edit_label: helixMIDlabel,
    } = SHOW_HELIX_MID
    const { enabled: WPGFieldEnabled } = SHOW_MIDS_WPG_FIELD
    const { enabled: showMIDHistory } = SHOW_MID_HISTORY
    const [midInfo, setMidInfo] = React.useState<MIDInfo>()
    const [midHistory, setMidHistory] = React.useState<MIDHistory[]>([])
    const [midHistoryLoading, setMidHistoryLoading] = React.useState<boolean>(
        true
    )
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const sendMidUpdate = (payload: any, updateInPlaceObj: any) => {
        MerchantApi.updateMid((midId as unknown) as number, payload)
            .then(() => {
                setMidInfo({
                    ...midInfo,
                    ...updateInPlaceObj,
                })
            })
            .catch((e) => {
                setAlertSnackbarProps({
                    title: 'Error',
                    message: `An error occurred. Please try again later.`,
                    intent: 'error',
                })
                setAlertSnackbarOpen(true)
            })
    }

    const updateServiceLevel = (e: any, value: any) => {
        const payload = {
            mid: midInfo?.mid,
            service_level_id: +value,
        }
        const updateInPlaceObj = {
            service_level: {
                id: +value,
            },
        }
        sendMidUpdate(payload, updateInPlaceObj)
    }

    React.useEffect(() => {
        setMidInfo(midDetails)
        if (midDetails?.id && showMIDHistory && enableMidHistoryForRole) {
            setMidHistoryLoading(true)
            MerchantApi.getMidHistory(midDetails?.id)
                .then((data) => {
                    setMidHistory(data.data)
                })
                .finally(() => setMidHistoryLoading(false))
        }
    }, [midDetails, showMIDHistory, enableMidHistoryForRole])

    if (!midInfo)
        return (
            <>
                <LoadingIndicator />
            </>
        )

    const historyColumns = [
        { id: 'username', label: 'User', minWidth: 170 },
        { id: 'date_created', label: 'Date', minWidth: 100 },
        { id: 'field', label: 'Field', minWidth: 100 },
        { id: 'old_value', label: 'Old Value', minWidth: 100 },
        { id: 'new_value', label: 'New Value', minWidth: 100 },
    ]

    return (
        <>
            {!isHiddenViewDetails ? (
                <>
                    <Grid
                        container
                        className={'emp-merchantMidsDetails-root'}
                    >
                        <Grid
                            container
                            item
                            xs={8}
                            className={'emp-merchantMidsDetails-leftGrid'}
                        >
                            <Grid item xs={12}>
                                <EditLabel
                                    label="Overall Status"
                                    testId={`${idDirectory.labelHeader}-overall-status`}
                                />
                                <Grid
                                    container
                                    alignItems="center"
                                    className={
                                        'emp-merchantMidsDetails-innerLeftGrid'
                                    }
                                >
                                    <Grid container item xs={6}>
                                        <UserStatus
                                            status={
                                                midInfo.status.id === 2 || false
                                            }
                                            testId={`${idDirectory.statusField}-overall-status`}
                                        />
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={6}
                                        justifyContent="flex-end"
                                    >
                                        <Tooltip
                                            title={
                                                'Click the edit button to modify'
                                            }
                                            arrow
                                            placement="top"
                                        >
                                            <div>
                                                <Switch
                                                    className={
                                                        'emp-merchantMidsDetails-switchBtn'
                                                    }
                                                    checked={
                                                        midInfo.status.id ===
                                                            2 || false
                                                    }
                                                    name={'mid-status'}
                                                    size={'small'}
                                                    disabled={true}
                                                    id={`${idDirectory.switchField}-overall-status`}
                                                    color="secondary"
                                                />
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                className={
                                    'emp-merchantMidsDetails-middleLeftGrid'
                                }
                            >
                                <Grid item xs={4}>
                                    <EditLabel
                                        label="MID Descriptor"
                                        testId={`${idDirectory.labelHeader}-mid-descriptor`}
                                    />
                                    <div
                                        className={
                                            'emp-merchantMidsDetails-merchantValue'
                                        }
                                    >
                                        <Ellipsify
                                            value={
                                                midInfo.descriptor.descriptor ||
                                                'Unavailable'
                                            }
                                            testId={`${idDirectory.textField}-mid-descriptor`}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <EditLabel
                                        label={MID_DETAILS.input_edit_label}
                                        testId={`${idDirectory.labelHeader}-${MID_DETAILS.input_edit_label}`}
                                    />
                                    <div
                                        className={
                                            'emp-merchantMidsDetails-merchantValue'
                                        }
                                    >
                                        <Ellipsify
                                            value={
                                                midInfo.alias || 'Unavailable'
                                            }
                                            testId={`${idDirectory.textField}-${MID_DETAILS.input_edit_label}`}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <EditLabel
                                        label="MCC"
                                        testId={`${idDirectory.labelHeader}-mcc`}
                                    />
                                    <div
                                        className={
                                            'emp-merchantMidsDetails-merchantValue'
                                        }
                                    >
                                        <Ellipsify
                                            value={midInfo.mcc || 'Unavailable'}
                                            testId={`${idDirectory.textField}-mcc`}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={4}>
                                    <EditLabel
                                        label={
                                            helixMIDEnabled
                                                ? helixMIDlabel
                                                : 'MID'
                                        }
                                        testId={`${idDirectory.labelHeader}-${
                                            helixMIDEnabled
                                                ? helixMIDlabel
                                                : 'MID'
                                        }`}
                                    />
                                    <div
                                        className={
                                            'emp-merchantMidsDetails-merchantValue'
                                        }
                                    >
                                        <Ellipsify
                                            value={midInfo.mid || 'Unavailable'}
                                            testId={`${idDirectory.textField}-${
                                                helixMIDEnabled
                                                    ? helixMIDlabel
                                                    : 'MID'
                                            }`}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <EditLabel
                                        label="CAID"
                                        testId={`${idDirectory.labelHeader}-caid`}
                                    />
                                    <div
                                        className={
                                            'emp-merchantMidsDetails-merchantValue'
                                        }
                                    >
                                        <Ellipsify
                                            value={
                                                midInfo.caid || 'Unavailable'
                                            }
                                            testId={`${idDirectory.textField}-caid`}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <EditLabel
                                        label="Platform"
                                        testId={`${idDirectory.labelHeader}-platform`}
                                    />
                                    <div
                                        className={
                                            'emp-merchantMidsDetails-merchantValue'
                                        }
                                    >
                                        <Ellipsify
                                            value={
                                                midInfo.processor.name ||
                                                'Unavailable'
                                            }
                                            testId={`${idDirectory.textField}-platform`}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            {WPGFieldEnabled ? (
                                <Grid container item xs={12}>
                                    <Grid item xs={4}>
                                        <EditLabel
                                            label="WPG Merchant Code"
                                            testId={`${idDirectory.labelHeader}-wpg-merchant-code`}
                                        />
                                        <div
                                            className={
                                                'emp-merchantMidsDetails-merchantValue'
                                            }
                                        >
                                            <Ellipsify
                                                value={
                                                    midInfo.wpg_merchant_code ||
                                                    'Unavailable'
                                                }
                                                testId={`${idDirectory.textField}-wpg-merchant-code`}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <EditLabel
                                            label="Date Onboarded"
                                            testId={`${idDirectory.labelHeader}-date-onboarded`}
                                        />
                                        <div
                                            className={
                                                'emp-merchantMidsDetails-merchantValue'
                                            }
                                        >
                                            <Ellipsify
                                                value={
                                                    (midInfo.date_created &&
                                                        format(
                                                            new Date(
                                                                midInfo.date_created
                                                            ),
                                                            'MM/dd/yyyy'
                                                        )) ||
                                                    'Unavailable'
                                                }
                                                testId={`${idDirectory.textField}-date-onboarded`}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid item xs={4}>
                                    <EditLabel
                                        label="Date Onboarded"
                                        testId={`${idDirectory.labelHeader}-date-onboarded`}
                                    />
                                    <div
                                        className={
                                            'emp-merchantMidsDetails-merchantValue'
                                        }
                                    >
                                        <Ellipsify
                                            value={
                                                (midInfo.date_created &&
                                                    format(
                                                        new Date(
                                                            midInfo.date_created
                                                        ),
                                                        'MM/dd/yyyy'
                                                    )) ||
                                                'Unavailable'
                                            }
                                            testId={`${idDirectory.textField}-date-onboarded`}
                                        />
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                        <Grid
                            container
                            item
                            xs={4}
                            className={'emp-merchantMidsDetails-rightGrid'}
                        >
                            <Grid item xs={12}>
                                <EditLabel
                                    label="MID Service Level"
                                    testId={`${idDirectory.labelHeader}-mid-service-level`}
                                />
                                <RadioGroup
                                    aria-label="service level"
                                    name={'mid-service-level'}
                                    value={midInfo.service_level.id}
                                    onChange={updateServiceLevel}
                                    className={
                                        'emp-merchantMidsDetails-radiosContainer'
                                    }
                                >
                                    {MERCHANT_SERVICE_LEVELS.basic_service_enabled && (
                                        <FormControlLabel
                                            value={1}
                                            control={<Radio color="secondary" />}
                                            label="Basic Service"
                                            id={`${idDirectory.radioField}-basic-service`}
                                            disabled={true}
                                        />
                                    )}
                                    {MERCHANT_SERVICE_LEVELS.pro_service_enabled && (
                                        <FormControlLabel
                                            value={5}
                                            control={<Radio color="secondary" />}
                                            label="Pro Service"
                                            id={`${idDirectory.radioField}-pro-service`}
                                            disabled={true}
                                        />
                                    )}
                                    {MERCHANT_SERVICE_LEVELS.full_service_enabled && (
                                        <FormControlLabel
                                            value={3}
                                            control={<Radio color="secondary" />}
                                            label="Full Service"
                                            id={`${idDirectory.radioField}-full-service`}
                                            disabled={true}
                                        />
                                    )}
                                </RadioGroup>
                                {INHERIT_MERCHANT_SETTING.enabled && (
                                    <>
                                        <Divider />
                                        <Grid
                                            container
                                            alignItems="center"
                                            className={
                                                'emp-merchantMidsDetails-innerGrid'
                                            }
                                        >
                                            <Grid
                                                container
                                                item
                                                xs={6}
                                                id={`${idDirectory.labelHeader}-inherit-merchant`}
                                                style={{ fontSize: '14px' }}
                                            >
                                                Inherit from Merchant
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                xs={6}
                                                justifyContent="flex-end"
                                            >
                                                <Tooltip
                                                    title={
                                                        'Click the edit button to modify'
                                                    }
                                                    arrow
                                                    placement="top"
                                                >
                                                    <span>
                                                        <Switch
                                                            className={
                                                                'emp-merchantMidsDetails-switchBtn'
                                                            }
                                                            checked={Boolean(
                                                                midInfo.inherit_merchant_deflectors
                                                            )}
                                                            size={'small'}
                                                            disabled
                                                            id={`${idDirectory.switchField}-inherit-merchant`}
                                                            color="secondary"
                                                        />
                                                    </span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                <Divider />
                                {DEFLECTORS.enabled &&
                                    availableDeflectors?.map(
                                        (availableDeflector, idx) => {
                                            // Statuses 1: off - 2: pending - 3: active
                                            let foundDeflector = undefined as any
                                            try {
                                                foundDeflector = midInfo.deflectors.find((obj: any) => {
                                                    return obj.id === availableDeflector.id
                                                })
                                            } catch(e) {}
                                            return (
                                                <div
                                                    key={`key-deflector-${idx}`}
                                                >
                                                    <EditLabel
                                                        label={
                                                            availableDeflector.name
                                                        }
                                                        testId={`${idDirectory.labelHeader}-${availableDeflector.name}`}
                                                    />
                                                    <Grid
                                                        container
                                                        alignItems="center"
                                                        className={
                                                            'emp-merchantMidsDetails-innerGrid'
                                                        }
                                                    >
                                                        <Grid
                                                            container
                                                            item
                                                            xs={6}
                                                        >
                                                            <AlertsDeflectorStatus
                                                                status={foundDeflector ? foundDeflector.status.id : 1}
                                                                label={
                                                                    foundDeflector
                                                                    ? foundDeflector
                                                                          ?.status
                                                                          ?.id === 2
                                                                        ? 'Pending Activation'
                                                                        : foundDeflector
                                                                              ?.status
                                                                              ?.id ===
                                                                          3
                                                                        ? 'Active'
                                                                        : 'Inactive'
                                                                    : 'Inactive'
                                                                }
                                                                testId={`${idDirectory.statusField}-${availableDeflector.name}`}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={6}
                                                            justifyContent="flex-end"
                                                        >
                                                            <Tooltip
                                                                title={
                                                                    'Click the edit button to modify'
                                                                }
                                                                arrow
                                                                placement="top"
                                                            >
                                                                <span>
                                                                    <Switch
                                                                        className={
                                                                            'emp-merchantMidsDetails-switchBtn'
                                                                        }
                                                                        checked={
                                                                            foundDeflector ? foundDeflector.status.id === 1 ? false : true : false
                                                                        }
                                                                        name={
                                                                            availableDeflector.alias
                                                                        }
                                                                        size={
                                                                            'small'
                                                                        }
                                                                        disabled
                                                                        id={`${idDirectory.switchField}-${availableDeflector.name}`}
                                                                        color="secondary"
                                                                    />
                                                                </span>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )
                                        }
                                    )}
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid
                        container
                        item
                        xs={12}
                        className={'emp-merchantMidsDetails-middleLeftGrid'}
                    >
                        <Grid item xs={3}>
                            <EditLabel
                                label={MID_DETAILS_ID?.input_edit_label ?? ''}
                                testId={`${idDirectory.labelHeader}-${
                                    MID_DETAILS_ID?.input_edit_label ?? ''
                                }`}
                            />
                            <div
                                className={
                                    'emp-merchantMidsDetails-merchantValue'
                                }
                            >
                                <Ellipsify
                                    value={midInfo.mid || 'Unavailable'}
                                    testId={`${idDirectory.textField}-${
                                        MID_DETAILS_ID?.input_edit_label ?? ''
                                    }`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <EditLabel
                                label={MID_UPDATE?.input_edit_label ?? ''}
                                testId={`${idDirectory.labelHeader}-${
                                    MID_UPDATE?.input_edit_label ?? ''
                                }`}
                            />
                            <div
                                className={
                                    'emp-merchantMidsDetails-merchantValue'
                                }
                            >
                                <Ellipsify
                                    value={midInfo.alias || 'Unavailable'}
                                    testId={`${idDirectory.textField}-${
                                        MID_UPDATE?.input_edit_label ?? ''
                                    }`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <EditLabel
                                label="Date Onboard"
                                testId={`${idDirectory.labelHeader}-date-onboard`}
                            />
                            <div
                                className={
                                    'emp-merchantMidsDetails-merchantValue'
                                }
                            >
                                <Ellipsify
                                    value={
                                        (midInfo.date_created &&
                                            format(
                                                new Date(midInfo.date_created),
                                                'MM/dd/yyyy'
                                            )) ||
                                        'Unavailable'
                                    }
                                    testId={`${idDirectory.textField}-date-onboard`}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <EditLabel
                                label="Status"
                                testId={`${idDirectory.labelHeader}-status`}
                            />
                            <div
                                className={
                                    'emp-merchantMidsDetails-lampWrapper'
                                }
                                id={`${idDirectory.textField}-status`}
                            >
                                <Lamp
                                    className={'emp-merchantMidsDetails-lamp'}
                                    color={
                                        midInfo.status.id === 2
                                            ? 'green'
                                            : 'red'
                                    }
                                />
                                {midInfo.status.name}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        className={'emp-merchantMidsDetails-middleLeftGrid'}
                    >
                        <Grid item xs={3}>
                            <EditLabel
                                label="Service Level"
                                testId={`${idDirectory.labelHeader}-service-level`}
                            />
                            <div
                                className={
                                    'emp-merchantMidsDetails-merchantValue'
                                }
                            >
                                <Ellipsify
                                    value={
                                        midInfo.service_level.name ||
                                        'Unavailable'
                                    }
                                    testId={`${idDirectory.textField}-service-level`}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </>
            )}
            {showMIDHistory && enableMidHistoryForRole ? (
                <>
                    <Divider />
                    <Typography
                        className={
                            'emp-merchantMidsDetails-midHistoryHeader'
                        }
                        variant="h5"
                    >
                        MID History
                    </Typography>
                    <MUIDataGrid
                        columns={historyColumns}
                        data={midHistory}
                        loading={midHistoryLoading}
                        testId="MIDDetailHistoryGrid"
                        pageSize={5}
                    />
                </>
            ) : (
                <></>
            )}
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => {
                    setAlertSnackbarOpen(false)
                }}
                showCloseIcon
            />
        </>
    )
}

export default MerchantMIDSDetails
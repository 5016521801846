/* eslint-disable no-else-return */
import { Auth } from 'aws-amplify'
import logger from 'utils/logger'
import User, { IUserInfo } from 'models/User'
import Connect from './Connect'
import { clearLocalStorage } from 'context/AuthedUser/AuthedUserContext'

export interface PreviewAuthUser {
    username: string;
    attributes: {
        username: string;
        email: string;
        name: string;
    };
    [key: string]: unknown;
}

export type Whoami = Omit<IUserInfo, "username" | "fname" | "lname"> 

/**
 * The AuthCombinedApi encapsulation to combine Auth and Whoami data
 */
class AuthCombinedApi {
    // Take the aws api auth user, and take attributes we want for rendering account info
    static transformAuthToUser(authUser: PreviewAuthUser, whoami: Whoami): User {

        return new User().fromData({
            ...whoami,
            ...User.transformFullname(authUser?.attributes?.name),
            email: whoami.email,
            username: authUser?.username,
        } as IUserInfo)
    }

/**
 * Get the full whoami database
 * @param {boolean} mock
 */
    static whoami(mock = false): Promise<Whoami | void | false> {
        try {
            // if (mock) {
            //     return Promise.resolve(mockUser as Whoami)
            // } else {
                return Connect.query({ name: 'whoami' }, { method: 'POST' }) as Promise<Whoami | void | false>
            // }
        } catch (errW) {
            logger('Error: Unable to get information about whoami')
            return Promise.resolve(undefined)
        }

    }

    /**
     * Get the cognito authed user
     */
    static cogAuthedUser(mock = false): Promise<PreviewAuthUser | undefined> {
        // if (mock) {
        //     return Promise.resolve(
        //         mockPreviewAuthUser as PreviewAuthUser
        //     )
        // }
        try {
            return Auth.currentAuthenticatedUser({
                bypassCache: false,
            })
        } catch (errA) {
            logger('Error getting authenticated user', errA)
            return Promise.resolve(undefined)
        }
    }

    /**
     * The combined function to get a full user
     */
    static authedUser(mock = false): Promise<User | undefined> {
        try {
            return AuthCombinedApi.cogAuthedUser(mock)?.then(authedUser => {
                if (authedUser) {
                    const usernameIsEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(authedUser.username)
                    const userAttributes = authedUser.attributes as any

                    if (!usernameIsEmail && !userAttributes.family_name) {
                        Auth.signOut().then(() => {
                            clearLocalStorage()
                            window.location.href = '/'
                        })

                        return undefined
                    }

                    return AuthCombinedApi.whoami(mock).then((whoami: Whoami | false | void) => {
                        if (whoami) {
                            return AuthCombinedApi.transformAuthToUser(authedUser, (whoami ?? {}))
                        } else {
                            return undefined
                        }
                    })
                }
                return undefined
            });
        } catch (errU) {
            logger('Error getting the combined user authentication data')
            throw Error(errU.message)
        }
    }
}

export default AuthCombinedApi

import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
    Button,
    Dialog,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
} from '@mui/material'
import idDirectory from './idAttributes'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'
import { bytesToSize } from 'utils/bytesToSize'
import { clientTheme } from 'theme-exports'
import UploadIcon from 'assets/icons/upload-icon-bg-alt.png'
import {
    AccentArea,
    FileDnD,
    SearchableSelect,
    Icon as IconIcon,
} from 'components'
import CB from 'lib'
import { useActiveMerchant } from 'components/ActiveMerchantContext'
import useIsMounted from 'hooks/utils/useIsMounted'
import { iconsTheme } from 'theme-exports'

interface UploadOnboardingProps {
    className?: string
    id?: string
    title?: string
    openModal: boolean
    clientId?: number
    toggleModal: () => void
}

interface CsvFile {
    name: string
    s3Key: string
    size: number
    contentType: string
}

/**
 * Use UploadOnboarding to initate onboarding for Merchant/Mids
 */
const UploadOnboarding = ({
    openModal,
    toggleModal,
}: UploadOnboardingProps) => {
    const { id: merchantId } = useActiveMerchant()
    const { isMounted } = useIsMounted()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [csvFileDrop, setCsvFileDrop] = useState<CsvFile[]>([])
    const [midValue, setMidValue] = useState<string>('')
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const midOptions = [
        {
            id: 0,
            name: 'Merchant',
            value: 'merchant',
        },
        {
            id: 1,
            name: 'MID',
            value: 'mid',
        },
    ]

    const handleMerchantCsvTemplate = React.useMemo(() => {
        let CSVHeaders =
            '"Business Name","DBA","Alias","Service Level","Country","Partner Company ID","Business address","City","State","Zip","Business phone","First name","Last name","Email","Username","Phone"\r\n'
        let CSVExampleData =
            '"ACME Company","ACME","ACME","Self service","United States","99827C","Pineapple Dr","Clearwater","FL","777777","8881111111","John","Doe","some@email.com","johndoe","5555555555"\r\n'

        return 'data:text/csv;charset=utf-8,' + CSVHeaders + CSVExampleData
    }, [])

    const handleFileDragAndDrop = (file: any) => {
        if (!file.length) {
            setAlertSnackbarProps({
                title: 'Error',
                message: 'Only a single CSV file is allowed.',
                intent: 'error',
            })
            return setAlertSnackbarOpen(true)
        }
        CB.documentsCsv
            .uploadOnboardingCsvFile(file)
            .then((data: any) => {
                if (isMounted.current) {
                    setCsvFileDrop([
                        {
                            name: data.filename,
                            s3Key: data.s3_key,
                            size: data.size,
                            contentType: data.content_type,
                        },
                    ])
                }
            })
            .catch((e) => {
                if (isMounted.current) {
                    setAlertSnackbarProps({
                        title: 'Error',
                        message: 'An error occurred. Please try again later.',
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                }
            })
    }

    const handleCsvUpload = () => {
        setLoading(true)
        CB.documentsCsv
            .postOnboardingCsvUpload({
                s3_key: csvFileDrop[0].s3Key,
                type: midValue.toLowerCase(),
                merchant_id: merchantId,
            })
            .then((data: any) => {
                if (isMounted.current) {
                    setLoading(false)
                    setCsvFileDrop([])
                    history.push({
                        pathname: `/onboarding/edit/${data.id}`,
                        state: data.id,
                    })
                }
            })
            .catch((e) => {
                if (isMounted.current) {
                    setLoading(false)
                    setAlertSnackbarProps({
                        title: 'Error',
                        message:
                            'An error occurred while trying to upload this CSV file. Please try again later.',
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                }
            })
    }

    const handleOnCloseModal = () => {
        setCsvFileDrop([])
        toggleModal()
    }

    return (
        <>
            <Dialog
                open={openModal}
                fullWidth={true}
                maxWidth={'md'}
                id={idDirectory.dialogModal}
            >
                <Icon
                    icon={faTimes}
                    className={'emp-uploadOnboarding-modalClose'}
                    onClick={handleOnCloseModal}
                />

                <AccentArea title="Upload Onboarding" testId="uploadOnboarding">
                    <div
                        id={idDirectory.divRoot}
                        className={'emp-uploadOnboarding-root'}
                    >
                        <div className={'emp-uploadOnboarding-container'}>
                            <div
                                style={{ width: '50%' }}
                                id={idDirectory.divUploadFile}
                            >
                                <div
                                    className={'emp-uploadOnboarding-fileDnD'}
                                >
                                    <FileDnD
                                        accepts={['.csv']}
                                        onDrop={handleFileDragAndDrop}
                                        icon={UploadIcon}
                                        fileUploadInstructionText="Drag & Drop to upload your CSV file, or browse"
                                        fileUploadCriteria="(Please upload only .CSV file)"
                                    />
                                </div>
                            </div>
                            <div
                                id={idDirectory.divTemplatesAndFiles}
                                style={{ width: '50%' }}
                            >
                                <Typography
                                    variant="h3"
                                    id={idDirectory.divTitle}
                                    style={{textAlign: "center"}}
                                >
                                    Download CSV Header Templates
                                </Typography>
                                <div
                                    className={
                                        'emp-uploadOnboarding-btnContainer'
                                    }
                                >
                                    <Button
                                        id={idDirectory.btnMerchantTemplate}
                                        color="secondary"
                                        variant="contained"
                                        className={'emp-uploadOnboarding-btn'}
                                        fullWidth={true}
                                        href={handleMerchantCsvTemplate}
                                        download="merchants.csv"
                                    >
                                        Merchant CSV template
                                    </Button>
                                    <Button
                                        id={idDirectory.btnMIDTemplate}
                                        color="secondary"
                                        variant="contained"
                                        className={'emp-uploadOnboarding-btn'}
                                        fullWidth={true}
                                        href={`${process.env.PUBLIC_URL}/mids.csv`}
                                    >
                                        MID CSV template
                                    </Button>
                                </div>
                                <div
                                    className={
                                        'emp-uploadOnboarding-selectContainer'
                                    }
                                >
                                    <p
                                        className={
                                            'emp-uploadOnboarding-required'
                                        }
                                        style={{
                                            color: midValue
                                                ? ''
                                                : clientTheme.primary,
                                        }}
                                    >
                                        {' '}
                                        Required*
                                    </p>
                                    <SearchableSelect
                                        className={
                                            'emp-uploadOnboarding-switcher'
                                        }
                                        accessor="name"
                                        value={midValue}
                                        options={midOptions}
                                        hideSearch
                                        onValueChange={(val: any) =>
                                            setMidValue(val.value)
                                        }
                                        required={true}
                                        placeholder={'Choose Onboarding Type'}
                                        testId="onboardingType"
                                    />
                                </div>
                                <div
                                    style={{ marginTop: 0 }}
                                    id={idDirectory.divFileList}
                                >
                                    <List>
                                        {csvFileDrop.map((file) => (
                                            <ListItem
                                                key={`${file.name}`}
                                                dense
                                            >
                                                <ListItemIcon>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            setCsvFileDrop([])
                                                        }
                                                    >
                                                        <Icon icon={faTimes} />
                                                    </IconButton>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {file.name}
                                                </ListItemText>
                                                <ListItemText>
                                                    {`(${bytesToSize(
                                                        file.size
                                                    )})`}
                                                </ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{ textAlign: 'center' }}
                            id={idDirectory.divUpload}
                        >
                            <Typography variant="body1">
                                Click upload below to send the CSV file and
                                initiate onboarding. At least one row must be
                                present in CSV file.
                            </Typography>
                            {!midValue || !csvFileDrop.length ? (
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    disabled={true}
                                    id={idDirectory.btnUploadDisable}
                                    className={
                                        'emp-uploadOnboarding-uploadBtn'
                                    }
                                >
                                    Upload
                                </Button>
                            ) : (
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    id={idDirectory.btnUpload}
                                    onClick={handleCsvUpload}
                                    disabled={loading}
                                    className={
                                        'emp-uploadOnboarding-uploadBtn'
                                    }
                                >
                                    {iconsTheme.upload && (
                                        <IconIcon
                                            className={`${iconsTheme.upload} emp-uploadOnboarding-uploadIcon`}
                                        />
                                    )}
                                    {loading ? 'Uploading files...' : 'Upload'}
                                </Button>
                            )}
                        </div>
                    </div>
                </AccentArea>
                <AlertSnackbar
                    content={alertSnackbarProps}
                    open={alertSnackbarOpen}
                    onClose={() => {
                        setAlertSnackbarOpen(false)
                    }}
                    showCloseIcon
                />
            </Dialog>
        </>
    )
}

export default UploadOnboarding

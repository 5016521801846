import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { TextField, Checkbox, Typography } from '@mui/material'
import useIsMounted from 'hooks/utils/useIsMounted'
import { clientTheme } from 'theme-exports'
import { SearchableSelect } from 'components'

export type Option = {
    id: number
    name?: string
}

export interface FormValues {
    name: any
    value: any
    isValid: boolean
}

export interface FieldInfo {
    key: string
    name: string | undefined
    type: 'number' | 'text' | 'date' | 'select' | undefined
    apiValuesPath: string
    selectAccessorId: string
    hideSearch: boolean
}

interface AdvancedFormProps {
    rowData: { [key: string]: any }
    formFields: FieldInfo[]
    values: { [key: string]: any }
    handleFormFieldChange: (field: FormValues) => void
}

const MakeSelect = ({
    selectFieldName,
    fieldInfo,
    initialValues,
    handleFormFieldChange,
}: {
    selectFieldName: string
    fieldInfo: FieldInfo
    initialValues: FormValues
    handleFormFieldChange: (field: FormValues) => void
}): React.ReactElement => {
    const { isMounted } = useIsMounted()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [selectName, setSelectName] = useState(
        initialValues.value ? selectFieldName : ''
    )
    const [fieldOptions, setFieldOptions] = useState<Option[]>([])
    const [searchValue, setSearchValue] = useState<string>('')

    useEffect(() => {
        setIsLoading(true)
        axios
            .get(fieldInfo.apiValuesPath, {
                params: {
                    limit: 999,
                    search: searchValue,
                },
            })
            .then(({ data: { data } }) => {
                let formattedFieldOptions: { id: number; name: string }[] = []
                if (!data) return
                switch (initialValues.name) {
                    case 'reason_code':
                        formattedFieldOptions = data.map(
                            (item: { [key: string]: any }) => {
                                return { id: item.id, name: item.code }
                            }
                        )
                        break
                    case 'dispute_currency':
                        formattedFieldOptions = data.map(
                            (item: { [key: string]: any }) => {
                                return { id: item.id, name: item.currency }
                            }
                        )
                        break
                    default:
                        formattedFieldOptions = data.map(
                            (item: { [key: string]: any }) => {
                                return { id: item.id, name: item.name }
                            }
                        )
                        break
                }
                if (isMounted) setFieldOptions(formattedFieldOptions)
            })
            .finally(() => setIsLoading(false))

        // eslint-disable-next-line
    }, [searchValue])


    const handleSelectFormFieldChange = (e: Option | null) => {
        setSelectName(e?.name ?? '')
        handleFormFieldChange({
            name: initialValues.name,
            value: e?.id,
            isValid: initialValues.isValid,
        })
    }

    return (
        <div className={'emp-advancedForm-selectField'}>
            <SearchableSelect
                value={selectName}
                onValueChange={(e: Option | null) =>
                    handleSelectFormFieldChange(e)
                }
                searchValue={searchValue}
                onSearchChange={setSearchValue}
                options={!isLoading ? fieldOptions ?? [] : []}
                accessor={'name'}
                testId={initialValues.name}
                debounceDelay={200}
                hideSearch={fieldInfo.hideSearch}
                loadingValues={isLoading}
            />
        </div>
    )
}

const AdvancedForm = ({
    formFields,
    values,
    handleFormFieldChange,
    rowData,
}: AdvancedFormProps) => {
    return (
        <div
            className={`emp-advancedForm-root ${
                formFields.length === 1 && 'emp-onlyOneFieldContainer'
            }`}
        >
            {formFields.map((field: FieldInfo, idx: number) => (
                <div
                    className={`emp-advancedForm-fieldContainer ${
                        formFields.length === 1 && 'emp-onlyOneFieldContainer'
                    }`}
                    key={`key-${idx}`}
                >
                    <Typography
                        variant="h5"
                        className={'emp-advancedForm-typography'}
                    >
                        {field.name}:
                    </Typography>

                    {/* Form field */}
                    {['text', 'number', 'date'].includes(field.type ?? '') && (
                        <TextField
                            value={values[field.key]?.value ?? ''}
                            name={field.key}
                            onChange={(e) => {
                                handleFormFieldChange({
                                    name: field.key,
                                    value: e.target.value,
                                    isValid: values[field.key]?.isValid,
                                })
                            }}
                            fullWidth
                            variant={'outlined'}
                            size="small"
                            className={'emp-advancedForm-textField'}
                            type={field.type}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius:
                                        clientTheme.selectionBox.borderRadius,
                                },
                            }}
                        />
                    )}
                    {field.type === 'select' && field.apiValuesPath && (
                        <MakeSelect
                            fieldInfo={field}
                            initialValues={values[field.key]}
                            handleFormFieldChange={handleFormFieldChange}
                            selectFieldName={
                                rowData[values[field.key]?.name] ?? ''
                            }
                        />
                    )}
                    <div className={'emp-advancedForm-divCheckbox'}>
                        <Checkbox
                            checked={values[field.key]?.is_valid}
                            onChange={() => {
                                handleFormFieldChange({
                                    name: field.key,
                                    value: values[field.key]?.value,
                                    isValid: !values[field.key]?.is_valid,
                                })
                            }}
                            size="small"
                            color={'secondary'}
                        />
                        <Typography variant="h5">Correct Value</Typography>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default AdvancedForm

import React from 'react'
import { Button } from '@mui/material'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'

interface AGDataGridActionsExportsProps {
    testId: string
    disableAllDataGridActions?: boolean
    showCsvExport?: boolean
    onExportCsv?: () => void
    showExcelExport?: boolean
    onExportExcel?: () => void
}

export const AGDataGridActionsExports = ({
    testId,
    disableAllDataGridActions = false,
    showCsvExport = true,
    onExportCsv = () => {},
    showExcelExport = true,
    onExportExcel = () => {},
}: AGDataGridActionsExportsProps): React.ReactElement => {
    return (
        <>
            {showCsvExport && (
                <Button
                    onClick={onExportCsv}
                    className={'emp-agDataGridActionsExports-btn'}
                    sx={{
                        ...clientTheme.buttons.textOrOutlinedButton.style,
                        fontSize: clientTheme.typography.button2.fontSize,
                    }}
                    id={`${idDirectory.btnCsv}-${testId}`}
                    disabled={disableAllDataGridActions}
                >
                    CSV
                </Button>
            )}
            {showExcelExport && (
                <Button
                    onClick={onExportExcel}
                    className={'emp-agDataGridActionsExports-btn'}
                    sx={{
                        ...clientTheme.buttons.textOrOutlinedButton.style,
                        fontSize: clientTheme.typography.button2.fontSize,
                    }}
                    id={`${idDirectory.btnExcel}-${testId}`}
                    disabled={disableAllDataGridActions}
                >
                    Excel
                </Button>
            )}
        </>
    )
}

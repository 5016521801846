import React from 'react'
import { OutcomeCellValue } from 'hooks/cb-api/types'

// enum OutcomeId {
//     'Already Refunded' = 1,
//     'Refunded',
//     'Refund Error',
//     'No Match Found',
//     'Declined Transaction',
//     'Already a Chargeback',
//     'No Refund',
//     'Completed',
//     'Resolved With Customer',
// }

interface OutcomeCellProps {
    value: OutcomeCellValue
}

const OutcomeCell = ({ value }: OutcomeCellProps) => {
    const outcomeLabel = (outcome: OutcomeCellValue): string => {
        if (
            outcome === 'Already Refunded' ||
            outcome === 'Refunded' ||
            outcome === 'Completed' ||
            outcome === 'Resolved With Customer'
        )
            return 'emp-success'
        if (outcome === 'Refund Error' || outcome === 'No Match Found')
            return 'emp-yellow'
        if (
            outcome === 'Declined Transaction' ||
            outcome === 'Already a Chargeback' ||
            outcome === 'No Refund'
        )
            return 'emp-warning'
        else return 'emp-default'
    }

    return (
        <div id={'preChargebacksAGAlertsOutcomeCell'}>
            <p style={{ margin: 0 }}>
                <span className={`${outcomeLabel(value)} ${'emp-container'}`}>
                    {value}
                </span>
            </p>
        </div>
    )
}

export default OutcomeCell

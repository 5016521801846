import React, { useState, useMemo } from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
    Button,
    Dialog,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
} from '@mui/material'
import UploadIcon from 'assets/icons/upload-icon-bg-alt.png'
import { bytesToSize } from 'utils/bytesToSize'
import useIsMounted from 'hooks/utils/useIsMounted'
import CB from 'lib'
import { iconsTheme } from 'theme-exports'
import {
    AccentArea,
    FileDnD,
    Icon as IconIcon,
    AlertSnackbar,
    ConfirmActionModal,
} from 'components'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import idDirectory from './idAttributes'

export interface UploadCaseUpdaterProps {
    openModal: boolean
    onClose: () => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    refreshGrid: () => void
    className?: string
}

interface CsvFile {
    name: string
    s3Key: string
    size: number
    contentType: string
}

const UploadCaseUpdater = ({
    openModal,
    onClose,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    refreshGrid,
    className,
}: UploadCaseUpdaterProps) => {
    const { isMounted } = useIsMounted()
    const [loading, setLoading] = useState(false)
    const [csvFileDrop, setCsvFileDrop] = useState<CsvFile[]>([])
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})
    const [isClosingWithFile, setIsClosingWithFile] = useState<boolean>(false)

    const handleFileDragAndDrop = (file: any) => {
        if (!file.length) {
            setAlertSnackbarProps({
                title: 'Error',
                message: 'Only a single CSV file is allowed.',
                intent: 'error',
            })
            return setAlertSnackbarOpen(true)
        }

        CB.documentsCaseUpdater
            .uploadCsvFile(file)
            .then((data: any) => {
                if (isMounted.current) {
                    setCsvFileDrop([
                        {
                            name: data.filename,
                            s3Key: data.s3_key,
                            size: data.size,
                            contentType: data.content_type,
                        },
                    ])
                }
            })
            .catch((e) => {
                if (isMounted.current) {
                    setAlertSnackbarProps({
                        title: 'Error',
                        message: 'An error occurred. Please try again later.',
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                }
            })
    }

    const handleCsvUpload = () => {
        setLoading(true)
        CB.documentsCaseUpdater
            .postCaseUpdater({
                s3_key: csvFileDrop[0].s3Key,
                size: csvFileDrop[0].size,
                filename: csvFileDrop[0].name,
            })
            .then(() => {
                if (isMounted.current) {
                    handleCloseModalWithFile()
                    setAlertSnackbarMainProps({
                        title: 'Success',
                        intent: 'success',
                        message: 'CSV successfully uploaded.',
                    })
                    refreshGrid()
                    setAlertSnackbarMainOpen(true)
                }
            })
            .catch((e) => {
                if (isMounted.current) {
                    setAlertSnackbarProps({
                        title: 'Error',
                        message:
                            'An error occurred while trying to upload this CSV file. Please try again later.',
                        intent: 'error',
                    })
                    setAlertSnackbarOpen(true)
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setLoading(false)
                }
            })
    }

    const checkForFileBeforeClose = () => {
        csvFileDrop.length ? setIsClosingWithFile(true) : onClose()
    }

    const handleCloseModalWithFile = () => {
        setCsvFileDrop([])
        onClose()
        setIsClosingWithFile(false)
    }

    const handleCsvTemplate = useMemo(() => {
        let CSVHeaders = '"ID","Verdict"\r\n'
        let CSVExampleData =
            '"11111","Win"\r\n"22222","Loss"\r\n"33333","Pending"\r\n'

        return 'data:text/csv;charset=utf-8,' + CSVHeaders + CSVExampleData
    }, [])

    return (
        <>
            <Dialog
                open={openModal}
                fullWidth={true}
                maxWidth={'md'}
                id={idDirectory.dialogModal}
            >
                <Icon
                    icon={faTimes}
                    className={'emp-uploadCaseUpdater-modalClose'}
                    onClick={checkForFileBeforeClose}
                />

                <AccentArea title="Upload CSV" testId="uploadCaseUpdater">
                    <div id={idDirectory.divRoot}>
                        <div className={'emp-uploadCaseUpdater-container'}>
                            <div
                                style={{ width: '50%' }}
                                id={idDirectory.divUploadFile}
                            >
                                <div
                                    className={
                                        'emp-uploadCaseUpdater-fileDnD'
                                    }
                                >
                                    <FileDnD
                                        accepts={['.csv']}
                                        onDrop={handleFileDragAndDrop}
                                        icon={UploadIcon}
                                        fileUploadInstructionText="Drag & Drop to upload your CSV file, or browse"
                                        fileUploadCriteria="(Please upload only .CSV file)"
                                    />
                                </div>
                            </div>
                            <div
                                id={idDirectory.divFileInfo}
                                style={{ width: '50%', textAlign: 'center' }}
                            >
                                <Typography
                                    variant="h3"
                                    id={idDirectory.divTitle}
                                >
                                    Download CSV Header Template
                                </Typography>
                                <div
                                    className={
                                        'emp-uploadCaseUpdater-btnContainer'
                                    }
                                >
                                    <Button
                                        id={idDirectory.btnCSVTemplate}
                                        color="secondary"
                                        variant="contained"
                                        className={
                                            'emp-uploadCaseUpdater-btn'
                                        }
                                        href={handleCsvTemplate}
                                        download="csv-template.csv"
                                    >
                                        CSV template
                                    </Button>
                                </div>
                                <div
                                    style={{ marginTop: 0 }}
                                    id={idDirectory.divFileList}
                                >
                                    <List>
                                        {csvFileDrop.map((file) => (
                                            <ListItem
                                                key={`key-${file.name}`}
                                                dense
                                            >
                                                <ListItemIcon>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            setCsvFileDrop([])
                                                        }
                                                    >
                                                        <Icon icon={faTimes} />
                                                    </IconButton>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    {file.name}
                                                </ListItemText>
                                                <ListItemText>
                                                    {`(${bytesToSize(
                                                        file.size
                                                    )})`}
                                                </ListItemText>
                                            </ListItem>
                                        ))}
                                    </List>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{ textAlign: 'center' }}
                            id={idDirectory.divUpload}
                        >
                            <Typography
                                variant="body1"
                                style={{ marginBottom: '8px' }}
                            >
                                Click upload below to send the CSV file and
                                initiate case update. At least one file must be
                                uploaded.
                            </Typography>

                            <Button
                                color="secondary"
                                variant="contained"
                                id={idDirectory.btnUpload}
                                onClick={handleCsvUpload}
                                disabled={loading || !csvFileDrop.length}
                            >
                                {iconsTheme.upload && (
                                    <IconIcon
                                        className={`${
                                            iconsTheme.upload
                                        } ${'emp-uploadCaseUpdater-uploadIcon'}`}
                                    />
                                )}
                                {loading ? 'Uploading files...' : 'Upload'}
                            </Button>
                        </div>
                    </div>
                </AccentArea>
                <AlertSnackbar
                    content={alertSnackbarProps}
                    open={alertSnackbarOpen}
                    onClose={() => {
                        setAlertSnackbarOpen(false)
                    }}
                    showCloseIcon
                />
            </Dialog>
            <ConfirmActionModal
                open={isClosingWithFile}
                toggleOpen={() => setIsClosingWithFile(false)}
                onConfirm={handleCloseModalWithFile}
                header={'Are you sure?'}
                message={'The changes you made in this session will be lost.'}
                confirmButtonText={'Discard'}
                testId={'upload-case-updater'}
            />
        </>
    )
}

export default UploadCaseUpdater

import React from 'react'
import { clientTheme } from 'theme-exports'

interface ILogoProps {
    className?: string
    alt: string
    src: string
}

/**
 * Standard logo for now
 */
const Logo = ({ className, alt, src }: ILogoProps) => {
    return (
        <img
            src={src}
            className={`app-logo ${className}`}
            alt={alt}
            style={{
                maxWidth: clientTheme.topBar.brandLogo.maxWidth,
                paddingTop: clientTheme.topBar.brandLogo.paddingTop,
            }}
        />
    )
}

export default Logo

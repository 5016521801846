import React, { useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material'
import { LoadingIndicator, Picker } from 'components'
import 'react-resizable/css/styles.css'
import { clientTheme } from 'theme-exports'
import { useTable, usePagination } from 'react-table'
import idDirectory from './idAttributes'
import { capitalizeFunc } from 'api/OnboardingApi'

interface SelectOptions {
    [key: string]: {
        list: {
            name: string
            value: string
            label: string
        }[]
        text: string
    }
}

interface DataGridEditableProps {
    columns: any[]
    data: any[]
    loading: boolean
    updateMyData: (rowIndex: number, columnId: string, value: any) => void
    testId: string
    selectOptions: SelectOptions
}

interface EditableCellProps {
    value: {
        value: number | string
        err_messages: string[]
    }
    updateMyData: (rowIndex: number, columnId: string, value: any) => void
    row: { index: number }
    column: { id: string }
    selectOptions: SelectOptions
    setSelectedRow: (val: number) => void
    selectedRow: number | null
    data: any
}

const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData,
    selectOptions,
    setSelectedRow,
    data,
}: EditableCellProps) => {
    const [value, setValue] = React.useState(initialValue.value)
    const [dataCell, setDataCell] = React.useState(initialValue)
    const isDeleted = data[index]?.status?.value?.id === 2

    const onChange = (e: any) => {
        setValue(e.target.value)
        setDataCell({ ...dataCell, value: e.target.value })
    }

    const onKeyUp = () => {
        setSelectedRow(index)
        updateMyData(index, id, dataCell)
    }

    const handleSelect = (e: any) => {
        setSelectedRow(index)
        setValue(e.target.value)
        setDataCell({ ...dataCell, value: e.target.value })
        updateMyData(index, id, { ...dataCell, value: e.target.value })
    }

    React.useEffect(() => {
        setValue(initialValue.value)
        setDataCell(initialValue)
    }, [initialValue])

    if (isDeleted) {
        return <Typography>{value}</Typography>
    } else if (initialValue.err_messages.length && selectOptions[id]) {
        return (
            <Picker
                value={value ?? ''}
                handleChange={handleSelect}
                options={selectOptions[id]?.list ?? []}
                className={`${'emp-dataGridEditable-notValidSelect'} ${
                    isDeleted ? 'emp-dataGridEditable-deleteRow' : ' '
                }`}
                border={`2px solid ${clientTheme.primary}`}
                testId={id}
                isOutlined={false}
            />
        )
    } else if (!initialValue.err_messages.length && selectOptions[id]) {
        return (
            <Picker
                value={
                    selectOptions[id]?.text === 'upper case'
                        ? String(value).toUpperCase()
                        : capitalizeFunc(String(initialValue.value))
                }
                handleChange={handleSelect}
                options={selectOptions[id]?.list ?? []}
                className={`${'emp-dataGridEditable-validSelect'} ${
                    isDeleted ? 'emp-dataGridEditable-deleteRow' : ' '
                }`}
                testId={id}
                isOutlined={false}
            />
        )
    } else if (initialValue.err_messages.length) {
        return (
            <input
                value={value ?? ''}
                onChange={onChange}
                onKeyUp={onKeyUp}
                className={`${'emp-dataGridEditable-notValid '} ${
                    isDeleted ? 'emp-dataGridEditable-deleteRow' : ' '
                }`}
                style={{ border: `2px solid ${clientTheme.primary}` }}
            />
        )
    } else {
        return (
            <input
                value={value ?? ''}
                onChange={onChange}
                onKeyUp={onKeyUp}
                className={`${'emp-dataGridEditable-valid '} ${
                    isDeleted ? 'emp-dataGridEditable-deleteRow' : ' '
                }`}
            />
        )
    }
}

const defaultColumn = {
    Cell: EditableCell,
}

export const DataGridEditable: React.FC<DataGridEditableProps> = ({
    columns,
    data,
    loading,
    updateMyData,
    selectOptions,
    testId,
}) => {
    const [selectedRow, setSelectedRow] = useState<number | null>(null)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // @ts-ignore
            updateMyData,
            selectOptions,
            setSelectedRow,
            selectedRow,
        },
        usePagination
    )

    return (
        <div
            style={{ overflowX: 'auto' }}
            id={`${idDirectory.dataGridEditable.divRoot}-${testId}`}
        >
            {!loading ? (
                <TableContainer
                    className={`table-outer-container ${'emp-dataGridEditable-tableContainer'}`}
                    component={Paper}
                    id={`${idDirectory.dataGridEditable.tableContainer}-${testId}`}
                >
                    <Table {...getTableProps()} size="medium">
                        <TableHead
                            className={`emp-dataGridEditable-tableHeader`}
                            id={`${idDirectory.dataGridEditable.tableHead}-${testId}`}
                        >
                            {headerGroups.map((headerGroup, idx) => (
                                <TableRow
                                    {...headerGroup.getHeaderGroupProps()}
                                    className={
                                        'emp-dataGridEditable-headerRow'
                                    }
                                    key={`header-${idx}`}
                                >
                                    {headerGroup.headers.map((column) => (
                                        <TableCell
                                            {...column.getHeaderProps()}
                                            className={
                                                'emp-dataGridEditable-headerTitle'
                                            }
                                            align="center"
                                            id={`${idDirectory.dataGridEditable.tableHeadCell}-${testId}-${column.id}`}
                                            key={`headerCol--${testId}-${column.id}`}
                                        >
                                            {column.render('Header')}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody {...getTableBodyProps()}>
                            {rows.map((row: any, idx: number) => {
                                prepareRow(row)
                                return (
                                    <TableRow
                                        {...row.getRowProps()}
                                        key={`row-${idx}`}
                                        className={`${
                                            row?.original?.status?.value?.id ===
                                            2
                                                ? 'emp-dataGridEditable-deleteRow'
                                                : ''
                                        } ${
                                            selectedRow === idx
                                                ? 'emp-dataGridEditable-selectedRow'
                                                : ''
                                        }`}
                                    >
                                        {row.cells.map(
                                            (cell: any, idx: number) => {
                                                return !cell?.value
                                                    ?.err_messages.length ? (
                                                    <TableCell
                                                        key={`cell-${idx}`}
                                                        {...cell.getCellProps()}
                                                        align="center"
                                                    >
                                                        {cell.render('Cell')}
                                                    </TableCell>
                                                ) : (
                                                    <Tooltip
                                                        title={cell?.value?.err_messages.map(
                                                            (
                                                                item: string,
                                                                idx: number
                                                            ) => (
                                                                <span
                                                                    key={`key-${idx}`}
                                                                >
                                                                    {item}
                                                                    <br />
                                                                </span>
                                                            )
                                                        )}
                                                        placement="top"
                                                        arrow
                                                        key={`cell-${idx}`}
                                                    >
                                                        <TableCell
                                                            {...cell.getCellProps()}
                                                            align="center"
                                                        >
                                                            {cell.render(
                                                                'Cell'
                                                            )}
                                                        </TableCell>
                                                    </Tooltip>
                                                )
                                            }
                                        )}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <div style={{ height: '50px ' }}>
                    <div className={'emp-dataGridEditable-loadingContainer'}>
                        <LoadingIndicator />
                    </div>
                </div>
            )}
        </div>
    )
}

export default DataGridEditable

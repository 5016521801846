/**
 * @description Directory for ID Attributes on Reports.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    divHeader: `reports-div-header`,
    gridReport: `reports-grid-report`,
    divReport: `reports-div-report`,
    cardReport: `reports-card-report`,
}

export default idDirectory

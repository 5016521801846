/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Alert as MAlert, AlertTitle } from '@mui/material';
import { AlertProps } from '@mui/lab';
import styles from './Alert.module.css'

export interface IAlertProps {
    severity?: AlertProps['severity']
    title?: string
    className?: string
    children: React.ReactNode
    [key: string]: any
}

/**
 * A read-only warning/info/success/error indicator, with possible icon, close button, title etc
 * Mainly useful as an operational component to compare, but use `yarn generate NewComponent` for new components
 * @property {string} severity info | error | warning | success
 * @property {string} title Text to go over the top of the alert
 * @property children Compositional content to go in the alert
 *
 */
const Alert = ({
    className,
    children,
    severity = 'info',
    ...rest
}: IAlertProps) => (
    <MAlert
        severity={severity}
        className={`${styles.alert} ${className}`}
        {...rest}
    >
        {children}
    </MAlert>
)

export { AlertTitle }

export default Alert

import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material'
import { Auth } from 'aws-amplify'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import React from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { clearLocalStorage } from 'context/AuthedUser/AuthedUserContext'
import CB from 'lib'
import { clientTheme } from 'theme-exports'

interface IdleUserBoundaryProps {
    className?: string
    id?: string
    title?: string
    children: React.ReactNode
    warningMinutes?: number
    timeoutMinutes?: number
}

let idleWarningInterval: any
const ExpiringSessionModal = ({
    className,
    id,
    children,
    warningMinutes = 1,
    timeoutMinutes = 15,
}: IdleUserBoundaryProps) => {
    const {
        user,
        selectedPatchIDs,
        setSelectedPatchIDs,
        selectedPatchType,
        setSelectedPatchType,
        hasClearedCaseStatus,
        setHasClearedCaseStatus,
        SSOTokenURL,
    } = useAuthedUser()
    const userIsAuthenticated = Boolean(user)
    const maxIdleTime = Number(process.env.MAX_IDLE_TIME) || 60 * warningMinutes
    const maxIdleWarningTime =
        Number(process.env.MAX_IDLE_WARNING_TIME) ||
        60 * (timeoutMinutes - warningMinutes)
    const secondsToThrottleAutoRenewal = 60
    const [isIdleWarningOpen, setIsIdleWarningOpen] = React.useState(false)
    const [idleWarningSeconds, setIdleWarningSeconds] = React.useState(
        maxIdleWarningTime
    )
    const [lastRenewedTime, setLastRenewedTime] = React.useState<number>(
        Math.floor(Date.now() / 1000)
    )
    const originalTitle = React.useRef(document.title)
    const [title, setTitle] = React.useState('')

    // Reference needed to idleWarning Seconds, for use in setInterval routine below.
    const idleWarningSecondsRef = React.useRef(idleWarningSeconds)
    idleWarningSecondsRef.current = idleWarningSeconds

    React.useEffect(() => {
        isIdleWarningOpen && setTitle(document.title)
    }, [isIdleWarningOpen])

    React.useEffect(() => {
        if (isIdleWarningOpen) {
            window.document.title =
                idleWarningSeconds % 2 ? '**WARNING**' : originalTitle.current
        }
    }, [idleWarningSeconds, isIdleWarningOpen, originalTitle])

    const handleCaseStatusPatchAndLogout = () => {
        if (selectedPatchIDs && !hasClearedCaseStatus) {
            setHasClearedCaseStatus && setHasClearedCaseStatus(true)
            const actionId = selectedPatchType === 'upload' ? 1 : 2
            return CB.cases.callCasePatch(selectedPatchIDs, actionId, selectedPatchType)
                .then(() => {
                    setSelectedPatchIDs && setSelectedPatchIDs('')
                    setSelectedPatchType && setSelectedPatchType('')
                })
                .catch((e) => {})
                .finally(async () => {
                    await Auth.signOut().then(() => {
                        clearLocalStorage()
                        if (SSOTokenURL?.length) {
                            const tempURL = SSOTokenURL
                            localStorage.removeItem('SSOTokenURL')
                            return document.location.href = tempURL
                        }
                        document.location.href = '/'
                    })
                })
        }
        return Auth.signOut().then(() => {
            clearLocalStorage()
            if (SSOTokenURL?.length) {
                const tempURL = SSOTokenURL
                localStorage.removeItem('SSOTokenURL')
                return document.location.href = tempURL
            }
            document.location.href = '/'
        })
    }

    const renewUserSession = async (e: any, autoRenew: Boolean = false) => {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        const currentSession = cognitoUser.signInUserSession
        cognitoUser.refreshSession(
            currentSession.refreshToken,
            (e: any, session: any) => {
                if (!e && session.refreshToken.token) {
                    setLastRenewedTime(Math.floor(Date.now() / 1000))
                    if (!autoRenew) {
                        closeIdleWarningModal(false)
                        window.document.title = title
                    }
                    return
                }
                return handleCaseStatusPatchAndLogout()
            }
        )
    }
    const checkIsLoggedIn = () => {
        if (!userIsAuthenticated || isIdleWarningOpen) return
        openIdleWarningModal()
    }
    const openIdleWarningModal = () => {
        setIdleWarningSeconds(maxIdleWarningTime)
        setIsIdleWarningOpen(true)
        idleWarningInterval = setInterval(() => {
            const newTimerSeconds = idleWarningSecondsRef.current - 1
            setIdleWarningSeconds(newTimerSeconds)
            if (newTimerSeconds === 0) {
                closeIdleWarningModal()
            }
        }, 1000)
    }
    const closeIdleWarningModal = (redirectToLogout: Boolean = true) => {
        setIsIdleWarningOpen(false)
        clearInterval(idleWarningInterval)
        if (redirectToLogout) {
            return handleCaseStatusPatchAndLogout()
        }
    }
    const handleOnAction = () => {
        const epochNow = Math.floor(Date.now() / 1000)
        if (
            epochNow > lastRenewedTime + secondsToThrottleAutoRenewal &&
            userIsAuthenticated &&
            !isIdleWarningOpen
        ) {
            setLastRenewedTime(epochNow)
            renewUserSession(null, true)
        }
    }

    useIdleTimer({
        timeout: maxIdleTime * 1000,
        debounce: 500,
        onIdle: checkIsLoggedIn,
        onAction: handleOnAction,
    })

    return (
        <div className={className} id={id}>
            {children}
            <div>
                <Dialog open={isIdleWarningOpen}>
                    <DialogTitle style={{ backgroundColor: 'red' }}>
                        <Typography
                            style={{ color: 'white', fontSize: '15px' }}
                        >
                            Oh No!
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div
                            style={{ margin: '15px 0px' }}
                        >{`Your session will timeout in ${idleWarningSeconds}s`}</div>
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gap: '0.5rem',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={renewUserSession}
                            >
                                Extend my session
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleCaseStatusPatchAndLogout}
                                sx={clientTheme.buttons.defaultButton.style}
                            >
                                Log out now
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    )
}

// Split apart this component if the file starts to exceeds 300 lines or pieces are reused in 3 places

export default ExpiringSessionModal

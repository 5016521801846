import printJS from 'print-js'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'

interface obj {
    [key: string]: any
}
export class Tools {
    static copy() {}

    static print(tableData: any[], columns: any[], title: string) {
      
        if (!tableData) {
            throw new Error('No element provided.')
        }
        const JSON_array = tableData.map((row) => {
            let rowJSON: obj = {}
            columns.forEach((column) => {
                if (typeof column.accessor !== 'function') {
                    const key = column.accessor.includes('.')
                        ? column.accessor.split('.')[0]
                        : column.accessor
                    if (typeof row[key] === 'object') {
                        if (column.accessor === 'flag') {
                            rowJSON[key] = row[key]?.comment ?? ''
                        } else if (column.accessor === 'assigned_user') {
                            rowJSON[key] = row[key]?.fname
                                ? `${row[key]?.fname} ${row[key]?.lname}`
                                : row[key] ?? ''
                        } else {
                            rowJSON[key] = row[key]?.name ?? ''
                        }
                    } else {
                        rowJSON[key] = row[key]
                    }
                } else {
                    const key = column.id.includes('.')
                        ? column.id.split('.')[0]
                        : column.id
                    if (typeof row[key] === 'object') {
                        rowJSON[key] = row[key] ? row[key].name : ''
                    } else {
                        rowJSON[key] = row[key]
                    }
                }
            })
            return rowJSON
        })
        let properties = columns
            .map((column) => {
                if (typeof column.accessor !== 'function') {
                    const key = column.accessor.includes('.')
                        ? column.accessor.split('.')[0]
                        : column.accessor
                    if (column.accessor === 'flag') {
                        return {
                            field: key,
                            displayName: 'Flag Comment',
                        }
                    }
                    if (typeof column.Header === 'object') {
                        return {
                            field: key,
                            displayName: column.Header.props.value,
                        }
                    }
                    if (typeof column.Header === 'string') {
                        return { field: key, displayName: column.Header }
                    }
                    return undefined
                } else {
                    const key = column.id.includes('.')
                        ? column.id.split('.')[0]
                        : column.id
                    if (typeof column.Header === 'object') {
                        return {
                            field: key,
                            displayName: column.Header.props.value,
                        }
                    } else if (typeof column.Header === 'string') {
                        return { field: key, displayName: column.Header }
                    }
                    return undefined
                }
            })
            .filter(
                (p) => p !== undefined && JSON_array[0][p.field] !== undefined
            )

        return printJS({
            printable: JSON_array,
            type: 'json',
            properties: properties,
            documentTitle: title,
            style: `body { text-align: center; }`,
            gridStyle: 'border: 1px solid #eeeeee; padding: 10px',
        })
    }

    static generatePDF = (
        tableData: any[],
        columns: any[],
        fileName: string
    ) => {
        if (!tableData) throw new Error('No table data provided.')
        const JSON_array = tableData.map((row, idx) => {
            let rowJSON: obj = {}
            columns.forEach((column) => {
                const key = column.accessor.includes('.')
                    ? column.accessor.split('.')[0]
                    : column.accessor
                if (typeof row[key] === 'object') {
                    if (column.accessor === 'flag') {
                        rowJSON[key] = row[key]?.comment ?? ''
                    } else if (column.accessor === 'assigned_user') {
                        rowJSON[key] = row[key]?.fname
                            ? `${row[key]?.fname} ${row[key]?.lname}`
                            : row[key] ?? ''
                    } else {
                        rowJSON[key] = row[key]?.name ?? ''
                    }
                } else {
                    rowJSON[key] = row[key]
                }
            })
            rowJSON['index'] = idx + 1

            // Reason code messages are too lengthy and break PDF lib. So truncate to first 10 words.
            if (rowJSON.reason_code_message) {
                let reasonCodeMsgArr = rowJSON.reason_code_message.split(' ')
                rowJSON.reason_code_message = `${reasonCodeMsgArr.splice(0, 10)}...`
            }

            return rowJSON
        })
        let properties = columns
            .map((column) => {
                const key = column.accessor.includes('.')
                    ? column.accessor.split('.')[0]
                    : column.accessor

                if (column.accessor === 'flag') {
                    return {
                        dataKey: key,
                        header: 'Flag Comment',
                    }
                }
                if (typeof column.Header === 'object') {
                    return { dataKey: key, header: column.Header.props.value }
                }
                if (typeof column.Header === 'string') {
                    return { dataKey: key, header: column.Header }
                }
                return undefined
            })
            .filter(
                (p) => p !== undefined && JSON_array[0][p.dataKey] !== undefined
            )
        properties.push({ dataKey: 'index', header: '#' })

        const doc = new jsPDF('landscape')
        // @ts-ignore
        doc.autoTable({
            tableWidth: 'wrap',
            headStyles: { fillColor: [34, 49, 66] },
            styles: { cellPadding: 0.75, fontSize: 8 },
            horizontalPageBreak: true,
            horizontalPageBreakRepeat: 'index',
            body: JSON_array,
            columns: properties,
        })
        return doc.save(`${fileName}.pdf`)
    }
}

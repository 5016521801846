import React, { useState } from 'react'
// import { useHistory } from 'react-router'
// import { useLocation } from 'react-router-dom'
import { format } from 'date-fns'
import { dateUtilities } from 'utils/dateUtilities'
import { Button } from '@mui/material';
import { withRequiredRole } from 'components/useRequireRole'
import EntFeature from 'models/EntFeature'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
// import { clientTheme } from 'theme-exports'
// import { AccentArea, PresetDateRange, UploadOnboarding, Icon } from 'components'
import { AccentArea, PresetDateRange, UploadOnboarding } from 'components'
import idDirectory from './idAttributes'
import View from 'views/View'
import { IDateRange } from 'components/PresetDateRange'
import { OnboardingGrid } from './grids/OnboardingGrid'
import useOnBoarding from './Onboarding.vm'

const defaultDateRangeOnLoad: {
    to: Date
    from: Date
} = dateUtilities.getPriorDays(7) // change this to change the default date range (ref: dateUtilities.ts)
const { to, from } = defaultDateRangeOnLoad
const PresetDateRangeDate: IDateRange = {
    to: format(to, 'yyyy-MM-dd'),
    from: format(from, 'yyyy-MM-dd'),
}

export const Onboarding: React.FC = () => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only
    // const history = useHistory()
    // const { pathname } = useLocation()
    // const { setShowNewDataGrid } = useAuthedUser()

    const [onboardUploadOpen, setOnboardUploadOpen] = useState<boolean>(false)
    const { data, swrActions } = useOnBoarding()

    const {
        isLoading,
        search,
        setSearch,
        rowsPerPage,
        setRowsPerPage,
        setPage,
        total,
        page,
        setSortBy,
        setSortDesc,
        sortInfo,
        invalidate: rehydrateView,
    } = swrActions

    // const handleSwitchGrids = () => {
    //     if (pathname.includes('-new')) {
    //         setShowNewDataGrid && setShowNewDataGrid(false)
    //         history.push(pathname.slice(0, -4))
    //     } else {
    //         setShowNewDataGrid && setShowNewDataGrid(true)
    //         history.push(`${pathname}-new`)
    //     }
    // }

    return (
        <View
            title="Onboarding"
            breadcrumb="Onboarding"
            HeaderInterior={
                <div className={'emp-root'}>
                    <Button
                        className={'emp-onboardingBtn'}
                        color="secondary"
                        variant="contained"
                        onClick={() => setOnboardUploadOpen(true)}
                        id={idDirectory.onboarding.btnOnboarding}
                        disabled={isReadOnly}
                    >
                        Initiate Onboarding
                    </Button>
                </div>
            }
            testId="onboarding"
        >
            <div id={idDirectory.onboarding.divContainer}>
                <AccentArea
                    containsFullWidthGrid={true}
                    title={
                        <div>
                            <span> Onboarding History</span>
                        </div>
                    }
                    headerAction={
                        <>
                            {/* <Button
                                onClick={handleSwitchGrids}
                                className={'emp-switchArrowBtn'}
                                sx={clientTheme.buttons.textOrOutlinedButton.style}
                            >
                                <div>
                                    <Icon
                                        className={`fa fa-right-left ${'emp-switchArrowIcon'}`}
                                    />
                                    <Icon className={'fa fa-table'} />
                                </div>
                            </Button> */}
                            <PresetDateRange
                                //todo: connect to data
                                getDateRange={(date) => {}}
                                dateRange={PresetDateRangeDate}
                                className={'emp-dateRangePicker'}
                                allowFutureDateSelection={false}
                                testId="onboardingHx"
                            />
                        </>
                    }
                    testId="onboardingHx"
                >
                    <UploadOnboarding
                        openModal={onboardUploadOpen}
                        toggleModal={() => setOnboardUploadOpen(false)}
                    />
                    <OnboardingGrid
                        sortInfo={sortInfo}
                        data={data}
                        total={total}
                        onPageSizeChange={setRowsPerPage}
                        loading={isLoading}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={setPage}
                        onSortDirectionChange={setSortDesc}
                        onSortChange={setSortBy}
                        setSearch={setSearch}
                        testId="onboarding"
                        globalSearch={search}
                        onSearchChange={setSearch}
                        rehydrateView={rehydrateView}
                    />
                </AccentArea>
            </div>
        </View>
    )
}

export default withRequiredRole(EntFeature.ONBOARDING_LEGACY, Onboarding)

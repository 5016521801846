import React, { useEffect, useState } from 'react'
import { DialogContent, Typography } from '@mui/material'
import { AccountSetupApi } from 'api'
import { Alert, Skeleton } from '@mui/material'

// Args used as controls in storybook
interface AccountSetupArgs {
    toggleLoading?: boolean
    toggleError?: boolean
}

interface AccountSetupLinks {
    path: string
    icon: string
    title: string
}

const AccountSetup = ({
    toggleLoading = false,
    toggleError = false,
}: AccountSetupArgs): React.ReactElement => {
    const [accountSettingsData, setAccountSettingsData] = useState<
        AccountSetupLinks[]
    >([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const viewState = loading ? Array(4).fill([]) : accountSettingsData

    useEffect(() => {
        setLoading(toggleLoading)
    }, [toggleLoading])

    useEffect(() => {
        setError(toggleError)
    }, [toggleError])

    useEffect(() => {
        setLoading(true)
        AccountSetupApi.accountSetupLinks()
            .then(({ accountSetupLinks }) =>
                setAccountSettingsData(accountSetupLinks)
            )
            .catch((e) => setError(e))
            .finally(() => setLoading(false))
    }, [])

    return (
        <div className={`emp-accountSetup-dialogContentContainer`}>
            {error ? (
                <Alert
                    classes={{ root: 'emp-accountSetup-alert' }}
                    color="error"
                >
                    Sorry, there was an issue fetching account setup links.
                </Alert>
            ) : (
                viewState.map((val, idx) => (
                    <DialogContent
                        className={`emp-accountSetup-dialogContent`}
                        key={`${val?.title}-${idx}`}
                    >
                        {loading ? (
                            <Skeleton width="200px" height="165px" />
                        ) : (
                            // eslint-disable-next-line jsx-a11y/alt-text
                            <img src={val?.icon} alt={val?.title} />
                        )}

                        <Typography color="textPrimary">
                            {loading ? <Skeleton width="100px" /> : val?.title}
                        </Typography>
                    </DialogContent>
                ))
            )}
        </div>
    )
}

export default AccountSetup

import React, { useState, useEffect, useMemo } from 'react'
import {
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { LoadingIndicator } from 'components'
import axios from 'axios'
import useIsMounted from 'hooks/utils/useIsMounted'
import MaskedInput from 'react-text-mask'
import { caseBuilderFieldMap } from './CaseBuilderFieldMap'
import { caseBuilderGroupMap } from './CaseBuilderGroupMap'
import {
    faExclamationCircle,
    faAddressCard,
    faFileInvoice,
    faTruck,
    faCreditCard,
    faBoxOpen,
    faBuilding,
    faBriefcase,
    faTicketAlt,
    faLaptop,
    faPlane,
    faList,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import idDirectory from './idAttributes'

const renderErrors = (errors: any, label: string) => {
    return (
        <div
            className="errorContainer"
            id={`${idDirectory.divFieldErrors}-${label}`}
        >
            <Icon icon={faExclamationCircle} />
            <ul className="errorsContainer">
                {errors.map((error: string, idx: number) => {
                    return <li key={idx}>{error}</li>
                })}
            </ul>
        </div>
    )
}

const RenderSelectField = (field: any, handleValueChange: any, value: any) => {
    const { label, id, is_required, endpoint } = field
    const [loadingAPIValues, setLoadingAPIValues] = useState<boolean>(true)
    const [apiValues, setApiValues] = useState([])
    const { isMounted } = useIsMounted()

    useEffect(() => {
        if (endpoint) {
            axios
                .get(`${endpoint}?limit=999`)
                .then(({ data: { data } }) => {
                    if (!data) return
                    switch (endpoint) {
                        case '/cm/gen/countries':
                            if (endpoint && isMounted.current) {
                                setApiValues(
                                    data.map((row: any) => {
                                        return {
                                            id: row.id,
                                            value: row.id,
                                            name: `${row.code} - ${row.name}`,
                                        }
                                    })
                                )
                            }
                            break
                        default:
                            if (endpoint && isMounted.current) {
                                setApiValues(
                                    data.map((row: any) => {
                                        return {
                                            value: row.id,
                                            ...row,
                                        }
                                    })
                                )
                            }
                            break
                    }
                })
                .finally(() => {
                    if (endpoint && isMounted.current) setLoadingAPIValues(false)
                })
        }
    }, [endpoint, isMounted])

    return (
        <FormControl fullWidth  variant="outlined" margin="dense" style={{maxWidth: '100%'}}>
            <InputLabel className={is_required ? 'requiredLabelIndicator' : ''} shrink>{label}</InputLabel>
            {loadingAPIValues ?
                <div className={'selectLoadingContainer'}><LoadingIndicator /></div>
            :
                <Select
                    name={`field_${id}`}
                    value={value || 0}
                    label={label}
                    variant="outlined"
                    fullWidth
                    required={is_required}
                    onChange={(e) => {
                        handleValueChange(
                            `field_${id}`,
                            e.target.value as string
                        )
                    }}
                    id={`${idDirectory.selectField}-${label}`}
                    className={'emp-caseBuilderFormBuilder-textFieldBox'}
                >
                    <MenuItem value={0}>
                        -
                    </MenuItem>
                    {apiValues.map(({value, name}: any, idx: number) => (
                        <MenuItem key={idx} value={value}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            }
        </FormControl>
    )
}

const RenderTextArea = (field: any, handleValueChange: any, value: any) => {
    const { label, id, is_required } = field

    return (
        <TextField
            label={label}
            name={`field_${id}`}
            multiline
            rows={4}
            fullWidth
            margin="dense"
            InputLabelProps={{
                shrink: true,
            }}
            value={value || ''}
            onChange={(e) => {
                handleValueChange(`field_${id}`, e.target.value as string)
            }}
            required={is_required}
            variant="outlined"
            id={`${idDirectory.textAreaField}-${label}`}
            className={'emp-caseBuilderFormBuilder-textFieldBox'}
        />
    )
}

const RenderNumberField = (field: any, handleValueChange: any, value: any) => {
    const { label, id, is_required } = field

    return (
        <TextField
            label={label}
            name={`field_${id}`}
            margin="dense"
            variant="outlined"
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            value={value || 0}
            onChange={(e) => {
                handleValueChange(`field_${id}`, e.target.value as string)
            }}
            fullWidth
            required={is_required}
            id={`${idDirectory.numberField}-${label}`}
            className={'emp-caseBuilderFormBuilder-textFieldBox'}
        />
    )
}

const RenderTextField = (field: any, handleValueChange: any, value: any) => {
    const { id, label, is_required } = field

    return (
        <TextField
            label={label}
            name={`field_${id}`}
            placeholder={label}
            margin="dense"
            variant="outlined"
            value={value || ''}
            InputLabelProps={{
                shrink: true,
            }}
            fullWidth
            onChange={(e) => {
                handleValueChange(`field_${id}`, e.target.value as string)
            }}
            required={is_required}
            id={`${idDirectory.textField}-${label}`}
            className={'emp-caseBuilderFormBuilder-textFieldBox'}
        />
    )
}

const RenderMaskedTextField = (
    field: any,
    handleValueChange: any,
    value: any,
    mask: any,
    placeholder: string
) => {
    const { id, label, is_required } = field

    return (
        <FormControl
            fullWidth
            variant="outlined"
            margin="dense"
            style={{ maxWidth: '100%' }}
        >
            <div
                className={`MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiOutlinedInput-marginDense ${'emp-caseBuilderFormBuilder-textFieldBox'}`}
                id={`${idDirectory.textField}-${label}`}
            >
                <InputLabel
                    className={is_required ? 'requiredLabelIndicator' : ''}
                    shrink
                    style={{
                        padding: is_required ? '0px 0px 0px 5px' : '0px 5px',
                    }}
                >
                    {label}
                </InputLabel>
                <MaskedInput
                    className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense"
                    style={{
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                        borderRadius: 4,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        padding: '8.5px 7px',
                        width: '100%',
                    }}
                    mask={mask}
                    value={value}
                    placeholder={placeholder}
                    onChange={(e) => {
                        handleValueChange(
                            `field_${id}`,
                            e.target.value as string
                        )
                    }}
                />
            </div>
        </FormControl>
    )
}

const RenderDateField = (field: any, handleValueChange: any, value: any) => {
    const { label, id, is_required } = field

    return (
        <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            label={label}
            type="date"
            value={value || ''}
            onChange={(e) => {
                handleValueChange(`field_${id}`, e.target.value as string)
            }}
            InputLabelProps={{
                shrink: true,
            }}
            required={is_required}
            id={`${idDirectory.dateField}-${label}`}
            className={'emp-caseBuilderFormBuilder-textFieldBox'}
        />
    )
}

const RenderInvalidFieldType = (field: any) => {
    const { label, type } = field

    return (
        <div
            className="invalidFieldTypeContainer"
            id={`${idDirectory.invalidField}-${label}`}
        >
            Invalid field type <b>{type}</b> passed for <b>{label}</b>
        </div>
    )
}

interface FormBuilderProps {
    fields: any
    caseBuilderFieldMap: any
    handleValueChange: (field: string, values: any) => void
    values: any
    fieldErrorList?: any
}

const FieldBuilder = ({
    fields,
    caseBuilderFieldMap,
    handleValueChange,
    values,
    fieldErrorList,
}: FormBuilderProps) => {
    return (
        <div className={'emp-caseBuilderFormBuilder-groupContainerInner'}>
            {fields.map((field: any, idx: number) => {
                const { id, type, label } = field
                const value = values[`field_${id}`]
                const additionalProps = caseBuilderFieldMap[id] ?? {}
                const fieldErrors = fieldErrorList.find((obj: any) => obj.id === id)?.errors || []
                let isInvalidField = false
                let elementToRender = <></>

                switch(type) {
                    case 'number':
                        elementToRender = RenderNumberField(field, handleValueChange, value)
                        break
                    case 'date':
                        elementToRender = RenderDateField(field, handleValueChange, value)
                        break
                    case 'select':
                        elementToRender = RenderSelectField(field, handleValueChange, value)
                        break
                    case 'textarea':
                        elementToRender = RenderTextArea(field, handleValueChange, value)
                        break
                    case 'text':
                        elementToRender = RenderTextField(field, handleValueChange, value)
                        break
                    case 'mmYYDate':
                        elementToRender = RenderMaskedTextField(
                            field,
                            handleValueChange,
                            value,
                            [
                                /\d/,
                                /\d/,
                                "/",
                                /\d/,
                                /\d/,
                            ],
                            'MM/YY'
                        )
                        break
                    default:
                        isInvalidField = true
                        elementToRender = RenderInvalidFieldType(value)
                }

                if (isInvalidField) {
                    return (
                        <div
                            key={idx}
                            className={
                                additionalProps.isFullWidth
                                    ? `fieldWidth1`
                                    : `fieldWidth2`
                            }
                            id={`${idDirectory.divField}-${label}`}
                        >
                            {elementToRender}
                        </div>
                    )
                }

                return (
                    <div
                        key={idx}
                        className={`${
                            additionalProps.isFullWidth
                                ? `fieldWidth1`
                                : `fieldWidth2`
                        }
                            ${
                                (field.is_required && !value) ||
                                fieldErrors.length
                                    ? ` requiredWithNoValue`
                                    : ''
                            }`}
                        style={{ overflow: 'auto' }}
                        id={`${idDirectory.divField}-${label}`}
                    >
                        {elementToRender}
                        {fieldErrors.length
                            ? renderErrors(fieldErrors, label)
                            : null}
                    </div>
                )
            })}
        </div>
    );
}

const groupIcons: { [propertyName: string]: IconDefinition } = {
    faAddressCard,
    faFileInvoice,
    faTruck,
    faCreditCard,
    faBoxOpen,
    faBuilding,
    faBriefcase,
    faTicketAlt,
    faLaptop,
    faPlane,
    faList,
}

type TCaseBuilderFormBuilderInputs = {
    [key: string]: string
}

interface ICaseBuilderFormBuilderProps {
    fieldGroups: any
    handleFormBuilderValueChange: (field: number, values: any) => void
    renderOneSection?: boolean
    fieldErrorList?: any
}

const CaseBuilderFormBuilder = ({
    fieldGroups = [],
    renderOneSection = false,
    handleFormBuilderValueChange,
    fieldErrorList = [],
}: ICaseBuilderFormBuilderProps) => {
    const defaultValues = useMemo<TCaseBuilderFormBuilderInputs>(() => {
        const formattedDefaultValues: { [key: string]: string } = {}
        fieldGroups.forEach((group: any) => {
            group.fields.forEach((field: any) => {
                formattedDefaultValues[`field_${field.id}`] = field.value
            })
        })
        return formattedDefaultValues
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldGroups])

    const { watch, setValue } = useForm<TCaseBuilderFormBuilderInputs>({
        defaultValues: defaultValues,
        values: defaultValues,
        mode: 'onChange',
    })

    const handleOnChangeValue = (fieldName: string, value: any) => {
        setValue(fieldName, value, { shouldDirty: true, shouldValidate: true })
        handleFormBuilderValueChange(+fieldName.replace('field_', ''), value)
    }

    return (
        <div
            className={`emp-caseBuilderFormBuilder-root ${
                renderOneSection ? ' renderOneSectionRoot' : ''
            }`}
            id={idDirectory.divRoot}
        >
            {fieldGroups.map((fieldGroup: any, idx: number) => {
                const { label, fields, id } = fieldGroup
                const groupIconName = caseBuilderGroupMap[id] ?? undefined

                return (
                    <div
                        className={`emp-caseBuilderFormBuilder-groupContainer ${
                            renderOneSection ? ' renderOneSectionGroup' : ''
                        }`}
                        key={`fieldGroup-${idx}`}
                        id={`${idDirectory.divSection}-${label}`}
                    >
                        {renderOneSection ? (
                            <>
                                <Typography
                                    variant="body1"
                                    style={{ fontWeight: 'bold' }}
                                    id={`${idDirectory.typographyHeader}-${label}`}
                                >
                                    {label}
                                </Typography>
                                <Divider />
                            </>
                        ) : (
                            <div
                                className={
                                    'emp-caseBuilderFormBuilder-groupHeaderWithIconContainer'
                                }
                            >
                                {groupIconName ? (
                                    <Icon
                                        size="lg"
                                        icon={groupIcons[groupIconName]}
                                        id={`${idDirectory.iconHeader}-${groupIconName}`}
                                    />
                                ) : (
                                    ''
                                )}
                                <span
                                    className={
                                        'emp-caseBuilderFormBuilder-groupHeader'
                                    }
                                    id={`${idDirectory.typographyHeader}-${label}`}
                                >
                                    {label}
                                </span>
                            </div>
                        )}
                        <FieldBuilder
                            fields={fields}
                            caseBuilderFieldMap={caseBuilderFieldMap}
                            handleValueChange={handleOnChangeValue}
                            values={watch()}
                            fieldErrorList={fieldErrorList}
                        />
                    </div>
                )
            })}
        </div>
    )
}

export default CaseBuilderFormBuilder

import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import UsersApi from 'api/UsersApi'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface ITempPasswordRequestProps {
    modalOpen: boolean
    setModalOpen: (isopen: boolean) => void
    tempPasswordUser: string
    setTempPasswordUser: (user: string) => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export const TempPasswordRequest = ({
    modalOpen,
    setModalOpen,
    tempPasswordUser,
    setTempPasswordUser,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: ITempPasswordRequestProps): React.ReactElement => {
    const confirmTempPassword = () => {
        setModalOpen(false)

        UsersApi.resetPassword(tempPasswordUser)
            .then(() => {
                setAlertSnackbarMainProps({
                    title: 'Success',
                    message: `Successfully reset user: ${tempPasswordUser}`,
                    intent: 'success',
                })
                setAlertSnackbarMainOpen(true)
            })
            .catch(() => {
                setAlertSnackbarMainProps({
                    title: 'Error',
                    message: `There was a problem resetting user: ${tempPasswordUser}`,
                    intent: 'error',
                })
                setAlertSnackbarMainOpen(true)
            })
            .finally(() => {
                setTempPasswordUser('')
            })
    }

    const closeTempPasswordModal = () => {
        setModalOpen(false)
        setTempPasswordUser('')
    }

    return (
        <Dialog
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={modalOpen}
            id={'usersTempPasswordRequest'}
        >
            <DialogTitle>
                <h3 style={{ margin: 0, padding: 0 }}>{'Are you sure?'}</h3>
            </DialogTitle>
            <DialogContent dividers className={'emp-content'}>
                {'Are you sure you want to reset this users password?'}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={closeTempPasswordModal}
                    variant="contained"
                    color="primary"
                >
                    Cancel
                </Button>
                <Button
                    onClick={confirmTempPassword}
                    variant="contained"
                    color="secondary"
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

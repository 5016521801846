import React, { useEffect, useState } from 'react'
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    TextField,
    Select,
} from '@mui/material'
import { LoadingIndicator, SearchableSelect } from 'components'
import { merchandiseOrServicesValues, deliveryStatuses, useDebounce } from './VisaCE.vm'
import { format, sub } from 'date-fns'
import { useFormik } from 'formik'
import * as Yup from 'yup'

interface VisaCEAddUndistputedTransactionsProps {
    currencies: any
    caseDetails: any
    formStep: number
    undisputedCases: any
    setUndisputedCases: (value: any, type: string, formStep: number, isAdd: boolean) => boolean
    setSubmitDisabled: (value: boolean) => void
}

const VisaCEAddUndistputedTransactions = ({
    setUndisputedCases,
    currencies = [],
    caseDetails,
    formStep,
    undisputedCases,
    setSubmitDisabled,
} : VisaCEAddUndistputedTransactionsProps ) => {
    const [currencySearchValue, setCurrencySearchValue] = useState<string>('')
    const foundUndisputedCase = undisputedCases[formStep - 2]
    const {
        values,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
        errors,
        touched,
        isValid,
        setFieldTouched,
    } = useFormik({

        validationSchema: Yup.object().shape({
            historical_arn_or_transaction_id: Yup.string().test(
                'arnOrTransactionIDTest',
                'Field must be 15-16 or 23-24 characters in length.',
                (value) => {
                    const len = value?.length ?? 0
                    return (len >= 15 && len <=16) || (len >= 23 && len <= 24)
                }
            ),
            historical_tran_amount: Yup.number().min(1).required().label('Transaction Amount'),
            historical_tran_currency: Yup.number().min(1).required().label('Currency'),
            historical_tran_date: Yup.string().required().label('Transaction Date'),
            shipping_delivery_status: Yup.string().required().label('Shipping Delivery Status'),
            merchandise_or_services: Yup.string().required().label('Merchandise or Services'),
            merchandise_or_services_description: Yup.string().required().label('Merchandise or Services Description'),
        }),
        validateOnMount: true,
        enableReinitialize: true,
        initialValues: {
            historical_arn_or_transaction_id: foundUndisputedCase?.historical_arn_or_transaction_id ?? '',
            historical_tran_amount: foundUndisputedCase?.historical_tran_amount ?? 0,
            historical_tran_currency: foundUndisputedCase?.historical_tran_currency ?? '',
            historical_tran_date: foundUndisputedCase?.historical_tran_date ?? '',
            historical_order_id: foundUndisputedCase?.historical_order_id ?? '',
            shipping_delivery_status: foundUndisputedCase?.shipping_delivery_status ?? '',
            tracking_information: foundUndisputedCase?.tracking_information ?? '',
            merchandise_or_services: foundUndisputedCase?.merchandise_or_services ?? '',
            merchandise_or_services_description: foundUndisputedCase?.merchandise_or_services_description ?? ''
        },
        onSubmit: (values) => {
            const caseAdded = setUndisputedCases({...values, isValid: isValid}, 'fromForm', formStep, true)
            setSubmitDisabled(!caseAdded)
        },
    })

    const handleValueUpdate = useDebounce(() => {
        if (isValid) {
            return handleSubmit()
        }
        setSubmitDisabled(true)
    }, 500)

    const handleCurrencySearchChange = (val: string) => {
        setCurrencySearchValue(val)
    }

    useEffect(() => {
        handleValueUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid, values])

    if (currencies.length <= 1) return <></>

    const date120Prior = format(sub(new Date(caseDetails?.date_post), {days: 120}), 'yyyy-MM-dd')
    const date365Prior = format(sub(new Date(caseDetails?.date_post), {days: 365}), 'yyyy-MM-dd')

    return (
        <form onSubmit={handleSubmit}>
            <div
                className={`emp-visaCEAddUndisputedTransactions-formFieldsContainer`}
            >
                <TextField
                    label="ARN/Transaction ID *"
                    name="historical_arn_or_transaction_id"
                    placeholder="ARN/Transaction ID"
                    margin="dense"
                    size="small"
                    variant="outlined"
                    value={values.historical_arn_or_transaction_id}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.historical_arn_or_transaction_id) && Boolean(touched.historical_arn_or_transaction_id)}
                    helperText={Boolean(touched.historical_arn_or_transaction_id) && errors.historical_arn_or_transaction_id}
                />
                <TextField
                    label="Transaction Amount *"
                    name="historical_tran_amount"
                    margin="dense"
                    size="small"
                    variant="outlined"
                    type='number'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={values.historical_tran_amount}
                    onChange={handleChange}
                    fullWidth
                    onBlur={handleBlur}
                    error={Boolean(errors.historical_tran_amount) && Boolean(touched.historical_tran_amount)}
                    helperText={Boolean(touched.historical_tran_amount) && errors.historical_tran_amount}
                />
                {!currencies.length ?
                    <div className={'selectLoadingContainer'}><LoadingIndicator /></div>
                :
                    <FormControl error={Boolean(errors.historical_tran_currency) && Boolean(touched.historical_tran_currency)}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        size="small"
                        style={{maxWidth: '100%'}}
                    >
                        <InputLabel
                            shrink
                        >
                            Currency *
                        </InputLabel>
                        <SearchableSelect
                            value={
                                currencies.find((obj: any) => {
                                    return obj.value === values.historical_tran_currency
                                })?.name || '-'
                            }
                            onValueChange={(e: any) =>
                                setFieldValue('historical_tran_currency', e.value)
                            }
                            searchValue={currencySearchValue}
                            onSearchChange={handleCurrencySearchChange}
                            options={[
                                {
                                    id: 0,
                                    value: '',
                                    name: '-',
                                },
                                ...(currencies.length ? currencies.filter((obj: any) => obj.name.startsWith(currencySearchValue.toUpperCase())) || [] : [])
                            ]}
                            accessor={'name'}
                            debounceDelay={200}
                            hideSearch={false}
                            isPaginate={false}
                        />
                        {Boolean(errors.historical_tran_currency) && Boolean(touched.historical_tran_currency) ?
                            <FormHelperText>
                                {errors.historical_tran_currency}
                            </FormHelperText>
                        : ''}
                    </FormControl>
                }
                <TextField
                    margin="dense"
                    size="small"
                    variant="outlined"
                    fullWidth
                    label="Transaction Date *"
                    type="date"
                    name="historical_tran_date"
                    value={values.historical_tran_date}
                    onChange={handleChange}
                    InputProps={{
                        inputProps: {
                            min: date365Prior ?? '',
                            max: date120Prior ?? ''
                        } 
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onBlur={handleBlur}
                    error={Boolean(errors.historical_tran_date) && Boolean(touched.historical_tran_date)}
                    helperText={Boolean(touched.historical_tran_date) && errors.historical_tran_date}
                />
                <TextField
                    label="Order ID"
                    name="historical_order_id"
                    placeholder="Order ID"
                    margin="dense"
                    size="small"
                    variant="outlined"
                    value={values.historical_order_id}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </div>
            <div className={'emp-visaCETopSection-formFieldsContainer'}>
                <div className='select-fix'>
                    <FormControl fullWidth  variant="outlined" margin="dense" size="small" style={{maxWidth: '100%'}}>
                        <InputLabel
                            shrink
                        >
                            Merchandise or Services Provided:
                        </InputLabel>
                        <Select
                            name={`merchandise_or_services`}
                            value={values.merchandise_or_services ?? ''}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => {
                                setFieldValue('merchandise_or_services', e.target.value)
                                setFieldValue('shipping_delivery_status', e.target.value === 'Services' ? 'O' : '' )
                                setFieldValue('tracking_information', '')
                                // Why? 'Cuz Formik.
                                setTimeout(() => {
                                    setFieldTouched('shipping_delivery_status')
                                    setFieldTouched('tracking_information')
                                }, 10)
                            }}
                        >
                            <MenuItem value={''}>
                                -
                            </MenuItem>
                            {merchandiseOrServicesValues.map(({value, name}: any, idx: number) => (
                                <MenuItem key={idx} value={value}>
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className='select-fix'>
                    {values.merchandise_or_services === 'Merchandise' ?
                        <FormControl sx={{ fieldset: { legend: {maxWidth: '100%'}} }} fullWidth  variant="outlined" margin="dense" size="small" style={{maxWidth: '100%'}}>
                            <InputLabel
                                shrink
                            >
                                Delivery Status:
                            </InputLabel>
                            <Select
                                name={`shipping_delivery_status`}
                                value={values.shipping_delivery_status ?? ''}
                                variant="outlined"
                                notched
                                fullWidth
                                disabled={!values.merchandise_or_services}
                                onChange={(e) => {
                                    if (e.target.value) {
                                        return setFieldValue('shipping_delivery_status', e.target.value)
                                    }
                                    setFieldValue('shipping_delivery_status', e.target.value)
                                    setFieldValue('tracking_information', '')
                                }}
                            >
                                <MenuItem value={''}>
                                    -
                                </MenuItem>
                                {deliveryStatuses.map(({value, name}: any, idx: number) => (
                                    <MenuItem key={idx} value={value}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    :
                        <></>
                    }
                    
                </div>
                <div>
                    {values.merchandise_or_services === 'Merchandise' ?
                        <TextField
                            label="Tracking Number:"
                            name={`tracking_information`}
                            placeholder="Tracking Number"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            value={values.tracking_information ?? ''}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required={true}
                            disabled={!values.shipping_delivery_status}
                        />
                    :
                        <></>
                    }
                </div>
            </div>
            <div className={'emp-visaCETopSection-descriptionContainer'}>
                <TextField
                    label="Merchandise or Services Description:"
                    name={`merchandise_or_services_description`}
                    placeholder="Description"
                    margin="dense"
                    size="small"
                    variant="outlined"
                    value={values.merchandise_or_services_description ?? ''}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                />
            </div>
        </form>
    )
}

export default VisaCEAddUndistputedTransactions
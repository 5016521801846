import BaseService, { PageOf } from 'lib/BaseService'

type Descriptor = { id: number; descriptor: string }

type Processor = {
    id: number
    name: string
    domestic: boolean
    days_due: number
}

type Status = { id: number; name: string }

export interface Mid {
    id: number
    merchant_id: number
    alias: string
    date_created: string
    descriptor: Descriptor
    mid: string
    processor: Processor
    status: Status
}

export class MidService extends BaseService {
    basePath = '/cm/mids'

    get(midId: string) {
        return this.request<Mid>(`/${midId}`)
    }

    /**
     *
     * @param merchantId - parent merchant id
     * @param options { search, limit }
     *
     * @returns Promise resolving children merchant,
     */
    list(merchantId: string, options?: any) {
        return this.request<PageOf<Mid[]>>(
            this.injectParams({
                merchant_id: merchantId,
                limit: 10,
                start: 0,
                ...options,
            })
        )
    }

    removeUserAccess(midId: number, userId: number) {
        return this.request(`/${midId}/permissions/${userId}`, {
            method: 'POST',
            body: JSON.stringify({
                is_excluded: true,
            }),
        })
    }

    /** */
    removeAllMidPermissions(userId: number, midIds: number[]) {
        return this.updateMidPermissions(userId, [], midIds)
    }

    /** */
    addAllMidPermissions(userId: number, midIds: number[]) {
        return this.updateMidPermissions(userId, midIds, [])
    }

    /** */
    protected updateMidPermissions(
        userId: number,
        mids: number[] = [],
        excludedMids: number[] = []
    ) {
        return this.request(`/permissions/${userId}`, {
            method: 'PUT',
            body: JSON.stringify({
                mids,
                exclude_mids: excludedMids,
            }),
        })
    }
}

import { useEffect, useReducer, useState } from 'react'
import { Params as ParamsType } from './types'

export const useParams = (defaults?: {
    start?: number
    limit?: number
    sort_by?: string
    sort_order?: 'asc' | 'desc'
    search?: string
    descriptor?: string
    date_range?: string
}) => {
    // reducer for api parameters
    const [queryParams, setQueryParams] = useState<any[]>([])
    const [params, setParams] = useReducer(
        (state: ParamsType, action: Partial<ParamsType>) => ({
            ...state,
            ...action,
        }),
        {
            start: defaults?.start ? defaults?.start : 0,
            limit: defaults?.limit ? defaults?.limit : 25,
            sort_by: defaults?.sort_by ? defaults?.sort_by : '',
            sort_order: defaults?.sort_order ? defaults?.sort_order : 'asc',
            search: defaults?.search ? defaults?.search : '',
            date_range: defaults?.date_range ? defaults?.date_range : '',
            descriptor: defaults?.descriptor ? defaults?.descriptor : '',
        }
    )

    useEffect(() => {
        setParams({ ...Object.fromEntries(queryParams) })
    }, [queryParams])

    // reset api parameters to defaults
    const reset = (limit: number, start: number) => {
        setParams({
            start: 0,
            limit: 25,
            sort_by: '',
            sort_order: 'asc',
            search: '',
            date_range: '',
            descriptor: '',
        })
    }

    return {
        params,
        setParams,
        setQueryParams,
        reset,
    }
}

export default useParams

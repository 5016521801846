/**
 * @description Directory for ID Attributes on Metric.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    aLink: `metric-a-link`,
    divRoot: `metric-div-root`,
    divInfo: `metric-div-info`,
    divIcon: `metric-div-icon`,
    imgIcon: `metric-img-icon`,
    divContent: `metric-div-content`,
    imgCaretRight: `metric-img-caretRight`,
    imgArrowRight: `metric-img-arrowRight`,
    divAltLink: `metric-div-altLink`,
    textValue: `metric-text`,
}

export default idDirectory

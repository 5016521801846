import { useSWRActions, SWRActions, useSwrData } from 'hooks/cb-api'
import useParams from 'hooks/cb-api/useParams'

export interface Onboarding {
    actions: {
        isEdit: boolean
    }
    merchant: string
    username: string
    upload_type: string
    upload_date: string
    finalized_date: string
    uploaded_rows: number
    id: number
}

interface OnboardingVM {
    data: Onboarding[]
    error: boolean | undefined
    swrActions: SWRActions
}

const useOnBoarding = (): OnboardingVM => {
    const { params, setQueryParams, setParams, reset } = useParams({})

    const endpointConfig = {
        paramInUrl: false,
        url: '/cm/onboarding/files',
        id: {
            value: 'onboarding',
        },
    }

    const onboardingData = useSwrData(endpointConfig, params)
    const swrActions = useSWRActions(onboardingData, {
        setParams,
        setQueryParams,
        params,
        reset,
    })

    const onboardingDataRefactored = onboardingData.data.map((val: any) => {
        return {
            actions: {
                isEdit: !Boolean(val.finalized_date),
            },
            merchant: val.merchant.business_name,
            username: `${val.user.name} ${val.user.last_name}`,
            upload_type: val.onboarding_type.name,
            upload_date: val.date_created,
            finalized_date: val.finalized_date,
            uploaded_rows: val.rows_in_upload ?? 0,
            id: val.id,
        }
    })

    return {
        data: onboardingDataRefactored ?? [],
        error: onboardingData.error,
        swrActions,
    }
}

export default useOnBoarding

import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { DataDropdownTable } from 'components'

interface DropdownData {
    columns: {
        retrievals: string
        first_cycle: string
        second_cycle: string
    }
    rows: {
        visa: string
        master_card: string
        discover: string
        american_express: string
        other: string
    }
}

export const ReconciliationReportGridDetails = (props: ICellRendererParams) => {

    const { data: rowData } = props

    const dropdownData: DropdownData = {
        columns: {
            retrievals: 'Retrievals',
            first_cycle: '1st Cycle',
            second_cycle: '2nd Cycle',
        },
        rows: {
            visa: 'Visa',
            master_card: 'Master Card',
            discover: 'Discover',
            american_express: 'American Express',
            other: 'Other',
        },
    }

    return (
        <div  id={"reconciliationReportGridDetailsAG"}>
            <DataDropdownTable data={rowData} dropdownData={dropdownData} />
        </div>
    )
}

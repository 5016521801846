/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import {
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Select,
    MenuItem,
    Box,
} from '@mui/material'
import { CompellingEvidenceLauncher, LoadingIndicator } from 'components'
import { useDropzone } from 'react-dropzone'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import uploadIcon from 'assets/icons/upload-icon-bg-alt.png'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import useIsMounted from 'hooks/utils/useIsMounted'
import { clientTheme } from 'theme-exports'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'

export interface FileDnDProps {
    onDrop: (fileList: File[]) => void
    icon?: string
    accepts?: string[] | string
    fileUploadInstructionText?: string
    fileUploadCriteria?: string
    maxUploadFileSize?: number
    enableCustomFileDnDFeature?: boolean
    customFileDnDFeature?: React.ReactNode
}

/**
 * Drag and drop file upload area.
 *
 * @param props {@link FileDnDProps}
 */
export const FileDnD = ({
    onDrop,
    icon = uploadIcon,
    accepts,
    fileUploadInstructionText,
    fileUploadCriteria,
    maxUploadFileSize = 5242880,
    enableCustomFileDnDFeature = false,
    customFileDnDFeature,
}: FileDnDProps): JSX.Element => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: accepts,
        maxSize: maxUploadFileSize,
        maxFiles: 1,
        onDrop,
        disabled: isReadOnly,
    })

    return !enableCustomFileDnDFeature ? (
        <Box
            {...(getRootProps() as any)} // TODO: figure why this type error is happening
            className={`${'emp-fileDnD-root'} ${
                isReadOnly && 'emp-fileDnD-disabledRoot'
            }`}
            style={{ backgroundImage: `url(${icon})` }}
            sx={{
                '&:hover': {
                    borderColor: clientTheme.uploadDnD.borderColor,
                    backgroundColor: clientTheme.uploadDnD.backgroundColor,
                    backgroundSize: 240,

                    '& .MuiTypography-body1': {
                        color: clientTheme.uploadDnD.labelColor,
                    },
                },
            }}
        >
            <input id="dz-input" {...getInputProps()} />
            <div className={'emp-fileDnD-label'}>
                {isDragActive ? (
                    <Typography variant="h6">
                        Drop the files here ...
                    </Typography>
                ) : (
                    <React.Fragment>
                        <Typography>
                            {fileUploadInstructionText
                                ? fileUploadInstructionText
                                : 'Drag & drop to upload your case document or click to browse'}
                        </Typography>
                        {fileUploadCriteria && (
                            <Typography
                                className={'emp-fileDnD-fileUploadCriteria'}
                            >
                                {fileUploadCriteria}
                            </Typography>
                        )}
                    </React.Fragment>
                )}
            </div>
        </Box>
    ) : (
        <div className={'emp-fileDnD-customFileDnDFeatureContainer'}>
            {customFileDnDFeature}
        </div>
    )
}

interface FileUploadProps {
    /**  */
    onUpload: (fileList: File[]) => void
    /**  */
    onTemplateListSelect?: (template: any) => void
    /**  */
    onFileDragAndDrop?: (fileList: File[]) => void
    /** */
    loadingFileDragAndDrop: boolean
    /** */
    onUnstage: (val: any) => void
    /** */
    onReorderCombinedDocuments?: (val: any) => void
    /**  */
    files: File[]
    /** title to display over uploaded file list. */
    fileListTitle?: string
    /** file types to accept */
    accepts: string | string[]
    /** max upload file size */
    maxUploadFileSize: number
    /** Text to display in file upload component */
    fileUploadInstructionText?: string
    /** Is this running in Chargebacks - upload representment modal? */
    isRepresentmentModal?: boolean
    /** Will be passed if this is running in representment upload modal */
    templatesList?: any
    combinedDocuments?: any
    isMultiSelect?: boolean
    showCompellingEvidence?: boolean
    setCompellingEvidenceModalOpen?: (open: boolean, closeRepresentment?: boolean) => void
}

/**
 * helper to format byte size to a human readable form.
 *
 * @param bytes - size of file in number of bytes.
 *
 * @returns {string} - human readable size string i.e 1.3KB, 1MB.
 */
function bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0 Byte'
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`
}

/**
 * Use to upload files in the browser.
 *
 */
export const FileUpload = ({
    files,
    fileListTitle = 'Files Added',
    onUpload,
    onUnstage,
    onTemplateListSelect,
    onFileDragAndDrop,
    loadingFileDragAndDrop,
    onReorderCombinedDocuments = () => {},
    accepts,
    maxUploadFileSize,
    fileUploadInstructionText,
    isRepresentmentModal = false,
    templatesList,
    combinedDocuments,
    isMultiSelect = false,
    showCompellingEvidence = false,
    setCompellingEvidenceModalOpen = (open: boolean, closeRepresentment?: boolean) => {},
}: FileUploadProps): JSX.Element => {
    const { client } = useFeatureToggle('CLIENT')
    const { style: formVariantStyle = 'outlined' } = useFeatureToggle(
        'FORM_FIELDS'
    )
    const [selectedTemplateId, setSelectedTemplateId] = useState(-1)
    const [documentDisplayTitles, setDocumentDisplayTitles] = useState([])

    const { isMounted } = useIsMounted()

    // with multiple cases are selected for doc upload, each case gets the same document which results in duplicate combined files - this removes any duplicates so that the document names can be displayed correctly in the DnD below
    useEffect(() => {
        if (isMounted.current && combinedDocuments?.length) {
            // if multi select, the combined documents come in as an array of case ids with attached files, we just need the file names, so we can hard target the first index case of combinedDocuments to read the file array within to display what files have been uploaded
            isMultiSelect
                ? setDocumentDisplayTitles(combinedDocuments[0].files)
                : setDocumentDisplayTitles(combinedDocuments)
        }
    }, [combinedDocuments, isMultiSelect, isMounted])

    return (
        <Grid container>
            {/* Upload Zone */}
            <Grid item xs={12} sm={6}>
                {isRepresentmentModal && (
                    <Grid className={'emp-fileUpload-templateLibContainer'}>
                        <Select
                            labelId="template-field-label"
                            id="template-field"
                            name="template"
                            value={selectedTemplateId}
                            onChange={(e: any) => {
                                setSelectedTemplateId(e.target.value)
                            }}
                            variant={formVariantStyle}
                            sx={[
                                formVariantStyle === 'standard' && {
                                    '& .MuiInputBase-input': {
                                        ...clientTheme.formFields.formText
                                            .standard,
                                        borderBottom: '1px solid #84878E',
                                    },
                                },
                            ]}
                        >
                            <MenuItem value={-1}>
                                Choose a template from list.
                            </MenuItem>
                            {templatesList.map((option: any) => {
                                return (
                                    <MenuItem
                                        key={`template-option-${option.value}`}
                                        value={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        <Button
                            size={'small'}
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                const foundTemplateFile = templatesList.find(
                                    (template: any) =>
                                        template.value === selectedTemplateId
                                )
                                if (foundTemplateFile && onTemplateListSelect) {
                                    onTemplateListSelect(foundTemplateFile)
                                }
                            }}
                            sx={[
                                client === 'jpmc' &&
                                    clientTheme.buttons.greyButton.style,
                            ]}
                        >
                            Add
                        </Button>
                    </Grid>
                )}
                <FileDnD
                    fileUploadInstructionText={fileUploadInstructionText}
                    onDrop={onFileDragAndDrop || onUpload}
                    accepts={accepts}
                    fileUploadCriteria="(Please upload only .JPG or .PDF files)"
                    maxUploadFileSize={maxUploadFileSize}
                />
            </Grid>
            {/* File List */}
            <Grid item xs={12} sm={6} className={'emp-fileUpload-fileRoot'}>
                {showCompellingEvidence && (
                    <CompellingEvidenceLauncher
                        isRemedyPossible={true}
                        setCompellingEvidenceModalOpen={
                            setCompellingEvidenceModalOpen
                        }
                    />
                )}
                <Typography variant="h3">{fileListTitle}</Typography>
                {loadingFileDragAndDrop && (
                    <div className="emp-fileUpload-loadingContainer">
                        <LoadingIndicator text={'Uploading...'} />
                    </div>
                )}
                {!combinedDocuments ? (
                    <List>
                        {files.map((file) => (
                            <ListItem
                                key={`${file.name}-${file.lastModified}`}
                                dense
                            >
                                <ListItemIcon>
                                    <IconButton
                                        size="small"
                                        onClick={(): void => onUnstage(file)}
                                    >
                                        <Icon icon={faTimes} />
                                    </IconButton>
                                </ListItemIcon>
                                <ListItemText>{file.name}</ListItemText>
                                <ListItemText>{`(${bytesToSize(
                                    file.size
                                )})`}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <DragDropContext onDragEnd={onReorderCombinedDocuments}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <List
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {documentDisplayTitles.map(
                                        (doc: any, idx: any) => (
                                            <Draggable
                                                key={`doc-${idx}`}
                                                draggableId={`doc-${idx}`}
                                                index={idx}
                                            >
                                                {(provided, snapshot) => (
                                                    <ListItem
                                                        dense
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <ListItemIcon>
                                                            <IconButton
                                                                size="small"
                                                                onClick={(): void =>
                                                                    onUnstage(
                                                                        idx
                                                                    )
                                                                }
                                                            >
                                                                <Icon
                                                                    icon={
                                                                        faTimes
                                                                    }
                                                                />
                                                            </IconButton>
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            {doc.label}
                                                        </ListItemText>
                                                    </ListItem>
                                                )}
                                            </Draggable>
                                        )
                                    )}
                                    {provided.placeholder}
                                </List>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </Grid>
        </Grid>
    )
}

export default FileUpload

import React, { useState } from 'react'
import { Tooltip } from '@mui/material'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { AssignUser, AssignUserIcon, DnrActionIcon, FlagIcon } from 'components'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import { ModalMode, ModalVariant } from 'hooks/cb-api'
import { useSWRContext } from 'context/SWRContext'
import { FlagCase } from '../modals/FlagCase'

interface FlagCellProps {
    row: any
    rowIndex: number
    testId: string
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export const FlagCell: React.FC<FlagCellProps> = ({
    row,
    rowIndex,
    testId,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}) => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const [open, setOpen] = useState(false)

    return (
        <div id={'caseMgmtFlagCell'}>
            <Tooltip title={row.flag?.comment ?? ''} placement="right" arrow>
                <div className={`${isReadOnly ? 'emp-disabledIcon' : ''}`}>
                    <FlagIcon
                        isFlagged={Boolean(row.flag)}
                        onClick={() => setOpen(true)}
                        className={'emp-actionIcon'}
                        rowIndex={rowIndex}
                        testId={testId}
                    />
                </div>
            </Tooltip>
            {open && (
                <FlagCase
                    row={row}
                    open={open}
                    setOpen={setOpen}
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                />
            )}
        </div>
    )
}

interface AssignCellProps {
    toggleModal?: (variant: ModalVariant, mode?: ModalMode) => void
    row: any
    rowIndex: number
    testId: string
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

// TODO: centralize all modals
export const AssignCell: React.FC<AssignCellProps> = ({
    toggleModal,
    row,
    rowIndex,
    testId,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}) => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const [open, setOpen] = useState<boolean>(false)
    const toggleOpen = () => setOpen((prev) => !prev)
    const { swrActions } = useSWRContext()
    const { invalidate } = swrActions
    const { assigned_user } = row
    const assignEnabled = row.properties.is_assign_user_enabled ?? false

    return (
        <div className={`container`} id={'caseMgmtAssignCell'}>
            <AssignUser
                selectedCaseIds={[]}
                open={open}
                toggleOpen={toggleOpen}
                row={row}
                rehydrateView={invalidate}
                unassign={Boolean(assigned_user)}
                cases={[]}
                setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
            />
            <span
                style={{
                    cursor: 'pointer',
                }}
            >
                {Boolean(assigned_user) ? (
                    <Tooltip
                        title={`${row.assigned_user?.fname} ${row.assigned_user?.lname}`}
                        placement="top"
                        arrow
                    >
                        <div
                            onClick={() => {
                                if (!assignEnabled || isReadOnly) return
                                toggleOpen()
                            }}
                            className={`${'emp-assigned'} ${
                                !assignEnabled || isReadOnly
                                    ? 'emp-disabled'
                                    : ''
                            }`}
                        >
                            {`${row.assigned_user?.fname} ${row.assigned_user?.lname}`}
                        </div>
                    </Tooltip>
                ) : (
                    <Tooltip
                        title={assignEnabled ? 'Assign' : ''}
                        placement="top"
                        arrow
                    >
                        <div
                            className={`${
                                !assignEnabled || isReadOnly
                                    ? 'emp-disabledIcon'
                                    : ''
                            }`}
                        >
                            <AssignUserIcon
                                onClick={() => {
                                    if (!assignEnabled || isReadOnly) return
                                    toggleOpen()
                                }}
                                assignEnabled={assignEnabled}
                                className={'emp-actionIcon'}
                                rowIndex={rowIndex}
                                testId={testId}
                            />
                        </div>
                    </Tooltip>
                )}
            </span>
        </div>
    )
}

interface ActionsCellProps {
    row: any
    testId: string
    selectedTabFilter: string
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export const ActionsCell = ({
    row,
    testId,
    selectedTabFilter,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: ActionsCellProps): React.ReactElement => {
    const { swrActions } = useSWRContext()
    const { invalidate: rehydrateView } = swrActions

    return (
        <div className={`ccImage`} id={'caseMgmtActionsCell'}>
            <DnrActionIcon
                row={row.row}
                rehydrateView={rehydrateView}
                testId={testId}
                selectedTabFilter={selectedTabFilter}
                setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
            />
        </div>
    )
}

import { useActiveMerchant } from 'components/ActiveMerchantContext'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import CB from 'lib'
import { Currency, Cycle, ReasonCode } from 'lib/Generic'
import { ConfirmJob, JobParams, ScheduledJob } from 'lib/Process'
import { useMemo, useReducer, useState, useEffect } from 'react'
import { format } from 'date-fns'

export enum BulkCloseParam {
    AMOUNT = 'amount',
    CYCLE = 'cycle',
    CURRENCY = 'currency',
    REASON_CODE = 'reason_code',
}

type ParamValue = string | { min: number; max: number }

export interface Param<V = any> {
    name: BulkCloseParam
    label: string
    value: V
    displayValue: string
}

export type Field = Partial<Param> & {
    name: BulkCloseParam
}

type ParamMap = {
    [BulkCloseParam.AMOUNT]: Param<{ min: number; max: number }>
    [BulkCloseParam.CURRENCY]: Param<string>
    [BulkCloseParam.CYCLE]: Param<string>
    [BulkCloseParam.REASON_CODE]: Param<string>
}

type AddParam = (
    name: BulkCloseParam,
    value: ParamValue,
    displayValue?: string
) => void

type RemoveParam = (field: BulkCloseParam) => void

type GetParamByKey = (key: BulkCloseParam) => Param | undefined

type Submit = () => Promise<ConfirmJob>

type Confirm = () => Promise<ScheduledJob>

type Cancel = () => void

interface BulkCloseInstance {
    params: Param[]
    addParam: AddParam
    removeParam: RemoveParam
    submit: Submit
    confirm: Confirm
    getParamByKey: GetParamByKey
    currencies: Currency[]
    setCurrenciesSearchValue: (value: string) => void
    currenciesSearchValue: string
    cycles: Cycle[]
    setCyclesSearchValue: (value: string) => void
    cyclesSearchValue: string
    reasonCodes: ReasonCode[]
    setReasonCodesSearchValue: (value: string) => void
    reasonCodesSearchValue: string
    isSubmitting: boolean
    isConfirming: boolean
    jobSubmitted: boolean
    jobConfirmation: ConfirmJob | null
    cancel: Cancel
    isSubmitDisabled: boolean
    currentScheduledJob: string | null
    lastScheduledJob: string | null
}

type UseBulkClose = () => BulkCloseInstance

interface StateObject<D> {
    loading: boolean
    error: Error | null
    data: D[] | null
    search: ''
}

export enum Fields {
    CYCLE = 'cycle',
    CURRENCY = 'currency',
    REASON_CODE = 'reason_code',
}

type State = {
    [Fields.CURRENCY]: StateObject<Currency>
    [Fields.CYCLE]: StateObject<Cycle>
    [Fields.REASON_CODE]: StateObject<ReasonCode>
}

enum ActionType {
    RESOLVE_QUERY = 'resolve-query',
    REJECT_QUERY = 'reject-query',
    SET_SEARCH_STRING = 'set-search-string',
}

interface Action {
    type: ActionType
    field: Fields
    [key: string]: any
}

const initialState: State = {
    currency: { loading: false, error: null, data: null, search: '' },
    cycle: { loading: false, error: null, data: null, search: '' },
    reason_code: { loading: false, error: null, data: null, search: '' },
}

type Reducer = (state: State, action: Action) => State

const reducer: Reducer = (state, action) => {
    switch (action.type) {
        case ActionType.RESOLVE_QUERY: {
            return {
                ...state,
                [action.field]: {
                    ...state[action.field],
                    loading: false,
                    data: action.data,
                    error: null,
                },
            }
        }
        case ActionType.REJECT_QUERY: {
            return {
                ...state,
                [action.field]: {
                    ...state[action.field],
                    loading: false,
                    data: null,
                    error: action.error,
                },
            }
        }
        case ActionType.SET_SEARCH_STRING: {
            return {
                ...state,
                [action.field]: {
                    ...state[action.field],
                    loading: true,
                    search: action.search,
                    data: null,
                    error: null,
                },
            }
        }
        default: {
            throw new Error(
                `Error setting state, action.type '${action.type}' is unknown`
            )
        }
    }
}

export const useBulkClose: UseBulkClose = () => {
    const { user } = useAuthedUser()
    const activeMerchant = useActiveMerchant()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isConfirming, setIsConfirming] = useState(false)
    const [jobSubmitted, setJobSubmitted] = useState(false)
    const [state, dispatch] = useReducer<Reducer>(reducer, initialState)
    const [currentScheduledJob, setCurrentScheduledJob] = useState<
        string | null
    >(null)
    const [lastScheduledJob, setLastScheduledJob] = useState<
        string | null
    >(null)
    const [jobConfirmation, setJobConfirmation] = useState<ConfirmJob | null>(
        null
    )
    const [paramMap, setParamMap] = useState<Partial<ParamMap>>({
        amount: undefined,
        currency: undefined,
        cycle: undefined,
        reason_code: undefined,
    })

    const formatValue = (
        name: BulkCloseParam,
        value: string | { max: number; min: number }
    ): any => {
        let displayValue = value
        if (name === BulkCloseParam.AMOUNT && typeof value !== 'string') {
            displayValue = `${value?.min?.toFixed(2)} - ${value?.max?.toFixed(
                2
            )}`
        }
        if (name === BulkCloseParam.REASON_CODE) {
            const selectedReasonCodeInfo = state.reason_code?.data?.find(
                (row: any) => String(row.code) === value
            )
            displayValue = selectedReasonCodeInfo
                ? `${selectedReasonCodeInfo?.card_type?.name ?? 'N/A'} - ${
                      selectedReasonCodeInfo?.code
                  }`
                : ''
        }
        return {
            name,
            value,
            label: name
                .split('_')
                .map((i) => i.toLowerCase())
                .join(' '),
            displayValue: displayValue,
        }
    }

    // Needed to update 'reason code' display value once we have reason code list.
    const reasonCodeLength = state.reason_code?.data?.length
    const reasonCodeValue = paramMap[BulkCloseParam.REASON_CODE]?.value
    useEffect(() => {
        if (reasonCodeLength && reasonCodeValue && !state.reason_code.search) {
            setParamMap({
                ...paramMap,
                [BulkCloseParam.REASON_CODE]: formatValue(
                    BulkCloseParam.REASON_CODE,
                    reasonCodeValue
                ),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reasonCodeLength, reasonCodeValue])

    useEffect(() => {
        CB.process.listAutoAcceptJobs().then(async (r) => {
            const queuedJobs = r.data.filter((i) => !Boolean(i.date_run_end))
            if (queuedJobs[0]) {
                const {
                    date_created,
                    records_num,
                    user_id,
                    reason_code,
                    cycle,
                    currency,
                    amt_from,
                    amt_to,
                } = queuedJobs[0]
                const user = await CB.users.get(user_id)
                setCurrentScheduledJob(
                    `${format(new Date(date_created), "do 'of' LLLL yyyy 'at' K':'mmaaa")}, ${user.email}, ${records_num} records to update!`
                )
                setParamMap({
                    [BulkCloseParam.CURRENCY]: formatValue(
                        BulkCloseParam.CURRENCY,
                        currency
                    ),
                    [BulkCloseParam.CYCLE]: formatValue(BulkCloseParam.CYCLE, cycle),
                    [BulkCloseParam.REASON_CODE]: formatValue(
                        BulkCloseParam.REASON_CODE,
                        reason_code
                    ),
                    [BulkCloseParam.AMOUNT]: formatValue(BulkCloseParam.AMOUNT, {
                        min: +amt_from,
                        max: +amt_to,
                    }),
                })
            } else if (r.data.length) {
                const {
                    date_created,
                    records_num,
                    user_id
                } = r.data.pop() as any
                const user = await CB.users.get(user_id)
                setLastScheduledJob(
                    `${format(new Date(date_created), "do 'of' LLLL yyyy 'at' K':'mmaaa")}, ${user.email}, ${records_num} records updated.`
                )
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        CB.generic
            .listCurrencies({ search: state.currency.search, limit: "999" })
            .then((r) =>
                dispatch({
                    type: ActionType.RESOLVE_QUERY,
                    field: Fields.CURRENCY,
                    data: r.data,
                })
            )
            .catch((err) =>
                dispatch({
                    type: ActionType.REJECT_QUERY,
                    field: Fields.CURRENCY,
                    error: err,
                })
            )
    }, [state.currency.search])

    useEffect(() => {
        CB.generic
            .listCycles({ search: state.cycle.search, limit: "999" })
            .then((r) =>
                dispatch({
                    type: ActionType.RESOLVE_QUERY,
                    field: Fields.CYCLE,
                    data: r.data,
                })
            )
            .catch((err) =>
                dispatch({
                    type: ActionType.REJECT_QUERY,
                    field: Fields.CYCLE,
                    error: err,
                })
            )
    }, [state.cycle.search])

    useEffect(() => {
        CB.generic
            .listReasonCodes({ search: state.reason_code.search, limit: '999' })
            .then((r) => {
                const formattedReasonCodes = r.data
                    .sort((a: any, b: any) => {
                        const aCardName = a.card_type.name.toLowerCase()
                        const bCardName = b.card_type.name.toLowerCase()
                        return aCardName > bCardName
                            ? 1
                            : aCardName < bCardName
                            ? -1
                            : 0
                    })
                    .map((row: any) => ({
                        ...row,
                        name: `${row.card_type?.name ?? 'N/A'} - ${row.code}`,
                    }))
                return dispatch({
                    type: ActionType.RESOLVE_QUERY,
                    field: Fields.REASON_CODE,
                    data: formattedReasonCodes,
                })
            })
            .catch((err) =>
                dispatch({
                    type: ActionType.REJECT_QUERY,
                    field: Fields.REASON_CODE,
                    error: err,
                })
            )
    }, [state.reason_code.search])

    const addParam: AddParam = (name, value, displayValue = '') => {
        setParamMap({
            ...paramMap,
            [name]: {
                name,
                value,
                label: name
                    .split('_')
                    .map((i) => i.toLowerCase())
                    .join(' '),
                displayValue: displayValue ? displayValue : value,
            },
        })
    }

    const removeParam: RemoveParam = (field) => {
        const newParamMap = Object.keys(paramMap).reduce(
            (acc, key) => ({
                ...acc,
                [key as BulkCloseParam]:
                    key === field ? undefined : paramMap[key as BulkCloseParam],
            }),
            {}
        )
        setParamMap(newParamMap)
    }

    const getParamByKey: GetParamByKey = (key: BulkCloseParam) => {
        return paramMap[key]
    }

    const submit: Submit = () =>
        new Promise((resolve, reject) => {
            if (!user?.id) reject('No authenticated user')
            else {
                let jobParams: JobParams = {
                    user_id: user.id,
                    merchant_id: +activeMerchant.id,
                }
                Object.keys(paramMap).forEach((key) => {
                    switch (key) {
                        case BulkCloseParam.AMOUNT: {
                            if (paramMap.amount?.value) {
                                jobParams.amt_from = paramMap.amount.value.min
                                jobParams.amt_to = paramMap.amount.value.max
                            }
                            break
                        }
                        case BulkCloseParam.CURRENCY: {
                            if (paramMap.currency?.value) {
                                jobParams.currency_id = state.currency.data?.find(
                                    (i) =>
                                        i.currency === paramMap.currency?.value
                                )?.id
                            }
                            break
                        }
                        case BulkCloseParam.CYCLE: {
                            if (paramMap.cycle?.value) {
                                jobParams.cycle_id = state.cycle.data?.find(
                                    (i) => i.name === paramMap.cycle?.value
                                )?.id
                            }
                            break
                        }
                        case BulkCloseParam.REASON_CODE: {
                            if (paramMap.reason_code?.value) {
                                jobParams.reason_code_id = state.reason_code.data?.find(
                                    (i) =>
                                        i.code.toString() ===
                                        paramMap.reason_code?.value
                                )?.id
                            }
                            break
                        }
                    }
                })

                setIsSubmitting(true)

                CB.process
                    .autoAccept(jobParams)
                    .then((r) => {
                        setJobConfirmation(r)
                        resolve(r)
                    })
                    .catch(reject)
                    .finally(() => setIsSubmitting(false))
            }
        })

    const confirm: Confirm = () => {
        if (jobConfirmation === null) {
            throw new Error(
                'No submitted job to confirm. Submit a job before confirming'
            )
        }
        setIsConfirming(true)
        return CB.process
            .confirmAutoAccept(jobConfirmation)

            .finally(() => {
                setJobSubmitted(true)
                setIsConfirming(false)
                setJobConfirmation(null)
            })
    }

    const cancel: Cancel = () => {
        setJobConfirmation(null)
    }

    const params: Param[] = useMemo(
        () => Object.values(paramMap).filter(Boolean) as Param[],
        [paramMap]
    )

    return {
        params,
        addParam,
        removeParam,
        submit,
        confirm,
        currencies: state.currency.data ?? [],
        currenciesSearchValue: state.currency.search,
        setCurrenciesSearchValue: (search) =>
            dispatch({
                type: ActionType.SET_SEARCH_STRING,
                field: Fields.CURRENCY,
                search,
            }),

        cycles: state.cycle.data ?? [],
        cyclesSearchValue: state.cycle.search,
        setCyclesSearchValue: (search) =>
            dispatch({
                type: ActionType.SET_SEARCH_STRING,
                field: Fields.CYCLE,
                search,
            }),
        reasonCodes: state.reason_code.data ?? [],
        reasonCodesSearchValue: state.reason_code.search,
        setReasonCodesSearchValue: (search) =>
            dispatch({
                type: ActionType.SET_SEARCH_STRING,
                field: Fields.REASON_CODE,
                search,
            }),
        getParamByKey,
        isConfirming,
        isSubmitting,
        jobSubmitted,
        jobConfirmation,
        cancel,
        isSubmitDisabled:
            !Object.values(paramMap).filter((param) => typeof param !== 'undefined').length ||
            Boolean(currentScheduledJob) ||
            isSubmitting ||
            isConfirming,
        currentScheduledJob,
        lastScheduledJob
    }
}

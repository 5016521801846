import React, { useEffect } from 'react'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material'
import { useFormik } from 'formik'
import { LoadingIndicator } from 'components'
import { useDebounce } from './VisaCE.vm'

interface MatchingTransactionDetailsProps {
    countries: any
    matchingTransactionDetails: any
    handlelMatchingTransactionDetails: (values: any, isValid: boolean) => void
    setSubmitDisabled: (value: boolean) => void
    sendNewRelicEvent: (name: string, action: string, message?: string, overrideConfig?: boolean) => void
}

const MatchingTransactionDetails = ({
    countries = [],
    matchingTransactionDetails,
    handlelMatchingTransactionDetails,
    setSubmitDisabled,
    sendNewRelicEvent,
}: MatchingTransactionDetailsProps) => {
    const {
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
        isValid,
    } = useFormik({
        // @ts-ignore-next-line
        validate: (values: any) => {
            const errors: any = {}
            if (!values.ip_address_details
                 && !values.device_fingerprinting_details
                 && !values.device_id_details
                 && !values.address
                 && !values.customer_account_login_id_details
            ) errors.ip_address_details = 'Required'
            if (values.ip_address_details) {
                if (
                    !values.device_fingerprinting_details
                    && !values.device_id_details
                    && !values.customer_account_login_id_details
                    && !values.address) {
                    errors.ip_address_details = 'Required'
                }
            }
            if (values.device_id_details) {
                if (
                    !values.ip_address_details
                    && !values.customer_account_login_id_details
                    && !values.address
                ) {
                    errors.device_id_details = 'Required'
                } 
                if (values.device_fingerprinting_details) {
                    errors.device_id_details = 'Required'
                }
            }
            if (values.device_fingerprinting_details) {
                if (
                    !values.ip_address_details
                    && !values.customer_account_login_id_details
                    && !values.address)
                {
                    errors.device_fingerprinting_details = 'Required'
                }
                if (values.device_id_details) {
                    errors.device_fingerprinting_details = 'Required'
                }
            }
            if (values.customer_account_login_id_details) {
                if (
                    !values.ip_address_details
                    && !values.device_id_details
                    && !values.device_fingerprinting_details) {
                    errors.customer_account_login_id_details = 'Required'
                }
                if (values.address) {
                    errors.customer_account_login_id_details = 'Required'
                }
            }
            if (values.address) {
                if (
                    !values.ip_address_details
                    && !values.device_id_details
                    && !values.device_fingerprinting_details) {
                    errors.address = 'Required'
                }
                if (values.customer_account_login_id_details) {
                    errors.address = 'Required'
                }
                if (!values.city
                    || !values.state_region
                    || !values.postal_code
                    || !values.country
                ) {
                    errors.address = 'Required'
                }
            }

            return errors
        },
        validateOnMount: false,
        enableReinitialize: true,
        initialValues: {
            ipActive: matchingTransactionDetails.ipActive ?? false,
            idActive: matchingTransactionDetails.idActive ?? false,
            fingerprintActive: matchingTransactionDetails.fingerprintActive ?? false,
            userIdActive: matchingTransactionDetails.userIdActive ?? false,
            addressActive: matchingTransactionDetails.addressActive ?? false,
            merchandise_or_services_desc: matchingTransactionDetails.merchandise_or_services_desc ?? '',
            device_id_details: matchingTransactionDetails.device_id_details ?? '',
            device_fingerprinting_details: matchingTransactionDetails.device_fingerprinting_details ?? '',
            ip_address_details: matchingTransactionDetails.ip_address_details ?? '',
            customer_account_login_id_details: matchingTransactionDetails.customer_account_login_id_details ?? '',
            address: matchingTransactionDetails.address ?? '',
            address2: matchingTransactionDetails.address2 ?? '',
            city: matchingTransactionDetails.city ?? '',
            state_region: matchingTransactionDetails.state_region ?? '',
            postal_code: matchingTransactionDetails.postal_code ?? '',
            country: matchingTransactionDetails.country ?? '',
            isValid: matchingTransactionDetails.isValid ?? false,
        },
        onSubmit: () => {
            handlelMatchingTransactionDetails(values, isValid)
        },
    })

    const handleValueUpdate = useDebounce(() => {
        if (isValid) {
            handleSubmit()
            return setSubmitDisabled(false)
        }
        setSubmitDisabled(true)
    }, 500)

    useEffect(() => {
        handleValueUpdate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, isValid])
    
    return (
        <form onSubmit={handleSubmit}>
            <div className='emp-visaCEMatchingTransactionDetails'>
                <div className={`emp-visaCEMatchingTransactionDetails-header`}>
                    <div>Matching Transaction Details</div>
                    <div>
                        Identify at least two of the core data elements match between prior 
                        transactions and the disputed transactioni and one of the two must be either 
                        IP address or Device ID.
                    </div>
                </div>
                <div className='emp-visaCEMatchingTransactionDetails-checkbox-container'>
                    <FormControlLabel control={
                        <Checkbox
                            checked={values.ipActive}
                            color='secondary'
                            onChange={(e) => {
                                const val = e.target.checked
                                sendNewRelicEvent('onChange', `step 4: ip_address_details checkbox: [${val ? 'checked' : 'unchecked'}]`)
                                if (!val) setFieldValue('ip_address_details', '')
                                setFieldValue('ipActive', e.target.checked)
                            }}
                        />
                    } label="IP Address" />
                    <FormControlLabel control={
                        <Checkbox
                            checked={values.idActive}
                            color='secondary'
                            onChange={(e) => {
                                const val = e.target.checked
                                sendNewRelicEvent('onChange', `step 4: device_id_details checkbox: [${val ? 'checked' : 'unchecked'}]`)
                                if (!val) setFieldValue('device_id_details', '')
                                setFieldValue('idActive', e.target.checked)
                            }}
                        />
                    } label="Device ID" />
                    <FormControlLabel control={
                        <Checkbox
                            checked={values.fingerprintActive}
                            color='secondary'
                            onChange={(e) => {
                                const val = e.target.checked
                                sendNewRelicEvent('onChange', `step 4: device_fingerprinting_details checkbox: [${val ? 'checked' : 'unchecked'}]`)
                                if (!val) setFieldValue('device_fingerprinting_details', '')
                                setFieldValue('fingerprintActive', e.target.checked)
                            }}
                        />
                    } label="Device Fingerprint" />
                    <FormControlLabel control={
                        <Checkbox
                            checked={values.userIdActive}
                            color='secondary'
                            onChange={(e) => {
                                const val = e.target.checked
                                sendNewRelicEvent('onChange', `step 4: customer_account_login_id_details checkbox: [${val ? 'checked' : 'unchecked'}]`)
                                if (!val) setFieldValue('customer_account_login_id_details', '')
                                setFieldValue('userIdActive', e.target.checked)
                            }}
                        />
                    } label="User ID" />
                    <FormControlLabel control={
                        <Checkbox
                            checked={values.addressActive}
                            color='secondary'
                            onChange={(e) => {
                                const val = e.target.checked
                                sendNewRelicEvent('onChange', `step 4: shipping_address checkbox: [${val ? 'checked' : 'unchecked'}]`)
                                if (!val) {
                                    setFieldValue('address', '')
                                    setFieldValue('address2', '')
                                    setFieldValue('city', '')
                                    setFieldValue('state_region', '')
                                    setFieldValue('postal_code', '')
                                    setFieldValue('country', '')
                                }
                                setFieldValue('addressActive', val)
                            }}
                        />
                    } label="Shipping Address" />
                </div>
                <div className={`emp-visaCEMatchingTransactionDetails-formFieldsContainer`}>
                    {values.ipActive &&
                        <TextField
                            label="IP Address:"
                            name={`ip_address_details`}
                            placeholder="IP Address"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            value={values.ip_address_details}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            onChange={handleChange}
                        />
                    }
                    {values.idActive &&
                        <TextField
                            label="Device ID:"
                            name={`device_id_details`}
                            placeholder="Device ID"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            value={values.device_id_details}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            onChange={handleChange}
                        />
                    }
                    {values.fingerprintActive &&
                        <TextField
                            label="Device Fingerprint:"
                            name={`device_fingerprinting_details`}
                            placeholder="Device Fingerprint"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            value={values.device_fingerprinting_details}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            onChange={handleChange}
                        />
                    }
                    {values.userIdActive &&
                        <TextField
                            label="User ID:"
                            name={`customer_account_login_id_details`}
                            placeholder="User ID"
                            margin="dense"
                            size="small"
                            variant="outlined"
                            value={values.customer_account_login_id_details}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                            onChange={handleChange}
                            required={true}
                        />
                    }
                </div>
                {values.addressActive &&
                
                    <div>
                        <div className={`emp-visaCEMatchingTransactionDetails-header`}>
                            <div>Shipping Address</div>
                            <div></div>
                        </div>
                        <div className={`emp-visaCEMatchingTransactionDetails-formFieldsContainer`}>
                            <TextField
                                label="Address:"
                                name={`address`}
                                placeholder="Address"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                value={values.address}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                onChange={handleChange}
                                required={true}
                            />
                            <TextField
                                label="Address 2:"
                                name={`address2`}
                                placeholder="Address 2"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                value={values.address2}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                onChange={handleChange}
                                required={false}
                            />
                            <TextField
                                label="State/Region:"
                                name={`state_region`}
                                placeholder="State/Region"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                value={values.state_region}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                onChange={handleChange}
                                required={true}
                            />
                        </div>
                        <div className={`emp-visaCEMatchingTransactionDetails-formFieldsContainer`}>
                            <TextField
                                label="City:"
                                name={`city`}
                                placeholder="City"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                value={values.city}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                onChange={handleChange}
                                required={true}
                            />
                            <TextField
                                label="Postal Code:"
                                name={`postal_code`}
                                placeholder="Postal Code"
                                margin="dense"
                                size="small"
                                variant="outlined"
                                value={values.postal_code}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                                onChange={handleChange}
                                required={true}
                            />
                            {!countries.length ?
                                <div className={'selectLoadingContainer'}><LoadingIndicator /></div>
                            :
                                <FormControl className='select-fix' fullWidth  variant="outlined" margin="dense" size="small" style={{maxWidth: '100%'}}>
                                    <InputLabel
                                        shrink
                                    >
                                        Country:
                                    </InputLabel>
                                    <Select
                                        name={`country`}
                                        value={values.country}
                                        label="Country:"
                                        variant="outlined"
                                        fullWidth
                                        onChange={(e) => {
                                            setFieldValue('country', e.target.value)
                                        }}
                                    >
                                        <MenuItem value={0}>
                                            -
                                        </MenuItem>
                                        {countries.map(({value, name}: any, idx: number) => (
                                            <MenuItem key={idx} value={value}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                        </div>
                    </div>
                }
            </div>
        </form>
    )
}

export default MatchingTransactionDetails

import React from 'react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import { AccentArea, Alert, MerchantSwitcher } from 'components'
import idDirectory from './idAttributes'
import { withRequiredRole } from 'components/useRequireRole'
import EntFeature from 'models/EntFeature'
import { Link } from 'react-router-dom'
import { CellProps as RTCellProps } from 'react-table'
import View from 'views/View'
import { UsersDataGrid } from './grids/UsersDataGrid'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { useUsers } from './Users.vm'
import AlertSnackbar from 'components/AlertSnackbar'

export type CellProps = RTCellProps<Record<string, string | number>>

/**
 * View used for user information, listing, and manipulation
 */
const UsersView: React.FC = () => {
    const {
        currentUser,
        isUserJustAdded,
        wasUserEdited,
        selectAllRowsInStore,
        unselectAllRowsInStore,
        users,
        swrActions,
        isFederatedUser,
        params,
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
        showAddUserBtn,
    } = useUsers()
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const {
        isLoading,
        search,
        setSearch,
        rowsPerPage,
        setRowsPerPage,
        setPage,
        total,
        page,
        setSortBy,
        setSortDesc,
        sortInfo,
        invalidate: rehydrateView,
    } = swrActions

    return (
        <View
            title="Users"
            breadcrumb="Users"
            className=""
            HeaderInterior={<MerchantSwitcher testId="users" />}
            testId="users"
        >
            {isUserJustAdded && (
                <Alert severity="success">
                    You successfully {wasUserEdited ? 'updated' : 'created'} the
                    user.
                </Alert>
            )}
            <AccentArea
                testId="users"
                containsFullWidthGrid={true}
                hasHeaderDivider={showAddUserBtn}
                title={
                    showAddUserBtn && !isReadOnly ? (
                        <Link
                            to={{
                                pathname: '/user/update',
                                state: { id: currentUser?.id },
                            }}
                        >
                            <Button
                                id={idDirectory.btnAddUser}
                                variant="contained"
                                color="secondary"
                                disabled={isReadOnly}
                            >
                                <Icon icon={faPlus} />
                                <span className={'emp-addUserBtnText'}>
                                    Add User
                                </span>
                            </Button>
                        </Link>
                    ) : !showAddUserBtn ? (
                        <></>
                    ) : (
                        <Button
                            id={idDirectory.btnAddUser}
                            variant="contained"
                            color="secondary"
                            disabled
                        >
                            <Icon icon={faPlus} />
                            <span className={'emp-addUserBtnText'}>
                                Add User
                            </span>
                        </Button>
                    )
                }
            >
                <UsersDataGrid
                    printId="users-grid-print-id"
                    user={currentUser}
                    users={users}
                    loading={isLoading}
                    total={total}
                    pageSize={rowsPerPage}
                    onSortDirectionChage={setSortDesc}
                    onSortChange={setSortBy}
                    onPageSizeChange={setRowsPerPage}
                    onPageChange={setPage}
                    rowsPerPage={rowsPerPage}
                    globalSearch={search}
                    onSearchChange={setSearch}
                    sortInfo={sortInfo}
                    page={page}
                    selectAllRowsInStore={selectAllRowsInStore}
                    unselectAllRowsInStore={unselectAllRowsInStore}
                    params={params}
                    isFederatedUser={isFederatedUser}
                    testId="users"
                    setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                    setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                    rehydrateView={rehydrateView}
                />
            </AccentArea>
            <AlertSnackbar
                content={alertSnackbarMainProps}
                open={alertSnackbarMainOpen}
                onClose={() => setAlertSnackbarMainOpen(false)}
                showCloseIcon
            />
        </View>
    )
}

export default withRequiredRole(EntFeature.USERS, UsersView)

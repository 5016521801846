import { Group } from '@visx/group'
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend'
import { withParentSize } from '@visx/responsive'
import { scaleOrdinal } from '@visx/scale'
import Pie, { PieArcDatum, ProvidedProps } from '@visx/shape/lib/shapes/Pie'
import { Text } from '@visx/text'
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { animated, interpolate, useTransition } from 'react-spring'
import ResizeObserver from 'resize-observer-polyfill'
import { clientTheme } from 'theme-exports'

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 }

export interface DataSchema {
    id: number
    label: string
    value: number
}

export type PieProps = {
    parentHeight?: number
    parentWidth?: number
    width?: number
    height?: number
    donutThickness?: number
    margin?: typeof defaultMargin
    animate?: boolean
    text?: string
    data?: DataSchema[]
    responsive?: boolean
}

const legendGlyphSize = 15

type TooltipData = string

const tooltipStyles = {
    ...defaultStyles,
    padding: '0.25rem',
}
const PieChart = memo(
    ({
        width: _width,
        height: _height,
        parentHeight = 0,
        parentWidth = 0,
        margin = defaultMargin,
        data: input = [],
        animate = true,
        donutThickness = 5,
    }: PieProps) => {
        const [hovered, setHovered] = useState<string | null>(null)
        const [hightlight, setHightlight] = useState<string | null>(null)
        const [text, setText] = useState('')

        const width = _width ?? parentWidth
        const height = _height ?? parentHeight

        const innerWidth = width - margin.left - margin.right
        const innerHeight = height - margin.top - margin.bottom
        const radius = Math.min(innerWidth, innerHeight) / 2
        const centerY = innerHeight / 2
        const centerX = innerWidth / 2

        /** Tooltip Setup */
        const {
            containerBounds,
            TooltipInPortal: TooltipComponent,
        } = useTooltipInPortal({
            detectBounds: true,
            polyfill: ResizeObserver,
        })
        const {
            showTooltip,
            hideTooltip,
            tooltipOpen,
            tooltipData,
            tooltipLeft = 0,
            tooltipTop = 0,
        } = useTooltip<TooltipData>({
            // initial tooltip state
            tooltipOpen: false,
            tooltipLeft: width / 3,
            tooltipTop: height / 3,
            tooltipData: 'Move me with your mouse or finger',
        })

        const handlePointerMove = useCallback(
            (event: React.PointerEvent<HTMLDivElement>) => {
                if (hovered) {
                    // coordinates should be relative to the container in which Tooltip is rendered
                    const containerX =
                        ('clientX' in event ? event.clientX : 0) -
                        containerBounds.left
                    const containerY =
                        ('clientY' in event ? event.clientY : 0) -
                        containerBounds.top

                    const [{ value }] = input.filter((i) => i.label === hovered)

                    showTooltip({
                        tooltipLeft: containerX,
                        tooltipTop: containerY,
                        tooltipData: `${hovered}: ${value}`,
                    })
                }
            },
            [showTooltip, containerBounds, hovered, input]
        )

        const data = useMemo(() => {
            return input
        }, [input])

        useEffect(() => {
            if(data[0]) {
                setHightlight(data[0]?.label ?? null)
            }
        }, [data])

        useEffect(() => {
            const [total, part] = data.reduce(
                (acc, cur) => [
                    (acc[0] += cur.value),
                    cur.label === hightlight ? cur.value : acc[1],
                ],
                [0, 0]
            )
            if (total === 0) {
                setText('No Data')
            } else {
                setText(`${Math.round((part / total) * 100)}%`)
            }
        }, [hightlight, data])

        const legendScale = useMemo(() => {
            const colors = clientTheme.charts.pieCharts.dashboardPieChart
            return scaleOrdinal(
                input.reduce(
                    (acc, cur, idx) => {
                        return {
                            domain: [...acc.domain, cur.label],
                            range: [...acc.range, colors[idx % colors.length]],
                        }
                    },
                    { domain: [], range: [] } as {
                        domain: string[]
                        range: string[]
                    }
                )
            )
        }, [input])

        const handleHover = (arc: PieArcDatum<DataSchema> | null) => {
            if (!arc) {
                hideTooltip()
                setHovered(null)
                setHightlight(data[0].label)
            } else {
                setHightlight(arc.data.label)
                setHovered(arc.data.label)
            }
        }

        if (width < 10) return null

        return (
            <div onPointerMove={handlePointerMove}>
                {tooltipOpen && (
                    <TooltipComponent
                        key={Math.random()} // needed for bounds to update correctly
                        left={tooltipLeft}
                        top={tooltipTop}
                        style={tooltipStyles}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                style={{
                                    height: '1rem',
                                    width: '1rem',
                                    marginRight: '0.5rem',
                                    backgroundColor: legendScale(hovered ?? ''),
                                }}
                            />
                            {tooltipData}
                        </div>
                    </TooltipComponent>
                )}
                <svg
                    width={width + 5}
                    height={height + 5}
                    className={'emp-piePlot-root'}
                >
                    <Group
                        top={centerY + margin.top}
                        left={centerX + margin.left}
                    >
                        <Pie
                            data={data}
                            pieValue={(d) => d.value}
                            outerRadius={(arc) =>
                                arc.data.label === hightlight
                                    ? radius + 20
                                    : radius + 18
                            }
                            innerRadius={radius + 2}
                            cornerRadius={1}
                            padAngle={0.005}
                        >
                            {(pie) => (
                                <AnimatedPie<DataSchema>
                                    {...pie}
                                    animate={animate}
                                    getKey={(arc) => arc.data.id.toString()}
                                    onHoverDatum={handleHover}
                                    onClickDatum={() => {}}
                                    getColor={(arc) =>
                                        legendScale(arc.data.label)
                                    }
                                />
                            )}
                        </Pie>
                        <Group style={{ textAlign: 'center' }}>
                            <Text
                                x={0}
                                y={0 - donutThickness / 2 - 22}
                                width={width}
                                verticalAnchor="start"
                                textAnchor="middle"
                                fill="#666666"
                                className={'emp-piePlot-pieHeader'}
                            >
                                {text}
                            </Text>
                            <Text
                                x={0}
                                y={0 - donutThickness / 2 + 20}
                                verticalAnchor="start"
                                textAnchor="middle"
                                fill="#333333"
                                className={'emp-piePlot-subPieHeader'}
                            >
                                {hightlight ?? ''}
                            </Text>
                        </Group>
                    </Group>
                </svg>
                <LegendOrdinal
                    scale={legendScale}
                    labelFormat={(label) => `${label}`}
                >
                    {(labels) => (
                        <Box
                            className={'emp-piePlot-legend'}
                            style={{ maxWidth: width }}
                            sx={(theme) => ({
                                fontSize: clientTheme.typography.label.fontSize,
                                [theme.breakpoints.down('xl')]: {
                                    fontSize: '13px',
                                },
                                [theme.breakpoints.down('lg')]: {
                                    fontSize: '11px',
                                },
                                [theme.breakpoints.down('md')]: {
                                    fontSize:
                                        clientTheme.typography.label.fontSize,
                                },
                            })}
                        >
                            {labels
                                .filter((label) => label.datum !== null)
                                .map((label, i) => (
                                    <LegendItem
                                        key={`legend-quantile-${i}`}
                                        margin="0 5px"
                                    >
                                        <svg
                                            width={legendGlyphSize}
                                            height={legendGlyphSize}
                                        >
                                            <LegendLabel
                                                align="left"
                                                margin="0 4px"
                                            >
                                                {label.text}
                                            </LegendLabel>
                                            <circle
                                                fill={
                                                    // if hovered if true check if this loop is rendering the hovered label,
                                                    // if so fill with the label color, otherwise fill with low opacity black.
                                                    hovered
                                                        ? hovered.toLowerCase() ===
                                                          label.text.toLowerCase()
                                                            ? label.value
                                                            : 'rgba(0,0,0,0.2)'
                                                        : label.value
                                                }
                                                opacity={1}
                                                r={legendGlyphSize / 3}
                                                cx={legendGlyphSize / 2}
                                                cy={legendGlyphSize / 2}
                                            />
                                        </svg>
                                        <LegendLabel
                                            align="left"
                                            margin="0 0 0 4px"
                                            style={{
                                                marginLeft: 2,
                                                opacity: 1,
                                                fontFamily: 'Source Sans Pro,sans-serif',
                                            }}
                                        >
                                            {label.text}
                                        </LegendLabel>
                                    </LegendItem>
                                ))}
                        </Box>
                    )}
                </LegendOrdinal>
            </div>
        )
    }
)

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number }

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
    // enter from 360° if end angle is > 180°
    startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
    endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
    opacity: 0,
})
const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
    startAngle,
    endAngle,
    opacity: 1,
})

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
    animate?: boolean
    getKey: (d: PieArcDatum<Datum>) => string
    getColor: (d: PieArcDatum<Datum>) => string
    onClickDatum: (d: PieArcDatum<Datum>) => void
    onHoverDatum: (d: PieArcDatum<Datum> | null) => void
    delay?: number
}

function AnimatedPie<Datum>({
    animate,
    arcs,
    path,
    getKey,
    getColor,
    onClickDatum,
    onHoverDatum,
}: AnimatedPieProps<Datum>) {
    const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(
        arcs,
        getKey,
        // @ts-ignore react-spring doesn't like this overload
        {
            from: animate ? fromLeaveTransition : enterUpdateTransition,
            enter: enterUpdateTransition,
            update: enterUpdateTransition,
            leave: animate ? fromLeaveTransition : enterUpdateTransition,
        }
    )
    return (
        <>
            {transitions.map(
                ({
                    item: arc,
                    props,
                    key,
                }: {
                    item: PieArcDatum<Datum>
                    props: AnimatedStyles
                    key: string
                }) => {
                    return (
                        <g key={key}>
                            <animated.path
                                // compute interpolated path d attribute from intermediate angle values
                                d={interpolate(
                                    [props.startAngle, props.endAngle],
                                    (startAngle, endAngle) =>
                                        path({
                                            ...arc,
                                            startAngle,
                                            endAngle,
                                        })
                                )}
                                fill={getColor(arc)}
                                onClick={() => onClickDatum(arc)}
                                onTouchStart={() => onClickDatum(arc)}
                                onMouseOver={() => onHoverDatum(arc)}
                                onMouseOut={() => onHoverDatum(null)}
                            />
                        </g>
                    )
                }
            )}
        </>
    )
}
// @ts-ignore
export default withParentSize<PieProps>(PieChart)

import { ServerMerchant } from '../../models/Server/ServerMerchant'

export function merchantToMerchantHierarchy(
    serverMerchant: ServerMerchant,
    data: ServerMerchant[] = [],
    depth = 0
): any {
    // gather ids of all merchents to check if a child is present
    const merchantIds = data.map((i) => i.id)
    if (
        depth === 0 &&
        // check the parent available
        merchantIds.includes(serverMerchant.parent_id as number)
    ) {
        return null
    }
    const children = data.filter((i) => i.parent_id === serverMerchant.id)
    return {
        id: serverMerchant.id,
        alias: serverMerchant.alias,
        label: serverMerchant.business_name,
        children: children.map((i) =>
            merchantToMerchantHierarchy(i, children, depth + 1)
        ),
        parentId: serverMerchant.parent_id,
        mids: [], //serverMids.map(mid => ({mid: mid.mid, alias: mid.alias})),
        status: serverMerchant.status,
    }
}

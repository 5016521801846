/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-key */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    Table,
    TableBody,
    TableFooter,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
} from '@mui/material'
import { ResizableBox } from 'react-resizable'
import 'react-resizable/css/styles.css'
// import { LoadingIndicator } from 'components'
import SortComponent from 'components/DataGrid/SortComponent'
import idDirectory from './idAttributes'
import { clientTheme } from 'theme-exports'

import {
    ExtendedHeadersMTD,
    ExtendedColumnsMTD,
} from '../../views/MTDReports/grids/MTDReportsGrid'

interface DataGridPropsMTD {
    columns?: ExtendedColumnsMTD[]
    data?: any[]
    headers?: ExtendedHeadersMTD[]
    currency?: boolean
    loading?: boolean
    className?: string
    testId?: string
}

const RowCheckbox: any = ({ ...rest }: any) => (
    <input className="row-select-checkbox" {...rest} />
)

const EnchancedTableHead = ({
    columns = [],
    currency = false,
    headers = [],
    className,
    testId,
}: DataGridPropsMTD): JSX.Element => {

    const filtercolumns = currency
        ? columns
        : columns.filter((col) => col.type !== 'currency')

    return (
        <TableHead
            className={`${'emp-dataGridSlimMTD-tableHeader '} ${className}`}
            id={`${idDirectory.dataGridSlimMTD.tableHead}-${testId}`}
        >
            <TableRow>
                {headers.map((header) => {
                    return currency || header.header !== 'Currency' ? (
                        <Tooltip
                            arrow
                            placement="top"
                            title={header.header}
                            classes={{
                                tooltip:
                                    'emp-dataGridSlimMTD-tooltipPopper',
                                arrow:
                                    'emp-dataGridSlimMTD-tooltipPopperArrow',
                            }}
                            key={`key-${header.header}`}
                        >
                            <TableCell
                                className={
                                    'emp-dataGridSlimMTD-headerFooterTitle'
                                }
                                colSpan={header.colSpan}
                                style={{
                                    backgroundColor: header.color,
                                    paddingLeft: '3px',
                                    top: 0,
                                }}
                                id={`${idDirectory.dataGridSlimMTD.tableHeadCell}-${testId}-${header.header}`}
                            >
                                {header.icon ? (
                                    <img
                                        src={header.icon}
                                        className={
                                            'emp-dataGridSlimMTD-icon'
                                        }
                                        alt={header.header}
                                    />
                                ) : null}
                                {header.header.toUpperCase()}
                            </TableCell>
                        </Tooltip>
                    ) : null
                })}
            </TableRow>
            <TableRow>
                <TableCell style={{ margin: '0px', top: 27 }}>
                    <RowCheckbox
                        type="checkbox"
                        style={{ transform: 'scale(1.4)' }}
                    />
                </TableCell>
                {filtercolumns.map((column) => (
                    <Tooltip
                        arrow
                        placement="top"
                        title={column.label}
                        classes={{
                            tooltip: 'emp-dataGridSlimMTD-tooltipPopper',
                            arrow:
                                'emp-dataGridSlimMTD-tooltipPopperArrow',
                        }}
                        key={`key-${column.accessor}`}
                    >
                        <TableCell
                            className={'emp-dataGridSlimMTD-columns'}
                            key={`key-${column.accessor}`}
                            style={{ top: 27 }}
                        >
                            {column.sortable ? (
                                <SortComponent
                                    sortHandler={() => null}
                                    accessor={column.accessor}
                                    value={column.label}
                                    sortInfo={{
                                        sortBy: '',
                                        sortDesc: 'desc',
                                    }}
                                />
                            ) : (
                                column.label
                            )}
                        </TableCell>
                    </Tooltip>
                ))}
            </TableRow>
        </TableHead>
    )
}

const EnhancedTableTFooter = ({
    currency = false,
    headers = [],
    testId,
}: DataGridPropsMTD): JSX.Element => {

    return (
        <TableFooter style={{ position: 'sticky', bottom: 0 }}>
            <TableRow>
                <TableCell
                    colSpan={6}
                    align="right"
                    className={'emp-dataGridSlimMTD-headerFooterTitle'}
                    style={{
                        backgroundColor: '#f1f1f1',
                        padding: '3px 3px 10px 3px',
                    }}
                >
                    GRAND TOTAL
                </TableCell>
                {currency && (
                    <TableCell
                        className={'emp-dataGridSlimMTD-headerFooterTitle'}
                        colSpan={2}
                        style={{ backgroundColor: '#e2fae2' }}
                    ></TableCell>
                )}
                {headers.map((header, idx) =>
                    header.total
                        ? header.total.map((item) => (
                              <TableCell
                                  className={
                                      'emp-dataGridSlimMTD-headerFooterTitle'
                                  }
                                  style={{
                                      backgroundColor: header.color,
                                      padding: '3px 0px 10px 3px',
                                  }}
                                  key={`key-${item.title}-${idx}`}
                              >
                                  {item.value}
                              </TableCell>
                          ))
                        : null
                )}
            </TableRow>
        </TableFooter>
    )
}

export const DataGridSlimMTD: React.FC<DataGridPropsMTD> = ({
    columns = [],
    data = [],
    currency = false,
    headers = [],
    loading = true,
    testId,
}) => {

    //calculated based on CSS (Will need to update if CSS styling changes)
    const heightCalc = 126 + 22 * data.length
    const resizeMinHeight = heightCalc < 300 ? heightCalc : 300

    return (
        <>
            <ResizableBox
                width={Infinity}
                height={resizeMinHeight}
                minConstraints={[Infinity, resizeMinHeight]}
                maxConstraints={[Infinity, heightCalc]}
            >
                <div
                    className={'emp-dataGridSlimMTD-dataGrid '}
                    id={`${idDirectory.dataGridSlimMTD.divRoot}-${testId}`}
                >
                    <TableContainer
                        component={Paper}
                        className={'emp-dataGridSlimMTD-tableContainer'}
                        id={`${idDirectory.dataGridSlimMTD.tableContainer}-${testId}`}
                    >
                        <Table size={'small'} stickyHeader={true}>
                            <EnchancedTableHead
                                columns={columns}
                                currency={currency}
                                headers={headers}
                                testId={testId}
                            />

                            <TableBody>{/* DATA */}</TableBody>
                            <EnhancedTableTFooter
                                currency={currency}
                                headers={headers}
                                testId={testId}
                            />
                        </Table>
                    </TableContainer>
                </div>
            </ResizableBox>

            {/* {loading && (
                <div style={{ height: '20px ' }}>
                    <div className={'emp-dataGridSlimMTD-loadingContainer}>
                        <LoadingIndicator />
                    </div>
                </div>
            )} */}

            <div
                className={'emp-dataGridSlimMTD-showingResults'}
                id={`${idDirectory.dataGridSlimMTD.divEntries}-${testId}`}
                style={{ fontSize: clientTheme.typography.subtitle3.fontSize }}
            >
                <p>
                    <span>Showing </span>
                    <span>{0}</span>
                    <span> to </span>
                    <span>{0}</span>
                    <span> of </span>
                    <span style={{ marginLeft: 4, marginRight: 4 }}>{0}</span>
                    <span>entries</span>
                </p>
            </div>
        </>
    )
}

export default DataGridSlimMTD

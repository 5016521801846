import { useEffect, useReducer, useState } from 'react'
import { Params as ParamsType } from './types'

export const useParamsAGGrid = (defaults?: {
    sort_by?: string
    sort_order?: 'asc' | 'desc'
    descriptor?: string
    date_range?: string
}) => {
    // reducer for api parameters
    const [queryParams, setQueryParams] = useState<any[]>([])
    const [params, setParams] = useReducer(
        (state: ParamsType, action: Partial<ParamsType>) => ({
            ...state,
            ...action,
        }),
        {
            sort_by: defaults?.sort_by ? defaults?.sort_by : undefined,
            sort_order: defaults?.sort_order ? defaults?.sort_order : 'asc',
            date_range: defaults?.date_range ? defaults?.date_range : undefined,
            descriptor: defaults?.descriptor ? defaults?.descriptor : undefined,
        }
    )

    useEffect(() => {
        let queryParamArray: any[] = []
        queryParams.forEach(([key, value]) => {
            if (value) {
                queryParamArray.push([key, String(value)])
            } else {
                queryParamArray.push([key, undefined])
            }
        })
        setParams({ ...Object.fromEntries(queryParamArray) })
    }, [queryParams])

    // reset api parameters to defaults
    const reset = (limit: number, start: number) => {
        setParams({
            sort_by: '',
            sort_order: 'asc',
            date_range: '',
            descriptor: '',
        })
    }

    return {
        params,
        setParams,
        setQueryParams,
        reset,
    }
}

export default useParamsAGGrid

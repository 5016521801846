import BaseService, { PageOf } from 'lib/BaseService'

type GenericSearchParams = Record<string, string>

export type Currency = {
    code: number
    currency: string
    decimal_digits: null | number
    id: number
    iso: string
    symbol: string
}

type ListCurrencies = (
    params?: GenericSearchParams
) => Promise<PageOf<Currency[]>>

export interface Cycle {
    id: number
    name: string
}

type ListCycles = (params?: GenericSearchParams) => Promise<PageOf<Cycle[]>>

export interface ReasonCodeCatagory {
    id: number
    name: string
}

export interface ReasonCode {
    alt_code: number | null
    card_type: ReasonCodeCatagory
    category: ReasonCodeCatagory
    code: number
    description: string
    id: number
    is_fraud: boolean
}

type ListReasonCodes = (
    params?: GenericSearchParams
) => Promise<PageOf<ReasonCode[]>>

export type CardType = {
    id: number
    name: string
}

type ListCardTypes = (
    params?: GenericSearchParams
) => Promise<PageOf<CardType[]>>

export interface Source {
    id: number
    code: string
}

type ListSources = (params?: GenericSearchParams) => Promise<PageOf<Source[]>>

export interface MIDDescriptor {
    id: number
    descriptor: string
}

type ListMIDDescriptors = (
    merchantId: string,
    params?: GenericSearchParams
) => Promise<PageOf<MIDDescriptor[]>>

export class GenericService extends BaseService {
    listCurrencies: ListCurrencies = (params) =>
        this.axios
            .get(this.injectParams('/cm/gen/currencies', params))
            .then((r) => r.data)

    listCycles: ListCycles = (params) =>
        this.axios
            .get(this.injectParams('/cm/gen/cycles', params))
            .then((r) => r.data)

    listReasonCodes: ListReasonCodes = (params) =>
        this.axios
            .get(this.injectParams('/cm/gen/rc', params))
            .then((r) => r.data)

    listSources: ListSources = (params) =>
        this.axios
            .get(this.injectParams('cm/gen/rc', params))
            .then((r) => r.data)

    listCardTypes: ListCardTypes = (params) =>
        this.axios
            .get(this.injectParams('/cm/gen/cctypes', params))
            .then((r) => r.data)

    listMidDescriptors: ListMIDDescriptors = (merchantId, params) =>
        this.axios
            .get(
                this.injectParams(
                    `/cm/merchants/${merchantId}/descriptors`,
                    params
                )
            )
            .then((r) => r.data)
}

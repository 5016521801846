import React from 'react'
import { Button, Dialog } from '@mui/material'
import {
    faTimes
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'
import axios from 'axios'
import { clientTheme } from 'theme-exports'
import { CloseAndInvalidate } from '../grids/AlertsGrid'

interface CompleteAlertProps {
    alertId: number | undefined
    open: boolean
    onClose: CloseAndInvalidate
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

/**
 * @name CompleteAlert
 * @description - Complete an alert
 * @paran alertId - number | undefined: ID of the alert
 * @param open - bool: Determines whether modal is to be open
 * @param onClose - func: toggle open state
 */
const CompleteAlert: React.FC<CompleteAlertProps> = ({
    alertId,
    open,
    onClose,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}) => {

    const completeHandler = () => {
        return axios
            .post(`/cm/svc/alerts/complete`, {
                alert_id: alertId,
            })
            .then(() => {
                setAlertSnackbarMainProps({
                    title: 'Success',
                    message: `Alert record ${alertId} has been completed.`,
                    intent: 'success',
                })
            })
            .catch(() => {
                setAlertSnackbarMainProps({
                    title: 'Error',
                    message: `There was a problem completing alert record ${alertId}.`,
                    intent: 'error',
                })
            })
            .finally(() => {
                setAlertSnackbarMainOpen(true)
                onClose('complete', true)
            })
    }

    return (
        <Dialog id={'preChargebacksAGAlertsCompleteAlert'} open={open}>
            <div className={'emp-headerBanner'}>
                <span>Confirm Completion</span>
                <span>
                    <Button
                        onClick={() => {
                            onClose('complete')
                        }}
                        color="primary"
                        className={'emp-closeBtn'}
                    >
                        <Icon icon={faTimes} />
                    </Button>
                </span>
            </div>
            <div className={'emp-modal'}>
                <div className={'emp-inputContainer'}>
                    Are you sure this alert is correct and should be finalized?
                </div>
                <div className={'emp-btnGrp'}>
                    <Button
                        variant="contained"
                        className={'emp-btn'}
                        onClick={() => {
                            onClose('complete')
                        }}
                        sx={clientTheme.buttons.defaultButton.style}
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        className={'emp-completeBtn'}
                        onClick={completeHandler}
                    >
                        Complete
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default CompleteAlert
import React from 'react'
import { Tooltip } from '@mui/material'
import { clientTheme } from 'theme-exports'

export type LampColor = 'green' | 'red' | 'orange' | 'yellow' | 'grey'

export type TLampSize = 'sm' | 'md' | 'lg'

export interface ILampProps {
    color: LampColor
    size: TLampSize
    label: string
    className: string
}

/**
 * Specify which colors map to which base data
 */
type LampColorOptions = { value: string | number; color: LampColor }

/**
 *
 * @param value - current data point value.
 * @param options - mappings of value to color.
 *
 * @returns color of Lamp based on provided mapping.
 */
export const withColor = (
    value: number | string,
    options: LampColorOptions[]
): LampColor => {
    let color: LampColor = 'grey'
    options.forEach((i) => {
        if (value === i.value) {
            color = i.color
        }
    })
    return color
}

/**
 * Color-based indication icon (e.g. stoplight)
 */
const Lamp = ({
    className,
    label = '',
    color = 'grey',
    size = 'lg',
}: Partial<ILampProps>): JSX.Element => {
    const lampColor: Record<LampColor, string> = {
        green: clientTheme.lampColors.green,
        red: clientTheme.lampColors.red,
        orange: clientTheme.lampColors.orange,
        yellow: clientTheme.lampColors.yellow,
        grey: clientTheme.lampColors.grey,
    }

    const lampSize = {
        sm: 'emp-lamp-small',
        md: 'emp-lamp-medium',
        lg: 'emp-lamp-large',
    }

    return (
        <Tooltip title={label} placement="top" arrow>
            <span
                className={`${lampSize[size]} ${className}`}
                style={{ backgroundColor: lampColor[color] }}
            />
        </Tooltip>
    )
}

export default Lamp

import axios, { AxiosTransformer } from 'axios'
import { ServerMerchant, ServerMid } from '../models/Server/ServerMerchant'
import { merchantToMerchantHierarchy } from './transformations/MerchantTransformations'
import { Merchant, MerchantMID } from '../models/Merchant'
import { Deflector } from '../views/MerchantHierarchy/MerchantHierarchy.vm'


export type MIDInfo = {
    alias: string | undefined
    caid: any
    contact: {
        email: string | undefined
        fname: string | undefined
        lname: string | undefined
        phone_number: string | undefined
        username: string | undefined
    }
    date_created: string | undefined
    descriptor: {
        descriptor: string | undefined
        id: number | string | undefined
    }
    id: number | string | undefined
    mcc: any
    merchant_id: number | string | undefined
    mid: string | undefined
    mid_type: {
        id: number | string | undefined
        name: string | undefined
    }
    processor: {
        alias: string | undefined
        id: number | string | undefined
        name: string | undefined
    }
    service_level: {
        id: number | string | undefined
        name: string | undefined
    }
    status: {
        id: number | string | undefined
        name: string | undefined
    }
    wpg_merchant_code: string | undefined
    deflectors: Deflector[]
    inherit_merchant_deflectors: boolean
}

export type MIDHistory = {
    username: string | undefined
    message: string | undefined
    date_created: string | undefined
}

type GetMerchantHierarchy = (merchantId: number) => Promise<Merchant>
type SearchMerchantHierarchy = (merchantId: number, search: string) => void ///Promise<MerchantHierarchy>
type GetMidsForMerchant = (merchantId: number) => Promise<MerchantMID>
type UpdateMerchantParent = (
    merchantId: number,
    parentId: number | null
) => Promise<any>
type GetDocumentTemplatesList = (merchantId: number) => Promise<any>
type GetMidHistory = (midId: number) => Promise<any>
type GetMerchant = (merchantId: number) => Promise<any>

interface IMerchantApi {
    getMerchantHierarchy: GetMerchantHierarchy
    searchMerchantHierarchy: SearchMerchantHierarchy
    getMidsForMerchant: GetMidsForMerchant
    updateMerchantParent: UpdateMerchantParent
    getDocumentTemplatesList: GetDocumentTemplatesList
    getMidHistory: GetMidHistory
    getMerchant: GetMerchant
}

/**
 * use to get merchant info, health and metadata
 */
class MerchantApi implements IMerchantApi {
    getMerchantHierarchy(merchantId: number) {
        const transformations =
            (axios.defaults.transformResponse as AxiosTransformer[]) || []
        transformations.push((data: ServerMerchant) => {
            return merchantToMerchantHierarchy(data)
        })
        return axios
            .get(`/cm/merchants/${merchantId}`)
            .then((resp) => resp.data)
    }

    searchMerchantHierarchy(merchantId: number, search: string) {
        return axios
            .get('/cm/merchants', {
                params: {
                    search,
                    merchant_id: merchantId,
                },
                transformResponse: [
                    ...(axios.defaults.transformResponse as AxiosTransformer[]),
                    ({ data }: { data: ServerMerchant[] }) =>
                        data
                            .map((i) => merchantToMerchantHierarchy(i, data))
                            // filter out non-root node which have a parentId of null
                            .filter((i) => Boolean(i)),
                ],
            })
            .then((resp) => {
                return resp.data
            })
    }

    getMidsForMerchant(merchantId: number) {
        return axios
            .get('/cm/mids', {
                params: {
                    merchant_id: merchantId,
                },
                transformResponse: [
                    ...(axios.defaults.transformResponse as AxiosTransformer[]),
                    ({ data }: { data: ServerMid[] }) =>
                        data.map((m) => ({
                            midId: m.id,
                            mid: m.mid,
                            alias: m.alias,
                            merchantId: m.merchant_id,
                        })),
                ],
            })
            .then((resp) => resp.data)
    }

    getMerchant(merchantId: number) {
        return axios.get(`/cm/merchants/${merchantId}`).then(({ data }) => data)
    }

    getMid(midId: number) {
        return axios
            .get(`/cm/mids/${midId}`)
            .then((resp) => resp.data as MIDInfo)
    }

    getMidHistory(midId: number) {
        return axios.get(`/cm/mids/${midId}/history`, {
            params: {
                start: 0,
                limit: 999,
            },
        })
        .then((resp) => resp.data)
    }

    updateMerchant(merchant: Merchant, parentId: number | null) {
        return axios.put(`/cm/merchants/${merchant.id}`, {
            ...merchant,
            parents: [parentId],
        })
    }

    updateMerchantParent(merchantId: number, parentId: number | null) {
        return axios.patch(`/cm/merchants/${merchantId}`, {
            parent_id: parentId,
        })
    }

    getEditMerchantInfo(merchantId: number) {
        return axios.get(`/cm/merchants/${merchantId}`).then(({ data }) => {
            // We don't need these for merchant edit.
            delete data.mids
            delete data.children

            return data
        })
    }

    editMerchantInfo(merchantId: number, merchantInfo: any) {
        return axios.patch(`/cm/merchants/${merchantId}`, {
            ...merchantInfo,
        })
    }

    addMerchant(newMerchantInfo: any) {
        return axios.post(`/cm/merchants`, newMerchantInfo)
    }

    getDocumentTemplatesList(merchantId: number) {
        return axios.get(`/docs/templates`, {
            params: {
                merchant_id: merchantId,
                start: 0,
                limit: 999,
            },
        })
    }

    async addMid(body: any) {
        try {
            const postMid = await axios.post('/cm/mids', body)
            return {
                status: postMid.request.status,
                message: postMid.request.response,
            }
        } catch (err) {
            // returns { status, message }
            return err.response.data
        }
    }

    addBulkMids(body: any) {
        const formData = new FormData()

        formData.append('merchant_id', body.merchant_id)
        formData.append('mids', body.mids)

        return axios
            .post('/cm/mids/upload', formData)
            .then((res) => res)
    }

    updateMid(midId: any, body: Partial<any>) {
        return axios.patch(`/cm/mids/${midId}`, body).then((res) => res)
    }
}

export default new MerchantApi()
import { AmplifySignOut } from '@aws-amplify/ui-react'
import { useMediaQuery, useTheme, Box } from '@mui/material'
import { Auth } from 'aws-amplify'
import idDirectory from './idAttributes'
import { DisputeDeflectorBanner, TopBar, StandardFooter, Sidebar, ErrorBoundary } from 'components'
import NavLinks from 'NavLinks'
import NavLinksNewGrids from 'NavLinksNewGrids'
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import logger from 'utils/logger'
import { SidebarNav } from 'components/SidebarNav'
import { clearLocalStorage, useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import CB from 'lib'

export interface ILayoutMProps {
    children: React.ReactNode
    className?: string
    containerProps?: unknown
    SignoutComponent?: React.FC<React.ComponentProps<typeof AmplifySignOut>>
}

/** TODO: Reafact checklist
 *
 * - [x] Create Set component for wrapping (layouts, contexts, etc...)
 * - [x] Crate MainLayout component
 * - [x] Update children that are dependent on props so they get their own data (sidebar, etc)
 * - [] Update Set to help with role based route access. This should probably be done in the views with a useRequireRole(<Role>) hook
 * */

const MainLayout: React.FC = ({ children }) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    })
    const { pathname } = useLocation()
    const [pageTitle, setPageTitle] = useState<string>('Dashboard')
    // Always open by default on desktop
    const [openSidebar, setOpenSidebar] = useState(true)
    const handleSidebarOpen = () => {
        setOpenSidebar(true)
    }

    const handleSidebarClose = () => {
        setOpenSidebar(false)
    }
    const {
        selectedPatchIDs,
        setSelectedPatchIDs,
        selectedPatchType,
        setSelectedPatchType,
        hasClearedCaseStatus,
        setHasClearedCaseStatus,
        SSOTokenURL,
        showNewDataGrid,
        user,
    } = useAuthedUser()

    const currentNavLinks = showNewDataGrid ? NavLinksNewGrids : NavLinks

    // Needed to set initial page title if page is reloaded.
    useEffect(() => {
        currentNavLinks.forEach((section) =>
            section.sublinks
                ? section.sublinks.forEach(
                      (subSection) =>
                          subSection.href === pathname &&
                          setPageTitle(subSection.title)
                  )
                : section.href === pathname && setPageTitle(section.title)
        )
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const isSidebarCurrentlyOpen = isDesktop ? true : openSidebar
    const handleSignout = async () => {
        try {
            const localStorageIsSAMLBackDoor = localStorage.getItem(
                'isSAMLBackDoor'
            )
            if (selectedPatchIDs && !hasClearedCaseStatus) {
                setHasClearedCaseStatus && setHasClearedCaseStatus(true)
                const actionId = selectedPatchType === 'upload' ? 1 : 2
                CB.cases.callCasePatch(selectedPatchIDs, actionId, selectedPatchType)
                    .then(() => {
                        setSelectedPatchIDs && setSelectedPatchIDs('')
                        setSelectedPatchType && setSelectedPatchType('')
                    })
                    .catch((e) => {})
                    .finally(async () => {
                        await Auth.signOut().then(() => {
                            clearLocalStorage()
                            if (SSOTokenURL?.length) {
                                const tempURL = SSOTokenURL
                                localStorage.removeItem('SSOTokenURL')
                                return document.location.href = tempURL
                            }
                            document.location.href = `/${localStorageIsSAMLBackDoor ? '?saml_back_door_logout=1' : ''}`
                        })
                    })
            } else {
                await Auth.signOut().then(() => {
                    clearLocalStorage()
                    if (SSOTokenURL?.length) {
                        const tempURL = SSOTokenURL
                        localStorage.removeItem('SSOTokenURL')
                        return document.location.href = tempURL
                    }
                    document.location.href = `/${localStorageIsSAMLBackDoor ? '?saml_back_door_logout=1' : ''}`
                })
            }
        } catch (error) {
            logger('Error signing out: ', error)
        }
    }

    useEffect(() => {
        if (!isDesktop) {
            handleSidebarClose()
        }
    }, [isDesktop])

    return (
        <div>
            <div
                id={idDirectory.divRoot}
                className={`layout-m ${'emp-mainLayout-root'}`}
            >
                <header id={idDirectory.header} className="m-header">
                    <TopBar
                        onSidebarOpen={handleSidebarOpen}
                        onSidebarClose={handleSidebarClose}
                        openSidebar={openSidebar}
                        SignOutComponent={
                            null //TODO: figure out what SignoutComponent lol
                            // <SignoutComponent className="sign-out" />
                        }
                        handleSignout={handleSignout}
                        pageTitle={pageTitle}
                    />
                </header>
                {/* Sidebar */}
                <Sidebar
                    className="m-nav"
                    open={isSidebarCurrentlyOpen}
                    variant={isDesktop ? 'persistent' : 'temporary'}
                    onClose={handleSidebarClose}
                    openSidebar={openSidebar}
                >
                    <SidebarNav
                        className={'emp-mainLayout-nav'}
                        navLinks={currentNavLinks}
                        onSidebarOpen={handleSidebarOpen}
                        onSidebarClose={handleSidebarClose}
                        openSidebar={openSidebar}
                        currentPath={pathname}
                        setPageTitle={setPageTitle}
                    />
                    {user?.merchant?.show_alerts_banner && (
                        <DisputeDeflectorBanner />
                    )}
                </Sidebar>
                <Box
                    component="main"
                    id={idDirectory.main}
                    className={`m-main ${!openSidebar ? 'sidebar-closed' : ''}`}
                    sx={(theme) => ({
                        flexGrow: 1,
                        height: '100%',
                        backgroundColor: theme.palette.background.default,
                        padding: theme.spacing(2),
                        [theme.breakpoints.up('lg')]: {
                            transition: theme.transitions.create('width', {
                                easing: theme.transitions.easing.sharp,
                                duration:
                                    theme.transitions.duration.enteringScreen,
                            }),
                            maxWidth: 'calc(100vw - 220px)',
                            '&.sidebar-closed': {
                                transition: theme.transitions.create('width', {
                                    easing: theme.transitions.easing.sharp,
                                    duration:
                                        theme.transitions.duration
                                            .leavingScreen,
                                }),
                                maxWidth: 'calc(100vw - 65px)',
                            },
                            // overflow: 'hidden',
                        },
                    })}
                >
                    <ErrorBoundary area="the main content">
                        {children}
                    </ErrorBoundary>
                </Box>
                <footer id={idDirectory.footer}>
                    <StandardFooter />
                </footer>
            </div>
        </div>
    )
}
export default MainLayout

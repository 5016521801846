import config from 'config'
import { Auth } from 'aws-amplify'
import axios from 'axios'

type Profile = any
type GetNotifications = (userFeatures: any) => Promise<Profile>
type GetLoginEvents = (
    userId: number,
    limit: number,
    start: number,
    is_unique: boolean
) => Promise<Profile>
type GetWhoami = (accessToken: string) => Promise<Profile>
type SetCognitoPassword = (
    currentPassword: string,
    newPassword: string
) => Promise<Profile>
type SetNotificationSetting = (
    userId: number,
    resourceId: number,
    enabled: boolean
) => Promise<Profile>
type SetLockLogin = (locked: boolean, id?: number, ip?: string) => void
type RefreshTokens = () => Promise<any>

interface IProfileApi {
    getNotifications: GetNotifications
    getLoginEvents: GetLoginEvents
    getWhoami: GetWhoami
    setCognitoPassword: SetCognitoPassword
    setNotificationSetting: SetNotificationSetting
    setLockLogin: SetLockLogin
    refreshTokens: RefreshTokens
}

interface ILoginHistoryRows {
    id: number
    ipAddress: string
    date: string
    locked: boolean
    blockId: number
}

interface ILoginHistoryPagination {
    limit: number
    start: number
    total: number
}

interface ILoginHistory {
    data: ILoginHistoryRows[]
    pagination: ILoginHistoryPagination
}

/**
 * use to get the current users profile, settings, and account information
 */
class ProfileApi implements IProfileApi {
    getNotifications(userAllowResources: number[]) {
        return axios
            .get(`/users/resources?search=email_`, {})
            .then(({ data: { data } }) => {
                return data.map(
                    (data: {
                        id: number
                        alias: string
                        name: string
                        is_enabled: boolean
                        is_user_option: boolean
                    }) => {
                        return {
                            id: data.id,
                            identity: data.alias,
                            text: data.name,
                            isOn: userAllowResources.indexOf(data.id) > -1 ? true : false
                        }
                    }
                )
            })
    }

    getLoginEvents(
        userId: number,
        limit: number = 5,
        start: number = 1,
        is_unique: boolean
    ) {
        return axios
            .get(`/users/users/${userId}/login-history`, {
                params: {
                    is_unique: is_unique ? 1 : 0,
                    limit,
                    start,
                },
            })
            .then(({ data: { data, pagination } }) => {
                const paginationData: ILoginHistoryPagination = {
                    limit: pagination.limit,
                    start: pagination.start,
                    total: pagination.total,
                }
                const rowsData: ILoginHistoryRows[] = data.map(
                    (data: {
                        id: number
                        ip: string
                        timestamp: string
                        is_blocked: boolean
                        blocked_id?: number
                    }) => {
                        return {
                            id: data.id,
                            ipAddress: data.ip,
                            date: data.timestamp,
                            locked: data.is_blocked,
                            blockId: data.blocked_id,
                        }
                    }
                )
                const returnData: ILoginHistory = {
                    data: rowsData,
                    pagination: paginationData,
                }
                return returnData
            })
    }

    getWhoami(accessToken: string) {
        return axios.post(`${config['base-api']}users/whoami`, {
            mode: 'no-cors',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
                Authorization: accessToken,
                Accept: 'application/json',
            },
            body: JSON.stringify({}),
        })
    }

    setCognitoPassword(currentPassword: string, newPassword: string) {
        return Auth.currentAuthenticatedUser()
            .then((user) =>
                Auth.changePassword(user, currentPassword, newPassword)
            )
            .then((data) => data)
            .catch((e) => {
                throw new Error(`Problem changing password: ${e.message}`)
            })
    }

    setNotificationSetting(
        userId: number = 0,
        resourceId: number,
        enabled: boolean
    ) {
        if (enabled) {
            return axios
                .post(`/users/${userId}/resources`, {
                    resource_id: resourceId,
                    is_allowed: true,
                })
                .then((data) => data)
        }
        return axios
            .delete(`/users/${userId}/resources/${resourceId}`)
            .then((data) => data)
    }

    setLockLogin(locked: boolean, id?: number, ip?: string) {
        if (!locked) {
            return axios
                .post(`/users/blockip`, {
                    ip,
                    is_allowed: false,
                })
                .then((data) => data)
        }
        return axios.delete(`/users/blockip/${id}`).then((data) => data)
    }

    async refreshTokens() {
        const cognitoUser = await Auth.currentAuthenticatedUser()
        const { refreshToken } = cognitoUser.getSignInUserSession()
        await cognitoUser.refreshSession(refreshToken, (_err: any, _session: any) => {})
    }
}

export default new ProfileApi()

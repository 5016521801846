/**
 * @description Directory for ID Attributes on Bulk Close.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    bulkClose: {
        divRoot: `bulkClose-div-root`,
        divCriteria: `bulkClose-div-criteria`,
        divCriteriaLabel: `bulkClose-div-criteriaLabel`,
        divSelectors: `bulkClose-div-selectors`,
        divAppliedCriteria: `bulkClose-div-appliedCriteria`,
        divAppliedCriteriaLabel: `bulkClose-div-appliedCriteriaLabel`,
        divBadges: `bulkClose-div-badges`,
        divActions: `bulkClose-div-actions`,
        divStatusText: `bulkClose-div-statusText`,
        textCurrentScheduledJob: `bulkClose-text-currentScheduledJob`,
        textLastScheduledJob: `bulkClose-text-lastScheduledJob`,
        btnRunAction: `bulkClose-btn-runAction`,
        divJobSubmittedMsg: `bulkClose-div-jobSubmittedMsg`,
        dialogRoot: `bulkClose-dialog-root`,
        dialogTitle: `bulkClose-dialog-title`,
        dialogContent: `bulkClose-dialog-content`,
        divDialogContentMsg: `bulkClose-div-dialogContentMsg`,
        dialogActions: `bulkClose-dialog-actions`,
        btnDialogCancel: `bulkClose-btn-dialogCancel`,
        btnDialogAccept: `bulkClose-btn-dialogAccept`,
    },
    badge: {
        divRoot: `badge-div-root`,
        divBadgeText: `badge-div-badgeText`,
        iconBadgeClose: `badge-icon-badgeClose`,
    },
    popoverRangeSelect: {
        formRoot: `popoverRangeSelect-form-root`,
        divCriteria: `popoverRangeSelect-div-criteria`,
        inputMin: `popoverRangeSelect-input-min`,
        inputMax: `popoverRangeSelect-input-max`,
        btnApply: `popoverRangeSelect-btn-apply`,
    },
}

export default idDirectory

import React, { useState } from 'react'
import { Typography, Divider, IconButton, Tooltip } from '@mui/material'
import { format } from 'date-fns'
import {
    faEdit,
    faHistory,
    faCheck,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import EntFeature from 'models/EntFeature'
import { useUiStateContext } from 'context/UiState/UiStateContext'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { clientTheme } from 'theme-exports'
import { MidHistory } from '../modals/MidHistory'
import idDirectory from '../idAttributes'
import { deflectorStatusIdToText } from '../MerchantSettings.vm'

const UserStatus = ({ status }: { status: boolean }) => {
    return (
        <div
            className={
                'emp-statusContainer'
            }
            style={{ fontSize: clientTheme.typography.body1.fontSize }}
        >
            <FontAwesomeIcon
                icon={status ? faCheck : faTimes}
                style={{
                    color: status ? 'green' : 'red',
                    marginRight: '8px',
                    fontSize: '14px',
                }}
            />
            {status ? 'Active' : 'Inactive'}
        </div>
    )
}

export const MerchantMidsGridDetails = (props: any) => {
    const { user } = useAuthedUser()
    const uiState = useUiStateContext()

    const isReadOnly = user?.is_read_only
    const isReadOnlyEditMid = !uiState.whoami?.hasRole(EntFeature.EDIT_MID)

    const [openMidHistoryModal, setOpenMidHistoryModal] = useState<boolean>(
        false
    )

    const {
        selectedRowData,
        availableDeflectors,
        handleMidsListEditMid,
    } = props

    const {
        MID_DETAILS,
        DEFLECTORS,
        SHOW_HELIX_MID = false,
        SHOW_MIDS_WPG_FIELD = false,
        SHOW_MID_HISTORY,
    } = useFeatureToggle('MERCHANT_HIERARCHY')
    const {
        enabled: helixMIDEnabled,
        input_edit_label: helixMIDlabel,
    } = SHOW_HELIX_MID
    const { enabled: WPGFieldEnabled } = SHOW_MIDS_WPG_FIELD

    return (
        <div id={'merchantSettingsMerchantMidsGridDetails'}>
            {selectedRowData ? (
                <div
                    className={'emp-root'}
                    id={idDirectory.merchantMidsGridDetails.divRoot}
                >
                    <div className={'emp-headerContainer'}>
                        <Typography variant="h5" className={'emp-header'}>
                            MID Details
                        </Typography>
                        <div className={'emp-iconContainer'}>
                            {!isReadOnlyEditMid && (
                                <Tooltip arrow placement="top" title="Edit MID">
                                    <div>
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleMidsListEditMid(
                                                    selectedRowData
                                                )
                                            }
                                            disabled={isReadOnly}
                                            id={
                                                idDirectory
                                                    .merchantMidsGridDetails
                                                    .btnEdit
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                className={`${
                                                    isReadOnly &&
                                                    'emp-disabledIcon'
                                                }`}
                                                style={{
                                                    color:
                                                        clientTheme.secondary,
                                                }}
                                            />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            )}
                            {SHOW_MID_HISTORY.enabled && (
                                <Tooltip
                                    arrow
                                    placement="top"
                                    title="MID History"
                                >
                                    <div>
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                setOpenMidHistoryModal(true)
                                            }
                                            id={
                                                idDirectory
                                                    .merchantMidsGridDetails
                                                    .btnMidHistory
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faHistory}
                                                style={{
                                                    color:
                                                        clientTheme.secondary,
                                                }}
                                            />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    <Divider className={'emp-divider'} />
                    <div className={'emp-detailContainer'}>
                        <Typography className={'emp-label'}>Status:</Typography>
                        <UserStatus
                            status={selectedRowData?.status?.id === 2 || false}
                        />
                    </div>
                    <Divider className={'emp-divider'} />
                    <div>
                        <div className={'emp-detailContainer'}>
                            <Typography className={'emp-label'}>
                                {helixMIDEnabled ? helixMIDlabel : 'MID'}:
                            </Typography>
                            <Typography>
                                {selectedRowData?.mid ?? 'Unavailable'}
                            </Typography>
                        </div>
                        <div className={'emp-detailContainer'}>
                            <Typography className={'emp-label'}>
                                {MID_DETAILS.input_edit_label}:
                            </Typography>
                            <Typography>
                                {selectedRowData?.alias ?? 'Unavailable'}
                            </Typography>
                        </div>
                        <div className={'emp-detailContainer'}>
                            <Typography className={'emp-label'}>
                                MID Descriptor:
                            </Typography>
                            <Typography>
                                {selectedRowData?.descriptor?.descriptor ??
                                    'Unavailable'}
                            </Typography>
                        </div>
                        <div className={'emp-detailContainer'}>
                            <Typography className={'emp-label'}>
                                Platform:
                            </Typography>
                            <Typography>
                                {selectedRowData?.processor?.name ??
                                    'Unavailable'}
                            </Typography>
                        </div>
                        <div className={'emp-detailContainer'}>
                            <Typography className={'emp-label'}>
                                MCC:
                            </Typography>
                            <Typography>
                                {selectedRowData?.mcc ?? 'Unavailable'}
                            </Typography>
                        </div>

                        <div className={'emp-detailContainer'}>
                            <Typography className={'emp-label'}>
                                CAID:
                            </Typography>
                            <Typography>
                                {selectedRowData?.caid ?? 'Unavailable'}
                            </Typography>
                        </div>
                        <div className={'emp-detailContainer'}>
                            <Typography className={'emp-label'}>
                                MID Service Level:
                            </Typography>
                            <Typography>
                                {selectedRowData?.service_level?.name ??
                                    'Unavailable'}
                            </Typography>
                        </div>
                        {WPGFieldEnabled ? (
                            <>
                                <div className={'emp-detailContainer'}>
                                    <Typography className={'emp-label'}>
                                        WPG Merchant Code:
                                    </Typography>
                                    <Typography>
                                        {selectedRowData?.wpg_merchant_code ??
                                            'Unavailable'}
                                    </Typography>
                                </div>
                                <div className={'emp-detailContainer'}>
                                    <Typography className={'emp-label'}>
                                        Date Onboarded:
                                    </Typography>
                                    <Typography>
                                        {selectedRowData?.date_created
                                            ? format(
                                                  new Date(
                                                      selectedRowData?.date_created
                                                  ),
                                                  'MM/dd/yyyy'
                                              )
                                            : 'Unavailable'}
                                    </Typography>
                                </div>
                            </>
                        ) : (
                            <div className={'emp-detailContainer'}>
                                <Typography className={'emp-label'}>
                                    Date Onboarded:
                                </Typography>
                                <Typography>
                                    {selectedRowData?.date_created
                                        ? format(
                                              new Date(
                                                  selectedRowData?.date_created
                                              ),
                                              'MM/dd/yyyy'
                                          )
                                        : 'Unavailable'}
                                </Typography>
                            </div>
                        )}

                        {DEFLECTORS.enabled && (
                            <>
                                <Divider className={'emp-divider'} />

                                {availableDeflectors?.map(
                                    (
                                        availableDeflector: {
                                            alias: string
                                            id: number
                                            name: string
                                        },
                                        idx: number
                                    ) => {
                                        // Statuses 1: Inactive - 2: Pending Activation - 3: Active
                                        let foundDeflector = undefined as any
                                        try {
                                            foundDeflector =
                                                selectedRowData?.deflectors &&
                                                selectedRowData.deflectors?.find(
                                                    (obj: any) => {
                                                        return (
                                                            obj.id ===
                                                            availableDeflector.id
                                                        )
                                                    }
                                                )
                                        } catch (e) {}

                                        return (
                                            <div
                                                key={`key-deflector-${idx}`}
                                                className={
                                                    'emp-detailContainer'
                                                }
                                            >
                                                <Typography
                                                    className={'emp-label'}
                                                >
                                                    {availableDeflector?.name}:
                                                </Typography>
                                                <Typography>
                                                    {foundDeflector
                                                        ? deflectorStatusIdToText(
                                                              foundDeflector
                                                                  ?.status?.id
                                                          )
                                                        : 'Inactive'}
                                                </Typography>
                                            </div>
                                        )
                                    }
                                )}
                            </>
                        )}
                    </div>
                    {openMidHistoryModal && (
                        <MidHistory
                            openMidHistory={openMidHistoryModal}
                            onClose={() => setOpenMidHistoryModal(false)}
                            midId={selectedRowData.id}
                        />
                    )}
                </div>
            ) : (
                <div
                    className={'emp-noSelectedRowRoot'}
                    id={idDirectory.merchantMidsGridDetails.divNoSelectedRow}
                >
                    <Typography variant="h5">
                        You must select a row to see MID details.
                    </Typography>
                </div>
            )}
        </div>
    )
}

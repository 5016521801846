import React from 'react'
import { Auth } from 'aws-amplify'
import {
    TextField,
    Typography,
    Button,
    Link,
    CircularProgress,
    Box,
} from '@mui/material'
import { FormState } from './AuthedUserContext'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'

interface SignInProps {
    formState: FormState
    setFormState: (val: any) => void
    setSnackbar: (val: { isOpen: boolean; errorMessage: any }) => void
    isSubmit: boolean
    setIsSubmit: (val: boolean) => void
    handleOnChange: (e: any) => void
    setIsSAMLBackDoor: (e: any) => void
    isSAMLBackDoorLogout?: boolean
}

const SignIn = ({
    formState,
    setFormState,
    setSnackbar,
    isSubmit,
    setIsSubmit,
    handleOnChange,
    setIsSAMLBackDoor,
    isSAMLBackDoorLogout = false,
}: SignInProps) => {
    const { username, password } = formState

    Auth.configure({
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    })

    const handleSignIn = async (e: any) => {
        e.preventDefault()
        if (!username.length)
            return setSnackbar({
                isOpen: true,
                errorMessage: 'Username cannot be empty.',
            })
        if (!password.length)
            return setSnackbar({
                isOpen: true,
                errorMessage: 'Password cannot be empty.',
            })
        setIsSubmit(true)
        try {
            const { challengeName } = await Auth.signIn({
                username,
                password,
            })
            if (isSAMLBackDoorLogout) setIsSAMLBackDoor(1)
            if (challengeName === 'NEW_PASSWORD_REQUIRED') {
                setFormState(() => ({
                    ...formState,
                    formType: 'confirmSignUp',
                }))
            } else {
                setFormState(() => ({
                    ...formState,
                    formType: 'signedIn',
                    password: '',
                }))
            }
            setIsSubmit(false)
        } catch (err: any) {
            const errorMsg = err?.message
            const formattedMsg =
                typeof errorMsg === 'string'
                    ? errorMsg?.replace(
                          'PreAuthentication failed with error ',
                          ''
                      )
                    : `An error occurred. Please try again later.`

            setSnackbar({ isOpen: true, errorMessage: formattedMsg })
            setIsSubmit(false)
        }
    }

    return (
        <form onSubmit={handleSignIn} id={'signIn'}>
            <div
                className={'emp-container'}
                id={idDirectory.signIn.divContainer}
            >
                <div
                    className={'emp-loginContainer'}
                    id={idDirectory.signIn.divLoginContainer}
                >
                    <div className={'emp-inputs'}>
                        <Typography variant={'h6'} className={'emp-textInput'}>
                            Username
                        </Typography>
                        <Box
                            className={'emp-textField'}
                            sx={{
                                '& .MuiInput-underline:after': {
                                    border: `1px solid ${clientTheme.textField}`,
                                },
                            }}
                        >
                            <TextField
                                type="username"
                                name="username"
                                variant="standard"
                                onChange={handleOnChange}
                                value={username}
                                fullWidth
                                id={idDirectory.signIn.inputUsername}
                            />
                        </Box>
                        <Typography variant={'h6'} className={'emp-textInput'}>
                            Password
                        </Typography>
                        <Box
                            className={'emp-textField'}
                            sx={{
                                '& .MuiInput-underline:after': {
                                    border: `1px solid ${clientTheme.textField}`,
                                },
                            }}
                        >
                            <TextField
                                type="password"
                                name="password"
                                variant="standard"
                                onChange={handleOnChange}
                                value={password}
                                fullWidth
                                id={idDirectory.signIn.inputPassword}
                            />
                        </Box>
                        <Typography id={idDirectory.signIn.textForgotPassword}>
                            <Link
                                onClick={() =>
                                    setFormState(() => ({
                                        ...formState,
                                        formType: 'resetPassword',
                                    }))
                                }
                                className={'emp-link'}
                                sx={{
                                    '&:hover': {
                                        color: `${clientTheme.textField} !important`,
                                        textDecoration: 'none',
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                Forgot your password?
                            </Link>
                        </Typography>
                    </div>
                </div>
                <div>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={'emp-btn'}
                        type="submit"
                        fullWidth
                        id={idDirectory.signIn.btnSignIn}
                    >
                        {isSubmit ? (
                            <CircularProgress
                                className={'emp-circularProgress'}
                                color="secondary"
                            />
                        ) : (
                            'Sign In'
                        )}
                    </Button>
                </div>
            </div>
        </form>
    )
}

export default SignIn

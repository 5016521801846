import React, { useState } from 'react'
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Skeleton } from '@mui/material'
import { CaseFilters, useCaseFilterValue } from './useCaseFilterValue'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { ConfirmActionModal } from 'components'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

export interface ICaseFilterProps {
    title: string
    className?: string
    id?: string
    onFilterChange: (
        values: Record<string, string | boolean | number | null>
    ) => void
    handleDayLimit?: () => any
    clientId: number
    onClose: () => void
    clearAdvanceSearch: () => void
    isActiveAdvSearchFilters: boolean
    params: any
    setMetricPresets: (value: any) => void
    setAlertFilterOpen: (value: boolean) => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

/**
 * Getting counts and allowing actions on filtering of case statuses
 * Really this should use chips, but the reference implementation uses a table
 */
const CaseFilter = ({
    className,
    id,
    title,
    onFilterChange,
    onClose,
    clearAdvanceSearch,
    isActiveAdvSearchFilters,
    params,
    setMetricPresets,
    setAlertFilterOpen,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: ICaseFilterProps) => {
    const [selectedFilter, setSelectedFilter] = useState('')
    const [dayValue, setDayValue] = useState(7)
    const { CASE_FILTERS = {} } = useFeatureToggle('CHARGEBACKS') || {}
    const { PRIMARY_EXPIRING_X_DAYS = { enabled: false } } = CASE_FILTERS
    const { enabled: primaryExpiringXDaysEnabled } = PRIMARY_EXPIRING_X_DAYS
    const rfis = useCaseFilterValue(params, CaseFilters.RFIS)
    const primaryCycles = useCaseFilterValue(params, CaseFilters.Primary)
    const secondaryCycles = useCaseFilterValue(params, CaseFilters.Secondary)
    const primaryFrauds = useCaseFilterValue(params, CaseFilters.Primary, 1)
    const primaryNonFrauds = useCaseFilterValue(params, CaseFilters.Primary, 0)
    const primaryExpiredCases = useCaseFilterValue(params, null, null, dayValue)
    const [caseFilterAlertOpen, setCaseFilterAlertOpen] = useState<boolean>(
        false
    )

    const filters = [
        {
            name: 'Retrievals (RFIs)',
            data: rfis,
        },
        {
            name: 'Primary Cycle',
            data: primaryCycles,
        },
        {
            name: 'Secondary Cycle',
            data: secondaryCycles,
        },
        {
            name: 'Primary Fraud',
            data: primaryFrauds,
        },
        {
            name: 'Primary Non-Fraud',
            data: primaryNonFrauds,
        },
    ]

    const initialState = {
        cycle: '',
        is_fraud: '',
        filter_group: '',
        primary_case_due_days: '',
    }

    const handleFilterChange = async (value: string) => {
        isActiveAdvSearchFilters && (await clearAdvanceSearch())
        setSelectedFilter(value)

        switch (value) {
            case 'Retrievals (RFIs)':
                onFilterChange({
                    ...initialState,
                    cycle: '9',
                })
                setMetricPresets([
                    {
                        filter_name: 'cycle_id',
                        filter_value: [
                            {
                                name: 'Retrievals Request',
                                id: 9,
                                value: 9,
                            },
                        ],
                    },
                ])
                setAlertFilterOpen(true)
                break
            case 'Primary Cycle':
                onFilterChange({
                    ...initialState,
                    cycle: '1',
                })
                setMetricPresets([
                    {
                        filter_name: 'cycle_id',
                        filter_value: [
                            {
                                name: 'First Chargeback',
                                id: 1,
                                value: 1,
                            },
                        ],
                    },
                ])
                setAlertFilterOpen(true)
                break
            case 'Secondary Cycle':
                onFilterChange({
                    ...initialState,
                    cycle: '2',
                })
                setMetricPresets([
                    {
                        filter_name: 'cycle_id',
                        filter_value: [
                            {
                                name: 'Second Chargeback',
                                id: 2,
                                value: 2,
                            },
                        ],
                    },
                ])
                setAlertFilterOpen(true)
                break
            case 'Primary Fraud':
                onFilterChange({
                    ...initialState,
                    cycle: '1',
                    is_fraud: 1,
                })
                setMetricPresets([
                    {
                        filter_name: 'cycle_id',
                        filter_value: [
                            {
                                name: 'First Chargeback',
                                id: 1,
                                value: 1,
                            },
                        ],
                    },
                ])
                setAlertFilterOpen(true)
                break
            case 'Primary Non-Fraud':
                onFilterChange({
                    ...initialState,
                    cycle: '1',
                    is_fraud: 0,
                })
                setMetricPresets([
                    {
                        filter_name: 'cycle_id',
                        filter_value: [
                            {
                                name: 'First Chargeback',
                                id: 1,
                                value: 1,
                            },
                        ],
                    },
                ])
                setAlertFilterOpen(true)
                break
            case 'Primary Cases Due to Expire in':
                onFilterChange({
                    ...initialState,
                    filter_group: 'primary_case_expire',
                    primary_case_due_days: dayValue,
                })
                break
            default:
                return
        }
        setCaseFilterAlertOpen(false)
    }

    const handleReset = async () => {
        isActiveAdvSearchFilters && (await clearAdvanceSearch())
        setSelectedFilter('')
        onFilterChange(initialState)
    }

    const handleErrorPrimaryCasesDueDays = () => {
        setAlertSnackbarMainProps({
            message: 'Primary cases due to expire cannot be 0 days.',
            title: 'Error.',
            intent: 'error',
        })
        setAlertSnackbarMainOpen(true)
        return
    }

    return (
        <div>
            <Paper
                elevation={0}
                className={`emp-caseFilter-root ${className}`}
                id={id}
            >
                <TableContainer>
                    <Table size="small" aria-label="filters">
                        <TableHead className={'emp-caseFilter-header'}>
                            <TableRow>
                                <TableCell component="th">{title}</TableCell>
                                <TableCell component="th" align="right">
                                    Total
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filters?.map(({ name, data }) => (
                                <TableRow
                                    onClick={() => {
                                        if (isActiveAdvSearchFilters) {
                                            setCaseFilterAlertOpen(true)
                                            setSelectedFilter(name)
                                        } else {
                                            handleFilterChange(name)
                                        }
                                    }}
                                    className={`emp-caseFilter-table-row ${
                                        selectedFilter === name &&
                                        'emp-caseFilter-selectedRow'
                                    }`}
                                    key={name}
                                >
                                    <TableCell
                                        className={'emp-caseFilter-listItem'}
                                    >
                                        <p>{name}</p>
                                    </TableCell>
                                    <TableCell align="right">
                                        {data.loading ? (
                                            <Skeleton variant="rectangular" />
                                        ) : (
                                            Number(
                                                data.totalCases
                                            ).toLocaleString('en-US')
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {primaryExpiringXDaysEnabled && (
                                <TableRow
                                    className={'emp-caseFilter-table-row'}
                                >
                                    <TableCell
                                        className={'emp-caseFilter-listItem'}
                                    >
                                        <p>
                                            Primary Cases Due to Expire in
                                            <br />
                                            <input
                                                id="dayValue"
                                                value={dayValue}
                                                className={
                                                    'emp-caseFilter-dayInput'
                                                }
                                                onChange={(e) => {
                                                    const days = +e.target.value
                                                    if (isNaN(days)) return
                                                    if (e.target.value === '')
                                                        return setDayValue(days)
                                                    if (!days)
                                                        handleErrorPrimaryCasesDueDays()
                                                    setDayValue(days)
                                                }}
                                            />
                                            <span>day(s)</span>
                                            <FontAwesomeIcon
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                    backgroundColor: '#afafaf',
                                                    padding: '5px',
                                                    color: 'white',
                                                    borderRadius: '4px',
                                                    marginLeft: '5px',
                                                }}
                                                icon={faSearch}
                                                onClick={() => {
                                                    if (!dayValue)
                                                        return handleErrorPrimaryCasesDueDays()
                                                    handleFilterChange(
                                                        'Primary Cases Due to Expire in'
                                                    )
                                                }}
                                            />
                                        </p>
                                    </TableCell>
                                    <TableCell align="right">
                                        {primaryExpiredCases.loading ? (
                                            <Skeleton variant="rectangular" />
                                        ) : (
                                            Number(
                                                primaryExpiredCases.totalCases
                                            ).toLocaleString('en-US')
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={`emp-caseFilter-actions filter-action-area`}>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => onClose()}
                    >
                        Close
                    </Button>
                </div>
            </Paper>
            <ConfirmActionModal
                open={caseFilterAlertOpen}
                toggleOpen={() => setCaseFilterAlertOpen((prev) => !prev)}
                onConfirm={() => {
                    handleFilterChange(selectedFilter)
                }}
                header={'Apply Case Filters'}
                message={
                    'Applying the selected case filter will overwrite any applied advanced search parameters.'
                }
                confirmButtonText={'Continue'}
                testId={'case-filter'}
            />
        </div>
    )
}

export default CaseFilter

import React from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material'
import { Alert } from '@mui/material'
import { clientTheme } from 'theme-exports'

interface RemoveMidAccessConfirmationProps {
    open: boolean
    actionConfirmListener: () => void
    closeModal: () => void
    error?: Error | null
}

// The action confirmation modal when clicking remove access to all MIDs (different from the normal Modal Area found elsewhere...)
export const RemoveMidAccessConfirmation: React.FC<RemoveMidAccessConfirmationProps> = ({
    open,
    actionConfirmListener,
    closeModal,
    error,
}) => {
    return (
        <Dialog
            id={'merchantHierarchyRemoveMidAccessConfirmation'}
            open={open}
            maxWidth="xs"
        >
            {error && <Alert color="error">{error.message}</Alert>}
            <DialogTitle
                className={'emp-modalHeader'}
                style={{ borderTop: `3px solid ${clientTheme.primaryDark}` }}
            ></DialogTitle>
            <DialogContent
                classes={{
                    root: 'emp-dialogContent',
                }}
            >
                <Typography variant="subtitle1">
                    Are you sure you want to remove access to this MID for this
                    user?
                </Typography>

                <Typography variant="subtitle1" color="error">
                    This will automatically disable the user since it won't have
                    access to any information from this point.
                </Typography>

                <div className={'emp-divider'} />

                <div className={'emp-btnGrp'}>
                    <Button
                        onClick={closeModal}
                        variant="contained"
                        sx={clientTheme.buttons.defaultButton.style}
                    >
                        No
                    </Button>
                    <Button
                        onClick={actionConfirmListener}
                        variant="contained"
                        color="secondary"
                    >
                        Yes
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

import React from 'react'
import { Typography, CardMedia } from '@mui/material'
import { Skeleton } from '@mui/material'
import { AccentArea } from 'components'
import { Topic } from './Help.vm'

// A styled well container for interior content
const Well = ({ children }: { children: React.ReactNode }) => (
    <div className={'emp-well-container'}>{children}</div>
)

interface VideoProps {
    src: string
    type: string
    className?: string
}
// Contain a html5 video with a single src
const Video = ({ src, type, className }: VideoProps) => (
    <video className={`${className} emp-video`} controls>
        <source src={src} type={type} />
        Unable to view the video in this browser, please try with another.
    </video>
)
/**
 * Use to display a single topic and video
 */
export const TopicArea = ({
    topic,
    loading,
}: {
    topic: Topic
    loading: boolean
    className?: string
}) => {
    return (
        <div id={"helpTopic"}>
            <AccentArea
                title={
                    loading ? (
                        <Skeleton variant="text" height="3rem" width="10rem" />
                    ) : (
                        topic?.title
                    )
                }
                testId={topic?.testId}
            >
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                    gutterBottom
                    className={'emp-typography'}
                >
                    {loading
                        ? [...Array(5)].map(() => (
                              <div key={topic.id}>
                                  <Skeleton variant="text" />
                              </div>
                          ))
                        : topic?.description}
                </Typography>
                <CardMedia>
                    <Well>
                        {!loading && topic?.videoUrl && topic?.videoType ? (
                            <Video
                                src={`${process.env.PUBLIC_URL}/help/${topic.videoUrl}`}
                                type={topic.videoType}
                            />
                        ) : (
                            <Skeleton
                                variant="rectangular"
                                height="13rem"
                                width="100%"
                            />
                        )}
                    </Well>
                </CardMedia>
                {topic?.outro && <div>{topic?.outro}</div>}
            </AccentArea>
        </div>
    )
}

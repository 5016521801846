// These will be split into categories eventually

export const endpointMap: Record<string, string> = {
    status: 'status',
    whoami: 'users/whoami',
    users: 'users/users',
    userRoles: 'users/roles',
    cases: 'cm/cases',
    caseStatuses: 'cm/cases/statuses',
    merchants: 'cm/merchants',
    midTop5: 'cm/cases/reports/mids-top5',
    midCount: 'cm/cases/reports/mids-count',
    casesMtd: 'cm/cases/dashboard/chargebacks-mtd',
    casesFraudPct: 'cm/cases/reports/cases-fraud-pct',
    casesStatusTotals: 'cm/cases/reports/cases-status-totals',
    casesExpiring: 'cm/cases/dashboard/expiring-cases',
    userCount: 'cm/cases/reports/user-count',
    cardType: 'cm/cases/reports/card-type',
    chargebacksByPostDate: 'cm/cases/dashboard/chargebacks-by-date-range',
    generateReasonCode: 'cm/gen/rc',
    countries: 'cm/gen/countries',
    processors: 'cm/gen/processors',
    states: 'cm/gen/states',
    currencies: 'cm/gen/urrencies',
    ccTypes: 'cm/gen/cctypes',
    cycles: 'cm/gen/cycles',
    merchantHierarchySearch: 'cm/merhant-hierarchy/search',
    countryCodes: `/cm/gen/countries`,
}

export default endpointMap

import { useEffect, useState } from 'react'
import { dateUtilities } from 'utils/dateUtilities'
import { format } from 'date-fns'
import { IDateRange } from 'components/PresetDateRange'
import { useActiveMerchant } from 'components'
import axios from 'axios'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

export interface CbDatum {
    month: string
    transactions: string
    chargebacks: string
    result_ratio: string
}

export interface AlertDatum {
    chargeback_total: string
    alert_total: string
    total: string
    month: string
}

export interface OptionsList {
    id: number
    name: string
}

interface Pagination {
    start: number
    limit: number
    total: number
}

interface ResultantKpiVM {
    resultantKpiAlerts: AlertDatum[]
    resultantKpiChargebacks: CbDatum[]
    loading: boolean
    selectedValue: {
        currency_id: {
            name: string
            id: string
        }
        platform_id: {
            name: string
            id: string
        }
    }
    handleFilterUpdate: (
        e: { id: number; name: string },
        key: 'currency_id' | 'platform_id'
    ) => void
    currencySearchValue: string
    setCurrencySearchValue: (value: string) => void
    currenciesList: OptionsList[]
    platformsList: OptionsList[]
    optionsListLoading: boolean
    alertSnackbarMainProps: alertSnackbarContentProps
    alertSnackbarMainOpen: boolean
    setAlertSnackbarMainOpen: (status: boolean) => void
}

const useResultantKpi = (): ResultantKpiVM => {
    const { id: merchantId } = useActiveMerchant()

    const [resultantKpiAlerts, setResultantKpiAlerts] = useState<{
        data: AlertDatum[]
        pagination: Pagination
    }>()
    const [resultantKpiChargebacks, setResultantKpiChargebacks] = useState<{
        data: CbDatum[]
        pagination: Pagination
    }>()
    const [alertSnackbarMainOpen, setAlertSnackbarMainOpen] = useState<boolean>(
        false
    )
    const [
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
    ] = useState<alertSnackbarContentProps>({})
    const [loading, setLoading] = useState(false)

    const [selectedValue, setSelectedValue] = useState({
        currency_id: { name: 'All Currencies', id: '' },
        platform_id: { name: 'All Platforms', id: '' },
    })
    const [currencySearchValue, setCurrencySearchValue] = useState('')
    const [currenciesList, setCurrenciesList] = useState([
        {
            id: -1,
            name: 'All Currencies',
        },
    ])
    const [platformsList, setPlatformsList] = useState([
        {
            id: -1,
            name: 'All Platforms',
        },
    ])
    const [optionsListLoading, setOptionsListLoading] = useState(false)

    const defaultDateRangeOnLoad: {
        to: Date
        from: Date
    } = dateUtilities.getPriorMonths(6)
    const { to, from } = defaultDateRangeOnLoad
    const presetDateRangeDate: IDateRange = {
        to: format(to, 'yyyy-MM-dd'),
        from: format(from, 'yyyy-MM-dd'),
    }
    const apiDateRange = `${presetDateRangeDate.from}|${presetDateRangeDate.to}`

    // Updates input value state with provide value and key
    const handleFilterUpdate = (
        e: { id: number; name: string },
        key: keyof typeof selectedValue
    ) => {
        setSelectedValue({
            ...selectedValue,
            [key]: { name: e.name, id: e.id === -1 ? '' : e.id },
        })
        if (e.id === -1 && key === 'currency_id') setCurrencySearchValue('')
    }

    useEffect(() => {
        if (!merchantId) return
        setLoading(true)

        const RKpiChargebacks = axios
            .get('/cm/cases/reports/res-kpi/chargebacks', {
                params: {
                    merchant_id: merchantId,
                    date_range: apiDateRange,
                    currency_id: selectedValue.currency_id.id,
                    platform_id: selectedValue.platform_id.id,
                },
            })
            .then((res) => setResultantKpiChargebacks(res.data))

        const RKpiAlerts = axios
            .get('/cm/cases/reports/res-kpi/alerts', {
                params: {
                    merchant_id: merchantId,
                    date_range: apiDateRange,
                    currency_id: selectedValue.currency_id.id,
                    platform_id: selectedValue.platform_id.id,
                },
            })
            .then((res) => setResultantKpiAlerts(res.data))

        Promise.all([RKpiChargebacks, RKpiAlerts])
            .catch(() => {
                setAlertSnackbarMainProps({
                    title: 'Error',
                    message:
                        'There was an error fetching the Resultant KPI data.',
                    intent: 'error',
                })
                setAlertSnackbarMainOpen(true)
            })
            .finally(() => setLoading(false))

        return () => {
            setAlertSnackbarMainProps({})
            setAlertSnackbarMainOpen(false)
            setLoading(false)
        }
    }, [apiDateRange, merchantId, selectedValue])

    useEffect(() => {
        setOptionsListLoading(true)
        const returnData: OptionsList[] = []
        if (currencySearchValue.length > 1) {
            axios
                .get('/cm/gen/currencies', {
                    params: {
                        search: currencySearchValue,
                        limit: 999,
                    },
                })
                .then(({ data }) => {
                    data.data.forEach(
                        (data: { id: number; currency: string }) => {
                            returnData.push({
                                id: data.id,
                                name: data.currency,
                            })
                        }
                    )
                    setCurrenciesList([
                        {
                            id: -1,
                            name: 'All Currencies',
                        },
                        ...returnData,
                    ])
                })
                .finally(() => setOptionsListLoading(false))
        }
    }, [currencySearchValue])

    useEffect(() => {
        setOptionsListLoading(true)
        const returnData: OptionsList[] = []
        axios
            .get('/cm/gen/processors')
            .then(({ data }) => {
                data.data
                    .filter((data: { alias: string }) => data.alias.length)
                    .forEach((data: { id: number; alias: string }) => {
                        returnData.push({
                            id: data.id,
                            name: data.alias,
                        })
                    })
                setPlatformsList([
                    {
                        id: -1,
                        name: 'All Platforms',
                    },
                    ...returnData,
                ])
            })
            .finally(() => setOptionsListLoading(false))
    }, [])

    return {
        resultantKpiAlerts: resultantKpiAlerts?.data ?? [],
        resultantKpiChargebacks: resultantKpiChargebacks?.data ?? [],
        loading,
        selectedValue,
        handleFilterUpdate,
        currencySearchValue,
        setCurrencySearchValue,
        currenciesList,
        platformsList,
        optionsListLoading,
        alertSnackbarMainProps,
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
    }
}

export default useResultantKpi

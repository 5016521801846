import { useState } from 'react'
import axios from 'axios'
import {
    ParamSetter,
    CaseStatusId,
    CaseStatusGroupFilter,
    CaseStatusGroupId,
    CaseData,
    IFormField,
    AdvancedSearchValues,
    HandleAdvancedSearchSubmit,
} from '../index'

export interface UseCaseActions {
    caseViewActions: CaseViewActions
    caseNetworkActions: CaseNetworkActions
    casesLoading: boolean
}

interface CaseNetworkActions {
    [key: string]: any
}
interface CaseViewActions {
    [key: string]: any
    shouldDisableUpload: any
    shouldDisableRevertDNR: any
    shouldDisableDNR: any
}

export const useCaseActions = (
    viewInstance: Record<string, any>,
    paramSetter: ParamSetter
): UseCaseActions => {
    const [loading, setLoading] = useState(false)
    const [adSearchValues, setAdSearchValues] = useState<AdvancedSearchValues>(
        {}
    )
    const [
        caseFilterGroup,
        setCaseFilterGroup,
    ] = useState<CaseStatusGroupFilter | null>()

    // mutate allows us to re-flush the cache of the view instance and immediately update data in the UI if anything changed. Mutate is bound to the view instance it is exported from. Mutate can be called after a case(s) is updated in any of the below methods, typically in a finally / cleanup block.
    const { mutate, data: cases } = viewInstance

    const { setQueryParams } = paramSetter

    //** CASE VIEW ACTIONS */
    // Case Views Actions return information about the cases, but do not make network calls to update them.
    const caseViewActions: CaseViewActions = {
        isCaseSelectable: (status_group_id: number) =>
            Boolean(status_group_id === +CaseStatusGroupId.New),
        shouldDisableUpload: function (prop: boolean) {
            return !Boolean(prop)
        },
        shouldDisableRevertDNR: function (prop: boolean) {
            return !Boolean(prop)
        },
        shouldDisableDNR: function (prop: boolean) {
            return !Boolean(prop)
        },
        isDocStatusCompiling: (status_id: CaseStatusId) => {
            if (status_id === +CaseStatusId.DocCompiling) return true
            else return false
        },
        getAssignedName: (assigned_user: any) =>
            `${assigned_user.fname} ${assigned_user.lname}`,
        formatAdvancedSearchParams: (
            params: AdvancedSearchValues,
            formField: IFormField[]
        ): AdvancedSearchValues => {
            return Object.fromEntries(
                Object.entries({
                    ...params,
                }).map(([key, value]) => {
                    const foundField = formField.find((field) => {
                        return field.key === key
                    })
                    if (!foundField) return [key, value]

                    const keyToSend = foundField.filterOverrideName ?? key

                    // Get rid of amount field operators, when related field has no value set.
                    if (foundField.key.endsWith('_op')) {
                        const relatedFieldKey = foundField.key.replace(
                            '_op',
                            ''
                        )
                        if (!params[relatedFieldKey]) return [keyToSend, '']
                    }

                    return [keyToSend, value]
                })
            )
        },
        currentCaseFilterGroup: caseFilterGroup,

        // const isFlagged can be handled at the component level by checking if cases.flag is null or defined.
        // const isAssigned can be handled at the
    }
    //** END CASE VIEWS */

    //** CASE NETWORK ACTIONS */
    // Case Network Actions make network calls to update cases.
    const caseNetworkActions: CaseNetworkActions = {
        refreshInterval: null as null | NodeJS.Timeout,
        setRefreshInterval: function (timeout: NodeJS.Timeout) {
            this.refreshInterval = timeout
        },
        resetInterval: function () {
            clearInterval(this.refreshInterval as any)
        },
        assignUserToCase: async (
            assigned_user_id: number | null,
            case_id: number
        ) => {
            setLoading(true)
            try {
                await axios.patch(`cm/cases/${case_id}`, {
                    user_id: assigned_user_id,
                })
                mutate()
            } finally {
                setLoading(false)
            }
        },
        unassignUserFromCase: function async(case_id: number) {
            this.assignUserToCase(null, case_id)
        },
        // passing false as the assigned_user_id will unassign the user from the cases.
        bulkToggleAssignUserToCases: async (
            case_ids: number[],
            assigned_user_id: number | boolean
        ) => {
            const cases = case_ids.map((id: any) => ({
                id,
                user_id: assigned_user_id,
            }))
            setLoading(true)
            try {
                await axios.put(`cm/cases`, {
                    cases,
                })
                mutate()
            } finally {
                setLoading(false)
            }
        },

        // These are similar to the regular assign, but modified for admin review cases.
        assignUserToAdminReview: async (
            assigned_user_id: number | boolean,
            case_id: number
        ) => {
            setLoading(true)
            try {
                await axios.patch(`cm/review`, {
                    cases: [
                        {
                            id: case_id,
                            assign_id: assigned_user_id,
                        },
                    ],
                })
                mutate()
            } finally {
                setLoading(false)
            }
        },
        unassignUserFromAdminReview: function async(case_id: number) {
            this.assignUserToAdminReview(false, case_id)
        },
        // passing false as the assigned_user_id will unassign the user from the cases.
        bulkToggleAssignUserToAdminReviews: async (
            case_ids: number[],
            assigned_user_id: number | boolean
        ) => {
            const casesToSend = case_ids.map((id: any) => ({
                id,
                assign_id: assigned_user_id,
            }))
            setLoading(true)
            try {
                await axios.patch(`cm/review`, {
                    cases: casesToSend,
                })
                mutate()
            } finally {
                setLoading(false)
            }
        },

        flagCase: async (case_id: number, comment?: string) => {
            setLoading(true)
            try {
                await axios.post(`cm/cases/${case_id}/flag`, {
                    flag: true,
                    comment,
                })
                mutate()
            } finally {
                setLoading(false)
            }
        },
        // flag_id comes from the case after it is flagged
        unFlagCase: async (case_id: number, flag_id: number) => {
            setLoading(true)
            try {
                await axios.patch(`cm/cases/${case_id}/flag/${flag_id}`, {
                    is_flagged: false,
                })
                mutate()
            } finally {
                setLoading(false)
            }
        },
        // bulk flag toggle on cases does not require a flag ID to unflag...
        bulkToggleFlag: async (
            case_ids: number[],
            is_flagged: boolean,
            comment?: string
        ) => {
            const flags = case_ids.map((id) => ({
                case_id: id,
                is_flagged: is_flagged,
                comment,
            }))
            setLoading(true)
            try {
                await axios.put(`cm/cases/flag`, {
                    flags,
                })
                mutate()
            } finally {
                setLoading(false)
            }
        },
        setCaseStatus: async function (
            case_id: number,
            status_id: CaseStatusId
        ) {
            setLoading(true)
            try {
                await axios.patch(`cm/cases/${case_id}`, {
                    status_id: +status_id,
                })
                mutate()
            } finally {
                setLoading(false)
            }
        },
        doNotRepresentCase: async function (case_id: number) {
            this.setCaseStatus(case_id, CaseStatusId.DoNotRepresent)
        },
        revertToNewCase: async function (case_id: number) {
            this.setCaseStatus(case_id, CaseStatusId.New)
        },
        // const setStatus2;
        initDocumentCompileSuccessInterval: function () {
            // calling mutate will re-flush the view instance data cache and if any status_id updates on a case, it will update the UI immediately.
            this.setRefreshInterval(
                setTimeout(() => {
                    const documentsAreCompiling = cases.filter(
                        (c: CaseData) =>
                            c.status_id === CaseStatusId.DocCompiling
                    ).length

                    if (documentsAreCompiling) {
                        // call the bound case mutator to reflush the view instance data cache every 30 seconds until no cases are in DocCompiling status.
                        mutate()
                    } else {
                        this.resetInterval()
                    }
                }, 30_000)
            )
        },
        setCaseStatusFiltlerGroup: (
            case_status_group_filter: CaseStatusGroupFilter
        ) => {
            let queryParamArray: any[] = []

            queryParamArray.push(['start', '0'])
            queryParamArray.push(['intent', 'cm'])

            setCaseFilterGroup(case_status_group_filter)

            queryParamArray.push(['filter_group', case_status_group_filter])

            setQueryParams(queryParamArray)

            mutate()
        },
        setCaseStatusFilterGroupAGGrid: (
            case_status_group_filter: CaseStatusGroupFilter
        ) => {
            const queryParamArray: string[][] = [['intent', 'cm']]
            queryParamArray.push(['filter_group', case_status_group_filter])

            setCaseFilterGroup(case_status_group_filter)
            setQueryParams(queryParamArray)
        },
        bulkDoNotRepresent: async (case_ids: number[]) => {
            setLoading(true)
            const cases = case_ids.map((id) => ({
                id: id,
                status_id: CaseStatusId.DoNotRepresent,
            }))
            try {
                await axios.put(`cm/cases/`, {
                    cases,
                })
                mutate()
            } finally {
                setLoading(false)
            }
        },
        bulkRevertToNew: async (case_ids: number[]) => {
            setLoading(true)
            const cases = case_ids.map((id) => ({
                id: id,
                status_id: CaseStatusId.New,
            }))
            try {
                await axios.put(`cm/cases/`, {
                    cases,
                })
                mutate()
            } finally {
                setLoading(false)
            }
        },
        submitAdvancedSearchValues: (
            params: AdvancedSearchValues,
            form_fields: IFormField[]
        ): HandleAdvancedSearchSubmit => {
            const values = caseViewActions.formatAdvancedSearchParams(
                params,
                form_fields
            )

            let queryParamArray: any[] = []
            Object.entries(values).forEach(([key, value]) => {
                queryParamArray.push([key, String(value)])
            })

            setAdSearchValues(values)

            setQueryParams(queryParamArray)

            return values
        },
        clearAdvancedSearchValues: (defaultValues?: { [key: string]: any }) => {
            const defaultAccessor = Object.keys(defaultValues ?? {})
            const searchAccessor = Object.keys(adSearchValues)
            let clearedSearchAccessors = {}
            // map over the search accessors and set them to empty strings, efectively clearing the network search - we can no longer clear the params by just setting an empty param object here
            searchAccessor.forEach((key) => {
                clearedSearchAccessors = {
                    ...clearedSearchAccessors,
                    [key]:
                        defaultAccessor.includes(key) && defaultValues
                            ? defaultValues[key]
                            : '',
                }
            })

            const queryParamArray: string[][] = []
            Object.entries(clearedSearchAccessors).forEach(([key, value]) => {
                queryParamArray.push([key, String(value)])
            })

            setQueryParams(queryParamArray)
        },
        submitCaseFilterValues: (queryParams: any) => {
            const queryParamArray: string[][] = []
            Object.entries(queryParams).forEach(([key, value]) => {
                queryParamArray.push([key, String(value)])
            })
            setQueryParams(queryParamArray)
        },
    }
    //** END CASE ACTIONS */

    return {
        caseViewActions,
        caseNetworkActions,
        casesLoading: loading,
    }
}

import { useSWRActions, SWRActions, useSwrData } from 'hooks/cb-api'
import useParams from 'hooks/cb-api/useParams'
import { useActiveMerchant } from 'components'
import { dateUtilities } from 'utils/dateUtilities'
import { format } from 'date-fns'
import { IDateRange } from 'components/PresetDateRange'

interface MidHealthVM {
    midHealthData: MidHealthData[]
    swrActions: SWRActions
}

export interface MidHealthData {
    rating: number
    gateway_id: number
    mid_group: any
    mid: string
    mid_alias: string
    processor: string
    transactions: number
    chargebacks: number
    ctr_ratio: string
    ethoca: number
    cdrn: number
    rdr: number
    order_insight: number
    consumer_clarity: number
    direct: number
    alert_count: number
    alert_trans_ratio: string
    alert_chargebacks_ratio: string
}

export const useMidHealth = (): MidHealthVM => {
    const { id: merchantId } = useActiveMerchant()
    const defaultDateRangeOnLoad: {
        to: Date
        from: Date
    } = dateUtilities.getPriorMonths()
    const { to, from } = defaultDateRangeOnLoad
    const presetDateRangeDate: IDateRange = {
        to: format(to, 'yyyy-MM-dd'),
        from: format(from, 'yyyy-MM-dd'),
    }
    const apiDateRange = `${presetDateRangeDate.from}|${presetDateRangeDate.to}`
    const { params, setQueryParams, setParams, reset } = useParams({date_range: apiDateRange})

    const endpointConfig = {
        paramInUrl: false,
        url: '/cm/cases/reports/mid-health',
        id: {
            key: 'merchant_id',
            value: merchantId,
        },
    }

    const midHealthData = useSwrData(endpointConfig, params)
    const swrActions = useSWRActions(midHealthData, {
        setParams,
        setQueryParams,
        params,
        reset,
    })

    return {
        midHealthData: midHealthData.data,
        swrActions,
    }
}

/**
 * @description Directory for ID Attributes on Merchant Settings.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    gridContainer: `merchantSettings-grid-container`,
    gridItem: `merchantSettings-grid-item`,
    selectSearchType: `merchantSettings-select-searchType`,
    textFieldSearch: `merchantSettings-textField-search`,
    ulMerchantTree: `merchantSettings-ul-merchantTree`,
    merchantNode: {
        divMerchant: `merchantNode-div-merchant`,
        listItemMid: `merchantNode-li-mid`,
        btnAddMerchant: `merchantNode-btn-addMerchant`,
        btnAddMid: `merchantNode-btn-addMid`,
        divEllipsis:  `merchantNode-div-ellipsis`,
    },
}

export default idDirectory

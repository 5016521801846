// @TODO transfer this into a database
import { faCreditCard, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import iconAccel from '../assets/images/processors/cc_icon-accel.svg'
import iconAffn from '../assets/images/processors/cc_icon-affn.svg'
import iconArc from '../assets/images/processors/cc_icon-arc.svg'
import iconCup from '../assets/images/processors/cc_icon-cup.svg'
import iconDinersclub from '../assets/images/processors/cc_icon-dinersclub.svg'
import iconDisc from '../assets/images/processors/cc_icon-disc.svg'
import iconJcb from '../assets/images/processors/cc_icon-jcb.svg'
import iconJeanie from '../assets/images/processors/cc_icon-jeanie.svg'
import iconMaestro from '../assets/images/processors/cc_icon-maestro.svg'
import iconMc from '../assets/images/processors/cc_icon-mc.svg'
import iconMcjapan from '../assets/images/processors/cc_icon-mcjapan.svg'
import iconNyce from '../assets/images/processors/cc_icon-nyce.svg'
import iconPaypal from '../assets/images/processors/cc_icon-paypal.svg'
import iconPulse from '../assets/images/processors/cc_icon-pulse.svg'
import iconShazam from '../assets/images/processors/cc_icon-shazam.svg'
import iconVisa from '../assets/images/processors/cc_icon-visa.svg'
// import iconDefault from 'assets/images/processors/cc_icon-default.svg'
// These dont fit the same pathing mold
import iconAmex from '../assets/images/processors/cc_icon-amex.svg'
import iconDc from '../assets/images/processors/icon-dc.svg'
import iconSepa from '../assets/images/processors/icon-sepa.svg'

// These are PNG icons, where an SVG version was unavailable for use
import pngIata from '../assets/images/processors/cc_icon_IATA.png'
import pngVisaDebit from '../assets/images/processors/cc_icon_VisaDebit.png'
import pngMCDebit from '../assets/images/processors/cc_icon_MasterCardDebit.png'
import pngChaseNet from '../assets/images/processors/icon-chase-net.png'

export interface Processor {
    name: string
    icon?: IconDefinition | string
    image?: string
    aliases?: string[]
}

/**
 * Used to ensure a known list of processors
 * @TODO Refactor this to a standard database map somehow later, not sure how to get the
 */
const standardProcessorMap: { [key: string]: Processor } = {
    default: {
        name: 'Credit Card Processor',
        icon: faCreditCard,
    },
    mastercard: {
        name: 'MasterCard',
        icon: iconMc,
    },
    mastercarddebit: {
        name: 'Mastercard Debit',
        image: pngMCDebit,
    },
    mastercardjapan: {
        name: 'MasterCard Japan',
        icon: iconMcjapan,
    },
    visa: {
        name: 'Visa',
        icon: iconVisa,
        aliases: ['VisaSpecial'],
    },
    visadebit: {
        name: 'Visa Debit',
        image: pngVisaDebit,
    },
    amex: {
        name: 'American Express',
        icon: iconAmex,
        aliases: ['amex', 'americanexpress'],
    },
    discover: {
        name: 'Discover',
        icon: iconDisc,
    },
    arc: {
        name: 'ARC',
        icon: iconArc,
    },
    iata: {
        name: 'IATA',
        image: pngIata,
    },
    accel: {
        name: 'Accel',
        icon: iconAccel,
    },
    affn: {
        name: 'AFFN',
        icon: iconAffn,
    },
    cup: {
        name: 'Union Pay',
        icon: iconCup,
        aliases: ['unionpay', 'cup'],
    },
    dinersclub: {
        name: 'Diners Club',
        icon: iconDinersclub,
    },
    jcb: {
        name: 'JCB',
        icon: iconJcb,
    },
    jeanie: {
        name: 'Jeanie',
        icon: iconJeanie,
    },
    maestro: {
        name: 'Maestro',
        icon: iconMaestro,
    },
    nyce: {
        name: 'NYCE',
        icon: iconNyce,
    },
    paypal: {
        name: 'Paypal',
        icon: iconPaypal,
    },
    pulse: {
        name: 'Pulse',
        icon: iconPulse,
    },
    shazam: {
        name: 'Shazam',
        icon: iconShazam,
    },
    dc: {
        name: 'DC',
        icon: iconDc,
    },
    sepa: {
        name: 'Sepa',
        icon: iconSepa,
    },
    chasedebit: {
        name: 'ChaseNet',
        image: pngChaseNet,
    },
}

export default standardProcessorMap

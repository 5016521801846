import React, { useEffect, useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
} from '@mui/material';
import { Link } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import {
    faCheckCircle,
    faTimesCircle,
    faTrash,
    faSave,
    faUndo,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import View from 'views/View'
import { AccentArea, AlertSnackbar, DataGridEditable } from 'components'
import { withRequiredRole } from 'components/useRequireRole'
import EntFeature from 'models/EntFeature'
import useEditOnBoarding from './EditOnboarding.vm'
import idDirectory from './idAttributes'
import OnboardingApi, { SelectOptions } from 'api/OnboardingApi'
import useIsMounted from 'hooks/utils/useIsMounted'
import { clientTheme } from 'theme-exports'

export interface SelectList {
    [key: string]: {
        list: SelectOptions[]
        text: string
    }
}

export const EditOnboarding = (props: any): React.ReactElement => {
    const onboardingId = props.match.params.onboardingId
    const { isMounted } = useIsMounted()
    const {
        uploadData,
        rows,
        columns,
        isLoadingData,
        isLoadingRequest,
        updateRecords,
        finalizeOnboarding,
        deleteOrUndoDeleteRecord,
        alertSnackbarMainProps,
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
    } = useEditOnBoarding(onboardingId)
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false)
    const [data, setData] = useState(rows)
    const [selectedRow, setSelectRow] = useState<number>(0)
    const [platformList, setPlatformList] = useState<SelectList>({
        platform: { list: [], text: '' },
    })
    const [serviceLevelList, setServiceLevelList] = useState<SelectList>({
        service_level: { list: [], text: '' },
    })

    useEffect(() => {
        rows.length && setData(rows)
    }, [rows])

    useEffect(() => {
        OnboardingApi.getServiceLevelList().then((data) => {
            if (isMounted) setServiceLevelList(data)
        })
        OnboardingApi.getPlatformList().then((data) => {
            if (isMounted) setPlatformList(data)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const updateMyData = (rowIndex: number, columnId: string, value: any) => {
        setData((old) =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    }
                }
                return row
            })
        )
    }

    const handleUpdateRecords = (e: any) => {
        const rowId: number = e.currentTarget.value
        setSelectRow(rowId)
        const currentRow = data[rowId]
        const refactoredRecords: any = {
            id: currentRow.id.value,
        }
        for (const key in currentRow) {
            refactoredRecords[key] = currentRow[key].value
        }
        updateRecords(onboardingId, refactoredRecords)
    }

    const handleDeleteRow = () => {
        const currentRow = data[selectedRow]
        const deleteRecords: any = {
            id: currentRow.id.value,
            status_id: 2,
        }
        deleteOrUndoDeleteRecord(onboardingId, deleteRecords)
        setDeleteOpen(false)
    }

    const handleUndoDeleteRow = (e: any) => {
        const rowId: number = e.currentTarget.value
        setSelectRow(rowId)
        const currentRow = data[rowId]
        const undoDeleteRecords: any = {
            id: currentRow.id.value,
            status_id: 1,
        }
        deleteOrUndoDeleteRecord(onboardingId, undoDeleteRecords)
    }

    const editColumns = [
        ...columns.filter(
            (col) => col.accessor !== 'id' && col.accessor !== 'status'
        ),
        {
            Header: 'Actions',
            accessor: 'action',
            Cell: ({ row }: any) => {
                const isDeleted = row?.original?.status?.value?.id === 2
                return (
                    <div className={'emp-actionContainer'}>
                        <div style={{ width: '40px' }}>
                            <IconButton
                                size="small"
                                onClick={(e) => handleUpdateRecords(e)}
                                value={row.id}
                                disabled={
                                    (isLoadingRequest &&
                                        selectedRow === row.id) ||
                                    isDeleted
                                        ? true
                                        : false
                                }
                                sx={{
                                    color: clientTheme.secondary,
                                    fontSize: '25px',
                                    '&:hover': {
                                        color: clientTheme.secondaryDark,
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                <Tooltip
                                    title={'Save & Validate Row'}
                                    placement="top"
                                    arrow
                                >
                                    <span>
                                        <Icon icon={faSave} />
                                    </span>
                                </Tooltip>
                            </IconButton>
                        </div>
                        <div style={{ width: '40px' }}>
                            {row.allCells?.some((cell: any) =>
                                Boolean(cell.value?.err_messages.length)
                            ) ? (
                                <Tooltip
                                    title={'Invalid Row'}
                                    placement="top"
                                    arrow
                                >
                                    <span>
                                        <Icon
                                            icon={faTimesCircle}
                                            className={
                                                isDeleted
                                                    ? 'emp-disabledIcon'
                                                    : 'emp-timesCircleIcon'
                                            }
                                        />
                                    </span>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={'Valid Row'}
                                    placement="top"
                                    arrow
                                >
                                    <span>
                                        <Icon
                                            icon={faCheckCircle}
                                            className={'emp-checkCircleIcon'}
                                        />
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                        <div style={{ width: '40px' }}>
                            {isDeleted ? (
                                <IconButton
                                    size="small"
                                    onClick={handleUndoDeleteRow}
                                    value={row.id}
                                    sx={{
                                        color: clientTheme.secondary,
                                        fontSize: '21px',
                                        '&:hover': {
                                            color: clientTheme.secondaryDark,
                                            cursor: 'pointer',
                                        },
                                    }}
                                >
                                    <Tooltip
                                        title={'Undelete Row'}
                                        placement="top"
                                        arrow
                                    >
                                        <span>
                                            <Icon icon={faUndo} />
                                        </span>
                                    </Tooltip>
                                </IconButton>
                            ) : (
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        setDeleteOpen(true)
                                        setSelectRow(
                                            Number(e.currentTarget.value)
                                        )
                                    }}
                                    className={'emp-trashIcon'}
                                    value={row.id}
                                >
                                    <Tooltip
                                        title={'Delete Row'}
                                        placement="top"
                                        arrow
                                    >
                                        <span>
                                            <Icon icon={faTrash} />
                                        </span>
                                    </Tooltip>
                                </IconButton>
                            )}
                        </div>
                    </div>
                )
            },
        },
    ]

    return (
        <View title="Edit Onboard" breadcrumb={'Edit Onboarding'}>
            <AccentArea
                title={
                    uploadData.title ? (
                        uploadData.title
                    ) : (
                        <Skeleton
                            variant="rectangular"
                            width={100}
                            height={20}
                        />
                    )
                }
                headerAction={
                    <>
                        <Tooltip
                            title={`${
                                uploadData?.total_failing_validation ?? ''
                            } Cell${
                                uploadData?.total_failing_validation === 1
                                    ? ''
                                    : 's'
                            } Failing Validation`}
                            placement="top"
                            arrow
                        >
                            <span>
                                <Button
                                    className={'emp-btn'}
                                    color="secondary"
                                    variant="contained"
                                    onClick={() =>
                                        finalizeOnboarding(onboardingId)
                                    }
                                    disabled={
                                        !uploadData?.is_valid ||
                                        isLoadingRequest
                                    }
                                    id={
                                        idDirectory.editOnboarding
                                            .btnFinalizeOnboarding
                                    }
                                >
                                    Finalize Onboarding
                                </Button>
                            </span>
                        </Tooltip>
                    </>
                }
                testId="editOnboarding"
            >
                <div>
                    <DataGridEditable
                        columns={editColumns}
                        data={data}
                        loading={isLoadingData}
                        updateMyData={updateMyData}
                        testId="editOnboarding"
                        selectOptions={{ ...platformList, ...serviceLevelList }}
                    />
                    <Dialog
                        open={deleteOpen}
                        className={'emp-modal'}
                        maxWidth="sm"
                        id={idDirectory.editOnboarding.dialogModal}
                    >
                        <DialogContent
                            classes={{
                                root: 'emp-dialogContent',
                            }}
                        >
                            <div className={'emp-exlamationIcon'}>
                                <span>!</span>
                            </div>

                            <DialogTitle className={'emp-dialogTitle'}>
                                <p>Delete Row</p>
                            </DialogTitle>
                            <div className={'emp-dialogContentText'}>
                                <p className={'emp-contentText'}>
                                    Do you want to delete this row?
                                </p>
                            </div>

                            <DialogActions className={'emp-dialogActions'}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    size="medium"
                                    onClick={handleDeleteRow}
                                    fullWidth
                                    id={idDirectory.editOnboarding.btnConfirm}
                                >
                                    Confirm
                                </Button>
                                <Button
                                    className={'emp-cancelBtn'}
                                    size="medium"
                                    variant="contained"
                                    onClick={() => setDeleteOpen(false)}
                                    fullWidth
                                    id={idDirectory.editOnboarding.btnCancel}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                </div>

                <div
                    style={{
                        marginTop: 20,
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Link to="/onboarding">
                        <Button
                            className={'emp-btn'}
                            color="secondary"
                            variant="contained"
                        >
                            Back
                        </Button>
                    </Link>
                </div>
            </AccentArea>
            <AlertSnackbar
                content={alertSnackbarMainProps}
                open={alertSnackbarMainOpen}
                onClose={() => setAlertSnackbarMainOpen(false)}
                showCloseIcon
            />
        </View>
    )
}

export default withRequiredRole(EntFeature.ONBOARDING_LEGACY, EditOnboarding)

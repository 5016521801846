import React, { useState } from 'react'
import ReportsApi from '../../../../api/ReportsApi'
import PieChart from 'components/PieChart/PieChart'
import { IReportFilter } from 'views/Reports'
import { clientTheme } from 'theme-exports'

/**
 * @name ByCardType
 * @desc Render Card Type chart
 * @filterValues IReportsFilter
 */
const ByCardType = ({
    filterValues,
}: {
    filterValues: IReportFilter
}): React.ReactElement => {
    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<{ name: string; value: number }[]>([])

    React.useEffect(() => {
        setLoading(true)
        ReportsApi.getByCardType(filterValues).then((data) => {
            setLoading(false)
            setData(data)
        })
    }, [filterValues]) // eslint-disable-line react-hooks/exhaustive-deps

    const byCardTypeColorPalette = clientTheme.charts.pieCharts.byCardTypePieChart

    return (
        <PieChart
            colorPalette={byCardTypeColorPalette}
            chartTitle="Card Type"
            data={data}
            loading={loading}
            testId="byCardType"
        />
    )
}

export default ByCardType

import React from 'react'
import {
    Popover,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    CircularProgress,
} from '@mui/material'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'

type TPopoverPosition = {
    horizontal: 'center' | 'left' | 'right' | number
    vertical: 'bottom' | 'center' | 'top' | number
}

export interface IStandardPopoverProps {
    anchorEl: null | HTMLElement
    onPopoverClose: () => void
    popoverHeaderTitle: string
    children: React.ReactNode
    testId: string
    popoverWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | number
    anchorOrigin?: TPopoverPosition
    transformOrigin?: TPopoverPosition
    disableScrollLock?: boolean
    enableErrorTitleStyling?: boolean
    showPrimaryActionBtn?: boolean
    primaryBtnText?: string
    handlePrimaryActionBtn?: () => void
    disablePrimaryActionBtn?: boolean
    isLoadingPrimaryActionBtn?: boolean
    showSecondaryActionBtn?: boolean
    secondaryBtnText?: string
    handleSecondaryActionBtn?: () => void
    disableSecondaryActionBtn?: boolean
    isLoadingSecondaryActionBtn?: boolean
    className?: string
}

const StandardPopover = ({
    anchorEl,
    onPopoverClose,
    popoverHeaderTitle,
    children,
    testId,
    popoverWidth = 'md',
    anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'center',
    },
    disableScrollLock = false,
    enableErrorTitleStyling = false,
    showPrimaryActionBtn = true,
    primaryBtnText = 'Submit',
    handlePrimaryActionBtn = () => {},
    disablePrimaryActionBtn = false,
    isLoadingPrimaryActionBtn = false,
    showSecondaryActionBtn = true,
    secondaryBtnText = 'Cancel',
    handleSecondaryActionBtn = () => {},
    disableSecondaryActionBtn = false,
    isLoadingSecondaryActionBtn = false,
    className = '',
}: IStandardPopoverProps): React.ReactElement => {
    const popoverSize: { [key: string]: string } = {
        lg: '1200px',
        md: '900px',
        sm: '600px',
        xl: '1535px',
        xs: '445px',
    }

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onPopoverClose}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            className={`emp-standardPopover-root ${className}`}
            disableScrollLock={disableScrollLock}
            id={`${idDirectory.divRoot}-${testId}`}
        >
            <div style={{ width: popoverSize[popoverWidth] ?? popoverWidth }}>
                <DialogTitle
                    className={`${'emp-standardPopover-dialogTitle'} ${
                        enableErrorTitleStyling &&
                        'emp-standardPopover-errorDialogTitle'
                    }`}
                    id={`${idDirectory.divTitle}-${testId}`}
                >
                    {popoverHeaderTitle}
                    <FontAwesomeIcon
                        icon={faTimes}
                        className={'emp-standardPopover-popoverClose'}
                        onClick={onPopoverClose}
                    />
                </DialogTitle>
                <DialogContent
                    className={'emp-standardPopover-dialogContent'}
                    id={`${idDirectory.divContent}-${testId}`}
                >
                    {children}
                </DialogContent>
                {(showPrimaryActionBtn || showSecondaryActionBtn) && (
                    <DialogActions
                        className={'emp-standardPopover-dialogActions'}
                        id={`${idDirectory.divActions}-${testId}`}
                    >
                        {showSecondaryActionBtn && (
                            <Button
                                variant="contained"
                                sx={clientTheme.buttons.defaultButton.style}
                                onClick={handleSecondaryActionBtn}
                                id={`${idDirectory.btnSecondary}-${testId}`}
                                disabled={disableSecondaryActionBtn}
                            >
                                {isLoadingSecondaryActionBtn ? (
                                    <>
                                        Submitting...{' '}
                                        <CircularProgress
                                            className={
                                                'emp-standardPopover-circularProgress'
                                            }
                                            color="secondary"
                                        />
                                    </>
                                ) : (
                                    secondaryBtnText
                                )}
                            </Button>
                        )}
                        {showPrimaryActionBtn && (
                            <Button
                                variant="contained"
                                onClick={handlePrimaryActionBtn}
                                color={'secondary'}
                                id={`${idDirectory.btnPrimary}-${testId}`}
                                disabled={disablePrimaryActionBtn}
                            >
                                {isLoadingPrimaryActionBtn ? (
                                    <>
                                        Submitting...{' '}
                                        <CircularProgress
                                            className={
                                                'emp-standardPopover-circularProgress'
                                            }
                                            color="secondary"
                                        />
                                    </>
                                ) : (
                                    primaryBtnText
                                )}
                            </Button>
                        )}
                    </DialogActions>
                )}
            </div>
        </Popover>
    )
}

export default StandardPopover

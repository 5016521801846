/**
 * @description Directory for ID Attributes on Popover Select.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `popoverSelect-div-root`,
}

export default idDirectory

import React, { useState, useEffect } from 'react'
import { Button, Chip } from '@mui/material'
import { clientTheme } from 'theme-exports'
import { Searchbar } from 'components'
import {
    AGDataGridActionsExports,
    AGDataGridActionsFilter,
    AGDataGridActionsColumnFilter,
} from './index'
import { IFilterFormField } from './AGDataGridActionsFilter'
import idDirectory from './idAttributes'

interface AGDataGridActionsBarProps {
    testId: string
    showDataGridSearch?: boolean
    searchBarPlaceholderText?: string
    handleReturnSearchValue?: (value: string) => void
    showDataGridFilter?: boolean
    filterFormFields?: IFilterFormField[]
    filterValues?: { [key: string]: string | number | undefined }
    setFilterValues?: (filter: {
        [key: string]: string | number | undefined
    }) => void
    showDataGridColumnFilter?: boolean
    gridRef?: any
    gridName?: string
    showCsvExport?: boolean
    onExportCsv?: () => void
    showExcelExport?: boolean
    onExportExcel?: () => void
    disableAllDataGridActions?: boolean
    additionalContent?: React.ReactNode
    className?: string
}

const AGDataGridActionsBar = ({
    testId,
    showDataGridSearch = true,
    searchBarPlaceholderText = 'Search...',
    handleReturnSearchValue = () => {},
    showDataGridFilter = true,
    filterFormFields = [],
    filterValues = {},
    setFilterValues = () => {},
    showDataGridColumnFilter = true,
    gridRef,
    gridName = '',
    showCsvExport = true,
    onExportCsv = () => {},
    showExcelExport = true,
    onExportExcel = () => {},
    disableAllDataGridActions = false,
    additionalContent = null,
    className = '',
}: AGDataGridActionsBarProps): React.ReactElement => {
    const [anchorElFilter, setAnchorElFilter] = useState<null | HTMLElement>(
        null
    )
    const [
        anchorElColumnFilter,
        setAnchorElColumnFilter,
    ] = useState<null | HTMLElement>(null)

    const accessorLabelObj: { [key: string]: string } = {}
    filterFormFields.forEach((field) => {
        accessorLabelObj[field.accessor] = field.label
    })

    const handleClickDataGridFilter = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorElFilter(event.currentTarget)
    }
    const handleCloseDataGridFilter = () => {
        setAnchorElFilter(null)
    }

    const handleClickDataGridColumnFilter = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setAnchorElColumnFilter(event.currentTarget)
    }
    const handleCloseDataGridColumnFilter = () => {
        setAnchorElColumnFilter(null)
    }

    const handleDeleteFilterTag = (accessor: string) => {
        setFilterValues({
            ...filterValues,
            [accessor]: undefined,
        })
    }

    useEffect(() => {
        return () => {
            handleReturnSearchValue('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            id={`${idDirectory.divRoot}-${testId}`}
            className={`${'emp-agDataGridActionsBar-root'} ${className}`}
        >
            <div className={'emp-agDataGridActionsBar-searchContainer'}>
                {showDataGridSearch && (
                    <Searchbar
                        className={'emp-agDataGridActionsBar-searchInput'}
                        debounceDelay={200}
                        returnValue={handleReturnSearchValue}
                        testId={testId}
                        disabled={disableAllDataGridActions}
                        placeholder={searchBarPlaceholderText}
                    />
                )}
                {showDataGridFilter && !disableAllDataGridActions && (
                    <div
                        className={
                            'emp-agDataGridActionsBar-filterTagContainer'
                        }
                        id={`${idDirectory.divFilterTags}-${testId}`}
                    >
                        {Object.entries(filterValues).map((entry, idx) => {
                            const [accessor, value] = entry
                            let tagValue = value
                            if (!value) return null
                            const selectFilterField = filterFormFields.find(
                                (filterField) =>
                                    filterField.accessor === accessor &&
                                    filterField.inputType === 'select'
                            )
                            if (selectFilterField) {
                                tagValue = selectFilterField.selectList?.find(
                                    (option) => option.value === value
                                )?.name
                            }
                            return (
                                <Chip
                                    key={`key-${accessor}-${idx}`}
                                    className={
                                        'emp-agDataGridActionsBar-filterTag'
                                    }
                                    style={{
                                        fontSize:
                                            clientTheme.typography.body1
                                                .fontSize,
                                    }}
                                    label={`${
                                        accessorLabelObj[accessor] ?? accessor
                                    }: ${tagValue}`}
                                    size="small"
                                    onDelete={() =>
                                        handleDeleteFilterTag(accessor)
                                    }
                                />
                            )
                        })}
                    </div>
                )}
            </div>
            <div className={'emp-agDataGridActionsBar-actionBtnsContainer'}>
                {showDataGridFilter && (
                    <Button
                        className={'emp-agDataGridActionsBar-btn'}
                        sx={{
                            ...clientTheme.buttons.textOrOutlinedButton.style,
                            fontSize: clientTheme.typography.button2.fontSize,
                        }}
                        onClick={handleClickDataGridFilter}
                        id={`${idDirectory.btnFilter}-${testId}`}
                        disabled={disableAllDataGridActions}
                    >
                        Filter
                    </Button>
                )}
                {showDataGridColumnFilter && (
                    <Button
                        className={'emp-agDataGridActionsBar-btn'}
                        sx={{
                            ...clientTheme.buttons.textOrOutlinedButton.style,
                            fontSize: clientTheme.typography.button2.fontSize,
                        }}
                        onClick={handleClickDataGridColumnFilter}
                        id={`${idDirectory.btnColumnFilter}-${testId}`}
                        disabled={disableAllDataGridActions}
                    >
                        Columns
                    </Button>
                )}
                {(showCsvExport || showExcelExport) && (
                    <AGDataGridActionsExports
                        testId={testId}
                        showCsvExport={showCsvExport}
                        onExportCsv={onExportCsv}
                        showExcelExport={showExcelExport}
                        onExportExcel={onExportExcel}
                        disableAllDataGridActions={disableAllDataGridActions}
                    />
                )}
                {additionalContent && (
                    <div id={`${idDirectory.divAdditionalContent}-${testId}`}>
                        {additionalContent}
                    </div>
                )}
            </div>

            <AGDataGridActionsFilter
                anchorEl={anchorElFilter}
                handleCloseDataGridFilter={handleCloseDataGridFilter}
                filterFormFields={filterFormFields}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                testId={testId}
            />

            <AGDataGridActionsColumnFilter
                anchorEl={anchorElColumnFilter}
                handleCloseDataGridColumnFilter={
                    handleCloseDataGridColumnFilter
                }
                gridRef={gridRef}
                gridName={gridName}
                testId={testId}
            />
        </div>
    )
}

export default AGDataGridActionsBar

/**
 * @description Directory for ID Attributes on Add Comment Modal.
 * Naming Convention: "data-test-id-[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "data-test-id-mainlayout-btn-cancel".
 */
const idDirectory = {
    dialogMain: `addCommentModal-dialog-main`,
    dialogContent: `addCommentModal-dialog-content`,
    textTitle: `addCommentModal-text-title`,
    textSubtitle: `addCommentModal-text-subtitle`,
    inputTextField: `addCommentModal-input-textField`,
    btnCancel: `addCommentModal-btn-cancel`,
    btnSubmit: `addCommentModal-btn-submit`,
}

export default idDirectory

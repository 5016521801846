import MuiButton from './MuiButton'
import MuiPaper from './MuiPaper'
import MuiTableCell from './MuiTableCell'
import MuiTableHead from './MuiTableHead'

export default {
    MuiButton,
    MuiPaper,
    MuiTableCell,
    MuiTableHead,
}

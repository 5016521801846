import React from 'react'
import {
    InputLabel,
    TextField,
    Select as MuiSelect,
    Autocomplete,
} from '@mui/material'
import idDirectory from './idAttributes'

/**
 * Convenience type; MUI select onChagne event provides event
 * containing `{ name, value }` key-value pair.
 */
type ChangeEventHandler = (
    e: React.ChangeEvent<{
        [key: string]: string | number
    }>
) => void

interface IDatePickerProps {
    label: string
    defaultValue: string
    handleChange: DatepickerChangeEventHandler
    className: string
    hasDisplayLabel: boolean
    id?: string
}

type DatepickerChangeEventHandler = (
    e: React.ChangeEvent<{
        name: string
        value: string | number
    }>
) => void

export interface IPickerProps {
    className?: string
    label?: string
    handleChange: ChangeEventHandler
    options: {
        [key: string]: string | number
    }[]
    value: number | string
    testId?: string
    isOutlined?: boolean
    border?: string
}

export interface IOptionList {
    name: string
    id: number | string
}

/**
 * A single select picker of options.
 */
const Picker = ({
    className,
    label = '',
    handleChange,
    value = '',
    options = [],
    isOutlined = true,
    testId,
    border = '',
}: IPickerProps): React.ReactElement => {
    const hc = (i: any) => {
        handleChange(i)
    }

    return (
        <div
            id={`${idDirectory.divRoot}-${testId}`}
            className={`emp-picker-root ${className}`}
            style={{ border: border }}
        >
            {label && (
                <InputLabel
                    className={'emp-picker-label'}
                    id={`${idDirectory.inputLabel}-${testId}`}
                >
                    ${label}:
                </InputLabel>
            )}
            <MuiSelect
                id={`${idDirectory.select}-${testId}`}
                variant={isOutlined ? 'outlined' : 'standard'}
                sx={[!isOutlined && {
                    '&&&:before': {
                        borderBottom: 'none',
                    },
                    '&&:after': {
                        borderBottom: 'none',
                    },
                }]}
                className={'emp-picker-picker'}
                value={value}
                onChange={hc}
                native
            >
                {options.map((i) => (
                    <option
                        id={`${idDirectory.option}-${testId}-${i.value}`}
                        key={i.value}
                        value={i.value}
                        style={{ textOverflow: 'inherit' }}
                    >
                        {i.name}
                    </option>
                ))}
            </MuiSelect>
        </div>
    )
}

interface IAutocompleteProps {
    className?: string
    id?: string | number
    label: string
    variant?: 'filled' | 'outlined' | 'standard' | undefined
    handleChange: (value: any) => void
    optionList: IOptionList[]
    defaultValue: string
}

/**
 * An autocomplete search of options. MUI Autocomplete does not reuire a value prop - getOptionLabel replaces that
 */
export const SearchPicker = ({
    className = '',
    label,
    variant = undefined,
    handleChange,
    optionList,
}: IAutocompleteProps): React.ReactElement => {
    return (
        <Autocomplete
            className={className}
            options={optionList}
            getOptionLabel={(option: IOptionList) => option.name}
            onChange={(_e, value) => handleChange(value)}
            renderInput={(params: any) => (
                <TextField {...params} label={label} variant={variant} />
            )}
        />
    )
}

/**
 * Use to display a monthly calendar to select a single day from for a textfield
 * @deprecated Migrate this to ds-core
 */
export const DatePick = ({
    className,
    label,
    handleChange,
    hasDisplayLabel = true,
    defaultValue,
}: IDatePickerProps): React.ReactElement => {
    return (
        <div className={`emp-picker-root ${className}`} aria-label={label}>
            {hasDisplayLabel ? (
                <InputLabel
                    className={'emp-picker-label'}
                >{`${label}:`}</InputLabel>
            ) : null}

            <TextField
                className={`emp-picker-dateInput`}
                id="date"
                label={label}
                type="date"
                defaultValue={defaultValue}
                onChange={handleChange}
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
            />
        </div>
    )
}

export default Picker

import React from 'react'
import { ValueFormatterParams } from 'ag-grid-community'
import { AGDataGrid } from 'components'
import { ExtendedColumn } from 'components/DataGrid/ExtendedColumn'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'

interface MidTop5DataGridProps {
    testId: string
    gridRef: any
    dataSource: any
    isLoading: boolean
    paginationTotal: number
}

export const MidTop5DataAGGrid: React.FC<MidTop5DataGridProps> = ({
    testId,
    gridRef,
    dataSource,
    isLoading,
    paginationTotal,
}) => {
    const { client } = useFeatureToggle('CLIENT')

    const valueFormatter = (params: ValueFormatterParams) => {
        return Number(params.value).toLocaleString('en-US')
    }

    const columns: ExtendedColumn[] = [
        {
            Header: 'MID',
            accessor: client === 'bluesnap' ? 'midAlias' : 'mid',
            type: 'string',
            minWidth: 110,
        },
        {
            Header: 'Merchant Descriptor',
            accessor: 'midDescriptor',
            type: 'string',
            minWidth: 120,
        },
        {
            Header: 'MID Alias',
            accessor: 'midAlias',
            type: 'string',
            minWidth: 110,
        },
        {
            Header: 'New',
            accessor: 'new',
            type: 'number',
            format: valueFormatter,
        },
        {
            Header: 'Non-fraud',
            accessor: 'nonFraud',
            type: 'number',
            format: valueFormatter,
            minWidth: 110,
        },
        {
            Header: '% Non-fraud',
            accessor: 'percentNonFraud',
            type: 'number',
            minWidth: 125,
        },
        {
            Header: 'Fraud',
            accessor: 'fraud',
            type: 'number',
            format: valueFormatter,
            minWidth: 90,
        },
        {
            Header: '% Fraud',
            accessor: 'percentFraud',
            type: 'number',
            minWidth: 110,
        },
        {
            Header: 'Defended',
            accessor: 'defended',
            type: 'number',
            format: valueFormatter,
            minWidth: 110,
        },
    ]

    const defaultColDef = {
        flex: 1,
        minWidth: 80,
        resizable: true,
    }

    return (
        <div id={'dashboardMidTop5DataAGGrid'}>
            <AGDataGrid
                gridRef={gridRef}
                gridName={'top5Mid'}
                fileName={'top-5-mid'}
                columns={columns}
                dataSource={dataSource}
                loading={isLoading}
                testId={testId}
                totalEntries={paginationTotal}
                defaultColDef={defaultColDef}
                rowSelectionType={'single'}
                disableRowClickSelection
                paginate={false}
            />
        </div>
    )
}

/**
 * @description Directory for ID Attributes on SearchableSelect.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    inputSearch: `searchableSelect-input-search`,
    selectOption: `searchableSelect-select-option`,
    divLoading: `searchableSelect-div-loading`,
    divPagination: `searchableSelect-div-pagination`,
    btnPrevious: `searchableSelect-btn-previous`,
    btnNext: `searchableSelect-btn-next`,
    selectRowsPerPage: `searchableSelect-select-rowsPerPage`,
    textPaginationInfo: `searchableSelect-text-paginationInfo`,
}

export default idDirectory

import React, { useEffect, useState, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import Connect from 'api/Connect'
import {
    AccentArea,
    MerchantSwitcher,
    Metric,
    PresetDateRange,
    useActiveMerchant,
} from 'components'
import idDirectory from './idAttributes'
import { IDateRange } from 'components/PresetDateRange'
import { withRequiredRole } from 'components/useRequireRole'
import { format } from 'date-fns'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import CB from 'lib'
import EntFeature from 'models/EntFeature'
import { dateUtilities } from 'utils/dateUtilities'
import View from 'views/View'
import ChargebacksByCardType from './charts/ChargebacksByCardType'
import { ChargebacksByPostDate } from './charts/ChargebacksByPostDate'
import { ChargebacksByStatusPie } from './charts/ChargebacksByStatus'
import { MidTop5DataGrid } from './grids/MidTop5DataGrid'
import { MidTop5DataAGGrid } from './grids/MidTop5DataAGGrid'
import { getMetrics } from './metrics_config/metrics-config'
import { iconsTheme } from 'theme-exports'
import Icon from 'components/Icon'
import { useAGDataGridActions } from 'hooks/useAGDataGridActions/useAGDataGridActions'

interface Top5MidData {
    mid: number | string
    merchantIdentifier: number | string
    midAlias: string
    newCases: number | string
    nonFraud: number | string
    percentNonFraud: number | string
    fraud: number | string
    percentFraud: number | string
    defended: number | string
}

interface Top5MidState {
    data: Top5MidData[]
    date_range?: string
    loading: boolean
    error: boolean | Record<string, string | number>
}

export interface CardType {
    case_status: string
    count: number
}

interface ChargebacksByCardTypeState {
    loading: boolean
    date_range: string
    payload: {
        cardTypes: any
    }
}

interface ChargebacksByStatusType {
    loading: boolean
    date_range: string
    payload: {
        caseStatusData: CardType[]
    }
}

interface ChargebackByPostDate {
    date: string
    count: string
}

export interface ChargebacksByPostDateProps {
    chartData: ChargebackByPostDate[]
    loading: boolean
}

interface ChargebacksByPostDateState {
    loading: boolean
    date_range: string
    payload: {
        chargebacks: ChargebackByPostDate[]
    }
}

interface IMetric {
    metric: string | number
    decorator: null | string
    title: string
    timeframe: string
    link: {
        to: string
        text: string
    }
    intent: 'warning' | 'success' | 'error' | 'info'
    icon: any
    backgroundColor?: string
    borderTopColor?: string
    variant: 'default' | 'alt'
}

interface FormattedMetricDataState {
    loading: boolean
    error: boolean | Record<string, string | number>
    metricData: IMetric[]
    [key: string]: any
}

/** DEFAULT DATE RANGES FOR INPUTS AND API CALLS ONLOAD  */
// date_range default is for the last 7 days
const defaultDateRangeOnLoad: {
    to: Date
    from: Date
} = dateUtilities.getPriorDays(90) // change this to change the default date range (ref: dateUtilities.ts)
const { to, from } = defaultDateRangeOnLoad
const PresetDateRangeDate: IDateRange = {
    to: format(to, 'yyyy-MM-dd'),
    from: format(from, 'yyyy-MM-dd'),
}

const getNext72Hr = () => {
    const d = dateUtilities.getNextDays(3)
    return `${format(d.from, 'yyyy-MM-dd')}|${format(d.to, 'yyyy-MM-dd')}`
}
// default api call date range on load
const initialApiDateRange = `${PresetDateRangeDate.from}|${PresetDateRangeDate.to}`

/** The Dashboard view with it's graphs */
export const Dashboard = () => {
    const testId = 'dashboard'
    const { id } = useActiveMerchant()
    const { title_icon: titleIconEnabled } = useFeatureToggle('ACCENT_AREA')
    // const { setShowNewDataGrid, showNewDataGrid } = useAuthedUser()
    const { showNewDataGrid } = useAuthedUser()
    const top5MidGridRef = useRef<AgGridReact>(null)

    // TODO - replace this with the above merchant context once that is fully working.
    const [top5MidData, setTop5MidData] = useState<Top5MidState>({
        data: [],
        loading: true,
        error: false,
        date_range: initialApiDateRange,
    })
    // Use to run API call even if same date range given for Top 5 Mids
    const [runTop5Mids, setRunTop5Mids] = useState(false)

    // Needed for Top 5 Mid AG Grid
    const filterValuesMemo = useMemo(() => {
        return {
            client_id: id,
            limit: undefined,
            start: undefined,
            sort_order: undefined,
            date_range: top5MidData.date_range,
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, top5MidData.date_range])

    const { dataSource, isLoading, paginationTotal } = useAGDataGridActions(
        '/cm/cases/reports/mids-top5',
        top5MidGridRef,
        +id,
        filterValuesMemo,
        {}
    )

    // once all raw metric data is loaded it is formatted into an array and data shape that works with Metric
    const [
        formattedMetricData,
        setFormattedMedtricData,
    ] = useState<FormattedMetricDataState>({
        loading: true, //loading intially awaiting metric data
        error: false,
        metricData: [], // needs initial data to show loading
    })
    // chargeback by card type data 'default is to return one years worth for now'
    const [
        caseStatusData,
        setCaseStatusData,
    ] = useState<ChargebacksByStatusType>({
        loading: true,
        date_range: initialApiDateRange,
        payload: {
            caseStatusData: [],
        },
    })

    const [
        cardTypeData,
        setCardTypeData,
    ] = useState<ChargebacksByCardTypeState>({
        loading: true,
        date_range: initialApiDateRange,
        payload: {
            cardTypes: [],
        },
    })

    const [
        chargebacksByPostDate,
        setChargebacksByPostDate,
    ] = useState<ChargebacksByPostDateState>({
        loading: true,
        date_range: initialApiDateRange,
        payload: {
            chargebacks: [],
        },
    })

    // Use to run API call even if same date range given for CB by Post Date
    const [runCbByPostDate, setRunCbByPostDate] = useState(false)

    // Ahhh.. so many endpoints. All for the Metrics.
    const getCasesMtd = React.useCallback(async (): Promise<any> => {
        if (!id) return
        try {
            const casesMtd = await Connect.query(
                { name: 'casesMtd' },
                {
                    method: 'GET',
                    params: {
                        client_id: id,
                    },
                }
            )

            return {
                accessor: 'chargebacks',
                payload: casesMtd,
            }
        } catch (e) {
            return e
        }
    }, [id])

    const getFraudulentCasePct = React.useCallback(async (): Promise<any> => {
        if (!id) return
        try {
            const monthDateObj = dateUtilities.getMonth()
            const fraudPct = (await Connect.query(
                { name: 'casesFraudPct' },
                {
                    method: 'GET',
                    params: {
                        client_id: id,
                        date_range: `${monthDateObj.from}|${monthDateObj.to}`,
                    },
                }
            )) as { ratio: string }

            return {
                accessor: 'caseFraudPercent',
                // TODO - this is a hack to get the data into the right shape.
                payload: {
                    ratio: `${fraudPct.ratio.split('.')[0].replace('%', '')}%`,
                },
            }
        } catch (e) {
            return e
        }
    }, [id])

    const getChargebacksExpiring = React.useCallback(async () => {
        if (!id) return
        try {
            const res = await CB.cases.list({
                client_id: id,
                status_group_id: '1,2',
                limit: '0',
                due_date_range: getNext72Hr(),
            })

            return {
                accessor: 'chargebacksExpiring',
                payload: res.pagination.total,
            }
        } catch (error) {
            return error
        }
    }, [id])

    const getNewChargebacks = React.useCallback(async () => {
        if (!id) return
        try {
            const res = await CB.cases.list({
                client_id: id,
                status_group_id: '1',
                limit: '0',
            })

            return {
                accessor: 'newChargebacks',
                payload: res.pagination.total,
            }
        } catch (error) {
            return error
        }
    }, [id])

    const getMidCount = React.useCallback(async (): Promise<any> => {
        if (!id) return
        try {
            const midCount = await Connect.query(
                { name: 'midCount' },
                {
                    method: 'GET',
                    params: {
                        client_id: id,
                        date_range: initialApiDateRange,
                    },
                }
            )

            return {
                accessor: 'mids',
                payload: midCount,
            }
        } catch (e) {
            return e
        }
    }, [id])

    const getUsersCount = React.useCallback(async () => {
        if (!id) return
        try {
            const res: any = await Connect.query(
                { name: 'users' },
                {
                    method: 'GET',
                    params: {
                        merchant_id: id,
                        limit: '0',
                    },
                }
            )

            return {
                accessor: 'usersCount',
                payload: res.pagination?.total,
            }
        } catch (error) {
            return error
        }
    }, [id])

    // updates chargebacks by post date range when selecting apply in the preset date range select
    const updateCbByPostDateRange = (date_range: string) => {
        setChargebacksByPostDate({
            ...chargebacksByPostDate,

            date_range,
        })
        setRunCbByPostDate(!runCbByPostDate)
    }

    // updates top 5 mid date range
    const updateTop5MidsDateRange = (date_range: string) => {
        setTop5MidData((prevState) => ({
            ...prevState,
            date_range,
        }))
        setRunTop5Mids(!runTop5Mids)
    }

    const returnMetricEndpoints = React.useCallback(async (): Promise<any> => {
        try {
            const allEndpoints = await Promise.all([
                getCasesMtd(),
                getFraudulentCasePct(),
                getChargebacksExpiring(),
                getNewChargebacks(),
                getMidCount(),
                getUsersCount(),
            ])

            return allEndpoints
        } catch (e) {
            return e
        }
    }, [
        getCasesMtd,
        getFraudulentCasePct,
        getChargebacksExpiring,
        getNewChargebacks,
        getMidCount,
        getUsersCount,
    ])

    // // call each metric endpoint and return them all at once
    useEffect(() => {
        setFormattedMedtricData((prevState) => ({
            ...prevState,
            loading: true,
        }))
        if (id) {
            returnMetricEndpoints()
                .then((res: any[]) => {
                    let metricObject: any = {}
                    // loop over each data set in rawMetricData to create an object with
                    res.forEach((dataset: any) => {
                        if (dataset.accessor === 'chargebacks') {
                            metricObject = {
                                ...metricObject,
                                chargebacks: {
                                    metric: dataset.payload.count,
                                },
                            }
                        }
                        if (dataset.accessor === 'caseFraudPercent') {
                            metricObject = {
                                ...metricObject,
                                caseFraudPercent: {
                                    metric: dataset.payload.ratio,
                                },
                            }
                        }
                        if (dataset.accessor === 'chargebacksExpiring') {
                            metricObject = {
                                ...metricObject,
                                chargebacksExpiring: {
                                    metric: dataset.payload,
                                },
                            }
                        }
                        if (dataset.accessor === 'newChargebacks') {
                            metricObject = {
                                ...metricObject,
                                newChargebacks: {
                                    metric: dataset.payload,
                                },
                            }
                        }
                        if (dataset.accessor === 'mids') {
                            metricObject = {
                                ...metricObject,
                                mids: {
                                    metric: dataset.payload.count,
                                },
                            }
                        }
                        if (dataset.accessor === 'usersCount') {
                            metricObject = {
                                ...metricObject,
                                usersCount: {
                                    metric: dataset.payload,
                                },
                            }
                        }
                    })

                    setFormattedMedtricData({
                        error: false,
                        loading: false,
                        metricData: metricObject,
                    })
                })
                .catch((err) => {
                    setFormattedMedtricData({
                        metricData: [],
                        loading: false,
                        error: err,
                    })
                })
        }
    }, [id, returnMetricEndpoints])

    // // call top 5 mid chart data
    useEffect(() => {
        setTop5MidData((prevState) => ({
            ...prevState,
            loading: true,
        }))
        if (id) {
            Connect.query(
                { name: 'midTop5' },
                {
                    method: 'GET',
                    params: {
                        client_id: id,
                        date_range:
                            top5MidData.date_range ?? initialApiDateRange,
                    },
                }
            )
                .then((res: any) => {
                    const updatedData = res.map((mid: Top5MidData) => ({
                        ...mid,
                        // Duplicating 'midAlias' since Bluesnap requires 2 columns with same mapping - to prevent grid from timing out.
                        midAlias2: mid.midAlias,
                    }))
                    setTop5MidData((prev) => ({
                        ...prev,
                        loading: false,
                        error: false,
                        data: updatedData,
                    }))
                })
                .catch((error) => {
                    setTop5MidData((prev) => ({
                        ...prev,
                        loading: false,
                        error,
                        data: [],
                    }))
                })
        }
    }, [top5MidData.date_range, id, runTop5Mids])

    // // call chargebacks by post date
    useEffect(() => {
        setChargebacksByPostDate((prevState) => ({
            ...prevState,
            loading: true,
        }))
        if (id) {
            Connect.query(
                { name: 'chargebacksByPostDate' },
                {
                    method: 'GET',
                    params: {
                        client_id: id,
                        date_range: chargebacksByPostDate.date_range,
                    },
                }
            )
                .then((res: any) =>
                    setChargebacksByPostDate((prevState) => ({
                        ...prevState,
                        loading: false,
                        payload: {
                            chargebacks: res,
                        },
                    }))
                )
                .catch((e) => e)
        }
    }, [chargebacksByPostDate.date_range, id, runCbByPostDate]) // recalls api anytime date_range or client_id is updated if we want to integrate some sort of date range / merchant switcher selection later

    // // call chargebacks by card type
    useEffect(() => {
        setCaseStatusData((prevState) => ({
            ...prevState,
            loading: true,
        }))
        if (id) {
            CB.reports
                .chargebacksByStatus(id, {
                    date_range: caseStatusData.date_range,
                })
                .then((r) =>
                    setCaseStatusData((prevState) => ({
                        ...prevState,
                        loading: false,
                        payload: {
                            // @ts-ignore
                            caseStatusData: r,
                        },
                    }))
                )
                .catch((e) => e)
        }
    }, [caseStatusData.date_range, id])

    useEffect(() => {
        setCardTypeData((prevState) => ({
            ...prevState,
            loading: true,
        }))
        if (id) {
            const monthDateObj = dateUtilities.getMonth()
            CB.reports
                .chargebacksByCardType(id, {
                    date_range: `${monthDateObj.from}|${monthDateObj.to}`,
                    name: 'Visa,Mastercard,Discover,Amex',
                })
                .then((r) =>
                    setCardTypeData((prevState) => ({
                        ...prevState,
                        loading: false,
                        payload: {
                            // @ts-ignore
                            cardTypes: r,
                        },
                    }))
                )
                .catch((e) => e)
        }
    }, [caseStatusData.date_range, id])

    // Pass in some template view data up front

    const { METRICS, CHARTS } = useFeatureToggle('DASHBOARD')

    const metrics = getMetrics(METRICS.metric_variant)

    // const handleSwitchGrids = () => {
    //     setShowNewDataGrid && setShowNewDataGrid(!showNewDataGrid)
    // }

    return (
        <>
            <View
                title="Dashboard"
                breadcrumb="Dashboard"
                HeaderInterior={<MerchantSwitcher testId={testId} />}
                testId={testId}
            >
                <div id={idDirectory.divGrid} className={'emp-grid'}>
                    {METRICS.enabled
                        ? Object.entries(metrics).map(([key, metric]: any) => (
                              <div
                                  id={`${idDirectory.divMetric}-${key}`}
                                  key={`${key}-metric`}
                                  className={'emp-metric'}
                              >
                                  <Metric
                                      loading={formattedMetricData.loading}
                                      metric={metric}
                                      key={metric.title}
                                      testId={`${testId}-${key}`}
                                      metricValue={formattedMetricData.metricData[
                                          key
                                      ]?.metric.toLocaleString('en-US')}
                                  />
                              </div>
                          ))
                        : null}

                    <AccentArea
                        className={'emp-lineChart'}
                        title={
                            <div className={'emp-icon'}>
                                {titleIconEnabled && (
                                    <Icon
                                        className={`${
                                            iconsTheme.calendar
                                        } ${'emp-marginRight'}`}
                                    />
                                )}
                                <span>Chargebacks by Post Date</span>
                            </div>
                        }
                        headerAction={
                            <PresetDateRange
                                getDateRange={updateCbByPostDateRange}
                                dateRange={PresetDateRangeDate}
                                className={'emp-dateRangePicker'}
                                allowFutureDateSelection={false}
                                testId={`${testId}-cbByPostDate`}
                            />
                        }
                        testId={`${testId}-cbByPostDate`}
                    >
                        <ChargebacksByPostDate
                            data={chargebacksByPostDate.payload.chargebacks}
                            loading={chargebacksByPostDate.loading}
                            dateRange={chargebacksByPostDate.date_range}
                        />
                    </AccentArea>

                    <AccentArea
                        className={'emp-pieChart'}
                        title={
                            <div className={'emp-icon'}>
                                {titleIconEnabled && (
                                    <Icon
                                        className={`${
                                            iconsTheme.creditCard
                                        } ${'emp-marginRight'}`}
                                    />
                                )}
                                <span>{CHARTS.pie_chart.title}</span>
                            </div>
                        }
                        testId={
                            CHARTS.pie_chart.variant === 'chargebacksByStatus'
                                ? `${testId}-cbByStatus`
                                : `${testId}-cbByCardType`
                        }
                    >
                        {CHARTS.pie_chart.variant === 'chargebacksByStatus' && (
                            <ChargebacksByStatusPie
                                loading={caseStatusData.loading}
                                data={caseStatusData.payload.caseStatusData}
                            />
                        )}
                        {CHARTS.pie_chart.variant ===
                            'chargebacksByCardType' && (
                            <ChargebacksByCardType
                                data={cardTypeData.payload.cardTypes}
                                loading={cardTypeData.loading}
                            />
                        )}
                    </AccentArea>
                    <AccentArea
                        className={'emp-table'}
                        title={
                            <div className={'emp-icon'}>
                                {titleIconEnabled && (
                                    <Icon
                                        className={`${
                                            iconsTheme.briefcase
                                        } ${'emp-marginRight'}`}
                                    />
                                )}
                                <span>
                                    Top 5 MIDs By Number Of New Chargebacks
                                </span>
                            </div>
                        }
                        headerAction={
                            <>
                                {/* <Button
                                    onClick={handleSwitchGrids}
                                    className={'emp-switchArrowBtn'}
                                    sx={clientTheme.buttons.textOrOutlinedButton.style}
                                >
                                    <div>
                                        <Icon
                                            className={`fa fa-right-left ${'emp-switchArrowIcon'}`}
                                        />
                                        <Icon className={'fa fa-table'} />
                                    </div>
                                </Button> */}
                                <PresetDateRange
                                    getDateRange={updateTop5MidsDateRange}
                                    dateRange={PresetDateRangeDate}
                                    className={'emp-dateRangePicker'}
                                    allowFutureDateSelection={false}
                                    testId={`${testId}-top5MidsByNumberOfNewCB`}
                                />
                            </>
                        }
                        testId={`${testId}-top5MidsByNumberOfNewCB`}
                    >
                        {showNewDataGrid ? (
                            <MidTop5DataAGGrid
                                gridRef={top5MidGridRef}
                                testId={`${testId}-top5MidsByNumberOfNewCB`}
                                dataSource={dataSource}
                                isLoading={isLoading}
                                paginationTotal={paginationTotal}
                            />
                        ) : (
                            <MidTop5DataGrid
                                data={top5MidData?.data}
                                loading={top5MidData?.loading}
                                testId={`${testId}-top5MidsByNumberOfNewCB`}
                            />
                        )}
                    </AccentArea>
                </div>
            </View>
        </>
    )
}

export default withRequiredRole(EntFeature.DASHBOARD, Dashboard)
